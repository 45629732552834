<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Benutzer erstellen
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute"
         mat-button
         [mat-dialog-close]="false" aria-label="close-dialog"></div>
</div>


<mat-dialog-content>
    <div class="card mt-0">
        <div class="card-body pt-0 pb-0">
            <div class="add-edit-person">
                <form [formGroup]="createUserForm"
                      [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs}"
                      (ngSubmit)="createUser()">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>E-Mail</mat-label>
                                <input matInput
                                       placeholder="E-Mail"
                                       formControlName="email">
                                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Passwort</mat-label>
                                <input matInput
                                       placeholder="Passwort Wiederholung"
                                       type="password"
                                       formControlName="password">
                                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Passwort Wiederholung</mat-label>
                                <input matInput
                                       placeholder="Passwort Wiederholung"
                                       type="password"
                                       formControlName="passwordRepetition">
                                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Vorname</mat-label>
                                <input matInput
                                       placeholder="Vorname"
                                       formControlName="firstName">
                                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Nachname</mat-label>
                                <input matInput
                                       placeholder="Nachname"
                                       formControlName="lastName">
                                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" class="float-right">
                                Speichern
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-dialog-content>
