<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Bilddetails
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="false"
         aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card mt-0">
        <div class="card-body">
            <div class="row">
                <div class="col-md-8">
                    <div id="image-full-wrapper" class="pr-5 text-center">
                        <img class="img-full m-auto"
                             *ngIf="loading === false"
                             title="{{title}}"
                             alt="{{altText}}"
                             [src]="'data:image/jpg;base64, ' + imgData | safeHtml">
                        <div *ngIf="loading === true"
                             class="m-auto"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center pb-1">
                            <button mat-raised-button
                                    color="warn"
                                    class="mt-3 mr-5"
                                    (click)="delete()">
                                Bild löschen
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div id="img-meta">
                        <form class="form"
                              [formGroup]="fullScreenForm"
                              [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>Bildnummer</mat-label>
                                        <input matInput
                                               placeholder="Bildnummer"
                                               formControlName="id"
                                               readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>Datum</mat-label>
                                        <input matInput
                                               placeholder="Datum"
                                               readonly="true"
                                               formControlName="created">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>Alt Text</mat-label>
                                        <input matInput
                                               placeholder="Alt Text"
                                               formControlName="altText">
                                        <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field>
                                        <mat-label>Title</mat-label>
                                        <input matInput
                                               placeholder="Title"
                                               formControlName="title">
                                        <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-4 mb-3">
                                <div class="col-md-12">
                                    <button class="float-right"
                                            mat-raised-button color="primary"
                                            (click)="updateImage()">
                                        <i class="fas fa-exclamation-circle mr-2"
                                           style="color: #b71c1c"
                                           *ngIf="displayErroneousInputs">
                                        </i>
                                        Aktualisieren
                                    </button>
                                    <mat-icon [hidden]="!(updateRequestSent && fullScreenForm.valid)"
                                              class="mr-2 float-right"
                                              style="margin-top: 10px">
                                        <mat-spinner color="accent" diameter="20"></mat-spinner>
                                    </mat-icon>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
