import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {PrintingService} from './printing.service';
import {PrintComponentFactory} from './PrintComponentFactory';
import {PrintableComponent} from './PrintableComponent';
import {Subscription} from 'rxjs';
import {PrintingDataAnimal, PrintingTemplate} from './templates/TemplateTypes';
import {AuthService} from '../auth/auth.service';
import {Constants, Shelter} from '../shared/constants';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';

@Component({
    selector: 'app-printing',
    templateUrl: './printing.component.html',
    styleUrls: ['./printing.component.scss']
})
export class PrintingComponent implements OnInit, OnDestroy {
    @Input() public templateType: PrintingTemplate;
    @Input() @Optional() public actionTypes: ActionTypeEntryModel[];
    @Input() @Optional() public isIncomeComponent: boolean;
    @Input() @Optional() public printDataSheets = true;
    @ViewChild('printingEntryContainer', {read: ViewContainerRef}) public printingEntryContainer: ViewContainerRef;
    public hiddenHeader = {
        hidden: false
    };
    public address = '';
    public zipAndCity = '';
    public phone = '';
    public email = '';
    public website = '';

    private dataFetchedSub: Subscription;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private cd: ChangeDetectorRef,
                private authService: AuthService,
                private printingService: PrintingService) {
    }

    ngOnInit(): void {
        const shelter = this.authService.getShelter();
        this.initShelterData(shelter);
        this.dataFetchedSub = this.printingService.dataFetched
            .subscribe(data => {
                let components: ComponentRef<PrintableComponent>[] = [];
                const componentFactory = new PrintComponentFactory(this.componentFactoryResolver,
                    this.printingEntryContainer,
                    data as PrintingDataAnimal,
                    shelter,
                    this.printDataSheets,
                    this.actionTypes);

                let printLandscape = false;

                if (this.templateType === PrintingTemplate.CareSpace) {
                    components = componentFactory.buildCareSpaceComponent();
                } else if (this.templateType === PrintingTemplate.AcquisitionAndGiveAway) {
                    components = componentFactory.buildAcquisitionAndGiveAwayComponents();
                } else if (this.templateType === PrintingTemplate.Cashbook) {
                    components = componentFactory.buildCashbookComponent();
                    printLandscape = true;
                } else if (this.templateType === PrintingTemplate.DataSheet) {
                    components = componentFactory.buildDataSheetsComponents();
                } else if (this.templateType === PrintingTemplate.IncomeOutcome) {
                    components = componentFactory.buildIncomeOutcomeComponent(this.isIncomeComponent);
                } else if (this.templateType === PrintingTemplate.Supervision) {
                    components = componentFactory.buildSupervisionComponents();
                }

                window.setTimeout(() => {
                    this.printingService.print(components, this.hiddenHeader, this.cd, printLandscape);
                    this.printingEntryContainer.clear();
                }, 1000);

            });
    }

    ngOnDestroy(): void {
        if (this.dataFetchedSub) {
            this.dataFetchedSub.unsubscribe();
        }
    }

    private initShelterData(shelter: Shelter): void {
        if (shelter === Shelter.LINZ) {
            this.address = Constants.LinzAddress;
            this.zipAndCity = Constants.LinzZipAndCity;
            this.phone = Constants.LinzPhone;
            this.email = Constants.LinzEmail;
            this.website = Constants.LinzWebsite;
        } else {
            this.address = Constants.SteyrAddress;
            this.zipAndCity = Constants.SteyrZipAndCity;
            this.phone = Constants.SteyrPhone;
            this.email = Constants.SteyrEmail;
            this.website = Constants.SteyrWebsite;
        }
    }
}
