<div class="title-bar not-printable">
    <h3>
        <ng-container>Pflegeplatz</ng-container>
    </h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">
                        <ng-container>Pflegeplatzübergabe</ng-container>
                    </h4>
                </div>

                <div class="card-body">
                    <form class="form"
                          [formGroup]="careSpaceForm"
                          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (click)="dateTimePicker.open()"
                                           [ngxMatDatetimePicker]="dateTimePicker"
                                           placeholder="Datum"
                                           formControlName="date"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="dateTimePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #dateTimePicker
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-form-field class="mat-form-field-appearance-fill">
                                    <mat-select placeholder="Laufnummer"
                                                formControlName="animalId"
                                                (closed)="selectAnimalsCtrl.reset()"
                                                (ngModelChange)="addAnimalToTable($event)"
                                                [multiple]="true">
                                        <mat-option>
                                            <ngx-mat-select-search
                                                    [formControl]="selectAnimalsCtrl.multiFilterCtrl">
                                            </ngx-mat-select-search>
                                            <mat-progress-spinner
                                                    *ngIf="loadAnimals"
                                                    diameter="20"
                                                    class="animal-search-indicator"
                                                    color="accent"
                                                    mode="indeterminate">
                                            </mat-progress-spinner>
                                        </mat-option>
                                        <mat-option *ngFor="let animal of selectAnimalsCtrl.filteredOptions | async"
                                                    [value]="animal">
                                            {{animal.id}} &mdash; {{animal.species.name}} &mdash; {{animal.race.name}}
                                            &mdash; {{animal.name}}
                                            <div class="controls animal-controls d-flex align-items-center">
                                                <a (click)="openEditDialog(animal)"
                                                   class="fas fa-edit icon prevent-opening"
                                                   matTooltip="Tierdaten bearbeiten"
                                                   matTooltipPosition="right"></a>
                                            </div>
                                        </mat-option>
                                        <mat-option *ngIf="selectAnimalsCtrl.values !== null &&
                                        selectAnimalsCtrl.values.length === 0">
                                            Keine Ergebnisse gefunden.
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">
                                <mat-form-field class="select-search">
                                    <mat-select placeholder="{{personSelectOneLabel}}"
                                                formControlName="person"
                                                (ngModelChange)="personSelectChange($event)"
                                                [compareWith]="comparePerson"
                                                #personSelect>
                                        <mat-option [classList]="'mat-option hidden-checkbox'">
                                            <a class="add-new" (click)="openDialog(simpleDialogType.NEW_PERSON)"
                                               style="font-weight: 600">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div>Neue Person erstellen...</div>
                                                    <div>
                                                        <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </mat-option>
                                        <mat-option *ngFor="let element of personSelectSearch.filteredElements | async"
                                                    [value]="element">
                                            {{element.viewValue}}
                                            <i class="fas fa-mask ml-1"
                                               [matTooltip]="'Bemerkung: ' + personRemark(element.meta)"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"
                                               *ngIf="personRemark(element.meta) !== ''"></i>
                                            <div class="controls person-controls d-flex align-items-center">
                                                <a class="fas fa-edit icon"
                                                   (click)="openDialog(simpleDialogType.EDIT_PERSON, true, element, careSpaceForm)"
                                                   matTooltip="Person bearbeiten"
                                                   matTooltipPosition="right"
                                                   matTooltipClass="tooltip-popup"></a>
                                                <a class="fas fa-dog icon"
                                                   (click)="openDialog(SHOW_ASSOCIATED_ANIMALS_DIALOG, true, element, careSpaceForm)"
                                                   matTooltip="Alle tierbezogenen Transaktionen"
                                                   matTooltipPosition="right"
                                                   matTooltipClass="tooltip-popup"></a>
                                            </div>
                                        </mat-option>
                                        <mat-option *ngIf="personSelectSearch.values !== undefined &&
                                        personSelectSearch.values.length === 0">
                                            Keine Ergebnisse gefunden.
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 pl-0">
                                <a (click)="openDialog(simpleDialogType.SEARCH_PERSON)"
                                   class="fas fa-search icon"
                                   style="margin-top: 33px"
                                   matTooltip="Person suchen"
                                   matTooltipPosition="right"
                                   matTooltipClass="tooltip-popup"></a>
                            </div>

                            <div class="col-md-5 mt-3"
                                 *ngIf="selected === 'annahme-pensionstier' || selected === 'rueckgabe-pensionstier'">
                                <label>{{isAcquisition ? 'Überbringer' : 'Abholer'}} ist auch Besitzer?</label>
                                <mat-radio-group formControlName="bringerReceiverAreSame"
                                                 aria-label="Receiver and bringer are the same?"
                                                 name="bringerReceiverAreSame">
                                    <mat-radio-button value="yes" [checked]="true">Ja</mat-radio-button>
                                    <mat-radio-button value="no">Nein</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Ausgewählte Tiere</h4>
                </div>
                <div class="card-body">
                    <app-animal-list [dataSourceFromAcquisitionGiveAway]="true"
                                     [showEntryCount]="false"
                                     [enableDelete]="false"
                                     [enableEdit]="false"
                                     [enableDuplicate]="false"
                                     (selectedAnimals)="animalAddedToTable($event)"
                                     [entryRemovable]="true">
                    </app-animal-list>
                    <div class="row">
                        <div class="col">
                            <button class="float-right mt-4 ml-3"
                                    mat-raised-button color="primary"
                                    [disabled]="requestSent && careSpaceForm.valid"
                                    (click)="saveAndPrintCareSpaceAnimals()">
                                <i class="fas fa-exclamation-circle mr-2"
                                   style="color: #b71c1c"
                                   *ngIf="displayErroneousInputs">
                                </i>
                                Speichern und Pflegeverträge drucken
                            </button>
                            <mat-icon
                                    [hidden]="!(requestSent && careSpaceForm.valid)"
                                    class="float-right" style="margin-top: 30px">
                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-printing [templateType]="PrintingTemplate.CareSpace"></app-printing>
