<div class="edit-veterinary-treatment">
    <form class="form container-fluid"
          [formGroup]="newTreatmentForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
        <div class="row">
            <div class="col-md-5">
                <mat-form-field>
                    <mat-label>Maßnahme</mat-label>
                    <input matInput
                           laceholder="Maßnahme"
                           formControlName="newDescription">
                </mat-form-field>
            </div>

            <div class="col-md-5">
                <mat-form-field>
                    <mat-label>Rechnungstext</mat-label>
                    <input matInput
                           placeholder="Rechnungstext"
                           formControlName="newInvoiceText">
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <mat-label>Kosten</mat-label>
                    <input matInput
                           placeholder="Kosten"
                           formControlName="newPrice">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button class="float-right mt-4"
                        mat-raised-button color="primary"
                        [disabled]="updateRequestSent && newTreatmentForm.valid"
                        (click)="updateTreatment()">
                    <i class="fas fa-exclamation-circle mr-2"
                       style="color: #b71c1c"
                       *ngIf="displayErroneousInputs">
                    </i>
                    Aktualisieren
                </button>
                <mat-icon [hidden]="!(updateRequestSent && newTreatmentForm.valid)" class="mr-2 float-right"
                          style="margin-top: 32px">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </div>
        </div>
    </form>
</div>

