import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as fromZipCityActions from './zip-city.actions';
import {EndpointService} from '../../http/endpoint.service';
import {ZipCity} from '../zip-city-search.service';
import {LoggingService} from '../../../logging/logging.service';
import {handleHTTPError} from '../../../error-handling';

@Injectable()
export class ZipCityEffects {
    @Effect()
    loadZipCities$ = this.actions$.pipe(
        ofType(fromZipCityActions.LOAD_ZIP_CITIES),
        switchMap((loadZipCities: fromZipCityActions.LoadZipCities) => {
            const type = loadZipCities.payload.type;
            const value = loadZipCities.payload.value;

            let params = new HttpParams();
            if (type === ZipCity.ZIP) {
                params = params.append('postal_code', value);
            } else {
                params = params.append('city', value);
            }
            return this.endpointService.zipCities().pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params}).pipe(
                            map(response => response.data)
                        );
                })
            );
        }),
        map(zipCities => {
            let mappedZipCities = [];
            if (zipCities) {
                mappedZipCities = zipCities;
            }
            return new fromZipCityActions.SetZipCities(mappedZipCities);
        }),
        catchError(error => {
            return this.handleError(error);
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }


    private handleError = (errorRes: any): Observable<fromZipCityActions.HttpFail> => {
        return handleHTTPError(errorRes, fromZipCityActions, 'Postleitzahlen/Orten', this.loggingService);
    };

}
