import {Action} from '@ngrx/store';
import {ActionTypeEntryModel} from '../../../shared/models/action-type-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_ACTION_TYPES = '[Action-Types] Load Action Types';
export const SET_ACTION_TYPES = '[Action-Types] Set Action Types';
export const HTTP_FAIL = '[Action-Types] Http Fail';

export class LoadActionTypes implements Action {
    readonly type = LOAD_ACTION_TYPES;
}

export class SetActionTypes implements Action {
    readonly type = SET_ACTION_TYPES;

    constructor(public payload: ActionTypeEntryModel[]) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type ActionTypesActions =
    LoadActionTypes
    | SetActionTypes
    | HttpFail;

