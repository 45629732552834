import {SelectSearch} from '../services/select-box/select-search';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PersonEntryModel} from '../models/person-entry.model';
import {DropdownOption} from '../data-types/dropdown-option';
import {isArray} from 'rxjs/internal-compatibility';
import * as moment from 'moment';

export class PersonSelect extends SelectSearch {
    public createPersonSelectBox(persons: Observable<PersonEntryModel[]> | PersonEntryModel[]): void {
        if (isArray(persons)) {
            const dropdownOptions = this.map(persons);
            super.setValues(dropdownOptions);
        } else {
            persons.pipe(
                take(1),
                map(data => {
                    return this.map(data);
                })
            ).subscribe(arr => {
                super.setValues(arr);
            });
        }
    }

    private map(persons: PersonEntryModel[]): DropdownOption[] {
        return persons.map((element: PersonEntryModel) => {
            return {
                value: element.id.toString(),
                viewValue: element.id + ' — ' +
                    element.lastName + ' ' + element.firstName + ' — ' +
                    this.getBirthday(element.birthday) +
                    element.street + '  — ' +
                    element.zip + ' - ' + element.city + ' - ' +
                    element.phone,
                meta: [{key: 'remark', value: element.remark}]
            } as DropdownOption;
        });
    }

    private getBirthday(birthday): string {
        if (typeof birthday !== 'undefined' && birthday !== null) {
            return moment(birthday).format('DD.MM.YYYY') + ' — ';
        } else {
            return '';
        }
    }
}
