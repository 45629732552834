import {MissingAnimalEntryModel} from '../models/animal/missing-animal-entry.model';

export class MissingAnimalActionConverter {
    public static toHTTP(missingAnimal: MissingAnimalEntryModel): any {
        const httpParams = {
            name: missingAnimal.name,
            race_id: missingAnimal.race.id,
            species_id: missingAnimal.species.id,
            castration_status: missingAnimal.castrated,
            gender: missingAnimal.gender,
            characteristics: missingAnimal.characteristics,
            missing_since: missingAnimal.missingSince,
            missing_street: missingAnimal.missingStreet,
            missing_zip: missingAnimal.missingZip,
            missing_city: missingAnimal.missingCity,
            missing_additional_info: missingAnimal.missingAdditionalInfo,
            owner_name: missingAnimal.ownerName,
            owner_address: missingAnimal.ownerAddress,
            owner_phone: missingAnimal.ownerPhone
        };
        return httpParams;
    }

    public static toMissingAnimalEntryModel(missingAnimal: any): MissingAnimalEntryModel {
        const mappedMissingAnimal: MissingAnimalEntryModel = {
            id: missingAnimal.id,
            name: missingAnimal.name,
            race: missingAnimal.race,
            species: missingAnimal.species,
            castrated: missingAnimal.castration_status,
            gender: missingAnimal.gender,
            characteristics: missingAnimal.characteristics,
            missingSince: missingAnimal.missing_since,
            missingStreet: missingAnimal.missing_street,
            missingZip: missingAnimal.missing_zip,
            missingCity: missingAnimal.missing_city,
            missingAdditionalInfo: missingAnimal.missing_additional_info,
            ownerName: missingAnimal.owner_name,
            ownerAddress: missingAnimal.owner_address,
            ownerPhone: missingAnimal.owner_phone,
            weight: missingAnimal.weight
        };
        return mappedMissingAnimal;
    }
}
