import {PersonEntryModel} from '../models/person-entry.model';
import {SupervisionEntryModel} from '../models/supervision-entry.model';
import {isArray} from 'rxjs/internal-compatibility';

export class ActionConverter {
    public static toPersonEntryModel(person: any): PersonEntryModel {
        if (person !== null && typeof person !== 'undefined') {
            const mappedPerson: PersonEntryModel = {
                id: person.id,
                firstName: person.first_name,
                lastName: person.last_name,
                street: person.street,
                zip: person.zip,
                city: person.city,
                phone: person.phone,
                birthday: person.birthday,
                email: person.email,
                proof: person.proof,
                issuer: person.issuer,
                remark: person.remark
            };
            return mappedPerson;
        }
        return null;
    }

    public static toSupervisionEntryModel(supervisions: any): SupervisionEntryModel[] | SupervisionEntryModel {
        if (typeof supervisions === 'undefined') {
            return null;
        }

        if (isArray(supervisions)) {
            if (supervisions.length > 0) {
                const supervisionEntries = supervisions.map(supervision => {
                    const mappedSupervision: SupervisionEntryModel = {
                        id: supervision.id,
                        datePerformed: supervision.date_performed,
                        report: supervision.report,
                        supervisor: supervision.supervisor,
                        animal: supervision.animal,
                        person: ActionConverter.toPersonEntryModel(supervision.person)
                    };
                    return mappedSupervision;
                });
                return supervisionEntries;
            }
            return [];
        } else {
            const mappedSupervision: SupervisionEntryModel = {
                id: supervisions.id,
                datePerformed: supervisions.date_performed,
                report: supervisions.report,
                supervisor: supervisions.supervisor,
                animal: supervisions.animal,
                person: ActionConverter.toPersonEntryModel(supervisions.person)
            };
            return mappedSupervision;
        }
    }
}
