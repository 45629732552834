import * as LoggingActions from './logging.actions';

export interface State {
    logging: string[];
}

const initialState: State = {
    logging: []
};

export function loggingReducer(state = initialState, action: LoggingActions.LoggingActions): State {
    switch (action.type) {
        case LoggingActions.ADD_LOGGING:
            return {
                ...state,
                logging: [...state.logging, action.payload]
            };
        default:
            return state;
    }
}
