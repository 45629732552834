import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../../shared/controls/date-time-picker/DateTimeConfig';
import {MatSelect} from '@angular/material/select';
import {SelectSearch} from '../../../shared/services/select-box/select-search';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AnimalsService} from '../../animals.service';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {AutoCompleteService} from '../../../shared/services/auto-complete/auto-complete.service';
import {SpeciesService} from '../../store/species/species.service';
import {RaceService} from '../../store/races/race.service';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {TupleHelper} from '../../../shared/data-types/tuple';

@Component({
    selector: 'app-missing-animals-filter',
    templateUrl: './missing-animals-filter.component.html',
    styleUrls: ['./missing-animals-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class MissingAnimalsFilterComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    @ViewChild('singleSelectRace') public singleSelectRace: MatSelect;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public selectSearchSpecies = new SelectSearch();
    public selectSearchRace = new SelectSearch();
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public filteredMissingStreets$: Observable<[number, string | number][]>;
    public filteredMissingZips$: Observable<[number, string | number][]>;
    public filteredMissingCities$: Observable<[number, string | number][]>;
    public filteredAnimalNames$: Observable<[number, string | number][]>;
    public filteredOwnerNames$: Observable<[number, string | number][]>;
    public filteredMissingAdditionalInfos$: Observable<[number, string | number][]>;

    private allRaces: DropdownOption[];
    private selectBoxSub: Subscription;

    constructor(private animalsService: AnimalsService,
                private autocompleteService: AutoCompleteService,
                private speciesService: SpeciesService,
                private racesService: RaceService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initDropdown();
    }

    ngAfterViewInit(): void {
        this.selectSearchSpecies.init(this.singleSelectSpecies);
        this.selectSearchRace.init(this.singleSelectRace);
    }

    ngOnDestroy(): void {
        if (this.selectBoxSub) {
            this.selectBoxSub.unsubscribe();
        }
    }

    public filter(): void {
        if (!this.filterForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;

        const value = this.filterForm.value;
        const filterModel = {
            missingSince: value.missingSince ? value.missingSince.format('YYYY-MM-DD') : '',
            missingStreet: value.missingStreet ? value.missingStreet : '',
            missingZip: value.missingZip ? value.missingZip : '',
            missingCity: value.missingCity ? value.missingCity : '',
            radius: value.radius ? value.radius : '',
            species: value.species ? value.species.viewValue : '',
            race: value.race ? value.race.viewValue : '',
            name: value.name ? value.name : '',
            owner: value.owner ? value.owner : '',
            characteristics: value.characteristics ? value.characteristics : '',
            additionalInfo: value.additionalInfo ? value.additionalInfo : ''
        };

        this.animalsService.filterMissingAnimalsEvent.next(filterModel);
    }

    public selectSpecies(option: DropdownOption): void {
        const filteredRaces = this.allRaces
            .filter(race => +TupleHelper.getValue(race.meta, 'speciesId') === +TupleHelper.getValue(option.meta, 'id'));
        this.selectSearchRace.setValues(filteredRaces);
        this.filterForm.patchValue({
            race: ''
        });
        this.singleSelectSpecies.close();
    }

    public reset(): void {
        this.filterForm.reset();
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            missingSince: ['', Validators.maxLength(50)],
            missingStreet: ['', Validators.maxLength(50)],
            missingZip: ['', Validators.maxLength(50)],
            missingCity: ['', Validators.maxLength(50)],
            radius: ['', [Validators.maxLength(50), Validators.pattern('^[0-9]*$')]],
            species: ['', Validators.maxLength(50)],
            race: ['', Validators.maxLength(50)],
            name: ['', Validators.maxLength(50)],
            owner: ['', Validators.maxLength(50)],
            characteristics: ['', Validators.maxLength(50)],
            additionalInfo: ['', Validators.maxLength(50)]
        });

        this.filteredMissingStreets$ = this.filterForm.controls.missingStreet.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('missing', 'missing_street', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredMissingZips$ = this.filterForm.controls.missingZip.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('missing', 'missing_zip', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredMissingCities$ = this.filterForm.controls.missingCity.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('missing', 'missing_city', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredAnimalNames$ = this.filterForm.controls.name.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('missing', 'name', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredOwnerNames$ = this.filterForm.controls.owner.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('missing', 'owner_name', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredMissingAdditionalInfos$ = this.filterForm.controls.additionalInfo.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('missing', 'missing_additional_info', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );
    }

    private initDropdown(): void {
        this.selectBoxSub = combineLatest([
            this.speciesService.fetchSpecies(),
            this.racesService.fetchRaces()
        ]).subscribe(([species, races]) => {
            this.allRaces = races;
            this.selectSearchSpecies.setValues(species);
            this.selectSearchRace.setValues(races);
            this.animalsService.speciesAndRaces.next([species, races]);
        });
    }
}
