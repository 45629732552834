import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import * as fromSpeciesActions from './species.actions';
import {LoadSpecies} from './species.actions';
import {CRUD} from '../../../shared/services/http/crud';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class SpeciesEffects {
    @Effect()
    loadSpecies$ = this.actions$.pipe(
        ofType(fromSpeciesActions.LOAD_SPECIES),
        switchMap((speciesState: LoadSpecies) => {
            return this.endpointService.species(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint).pipe(
                            map(response => response.data)
                        );
                }),
                map(response => {
                    return new fromSpeciesActions.SetSpecies({
                        species: response,
                        operation: speciesState.payload
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addSpecies$ = this.actions$.pipe(
        ofType(fromSpeciesActions.ADD_SPECIES),
        switchMap((speciesState: fromSpeciesActions.AddSpecies) => {
            return this.endpointService.species(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: speciesState.payload.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromSpeciesActions.LoadSpecies(CRUD.CREATE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateSpecies$ = this.actions$.pipe(
        ofType(fromSpeciesActions.UPDATE_SPECIES),
        switchMap((speciesState: fromSpeciesActions.UpdateSpecies) => {
            return this.endpointService.species(HttpMethod.PUT, speciesState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint, {
                                name: speciesState.payload.name,
                            }
                        ).pipe(
                            map(() => {
                                return new fromSpeciesActions.LoadSpecies(CRUD.UPDATE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteSpecies$ = this.actions$.pipe(
        ofType(fromSpeciesActions.DELETE_SPECIES),
        switchMap((speciesState: fromSpeciesActions.DeleteSpecies) => {
            return this.endpointService.species(HttpMethod.DELETE, speciesState.payload.deleteSpeciesId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .request('delete', endpoint, {
                            body: {
                                update_species_id: speciesState.payload.updateSpeciesId.toString(),
                                update_race_id: speciesState.payload.updateRaceId.toString(),
                            }
                        })
                        .pipe(
                            map(() => {
                                return new fromSpeciesActions.LoadSpecies(CRUD.DELETE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );


    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }


    private handleError = (errorRes: any): Observable<fromSpeciesActions.HttpFail> => {
        return handleHTTPError(errorRes, fromSpeciesActions, 'Tierarten', this.loggingService, 'species_already_exists');
    };

}
