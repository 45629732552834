import {CRUD} from '../../../shared/services/http/crud';
import {CareSpaceEntryModel} from '../../../shared/models/animal/care-space-entry.model';
import * as fromCareSpaces from './care-space.actions';

export interface State {
    careSpaces: CareSpaceEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    careSpaces: [],
    operation: CRUD.NONE
};

export function careSpaceReducer(state = initialState, action: fromCareSpaces.CareSpaceActions): State {
    switch (action.type) {
        case fromCareSpaces.SET_CARE_SPACES:
            return {
                ...state,
                careSpaces: [...action.payload.careSpaces],
                operation: action.payload.operation
            };
        case fromCareSpaces.UPDATE_CARE_SPACE:
            const updatedAnimal = {
                ...state.careSpaces.find(careSpace => careSpace.id === action.payload.id),
                ...action.payload
            };

            const updatedCareSpaces = [
                ...state.careSpaces
            ];
            updatedCareSpaces[action.payload.id] = updatedAnimal;

            return {
                ...state,
                careSpaces: updatedCareSpaces
            };
        case fromCareSpaces.ADD_CARE_SPACE:
            return {
                ...state,
                careSpaces: [...state.careSpaces, action.payload]
            };
        case fromCareSpaces.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
