<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Vermisstes Tier erstellen
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="true" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-body">
            <form class="form"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
                  [formGroup]="newMissingAnimalForm">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Tierart</mat-label>
                            <mat-select placeholder="Art"
                                        [multiple]="false"
                                        (keydown.enter)="addOrUpdateMissingAnimal()"
                                        (ngModelChange)="selectSpecies($event)"
                                        formControlName="species"
                                        #singleSelectSpecies>
                                <mat-option>
                                    <ngx-mat-select-search
                                            [formControl]="selectSearchSpecies.filterCtrl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new" (click)="speciesService.addSpecies($event,
                                    chipBar,
                                    newMissingAnimalForm.controls.species)"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neue Art erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option
                                        *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                        [value]="species.value"
                                        #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{species.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown)="$event.stopPropagation()"
                                                   (keydown.enter)="speciesService.updateSpecies($event,
                                                                                           option,
                                                                                           species,
                                                                                           updatedVal,
                                                                                           pageIndex,
                                                                                           entriesPerPage,
                                                                                           order,
                                                                                           orderedColumn,
                                                                                           newMissingAnimalForm.controls.species)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="species.viewValue" />
                                        </div>
                                        <div class="controls"
                                             *ngIf="species.value !== 'hund' && species.value !== 'katze'">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Tierart ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"
                                            ></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="speciesService.updateSpecies($event,
                                                                               option,
                                                                               species,
                                                                               updatedVal,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               newMissingAnimalForm.controls.species)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="speciesService.deleteSpecies($event,
                                                                               species,
                                                                               selectSearchSpecies.values,
                                                                               allRaces,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               newMissingAnimalForm.controls.species,
                                                                               newMissingAnimalForm.controls.race)"
                                               matTooltip="Tierart löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Tierrasse</mat-label>
                            <mat-select placeholder="Rasse"
                                        [multiple]="false"
                                        (keydown.enter)="addOrUpdateMissingAnimal()"
                                        (ngModelChange)="raceChanged($event)"
                                        formControlName="race"
                                        #singleSelectRace>
                                <mat-option>
                                    <ngx-mat-select-search
                                            [formControl]="selectSearchRace.filterCtrl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new"
                                       (click)="raceService.addRace($event,
                                       allFDRaces,
                                       allSpecies,
                                       newMissingAnimalForm.controls.race,
                                       newMissingAnimalForm.controls.species.value)"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neue Rasse erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option
                                        *ngFor="let race of selectSearchRace.filteredElements | async"
                                        [value]="race.value"
                                        #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{race.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown)="$event.stopPropagation()"
                                                   (keydown.enter)="raceService.updateRace($event,
                                                                                           option,
                                                                                           race,
                                                                                           updatedVal,
                                                                                           pageIndex,
                                                                                           entriesPerPage,
                                                                                           order,
                                                                                           orderedColumn,
                                                                                           newMissingAnimalForm.controls.race)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="race.viewValue" />
                                        </div>
                                        <div class="controls">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Rasse ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"
                                            ></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="raceService.updateRace($event,
                                                                               option,
                                                                               race,
                                                                               updatedVal,
                                                                                pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               newMissingAnimalForm.controls.race)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="raceService.deleteRace($event,
                                                                               race,
                                                                               selectSearchRace.values,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               newMissingAnimalForm.controls.race)"
                                               matTooltip="Rasse löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="chip-list">
                            <mat-chip-list
                                #chipList
                                aria-label="Beschreibung">
                                <mat-chip
                                        *ngFor="let characteristic of chipBar.getSelectedValues()"
                                        [selectable]="chipBar.selectable"
                                        [removable]="chipBar.removable"
                                        (removed)="chipBar.removeChip(characteristic.name)">
                                    {{characteristic.name}}
                                    <mat-icon matChipRemove *ngIf="chipBar.removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Beschreibung"
                                       #characteristicInput
                                       (click)="chipBar.focusCharacteristicsInput()"
                                       [formControl]="chipBar.characteristicsCtrl"
                                       (ngModelChange)="chipBar.filterCharacteristics($event)"
                                       [matAutocomplete]="chipAutoComplete"
                                       [matChipInputFor]="chipList">
                            </mat-chip-list>
                            <mat-autocomplete #chipAutoComplete
                                              (optionSelected)="chipBar.selected($event, characteristicInput)">
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new"
                                       (click)="addCharacteristic($event, allRaces, raceId)"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neues Merkmal erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option *ngFor="let characteristic of chipBar.filteredCharacteristics | async"
                                            [value]="characteristic"
                                            #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{characteristic.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown.enter)="updateCharacteristic($event,
                                                                                           option,
                                                                                           characteristic,
                                                                                           updatedVal)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="characteristic.viewValue" />
                                        </div>
                                        <div class="controls">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Merkmal ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="updateCharacteristic($event,
                                                                               option,
                                                                               characteristic,
                                                                               updatedVal)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="deleteCharacteristic($event,
                                                                             characteristic)"
                                               matTooltip="Merkmal löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>

                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Tierame</mat-label>
                            <input formControlName="name"
                                   matInput
                                   (keydown.enter)="addOrUpdateMissingAnimal()"
                                   placeholder="Tiername">
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field class="mat-datetime-picker">
                            <input matInput
                                   (click)="datePickerMissingSince.open()"
                                   [ngxMatDatetimePicker]="datePickerMissingSince"
                                   placeholder="Abgängig seit"
                                   formControlName="missingSince"
                                   [min]="dateTimeConfig.minDate"
                                   [max]="dateTimeConfig.maxDate">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datePickerMissingSince"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #datePickerMissingSince
                                                     [stepHour]="dateTimeConfig.stepHour"
                                                     [stepMinute]="dateTimeConfig.stepMinute"
                                                     [hideTime]="true">
                            </ngx-mat-datetime-picker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Geschlecht</mat-label>
                            <mat-select formControlName="gender"
                                        (keydown.enter)="addOrUpdateMissingAnimal()">
                                <mat-option *ngFor="let gender of genderOptions"
                                            [value]="gender.value">
                                    {{gender.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Kastration</mat-label>
                            <mat-select formControlName="castration"
                                        (keydown.enter)="addOrUpdateMissingAnimal()">
                                <mat-option *ngFor="let castration of castrationStatusOptions"
                                            [value]="castration.value">
                                    {{castration.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Abgängig Straße</mat-label>
                            <input matInput
                                   placeholder="Straße"
                                   formControlName="missingStreet">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Abgängig Postleitzahl</mat-label>
                            <input type="text"
                                   placeholder="Abgängig Postleitzahl"
                                   matInput
                                   (ngModelChange)="zipCitySearchService.valueChanged($event, ZipCity.ZIP)"
                                   formControlName="missingZip"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption
                                              #auto="matAutocomplete"
                                              (optionSelected)="zipCityChanged($event)"
                                              [displayWith]="zipCitySearchService.displayFn">
                                <mat-option *ngFor="let option of zipCitySearchService.values"
                                            [value]="option">
                                    {{option.viewValue}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Abgängig Ort</mat-label>
                            <input formControlName="missingCity"
                                   matInput
                                   (keydown.enter)="addOrUpdateMissingAnimal()"
                                   placeholder="Abgängig Ort">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Zusatzinfo</mat-label>
                            <textarea matInput
                                      formControlName="additionalInfo"
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="mb-0 mt-4">Besitzer</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input formControlName="ownerName"
                                   placeholder="Name"
                                   (keydown.enter)="addOrUpdateMissingAnimal()"
                                   matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Adresse</mat-label>
                            <input formControlName="ownerAddress"
                                   placeholder="Adresse"
                                   (keydown.enter)="addOrUpdateMissingAnimal()"
                                   matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field>
                            <mat-label>Telefonnummer</mat-label>
                            <input formControlName="ownerPhone"
                                   placeholder="Telefonnummer"
                                   (keydown.enter)="addOrUpdateMissingAnimal()"
                                   matInput>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-12">
                        <button mat-raised-button
                                color="warn"
                                type="button"
                                (click)="cancel()">Abbrechen
                        </button>
                        <button class="float-right"
                                mat-raised-button color="primary"
                                type="button"
                                (click)="addOrUpdateMissingAnimal()">Speichern
                        </button>
                        <mat-icon [hidden]="!(requestSent && newMissingAnimalForm.valid)"
                                  class="mr-2 float-right"
                                  style="margin-top: 9px">
                            <mat-spinner color="accent" diameter="20"></mat-spinner>
                        </mat-icon>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-content>
