import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_FD_RACES = '[FDRaces] Load FDRaces';
export const SET_FD_RACES = '[FDRaces] Set FDRaces';
export const ADD_FD_RACE = '[FDRaces] Add FDRace';
export const HTTP_FAIL = '[FDRaces] Http Fail';

export class LoadFDRaces implements Action {
    readonly type = LOAD_FD_RACES;

    constructor(public payload: { crud: CRUD, filter?: any }) {
    }
}

export class SetFDRaces implements Action {
    readonly type = SET_FD_RACES;

    constructor(public payload: { operation: CRUD, fdRaces: DictionaryEntryModel[] }) {
    }
}

export class AddFDRace implements Action {
    readonly type = ADD_FD_RACE;


    constructor(public payload: any) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type FDRacesActions =
    LoadFDRaces
    | SetFDRaces
    | HttpFail
    | AddFDRace;

