import {Action} from '@ngrx/store';
import {ErrorTypes} from '../../../../shared/data-types/error-types';
import {OccupationEntryModel} from '../../../../shared/models/occupation-entry.model';

export const LOAD_OCCUPATION = '[Occupation] Load Occupation';
export const SET_OCCUPATION = '[Occupation] Set Occupation';

export const HTTP_FAIL = '[Occupation] Http Fail';

export class LoadOccupation implements Action {
    readonly type = LOAD_OCCUPATION;

    constructor(public payload: {
        from: string,
        to: string
    }) {
    }
}

export class SetOccupation implements Action {
    readonly type = SET_OCCUPATION;

    constructor(public payload: {
        occupationEntryModel: OccupationEntryModel
    }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type OccupationActions =
    LoadOccupation
    | SetOccupation
    | HttpFail;

