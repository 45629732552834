import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PersonDialogComponent} from '../../persons/person-dialog/person-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {DialogType} from '../../dialogs/dialog-type';
import {PersonsService} from '../../persons/persons.service';
import {SupervisionService} from '../supervision.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SupervisionEntryModel} from '../../shared/models/supervision-entry.model';
import {Actions, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {CRUD} from '../../shared/services/http/crud';
import {Subscription} from 'rxjs/Subscription';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {Order} from '../../shared/controls/data-table/ordering';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import * as moment from 'moment';
import * as fromsSupervisionActions from '../store/supervision.actions';
import {ActionType} from 'app/animals/store/action-types/action-types.service';

@Component({
    selector: 'app-former-supervisions',
    templateUrl: './former-supervisions.component.html',
    styleUrls: ['./former-supervisions.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class FormerSupervisionsComponent implements OnInit, OnDestroy {
    @ViewChild('datePickerSupervision') public datePickerSupervision: any;
    public newSupervisionForm: FormGroup;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public addRequestSent = false;
    public displayErroneousInputs = false;
    public loading = false;
    public formerSupervisions: SupervisionEntryModel[];
    public totalSupervisions = 0;
    public animal: AnimalEntryModel;
    public ActionType = ActionType;
    public alreadyPrinted = false;

    private currentlyAssignedPerson: PersonEntryModel;
    private simpleDialogType = new DialogType();
    private personSub: Subscription;
    private fetchedSupervisionsSub: Subscription;

    constructor(private dialog: MatDialog,
                private dialogRef: MatDialogRef<FormerSupervisionsComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private personsService: PersonsService,
                private formBuilder: FormBuilder,
                private actions$: Actions,
                private supervisionService: SupervisionService) {
        if (this.data !== null && typeof this.data.animal !== 'undefined') {
            this.animal = this.data.animal;
        }
    }

    ngOnInit(): void {
        const latestTransaction = this.animal.transactionHistory.slice().pop();
        this.alreadyPrinted = latestTransaction.wasPrinted;

        if (this.animal.type.name === ActionType.VERGABE.key && this.alreadyPrinted) {
            this.initForm();
            this.fetchCurrentlyAssignedPerson();
        }
        this.initSupervisions();
        this.initErrorHandling();
    }

    ngOnDestroy(): void {
        if (this.fetchedSupervisionsSub) {
            this.fetchedSupervisionsSub.unsubscribe();
        }

        if (this.personSub) {
            this.personSub.unsubscribe();
        }
    }

    public openDialog(): void {
        this.dialog.open(PersonDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {type: this.simpleDialogType.NEW_PERSON}
        });
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public addSupervision(): void {
        if (!this.newSupervisionForm.valid) {
            this.addRequestSent = false;
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;
        const report = this.newSupervisionForm.value.report;
        const datePerformed = moment(this.newSupervisionForm.value.supervisionDate).format('YYYY-MM-DD');
        const supervisor = this.newSupervisionForm.value.supervisor;

        const newSupervision: SupervisionEntryModel = {
            id: -1, // Dummy -> Is not sent to the server,
            animal: this.animal,
            person: this.currentlyAssignedPerson,
            report,
            supervisor,
            datePerformed
        };

        this.supervisionService.addSupervision(newSupervision);
    }

    public showPerson(): void {
        this.dialog.open(PersonDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.PERSON_DATA,
                person: this.currentlyAssignedPerson
            }
        });
    }

    private initForm(): void {
        this.newSupervisionForm = this.formBuilder.group({
            supervisionDate: ['', Validators.required],
            supervisor: ['', Validators.required],
            person: [''],
            report: ['', Validators.required]
        });
    }

    private initSupervisions(): void {
        this.fetchedSupervisionsSub = this.supervisionService.fetchSupervisions(this.animal.id, 1)
            .subscribe(supervisions => {
                this.formerSupervisions = supervisions;
            });

        this.actions$.pipe(
            ofType(fromsSupervisionActions.SET_SUPERVISIONS),
            tap((supervisionActionState: fromsSupervisionActions.SetSupervisions) => {
                const crudOperation = supervisionActionState.payload.crud;
                this.totalSupervisions = supervisionActionState.payload.totalElements;

                if (crudOperation === CRUD.READ ||
                    crudOperation === CRUD.NONE) {
                    return;
                }

                this.supervisionService.handleRequestSuccess(crudOperation, 'Kontrolle', '');
                if (this.newSupervisionForm) {
                    this.newSupervisionForm.patchValue({
                        supervisionDate: '',
                        supervisor: '',
                        report: ''
                    });
                }
            })
        ).subscribe();
    }

    private fetchCurrentlyAssignedPerson(): void {
        const filter = {
            id: this.animal.personId
        };

        if (this.animal.personId !== null) {
            this.personSub = this.personsService.fetchPersons(1, 1, Order.NONE, '', filter)
                .subscribe(persons => {
                    this.currentlyAssignedPerson = persons.slice(-1).pop();
                    this.newSupervisionForm.patchValue({
                        person: this.currentlyAssignedPerson.id + ' — ' +
                            this.currentlyAssignedPerson.firstName + ' ' + this.currentlyAssignedPerson.lastName + ' — ' +
                            this.currentlyAssignedPerson.street + ' — ' +
                            this.currentlyAssignedPerson.zip + ' - ' + this.currentlyAssignedPerson.city
                    });
                });
        }
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromsSupervisionActions.HTTP_FAIL)
        ).subscribe((httpFail: fromsSupervisionActions.HttpFail) => {
            this.supervisionService.handleError(httpFail.payload);
            this.loading = false;
        });
    }
}
