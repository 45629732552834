<div class="title-bar">
    <h3>Übersicht</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Personen</h4>
                </div>
                <div class="card-body">
                    <div id="top-controls" class="d-flex justify-content-between">
                        <div id="show-entries-container">
                            Zeige
                            <select name="entries"
                                    id="entries"
                                    class="mr-1 ml-1"
                                    (ngModelChange)="changeEntriesPerPage($event)"
                                    [(ngModel)]="entriesPerPage">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            Einträge
                        </div>
                        <div id="add-new-person">
                            <a class="d-flex align-items-center no-color"
                               (click)="openDialog(simpleDialogType.NEW_PERSON)">
                                Neue Person erstellen <i
                                    class="add-btn fas fa-plus d-flex justify-content-center align-items-center ml-3"></i>
                            </a>
                        </div>
                    </div>
                    <div style="overflow-x: auto">
                        <table mat-table
                               [dataSource]="persons"
                               (matSortChange)="orderData($event)"
                               multiTemplateDataRows
                               matSort
                               class="mat-elevation-z0 extensible">
                            <!-- Position Column -->
                            <ng-container *ngFor="let def of tableDef">
                                <ng-container [matColumnDef]="def.key">
                                    <th class="sortable-header" mat-header-cell mat-sort-header
                                        *matHeaderCellDef> {{def.header}} </th>
                                    <td [ngClass]="def.className" mat-cell
                                        *matCellDef="let element"
                                        [ngSwitch]="def.key">
                                        <ng-container *ngSwitchCase="'id'">
                                            <div class="d-flex flex-column-reverse justify-content-center align-items-center position-relative">
                                                <div class="fas fa-mask remark mt-2"
                                                     [matTooltip]="'Bemerkung: ' + element.remark"
                                                     matTooltipPosition="right"
                                                     matTooltipClass="tooltip-popup"
                                                     *ngIf="element.remark !== '' && element.remark !== null">
                                                </div>
                                                <div>{{element[def.key] }}</div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngSwitchDefault>
                                            {{element[def.key]}}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container matColumnDef="controls">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <a (click)="openDialog(SHOW_ASSOCIATED_ANIMALS_DIALOG, element)"
                                       class="fas fa-dog icon prevent-opening"
                                       matTooltip="Alle tierbezogenen Transaktionen"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                    <a class="fas fa-edit icon"
                                       matTooltip="Personendaten bearbeiten"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                    <a class="fas fa-trash-alt icon prevent-opening"
                                       (click)="deletePerson(element);"
                                       matTooltip="Person löschen"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                </td>
                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
                                    <div class="table-element-detail w-100"
                                         [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                                        <app-person-quick-edit class="w-100"
                                                               [person]="element"
                                                               [pageIndex]="pageIndex"
                                                               [entriesPerPage]="entriesPerPage"
                                                               [order]="order"
                                                               [orderedColumn]="orderedColumn">
                                        </app-person-quick-edit>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="table-row"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = canExpand($event, element, expandedElement)">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
                        </table>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline">
                            <div id="total-results">Gesamt: <span>{{totalPersons}}</span></div>
                            <mat-paginator [length]="totalPersons"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div class="default-entry mt-2" *ngIf="totalPersons === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>

                </div>
                <div class="card-body pt-0">
                    <app-persons-filter (filterEvent)="filter($event)"></app-persons-filter>
                </div>
            </div>
        </div>
    </div>
</div>
