import {Component, Input, OnInit} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {MediaCentreMode} from '../media-centre.component';
import {MediaService} from '../media.service';

@Component({
    selector: 'app-media-filter',
    templateUrl: './media-filter.component.html',
    styleUrls: ['./media-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class MediaFilterComponent implements OnInit {
    @Input() public mode: MediaCentreMode;
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();

    constructor(private formBuilder: FormBuilder,
                private mediaService: MediaService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    public filter(): void {
        if (!this.filterForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;

        const value = this.filterForm.value;
        const filterModel = {
            fileName: value.id ? value.id : '',
            created: value.created ? value.created.format('YYYY-MM-DD') : ''
        };

        this.mediaService.filterEvent.next(filterModel);
    }

    public reset(): void {
        this.filterForm.reset();
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            id: ['', Validators.maxLength(50)],
            created: ['', Validators.maxLength(50)]
        });
    }


}
