export class DataTable {
    public static canExpand(event: MouseEvent, element: any, expandedElement: any): any {
        if (event.view.getSelection().type === 'Range') {
            return null;
        }

        const iconClicked = (event.target as HTMLElement).classList.contains('prevent-opening');
        if (iconClicked || expandedElement === element) {
            return null;
        } else {
            return element;
        }
    }
}
