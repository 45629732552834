import {PersonEntryModel} from '../../shared/models/person-entry.model';
import * as PersonActions from './persons.actions';

export interface State {
    persons: PersonEntryModel[];
}

const initialState: State = {
    persons: []
};

export function personsReducer(state = initialState, action: PersonActions.PersonsActions): State {
    switch (action.type) {
        case PersonActions.SET_PERSONS:
            return {
                ...state,
                persons: [...action.payload.persons]
            };
        case PersonActions.ADD_PERSON:
            return {
                ...state,
                persons: [...state.persons, action.payload.person]
            };
        case PersonActions.UPDATE_PERSON:
            const updatedPerson = {
                ...state.persons.find(person => person.id === action.payload.person.id),
                ...action.payload
            };

            const updatedPersons = [...state.persons];
            const arrIndex = updatedPersons.findIndex(element => element.id === updatedPerson.id);
            updatedPersons[arrIndex] = updatedPerson;

            return {
                ...state,
                persons: updatedPersons
            };
        case PersonActions.DELETE_PERSON:
            return {
                ...state,
                persons: state.persons.filter(person => person.id !== action.payload.personId)
            };
        case PersonActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
