import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {DialogType} from '../dialog-type';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tuple, TupleHelper} from '../../shared/data-types/tuple';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {SelectSearch} from '../../shared/services/select-box/select-search';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import * as moment from 'moment';

@Component({
    selector: 'app-simple-dialog',
    templateUrl: './simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()},
    ]
})
export class SimpleDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dateDied') public dateDied: any;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    @ViewChild('singleSelectRace') public singleSelectRace: MatSelect;
    @ViewChild('singleSelectFDRace') public singleSelectFDRace: MatSelect;
    @ViewChild('singleSelectFilteredRace') public singleSelectFilteredRace: MatSelect;
    public readonly type: string;
    public readonly simpleDialogType = new DialogType();
    public readonly entity: string;
    public readonly identifier: string;
    public readonly content: string;
    public readonly options: DropdownOption[];
    public readonly keepAspectRatio: boolean;
    public readonly meta: Tuple[];
    public deathReason = null;
    public simpleForm: FormGroup;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public title: string;
    public displayErroneousInputs = false;
    public selectSearchSpecies = new SelectSearch();
    public selectSearchRace = new SelectSearch();
    public selectSearchFDRaces = new SelectSearch();
    public selectSearchFilteredRaces = new SelectSearch();

    private allRaces: DropdownOption[];
    private allFDRaces: DropdownOption[];
    private readonly currentUser: UserEntryModel;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialogRef: MatDialogRef<SimpleDialogComponent>,
                private formBuilder: FormBuilder) {
        this.type = this.data.type;
        this.entity = this.data.entity;
        this.identifier = this.data.identifier;
        this.content = this.data.content;
        this.meta = this.data.meta;

        if (this.data.options !== null && typeof this.data.options !== 'undefined') {
            this.options = this.data.options;
        }

        if (this.data.meta !== null && typeof this.data.meta !== 'undefined') {
            this.keepAspectRatio = TupleHelper.getValue(this.data.meta, 'keepAspectRatio');
        }

        if (this.data.allRaces !== null && typeof this.data.allRaces !== 'undefined') {
            this.allRaces = this.data.allRaces;
        }

        if (this.data.currentUser) {
            this.currentUser = this.data.currentUser;
        }

        this.allFDRaces = TupleHelper.getValue(this.data.meta, 'allFDRaces') as DropdownOption[];
        const selectedSpecies = TupleHelper.getValue(this.data.meta, 'selectedSpeciesId') as string;
        this.filterFDRaces(selectedSpecies);
    }

    ngOnInit(): void {
        if (this.type === this.simpleDialogType.ACCOMMODATION) {
            this.title = 'Unterbringung erstellen';
        } else if (this.type === this.simpleDialogType.GOING_TO_HEAVEN) {
            this.title = 'Himmelfahrt';
        } else if (this.type === this.simpleDialogType.DELETE_RACE) {
            this.title = 'Tierrasse löschen';
        } else if (this.type === this.simpleDialogType.DELETE_SPECIES) {
            this.title = 'Tierart löschen';
        } else if (this.type === this.simpleDialogType.NEW_CHARACTERISTIC) {
            this.title = 'Neues Merkmal erstellen';
        } else if (this.type === this.simpleDialogType.CANCEL_BOOKING) {
            this.title = this.entity + ' stornieren';
        } else if (this.type === this.simpleDialogType.CREATE_GENERIC) {
            this.title = this.entity + ' erstellen';
        } else if (this.type === this.simpleDialogType.DELETE_GENERIC) {
            this.title = this.entity + ' löschen';
        } else if (this.type === this.simpleDialogType.TRIVIAL) {
            this.title = 'Hinweis';
        } else if (this.type === this.simpleDialogType.REMARK) {
            this.title = 'Hinweis';
        } else if (this.type === this.simpleDialogType.UPLOAD) {
            this.title = 'Bilder hochladen';
        } else if (this.type === this.simpleDialogType.UNSAVED_CHANGES ||
            this.type === this.simpleDialogType.NO_SYNCHRONIZATION) {
            this.title = this.entity;
        }

        const latestTransaction: TransactionEntryModel = typeof TupleHelper.getValue(this.meta, 'latestTransaction') === 'undefined' ?
            null : TupleHelper.getValue(this.meta, 'latestTransaction');

        if (latestTransaction !== null) {
            this.deathReason = latestTransaction.deathReason;
        }

        const allOptions: DropdownOption[] = TupleHelper.getValue(this.data.meta, 'allOptions');
        if (this.type === this.simpleDialogType.CREATE_GENERIC &&
            this.entity !== null &&
            typeof this.entity !== 'undefined' &&
            (this.entity === 'Tierrasse' || this.entity === 'Merkmal')) {
            this.initDropdownControls(allOptions, this.entity);
        }

        const selectedSpeciesId = TupleHelper.getValue(this.data.meta, 'selectedSpeciesId');
        let selectedSpecies = null;
        if (allOptions) {
            selectedSpecies = allOptions.find(option => option.value === selectedSpeciesId);
        }

        const selectedRaceId = TupleHelper.getValue(this.data.meta, 'selectedRaceId');
        let selectedRace = null;
        if (allOptions) {
            selectedRace = allOptions.find(option => +TupleHelper.getValue(option.meta, 'id') === selectedRaceId);
        }

        let cancellator = '';
        if (this.currentUser) {
            cancellator = this.currentUser.firstName + ' ' + this.currentUser.lastName;
        }

        this.simpleForm = this.formBuilder.group({
            name: ['', Validators.required],
            reason: ['', Validators.required],
            cancellator: [cancellator, Validators.required],
            newSpeciesOrRace: [null, Validators.required],
            dateDied: [moment(), Validators.required],
            deathReason: [this.deathReason, Validators.required],
            species: [selectedSpecies, Validators.required],
            race: [selectedRace, Validators.required],
            fdRace: ['', Validators.required],
            filteredRaces: ['', Validators.required]
        });

    }

    ngAfterViewInit(): void {
        this.selectSearchSpecies.init(this.singleSelectSpecies);
        this.selectSearchRace.init(this.singleSelectRace);
        this.selectSearchFDRaces.init(this.singleSelectRace);
        this.selectSearchFilteredRaces.init(this.singleSelectFilteredRace);
    }

    ngOnDestroy(): void {
        this.selectSearchSpecies.destroy();
        this.selectSearchRace.destroy();
        this.selectSearchFDRaces.destroy();
        this.selectSearchFilteredRaces.destroy();
    }

    public onSubmit(): void {
        this.displayErroneousInputs = true;

        if (this.type === this.simpleDialogType.CANCEL_BOOKING &&
            (!this.simpleForm.controls.cancellator.valid ||
                !this.simpleForm.controls.reason.valid)) {
            return;
        }

        if (this.type === this.simpleDialogType.CREATE_GENERIC &&
            this.entity === 'Tierrasse' &&
            !this.simpleForm.controls.fdRace.valid) {
            return;
        }

        if (this.type === this.simpleDialogType.CREATE_GENERIC &&
            (!this.simpleForm.controls.name.valid ||
                (this.entity === 'Tierrasse' && !this.simpleForm.controls.species.valid) ||
                (this.entity === 'Merkmal' && !this.simpleForm.controls.race.valid))) {
            return;
        }

        if (this.type === this.simpleDialogType.DELETE_SPECIES &&
            (!this.simpleForm.controls.newSpeciesOrRace.valid || !this.simpleForm.controls.filteredRaces.valid)) {
            return;
        }


        if (this.type === this.simpleDialogType.DELETE_RACE &&
            !this.simpleForm.controls.newSpeciesOrRace.valid) {
            return;
        }

        if (this.type === this.simpleDialogType.GOING_TO_HEAVEN &&
            (!this.simpleForm.controls.dateDied.valid || !this.simpleForm.controls.deathReason.valid)) {
            return;
        }

        this.dialogRef.close(this.simpleForm.value);
    }

    public speciesOrRaceChanged($event: MatSelectChange): void {
        if (this.type === this.simpleDialogType.DELETE_SPECIES) {
            const species = $event.value;
            const speciesId = +TupleHelper.getValue(species.meta, 'id');
            const filteredRaces = this.allRaces.filter(r => +TupleHelper.getValue(r.meta, 'speciesId') === speciesId);
            this.selectSearchFilteredRaces.setValues(filteredRaces);
        }
    }

    public speciesChanged($event: DropdownOption): void {
        const selectedSpecies = $event.value;
        this.filterFDRaces(selectedSpecies);
    }

    private initDropdownControls(options: DropdownOption[], type: string): void {
        if (type === 'Tierrasse') {
            this.selectSearchSpecies.setValues(options);
        } else { // Merkmal
            this.selectSearchRace.setValues(options);
        }
    }

    private filterFDRaces(selectedSpecies: string): void {
        if (selectedSpecies && this.allFDRaces) {
            let filteredFDRaces;
            if (selectedSpecies.toLowerCase() !== 'hund' && selectedSpecies.toLowerCase() !== 'katze') {
                filteredFDRaces =
                    this.allFDRaces.filter(entry => TupleHelper.getValue(entry.meta, 'species').toLowerCase() !== 'hund' &&
                        TupleHelper.getValue(entry.meta, 'species').toLowerCase() !== 'katze');
            } else {
                filteredFDRaces = this.allFDRaces.filter(entry => TupleHelper.getValue(entry.meta, 'species') === selectedSpecies);
            }

            this.selectSearchFDRaces.setValues(filteredFDRaces);
        }
    }
}
