import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_SPECIES = '[Species] Load Species';
export const SET_SPECIES = '[Species] Set Species';
export const ADD_SPECIES = '[Species] Add Species';
export const UPDATE_SPECIES = '[Species] Update Species';
export const DELETE_SPECIES = '[Species] Delete Species';
export const HTTP_FAIL = '[Species] Http Fail';

export class LoadSpecies implements Action {
    readonly type = LOAD_SPECIES;

    constructor(public payload: CRUD) {
    }
}

export class SetSpecies implements Action {
    readonly type = SET_SPECIES;

    constructor(public payload: { species: DictionaryEntryModel[], operation: CRUD }) {
    }
}

export class AddSpecies implements Action {
    readonly type = ADD_SPECIES;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class UpdateSpecies implements Action {
    readonly type = UPDATE_SPECIES;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class DeleteSpecies implements Action {
    readonly type = DELETE_SPECIES;

    constructor(public payload: { deleteSpeciesId: number, updateSpeciesId: number, updateRaceId: number }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type SpeciesActions =
    LoadSpecies
    | SetSpecies
    | AddSpecies
    | UpdateSpecies
    | DeleteSpecies
    | HttpFail;

