import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {AssociatedAnimalsComponent} from './associated-animals/associated-animals.component';
import {PersonDialogComponent} from './person-dialog/person-dialog.component';
import {DialogType} from '../dialogs/dialog-type';
import {PersonEntryModel} from '../shared/models/person-entry.model';
import {DataTable} from '../shared/data-types/data-table';
import {Order} from '../shared/controls/data-table/ordering';
import {Subscription} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../shared/services/http/crud';
import {PersonsService} from './persons.service';
import {Sort} from '@angular/material/sort';
import {SimpleDialogComponent} from '../dialogs/simple-dialog/simple-dialog.component';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';
import {ActionTypesService} from '../animals/store/action-types/action-types.service';
import * as fromPersonsActions from './store/persons.actions';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {ZipCitySearchService} from '../shared/services/zip-city-search/zip-city-search.service';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-persons',
    templateUrl: './persons.component.html',
    styleUrls: ['./persons.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class PersonsComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    public readonly SHOW_ASSOCIATED_ANIMALS_DIALOG = 'associated-animals-dialog';
    public simpleDialogType = new DialogType();
    public displayedColumns: string[] = [
        'id',
        'lastName',
        'firstName',
        'street',
        'zip',
        'city',
        'phone',
        'controls'
    ];
    public expandedElement: PersonEntryModel | null;
    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'PNr.',
            className: 'id'
        }, {
            key: 'lastName',
            header: 'Nachname',
            className: 'lastName'
        }, {
            key: 'firstName',
            header: 'Vorname',
            className: 'firstName'
        }, {
            key: 'street',
            header: 'Straße',
            className: 'street'
        }, {
            key: 'zip',
            header: 'Postleitzahl',
            className: 'zip'
        }, {
            key: 'city',
            header: 'Ort',
            className: 'city'
        }, {
            key: 'birthday',
            header: 'Geburtstag',
            className: 'birthday'
        }, {
            key: 'phone',
            header: 'Telefon',
            className: 'phone'
        }, {
            key: 'email',
            header: 'E-Mail',
            className: 'email'
        }
    ];
    public loading?: boolean = null;
    public totalPersons = 0;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.NONE;
    public orderedColumn = '';
    public persons: PersonEntryModel[];
    public displayErroneousInputs = false;

    private personsSub: Subscription;
    private lastPage = 1;
    private personIdentifier = '';
    private actionTypes: ActionTypeEntryModel[];
    private actionTypesSub: Subscription;
    private filterModel: any;

    constructor(public personService: PersonsService,
                public zipCitySearchService: ZipCitySearchService,
                protected actions$: Actions,
                protected snackbarService: SnackbarService,
                private actionTypesService: ActionTypesService,
                private authService: AuthService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.initPersonsTable();
        this.initActionTypes();
        this.initErrorHandling();
        this.personService.crudFinished.subscribe(payload => {
            this.personIdentifier = payload;
        });
    }

    ngOnDestroy(): void {
        if (this.personsSub) {
            this.personsSub.unsubscribe();
        }
        if (this.actionTypesSub) {
            this.actionTypesSub.unsubscribe();
        }
    }

    public openDialog(dialogType: string, selectedPerson?: PersonEntryModel): void {
        if (dialogType === this.simpleDialogType.NEW_PERSON) {
            this.dialog.open(PersonDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: dialogType,
                    lastPage: this.lastPage,
                    entriesPerPage: this.entriesPerPage,
                    order: this.order,
                    column: this.orderedColumn
                }
            });
        } else if (dialogType === this.SHOW_ASSOCIATED_ANIMALS_DIALOG) {
            if (this.actionTypes.length > 0) {
                this.dialog.open(AssociatedAnimalsComponent, {
                    width: '1200px',
                    panelClass: 'component-wrapper',
                    data: {
                        selectedPerson,
                        actionTypes: this.actionTypes
                    }
                });
            }
        }
    }

    public canExpand($event: MouseEvent, element: any, expandedElement: PersonEntryModel): any {
        return DataTable.canExpand($event, element, expandedElement);
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.personService
            .fetchPersons(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, this.filterModel)
            .subscribe(persons => {
                this.persons = persons;
            });

        this.paginator.firstPage();
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.personService
            .fetchPersons(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, this.filterModel)
            .subscribe(persons => {
                this.persons = persons;
            });
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active;
        if (this.orderedColumn === 'lastName') {
            this.orderedColumn = 'last_name';
        } else if (this.orderedColumn === 'firstName') {
            this.orderedColumn = 'first_name';
        }

        this.personService
            .fetchPersons(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, this.filterModel)
            .subscribe(persons => {
                this.persons = persons;
            });
    }

    public deletePerson(element: PersonEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Person',
                identifier: element.firstName + ' ' + element.lastName
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.personService
                    .deletePerson(element.id, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
                this.personIdentifier = element.firstName + ' ' + element.lastName;
            }
        });
    }

    public filter($event: any): void {
        if ($event) {
            this.filterModel = $event;
            this.personService
                .fetchPersons(this.entriesPerPage, 1, this.order, this.orderedColumn, $event)
                .subscribe(persons => {
                    this.persons = persons;
                });
            this.paginator.firstPage();
        }
    }

    private initPersonsTable(): void {
        this.loading = true;
        this.personService.fetchPersons(this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn)
            .subscribe(persons => {
                this.persons = persons;
            });

        this.personsSub = this.actions$.pipe(
            ofType(fromPersonsActions.SET_PERSONS)
        ).subscribe((personState: fromPersonsActions.SetPersons) => {
            const crudOperation = personState.payload.crud;
            this.totalPersons = personState.payload.totalElements;
            this.lastPage = personState.payload.lastPage;
            this.loading = false;

            if (crudOperation === CRUD.READ ||
                crudOperation === CRUD.NONE) {
                return;
            }

            if (crudOperation === CRUD.CREATE || crudOperation === CRUD.DELETE || crudOperation === CRUD.UPDATE) {
                this.persons = personState.payload.persons;
            }

            if (crudOperation === CRUD.CREATE) {
                this.paginator.length = this.totalPersons;
                this.paginator.lastPage();
            }

            this.personService.handleRequestSuccess(crudOperation, 'Person', this.personIdentifier);
        });
    }

    private initActionTypes(): void {
        this.actionTypesSub = this.actionTypesService.fetchActionTypes()
            .subscribe(actionTypes => {
                this.actionTypes = actionTypes;
            });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromPersonsActions.HTTP_FAIL)
        ).subscribe((httpFail: fromPersonsActions.HttpFail) => {
            this.personService.handleRequestError(httpFail.payload.message);
            this.loading = false;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }
}
