import {Action} from '@ngrx/store';
import {CRUD} from '../../shared/services/http/crud';
import {Order} from '../../shared/controls/data-table/ordering';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {ErrorTypes, InvalidAPIRequest} from '../../shared/data-types/error-types';

export const LOAD_ACTIONS = '[Actions] Load Actions';
export const SET_ACTIONS = '[Actions] Set Actions';
export const SET_LATEST_ACTION = '[Actions] Set Latest Action';
export const ADD_ACTIONS = '[Actions] Add Actions';
export const UPDATE_ACTIONS = '[Actions] Update Actions';
export const DELETE_ACTION = '[Actions] Delete Action';
export const DELETE_ACTIONS = '[Actions] Delete Actions';
export const FETCH_LATEST_TRANSACTION = '[Actions] Latest Action';
export const HTTP_FAIL = '[Actions] Http Fail';

export class LoadActions implements Action {
    readonly type = LOAD_ACTIONS;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        fetchActions?: boolean,
        transactionId?: any,
        actionFilter?: any,
        usePagination?: boolean,
        refreshTable?: boolean,
        invalidAPIRequests?: InvalidAPIRequest[]
    }) {
    }
}

export class SetActions implements Action {
    readonly type = SET_ACTIONS;

    constructor(public payload: {
        actions: TransactionEntryModel[],
        invalidAPIRequests: InvalidAPIRequest[],
        crud: CRUD, totalElements:
            number,
        lastPage: number,
        transactionId?: any,
        refreshTable?: boolean,
    }) {
    }
}

export class SetLatestAction implements Action {
    readonly type = SET_LATEST_ACTION;

    constructor(public payload: number) {
    }
}

export class AddActions implements Action {
    readonly type = ADD_ACTIONS;

    constructor(public payload: {
        actions: TransactionEntryModel[],
        size: number,
        page: number,
        order: Order,
        column: string,
        fetchActions: boolean
    }) {
    }
}

export class UpdateActions implements Action {
    readonly type = UPDATE_ACTIONS;

    constructor(public payload: {
        actions: TransactionEntryModel[],
        size: number,
        page: number,
        order: Order,
        column: string,
        filter: any
    }) {
    }
}

export class DeleteAction implements Action {
    readonly type = DELETE_ACTION;

    constructor(public payload: { id: number, size: number, page: number, order: Order, column: string, usePagination?: boolean }) {
    }
}

export class DeleteActions implements Action {
    readonly type = DELETE_ACTIONS;

    constructor(public payload: number[]) {
    }
}

export class FetchLatestTransaction implements Action {
    readonly type = FETCH_LATEST_TRANSACTION;
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type ActionsActions =
    LoadActions
    | SetActions
    | FetchLatestTransaction
    | SetLatestAction
    | AddActions
    | UpdateActions
    | DeleteAction
    | DeleteActions
    | HttpFail;
