import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AttributeEntryModel} from '../shared/models/attributes/attribute-entry.model';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {CRUD} from '../shared/services/http/crud';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {MatSnackBarRef} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../shared/components/snackbar/snackbar.component';
import {DictionaryEntryModel} from '../shared/models/dictionary-entry.model';
import * as fromAttributesActions from './store/attributes/attributes.actions';
import * as fromOptionsActions from './store/options/options.actions';
import * as fromApp from '../store/app.reducer';

@Injectable()
export class AttributesService {

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public executeFetchRequest(speciesGroupName: string): void {
        this.store.dispatch(new fromAttributesActions.LoadAttributes(speciesGroupName));
    }

    public fetchAttributes(speciesGroupName: string): Observable<AttributeEntryModel[]> {
        return this.store.select('attributeList').pipe(
            take(1),
            map(attributeState => {
                return attributeState.attributes;
            }),
            switchMap(() => {
                this.store.dispatch(new fromAttributesActions.LoadAttributes(speciesGroupName));
                return this.actions$.pipe(
                    ofType(fromAttributesActions.SET_ATTRIBUTES),
                    map((attributeState: fromAttributesActions.SetAttributes) => {
                        return attributeState.payload;
                    })
                );
            })
        );
    }

    public addAttribute(attributeName: string, optionName: string, speciesGroupName: string): void {
        const option: DictionaryEntryModel[] = [
            {
                id: -1,
                name: optionName
            }
        ];

        const newAttribute: AttributeEntryModel = {
            id: -1,
            name: attributeName,
            speciesGroupName,
            datasheetVisibility: false,
            options: option
        };
        this.store.dispatch(new fromAttributesActions.AddAttributes(newAttribute));
    }

    public updateAttribute(attribute: AttributeEntryModel, name: string, datasheetVisibility: boolean): void {
        const updatedAttribute: AttributeEntryModel = {
            id: attribute.id,
            name,
            datasheetVisibility,
            speciesGroupName: attribute.speciesGroupName,
            options: attribute.options
        };
        this.store.dispatch(new fromAttributesActions.UpdateAttribute(updatedAttribute));
    }

    public deleteAttribute(attribute: AttributeEntryModel): void {
        this.store.dispatch(new fromAttributesActions.DeleteAttribute(attribute));
    }

    public addOption(optionName: string, attributeId: number): void {
        const newOption: DictionaryEntryModel = {
            id: -1,
            name: optionName
        };
        this.store.dispatch(new fromOptionsActions.AddOption({option: newOption, attributeId}));
    }

    public updateOption(attribute: DictionaryEntryModel, value: string, attributeId: number): void {
        const updatedOption: DictionaryEntryModel = {
            id: attribute.id,
            name: value
        };
        this.store.dispatch(new fromOptionsActions.UpdateOption({option: updatedOption, attributeId}));
    }

    public deleteOption(optionId: number, attributeId: number): void {
        this.store.dispatch(new fromOptionsActions.DeleteOption({optionId, attributeId}));
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): MatSnackBarRef<SnackbarComponent> {
        return this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}

