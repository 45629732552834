import {SupervisionEntryModel} from '../../shared/models/supervision-entry.model';
import * as SupervisionActions from './supervision.actions';

export interface State {
    supervisions: SupervisionEntryModel[];
}

const initialState: State = {
    supervisions: []
};

export function supervisionsReducer(state = initialState, action: SupervisionActions.SupervisionsActions): State {
    switch (action.type) {
        case SupervisionActions.SET_SUPERVISIONS:
            return {
                ...state,
                supervisions: [...action.payload.supervisions]
            };
        case SupervisionActions.ADD_SUPERVISION:
            return {
                ...state,
                supervisions: [...state.supervisions, action.payload.supervision]
            };
        case SupervisionActions.UPDATE_SUPERVISION:
            const updatedSupervision = {
                ...state.supervisions.find(supervision => supervision.id === action.payload.supervision.id),
                ...action.payload
            };

            const updatedSupervisions = [...state.supervisions];
            const arrIndex = updatedSupervisions.findIndex(element => element.id === updatedSupervision.id);
            updatedSupervisions[arrIndex] = updatedSupervision;

            return {
                ...state,
                supervisions: updatedSupervisions
            };
        // case SupervisionActions.DELETE_SUPERVISION:
        //     return {
        //         ...state,
        //         supervisions: state.supervisions.filter(supervision => supervision.id !== action.payload.supervisionId)
        //     };
        case SupervisionActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
