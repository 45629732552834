import {Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MediaEntryModel} from '../shared/models/media-entry.model';
import {SimpleDialogComponent} from '../dialogs/simple-dialog/simple-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from '../dialogs/dialog-type';
import {MediaService} from './media.service';
import {Actions, ofType} from '@ngrx/effects';
import {HttpEventType} from '@angular/common/http';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {getGermanPaginatorIntl} from '../shared/controls/data-table/german-paginator-intl';
import * as fromMediaActions from './store/media.actions';
import {Tuple} from '../shared/data-types/tuple';
import {Order} from '../shared/controls/data-table/ordering';

@Component({
    selector: 'app-media-centre',
    templateUrl: './media-centre.component.html',
    styleUrls: ['./media-centre.component.scss'],
    providers: [
        {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()}
    ]
})
export class MediaCentreComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    @ViewChild('card') card: ElementRef;
    public media$: Observable<MediaEntryModel[]>;
    public loading = false;
    public entriesPerPage = 50;
    public pageIndex = 1;
    public totalMedia = 0;
    public order = Order.DESC;
    public column = 'id';
    public selectedItemIds = new Array<number>();
    public mode = MediaCentreMode.LOAD_IMAGES;
    public Mode = MediaCentreMode;

    private dialogType = new DialogType();
    private mediaSub: Subscription;
    private uploadDialog: MatDialogRef<SimpleDialogComponent>;
    private lastPage = 1;
    private filterModel: any;
    private readonly keepRatio = true;

    constructor(private dialog: MatDialog,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
                private mediaService: MediaService,
                private actions$: Actions) {
        if (this.data !== null) {
            if (typeof this.data.mode !== 'undefined') {
                this.mode = data.mode;
            }

            if (typeof this.data.keepRatio !== 'undefined') {
                this.keepRatio = this.data.keepRatio;
            }
        }
    }

    ngOnInit(): void {
        this.initThumbnailContainer();
        this.initErrorHandling();

        this.mediaService.filterEvent.subscribe(filterModel => {
            this.filterModel = filterModel;
            this.media$ = this.mediaService
                .fetchMedia(true,
                    this.entriesPerPage,
                    this.pageIndex,
                    this.order,
                    this.column,
                    this.filterModel);
            this.paginator.firstPage();
        });
    }

    ngOnDestroy(): void {
        if (this.mediaSub) {
            this.mediaSub.unsubscribe();
        }
    }

    public uploadImage(): void {

        const keepAspectRatio: Tuple[] = [
            {
                key: 'keepAspectRatio',
                value: this.keepRatio
            }
        ];
        this.uploadDialog = this.dialog.open(SimpleDialogComponent, {
            width: '1200px',
            panelClass: 'component-wrapper',
            data: {
                type: this.dialogType.UPLOAD,
                meta: keepAspectRatio
            }
        });
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.loading = true;
        this.media$ = this.mediaService.fetchMedia(true,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.column,
            this.filterModel);
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.loading = true;
        this.media$ = this.mediaService.fetchMedia(true,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.column,
            this.filterModel);
    }

    public thumbClicked(mediaId: number): void {
        const selectedIndex = this.selectedItemIds.indexOf(mediaId);
        if (selectedIndex >= 0) {
            this.selectedItemIds.splice(selectedIndex, 1);
        } else {
            this.selectedItemIds.push(mediaId);
        }
    }

    private initThumbnailContainer(): void {
        this.loading = true;
        this.media$ = this.mediaService.fetchMedia(true,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.column,
            this.filterModel);

        this.mediaSub = this.actions$.pipe(
            ofType(fromMediaActions.SET_MEDIA)
        ).subscribe((mediaState: fromMediaActions.SetMedia) => {
            this.totalMedia = mediaState.payload.totalElements;
            this.lastPage = mediaState.payload.lastPage;
            this.loading = false;
        });

        this.mediaService.progressStatus.subscribe(status => {
            if (status.type === HttpEventType.Response) {
                this.paginator.length = this.totalMedia;
                this.paginator.firstPage();
                this.loading = true;
                this.media$ = this.mediaService.fetchMedia(true,
                    this.entriesPerPage,
                    1,
                    this.order,
                    this.column,
                    this.filterModel);
                if (typeof this.uploadDialog !== 'undefined') {
                    this.uploadDialog.close();
                }
            }
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromMediaActions.HTTP_FAIL)
        ).subscribe((httpFail: fromMediaActions.HttpFail) => {
            this.mediaService.handleError(httpFail.payload);
            this.loading = false;
        });
    }
}

export enum MediaCentreMode {
    LOAD_IMAGES,
    CHANGE_IMAGE,
    PREVIEW
}
