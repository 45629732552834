<div id="media-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Laufnummer</mat-label>
                    <input matInput
                           placeholder="Laufnummer"
                           formControlName="id">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerCreated.open()"
                           [ngxMatDatetimePicker]="datePickerCreated"
                           placeholder="Erstellt am"
                           formControlName="created"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerCreated"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerCreated
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col text-right">
                <button mat-raised-button
                        class="mt-4 float-right"
                        color="primary">
                    Suchen
                </button>
            </div>

            <div class="col">
                <button mat-raised-button
                        class="mt-4 float-left"
                        color="warn"
                        (click)="reset()">
                    Zurücksetzen
                </button>
            </div>
        </div>
    </form>
</div>
