import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {SupervisionEntryModel} from '../shared/models/supervision-entry.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import * as fromApp from '../store/app.reducer';
import * as fromSupervisionActions from './store/supervision.actions';

@Injectable()
export class SupervisionService {

    public filterEvent = new BehaviorSubject(null);

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public addSupervision(supervisionModel: SupervisionEntryModel): void {
        this.store.dispatch(new fromSupervisionActions.AddSupervision({
            supervision: supervisionModel
        }));
    }

    public deleteSupervision(supervisionId: number, page: number, animalId = -1): void {
        this.store.dispatch(new fromSupervisionActions.DeleteSupervision({
            supervisionId,
            page,
            animalId
        }));
    }

    public fetchSupervisions(animalId = -1, page = -1): Observable<SupervisionEntryModel[]> {
        return this.store.select('supervisionList').pipe(
            take(1),
            map(supervisionState => {
                return supervisionState.supervisions;
            }),
            switchMap(supervisions => {
                if (supervisions.length === 0 || page >= 0) {
                    this.store.dispatch(new fromSupervisionActions.LoadSupervisions({
                        crud: CRUD.READ,
                        animalId,
                        page
                    }));
                    return this.actions$.pipe(
                        ofType(fromSupervisionActions.SET_SUPERVISIONS),
                        map((supervisionState: fromSupervisionActions.SetSupervisions) => {
                            return supervisionState.payload.supervisions;
                        })
                    );
                } else {
                    return of(supervisions);
                }
            })
        );
    }


    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleError(errorMsg): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }

}
