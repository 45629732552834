import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {ElementRef} from '@angular/core';
import {DropdownOption} from '../data-types/dropdown-option';
import {DictionaryEntryModel} from '../models/dictionary-entry.model';
import {TupleHelper} from '../data-types/tuple';
import {SelectBoxUtility} from './select-box/select-box-utility';

export class ChipBar {
    public characteristicsCtrl = new FormControl();
    public filteredCharacteristics: ReplaySubject<DropdownOption[]> = new ReplaySubject<DropdownOption[]>(1);
    public selectable = true;
    public removable = true;

    private selectedChips: DictionaryEntryModel[] = [];
    private chipBarInput: ElementRef<HTMLInputElement>;
    private chipOptions: DropdownOption[];

    public setInput(characteristicInput: ElementRef<HTMLInputElement>): void {
        this.chipBarInput = characteristicInput;
    }

    public setValues(chipOptions: DropdownOption[]): void {
        this.chipOptions = chipOptions;
        this.filteredCharacteristics.next(chipOptions.slice());
    }

    public getSelectedValues(): DictionaryEntryModel[] {
        return this.selectedChips;
    }

    public addChip(identfier: DictionaryEntryModel): void {
        this.selectedChips.push(identfier);
    }

    public replaceChip(name: string, newChip: DictionaryEntryModel): void {
        const index = this.selectedChips.findIndex(chip => chip.name === name);
        if (index >= 0) {
            this.selectedChips[index] = newChip;
        }
    }

    public removeChip(name: string): void {
        const index = this.selectedChips.findIndex(chip => chip.name === name);

        if (index >= 0) {
            this.selectedChips.splice(index, 1);
        }
    }

    public removeAllChips(): void {
        this.selectedChips = [];
    }

    public selected(event: MatAutocompleteSelectedEvent, characteristicInput: HTMLInputElement): void {
        const classes = event.option._getHostElement().className;
        if (classes.includes('hidden-checkbox')) {
            return;
        }

        const id = +TupleHelper.getValue(event.option.value.meta, 'id');
        if (typeof this.selectedChips.find(chip => chip.id === id) !== 'undefined') {
            this.characteristicsCtrl.setValue(null);
            return;
        }

        const toBeAddedChip: DictionaryEntryModel = {
            id,
            name: event.option.value.viewValue
        };

        this.selectedChips.push(toBeAddedChip);
        this.chipBarInput.nativeElement.name = '';
        this.characteristicsCtrl.setValue(null);
        characteristicInput.value = '';
    }


    public focusCharacteristicsInput(): void {
        this.chipBarInput.nativeElement.blur();
        this.chipBarInput.nativeElement.focus();
    }

    public filterCharacteristics(value: string | DropdownOption): void {
        if (value) {
            const optionsValue = SelectBoxUtility.getOptionsValue(value);
            const filteredOptions = this.chipOptions?.filter(option => option.value.toLowerCase().startsWith(optionsValue));
            if (filteredOptions) {
                this.filteredCharacteristics.next(filteredOptions.slice());
            }
        } else {
            this.filteredCharacteristics.next(this.chipOptions.slice());
        }
    }
}
