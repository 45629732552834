import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {Store} from '@ngrx/store';
import {SelectBoxService} from '../../../shared/controls/select-box/select-box.service';
import {ActionTypeEntryModel} from '../../../shared/models/action-type-entry.model';
import * as fromActionTypes from './action-types.actions';
import * as fromApp from '../../../store/app.reducer';

@Injectable()
export class ActionTypesService {
    public fetchedActionsEvent = new BehaviorSubject<ActionTypeEntryModel[]>(null);

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private selectBoxService: SelectBoxService) {
    }

    public populateActionTypeSelectBox(actionTypeEntries?: ActionTypeEntryModel[]): Observable<DropdownOption[]> {
        return this.store.select('actionTypeList').pipe(
            take(1),
            map(actionTypeState => {
                return actionTypeState.actionTypes;
            }),
            switchMap(actionTypes => {
                if (typeof actionTypeEntries !== 'undefined' &&
                    actionTypeEntries !== null &&
                    actionTypeEntries.length > 0) {
                    return of(actionTypeEntries);
                } else if (actionTypes.length === 0) {
                    return this.fetchActionTypes();
                } else {
                    return of(actionTypes);
                }
            }),
            map((actionTypes: ActionTypeEntryModel[]) => {
                return actionTypes
                    .map((element: ActionTypeEntryModel) => {
                        return {
                            value: SelectBoxUtility.getOptionsValue(element.name),
                            viewValue: this.getActionTypeText(element.name),
                            meta: [
                                {key: 'id', value: element.id.toString()},
                                {key: 'color', value: element.color},
                                {key: 'symbol', value: element.symbol},
                                {key: 'isAcquisition', value: element.isAcquisition}
                            ]
                        } as DropdownOption;
                    })
                    .sort(this.selectBoxService.sortCallback);
            })
        );
    }

    public fetchActionTypes(): Observable<ActionTypeEntryModel[]> {
        return this.store.select('actionTypeList').pipe(
            take(1),
            map(actionTypeState => {
                return actionTypeState.actionTypes;
            }),
            switchMap(actionTypes => {
                if (actionTypes.length === 0) {
                    this.store.dispatch(new fromActionTypes.LoadActionTypes());
                    return this.actions$.pipe(
                        ofType(fromActionTypes.SET_ACTION_TYPES),
                        map((actionTypeState: fromActionTypes.SetActionTypes) => {
                            return actionTypeState.payload;
                        })
                    );
                } else {
                    return of(actionTypes);
                }
            })
        );
    }

    public getActionTypeText(actionType: string): string {
        switch (actionType) {
            case ActionType.FINDLING.key:
                return ActionType.FINDLING.value;
            case ActionType.RUECKGABE.key:
                return ActionType.RUECKGABE.value;
            case ActionType.ABGABE.key:
                return ActionType.ABGABE.value;
            case ActionType.ANNAHME_PENSIONSTIER.key:
                return ActionType.ANNAHME_PENSIONSTIER.value;
            case ActionType.RUECKGABE_PENSIONSTIER.key:
                return ActionType.RUECKGABE_PENSIONSTIER.value;
            case ActionType.ABNAHME.key:
                return ActionType.ABNAHME.value;
            case ActionType.VERGABE.key:
                return ActionType.VERGABE.value;
            case ActionType.VERMISST.key:
                return ActionType.VERMISST.value;
            case ActionType.HIMMELFAHRT.key:
                return ActionType.HIMMELFAHRT.value;
            case ActionType.BACK_TO_SHELTER.key:
                return ActionType.BACK_TO_SHELTER.value;
        }
    }
}

export class ActionType {
    static readonly FINDLING = new ActionType('findling', 'Findling');
    static readonly RUECKGABE = new ActionType('rueckgabe', 'Rückgabe');
    static readonly ABGABE = new ActionType('abgabe', 'Abgabe');
    static readonly ANNAHME_PENSIONSTIER = new ActionType('annahme-pensionstier', 'Annahme Pensionstier');
    static readonly RUECKGABE_PENSIONSTIER = new ActionType('rueckgabe-pensionstier', 'Rückgabe Pensionstier');
    static readonly ABNAHME = new ActionType('abnahme', 'Abnahme');
    static readonly VERMISST = new ActionType('vermisst', 'Vermisst');
    static readonly VERGABE = new ActionType('vergabe', 'Vergabe');
    static readonly HIMMELFAHRT = new ActionType('himmelfahrt', 'Himmelfahrt');
    static readonly BACK_TO_SHELTER = new ActionType('übernahme', 'Übernahme ins Tierheim');

    private constructor(public readonly key: string, public readonly value: any) {
    }
}
