import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {AnimalEntryModel} from '../shared/models/animal/animal-entry.model';
import {Order} from '../shared/controls/data-table/ordering';
import {ActionsService} from '../actions/actions.service';
import {TransactionEntryModel} from '../shared/models/transaction-entry.model';
import {ActionType} from '../animals/store/action-types/action-types.service';
import {SupervisionEntryModel} from '../shared/models/supervision-entry.model';
import {Actions, ofType} from '@ngrx/effects';
import * as fromTransactionActions from '../actions/store/actions.actions';
import {PrintingDataAction, PrintingTemplate} from '../printing/templates/TemplateTypes';
import {PrintingService} from '../printing/printing.service';

@Component({
    selector: 'app-supervision',
    templateUrl: './supervision.component.html',
    styleUrls: ['./supervision.component.scss']
})
export class SupervisionComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    public animals: AnimalEntryModel[];
    public loading?: boolean = null;
    public totalTransactions = 0;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.ASC;
    public orderedColumn = 'id';
    public transactions: TransactionEntryModel[];
    public number = 1;
    public paginate = true;
    public PrintingTemplate = PrintingTemplate;

    private filterModel: any;
    private lastPage = 1;
    private animalSub: Subscription;
    private actionSub: Subscription;
    private filterSub: Subscription;
    private transactionsSub: Subscription;

    constructor(protected actions$: Actions,
                private printingService: PrintingService,
                private actionsService: ActionsService) {
    }

    ngOnInit(): void {
        this.fetchTransactions();
        this.initFilter();
    }

    ngOnDestroy(): void {
        if (this.animalSub) {
            this.animalSub.unsubscribe();
        }
        if (this.actionSub) {
            this.actionSub.unsubscribe();
        }
        if (this.filterSub) {
            this.filterSub.unsubscribe();
        }
        if (this.transactionsSub) {
            this.transactionsSub.unsubscribe();
        }

        this.actionsService.filterEvent.next(null);
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.actionsService
            .fetchActions(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, this.filterModel)
            .subscribe();
    }

    public latestSupervision(supervisions: SupervisionEntryModel[]): SupervisionEntryModel {
        if (supervisions.length === 0) {
            return null;
        } else {
            return supervisions.reduce((a, b) => (a.datePerformed > b.datePerformed ? a : b));
        }
    }

    public print(transaction?: TransactionEntryModel, latestSupervision?: SupervisionEntryModel): void {
        const printingData: PrintingDataAction = {
            actions: transaction ? [transaction] : this.transactions,
            supervisions: latestSupervision ? [latestSupervision] :
                this.transactions.map(t => this.latestSupervision(t.supervisions))
        };

        this.printingService.dataFetched.next(printingData);

        if (transaction) {
            const tr = this.transactions.find(t => t.id === transaction.id);

            const updatedTransaction: TransactionEntryModel = {
                ...tr,
                wasPrinted: true
            };
            this.actionsService.updateActions([updatedTransaction],
                this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn,
                this.filterModel);
        }
    }

    private fetchTransactions(): void {
        this.filterModel = {
            latestOnlyActionType: ActionType.VERGABE.key,
            isSupervised: 'false'
        };
        this.actionsService.fetchActions(this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn,
            this.filterModel)
            .subscribe();

        this.loading = true;
        this.transactionsSub = this.actions$
            .pipe(
                ofType(fromTransactionActions.SET_ACTIONS)
            )
            .subscribe((fromActionsState: fromTransactionActions.SetActions) => {
                this.transactions = fromActionsState.payload.actions;
                this.totalTransactions = fromActionsState.payload.totalElements;
                this.lastPage = fromActionsState.payload.lastPage;
                this.loading = false;
            });
    }

    private initFilter(): void {

        this.filterSub = this.actionsService.filterEvent.subscribe(filterModel => {
            if (filterModel !== null) {
                this.paginate = filterModel.dateFrom === '' && filterModel.dateTo === '' && filterModel.species === '';
                this.filterModel = {
                    ...filterModel,
                    latestOnlyActionType: ActionType.VERGABE.key,
                    isSupervised: (!filterModel.onlyUnsupervisedAnimals).toString()
                };

                this.actionsService
                    .fetchActions(this.entriesPerPage, 1, this.order, this.orderedColumn, this.filterModel, this.paginate, true)
                    .subscribe();
                if (typeof this.paginator !== 'undefined') {
                    this.paginator.firstPage();
                }
            }
        });
    }
}
