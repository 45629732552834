import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {RouterModule, Routes} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ManageUsersComponent} from './manage-users/manage-users.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {CreateUserDialogComponent} from './create-user-dialog/create-user-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {UserService} from './user-service.service';
import {SharedModule} from '../shared/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthGuard} from '../auth/auth.guard';
import {ManageUsersQuickEditComponent} from './manage-users/manage-users-quick-edit/manage-users-quick-edit.component';

const UserManagementRoutes: Routes = [
    {path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard]},
    {path: 'manage-users', component: ManageUsersComponent, canActivate: [AuthGuard]},
];

@NgModule({
    declarations: [
        ManageUsersComponent,
        ManageUsersQuickEditComponent,
        CreateUserDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(UserManagementRoutes),
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatTableModule,
        MatProgressSpinnerModule,
        SharedModule,
        MatDialogModule
    ],
    providers: [
        UserService
    ]
})
export class UserManagementModule {
}
