<div class="print-container w-100 mb-5" *ngIf="!hiddenHeader.hidden">
    <header class="d-flex align-items-center pb-3">
        <img class="logo ml-2" width="125" height="125" src="assets/img/logo/logo-inverted.svg">
        <div id="company-name">
            Tierheim Linz und Steyr <br />
            OÖ Landestierschutzverein
        </div>
    </header>
    <div class="sub-header text-center mt-3">
        <div>{{address}}, {{zipAndCity}} | Tel. {{phone}} | {{email}} | {{website}}
        </div>
    </div>
</div>

<main class="text-center">
    <ng-container #printingEntryContainer>
    </ng-container>
</main>
