import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActionTypesService} from '../animals/store/action-types/action-types.service';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appActionsSymbol]'
})
export class ActionsSymbolDirective implements OnInit, OnDestroy {
    @Input() actionTypeId: number;
    private actionType: ActionTypeEntryModel;
    private hostElement: ElementRef;
    private actionTypeSub: Subscription;

    constructor(private renderer: Renderer2,
                private actionTypeService: ActionTypesService,
                hostElement: ElementRef) {
        this.hostElement = hostElement;
    }

    ngOnInit(): void {
        this.actionTypeSub = this.actionTypeService.fetchedActionsEvent.subscribe(actionTypes => {
            if (actionTypes !== null) {
                this.actionType = actionTypes.find(aT => aT.id === this.actionTypeId);

                const actionTypeName = this.actionTypeService.getActionTypeText(this.actionType.name);
                const actionIdentifier = this.renderer.createElement('div');
                this.renderer.addClass(actionIdentifier, 'identifier');
                this.renderer.setProperty(actionIdentifier, 'innerHTML', actionTypeName);

                const symbol = this.renderer.createElement('div');
                this.renderer.addClass(symbol, 'animal-type');
                this.renderer.addClass(symbol, 'fas');
                this.renderer.setStyle(symbol, 'background-color', this.actionType.color);
                this.renderer.addClass(symbol, this.actionType.symbol);

                this.renderer.appendChild(this.hostElement.nativeElement, actionIdentifier);
                this.renderer.appendChild(this.hostElement.nativeElement, symbol);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.actionTypeSub) {
            this.actionTypeSub.unsubscribe();
        }
    }
}
