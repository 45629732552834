import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_RACES = '[Races] Load Races';
export const SET_RACES = '[Races] Set Races';
export const ADD_RACE = '[Races] Add Races';
export const UPDATE_RACE = '[Races] Update Races';
export const DELETE_RACE = '[Races] Delete Races';
export const HTTP_FAIL = '[Races] Http Fail';

export class LoadRaces implements Action {
    readonly type = LOAD_RACES;

    constructor(public payload: { operation: CRUD, alteredRace?: DictionaryEntryModel }) {
    }
}

export class SetRaces implements Action {
    readonly type = SET_RACES;

    constructor(public payload: { races: DictionaryEntryModel[], operation: CRUD, alteredRace?: DictionaryEntryModel }) {
    }
}

export class AddRace implements Action {
    readonly type = ADD_RACE;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class UpdateRace implements Action {
    readonly type = UPDATE_RACE;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class DeleteRace implements Action {
    readonly type = DELETE_RACE;

    constructor(public payload: { deleteId: number, updateId: number }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type RacesActions =
    LoadRaces
    | SetRaces
    | AddRace
    | UpdateRace
    | DeleteRace
    | HttpFail;

