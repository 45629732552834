import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';

@Component({
    selector: 'app-statistics-filter',
    templateUrl: './statistics-filter.component.html',
    styleUrls: ['./statistics-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class StatisticsFilterComponent implements OnInit {
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('datePickerTo') public datePickerTo: any;
    @Output() public filterEvent: EventEmitter<any> = new EventEmitter<any>();
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public filterForm: FormGroup;
    public displayErroneousInputs = false;

    constructor(private formBuilder: FormBuilder) {
    }


    ngOnInit(): void {
        this.initForm();
    }

    public filter(): void {
        if (!this.filterForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;

        const value = this.filterForm.value;
        const filterModel = {
            occupationFrom: value.occupationFrom ? value.occupationFrom.format('YYYY-MM-DD') : '',
            occupationTo: value.occupationTo ? value.occupationTo.format('YYYY-MM-DD') : '',
        };

        this.filterEvent.emit(filterModel);
    }

    public reset(): void {
        this.filterForm.reset();
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            occupationFrom: ['', Validators.maxLength(50)],
            occupationTo: ['', Validators.maxLength(50)],
        });
    }
}
