<div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
        <div class="collapse navbar-collapse flex-column" id="navigation">
            <div id="main-nav" class="d-flex w-100 align-items-center">
                <div class="navbar-wrapper">
                    <a class="navbar-brand" href="/"></a>
                </div>
                <ul class="navbar-nav navbar-right">
                    <li class="nav-item">
                        <a class="icon" class="nav-link" [routerLink]="['media-centre']" routerLinkActive="active">
                            <i class="fas fa-photo-video"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                        <span class="user-name">
                            {{currentUser.firstName + ' ' + currentUser.lastName}}
                            <i class="fas fa-angle-down"></i>
                        </span>
                            <img class="img-circle avatar" width="40" height="40"
                                 src="assets/img/avatar/placeholder.png">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item" (click)="closeSidebar()"
                               [routerLink]="['edit-profile']">
                                Profil bearbeiten</a>
                            <a class="dropdown-item" (click)="closeSidebar()"
                               [routerLink]="['manage-users']">
                                Benutzer verwalten</a>
                        </div>
                    </li>
                    <li id="logout" class="nav-item">
                        <a class="nav-link"
                           (click)="logout()">
                            <i class="fas fa-sign-out-alt"
                               style="font-size:16px">
                            </i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
