import {CRUD} from '../../shared/services/http/crud';
import * as fromActionsActions from './actions.actions';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';

export interface State {
    actions: TransactionEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    actions: [],
    operation: CRUD.NONE
};

export function actionsReducer(state = initialState,
                               action: fromActionsActions.ActionsActions): State {
    switch (action.type) {
        case fromActionsActions.SET_ACTIONS:
            return {
                ...state,
                actions: [...action.payload.actions],
                operation: action.payload.crud
            };
        case fromActionsActions.ADD_ACTIONS:
            return {
                ...state,
                actions: [...state.actions, ...action.payload.actions]
            };
        case fromActionsActions.DELETE_ACTION:
            return {
                ...state,
                actions: state.actions
                    .filter(transaction => transaction.id !== action.payload.id)
            };
        case fromActionsActions.HTTP_FAIL:
            return {
                ...state,
                operation: CRUD.NONE
            };
        default:
            return state;
    }
}
