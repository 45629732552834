import {CRUD} from '../../../shared/services/http/crud';
import {UserEntryModel} from '../../../shared/models/user-entry.model';
import * as fromUsersActions from './users.actions';

export interface State {
    users: UserEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    users: [],
    operation: CRUD.NONE
};

export function usersReducer(state = initialState,
                             action: fromUsersActions.UsersActions): State {
    switch (action.type) {
        case fromUsersActions.SET_USERS:
            return {
                ...state,
                users: [...action.payload.users],
                operation: action.payload.crud
            };
        case fromUsersActions.ADD_USER:
            return {
                ...state,
                users: [...state.users, action.payload.user]
            };
        case fromUsersActions.UPDATE_USER:
            const updatedUser = {
                ...state.users.find(user => user.id === action.payload.user.id),
                ...action.payload
            };

            const updatedUsers = [...state.users];
            const arrIndex = updatedUsers.findIndex(element => element.id === updatedUser.id);
            updatedUsers[arrIndex] = updatedUser;

            return {
                ...state,
                users: updatedUsers
            };
        case fromUsersActions.DELETE_USER:
            return {
                ...state,
                users: state.users
                    .filter(user => user.id !== action.payload.userId)
            };
        case fromUsersActions.HTTP_FAIL:
            return {
                ...state,
                operation: CRUD.NONE
            };
        default:
            return state;
    }
}
