<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        {{ label }}
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute"
         mat-button
         [mat-dialog-close]="false" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card mt-0">
        <div class="card-body pt-0 pb-0">
            <div class="add-edit-person">
                <app-person-form [person]="person"
                                 [dialogRef]="dialogRef"
                                 [dialogType]="dialogType"
                                 [lastPage]="lastPage"
                                 [entriesPerPage]="entriesPerPage"
                                 [order]="order"
                                 [orderedColumn]="orderedColumn"></app-person-form>
            </div>

        </div>
    </div>
</mat-dialog-content>
