<div id="animal-list-wrapper" class="not-printable">
    <div id="top-controls" class="d-flex justify-content-between">
        <div id="show-entries-container" *ngIf="showEntryCount">
            Zeige
            <select name="entries"
                    id="entries"
                    class="mr-1 ml-1"
                    (ngModelChange)="changeEntriesPerPage($event)"
                    [(ngModel)]="entriesPerPage">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            Einträge
        </div>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table
               [dataSource]="animals"
               (matSortChange)="!dataSourceFromAcquisitionGiveAway ? orderData($event) : ''"
               multiTemplateDataRows
               matSort
               class="mat-elevation-z0 extensible">
            <!-- Position Column -->
            <ng-container *ngFor="let def of tableDef">
                <ng-container [matColumnDef]="def.key">
                    <th [ngClass]="{ 'sortable-header': !dataSourceFromAcquisitionGiveAway ? columnSortable(def.key) : '' }"
                        mat-header-cell mat-sort-header
                        *matHeaderCellDef> {{def.header}} </th>
                    <td [ngClass]="def.className"
                        [class.reserved]="def.key === 'id' && element.reserved"
                        matTooltip="Reserviert"
                        [matTooltipDisabled]="def.key !== 'id' || !element.reserved"
                        matTooltipPosition="right"
                        matTooltipClass="tooltip-popup"
                        mat-cell
                        *matCellDef="let element"
                        [ngSwitch]="def.key">
                        <ng-container *ngSwitchCase="'id'">
                            {{element.id < 0 ? 'n.a.' : element.id}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'avatar'">
                            <div class="d-flex flex-row align-items-center justify-content-around">
                                <img [src]="avatarList[element.id]" class="mr-2" alt="avatar">
                                <div class="status-overview-wrapper">
                                    <div *ngIf="element.careSpace !== null && element.careSpace.dateBackToShelter === null">
                                        <i class="fas fa-user-nurse"
                                           style="font-size:16px"
                                           matTooltip="Auf Pflegeplatz"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></i>
                                    </div>
                                    <div *ngIf="element.type !== null && element.type.name === 'himmelfahrt'">
                                        <i class="fas fa-skull-crossbones"
                                           style="font-size:16px"
                                           matTooltip="Im Himmel"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></i>
                                    </div>
                                    <div *ngIf="element.hiddenOnWebsite"><i
                                            class="fas fa-eye-slash"
                                            matTooltip="Nicht auf Website anzeigen"
                                            matTooltipPosition="right"
                                            matTooltipClass="tooltip-popup"></i>
                                    </div>
                                    <div *ngIf="element.noGivingAway"><i class="fas fa-user-slash"
                                                                         matTooltip="Wird momentan nicht vergeben"
                                                                         matTooltipPosition="right"
                                                                         matTooltipClass="tooltip-popup"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'name'">
                            <div class="w-100 d-flex flex-column align-items-center justify-content-center"
                                 style="height: 58px;">
                                <div [class.mb-1]="element.type !== null">{{element[def.key] }}</div>
                                <div class="animal-type mr-0"
                                     *ngIf="element.type !== null"
                                     [style]="{background: element.type.color}"
                                     [matTooltip]='actionTypesService.getActionTypeText(element.type.name)'
                                     matTooltipPosition="right"
                                     matTooltipClass="tooltip-popup"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'dateCreated'">
                            {{element[def.key] | date:'dd.MM.yyyy HH:mm' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'gender'">
                            <ng-container *ngIf="element[def.key] === 'male'">
                                {{Gender.MALE.value}}
                            </ng-container>
                            <ng-container *ngIf="element[def.key] === 'female'">
                                {{Gender.FEMALE.value}}
                            </ng-container>
                            <ng-container *ngIf="element[def.key] === 'unknown'">
                                {{Gender.UNKNOWN.value}}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'castrated'">
                            {{ CastrationStatus.getViewValue(element[def.key]) }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'birthday'">
                            {{element[def.key] | date:'dd.MM.yyyy' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'species'">
                            {{element[def.key].name }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'race'">
                            {{element[def.key].name }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{element[def.key]}}
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                    <div class="control-wrapper d-flex align-items-center">
                        <a (click)="$event.stopPropagation(); openEditDialog(element, i)"
                           class="fas fa-edit icon prevent-opening"
                           matTooltip="Tierdaten bearbeiten"
                           matTooltipPosition="right"
                           matTooltipClass="tooltip-popup"></a>
                        <a (click)="$event.stopPropagation(); deleteAnimal(element)"
                           class="fas fa-trash-alt icon prevent-opening"
                           *ngIf="enableDelete"
                           matTooltip="Tier löschen"
                           matTooltipPosition="right"
                           matTooltipClass="tooltip-popup"></a>
                        <a class="fas fa-copy icon prevent-opening"
                           *ngIf="enableDuplicate"
                           (click)="$event.stopPropagation(); cloneAnimal(element)"
                           matTooltip="Tier duplizieren"
                           matTooltipPosition="right"
                           matTooltipClass="tooltip-popup"></a>
                        <a class="ml-2 remove-btn fas fa-minus prevent-opening"
                           *ngIf="entryRemovable"
                           (click)="$event.stopPropagation(); removeFromList(element.id, i)"
                           matTooltip="Eintrag entfernen"
                           matTooltipPosition="right"
                           matTooltipClass="tooltip-popup"></a>
                    </div>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
                    <div class="table-element-detail w-100"
                         [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
                         (@detailExpand.done)="animationDone(element !== expandedElement)">
                        <ng-container #quickEditContainer>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = dataIndex"
                class="table-row"
                [class.highlighted-row]="selectedRow(element, i)"
                [class.expanded-row]="expandedElement === element"
                (click)="clickedRow($event, element, expandedElement, i, expandedElement === element)">
            </tr>
            <ng-container *ngIf="enableEdit">
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </ng-container>
        </table>

        <div id="bottom-controls"
             *ngIf="showEntryCount"
             class="controls d-flex justify-content-between align-items-baseline">
            <div id="total-results">Gesamt: <span>{{totalAnimals}}</span></div>
            <mat-paginator [length]="totalAnimals"
                           [pageSize]="entriesPerPage"
                           (page)="switchPage($event)"
                           #paginator
                           showFirstLastButtons
                           hidePageSize>
            </mat-paginator>
        </div>

        <div class="default-entry mt-2" *ngIf="totalAnimals === 0 && loading === false">
            Keine Ergebnisse gefunden.
        </div>

        <div *ngIf="loading"
             style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner
                    color="accent"
                    mode="indeterminate">
            </mat-progress-spinner>
        </div>

    </div>
</div>

