import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService} from '../../http/endpoint.service';
import * as fromAutoCompleteActions from './auto-complete.actions';
import {LoggingService} from '../../../logging/logging.service';
import {handleHTTPError} from '../../../error-handling';

@Injectable()
export class AutoCompleteEffects {
    @Effect()
    loadAutoCompleteOptions$ = this.actions$.pipe(
        ofType(fromAutoCompleteActions.LOAD_AUTOCOMPLETE_OPTIONS),
        switchMap((autoCompleteState: fromAutoCompleteActions.LoadAutoCompleteOptions) => {
            const getParams = autoCompleteState.payload;
            return this.endpointService.autoComplete(getParams.type, getParams.field, getParams.value).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint).pipe(
                            map(response => response.data)
                        );
                }),
                map(response => {
                    return new fromAutoCompleteActions.SetAutoCompleteOptions(response);
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }


    private handleError = (errorRes: any): Observable<fromAutoCompleteActions.HttpFail> => {
        return handleHTTPError(errorRes, fromAutoCompleteActions, 'Autocomplete Optionen', this.loggingService);
    };

}
