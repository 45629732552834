<div class="row entry" [formGroup]="articleEntryForm">
    <div [ngClass]="displayRemarkInput ? 'col-md-2' : 'col-md-4'">
        <mat-form-field class="select-search">
            <mat-select id="article-select"
                        formControlName="searchArticle"
                        placeholder="Artikel"
                        (ngModelChange)="articleChanged($event)"
                        ngDefaultControl>
                <mat-option>
                    <ngx-mat-select-search
                            [formControl]="selectSearchArticle.filterCtrl">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option
                        *ngFor="let element of selectSearchArticle.filteredElements | async"
                        [value]="element.value">
                    {{element.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <mat-form-field class="text-input">
            <mat-label>Beschreibung</mat-label>
            <input matInput
                   placeholder="Beschreibung"
                   formControlName="newDescription">
            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-md-2" *ngIf="displayRemarkInput">
        <mat-form-field class="text-input">
            <mat-label>Bemerkung</mat-label>
            <input matInput
                   placeholder="Bemerkung"
                   formControlName="newRemark">
            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <mat-form-field class="text-input">
            <mat-label>Preis in €</mat-label>
            <input matInput
                   placeholder="Preis in €"
                   (keyup)="priceChange()"
                   formControlName="newPrice">
            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-md-1 remove-article d-flex justify-content-start" *ngIf="isIncomeComponent"
         style="margin-top: 20px">
        <mat-checkbox formControlName="newATM">
            Bankomat
        </mat-checkbox>
        <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
    </div>
    <div class="col-md-1 remove-article d-flex justify-content-start align-items-center"
         style="margin-top: 5px">
        <a class="ml-4 remove-btn fas fa-minus d-flex justify-content-center align-items-center"
           matTooltip="Artikel entfernen"
           matTooltipPosition="right"
           matTooltipClass="tooltip-popup"
           (click)="removeEntry()"></a>
    </div>
</div>
