import {ComponentFactoryResolver, ComponentRef, Type, ViewContainerRef} from '@angular/core';
import {CareSpacePrintingComponent} from './templates/care-space-printing/care-space-printing.component';
import {PrintableComponent} from './PrintableComponent';
import {DataSheetComponent} from './templates/data-sheet/data-sheet.component';
import {HandoverContractComponent} from './templates/handover-contract/handover-contract.component';
import {ChipContractComponent} from './templates/chip-contract/chip-contract.component';
import * as _ from 'lodash';
import {CashBookPrintingComponent} from './templates/cash-book-printing/cash-book-printing.component';
import {
    PrintingDataAction,
    PrintingDataAnimal,
    PrintingDataCashbook,
    PrintingIncomeOutcome,
    PrintingTemplate
} from './templates/TemplateTypes';
import {ActionSpecificPrintingComponent} from './templates/action-specific/action-specific-printing-component';
import {ActionType} from '../animals/store/action-types/action-types.service';
import {AbnahmePrintingComponent} from './templates/action-specific/abnahme-printing/abnahme-printing.component';
import {AnnahmePensionstierPrintingComponent} from './templates/action-specific/annahme-pensionstier-printing/annahme-pensionstier-printing.component';
import {AbgabePrintingComponent} from './templates/action-specific/abgabe-printing/abgabe-printing.component';
import {RueckgabePrintingComponent} from './templates/action-specific/rueckgabe-printing/rueckgabe-printing.component';
import {RueckgabePensionstierPrintingComponent} from './templates/action-specific/rueckgabe-pensionstier-printing/rueckgabe-pensionstier-printing.component';
import {FindlingPrintingComponent} from './templates/action-specific/findling-printing/findling-printing.component';
import {Shelter} from '../shared/constants';
import {IncomeOutcomePrintingComponent} from './templates/income-outcome-printing/income-outcome-printing.component';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';
import {SupervisionPrintingComponent} from './templates/supervision-printing/supervision-printing.component';

export class PrintComponentFactory {
    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private printingEntryContainer: ViewContainerRef,
                private data: PrintingDataAnimal | PrintingDataCashbook | PrintingDataAction | PrintingIncomeOutcome,
                private shelter: Shelter,
                private printDataSheets: boolean,
                private actionTypes?: ActionTypeEntryModel[]) {
    }

    public buildCareSpaceComponent(): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingDataAnimal;
        const animals = data.animals;
        const person = data.person;
        const resolver = this.componentFactoryResolver.resolveComponentFactory(CareSpacePrintingComponent);
        const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
        newPrintingComponent.instance.animals = animals;
        newPrintingComponent.instance.person = person;
        newPrintingComponent.instance.printingTemplate = PrintingTemplate.CareSpace;
        newPrintingComponent.instance.shelter = this.shelter;
        newPrintingComponent.instance.date = data.date;
        newPrintingComponent.changeDetectorRef.detectChanges();
        return [newPrintingComponent];
    }

    public buildAcquisitionAndGiveAwayComponents(): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingDataAnimal;
        const selectedActionType = data.actionType.name;
        const actionSpecificComponents =
            this.buildActionSpecificComponents(selectedActionType);

        const aggregatedComponents = [...actionSpecificComponents];

        if (selectedActionType === ActionType.RUECKGABE_PENSIONSTIER.key ||
            selectedActionType === ActionType.RUECKGABE.key ||
            selectedActionType === ActionType.VERGABE.key) {
            const chipContracts = this.buildChipContractComponents();
            aggregatedComponents.push(...chipContracts);
        } else {
            const dataSheetsComponents = this.buildDataSheetsComponents();
            aggregatedComponents.push(...dataSheetsComponents);
        }

        return aggregatedComponents;
    }

    public buildDataSheetsComponents(): ComponentRef<PrintableComponent>[] {
        if (!this.printDataSheets) {
            return;
        }
        const data = this.data as PrintingDataAnimal;
        const animals = data.animals;
        const newPrintingComponents: ComponentRef<DataSheetComponent>[] = [];
        animals.forEach(animal => {
            const resolver = this.componentFactoryResolver.resolveComponentFactory(DataSheetComponent);
            const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
            newPrintingComponent.instance.animal = animal;
            newPrintingComponent.instance.printingTemplate = PrintingTemplate.DataSheet;
            newPrintingComponent.instance.actionTypes = this.actionTypes;
            newPrintingComponent.instance.data = data;
            newPrintingComponent.instance.shelter = this.shelter;
            newPrintingComponent.changeDetectorRef.detectChanges();
            newPrintingComponents.push(newPrintingComponent);
        });
        return newPrintingComponents;
    }

    public buildActionSpecificComponents(selectedActionType: string): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingDataAnimal;
        const actionSpecificComponents = [];
        if (selectedActionType === ActionType.VERGABE.key) {
            const handOverComponent = this.buildHandOverComponents();
            actionSpecificComponents.push(...handOverComponent);
        } else if (selectedActionType === ActionType.FINDLING.key) {
            data.animals.forEach(animal => {
                const resolver = this.componentFactoryResolver.resolveComponentFactory(FindlingPrintingComponent);
                const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
                newPrintingComponent.instance.animals = animal;
                newPrintingComponent.instance.person = data.person;
                newPrintingComponent.instance.data = data;
                newPrintingComponent.instance.shelter = this.shelter;
                newPrintingComponent.instance.printingTemplate = PrintingTemplate.ActionSpecific;
                newPrintingComponent.changeDetectorRef.detectChanges();
                actionSpecificComponents.push(newPrintingComponent);
            });
        } else {
            let actionSpecificComponentType: Type<ActionSpecificPrintingComponent> = null;
            switch (selectedActionType) {
                case ActionType.ABGABE.key:
                    actionSpecificComponentType = AbgabePrintingComponent;
                    break;
                case ActionType.ABNAHME.key:
                    actionSpecificComponentType = AbnahmePrintingComponent;
                    break;
                case ActionType.ANNAHME_PENSIONSTIER.key:
                    actionSpecificComponentType = AnnahmePensionstierPrintingComponent;
                    break;
                case ActionType.RUECKGABE.key:
                    actionSpecificComponentType = RueckgabePrintingComponent;
                    break;
                case ActionType.RUECKGABE_PENSIONSTIER.key:
                    actionSpecificComponentType = RueckgabePensionstierPrintingComponent;
                    break;
            }

            if (actionSpecificComponentType) {
                const resolver = this.componentFactoryResolver.resolveComponentFactory(actionSpecificComponentType);
                const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
                newPrintingComponent.instance.animals = data.animals;
                newPrintingComponent.instance.person = data.person;
                newPrintingComponent.instance.data = data;
                newPrintingComponent.instance.shelter = this.shelter;
                newPrintingComponent.instance.printingTemplate = PrintingTemplate.ActionSpecific;
                newPrintingComponent.changeDetectorRef.detectChanges();
                actionSpecificComponents.push(newPrintingComponent);
            }
        }
        return actionSpecificComponents;
    }

    public buildSupervisionComponents(): ComponentRef<SupervisionPrintingComponent>[] {
        const data = this.data as PrintingDataAction;
        const supervisionSpecificComponents = [];
        data.actions.forEach((action, index) => {
            const resolver = this.componentFactoryResolver.resolveComponentFactory(SupervisionPrintingComponent);
            const supervisionComponent = this.printingEntryContainer.createComponent(resolver);
            supervisionComponent.instance.action = action;
            supervisionComponent.instance.supervision = data.supervisions[index];
            supervisionComponent.instance.hideHeader = false;
            supervisionComponent.changeDetectorRef.detectChanges();
            supervisionSpecificComponents.push(supervisionComponent);
        });
        return supervisionSpecificComponents;
    }

    public buildHandOverComponents(): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingDataAnimal;
        const grouped = _.chain(data.animals)
            .groupBy((animal) => {
                if (animal.species.name.toLowerCase() === 'hund') {
                    return 'hund';
                } else if (animal.species.name.toLowerCase() === 'katze') {
                    return 'katze';
                } else {
                    return 'kleintier';
                }
            })
            .map((value, key) => {
                return {species: key, animals: value};
            })
            .value();

        const printingComponents: ComponentRef<PrintableComponent>[] = [];
        grouped.forEach(group => {
            const resolver = this.componentFactoryResolver.resolveComponentFactory(HandoverContractComponent);
            const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
            newPrintingComponent.instance.species = group.species;
            newPrintingComponent.instance.animals = group.animals;
            newPrintingComponent.instance.person = data.person;
            newPrintingComponent.instance.owner = data.owner;
            newPrintingComponent.instance.hasFence = data.hasFence;
            newPrintingComponent.instance.hasGarden = data.hasGarden;
            newPrintingComponent.instance.residenceStreet = data.residenceStreet;
            newPrintingComponent.instance.residenceZip = data.residenceZip;
            newPrintingComponent.instance.residenceCity = data.residenceCity;
            newPrintingComponent.instance.otherAnimalsInHousehold = data.otherAnimalsInHousehold;
            newPrintingComponent.instance.animalPlace = data.animalPlace;
            newPrintingComponent.instance.date = data.date;
            newPrintingComponent.instance.printingTemplate = PrintingTemplate.HandOver;
            newPrintingComponent.instance.shelter = this.shelter;
            newPrintingComponent.changeDetectorRef.detectChanges();
            printingComponents.push(newPrintingComponent);
        });

        return printingComponents;
    }

    public buildChipContractComponents(): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingDataAnimal;
        const animals = data.animals;
        const newPrintingComponents: ComponentRef<ChipContractComponent>[] = [];
        animals
            .filter(a => a.species.name.toLowerCase() === 'hund')
            .forEach(animal => {
                const resolver = this.componentFactoryResolver.resolveComponentFactory(ChipContractComponent);
                const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
                newPrintingComponent.instance.animal = animal;
                newPrintingComponent.instance.printingTemplate = PrintingTemplate.ChipContract;
                newPrintingComponent.instance.shelter = this.shelter;
                newPrintingComponent.instance.animal = animal;
                newPrintingComponent.instance.data = data;
                newPrintingComponent.changeDetectorRef.detectChanges();
                newPrintingComponents.push(newPrintingComponent);
            });
        return newPrintingComponents;
    }

    public buildIncomeOutcomeComponent(isIncomeComponent: boolean): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingIncomeOutcome;
        const resolver = this.componentFactoryResolver.resolveComponentFactory(IncomeOutcomePrintingComponent);
        const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
        newPrintingComponent.instance.data = data;
        newPrintingComponent.instance.shelter = this.shelter;
        newPrintingComponent.instance.isIncomeComponent = isIncomeComponent;
        return [newPrintingComponent];
    }

    public buildCashbookComponent(): ComponentRef<PrintableComponent>[] {
        const data = this.data as PrintingDataCashbook;
        const resolver = this.componentFactoryResolver.resolveComponentFactory(CashBookPrintingComponent);
        const newPrintingComponent = this.printingEntryContainer.createComponent(resolver);
        newPrintingComponent.instance.data = data;
        return [newPrintingComponent];
    }
}
