<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Ähnliche vermisste Tiere
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="false"
         aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card mt-0">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="searchForm"
                          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
                          (ngSubmit)="search()">
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field class="text-input">
                                    <mat-label>Umkreis in km</mat-label>
                                    <input type="text"
                                           matInput
                                           placeholder="Umkreis in km"
                                           formControlName="radius">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 d-flex align-items-center ml-2 mt-2">
                                <button mat-raised-button color="primary" class="float-right">
                                    Suchen
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <app-missing [hideFilter]="true"
                                 [metrics]="metrics"
                                 [missingAnimals]="matchedAnimals"
                                 [loading]="false"
                                 [displayProbability]="true"
                                 [hideControls]="true"></app-missing>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
