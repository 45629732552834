import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {PrintingTemplate} from '../printing/templates/TemplateTypes';
import {ActionTypesService} from './store/action-types/action-types.service';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';
import {Subscription} from 'rxjs/Subscription';

@Component({
    selector: 'app-animals',
    templateUrl: './animals.component.html',
    styleUrls: ['./animals.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AnimalsComponent implements OnInit, OnDestroy {
    public PrintingTemplate = PrintingTemplate;
    public fetchedActionTypes: ActionTypeEntryModel[];

    private actionTypesSub: Subscription;

    constructor(private actionTypesService: ActionTypesService) {
    }

    ngOnInit(): void {
        this.actionTypesSub = this.actionTypesService.fetchActionTypes()
            .subscribe(actionTypes => {
                this.fetchedActionTypes = actionTypes;
            });
    }

    ngOnDestroy(): void {
        if (this.actionTypesSub) {
            this.actionTypesSub.unsubscribe();
        }
    }

}
