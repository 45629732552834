<div class="title-bar not-printable">
    <h3>Kontrollen</h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Übersicht</h4>
                </div>
                <div class="card-body">
                    <div class="supervision-entry" *ngFor="let transaction of transactions">
                        <div class="header d-flex justify-content-between align-items-start">
                            <div class="d-flex">
                                <h4 class="mb-0 headline">
                                    <span>Letzte Kontrolle: </span>
                                    <ng-container *ngIf="latestSupervision(transaction.supervisions) === null">
                                        <span>Noch nie kontrolliert</span>
                                    </ng-container>

                                    <ng-container *ngIf="latestSupervision(transaction.supervisions) !== null">
                                        <span style="color: #8b0000; font-weight: 500">{{latestSupervision(transaction.supervisions).datePerformed| date:'dd.MM.yyyy' }}</span>
                                        <span class="ml-4"
                                              style="font-size: 14px">Kontrolleur: {{latestSupervision(transaction.supervisions).supervisor}}</span>
                                    </ng-container>
                                </h4>
                            </div>
                            <div class="d-flex align-items-start">
                                <a class="fas fa-print icon mr-5" style="font-size:18px"
                                   matTooltip="Kontrollblatt drucken"
                                   (click)="print(transaction, latestSupervision(transaction.supervisions))"
                                   matTooltipPosition="right"
                                   matTooltipClass="tooltip-popup"></a>
                                <div>
                                    <div class="transaction-number">
                                        Transaktionsnummer: {{transaction.transactionId}}</div>
                                    <div>Vergabe: {{transaction.date | date:'dd.MM.yyyy' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="body">
                            <div class="row">
                                <div class="col-6">
                                    <h5><i class="fas fa-male mr-3"></i>Tierhalter</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="row person-number">
                                        <div class="col-md-5">Personennummer:</div>
                                        <div class="col">{{transaction.person.id}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Name:</div>
                                        <div class="col">{{transaction.person.lastName}} {{transaction.person.firstName}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Adresse:</div>
                                        <div class="col">{{transaction.person.street}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Ort:</div>
                                        <div class="col">{{transaction.person.zip}} - {{transaction.person.city}}</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-md-5">Geburtsdatum:</div>
                                        <div class="col">{{transaction.person.birthday | date:'dd.MM.yyyy' }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Telefonnummer:</div>
                                        <div class="col">{{transaction.person.phone}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Nachweis:</div>
                                        <div class="col">{{transaction.person.proof}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Aussteller:</div>
                                        <div class="col">{{transaction.person.issuer}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-6">
                                    <h5><i class="fas fa-paw mr-3"></i>Tierdaten</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="row laufnummer">
                                        <div class="col-md-5">Laufnummer:</div>
                                        <div class="col">{{transaction.animal.id}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Name:</div>
                                        <div class="col">{{transaction.animal.name}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Tierart:</div>
                                        <div class="col">{{transaction.animal.species.name}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">Rasse:</div>
                                        <div class="col">{{transaction.animal.race.name}}</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-5">Adresse des Tiers:</div>
                                        <div class="col" *ngIf="transaction.residenceStreet === null">Wie bei Person
                                        </div>
                                        <div class="col"
                                             *ngIf="transaction.residenceStreet !== null">{{transaction.residenceStreet}}
                                            — {{transaction.residenceCity}} {{transaction.residenceZip}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">Garten:</div>
                                        <div class="col">{{transaction.hasGarden === true ? 'ja' : 'nein'}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">Zaun:</div>
                                        <div class="col">{{transaction.hasFence === true ? 'ja' : 'nein'}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">Weitere Tiere im Haushalt:</div>
                                        <div class="col" *ngIf="transaction.otherAnimalsInHousehold === ''">Keine
                                        </div>
                                        <div class="col"
                                             *ngIf="transaction.otherAnimalsInHousehold !== ''">{{transaction.otherAnimalsInHousehold}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="latestSupervision(transaction.supervisions) !== null">
                                <div class="col-md-12">
                                    Report: {{latestSupervision(transaction.supervisions).report}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <button class="float-right mt-5 ml-4" mat-raised-button color="primary" (click)="print()">
                                Alle Kontrollen drucken
                            </button>
                        </div>
                    </div>

                    <div id="bottom-controls"
                         class="controls d-flex justify-content-between align-items-baseline">
                        <div id="total-results">Gesamt: <span>{{totalTransactions}}</span></div>
                        <mat-paginator [length]="totalTransactions"
                                       [pageSize]="entriesPerPage"
                                       (page)="switchPage($event)"
                                       [hidden]="!paginate"
                                       #paginator
                                       showFirstLastButtons
                                       hidePageSize>
                        </mat-paginator>
                    </div>

                    <div class="default-entry mt-2" *ngIf="totalTransactions === 0 && loading === false">
                        Keine Ergebnisse gefunden.
                    </div>

                    <div *ngIf="loading"
                         style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-spinner
                                color="accent"
                                mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>

                </div>
                <div class="card-body pt-0">
                    <app-supervision-filter></app-supervision-filter>
                </div>
            </div>
        </div>
    </div>
</div>

<app-printing [templateType]="PrintingTemplate.Supervision"></app-printing>
