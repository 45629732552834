import {FormControl} from '@angular/forms';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isArray} from 'rxjs/internal-compatibility';

export class SelectMultiSearch<T> {
    public multiFilterCtrl: FormControl = new FormControl();
    public filteredOptions: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
    public values: T[] = null;

    protected onDestroy = new Subject<void>();

    public init(values: Observable<T[]> | T[], predicate: (cur: T, next: T) => number = null): void {
        if (isArray(values)) {
            this.values = [];
            this.values = values;
            this.filteredOptions.next(values.slice());
            if (predicate !== null) {
                this.values = values.sort(predicate);
            }
        } else {
            values.pipe(
                takeUntil(this.onDestroy)
            ).subscribe(response => {
                this.values = [];
                this.filteredOptions.next(response.slice());
                if (predicate !== null) {
                    this.values = response.sort(predicate);
                }
            });
        }

    }

    public filterMulti(predicate: (cur: T, val: string) => boolean): void {
        if (!this.values) {
            return;
        }
        // get the search keyword
        let search = this.multiFilterCtrl.value;
        if (!search) {
            this.filteredOptions.next(this.values.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredOptions.next(
            this.values.filter(option => predicate(option, search))
        );
    }

    public valueChangeSubscribe(callback: (cur: T, result: string) => boolean): void {
        this.multiFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterMulti(callback);
            });
    }

    public reset(): void {
        this.filteredOptions = new ReplaySubject<T[]>(1);
        this.values = null;
    }

    public destroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
