import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})
export class DragDropDirective {
    @Output() public dragDropEmitter = new EventEmitter();

    @HostListener('dragover', ['$event']) onDragOver(event): void {
        event.preventDefault();
        this.dragDropEmitter.emit(event);
    }

    // --- Not working ---
    @HostListener('dragleave', ['$event'])
    public onDragLeave(event): void {
        this.dragDropEmitter.emit(event);
    }

    // --- Not working ---
    @HostListener('drop', ['$event'])
    public ondrop(event): void {
        event.preventDefault();
        this.dragDropEmitter.emit(event);
    }
}
