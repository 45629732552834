import {Action} from '@ngrx/store';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes, InvalidAPIRequest} from '../../../shared/data-types/error-types';

export const LOAD_ANIMALS = '[Animals] Load Animals';
export const SET_ANIMALS = '[Animals] Set Animals';
export const ADD_ANIMAL = '[Animals] Add Animal';
export const ADD_ANIMALS = '[Animals] Add Animals';
export const UPDATE_ANIMAL = '[Animals] Update Animal';
export const UPDATE_ANIMALS = '[Animals] Update Animals';
export const ADD_AND_UPDATE_ANIMALS = '[Animals] Add And Update Animals';
export const DELETE_ANIMAL = '[Animals] Delete Animal';
export const HTTP_FAIL = '[Animals] Http Fail';

export class LoadAnimals implements Action {
    readonly type = LOAD_ANIMALS;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        fetchAnimals?: boolean,
        animalFilter?: any,
        assignDataSource?: boolean,
        addedOrUpdatedAnimals?: AnimalEntryModel[],
        usePagination?: boolean,
        includeHistoricalTransactions?: boolean,
        invalidAPIRequests?: InvalidAPIRequest[]
    }) {
    }
}

export class SetAnimals implements Action {
    readonly type = SET_ANIMALS;

    constructor(public payload: {
        animals: AnimalEntryModel[],
        crud: CRUD,
        totalElements: number,
        lastPage: number,
        assignDataSource?: boolean,
        addedOrUpdatedAnimals?: AnimalEntryModel[],
        invalidAPIRequests?: InvalidAPIRequest[]
    }) {
    }
}

export class AddAnimal implements Action {
    readonly type = ADD_ANIMAL;

    constructor(public payload: {
        animal: AnimalEntryModel,
        assignedImageIds: number[],
        size: number,
        page: number,
        order: Order,
        column: string
    }) {
    }
}

export class AddAnimals implements Action {
    readonly type = ADD_ANIMALS;

    constructor(public payload: {
        animals: AnimalEntryModel[],
        size: number,
        page: number,
        order: Order,
        column: string,
    }) {
    }
}

export class UpdateAnimal implements Action {
    readonly type = UPDATE_ANIMAL;

    constructor(public payload: {
        animal: AnimalEntryModel,
        size: number,
        page: number,
        order: Order,
        column: string,
        animalFilter?: any,
        usePagination?: boolean,
    }) {
    }
}

export class UpdateAnimals implements Action {
    readonly type = UPDATE_ANIMALS;

    constructor(public payload: AnimalEntryModel[]) {
    }
}

export class AddAndUpdateAnimals implements Action {
    readonly type = ADD_AND_UPDATE_ANIMALS;

    constructor(public payload: {
        newAnimals: AnimalEntryModel[],
        updatedAnimals: AnimalEntryModel[],
        fetchAnimals: boolean
    }) {
    }
}

export class DeleteAnimal implements Action {
    readonly type = DELETE_ANIMAL;

    constructor(public payload: {
        id: number,
        size: number,
        page: number,
        order: Order,
        column: string,
        usePagination: boolean
    }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type AnimalActions = LoadAnimals |
    SetAnimals |
    AddAnimal |
    UpdateAnimal |
    AddAndUpdateAnimals |
    DeleteAnimal |
    HttpFail;
