import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AutoCompleteService} from '../../shared/services/auto-complete/auto-complete.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
    selector: 'app-persons-filter',
    templateUrl: './persons-filter.component.html',
    styleUrls: ['./persons-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class PersonsFilterComponent {
    @Input() public resettable = true;
    @Input() public exactSearch = false;
    @ViewChild('datePickerBirthday') public datePickerBirthday: any;
    @Output() public filterEvent: EventEmitter<any> = new EventEmitter<any>();
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public filteredPersonIds$: Observable<[number, string | number][]>;
    public filteredPersonLastNames$: Observable<[number, string | number][]>;
    public filteredPersonFirstNames$: Observable<[number, string | number][]>;
    public filteredPersonStreets$: Observable<[number, string | number][]>;
    public filteredPersonZips$: Observable<[number, string | number][]>;
    public filteredPersonCities$: Observable<[number, string | number][]>;

    private selectedPersonId = -1;

    constructor(private formBuilder: FormBuilder,
                private autocompleteService: AutoCompleteService) {
        this.initForm();
    }

    public filter(): void {
        if (!this.filterForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;
        let filterModel;

        if (this.exactSearch && this.filterForm.value.id === '' && this.selectedPersonId >= 0) {
            filterModel = {
                id: this.selectedPersonId
            };
        } else {
            const value = this.filterForm.value;
            filterModel = {
                id: value.id ? value.id : '',
                lastName: value.lastName ? value.lastName : '',
                firstName: value.firstName ? value.firstName : '',
                street: value.street ? value.street : '',
                zip: value.zip ? value.zip : '',
                city: value.city ? value.city : '',
                phone: value.phone ? value.phone : '',
                birthday: value.birthday ? value.birthday.format('YYYY-MM-DD') : ''
            };
        }

        this.filterEvent.emit(filterModel);
        this.selectedPersonId = -1;
    }

    public reset(): void {
        this.filterForm.reset();
    }

    public optionSelected($event: MatAutocompleteSelectedEvent, ctrl: string): void {
        this.selectedPersonId = $event.option.value[0];
        this.filterForm.controls[ctrl].setValue($event.option.value[1]);
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            id: ['', [Validators.maxLength(50), Validators.pattern('^[0-9]*$')]],
            lastName: ['', Validators.maxLength(50)],
            firstName: ['', Validators.maxLength(50)],
            street: ['', Validators.maxLength(50)],
            zip: ['', Validators.maxLength(50)],
            city: ['', Validators.maxLength(50)],
            phone: ['', Validators.maxLength(50)],
            birthday: ['', Validators.maxLength(50)]
        });

        this.initAutoComplete();
    }

    private initAutoComplete(): void {
        this.filteredPersonIds$ = this.filterForm.controls.id.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('person', 'id', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonLastNames$ = this.filterForm.controls.lastName.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('person', 'last_name', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonFirstNames$ = this.filterForm.controls.firstName.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('person', 'first_name', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonStreets$ = this.filterForm.controls.street.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('person', 'street', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonZips$ = this.filterForm.controls.zip.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('person', 'zip', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonCities$ = this.filterForm.controls.city.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('person', 'city', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );
    }
}
