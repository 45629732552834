import {Action} from '@ngrx/store';
import {SupervisionEntryModel} from '../../shared/models/supervision-entry.model';
import {CRUD} from '../../shared/services/http/crud';
import {ErrorTypes} from '../../shared/data-types/error-types';

export const LOAD_SUPERVISIONS = '[Supervisions] Load Supervisions';
export const SET_SUPERVISIONS = '[Supervisions] Set Supervisions';
export const ADD_SUPERVISION = '[Supervisions] Add Supervisions';
export const UPDATE_SUPERVISION = '[Supervisions] Update Supervisions';
export const DELETE_SUPERVISION = '[Supervisions] Delete Supervisions';
export const HTTP_FAIL = '[Supervisions] Http Fail';

export class LoadSupervisions implements Action {
    readonly type = LOAD_SUPERVISIONS;

    constructor(public payload: { crud: CRUD, animalId: number, page: number }) {
    }
}

export class SetSupervisions implements Action {
    readonly type = SET_SUPERVISIONS;

    constructor(public payload: { supervisions: SupervisionEntryModel[], crud: CRUD, totalElements: number, lastPage: number }) {
    }
}

export class AddSupervision implements Action {
    readonly type = ADD_SUPERVISION;

    constructor(public payload: { supervision: SupervisionEntryModel }) {
    }
}

export class UpdateSupervision implements Action {
    readonly type = UPDATE_SUPERVISION;

    constructor(public payload: { supervision: SupervisionEntryModel, page: number, animalId: number, }) {
    }
}

export class DeleteSupervision implements Action {
    readonly type = DELETE_SUPERVISION;

    constructor(public payload: { supervisionId: number, page: number, animalId: number }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type SupervisionsActions =
    LoadSupervisions
    | SetSupervisions
    | AddSupervision
    | UpdateSupervision
    | DeleteSupervision
    | HttpFail;

