import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {forkJoin, Observable} from 'rxjs';
import {CRUD} from '../../../shared/services/http/crud';
import {CareSpaceEntryModel} from '../../../shared/models/animal/care-space-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import {PersonConverter} from '../../../shared/converter/person-converter';
import * as fromCareSpaceActions from './care-space.actions';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class CareSpaceEffects {
    @Effect()
    loadCareSpaces$ = this.actions$.pipe(
        ofType(fromCareSpaceActions.LOAD_CARE_SPACES),
        switchMap((careSpaceState: fromCareSpaceActions.LoadCareSpaces) => {
            const page = careSpaceState.payload.page;
            const size = careSpaceState.payload.size;
            const order = careSpaceState.payload.order;
            const orderColumn = careSpaceState.payload.column;
            const careSpaceFilter = careSpaceState.payload.filter;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (careSpaceFilter) {
                params = params.append('filter', 'true');

                params = typeof careSpaceFilter.animalId !== 'undefined' && careSpaceFilter.animalId !== '' ?
                    params.append('animal_id', careSpaceFilter.animalId) : params;
                params = typeof careSpaceFilter.personId !== 'undefined' && careSpaceFilter.personId !== '' ?
                    params.append('person_id', careSpaceFilter.personId) : params;
            }
            return this.endpointService.careSpaces(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params});
                }),
                map(response => {
                    const mappedCareSpaceArr: CareSpaceEntryModel[] = response.data.map(careSpace => {
                        const mappedCareSpace: CareSpaceEntryModel = {
                            id: careSpace.id,
                            animalId: careSpace.animal_id,
                            created: careSpace.date_performed,
                            person: PersonConverter.toPersonEntryModel(careSpace.person),
                            dateBackToShelter: careSpace.date_back_to_shelter
                        };
                        return mappedCareSpace;
                    });

                    return new fromCareSpaceActions.SetCareSpaces({
                        careSpaces: mappedCareSpaceArr,
                        operation: careSpaceState.payload.crud
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addCareSpace$ = this.actions$.pipe(
        ofType(fromCareSpaceActions.ADD_CARE_SPACE),
        switchMap((careSpaceState: fromCareSpaceActions.AddCareSpace) => {
            return this.endpointService.careSpaces(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                animal_id: careSpaceState.payload.animalId,
                                person_id: careSpaceState.payload.person.id,
                                date_performed: careSpaceState.payload.created
                            }
                        ).pipe(
                            map((careSpaceResponse: any) => {
                                const mappedCareSpace: CareSpaceEntryModel = {
                                    id: careSpaceResponse.data.id,
                                    animalId: careSpaceResponse.data.animal_id,
                                    person: careSpaceResponse.data.person,
                                    created: careSpaceResponse.data.date_performed,
                                    dateBackToShelter: careSpaceResponse.data.date_back_to_shelter
                                };

                                return new fromCareSpaceActions.LoadCareSpaces({
                                    crud: CRUD.CREATE,
                                    size: -1,
                                    page: -1,
                                    order: Order.NONE,
                                    column: '',
                                    newCareSpaces: [mappedCareSpace]
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    addCareSpaces$ = this.actions$.pipe(
        ofType(fromCareSpaceActions.ADD_CARE_SPACES),
        switchMap((careSpaceState: fromCareSpaceActions.AddCareSpaces) => {
            return this.endpointService.careSpaces(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    const careSpaceObs$ = [];
                    careSpaceState.payload.careSpaces.forEach(careSpace => {
                        const addCareSpace$ = this.http
                            .post<any>(endpoint, {
                                animal_id: careSpace.animalId,
                                person_id: careSpace.person.id,
                                date_performed: careSpace.created,
                                date_back_to_shelter: careSpace.dateBackToShelter
                            });
                        careSpaceObs$.push(addCareSpace$);
                    });

                    return forkJoin(careSpaceObs$).pipe(
                        take(1),
                        map((response) => {
                            const newCareSpaces = response.map((entry: any) => {
                                const mappedCareSpace: CareSpaceEntryModel = {
                                    id: entry.data.id,
                                    animalId: entry.data.animal_id,
                                    person: entry.data.person,
                                    created: entry.data.date_performed,
                                    dateBackToShelter: entry.date_back_to_shelter
                                };
                                return mappedCareSpace;
                            });
                            return new fromCareSpaceActions.LoadCareSpaces({
                                crud: CRUD.CREATE,
                                size: careSpaceState.payload.size,
                                page: careSpaceState.payload.page,
                                order: careSpaceState.payload.order,
                                column: careSpaceState.payload.column,
                                filter: careSpaceState.payload.filter,
                                newCareSpaces
                            });
                        }),
                        catchError(error => {
                            return this.handleError(error);
                        })
                    );
                })
            );
        })
    );

    @Effect()
    updateCareSpace$ = this.actions$.pipe(
        ofType(fromCareSpaceActions.UPDATE_CARE_SPACE),
        switchMap((careSpaceState: fromCareSpaceActions.UpdateCareSpace) => {
            return this.endpointService.careSpaces(HttpMethod.PUT, careSpaceState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    const updateCareSpaceRequest$ = this.http
                        .put<any>(endpoint, {
                            animal_id: careSpaceState.payload.animalId,
                            person_id: careSpaceState.payload.person.id,
                            date_performed: careSpaceState.payload.created,
                            date_back_to_shelter: careSpaceState.payload.dateBackToShelter
                        });

                    return updateCareSpaceRequest$.pipe(
                        take(1),
                        map(() => {
                            return new fromCareSpaceActions.LoadCareSpaces({
                                crud: CRUD.UPDATE,
                                size: -1,
                                page: -1,
                                order: Order.NONE,
                                column: '',
                            });
                        }),
                        catchError(error => {
                            return this.handleError(error);
                        })
                    );
                })
            );
        })
    );

    @Effect()
    updateCareSpaces$ = this.actions$.pipe(
        ofType(fromCareSpaceActions.UPDATE_CARE_SPACES),
        switchMap((careSpaceState: fromCareSpaceActions.UpdateCareSpaces) => {
            const updateObsArr$ = [];
            careSpaceState.payload.careSpaces.forEach(careSpace => {
                const obs$ = this.endpointService.careSpaces(HttpMethod.PUT, careSpace.id).pipe(
                    take(1),
                    mergeMap(endpoint => {
                        return this.http
                            .put<any>(endpoint, {
                                animal_id: careSpace.animalId,
                                person_id: careSpace.person.id,
                                date_performed: careSpace.created,
                                date_back_to_shelter: careSpace.dateBackToShelter
                            });
                    })
                );
                updateObsArr$.push(obs$);
            });

            return forkJoin(updateObsArr$).pipe(
                take(1),
                map(() => {
                    return new fromCareSpaceActions.LoadCareSpaces({
                        crud: CRUD.UPDATE,
                        order: Order.NONE,
                        column: '',
                        size: -1,
                        page: 1,
                        filter: careSpaceState.payload.filter
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromCareSpaceActions.HttpFail> => {
        return handleHTTPError(errorRes, fromCareSpaceActions, 'Pflegeplätzen', this.loggingService);
    };

}
