import {ActionReducerMap} from '@ngrx/store';
import * as fromAnimals from '../animals/store/animals/animal.reducer';
import * as fromPersons from '../persons/store/persons.reducer';
import * as fromSpecies from '../animals/store/species/species.reducer';
import * as fromRaces from '../animals/store/races/race.reducer';
import * as fromFDRaces from '../animals/store/fd-races/fd-race.reducer';
import * as fromAttributes from '../attributes/store/attributes/attributes.reducer';
import * as fromOptions from '../attributes/store/options/options.reducer';
import * as fromArticles from '../pos-system/store/articles/articles.reducer';
import * as fromCategories from '../pos-system/store/categories/categories.reducer';
import * as fromBookings from '../pos-system/store/booking/booking.reducer';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromVeterinaryTreatments from '../veterinary-treatments/store/veterinary-treatments/veterinary-treatments.reducer';
import * as fromFormerTreatments from '../veterinary-treatments/store/former-treatments/former-treatments.reducer';
import * as fromUsers from '../user-management/store/manage-users/users.reducer';
import * as fromAccommodations from '../animals/store/accommodation/accommodation.reducer';
import * as fromActionTypes from '../animals/store/action-types/action-types.reducer';
import * as fromCharacteristics from '../animals/store/characteristics/characteristic.reducer';
import * as fromMedia from '../media-centre/store/media.reducer';
import * as fromActions from '../actions/store/actions.reducer';
import * as fromSupervisions from '../supervision/store/supervision.reducer';
import * as fromAutoComplete from '../shared/services/auto-complete/store/auto-complete.reducer';
import * as fromCareSpace from '../animals/store/care-space/care-space.reducer';
import * as fromLogging from '../shared/logging/store/logging.reducer';
import * as fromMissingAnimals from '../animals/store/missing/missing.reducer';
import * as fromZipCities from '../shared/services/zip-city-search/store/zip-city.reducer';
import * as fromOccupations from '../animals/store/statistics/occupation/occupation.reducer';

export interface AppState {
    auth: fromAuth.State;
    animalList: fromAnimals.State;
    speciesList: fromSpecies.State;
    raceList: fromRaces.State;
    fdRaceList: fromFDRaces.State;
    attributeList: fromAttributes.State;
    optionList: fromOptions.State;
    articleList: fromArticles.State;
    categoryList: fromCategories.State;
    personList: fromPersons.State;
    bookingList: fromBookings.State;
    veterinaryTreatmentList: fromVeterinaryTreatments.State;
    formerTreatmentList: fromFormerTreatments.State;
    usersList: fromUsers.State;
    accommodationList: fromAccommodations.State;
    actionTypeList: fromActionTypes.State;
    characteristicList: fromCharacteristics.State;
    mediaList: fromMedia.State;
    actionList: fromActions.State;
    supervisionList: fromSupervisions.State;
    autoCompleteList: fromAutoComplete.State;
    careSpaceList: fromCareSpace.State;
    loggingList: fromLogging.State;
    missingAnimalList: fromMissingAnimals.State;
    zipCityList: fromZipCities.State;
    occupationList: fromOccupations.State;
}

export const appReducer: ActionReducerMap<AppState> = {
    auth: fromAuth.authReducer,
    animalList: fromAnimals.animalReducer,
    speciesList: fromSpecies.speciesReducer,
    articleList: fromArticles.articlesReducer,
    categoryList: fromCategories.categoriesReducer,
    raceList: fromRaces.racesReducer,
    fdRaceList: fromFDRaces.racesReducer,
    attributeList: fromAttributes.attributesReducer,
    optionList: fromOptions.optionsReducer,
    personList: fromPersons.personsReducer,
    bookingList: fromBookings.bookingReducer,
    veterinaryTreatmentList: fromVeterinaryTreatments.veterinaryTreatmentsReducer,
    formerTreatmentList: fromFormerTreatments.formerTreatmentsReducer,
    usersList: fromUsers.usersReducer,
    accommodationList: fromAccommodations.accommodationReducer,
    actionTypeList: fromActionTypes.actionTypeReducer,
    characteristicList: fromCharacteristics.characteristicReducer,
    mediaList: fromMedia.mediaReducer,
    actionList: fromActions.actionsReducer,
    supervisionList: fromSupervisions.supervisionsReducer,
    autoCompleteList: fromAutoComplete.autoCompleteReducer,
    careSpaceList: fromCareSpace.careSpaceReducer,
    loggingList: fromLogging.loggingReducer,
    missingAnimalList: fromMissingAnimals.missingAnimalReducer,
    zipCityList: fromZipCities.zipCityReducer,
    occupationList: fromOccupations.occupationReducer
};

