import {Component, OnInit} from '@angular/core';
import {PrintingDataAnimal, PrintingTemplate} from '../../TemplateTypes';
import {ActionSpecificPrintingComponent} from '../action-specific-printing-component';
import {AnimalEntryModel} from '../../../../shared/models/animal/animal-entry.model';
import {PersonEntryModel} from '../../../../shared/models/person-entry.model';
import {Age, CastrationStatus, Constants, Gender, Shelter} from '../../../../shared/constants';
import {CharacteristicService} from '../../../../animals/store/characteristics/characteristic.service';
import * as moment from 'moment';

@Component({
    selector: 'app-rueckgabe-pensionstier-printing',
    templateUrl: './rueckgabe-pensionstier-printing.component.html',
    styleUrls: ['./rueckgabe-pensionstier-printing.component.scss']
})
export class RueckgabePensionstierPrintingComponent implements ActionSpecificPrintingComponent, OnInit {
    public hideHeader = false;
    public printingTemplate = PrintingTemplate.ActionSpecific;
    public visible = false;
    public data: PrintingDataAnimal;
    public animals: AnimalEntryModel[];
    public person: PersonEntryModel;
    public shelter: Shelter;
    public Shelter = Shelter;
    public Constants = Constants;
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public Age = Age;

    public owner: PersonEntryModel;
    public cityName = '';
    public shelterName = '';
    public costs = '';
    public additionalCostsTotal = '';
    public depositTotal = '';
    public total = '';

    constructor(private characteristicsService: CharacteristicService) {
    }

    ngOnInit(): void {
        if (this.shelter === Shelter.LINZ) {
            this.cityName = Constants.LinzCityName;
            this.shelterName = Constants.LinzShelterName;
        } else {
            this.cityName = Constants.SteyrCityName;
            this.shelterName = Constants.SteyrShelterName;
        }

        if (this.data.owner && typeof this.data.owner !== 'undefined') {
            this.owner = this.data.owner;
        } else {
            this.owner = this.person;
        }

        let days = 0;
        const dStayFrom = moment(this.data.stayFrom);
        const dStayUntil = moment(this.data.stayUntil);
        const from = dStayFrom !== null ? dStayFrom.startOf('day') : null;
        const until = dStayUntil !== null ? dStayUntil.startOf('day') : null;
        if (from && until) {
            days = until.diff(from, 'days') + 1;
        }

        const costs = (days * this.data.costsPerDayAccumulated);
        const additionalCostsTotal = this.data.additionalCostsAccumulated;
        const depositTotal = this.data.depositTotal;
        this.total = (costs + additionalCostsTotal - depositTotal).toString();
        this.costs = costs.toString();
        this.additionalCostsTotal = additionalCostsTotal.toString();
        this.depositTotal = depositTotal.toString();

    }
}
