import {Injectable, OnDestroy} from '@angular/core';
import {map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, of, Subject} from 'rxjs';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {Store} from '@ngrx/store';
import {SelectBoxService} from '../../../shared/controls/select-box/select-box.service';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import {MatOption} from '@angular/material/core';
import {DialogType} from '../../../dialogs/dialog-type';
import {Order} from '../../../shared/controls/data-table/ordering';
import {AbstractControl} from '@angular/forms';
import * as fromApp from '../../../store/app.reducer';
import * as fromSpeciesActions from '../../../animals/store/species/species.actions';
import * as fromAnimalActions from '../animals/animal.actions';
import {ChipBar} from '../../../shared/controls/chip-bar';
import {LoadingService} from '../../../shared/services/loading/loading.service';

@Injectable({providedIn: 'root'})
export class SpeciesService implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private simpleDialogType = new DialogType();
    private errorEmerged = false;

    constructor(private store: Store<fromApp.AppState>,
                private loadingService: LoadingService,
                private actions$: Actions,
                private snackbarService: SnackbarService,
                private selectBoxService: SelectBoxService) {
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public fetchSpecies(forceFetch = false): Observable<DropdownOption[]> {
        return this.store.select('speciesList').pipe(
            map(speciesState => {
                return speciesState.species;
            }),
            switchMap(species => {
                if (species.length === 0 || forceFetch) {
                    this.store.dispatch(new fromSpeciesActions.LoadSpecies(CRUD.READ));
                    return this.actions$.pipe(
                        ofType(fromSpeciesActions.SET_SPECIES),
                        map((speciesState: fromSpeciesActions.SetSpecies) => {
                            return speciesState.payload;
                        })
                    );
                } else {
                    return of(species);
                }
            }),
            map((species: DictionaryEntryModel[]) => {
                return species
                    .map((element: DictionaryEntryModel) => {
                        return {
                            value: SelectBoxUtility.getOptionsValue(element.name),
                            viewValue: element.name,
                            meta: [{key: 'id', value: element.id.toString()}]
                        } as DropdownOption;
                    })
                    .sort(this.selectBoxService.sortCallback);
            }),
            takeUntil(this.destroy$)
        );
    }

    public addSpecies(event: MouseEvent, chipBar: ChipBar, singleSelectSpecies: AbstractControl): void {
        event.stopPropagation();
        const dialogRef = this.selectBoxService.openAddDialog('Tierart');

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== false) {
                const newModel: DictionaryEntryModel = {
                    id: -1,
                    name: result.name
                };

                this.store.dispatch(new fromSpeciesActions.AddSpecies(newModel));
                this.actions$.pipe(
                    ofType(fromSpeciesActions.SET_SPECIES),
                    take(1)
                ).subscribe((speciesState: fromSpeciesActions.SetSpecies) => {
                    if (speciesState.payload.operation === CRUD.CREATE) {
                        chipBar.setValues([]);
                        singleSelectSpecies.setValue(SelectBoxUtility.getOptionsValue(result.name));
                        setTimeout(() => this.handleRequestSuccess(CRUD.CREATE, 'Tierart', result.name));
                    }
                });
            }
        });
    }

    public updateSpecies(event: MouseEvent,
                         matOption: MatOption,
                         species: DropdownOption,
                         updatedSpecies: HTMLInputElement,
                         pageIndex: number,
                         entriesPerPage: number,
                         order: Order,
                         orderedColumn: string,
                         speciesControl: AbstractControl): void {
        event.stopPropagation();

        if (updatedSpecies.value === '' || updatedSpecies.value === species.viewValue) {
            updatedSpecies.value = species.viewValue;
            this.selectBoxService.disableEditMode(matOption);
            return;
        }

        const updatedModel: DictionaryEntryModel = {
            id: +species.meta.find(tuple => tuple.key === 'id').value,
            name: updatedSpecies.value
        };
        this.selectBoxService.update(matOption, new fromSpeciesActions.UpdateSpecies(updatedModel));
        this.actions$.pipe(
            ofType(fromSpeciesActions.SET_SPECIES),
            take(1)
        ).subscribe(() => {
            if (!this.errorEmerged && speciesControl) {
                speciesControl.setValue(SelectBoxUtility.getOptionsValue(updatedSpecies.value));
                this.store.dispatch(new fromAnimalActions.LoadAnimals({
                    crud: CRUD.READ,
                    page: pageIndex,
                    size: entriesPerPage,
                    order,
                    column: orderedColumn,
                    usePagination: true
                }));
                setTimeout(() => this.handleRequestSuccess(CRUD.UPDATE, 'Tierart', updatedSpecies.value));
            }
            this.errorEmerged = false;
        });
    }

    public deleteSpecies(event: MouseEvent,
                         species: DropdownOption,
                         allSpecies: DropdownOption[],
                         allRaces: DropdownOption[],
                         pageIndex: number,
                         entriesPerPage: number,
                         order: Order,
                         orderedColumn: string,
                         speciesControl: AbstractControl,
                         raceControl: AbstractControl): void {
        event.stopPropagation();

        const remainingOptions = allSpecies.filter(option => option.viewValue !== species.viewValue);
        const dialogRef = this.selectBoxService.delete('Tierart',
            species.viewValue,
            this.simpleDialogType.DELETE_SPECIES,
            remainingOptions,
            allRaces);

        dialogRef.afterClosed().subscribe(result => {
            const newSpecies = result.newSpeciesOrRace;
            const newRace = result.filteredRaces;
            if (typeof newSpecies !== 'undefined' &&
                newSpecies !== false &&
                typeof newRace !== 'undefined' &&
                newRace !== false) {
                const updateRaceId = +newRace.meta.find(tuple => tuple.key === 'id').value;
                const updateSpeciesId = +newSpecies.meta.find(tuple => tuple.key === 'id').value;
                const deleteSpeciesId = +species.meta.find(tuple => tuple.key === 'id').value;

                this.loadingService.showLoadingScreen();
                this.store.dispatch(new fromSpeciesActions.DeleteSpecies({
                    deleteSpeciesId,
                    updateSpeciesId,
                    updateRaceId
                }));

                this.actions$.pipe(
                    ofType(fromSpeciesActions.SET_SPECIES),
                    take(1),
                    tap(() => {
                        return this.store.dispatch(new fromSpeciesActions.LoadSpecies(CRUD.READ));
                    })
                ).subscribe(() => {
                    this.loadingService.hideLoadingScreen();
                    if (!this.errorEmerged && speciesControl) {
                        speciesControl.setValue(SelectBoxUtility.getOptionsValue(newSpecies.value));
                        raceControl.setValue(SelectBoxUtility.getOptionsValue(newRace.value));
                        this.store.dispatch(new fromAnimalActions.LoadAnimals({
                            crud: CRUD.READ,
                            page: pageIndex,
                            size: entriesPerPage,
                            order,
                            column: orderedColumn,
                            usePagination: true
                        }));
                        setTimeout(() => this.handleRequestSuccess(CRUD.DELETE, 'Tierart', species.viewValue));
                    }
                    this.errorEmerged = false;
                });
            }
        });
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.errorEmerged = true;
        this.store.dispatch(new fromSpeciesActions.LoadSpecies(CRUD.READ));
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
