import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ArticleEntryModel} from '../../shared/models/pos/article-entry.model';
import {DataTable} from '../../shared/data-types/data-table';
import {SelectMultiSearch} from '../../shared/controls/select-box/select-multi-search';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogType} from '../../dialogs/dialog-type';
import {Observable, Subscription} from 'rxjs';
import {Actions, ofType} from '@ngrx/effects';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PosSystemService} from '../pos-system.service';
import {SelectBoxUtility} from '../../shared/controls/select-box/select-box-utility';
import {SelectBoxService} from '../../shared/controls/select-box/select-box.service';
import {CRUD} from '../../shared/services/http/crud';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Order} from '../../shared/controls/data-table/ordering';
import {tap} from 'rxjs/internal/operators/tap';
import {Constants} from '../../shared/constants';
import {TupleHelper} from '../../shared/data-types/tuple';
import * as fromArticlesActions from '../store/articles/articles.actions';
import * as fromCategoriesActions from '../store/categories/catgories.actions';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['./article-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class ArticleListComponent implements OnInit, OnDestroy {
    public static categoriesWithTwoInputs = [
        Constants.Donation,
        Constants.GodParenthood,
        Constants.AnimalPension,
        Constants.IncomingInvoice
    ];

    @ViewChild('paginator') public paginator: MatPaginator;
    public selectSearchPOSCategory = new SelectMultiSearch<DropdownOption>();
    public addNewArticle = false;
    public articles$: Observable<unknown>;
    public displayedColumns: string[] = [
        'id',
        'category',
        'name',
        'price',
        'controls'
    ];
    public expandedElement: ArticleEntryModel | null;
    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'Art. Nr.',
            className: 'article-number'
        }, {
            key: 'category',
            header: 'Kategorie',
            className: 'category'
        }, {
            key: 'name',
            header: 'Name',
            className: 'name'
        }, {
            key: 'price',
            header: 'Kosten',
            className: 'price'
        }
    ];
    public loading?: boolean = null;
    public totalArticles = 0;

    // Additional 'filter' column list
    public displayedColumnNewArticle: string[] = [
        'new-id',
        'new-category',
        'new-name',
        'new-price',
        'new-control'
    ];

    public newArticleForm: FormGroup;
    public displayErroneousInputs = false;
    public simpleDialogType = new DialogType();
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.NONE;
    public orderedColumn = '';

    protected static articleIdentifier = '';
    protected addRequestSent = false;

    private articleSub: Subscription;
    private lastPage = 1;

    constructor(public selectBoxService: SelectBoxService,
                public posSystemService: PosSystemService,
                protected actions$: Actions,
                private dialog: MatDialog,
                private authService: AuthService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.initArticleTable();
        this.initSelectBox();
        this.initForm();
        this.initErrorHandling();
    }

    ngOnDestroy(): void {
        this.selectSearchPOSCategory.destroy();
        if (this.articleSub) {
            this.articleSub.unsubscribe();
        }
    }

    public addTableRow(): void {
        this.addNewArticle = true;
    }

    public canExpand($event: MouseEvent, element: any, expandedElement: ArticleEntryModel): any {
        return DataTable.canExpand($event, element, expandedElement);
    }

    public saveArticle(): void {
        this.addRequestSent = true;

        if (!this.newArticleForm.valid) {
            this.addRequestSent = false;
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;
        const price = this.newArticleForm.value.newPrice.replace(/,/g, '.');
        const name = this.newArticleForm.value.newName;

        const newArticle: ArticleEntryModel = {
            id: -1, // Dummy -> Is not sent to the server
            category: {
                id: +TupleHelper.getValue(this.newArticleForm.value.newCategory.meta, 'id'),
                name: this.newArticleForm.value.newCategory.viewValue
            },
            name,
            price
        };

        this.posSystemService.addArticle(newArticle, this.lastPage, this.entriesPerPage, this.order, this.orderedColumn);
        ArticleListComponent.articleIdentifier = name;
    }

    public delete(element: ArticleEntryModel): any {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Artikel',
                identifier: element.name
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.posSystemService.deleteArticle(element.id, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
                ArticleListComponent.articleIdentifier = element.name;
            }
        });
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.articles$ = this.posSystemService.fetchArticles(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.articles$ = this.posSystemService.fetchArticles(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
        this.paginator.firstPage();
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active;
        this.articles$ = this.posSystemService.fetchArticles(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
    }

    protected lockedCategory(category: string): boolean {
        return ArticleListComponent.categoriesWithTwoInputs.includes(category);
    }

    protected initForm(article?: ArticleEntryModel): void {
        let name = '';
        let price = '';
        let categoryName = '';

        if (typeof article !== 'undefined') {
            name = article.name;
            price = article.price.toString().replace(/\./g, ',');
            categoryName = SelectBoxUtility.getOptionsValue(article.category.name);
        }

        this.newArticleForm = this.formBuilder.group({
            newName: [name, Validators.required],
            newPrice: [
                price,
                [
                    Validators.required,
                    Validators.pattern(/^[+-]?[0-9]+(,[0-9]{1,2})?$/)
                ]
            ],
            newCategory: [categoryName, Validators.required]
        });
    }

    protected initSelectBox(): void {
        this.posSystemService.initCategorySelectBox(this.selectSearchPOSCategory);
    }

    private initArticleTable(): void {
        this.loading = true;
        this.articles$ = this.posSystemService.fetchArticles(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);

        const articlesHttp$ = this.actions$.pipe(
            ofType(fromArticlesActions.SET_ARTICLES),
            tap((articleState: fromArticlesActions.SetArticles) => {
                const crudOperation = articleState.payload.crud;
                this.totalArticles = articleState.payload.totalElements;
                this.lastPage = articleState.payload.lastPage;

                if (crudOperation === CRUD.READ ||
                    crudOperation === CRUD.NONE) {
                    return;
                }

                if (crudOperation === CRUD.CREATE) {
                    this.paginator.length = this.totalArticles;
                    this.paginator.lastPage();
                    this.newArticleForm.reset();
                }

                this.posSystemService.handleRequestSuccess(crudOperation, 'Artikel', ArticleListComponent.articleIdentifier);
            })
        );

        this.articleSub = articlesHttp$
            .subscribe(() => {
                this.loading = false;
                this.addRequestSent = false;
                this.addNewArticle = false;
            });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromArticlesActions.HTTP_FAIL, fromCategoriesActions.HTTP_FAIL)
        ).subscribe((httpFail: fromArticlesActions.HttpFail | fromCategoriesActions.HttpFail) => {
            this.posSystemService.handleRequestError(httpFail.payload.message);
            this.loading = false;
            this.addRequestSent = false;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }
}
