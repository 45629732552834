<div class="mat-chip mat-chip-select-option mat-standard-chip mat-chip-with-trailing-icon"
     #chip>
    <div class="option d-flex justify-content-between"
         [attr.data-value]="SelectBoxUtility.getOptionsValue(option.name)">
        <div class="text-wrapper">
            <div class="text-element">{{option.name}}</div>
            <input class="d-none"
                   matInput
                   #updatedVal
                   (click)="$event.stopPropagation()"
                   [value]="option.name"
                   (keydown.enter)="updateOption(updatedVal)"
                   (keydown.space)="$event.stopPropagation()" />
        </div>
        <div class="controls d-flex align-items-center">
            <a class="fas fa-edit icon"
               (click)="chipListService.activateEditMode($event, chip)"
               matTooltip="Option bearbeiten"
               matTooltipPosition="right"
               matTooltipClass="tooltip-popup"></a>
            <a class="fas fa-check icon d-none"
               (click)="updateOption(updatedVal)"
               matTooltip="Änderungen speichern"
               matTooltipPosition="right"
               matTooltipClass="tooltip-popup"></a>
            <a class=" fas fa-trash icon"
               (click)="deleteOption(option)"
               matTooltip="Option löschen"
               matTooltipPosition="right"
               matTooltipClass="tooltip-popup"></a>
        </div>
    </div>
</div>
<ng-content></ng-content>
