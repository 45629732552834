import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {Order} from '../shared/controls/data-table/ordering';
import {TransactionEntryModel} from '../shared/models/transaction-entry.model';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {ActionType, ActionTypesService} from '../animals/store/action-types/action-types.service';
import {AnimalEntryModel} from '../shared/models/animal/animal-entry.model';
import {AnimalsService} from '../animals/animals.service';
import * as fromApp from '../store/app.reducer';
import * as fromActionsActions from './store/actions.actions';
import * as moment from 'moment';
import {ShelterStatus} from '../shared/constants';

@Injectable()
export class ActionsService implements OnDestroy {
    public filterEvent = new BehaviorSubject(null);

    private animalSub: Subscription;

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private actionTypesService: ActionTypesService,
                private animalService: AnimalsService,
                private snackbarService: SnackbarService) {
    }

    ngOnDestroy(): void {
        if (this.animalSub) {
            this.animalSub.unsubscribe();
        }
    }

    public fetchActions(size = -1,
                        page = -1,
                        order = Order.NONE,
                        column = '',
                        filterModel?: any,
                        usePagination = true,
                        refreshTable = true):
        Observable<TransactionEntryModel[]> {
        return this.store.select('actionList').pipe(
            take(1),
            map(actionState => {
                return actionState.actions;
            }),
            switchMap(actions => {
                if (actions.length === 0 || page >= 0) {
                    this.store.dispatch(new fromActionsActions.LoadActions({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column,
                        actionFilter: filterModel,
                        usePagination,
                        refreshTable
                    }));
                    return this.actions$.pipe(
                        ofType(fromActionsActions.SET_ACTIONS),
                        take(1),
                        map((actionState: fromActionsActions.SetActions) => {
                            return actionState.payload.actions;
                        })
                    );
                } else {
                    return of(actions);
                }
            })
        );
    }

    public addActions(transactionModels: TransactionEntryModel[],
                      size: number,
                      page: number,
                      order: Order,
                      column: string,
                      fetchActions = true): void {
        this.store.dispatch(new fromActionsActions.AddActions({
            actions: transactionModels,
            size,
            page,
            order,
            column,
            fetchActions
        }));
    }

    public updateActions(transactionModels: TransactionEntryModel[],
                         size: number,
                         page: number,
                         order: Order,
                         column: string,
                         filter: any): void {
        this.store.dispatch(new fromActionsActions.UpdateActions({
            actions: transactionModels,
            size,
            page,
            order,
            column,
            filter
        }));
    }

    public deleteAction(id: number, size: number, page: number, order: Order, column: string): void {
        this.store.dispatch(new fromActionsActions.DeleteAction({
            id,
            page,
            size,
            order,
            column,
            usePagination: true
        }));
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }

    public sendToHeaven(result: any,
                        latestTransaction: TransactionEntryModel,
                        animal: AnimalEntryModel,
                        pageIndex: number,
                        entriesPerPage: number,
                        order: Order,
                        column: string): void {
        this.animalSub = this.actionTypesService.fetchActionTypes()
            .subscribe(actionTypes => {
                if (typeof result !== 'undefined' && result !== false) {
                    const deadActionType = actionTypes.find(type => type.name === ActionType.HIMMELFAHRT.key);
                    const updatedAnimal: AnimalEntryModel = {
                        ...animal,
                        type: deadActionType,
                        shelter: ShelterStatus.NICHT_IM_HEIM.key
                    };

                    const transactionModel: TransactionEntryModel = {
                        id: -1,
                        transactionId: -1,
                        actionTypeId: deadActionType.id,
                        supervisions: [],
                        date: moment(result.dateDied).set(
                            {
                                hour: +moment().format('HH'),
                                minute: +moment().format('mm'),
                                second: +moment().format('ss')
                            }).format('YYYY-MM-DD HH:mm:ss'),
                        person: latestTransaction !== null && typeof latestTransaction !== 'undefined' ?
                            latestTransaction.person : null,
                        animal: updatedAnimal,
                        giveAwayReason: null,
                        takingOverReason: null,
                        publicAuthority: null,
                        owner: undefined,
                        stayFrom: null,
                        stayUntil: null,
                        shelter: updatedAnimal.shelter,
                        deposit: null,
                        streetFound: null,
                        zipFound: null,
                        cityFound: null,
                        howAnimalWasFound: null,
                        costsPerDayTotal: null,
                        depositTotal: null,
                        additionalCosts: null,
                        isPersonAddressResidenceAddress: null,
                        residenceStreet: null,
                        residenceZip: null,
                        residenceCity: null,
                        hasFence: false,
                        hasGarden: false,
                        otherAnimalsInHousehold: null,
                        deathReason: result.deathReason,
                        wasPrinted: false
                    };

                    this.addActions([transactionModel],
                        1,
                        pageIndex,
                        order,
                        column,
                        false);
                }
            });
    }
}
