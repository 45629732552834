export interface Tuple {
    key: string;
    value: any;
}

export class TupleHelper {
    public static hasElement(tuples: Tuple[], key: string): Tuple | undefined {
        if (tuples !== null && typeof tuples !== 'undefined') {
            return tuples.find(x => x.key === key);
        } else {
            return undefined;
        }
    }

    public static getValue(tuples: Tuple[], key: string): any | undefined {
        const element = TupleHelper.hasElement(tuples, key);
        if (element !== null && typeof element !== 'undefined') {
            return element.value;
        }
        return undefined;
    }
}
