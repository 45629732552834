<div class="title-bar">
    <h3>Storno</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Verbuchte Artikel</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="filterForm"
                          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (dateChange)="dateChange($event)"
                                           (click)="datePicker.open()"
                                           [ngxMatDatetimePicker]="datePicker"
                                           placeholder="Datum"
                                           formControlName="dateAt"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="datePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #datePicker
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute"
                                                             [hideTime]="true">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 d-flex align-items-center ml-3 mt-1">
                                <button mat-raised-button color="primary" class="float-right" (click)="search()">
                                    Suchen
                                </button>
                            </div>
                        </div>
                    </form>

                    <div id="top-controls" class="d-flex justify-content-between mt-5">
                        <div id="show-entries-container">
                            Zeige
                            <select name="entries"
                                    id="entries"
                                    class="mr-1 ml-1"
                                    (ngModelChange)="changeEntriesPerPage($event)"
                                    [(ngModel)]="entriesPerPage">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            Einträge
                        </div>
                    </div>

                    <div style="overflow-x: auto">
                        <table mat-table
                               [dataSource]="bookings"
                               (matSortChange)="orderData($event)"
                               multiTemplateDataRows
                               matSort
                               class="mat-elevation-z0 no-expansion">
                            <ng-container *ngFor="let def of tableDef">
                                <ng-container [matColumnDef]="def.key">
                                    <th [ngClass]="{ 'sortable-header': columnSortable(def.key) }" mat-header-cell
                                        mat-sort-header *matHeaderCellDef> {{def.header}} </th>
                                    <td [ngClass]="def.className"
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngSwitch]="def.key">
                                        <ng-container *ngSwitchCase="'type'">
                                            <div *ngIf="element[def.key] === Constants.Income">
                                                <div>{{Constants.Income}}</div>
                                                <i class="mt-1 income-symbol fas fa-long-arrow-alt-left"></i>
                                            </div>
                                            <div *ngIf="element[def.key] === Constants.Outcome">
                                                <div>{{Constants.Outcome}}</div>
                                                <i class="mt-1 outcome-symbol fas fa-long-arrow-alt-right"></i>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'price'">
                                            {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'dateCreated'">
                                            {{element[def.key] | date:'dd.MM.yyyy HH:mm' }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'isATM'">
                                            <i class="fas fa-check" *ngIf="element[def.key]"></i>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'dateCancelled'">
                                            <i class="fas fa-check" *ngIf="element[def.key] !== null"></i>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{element[def.key]}}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container matColumnDef="controls">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element['dateCancelled'] === null"
                                         class="control-wrapper d-flex align-items-center justify-content-center">
                                        <a class="fas fa-trash-alt icon"
                                           (click)="cancelBooking(element)"
                                           matTooltip="Artikel stornieren"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="table-row">
                            </tr>
                        </table>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline">
                            <div id="total-results">Gesamt: <span>{{bookingLength}}</span></div>
                            <mat-paginator [length]="bookingLength"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div class="default-entry mt-2" *ngIf="bookingLength === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
