import {Action} from '@ngrx/store';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const FINISHED_CRUD = '[Options] FinishedCrud';
export const ADD_OPTION = '[Options] Add Option';
export const UPDATE_OPTION = '[Options] Update Option';
export const DELETE_OPTION = '[Options] Delete Option';
export const HTTP_FAIL = '[Options] Http Fail';

export class FinishedCrud implements Action {
    readonly type = FINISHED_CRUD;
}

export class AddOption implements Action {
    readonly type = ADD_OPTION;

    constructor(public payload: { option: DictionaryEntryModel, attributeId: number }) {
    }
}

export class UpdateOption implements Action {
    readonly type = UPDATE_OPTION;

    constructor(public payload: { option: DictionaryEntryModel, attributeId: number }) {
    }
}

export class DeleteOption implements Action {
    readonly type = DELETE_OPTION;

    constructor(public payload: { optionId: number, attributeId: number }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type OptionsActions =
    FinishedCrud
    | AddOption
    | UpdateOption
    | DeleteOption
    | HttpFail;

