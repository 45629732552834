import {Component} from '@angular/core';
import {PrintingDataAnimal, PrintingTemplate} from '../../TemplateTypes';
import {ActionSpecificPrintingComponent} from '../action-specific-printing-component';
import {AnimalEntryModel} from '../../../../shared/models/animal/animal-entry.model';
import {PersonEntryModel} from '../../../../shared/models/person-entry.model';
import {Age, CastrationStatus, Constants, Gender, Shelter} from '../../../../shared/constants';
import {CharacteristicService} from '../../../../animals/store/characteristics/characteristic.service';

@Component({
    selector: 'app-abgabe-printing',
    templateUrl: './abgabe-printing.component.html',
    styleUrls: ['./abgabe-printing.component.scss']
})
export class AbgabePrintingComponent implements ActionSpecificPrintingComponent {
    public hideHeader = false;
    public printingTemplate = PrintingTemplate.ActionSpecific;
    public visible = false;
    public data: PrintingDataAnimal;
    public animals: AnimalEntryModel[];
    public person: PersonEntryModel;
    public shelter: Shelter;
    public Shelter = Shelter;
    public Constants = Constants;

    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public Age = Age;

    constructor(private characteristicsService: CharacteristicService) {
    }
}
