<div class="title-bar not-printable">
    <h3>Übersicht</h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Tiere</h4>
                </div>
                <div class="card-body">
                    <app-animal-list></app-animal-list>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>

                </div>
                <div class="card-body pt-0">
                    <app-animals-filter></app-animals-filter>
                </div>
            </div>
        </div>
    </div>
</div>


<app-printing [templateType]="PrintingTemplate.DataSheet"
              [actionTypes]="fetchedActionTypes"></app-printing>
