<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Kontrollformular</h1>

    <section class="d-flex justify-content-around mt-5 ml-auto mr-auto"
             style="max-width: 560px;"
             *ngIf="person !== null && person !== undefined">
        <div class="left-col text-right">
            <div class="font-weight-bolder">Letzte Kontrolle:</div>
            <div class="mt-2">Name des Tierhalters:</div>
            <div>Anschrift</div>
            <div>Tel.-Nr.:</div>
            <div>Tier bekommen am:</div>
            <div>LfNr.:</div>
            <div>Tierart:</div>
            <div>Rasse:</div>
            <div>Name:</div>
        </div>

        <div class="right-col text-left">
            <div *ngIf="supervision === null">
                Noch nie kontrolliert
            </div>
            <div *ngIf="supervision !== null">
                {{supervision.datePerformed | date: 'dd.MM.yyyy'}}
            </div>
            <div class="mt-2">{{person.firstName}} {{person.lastName}}</div>
            <div>{{person.street}} - {{person.zip}}</div>
            <div>{{person.phone}}</div>
            <div>{{action.date | date: 'dd.MM.yyyy'}}</div>
            <div>{{animal.id}}</div>
            <div>{{animal.species.name}}</div>
            <div>{{animal.race.name}}</div>
            <div>{{animal.name}}</div>
            <div>{{animal.specialCriteria}}</div>
        </div>
    </section>

    <section class="mt-5">
        <div class="row">
            <div class="col-3 text-left">
                Anzeige durch/wegen:
            </div>
            <div class="col-9" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-5">
            <div class="col-6">
                <div class="row">
                    <div class="col-4 text-left">Besucht am:</div>
                    <div class="col-8" style="border-bottom: 2px solid #000"></div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-3 text-right">Von:</div>
                    <div class="col-9" style="border-bottom: 2px solid #000"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="mt-5">
        <h2>Wie wird das Tier gehalten</h2>
        <div class="row mt-4">
            <div class="col-4 text-left">Unterkunft (Art und Größe):</div>
            <div class="col-8" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-4">
            <div class="col-4 text-left">Ernährungszustand:</div>
            <div class="col-8" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-4">
            <div class="col-4 text-left">Behandlung und Pflege:</div>
            <div class="col-8" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-4">
            <div class="col-4 text-left">Kette/Zwinger/etc.?</div>
            <div class="col-8" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-4">
            <div class="col-4 text-left">Wo befand sich das Tier?</div>
            <div class="col-8" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-4">
            <div class="col-4 text-left">Sonstige Anmerkungen:</div>
            <div class="col-8" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-5">
            <div class="col-12" style="border-bottom: 2px solid #000"></div>
        </div>
        <div class="row mt-5">
            <div class="col-12" style="border-bottom: 2px solid #000"></div>
        </div>

    </section>
</div>
