import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import * as fromAuthActions from '../../auth/store/auth.actions';
import {AdminLayoutService} from '../../layouts/admin-layout/admin-layout.service';
import {AuthService} from '../../auth/auth.service';
import {UserEntryModel} from '../../shared/models/user-entry.model';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    public currentUser: UserEntryModel;
    private storeSub: Subscription;

    constructor(private store: Store<fromApp.AppState>,
                private authService: AuthService,
                private adminLayoutService: AdminLayoutService) {
    }

    ngOnInit(): void {
        this.authService.loggedInUser.subscribe(currentUser => {
            this.currentUser = currentUser;
        });
    }

    ngOnDestroy(): void {
        if (this.storeSub) {
            this.storeSub.unsubscribe();
        }
    }

    public closeSidebar(): void {
        this.adminLayoutService.menuItemsClosed.next(true);
    }

    public logout(): void {
        this.storeSub = this.store.select('auth')
            .take(1)
            .pipe(
                map(x => !!x.user)
            )
            .subscribe(user => {
                this.store.dispatch(new fromAuthActions.Logout());
                if (user) {
                    this.authService.sendLogout();
                } else {
                    throw new Error('User is not set in angular store.');
                }
            });
    }
}
