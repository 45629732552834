import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {AnimalAddEditComponent} from '../animal-add-edit/animal-add-edit.component';
import {Store} from '@ngrx/store';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {DataTable} from '../../shared/data-types/data-table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Order} from '../../shared/controls/data-table/ordering';
import {combineLatest, Subscription} from 'rxjs';
import {AnimalsService} from '../animals.service';
import {Actions, ofType} from '@ngrx/effects';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {SpeciesService} from '../store/species/species.service';
import {RaceService} from '../store/races/race.service';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {DialogType} from '../../dialogs/dialog-type';
import {CRUD} from '../../shared/services/http/crud';
import {FormBuilder} from '@angular/forms';
import {CastrationStatus, Gender} from '../../shared/constants';
import {take, withLatestFrom} from 'rxjs/operators';
import {MediaReferrer, MediaService} from '../../media-centre/media.service';
import {SnackbarService} from '../../shared/components/snackbar/snackbar.service';
import {ActionType, ActionTypesService} from '../store/action-types/action-types.service';
import {CharacteristicService} from '../store/characteristics/characteristic.service';
import * as fromApp from '../../store/app.reducer';
import * as fromAnimalActions from '../store/animals/animal.actions';
import {CareSpaceService} from '../store/care-space/care-space.service';
import * as fromCareSpaceActions from '../store/care-space/care-space.actions';
import {RueckgabePensionstierService} from '../action-type-specific/rueckgabe-pensionstier/rueckgabe-pensionstier.service';
import {PrintingTemplate} from '../../printing/templates/TemplateTypes';
import {ActivatedRoute, Router} from '@angular/router';
import {QuickEditComponent} from './quick-edit/quick-edit.component';
import {ImageUploadType} from '../../media-centre/ImageUploadType';
import {AuthService} from '../../auth/auth.service';
import {ExternalApiService} from '../../shared/services/external-api.service';

@Component({
    selector: 'app-animal-list',
    templateUrl: './animal-list.component.html',
    styleUrls: ['./animal-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimalListComponent implements OnInit, OnDestroy {
    public static filterModel: any;
    @Input() public showEntryCount = true;
    @Input() public enableDelete = true;
    @Input() public enableEdit = true;
    @Input() public enableDuplicate = false;
    @Input() public entryRemovable = false;
    @Input() public dataSourceFromAcquisitionGiveAway = false;
    @Input() public selectedActionType = null;
    @Input() public isAcquisition = false;

    @Output() public selectedAnimals = new EventEmitter<[AnimalEntryModel[], boolean]>();
    @ViewChild('paginator') public paginator: MatPaginator;
    @ViewChildren('quickEditContainer', {read: ViewContainerRef}) public quickEditComponents: QueryList<ViewContainerRef>;
    public columns: string[] = [
        'id',
        'avatar',
        'name',
        'dateCreated',
        'gender',
        'castrated',
        'birthday',
        'species',
        'race',
        'chipNumber',
        'controls'
    ];
    public expandedElement: AnimalEntryModel;
    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'Lauf-Nr.',
            className: 'lfnr'
        }, {
            key: 'avatar',
            header: 'Avatar',
            className: 'avatar'
        }, {
            key: 'name',
            header: 'Name',
            className: 'name'
        }, {
            key: 'dateCreated',
            header: 'Erstellt am',
            className: 'created'
        }, {
            key: 'castrated',
            header: 'Kastriert',
            className: 'castrated'
        }, {
            key: 'birthday',
            header: 'Geburtsdatum',
            className: 'birthday'
        }, {
            key: 'species',
            header: 'Tierart',
            className: 'species'
        }, {
            key: 'race',
            header: 'Rasse',
            className: 'race'
        }, {
            key: 'gender',
            header: 'Geschlecht',
            className: 'gender'
        }, {
            key: 'chipNumber',
            header: 'Chipnummer',
            className: 'chip-number'
        }
    ];
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.DESC;
    public orderedColumn = 'id';
    public totalAnimals = 0;
    public loading?: boolean = null;
    public species: DropdownOption[];
    public races: DropdownOption[];
    public CastrationStatus = CastrationStatus;
    public avatarList = new Array<SafeResourceUrl>();
    public animals: AnimalEntryModel[] = [];
    public Gender = Gender;
    public selectedRowIndex = -1;
    public PrintingTemplate = PrintingTemplate;

    protected characteristicsSub: Subscription;

    private simpleDialogType = new DialogType();
    private animalSub: Subscription;
    private lastPage = 1;
    private notSortable = ['avatar'];
    private speciesRacesSub: Subscription;
    private avatarSub: Subscription;
    private animalsSub: Subscription;
    private filterSub: Subscription;
    private animalSelectedFromAcquisitionSub: Subscription;
    private careSpaceSub: Subscription;
    private characteristicsLoaded = false;
    private routeSub: Subscription;
    private isInformationPage = false;
    private quickEditVcr: ViewContainerRef;

    constructor(public actionTypesService: ActionTypesService,
                protected store: Store<fromApp.AppState>,
                protected formBuilder: FormBuilder,
                protected actions$: Actions,
                protected characteristicService: CharacteristicService,
                protected animalService: AnimalsService,
                protected snackbarService: SnackbarService,
                protected careSpaceService: CareSpaceService,
                protected dialog: MatDialog,
                private mediaService: MediaService,
                private authService: AuthService,
                private sanitizer: DomSanitizer,
                private speciesService: SpeciesService,
                private route: ActivatedRoute,
                private rueckgabePensionstierService: RueckgabePensionstierService,
                private raceService: RaceService,
                private cd: ChangeDetectorRef,
                private router: Router,
                private componentFactoryResolver: ComponentFactoryResolver,
                private externalApiService: ExternalApiService) {
    }

    ngOnInit(): void {
        if (!this.dataSourceFromAcquisitionGiveAway) {
            this.initAnimalListTable();
            this.initSpeciesAndRaces();
            this.initFilter();
            this.initCareSpaceEvent();
            this.initErrorHandling();
        } else {
            this.initAnimalsAddedToTable();
        }
    }

    ngOnDestroy(): void {
        if (this.animalSub) {
            this.animalSub.unsubscribe();
        }
        if (this.speciesRacesSub) {
            this.speciesRacesSub.unsubscribe();
        }
        if (this.avatarSub) {
            this.avatarSub.unsubscribe();
        }
        if (this.animalsSub) {
            this.animalsSub.unsubscribe();
        }
        if (this.filterSub) {
            this.filterSub.unsubscribe();
        }
        if (this.animalSelectedFromAcquisitionSub) {
            this.animalSelectedFromAcquisitionSub.unsubscribe();
        }
        if (this.careSpaceSub) {
            this.careSpaceSub.unsubscribe();
        }
        if (this.characteristicsSub) {
            this.characteristicsSub.unsubscribe();
        }

        this.animals = [];
        this.animalService.filterEvent.next(null);
    }

    public get displayedColumns(): string[] {
        let columns = this.columns.slice();
        if (!this.dataSourceFromAcquisitionGiveAway || this.isAcquisition) {
            columns = columns.filter(c => c !== 'chipNumber');
        }
        return columns;
    }

    public openEditDialog(animal: AnimalEntryModel, rowIndex = -1): void {
        const dialogRef = this.dialog.open(AnimalAddEditComponent, {
            width: '1200px',
            panelClass: ['component-wrapper'],
            disableClose: true,
            data: {
                edit: true,
                animal,
                editTemporaryAnimal: animal.id < 0,
                pageIndex: this.pageIndex,
                entriesPerPage: this.entriesPerPage,
                order: this.order,
                orderedColumn: this.orderedColumn
            }
        });

        // Update temporary animal
        if (animal.id < 0) {
            dialogRef.afterClosed().subscribe(result => {
                if (typeof result !== 'undefined' && result !== false) {
                    const updatedAnimal = result;
                    const animalsInTable = this.animals.slice();
                    animalsInTable[rowIndex] = updatedAnimal;
                    this.animals = animalsInTable.slice();
                    this.fetchAvatar(this.animals);
                    this.selectedAnimals.next([animalsInTable.slice(), true]);
                }
            });
        }
    }

    public deleteAnimal(animalEntry: AnimalEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Tier',
                identifier: animalEntry.name + ' mit LFNr. ' + animalEntry.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.animalService
                    .deleteAnimal(animalEntry.id, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
                this.animalService.crudFinished.next(animalEntry.name);
            }
        });
    }

    public canExpand($event: MouseEvent, element: any, expandedElement: AnimalEntryModel): any {
        return DataTable.canExpand($event, element, expandedElement);
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.characteristicsLoaded = false;
        this.animalService
            .fetchAnimals(this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn,
                AnimalListComponent.filterModel,
                true,
                true,
                this.isInformationPage)
            .pipe(take(1))
            .subscribe();
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.characteristicsLoaded = false;
        this.animalService
            .fetchAnimals(this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn,
                AnimalListComponent.filterModel,
                true,
                true,
                this.isInformationPage)
            .subscribe();
        this.paginator.firstPage();
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active;
        if (this.orderedColumn === 'castrated') {
            this.orderedColumn = 'castration_status';
        }

        this.animalService
            .fetchAnimals(this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn,
                AnimalListComponent.filterModel,
                true,
                true,
                this.isInformationPage)
            .subscribe();
    }

    public columnSortable(key: any): boolean {
        return !this.notSortable.includes(key);
    }

    public sanitizeBase64(bas64: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + bas64);
    }

    public removeFromList(animalId: number, index: number): void {
        this.animals = this.animals.filter(animal => animal.id !== animalId);
        this.rueckgabePensionstierService.animalInTableSelected.next(index);
        this.selectedAnimals.next([this.animals, false]);
        this.selectedRowIndex = 0;
    }

    public cloneAnimal(source: AnimalEntryModel): void {
        const lowestIdInTable = this.animals.length > 0 ?
            Math.min(...this.animals.map(a => a.id)) : 0;
        const clonedAnimal: AnimalEntryModel = {
            ...source,
            id: lowestIdInTable < 0 ? lowestIdInTable - 1 : (lowestIdInTable * -1) - 1,
            dateCreated: '',
            fundtierId: null,
            chipNumber: ''
        };
        const currentAnimalsInTable = this.animals.slice();
        currentAnimalsInTable.push(clonedAnimal);
        this.animals = currentAnimalsInTable.slice();
        this.fetchAvatar(this.animals);
        this.selectedAnimals.next([currentAnimalsInTable, true]);
    }

    public selectedRow(element: AnimalEntryModel, i: number): boolean {
        if (this.selectedActionType === ActionType.RUECKGABE_PENSIONSTIER.key &&
            i === this.selectedRowIndex &&
            this.dataSourceFromAcquisitionGiveAway) {
            return true;
        }
        return false;
    }

    public clickedRow($event: MouseEvent, element: any, expandedElement: AnimalEntryModel, index: number, expanded: boolean): void {
        this.expandedElement = this.canExpand($event, element, expandedElement);
        if (this.dataSourceFromAcquisitionGiveAway && this.selectedActionType === ActionType.RUECKGABE_PENSIONSTIER.key) {
            this.rueckgabePensionstierService.animalInTableSelected.next(index);
            this.selectedRowIndex = index;
        }

        if (this.enableEdit && !expanded) {
            this.quickEditVcr = this.quickEditComponents.toArray()[index];
            const factory = this.componentFactoryResolver.resolveComponentFactory(QuickEditComponent);
            const newComponent = this.quickEditVcr.createComponent(factory);
            newComponent.instance.animalEntry = expandedElement;
            newComponent.instance.animalEntry = element;
            newComponent.instance.pageIndex = this.pageIndex;
            newComponent.instance.entriesPerPage = this.entriesPerPage;
            newComponent.instance.order = this.order;
            newComponent.instance.orderedColumn = this.orderedColumn;
            newComponent.instance.species = this.species;
            newComponent.instance.races = this.races;
            newComponent.instance.filterModel = AnimalListComponent.filterModel;
            newComponent.changeDetectorRef.detectChanges();
        }
    }

    public animationDone(expanded: boolean): void {
        if (this.quickEditVcr && expanded) {
            this.quickEditVcr.clear();
        }
    }

    private initAnimalListTable(): void {
        this.loading = true;
        this.animalService.fetchAnimals(this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn,
            null,
            true,
            true,
            this.isInformationPage).subscribe();

        this.animalsSub = this.actions$.pipe(
            ofType(fromAnimalActions.SET_ANIMALS),
            withLatestFrom(this.animalService.crudFinished)
        ).subscribe(([animalState, identifier]: [fromAnimalActions.SetAnimals, string]) => {
            if (!animalState.payload.assignDataSource) {
                return;
            }

            this.animals = animalState.payload.animals;
            this.totalAnimals = animalState.payload.totalElements;
            this.lastPage = animalState.payload.lastPage;
            this.loading = false;

            const operation = animalState.payload.crud;

            this.fetchAvatar(animalState.payload.animals);
            if ((operation === CRUD.READ || operation === CRUD.NONE) && !this.characteristicsLoaded) {
                this.initCharacteristics();
                this.characteristicsLoaded = true;
                return;
            }

            if (operation === CRUD.CREATE) {
                this.paginator.length = this.totalAnimals;
                this.paginator.lastPage();
                this.animalService.handleRequestSuccess(operation, 'Tier', identifier);
            } else if (operation === CRUD.UPDATE || operation === CRUD.DELETE) {
                this.animalService.handleRequestSuccess(operation, 'Tier', identifier);
                this.externalApiService.handleInvalidRequests(animalState.payload.invalidAPIRequests);
            }
        });


        this.routeSub = this.route
            .data
            .subscribe((data) => {
                this.isInformationPage = data.isInformationPage;
            });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromAnimalActions.HTTP_FAIL)
        ).subscribe((httpFail: fromAnimalActions.HttpFail) => {
            this.animalService.handleRequestError(httpFail.payload.message);
            this.loading = false;
            this.dialog.closeAll();
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }

    private fetchAvatar(animals: AnimalEntryModel[]): void {
        const thumbs = [];
        animals.forEach(animal => {
            if (animal.avatarId !== null && !thumbs.includes(animal.avatarId)) {
                thumbs.push(animal.avatarId);
            } else if (animal.avatarId === null) {
                switch (animal.species.name) {
                    case 'Hund':
                        this.avatarList[animal.id] = 'assets/img/avatar/dog-placeholder.png';
                        break;
                    case 'Katze':
                        this.avatarList[animal.id] = 'assets/img/avatar/cat-placeholder.png';
                        break;
                    default :
                        this.avatarList[animal.id] = 'assets/img/avatar/small-animal-placeholder.png';
                        break;
                }
            }
        });

        if (thumbs.length > 0) {
            this.avatarSub = this.mediaService.fetchMultipleMedia(thumbs, true, ImageUploadType.NONE, MediaReferrer.AnimalList)
                .subscribe(mediaArr => {
                    animals.forEach(animal => {
                        const thumb = mediaArr.find(media => media.id === animal.avatarId);
                        if (typeof thumb !== 'undefined') {
                            this.avatarList[animal.id] = this.sanitizeBase64(thumb.data);
                        } else {
                            switch (animal.species.name) {
                                case 'Hund':
                                    this.avatarList[animal.id] = 'assets/img/avatar/dog-placeholder.png';
                                    break;
                                case 'Katze':
                                    this.avatarList[animal.id] = 'assets/img/avatar/cat-placeholder.png';
                                    break;
                                default :
                                    this.avatarList[animal.id] = 'assets/img/avatar/small-animal-placeholder.png';
                                    break;
                            }
                        }
                    });
                    this.cd.detectChanges();
                });
        }
        this.cd.detectChanges();
    }

    private initFilter(): void {
        this.filterSub = this.animalService.filterEvent.subscribe(filterModel => {
            AnimalListComponent.filterModel = filterModel;
            if (filterModel !== null) {
                this.animalService
                    .fetchAnimals(this.entriesPerPage,
                        1,
                        this.order,
                        this.orderedColumn,
                        filterModel,
                        true,
                        true,
                        this.isInformationPage)
                    .subscribe();
                if (typeof this.paginator !== 'undefined') {
                    this.paginator.firstPage();
                }
            }
        });
    }

    private initCareSpaceEvent(): void {
        this.careSpaceSub = this.actions$.pipe(
            ofType(fromCareSpaceActions.SET_CARE_SPACES),
            withLatestFrom(this.careSpaceService.crudFinished)
        ).subscribe(([careSpaceState, updatedAnimal]: [fromCareSpaceActions.SetCareSpaces, AnimalEntryModel]) => {
            const operation = careSpaceState.payload.operation;
            if (operation === CRUD.UPDATE) {
                const id = this.animals.findIndex(animal => animal.id === updatedAnimal.id);
                const updatedAnimals = this.animals.slice();
                updatedAnimals[id] = updatedAnimal;
                this.animals = updatedAnimals;
            }
        });
    }

    private initCharacteristics(): void {
        const raceIds = [];
        this.animals.forEach(animal => {
            if (raceIds.indexOf(animal.race.id) === -1) {
                raceIds.push(animal.race.id);
            }
        });

        const filter = {
            raceIds,
            excludeLegacyCharacteristics: true
        };

        this.characteristicsSub = this.characteristicService.fetchCharacteristics(filter)
            .pipe(take(1))
            .subscribe(characteristics => {
                this.characteristicService.characteristics.next(characteristics);
            });
    }

    private initSpeciesAndRaces(): void {
        this.speciesRacesSub = combineLatest([
            this.speciesService.fetchSpecies(),
            this.raceService.fetchRaces()
        ]).subscribe(([species, races]) => {
            this.species = species;
            this.races = races;
            this.animalService.speciesAndRaces.next([species, races]);
        });
    }

    // Acquisition and Give Away related
    private initAnimalsAddedToTable(): void {
        this.dataSourceFromAcquisitionGiveAway = true;
        this.animalSelectedFromAcquisitionSub = this.animalService.animalsSelected
            .subscribe(data => {
                if (data == null) {
                    this.animals = [];
                } else {
                    const animals = data[0];
                    const addMode = data[1];
                    if (typeof animals !== 'undefined' && animals.length > 0) {
                        const animalsInTable = this.animals.slice();
                        if (addMode) {
                            this.animals = [];
                            animals.forEach(animal => {
                                const result = animalsInTable.find(a => a.id === animal.id && a.id >= 0);
                                if (typeof result === 'undefined') {
                                    animalsInTable.push(animal);
                                }
                            });

                        } else {
                            const updatedAnimal = animals.pop();
                            const index = animalsInTable.findIndex(a => a.id === updatedAnimal.id);
                            animalsInTable[index] = updatedAnimal;
                        }
                        this.animals = animalsInTable.slice();
                        this.fetchAvatar(this.animals);
                        this.selectedAnimals.next([this.animals.slice(), true]);
                    }
                }
                this.cd.detectChanges();
            });

        this.rueckgabePensionstierService.formResetted
            .subscribe(resetted => {
                if (resetted) {
                    this.selectedRowIndex = -1;
                }
            });
    }
}
