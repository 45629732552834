import {ChangeDetectorRef, ComponentRef, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PrintableComponent} from './PrintableComponent';
import {PrintingDataAction, PrintingDataAnimal, PrintingDataCashbook, PrintingIncomeOutcome} from './templates/TemplateTypes';

@Injectable({
    providedIn: 'root'
})
export class PrintingService {
    public dataFetched: Subject<PrintingDataAnimal | PrintingDataCashbook | PrintingDataAction | PrintingIncomeOutcome> =
        new Subject();
    public finishedPrinting: Subject<boolean> = new Subject();

    private style: HTMLStyleElement;

    public print(articleEntryContainer: ComponentRef<PrintableComponent>[],
                 hiddenHeader: { hidden: boolean },
                 cd: ChangeDetectorRef,
                 landscape): void {

        articleEntryContainer.forEach(entry => {
            entry.instance.visible = true;
            entry.changeDetectorRef.detectChanges();
            if (entry.instance.hideHeader) {
                hiddenHeader.hidden = true;
                cd.detectChanges();
            }
            if (landscape) {
                this.attachLandscapeCSS();
            }
            window.print();
            entry.instance.visible = false;
            entry.changeDetectorRef.detectChanges();
            if (entry.instance.hideHeader) {
                hiddenHeader.hidden = false;
                cd.detectChanges();
            }
            if (landscape) {
                this.detachLandscapeCSS();
            }
        });
        this.finishedPrinting.next(true);
    }

    private attachLandscapeCSS(): void {
        const css = '@page { size: landscape; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        this.style = document.createElement('style');

        this.style.media = 'print';
        this.style.appendChild(document.createTextNode(css));
        head.appendChild(this.style);
    }

    private detachLandscapeCSS(): void {
        const head = document.head || document.getElementsByTagName('head')[0];
        head.removeChild(this.style);
    }
}
