import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TransactionEntryModel} from '../shared/models/transaction-entry.model';
import {Subscription} from 'rxjs';
import {ActionsService} from './actions.service';
import {Order} from '../shared/controls/data-table/ordering';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../shared/services/http/crud';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ActionTypesService} from '../animals/store/action-types/action-types.service';
import {Sort} from '@angular/material/sort';
import {AnimalsService} from '../animals/animals.service';
import {SimpleDialogComponent} from '../dialogs/simple-dialog/simple-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogType} from '../dialogs/dialog-type';
import * as fromActionsActions from './store/actions.actions';
import {PrintingService} from '../printing/printing.service';
import {PrintingDataAnimal, PrintingTemplate} from '../printing/templates/TemplateTypes';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';
import {AnimalActionConverter} from '../shared/converter/animal-action-converter';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    public displayedColumns: string[] = [
        'transactionId',
        'type',
        'date',
        'personNumber',
        'fullName',
        'lfnr',
        'animalName',
        'species',
        'controls'
    ];
    public tableDef: Array<any> = [
        {
            key: 'transactionId',
            header: 'Transaktions-Nr.',
            className: 'transactions-number'
        }, {
            key: 'type',
            header: 'Aktionstyp',
            className: 'type'
        }, {
            key: 'date',
            header: 'Datum',
            className: 'date'
        }, {
            key: 'personNumber',
            header: 'Person-Nr.',
            className: 'person-number'
        }, {
            key: 'fullName',
            header: 'Personname',
            className: 'full-name'
        }, {
            key: 'lfnr',
            header: 'Lauf-Nr.',
            className: 'lfnr'
        }, {
            key: 'animalName',
            header: 'Tiername',
            className: 'animal-name'
        }, {
            key: 'species',
            header: 'Tierart',
            className: 'species'
        }
    ];

    public transactions: TransactionEntryModel[] = [];
    public totalActions = 0;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.DESC;
    public orderedColumn = 'transaction_id';
    public loading?: boolean = null;
    public simpleDialogType = new DialogType();
    public PrintingTemplate = PrintingTemplate;
    public fetchedActionTypes: ActionTypeEntryModel[];

    private static filterModel: any;
    private setActionsSub: Subscription;
    private fetchedActionTypesSub: Subscription;
    private filterSub: Subscription;
    private fetchedActionsSub: Subscription;
    private lastPage = 1;

    constructor(private actionsService: ActionsService,
                private actionTypeService: ActionTypesService,
                private animalService: AnimalsService,
                private dialog: MatDialog,
                private printingService: PrintingService,
                private authService: AuthService,
                private actions$: Actions) {
    }

    ngOnInit(): void {
        this.fetchTransactions();
        this.fetchActionTypes();
        this.initFiltering();
        this.initErrorHandling();
    }

    ngOnDestroy(): void {
        if (this.setActionsSub) {
            this.setActionsSub.unsubscribe();
        }
        if (this.fetchedActionTypesSub) {
            this.fetchedActionTypesSub.unsubscribe();
        }
        if (this.filterSub) {
            this.filterSub.unsubscribe();
        }
        if (this.fetchedActionsSub) {
            this.fetchedActionsSub.unsubscribe();
        }

        this.actionsService.filterEvent.next(null);
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.actionsService
            .fetchActions(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, ActionsComponent.filterModel)
            .subscribe(transactions => {
                this.transactions = transactions;
            });

        this.paginator.firstPage();
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.actionsService
            .fetchActions(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, ActionsComponent.filterModel)
            .subscribe(transactions => {
                this.transactions = transactions;
            });
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active;
        if (this.orderedColumn === 'fullName') {
            this.orderedColumn = 'last_name';
        } else if (this.orderedColumn === 'type') {
            this.orderedColumn = 'action_type';
        } else if (this.orderedColumn === 'date') {
            this.orderedColumn = 'date_performed';
        } else if (this.orderedColumn === 'personNumber') {
            this.orderedColumn = 'person_id';
        } else if (this.orderedColumn === 'lfnr') {
            this.orderedColumn = 'animal_id';
        } else if (this.orderedColumn === 'animalName') {
            this.orderedColumn = 'animal_name';
        }

        this.actionsService
            .fetchActions(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, ActionsComponent.filterModel)
            .subscribe(transactions => {
                this.transactions = transactions;
            });
    }

    public deleteAction(element: any): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Aktion',
                identifier: 'Aktion ' + element.transactionId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.actionsService.deleteAction(element.id, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
            }
        });
    }

    public print(action: TransactionEntryModel): void {
        const filterModel = {
            transactionId: action.transactionId
        };
        this.actionsService.fetchActions(-1, 1, Order.NONE, '', filterModel, false, false)
            .subscribe(transactions => {
                if (transactions.length > 0) {
                    const mappedHistoricalTransactions = transactions.map(tr => {
                        const mappedTransaction: TransactionEntryModel = {
                            ...tr,
                            animal: {
                                ...tr.animal,
                                transactionHistory: tr.animal.transactionHistory.map(trHistory => {
                                    return AnimalActionConverter.toTransactionEntryModel(trHistory);
                                })
                            }
                        };
                        return mappedTransaction;
                    });
                    this.emitPrintingData(mappedHistoricalTransactions);
                }
            });
    }

    private fetchTransactions(): void {
        this.fetchedActionsSub = this.actionsService
            .fetchActions(this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn)
            .subscribe((transactions: TransactionEntryModel[]) => {
                this.transactions = transactions;
            });

        this.setActionsSub = this.actions$.pipe(
            ofType(fromActionsActions.SET_ACTIONS)
        ).subscribe((actionState: fromActionsActions.SetActions) => {
            const crudOperation = actionState.payload.crud;
            this.loading = false;

            if (actionState.payload.refreshTable) {
                this.totalActions = actionState.payload.totalElements;
                this.lastPage = actionState.payload.lastPage;
            }

            if (crudOperation === CRUD.READ ||
                crudOperation === CRUD.NONE) {
                return;
            }

            if (crudOperation === CRUD.DELETE) {
                this.transactions = actionState.payload.actions;
            }

            this.actionsService.handleRequestSuccess(crudOperation, 'Aktion', '');
        });
    }

    private fetchActionTypes(): void {
        this.fetchedActionTypesSub = this.actionTypeService.fetchActionTypes()
            .subscribe(actionTypes => {
                this.fetchedActionTypes = actionTypes;
                this.actionTypeService.fetchedActionsEvent.next(actionTypes);
            });
    }

    private initFiltering(): void {
        this.filterSub = this.actionsService.filterEvent.subscribe(filterModel => {
            ActionsComponent.filterModel = filterModel;
            if (filterModel !== null) {
                this.actionsService
                    .fetchActions(this.entriesPerPage, 1, this.order, this.orderedColumn, filterModel)
                    .subscribe(response => {
                        this.transactions = response;
                    });
                if (typeof this.paginator !== 'undefined') {
                    this.paginator.firstPage();
                }
            }
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromActionsActions.HTTP_FAIL)
        ).subscribe((httpFail: fromActionsActions.HttpFail) => {
            this.actionsService.handleRequestError(httpFail.payload.message);
            this.loading = false;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }

    private emitPrintingData(transactions: TransactionEntryModel[]): void {
        const tr = transactions.slice().pop();
        const actionTypeModel = this.actionTypeService.fetchedActionsEvent.value.find(at => at.id === tr.actionTypeId);
        const printingData: PrintingDataAnimal = {
            animals: transactions.slice().map(t => t.animal),
            person: tr.person,
            owner: tr.owner,
            date: tr.date,
            actionType: actionTypeModel,
            animalPlace: tr.residenceStreet === '' && tr.residenceZip === '' && tr.residenceCity === '',
            residenceStreet: tr.residenceStreet,
            residenceZip: tr.residenceZip,
            residenceCity: tr.residenceCity,
            hasFence: tr.hasFence,
            hasGarden: tr.hasGarden,
            otherAnimalsInHousehold: tr.otherAnimalsInHousehold,
            giveAwayReason: tr.giveAwayReason,
            takingOverReason: tr.takingOverReason,
            howAnimalWasFound: tr.howAnimalWasFound,
            streetFound: tr.streetFound,
            zipFound: tr.zipFound,
            cityFound: tr.cityFound,
            stayFrom: tr.stayFrom,
            stayUntil: tr.stayUntil,
            deposit: +tr.deposit,
            depositTotal: +tr.depositTotal,
            costsPerDayAccumulated: +tr.costsPerDayTotal,
            additionalCostsAccumulated: +tr.additionalCosts
        };
        this.printingService.dataFetched.next(printingData);
    }
}
