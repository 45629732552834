import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MediaService} from '../../../media-centre//media.service';
import {CRUD} from '../../services/http/crud';
import {CropperPosition, ImageCroppedEvent} from 'ngx-image-cropper';
import {ImageConverter} from '../ImageConverter';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SimpleDialogComponent} from '../../../dialogs/simple-dialog/simple-dialog.component';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    animations: [
        trigger('uploadExpand', [
            state('hidden', style({opacity: 0})),
            state('visible', style({opacity: 1})),
            transition('hidden <=> visible', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class ImageUploadComponent implements OnInit {
    @ViewChild('progressBar') public progressBar: ElementRef;
    @ViewChild('imageCropper') public imageCropper: any;
    @Input() public dialogRef: MatDialogRef<SimpleDialogComponent>;
    @Input() public keepAspectRatio: boolean;

    public isUserAboutToDropFile = false;
    public progress = 0;
    public isUploading = false;
    public imageChangedEvent: any = '';
    public croppedImage: any = '';
    public showDropArea = true;
    public filename = '';
    public loading = false;
    public showCropArea = false;
    public cropForm: FormGroup;
    public cropper: CropperPosition = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
    };
    public rotation = 0;

    constructor(private mediaService: MediaService,
                private formBuilder: FormBuilder,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.mediaService.progressStatus.subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                this.progress = +Math.round((100 * event.loaded) / event.total);
            } else if (event.type === HttpEventType.Response) {
                this.isUploading = false;
                this.mediaService.handleRequestSuccess(CRUD.CREATE, 'Bild', '');
                this.renderer.setStyle(this.progressBar.nativeElement, 'width', '0');
            }
        });

        this.initForm();
    }

    public fileChangeEvent(event: any): void {
        if (!this.isImage(event.target.files[0])) {
            this.mediaService.handleError('Bild konnte nicht geladen werden: ' + event.target.files[0].name);
            this.dialogRef.close();
        } else {
            this.imageChangedEvent = event;
            this.showDropArea = false;
            this.filename = event.target.files[0].name;
            this.loading = true;
        }
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
        this.cropForm.patchValue({
            width: event.width,
            height: event.height
        });
    }

    public imageLoaded(event: any): void {
        const width = event.original.image.width;
        const height = event.original.image.height;
        this.cropForm.patchValue({
            width,
            height
        });
        this.loading = false;
        this.showCropArea = true;
    }

    public loadImageFailed(): void {
        this.mediaService.handleError('Bild konnte nicht geladen werden: ' + this.filename);
        this.showDropArea = true;
        this.dialogRef.close();
    }

    public addImage(file: File = null): void {
        let croppedFile = null;
        if (file === null) {
            const base64WithoutHeader: string = this.croppedImage.split(',')[1];
            croppedFile = ImageConverter.dataURItoFile(this.filename, base64WithoutHeader);
        } else {
            croppedFile = file;
        }

        if (croppedFile.size > 16 * Math.pow(10, 6)) { // Todo: Export to config
            this.mediaService.handleError('Datei ist zu groß!');
            this.isUploading = false;
            return;
        }
        this.isUploading = true;
        this.mediaService.upload(croppedFile).subscribe();
    }

    public dragDropEvent(event: DragEvent): void {
        if (event.type === 'dragover') {
            event.dataTransfer.types.forEach(type => {
                if (type === 'Files' || type === 'application/x-moz-file') {
                    this.isUserAboutToDropFile = true;
                }
            });
        } else if (event.type === 'dragleave') {
            this.isUserAboutToDropFile = false;
        } else if (event.type === 'drop') {
            const files = event.dataTransfer.files;
            if (files && files[0] && this.isImage(files[0])) {
                this.addImage(files[0]);
            } else {
                this.mediaService.handleError('Datei ist ungültig.');
                this.isUserAboutToDropFile = false;
            }
            this.isUserAboutToDropFile = false;
        }
    }

    private isImage(file: File): boolean {
        const fileType = file.type;
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
        return validImageTypes.includes(fileType);
    }

    private initForm(): void {
        this.cropForm = this.formBuilder.group({
            width: '',
            height: ''
        });
    }
}
