import {DropdownOption} from '../../data-types/dropdown-option';

export class SelectBoxUtility {
    public static getOptionsValue(value: string | DropdownOption): string {
        if (typeof value === 'object' && 'value' in value) {
            return value.value;
        } else {
            return value;
        }

    }
}
