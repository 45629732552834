import {Component, OnInit} from '@angular/core';
import {PrintableComponent} from '../../PrintableComponent';
import {PrintingTemplate} from '../TemplateTypes';
import {Shelter} from '../../../shared/constants';
import {TransactionEntryModel} from '../../../shared/models/transaction-entry.model';
import {PersonEntryModel} from '../../../shared/models/person-entry.model';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {SupervisionEntryModel} from '../../../shared/models/supervision-entry.model';

@Component({
    selector: 'app-supervision-printing',
    templateUrl: './supervision-printing.component.html',
    styleUrls: ['./supervision-printing.component.scss']
})
export class SupervisionPrintingComponent implements PrintableComponent, OnInit {
    public hideHeader: boolean;
    public printingTemplate = PrintingTemplate.Supervision;
    public shelter: Shelter;
    public visible: boolean;
    public action: TransactionEntryModel;
    public supervision: SupervisionEntryModel;
    public person: PersonEntryModel;
    public animal: AnimalEntryModel;

    ngOnInit(): void {
        this.animal = this.action.animal;
        this.person = this.action.person;
    }
}
