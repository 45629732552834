import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import * as fromActionTypesActions from './action-types.actions';
import {ActionTypeEntryModel} from '../../../shared/models/action-type-entry.model';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class ActionTypesEffects {
    @Effect()
    loadActionTypes$ = this.actions$.pipe(
        ofType(fromActionTypesActions.LOAD_ACTION_TYPES),
        switchMap(() => {
            return this.endpointService.actionTypes().pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint);
                }),
                map(actionTypes => {
                    const mappedActionTypes = actionTypes.data.map(actionType => {
                        const action: ActionTypeEntryModel = {
                            id: actionType.id,
                            color: actionType.color,
                            name: actionType.name,
                            symbol: actionType.symbol,
                            isAcquisition: actionType.is_acquisition
                        };
                        return action;
                    });
                    return new fromActionTypesActions.SetActionTypes(mappedActionTypes);
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromActionTypesActions.HttpFail> => {
        return handleHTTPError(errorRes, fromActionTypesActions, 'Aktionstypen', this.loggingService);
    };
}
