<div class="title-bar">
    <h3>Übersicht</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Ausgangsperson</h4>
                </div>
                <div class="card-body">
                    <app-person-form [person]="sourcePerson"
                                     [readonly]="true"></app-person-form>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>

                </div>
                <div class="card-body pt-0">
                    <app-persons-filter (filterEvent)="filterSourcePersons($event)"
                                        [exactSearch]="true"
                                        [resettable]="false"></app-persons-filter>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Zielperson</h4>
                </div>
                <div class="card-body">
                    <app-person-form [person]="targetPerson"
                                     [readonly]="true"></app-person-form>
                    <div class="row">
                        <div class="col">
                            <button class="float-right mt-4 ml-3"
                                    mat-raised-button color="primary"
                                    [disabled]="requestSent &&
                                    personFormComponents.first.personForm.valid &&
                                    personFormComponents.last.personForm.valid"
                                    (click)="transferActionsAndCareSpaces()">
                                <i class="fas fa-exclamation-circle mr-2"
                                   *ngIf="displayErroneousInputs"
                                   style="color: #b71c1c">
                                </i>
                                Person übertragen
                            </button>
                            <mat-icon
                                    [hidden]="!(requestSent &&
                                    personFormComponents.first.personForm.valid &&
                                    personFormComponents.last.personForm.valid)"
                                    class="float-right" style="margin-top: 30px">
                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>

                </div>
                <div class="card-body pt-0">
                    <app-persons-filter (filterEvent)="filterTargetPersons($event)"
                                        [exactSearch]="true"
                                        [resettable]="false"></app-persons-filter>
                </div>
            </div>
        </div>
    </div>
</div>
