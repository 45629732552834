import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import * as fromOptionActions from './options.actions';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class OptionsEffects {
    @Effect({dispatch: false})
    finishedCrud$ = this.actions$.pipe(
        ofType(fromOptionActions.FINISHED_CRUD),
        switchMap(() => {
            return EMPTY;
        })
    );

    @Effect()
    addOption$ = this.actions$.pipe(
        ofType(fromOptionActions.ADD_OPTION),
        switchMap((optionState: fromOptionActions.AddOption) => {
            return this.endpointService.options(HttpMethod.POST, optionState.payload.attributeId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: optionState.payload.option.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromOptionActions.FinishedCrud();
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateOption$ = this.actions$.pipe(
        ofType(fromOptionActions.UPDATE_OPTION),
        switchMap((optionState: fromOptionActions.UpdateOption) => {
            return this.endpointService.options(HttpMethod.PUT,
                optionState.payload.attributeId,
                optionState.payload.option.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                name: optionState.payload.option.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromOptionActions.FinishedCrud();
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteOption$ = this.actions$.pipe(
        ofType(fromOptionActions.DELETE_OPTION),
        switchMap((optionState: fromOptionActions.DeleteOption) => {
            return this.endpointService.options(HttpMethod.DELETE,
                optionState.payload.attributeId,
                optionState.payload.optionId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromOptionActions.FinishedCrud();
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromOptionActions.HttpFail> => {
        return handleHTTPError(errorRes, fromOptionActions, 'Optionen', this.loggingService);
    };
}
