import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MediaService} from '../../media-centre/media.service';
import {Actions, ofType} from '@ngrx/effects';
import {MediaEntryModel} from '../../shared/models/media-entry.model';
import {CRUD} from '../../shared/services/http/crud';
import {Subscription} from 'rxjs';
import {SimpleDialogComponent} from '../simple-dialog/simple-dialog.component';
import {DialogType} from '../dialog-type';
import * as moment from 'moment';
import * as fromPersonsActions from '../../persons/store/persons.actions';
import * as fromMediaActions from '../../media-centre/store/media.actions';

@Component({
    selector: 'app-image-full-screen',
    templateUrl: './image-full-screen.component.html',
    styleUrls: ['./image-full-screen.component.scss']
})
export class ImageFullScreenComponent implements OnInit, OnDestroy {
    public fullScreenForm: FormGroup;
    public displayErroneousInputs = false;
    public altText = '';
    public title = '';
    public created = '';
    public imgData = '';
    public entriesPerPage = -1;
    public pageIndex = -1;
    public loading = true;
    public updateRequestSent = false;

    private imgMetaSub: Subscription;
    private readonly imgId = -1;
    private simpleDialogType = new DialogType();

    constructor(private formBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private actions$: Actions,
                private dialog: MatDialog,
                private mediaService: MediaService) {
        if (typeof data.id !== 'undefined') {
            this.imgId = data.id;
        }

        if (typeof data.entriesPerPage !== 'undefined' && data.pageIndex) {
            this.entriesPerPage = data.entriesPerPage;
            this.pageIndex = data.pageIndex;
        }
    }

    ngOnInit(): void {
        this.initForm();
        this.initErrorHandling();
        this.mediaService.fetchMultipleMedia([this.imgId], false);

        this.actions$.pipe(
            ofType(fromMediaActions.SET_MULTIPLE_MEDIA)
        ).subscribe((response: fromMediaActions.SetMultipleMedia) => {
            this.loading = false;
            const imgEntryModel = response.payload.media.slice().pop();
            if (typeof imgEntryModel !== 'undefined') {
                this.imgData = imgEntryModel.data;
                this.altText = imgEntryModel.altText;
                this.title = imgEntryModel.title;
                this.created = moment(imgEntryModel.created).format('DD.MM.YYYY');

                this.fullScreenForm.setValue({
                    id: this.imgId,
                    altText: this.altText,
                    title: this.title,
                    created: this.created
                });
            }
        });

        this.imgMetaSub = this.actions$.pipe(
            ofType(fromMediaActions.SET_MEDIA)
        ).subscribe((mediaState: fromMediaActions.SetMedia) => {
            const crudOperation = mediaState.payload.crud;
            this.updateRequestSent = false;

            if (crudOperation === CRUD.READ ||
                crudOperation === CRUD.NONE) {
                return;
            }

            this.mediaService.handleRequestSuccess(crudOperation, 'Bild', this.imgId.toString());
        });
    }

    ngOnDestroy(): void {
        if (this.imgMetaSub) {
            this.imgMetaSub.unsubscribe();
        }
    }

    public updateImage(): void {
        if (!this.fullScreenForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.updateRequestSent = true;

        const title = this.fullScreenForm.value.title;
        const altText = this.fullScreenForm.value.altText;

        const updatedMediaModel: MediaEntryModel = {
            id: this.imgId,
            title,
            altText,
            data: this.imgData,
            created: '',
            lfnr: -1
        };

        this.mediaService.updateMedia(updatedMediaModel, false, this.entriesPerPage, this.pageIndex);
    }

    public delete(): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Bild',
                identifier: this.imgId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.mediaService.deleteMedia(this.imgId, false, this.entriesPerPage, this.pageIndex);
            }
        });
    }

    private initForm(): void {
        this.fullScreenForm = this.formBuilder.group({
            id: [''],
            altText: ['', Validators.required],
            title: ['', Validators.required],
            created: ''
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromPersonsActions.HTTP_FAIL)
        ).subscribe((httpFail: fromPersonsActions.HttpFail) => {
            this.mediaService.handleError(httpFail.payload);
            this.loading = false;
        });
    }
}
