import {Injectable} from '@angular/core';
import {PosSystemService} from './pos-system.service';
import {Store} from '@ngrx/store';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {BookingEntryModel} from '../shared/models/pos/booking-entry.model';
import {Order} from '../shared/controls/data-table/ordering';
import {Moment} from 'moment';
import * as fromBookingActions from './store/booking/booking.actions';
import * as fromApp from '../store/app.reducer';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';

@Injectable()
export class BookingService {

    constructor(private posSystemService: PosSystemService,
                private actions$: Actions,
                private snackbarService: SnackbarService,
                private store: Store<fromApp.AppState>) {
    }

    public fetchBookings(size = -1,
                         page = -1,
                         start: Moment,
                         end: Moment,
                         order = Order.NONE,
                         column = '',
                         grouped = false): Observable<BookingEntryModel[]> {

        const startStr = start.format('YYYY-MM-DD HH:mm:ss');
        const endStr = end.format('YYYY-MM-DD HH:mm:ss');

        return this.store.select('bookingList').pipe(
            take(1),
            map(bookingState => {
                return bookingState.bookings;
            }),
            switchMap((bookings: BookingEntryModel[]) => {
                if (bookings.length === 0 || page >= 0) {
                    this.store.dispatch(new fromBookingActions.LoadBookings({
                        crud: CRUD.READ,
                        size,
                        page,
                        start: startStr,
                        end: endStr,
                        order,
                        column,
                        grouped
                    }));
                    return this.actions$.pipe(
                        ofType(fromBookingActions.SET_BOOKINGS),
                        take(1),
                        map((bookingState: fromBookingActions.SetBookings) => {
                            return bookingState.payload.bookings.map(booking => {
                                const mappedBooking: BookingEntryModel = {
                                    ...booking,
                                    dateCreated: booking.dateCreated,
                                    dateCancelled: booking.dateCancelled
                                };
                                return mappedBooking;
                            });
                        })
                    );
                } else {
                    return of(bookings);
                }
            })
        );
    }

    public addBooking(booking: BookingEntryModel,
                      page: number,
                      size: number,
                      order: Order,
                      column: string,
                      setCancelled = false,
                      start: string,
                      end: string): void {
        this.store.dispatch(new fromBookingActions.AddBooking({
            booking,
            size,
            page,
            order,
            column,
            setCancelled,
            start,
            end
        }));
    }

    public addBookings(bookings: BookingEntryModel[],
                       start: string,
                       end: string,
                       page: number,
                       size: number): void {
        this.store.dispatch(new fromBookingActions.AddBookings({
            bookings,
            start,
            end,
            page,
            size
        }));
    }

    public updateBooking(booking: BookingEntryModel,
                         page: number,
                         size: number,
                         order: Order,
                         column: string,
                         setCancelled = false,
                         grouped = false,
                         start: string,
                         end: string): void {
        this.store.dispatch(new fromBookingActions.UpdateBooking({
            booking,
            size,
            page,
            order,
            column,
            setCancelled,
            grouped,
            start,
            end
        }));
    }

    public fetchCashPosition(end: string): void {
        this.store.dispatch(new fromBookingActions.LoadCashPosition(end));
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }
}
