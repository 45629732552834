<div class="edit-article">
    <form class="form container-fluid"
          [formGroup]="newArticleForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
        <div class="row">
            <div class="col-md-3">
                <mat-form-field appearance="fill">
                    <mat-label>Kategorie</mat-label>
                    <mat-select placeholder="Kategorie"
                                [multiple]="false"
                                formControlName="newCategory">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchPOSCategory.multiFilterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option [classList]="'mat-option hidden-checkbox'">
                            <a class="add-new"
                               (click)="posSystemService.addCategory($event)"
                               style="font-weight: 600">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>Neue Kategorie erstellen...</div>
                                    <div>
                                        <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                    </div>
                                </div>
                            </a>
                        </mat-option>
                        <mat-option
                                *ngFor="let category of selectSearchPOSCategory.filteredOptions | async"
                                [value]="category.value"
                                #option>
                            <div class="option-wrapper d-flex justify-content-between align-items-center">
                                <div class="text-wrapper">
                                    <span class="text-element">{{category.viewValue}}</span>
                                    <input class="d-none"
                                           matInput
                                           #updatedVal
                                           (click)="$event.stopPropagation()"
                                           (keydown.enter)="posSystemService.updateCategory($event,
                                                                                           option,
                                                                                           category,
                                                                                           updatedVal,
                                                                                           pageIndex,
                                                                                           entriesPerPage,
                                                                                           order,
                                                                                           orderedColumn)"
                                           (keydown.space)="$event.stopPropagation()"
                                           [value]="category.viewValue" />
                                </div>
                                <div class="controls"
                                     *ngIf="category.value !== 'unkategorisiert' && !lockedCategory(category.viewValue)">
                                    <a class="fas fa-edit icon"
                                       (click)="selectBoxService.activateEditMode($event, option)"
                                       matTooltip="Kategorie ändern"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                    <a class="fas fa-check icon d-none"
                                       (click)="posSystemService.updateCategory($event,
                                                                               option,
                                                                               category,
                                                                               updatedVal,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn)"
                                       matTooltip="Änderung speichern"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                    <a class="fas fa-trash icon"
                                       (click)="posSystemService.deleteCategory($event,
                                                                               category,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn)"
                                       matTooltip="Kategorie löschen"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput
                           placeholder="Artikel"
                           formControlName="newName">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Kosten</mat-label>
                    <input matInput
                           placeholder="Kosten"
                           formControlName="newPrice">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <button class="float-right mt-4"
                        [disabled]="updateRequestSent && newArticleForm.valid"
                        mat-raised-button color="primary"
                        (click)="updateArticle()">
                    <i class="fas fa-exclamation-circle mr-2"
                       style="color: #b71c1c"
                       *ngIf="displayErroneousInputs">
                    </i>
                    Aktualisieren
                </button>
                <mat-icon [hidden]="!(updateRequestSent && newArticleForm.valid)" class="mr-2 float-right"
                          style="margin-top: 32px">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </div>

        </div>
    </form>
</div>

