import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CUSTOM_DATE_FORMATS} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {MatSelect} from '@angular/material/select';
import {PersonSelect} from '../../shared/controls/person-select';
import {SelectMultiSearch} from '../../shared/controls/select-box/select-multi-search';
import {FormGroup, Validators} from '@angular/forms';
import {AcquisitionAndGiveAwayComponent} from '../acquisition-and-give-away/acquisition-and-give-away.component';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {ofType} from '@ngrx/effects';
import * as fromAnimalActions from '../store/animals/animal.actions';
import * as fromCareSpaceActions from '../store/care-space/care-space.actions';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CRUD} from '../../shared/services/http/crud';
import {CareSpaceEntryModel} from '../../shared/models/animal/care-space-entry.model';
import * as moment from 'moment';
import {Order} from '../../shared/controls/data-table/ordering';
import {combineLatest} from 'rxjs/operators';
import * as fromPersonsActions from '../../persons/store/persons.actions';
import {PrintingDataAnimal} from '../../printing/templates/TemplateTypes';

@Component({
    selector: 'app-care-space',
    templateUrl: './care-space.component.html',
    styleUrls: ['./care-space.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
    ]
})
export class CareSpaceComponent extends AcquisitionAndGiveAwayComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('personSelect') public personSelect: MatSelect;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public selectAnimalsCtrl = new SelectMultiSearch();
    public personSelectSearch = new PersonSelect();
    public careSpaceForm: FormGroup;
    public requestSent = false;

    private animalSub: Subscription;
    private careSpaceSub: Subscription;
    private personSub: Subscription;

    ngOnInit(): void {
        this.animalService.animalsInTableChanged = new BehaviorSubject<[AnimalEntryModel[], boolean]>([null, false]);
        this.initForms();
        this.initEvents();
        this.initErrorHandling();
    }

    ngAfterViewInit(): void {
        this.personSelectSearch.init(this.personSelect);
    }

    ngOnDestroy(): void {
        this.selectAnimalsCtrl.destroy();
        this.personSelectSearch.destroy();

        if (this.animalSub) {
            this.animalSub.unsubscribe();
        }

        if (this.careSpaceSub) {
            this.careSpaceSub.unsubscribe();
        }

        if (this.personSub) {
            this.personSub.unsubscribe();
        }
    }

    public saveAndPrintCareSpaceAnimals(): void {
        if (!this.careSpaceForm.valid || this.animalsInTable.length === 0) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;
        const careSpaces: CareSpaceEntryModel[] = [];
        let selectedPerson = this.careSpaceForm.controls.person.value;
        if ('meta' in selectedPerson) {
            selectedPerson = this.fetchedPersons.find(p => p.id === +selectedPerson.value);
        }

        this.animalsInTable.forEach(animal => {
            const newCareSpaceEntry: CareSpaceEntryModel = {
                id: -1, // Dummy id
                animalId: animal.id,
                created: moment(this.careSpaceForm.controls.date.value).format('YYYY-MM-DD HH:mm:ss'),
                person: selectedPerson,
                dateBackToShelter: null
            };
            careSpaces.push(newCareSpaceEntry);
        });

        const filter = {
            personId: selectedPerson.id
        };
        this.careSpaceService.addCareSpaces(careSpaces, 1, -1, Order.NONE, '', filter);
        this.requestSent = true;
        this.personSelectSearch.createPersonSelectBox([]);

        const printingData: PrintingDataAnimal = {
            animals: this.animalsInTable,
            person: selectedPerson,
            date: moment(this.careSpaceForm.controls.date.value).format('YYYY-MM-DD')
        };
        this.printingService.dataFetched.next(printingData);
    }

    protected initEvents(): void {
        super.initEvents(true);

        this.animalSub = this.actions$.pipe(
            ofType(fromAnimalActions.SET_ANIMALS)
        ).subscribe((animalState: fromAnimalActions.SetAnimals) => {
            if (animalState.payload.crud === CRUD.UPDATE) {
                const updatedAnimals = animalState.payload.addedOrUpdatedAnimals;
                const updatedAnimal = updatedAnimals.slice().pop();
                this.animalService.animalsSelected.next([[updatedAnimal], false]);
                this.acquisitionGiveAwayForm.controls.animalId.setValue('');
                this.animalService.handleRequestSuccess(CRUD.UPDATE, 'Tier',
                    updatedAnimal.name + ' (' + updatedAnimal.id + ')');
            }
        });

        // noinspection JSDeprecatedSymbols
        this.careSpaceSub = this.actions$.pipe(
            ofType(fromCareSpaceActions.SET_CARE_SPACES),
            combineLatest(this.printingService.finishedPrinting)
        ).subscribe(([careSpaceState, finishedPrinting]: [fromCareSpaceActions.SetCareSpaces, boolean]) => {
            if (careSpaceState.payload.operation === CRUD.CREATE && finishedPrinting) {
                this.careSpaceService.handleRequestSuccess(careSpaceState.payload.operation, 'Pflegeplatz', '');
                this.printingService.finishedPrinting.next(false);
            }
            this.animalsInTable = [];
            this.animalService.animalsSelected.next(null);
            this.requestSent = false;
        });

        this.personSub = this.actions$.pipe(
            ofType(fromPersonsActions.SET_PERSONS)
        ).subscribe((personState: fromPersonsActions.SetPersons) => {
            const personResponse = personState.payload.persons.slice();
            if (personResponse.length > 0) {
                this.careSpaceForm.patchValue({
                    person: personResponse.slice()[0]
                });
            }
        });
    }

    private initForms(): void {
        this.careSpaceForm = this.formBuilder.group({
            date: [moment(), Validators.required],
            animalId: ['', Validators.required],
            person: ['', Validators.required]
        });
        this.initForm();
    }
}
