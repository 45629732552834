import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ChipBar} from '../../shared/controls/chip-bar';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {MatDatetimePickerInputEvent, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {SpeciesService} from '../store/species/species.service';
import {RaceService} from '../store/races/race.service';
import {SelectSearch} from '../../shared/services/select-box/select-search';
import {AccommodationService} from '../store/accommodation/accommodation.service';
import {SelectBoxUtility} from '../../shared/controls/select-box/select-box-utility';
import {AnimalDropdownOptions} from '../animal-dropdown-options';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {AttributeEntryModel} from '../../shared/models/attributes/attribute-entry.model';
import {AttributesService} from '../../attributes/attributes.service';
import {Actions, ofType} from '@ngrx/effects';
import {TupleHelper} from '../../shared/data-types/tuple';
import {SelectBoxService} from '../../shared/controls/select-box/select-box.service';
import {DialogType} from '../../dialogs/dialog-type';
import {Order} from '../../shared/controls/data-table/ordering';
import {ActionType, ActionTypesService} from '../store/action-types/action-types.service';
import {CharacteristicService} from '../store/characteristics/characteristic.service';
import {MatOption, MatOptionSelectionChange} from '@angular/material/core';
import {DictionaryEntryModel} from '../../shared/models/dictionary-entry.model';
import {AnimalsService} from '../animals.service';
import {CompactAttributeEntryModel} from '../../shared/models/animal/compact-attribute-entry.model';
import {MediaCentreComponent, MediaCentreMode} from '../../media-centre/media-centre.component';
import {MediaReferrer, MediaService} from '../../media-centre/media.service';
import {MediaEntryModel} from '../../shared/models/media-entry.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ImageUploadType} from '../../media-centre/ImageUploadType';
import {SnackbarService} from '../../shared/components/snackbar/snackbar.service';
import {SnackStatusType} from '../../shared/components/snackbar/snack-status-type';
import {take} from 'rxjs/operators';
import {ActionsService} from '../../actions/actions.service';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {ActionTypeEntryModel} from '../../shared/models/action-type-entry.model';
import {Constants, ShelterStatus} from '../../shared/constants';
import * as fromAnimalActions from '../store/animals/animal.actions';
import * as fromOptionsActions from '../../attributes/store/options/options.actions';
import * as fromAttributesActions from '../../attributes/store/attributes/attributes.actions';
import * as fromMediaMediaActions from '../../media-centre/store/media.actions';
import * as fromRaceActions from '../store/races/race.actions';
import * as fromSpeciesActions from '../store/species/species.actions';
import * as fromCharacteristicActions from '../store/characteristics/characteristic.actions';
import * as moment from 'moment';
import {CareSpaceService} from '../store/care-space/care-space.service';
import {CareSpaceEntryModel} from '../../shared/models/animal/care-space-entry.model';
import * as fromCareSpaceActions from '../store/care-space/care-space.actions';
import * as fromActionsActions from '../../actions/store/actions.actions';
import {CRUD} from '../../shared/services/http/crud';
import {MissingService} from '../store/missing/missing.service';
import {ZipCitySearchService} from '../../shared/services/zip-city-search/zip-city-search.service';
import {first} from 'rxjs/internal/operators/first';
import {AnimalActionConverter} from '../../shared/converter/animal-action-converter';
import {AuthService} from '../../auth/auth.service';
import {FdRaceService} from '../store/fd-races/fd-race.service';
import {MatButton} from '@angular/material/button';

@Component({
    selector: 'app-animal-add-edit',
    templateUrl: './animal-add-edit.component.html',
    styleUrls: ['./animal-add-edit.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class AnimalAddEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('characteristicInput') public characteristicInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') public matAutocomplete: MatAutocomplete;
    @ViewChild('datePickerBirthday') public datePickerBirthday: any;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    @ViewChild('singleSelectRace') public singleSelectRace: MatSelect;
    @ViewChild('characteristicsTrigger') public characteristicsTrigger: MatAutocompleteTrigger;
    @ViewChild('selectAccommodation', {static: true}) public selectAccommodation: MatSelect;
    @ViewChild('saveChangesBtn') public saveChangesBtn: MatButton;
    // Complex controls
    public selectSearchSpecies = new SelectSearch();
    public selectSearchRace = new SelectSearch();
    public selectSearchAccommodation = new SelectSearch();
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    // Simple controls
    public chipBar: ChipBar;
    // Forms
    public basicClaimsForm: FormGroup;
    public additionalFeatures: FormGroup;
    public animalAttributes: FormGroup;
    public synchronizeWithExternalSystem: FormGroup;
    public sponsoringForm: FormGroup;
    public careSpaceForm: FormGroup;

    public editMode = false;
    public shelterStatusOptions = AnimalDropdownOptions.shelterStatusOptions;
    public castrationStatusOptions = AnimalDropdownOptions.castrationStatusOptions;
    public genderOptions = AnimalDropdownOptions.genderOptions;
    public displayErroneousInputs = false;
    public animalEntry: AnimalEntryModel;
    public simpleDialogType = new DialogType();
    public pageIndex = 0;
    public entriesPerPage = 10;
    public order = Order.NONE;
    public orderedColumn = '';
    public requestSent = false;
    public allAttributes: AttributeEntryModel[];
    public mode = MediaCentreMode.LOAD_IMAGES;
    public MediaCentreMode = MediaCentreMode;
    public assignedImages = new Array<MediaEntryModel>();
    public sponsorLogoThumb: MediaEntryModel = null;
    public ImageUploadType = ImageUploadType;
    public latestTransaction: TransactionEntryModel; // Latest transaction for this animal.
    public allRaces: DropdownOption[];
    public allFDRaces: DropdownOption[];
    public allSpecies: DropdownOption[];
    public pensionPeriodExpired = false;
    public careSpaceRequest = false;
    public ActionType = ActionType;

    protected characteristicsInitialized = false;
    protected selectedRaceId = null;

    private readonly editTemporaryAnimal = false;
    private allAccommodations: DropdownOption[] = [];
    private selectedAttributeOptions = new Array<CompactAttributeEntryModel>();
    private avatarId: number = null;
    private filterModel: any;
    private filterSub: Subscription;
    private latestTransactionSub: Subscription;
    private selectBoxSub: Subscription;
    private fetchedTransactionSub: Subscription;
    private chipBarSub: Subscription;
    private attributesSub: Subscription;
    private animalSub: Subscription;
    private careSpaceSub: Subscription;
    private fetchedFDRacesSub: Subscription;
    private characteristicsSub: Subscription;
    private fetchedActionTypesSub: Subscription;
    private mediaSub: Subscription;
    private dialogType: DialogType = new DialogType();
    private selectedActionType: ActionTypeEntryModel;
    private fetchedActionTypes: ActionTypeEntryModel[] = [];
    private raceHasChanged = false;
    private birthdayValueChanged = false;
    private clickedQuickNavIcon = '';
    private backToShelterActionType = undefined;

    constructor(public speciesService: SpeciesService,
                public raceService: RaceService,
                public accommodationService: AccommodationService,
                public characteristicService: CharacteristicService,
                public selectBoxService: SelectBoxService,
                public zipCitySearchService: ZipCitySearchService,
                @Inject(MAT_DIALOG_DATA) protected data: any,
                protected formBuilder: FormBuilder,
                protected animalService: AnimalsService,
                protected missingAnimalService: MissingService,
                protected actions$: Actions,
                protected dialog: MatDialog,
                protected attributesService: AttributesService,
                protected authService: AuthService,
                protected fdRacesService: FdRaceService,
                private actionsService: ActionsService,
                private mediaService: MediaService,
                private actionTypesService: ActionTypesService,
                private careSpaceService: CareSpaceService,
                private snackbarService: SnackbarService,
                private dialogRef: MatDialogRef<AnimalAddEditComponent>) {

        this.chipBar = new ChipBar();
        if (this.data === null) {
            return;
        }

        this.selectedActionType = this.data.actionType;
        this.editMode = this.data.edit;
        if (this.editMode === true) {
            this.animalEntry = this.data.animal;
            this.selectedRaceId = this.animalEntry.race.id;
            this.editTemporaryAnimal = this.data.editTemporaryAnimal;
            this.avatarId = this.animalEntry.avatarId ? this.animalEntry.avatarId : null;
        } else { // Add mode
            this.animalEntry = this.createDummyAnimalEntry(this.data.temporaryAnimalId);
        }

        if (typeof data.pageIndex !== 'undefined' &&
            typeof data.entriesPerPage !== 'undefined' &&
            typeof data.order !== 'undefined' &&
            typeof data.orderedColumn !== 'undefined') {

            this.pageIndex = data.pageIndex;
            this.entriesPerPage = data.entriesPerPage;
            this.order = data.order;
            this.orderedColumn = data.orderedColumn;
        }
    }

    ngOnInit(): void {
        this.initForm();
        this.initSelectBox();
        this.initChipBar();
        if (this.editMode) {
            this.initAttributes();
        }
        this.setLatestTransaction();
        this.initMedia();
        this.initEvents();
        this.initErrorHandling();
    }

    ngAfterViewInit(): void {
        this.chipBar.setInput(this.characteristicInput);
        this.selectSearchSpecies.init(this.singleSelectSpecies);
        this.selectSearchRace.init(this.singleSelectRace);
    }

    ngOnDestroy(): void {
        this.selectSearchSpecies.destroy();
        this.selectSearchAccommodation.destroy();
        this.selectSearchRace.destroy();
        this.chipBar.setValues([]);

        if (this.selectBoxSub) {
            this.selectBoxSub.unsubscribe();
        }
        if (this.chipBarSub) {
            this.chipBarSub.unsubscribe();
        }
        if (this.attributesSub) {
            this.attributesSub.unsubscribe();
        }
        if (this.animalSub) {
            this.animalSub.unsubscribe();
        }
        if (this.latestTransactionSub) {
            this.latestTransactionSub.unsubscribe();
        }
        if (this.fetchedActionTypesSub) {
            this.fetchedActionTypesSub.unsubscribe();
        }
        if (this.careSpaceSub) {
            this.careSpaceSub.unsubscribe();
        }
        if (this.characteristicsSub) {
            this.characteristicsSub.unsubscribe();
        }
        if (this.fetchedTransactionSub) {
            this.fetchedTransactionSub.unsubscribe();
        }
        if (this.mediaSub) {
            this.mediaSub.unsubscribe();
        }
        if (this.fetchedFDRacesSub) {
            this.fetchedFDRacesSub.unsubscribe();
        }
    }

    public openDialog(dialogType: string): void {
        this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: dialogType,
                entity: 'Merkmal'
            }
        });
    }

    public deleteAccommodation($event: MouseEvent, accommodation: DropdownOption, selectedValue: string): void {
        this.accommodationService.deleteAccommodation($event,
            accommodation,
            this.animalEntry,
            this.additionalFeatures.controls.accommodation,
            selectedValue);
    }

    public addCharacteristic($event: MouseEvent, allRaces: DropdownOption[], raceId = null): void {
        let filter = null;
        if (this.selectedRaceId && this.selectedRaceId >= 0) {
            filter = {
                raceId: this.selectedRaceId,
            };
        } else if (raceId !== null) {
            filter = {
                raceId,
            };
        }
        this.characteristicService.addCharacteristic($event, allRaces, this.chipBar, filter);
    }

    public updateCharacteristic(event: MouseEvent,
                                matOption: MatOption,
                                characteristic: DropdownOption,
                                updatedCharacteristic: HTMLInputElement): void {
        if (this.animalEntry &&
            this.animalEntry.characteristics !== null &&
            this.animalEntry.characteristics.length > 0) {
            const updatedIndex = this.animalEntry.characteristics.findIndex(element => element.name === characteristic.viewValue);
            if (updatedIndex >= 0) {
                const clonedAnimalEntry = JSON.parse(JSON.stringify(this.animalEntry)) as AnimalEntryModel;
                clonedAnimalEntry.characteristics[updatedIndex].name = updatedCharacteristic.value;
                this.animalEntry = clonedAnimalEntry;
            }
        }

        const updatedModel: DictionaryEntryModel = {
            id: +characteristic.meta.find(tuple => tuple.key === 'id').value,
            name: updatedCharacteristic.value
        };

        let filter = null;
        if (this.selectedRaceId >= 0) {
            filter = {
                raceId: this.selectedRaceId
            };
        }

        this.characteristicService.updateCharacteristic(event,
            matOption,
            characteristic,
            updatedModel,
            filter);

        this.chipBar.replaceChip(characteristic.viewValue, updatedModel);
    }

    public deleteCharacteristic($event: MouseEvent,
                                characteristic: DropdownOption): void {
        let filter = null;
        let raceId = null;
        if (this.selectedRaceId && this.selectedRaceId >= 0) {
            raceId = this.selectedRaceId;
        } else {
            raceId = +characteristic.meta.find(tuple => tuple.key === 'raceId')?.value;
        }

        filter = {
            raceId
        };

        const dialogRef = this.characteristicService.deleteCharacteristic($event, characteristic, filter);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                if (this.animalEntry?.characteristics) {
                    /// Update animal entry object to reflect updated characteristics list
                    const remainingCharacteristics = this.animalEntry.characteristics
                        .filter(option => option.name !== characteristic.viewValue).slice();
                    this.animalEntry = {
                        ...this.animalEntry,
                        characteristics: remainingCharacteristics
                    };
                }
                this.chipBar.removeChip(characteristic.viewValue);
            }
        });
    }

    public addOrUpdateAnimal(): void {
        if (this.invalidForm()) {
            this.displayErroneousInputs = true;
            return;
        }

        const synchronizeExternalSystemFormValue = this.synchronizeWithExternalSystem.value;
        if (!synchronizeExternalSystemFormValue.willSynchronize) {
            const dialogRef = this.dialog.open(SimpleDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: this.dialogType.NO_SYNCHRONIZATION,
                    entity: 'Keine Synchronization mit Fundtierdatenbank'
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.addOrUpdate();
                }
            });
        } else {
            this.addOrUpdate();
        }
    }

    public attributeChanged(attributeName: string,
                            attribute: AttributeEntryModel,
                            $event: MatOptionSelectionChange,
                            optionId: number): void {
        if (typeof optionId === 'undefined') {
            return;
        }

        const options = TupleHelper
            .getValue(this.animalAttributes.controls[attributeName].meta, 'options') as CompactAttributeEntryModel[];
        const valueInList = this.selectedAttributeOptions.length > 0 && typeof this.selectedAttributeOptions
            .find(compactOption => compactOption.id === optionId) !== 'undefined';
        if ($event.source.selected && !valueInList) {
            const mappedCompactOption = options.find(compactOption => compactOption.id === optionId);
            if (mappedCompactOption) {
                this.selectedAttributeOptions.push(mappedCompactOption);
            }
        } else if (!$event.source.selected) {
            const selectedIndex = this.selectedAttributeOptions.findIndex(option => option.id === optionId);
            if (typeof selectedIndex !== 'undefined' && selectedIndex !== null) {
                this.selectedAttributeOptions.splice(selectedIndex, 1);
            }
        }
    }

    public changeImages(type: ImageUploadType, keepRatio = true): void {
        const dialogRef = this.dialog.open(MediaCentreComponent, {
            width: '1200px',
            panelClass: 'component-wrapper',
            data: {
                mode: MediaCentreMode.CHANGE_IMAGE,
                keepRatio
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (typeof result !== 'undefined' && result !== false && result.length > 0) {
                const imageIds = result as number[];
                this.mediaService.fetchMultipleMedia(imageIds, true, type, MediaReferrer.AnimalAddEdit);
            }
        });
    }

    public removeImage(media: MediaEntryModel): void {
        const assignedIndex = this.assignedImages.findIndex(item => item.id === media.id);
        if (assignedIndex >= 0) {
            this.assignedImages.splice(assignedIndex, 1);
            if (media.id === this.avatarId) {
                this.avatarId = null;
            }
        }
    }

    public removeSponsor(): void {
        this.sponsorLogoThumb = null;
    }

    public checkBoxChanged($event: MatCheckboxChange, identifier: string): void {
        if (identifier === 'premiumAnimal' && $event.checked) {
            this.additionalFeatures.get('sponsoredAnimal').setValue(false);
            this.sponsoringForm.enable();

            this.checkIfPremiumExists();
        }
        if (identifier === 'sponsoredAnimal' && $event.checked) {
            this.additionalFeatures.get('premiumAnimal').setValue(false);
            this.sponsoringForm.enable();
        }

        if (!this.additionalFeatures.controls.sponsoredAnimal.value &&
            !this.additionalFeatures.controls.premiumAnimal.value) {
            this.sponsoringForm.disable();
        }
    }

    public toggleAvatar(avatarId: number): void {
        this.avatarId = this.avatarId === avatarId ? null : avatarId;
    }

    public cancel(): void {
        this.unsavedChanges();
    }

    public takeBackToShelter(fromPensionstierAnnahme = true): void {
        if (fromPensionstierAnnahme) {
            this.backToShelterActionType = this.fetchedActionTypes.find(actionType => actionType.name === ActionType.BACK_TO_SHELTER.key);
            this.pensionPeriodExpired = false;
        } else {
            this.backToShelterActionType = this.fetchedActionTypes.find(actionType => actionType.name === ActionType.ABGABE.key);
        }

        const updatedAnimal: AnimalEntryModel = {
            ...this.animalEntry,
            shelter: ShelterStatus.IM_HEIM.key,
            type: this.backToShelterActionType
        };
        this.animalEntry = updatedAnimal;

        this.animalService.updateAnimal(
            updatedAnimal,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn,
            this.filterModel
        );

        this.basicClaimsForm.patchValue({
            shelter: ShelterStatus.IM_HEIM.key
        });
    }

    public selectSpecies(option: string): void {
        const selectedSpecies = option;
        if (!selectedSpecies || selectedSpecies === '') {
            return;
        }

        let speciesGroupName = 'Kleintier';
        switch (selectedSpecies) {
            case 'Hund':
                speciesGroupName = 'Hund';
                break;
            case 'Katze':
                speciesGroupName = 'Katze';
                break;
        }
        this.attributesSub = this.attributesService.fetchAttributes(speciesGroupName)
            .pipe(first())
            .subscribe(attributes => {
                this.allAttributes = attributes;
                this.appendDynamicControl(attributes);
            });
        this.chipBar.removeAllChips();
        this.chipBar.setValues([]);

        const species = this.allSpecies.find(s => s.value === selectedSpecies);
        const speciesId = +TupleHelper.getValue(species.meta, 'id');
        const filteredRaces = this.allRaces.filter(r => +TupleHelper.getValue(r.meta, 'speciesId') === speciesId);
        this.selectSearchRace.setValues(filteredRaces);
        this.basicClaimsForm.patchValue({
            race: ''
        });
        this.singleSelectSpecies.close();
    }

    public raceChanged(option: string): void {
        const name = option;
        if (!name || name === '') {
            return;
        }

        this.raceHasChanged = true;
        const selectedRace = this.selectSearchRace.values.find(race => race.value === name);
        this.singleSelectRace.close();

        if (selectedRace) {
            this.selectedRaceId = +TupleHelper.getValue(selectedRace.meta, 'id');

            const filter = {
                raceId: this.selectedRaceId,
                excludeLegacyCharacteristics: true
            };
            this.characteristicsSub = this.characteristicService.fetchCharacteristics(filter)
                .pipe(take(1))
                .subscribe(characteristics => {
                    this.chipBar.setValues(characteristics);
                    this.chipBar.removeAllChips();
                });
        }
    }

    public birthdayChanged(birthday: MatDatetimePickerInputEvent<moment.Moment>): void {
        const ageYears = moment().diff(birthday.value.format('YYYY-MM-DD'), 'years');
        const ageMonths = moment().diff(birthday.value.format('YYYY-MM-DD'), 'months') % 12;
        this.birthdayValueChanged = true;
        this.basicClaimsForm.patchValue({
            ageYears,
            ageMonths
        });
        this.birthdayValueChanged = false;
    }

    public ageChanged(): void {
        if (this.birthdayValueChanged) {
            return;
        }
        const years = +this.basicClaimsForm.controls.ageYears.value;
        const months = +this.basicClaimsForm.controls.ageMonths.value;
        if (typeof years === 'number' && typeof months === 'number') {
            let birthday;
            if (years === 0 && months === 0) {
                birthday = moment(this.basicClaimsForm.controls.birthday.value);
            } else {
                birthday = moment().subtract(years * 12 + months, 'months');
            }

            this.basicClaimsForm.patchValue({
                birthday: birthday.format('YYYY-MM-DD')
            });
        }
    }

    public clickedNavIcon($event: string): void {
        this.clickedQuickNavIcon = $event;
    }

    protected initChipBar(): void {
        let filter = null;
        if (this.animalEntry && this.animalEntry.race !== null) {
            filter = {
                raceId: this.animalEntry.race.id,
                excludeLegacyCharacteristics: true
            };
        }
        this.chipBarSub = this.characteristicService
            .fetchCharacteristics(filter)
            .subscribe(characteristics => {
                let raceId = -1;
                if (!this.characteristicsInitialized && this.editMode) {
                    this.chipBar.removeAllChips();
                    raceId = this.animalEntry.race.id;
                    this.animalEntry.characteristics.forEach(characteristic => {
                        this.chipBar.addChip(characteristic);
                    });
                } else {
                    const selectedRace = this.allRaces.find(r => r.value === this.basicClaimsForm.controls.race.value);
                    raceId = selectedRace ? +TupleHelper.getValue(selectedRace.meta, 'id') : -1;
                    if (!this.raceHasChanged) {
                        this.chipBar.focusCharacteristicsInput();
                    }
                    this.raceHasChanged = false;
                }

                this.characteristicsInitialized = true;
                this.chipBar.setValues(
                    characteristics.filter(c =>
                        +TupleHelper.getValue(c.meta, 'raceId') === raceId &&
                        TupleHelper.getValue(c.meta, 'isLegacy') === 'false')
                );
                this.characteristicsTrigger.closePanel();
            });
    }

    protected initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromOptionsActions.HTTP_FAIL,
                fromAttributesActions.HTTP_FAIL,
                fromRaceActions.HTTP_FAIL,
                fromSpeciesActions.HTTP_FAIL,
                fromCharacteristicActions.HTTP_FAIL)
        ).subscribe((httpFail: fromAttributesActions.HttpFail |
            fromOptionsActions.HttpFail |
            fromRaceActions.HttpFail |
            fromSpeciesActions.HttpFail |
            fromCharacteristicActions.HttpFail) => {
            switch (httpFail.type) {
                case fromSpeciesActions.HTTP_FAIL:
                    this.speciesService.handleRequestError(httpFail.payload.message);
                    break;
                case fromRaceActions.HTTP_FAIL:
                    this.raceService.handleRequestError(httpFail.payload.message);
                    break;
                case fromAttributesActions.HTTP_FAIL || fromOptionsActions.HTTP_FAIL:
                    this.attributesService.handleRequestError(httpFail.payload.message);
                    break;
                case fromCharacteristicActions.HTTP_FAIL:
                    this.characteristicService.handleRequestError(httpFail.payload.message, this.selectedRaceId, this.chipBar);
                    break;
                default:
                    this.animalService.handleRequestError('Unbekannter Fehler.');
            }
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }

    private unsavedChanges(): void {
        if (this.basicClaimsForm.dirty ||
            this.additionalFeatures.dirty ||
            this.animalAttributes.dirty ||
            this.synchronizeWithExternalSystem.dirty ||
            this.sponsoringForm.dirty) {
            const dialogRef = this.dialog.open(SimpleDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: this.dialogType.UNSAVED_CHANGES,
                    entity: 'Ungespeicherte Änderungen'
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.dialog.closeAll();
                }
            });
        } else {
            this.dialog.closeAll();
        }
    }

    private initForm(): void {
        const speciesName = this.animalEntry.species ? this.animalEntry.species.name : '';
        const raceName = this.animalEntry.race ? this.animalEntry.race.name : '';
        const name = !this.editMode ? Constants.Unknown : this.animalEntry.name;
        const chipNumber = !this.editMode ? Constants.Unknown : this.animalEntry.chipNumber;

        const birthday = this.animalEntry.birthday;
        const ageYears = birthday !== null && birthday !== '' ?
            moment().diff(birthday, 'years') : 0;
        const ageMonths = birthday !== null && birthday !== '' ?
            moment().diff(birthday, 'months') % 12 : 0;

        let shelter = this.animalEntry.shelter;
        if (!this.editMode) {
            if (this.selectedActionType.name === ActionType.ANNAHME_PENSIONSTIER.key) {
                shelter = ShelterStatus.PENSIONSTIER_IM_HEIM.key;
            } else {
                shelter = ShelterStatus.IM_HEIM.key;
            }
        }

        this.basicClaimsForm = this.formBuilder.group({
            lfnr: this.animalEntry.id < 0 ? 'n.a.' : this.animalEntry.id,
            name: [name, Validators.required],
            birthday: [birthday, Validators.required],
            ageYears,
            ageMonths,
            chipNumber: [chipNumber, [Validators.required, this.forbiddenChipNumber]],
            shelter: [shelter, Validators.required],
            species: [SelectBoxUtility.getOptionsValue(speciesName), Validators.required],
            race: [SelectBoxUtility.getOptionsValue(raceName), Validators.required],
            gender: [this.animalEntry.gender, Validators.required],
            castration: [SelectBoxUtility.getOptionsValue(this.animalEntry.castrated), Validators.required]
        });

        const accommodationName = this.animalEntry.accommodation ? this.animalEntry.accommodation.name : '';
        this.additionalFeatures = this.formBuilder.group({
            properties: this.animalEntry.properties,
            specialCriteria: this.animalEntry.specialCriteria,
            specialAttributes: new FormControl(null),
            accommodation: [SelectBoxUtility.getOptionsValue(accommodationName), Validators.required],
            accommodationDescription: this.animalEntry.accommodationDescription,
            noGivingAway: this.animalEntry.noGivingAway,
            noGivingAwayReason: this.animalEntry.noGivingAwayReason,
            hiddenOnWebsite: this.animalEntry.hiddenOnWebsite,
            premiumAnimal: this.animalEntry.premiumAnimal,
            sponsoredAnimal: this.animalEntry.sponsoredAnimal,
            reserved: this.animalEntry.reserved,
            reservedFor: this.animalEntry.reservedFor,
            reservedUntil: this.animalEntry.reservedUntil,
            description: this.animalEntry.description
        });

        this.animalAttributes = this.formBuilder.group({});
        this.synchronizeWithExternalSystem = this.formBuilder.group({
            willSynchronize: this.animalEntry.synchronizeExternalDatabase,
            fundtierDBRemark: this.animalEntry.fundtierDBRemark,
            fundtierDBId: this.animalEntry.fundtierId
        });

        this.sponsoringForm = this.formBuilder.group({
            sponsorName: [this.animalEntry.sponsorName, Validators.required],
            sponsorWebsite: [
                this.animalEntry.sponsorWebsite,
                [
                    Validators.required
                ]
            ],
            sponsorText: [this.animalEntry.sponsorText, Validators.required]
        });

        if (!this.animalEntry.sponsoredAnimal && !this.animalEntry.premiumAnimal) {
            this.sponsoringForm.disable();
        }

        if (this.animalEntry.careSpace !== null) {
            const careTaker = this.animalEntry.careSpace.person;
            this.careSpaceForm = this.formBuilder.group({
                careTakerName: careTaker.lastName + ' ' + careTaker.firstName,
                careTakerAddress: careTaker.street + ', ' + careTaker.city + '-' + careTaker.zip,
                careTakerPhone: careTaker.phone
            });
        }
    }

    private forbiddenChipNumber(control: FormControl): { [s: string]: boolean } {
        if (control.value !== Constants.Unknown && !/^\d{15}$/.test(control.value)) {
            return {chipNumberisForbidden: true};
        }
        return null;
    }

    private initSelectBox(): void {
        this.selectBoxSub = combineLatest([
            this.speciesService.fetchSpecies(),
            this.raceService.fetchRaces(),
            this.accommodationService.fetchAccommodations()
        ]).subscribe(([species, races, accommodations]) => {
            this.allRaces = races;
            this.allSpecies = species;
            this.allAccommodations = accommodations;
            this.selectSearchSpecies.setValues(species);

            if (this.animalEntry.species !== null || this.basicClaimsForm.controls.species.value !== '') {
                let speciesId = -1;
                if (this.basicClaimsForm.controls.species.value !== '') {
                    const selectedSpecies = species.find(s => s.value === this.basicClaimsForm.controls.species.value);
                    speciesId = typeof selectedSpecies !== 'undefined' ? +TupleHelper.getValue(selectedSpecies.meta, 'id')
                        : speciesId;
                } else {
                    speciesId = this.animalEntry.species.id;
                }
                this.selectSearchRace.setValues(races.filter(r => +TupleHelper.getValue(r.meta, 'speciesId') === speciesId));
            }

            this.selectSearchAccommodation.setValues(accommodations);
        });
    }

    private initAttributes(): void {
        const speciesName = this.animalEntry.species.name;
        const speciesGroupName = speciesName === 'Katze' || speciesName === 'Hund' ? speciesName : 'Kleintier';
        this.attributesSub = this.attributesService.fetchAttributes(speciesGroupName)
            .subscribe(attributes => {
                this.allAttributes = attributes;
                this.appendDynamicControl(attributes);
            });
    }

    private appendDynamicControl(attributes: AttributeEntryModel[]): void {
        this.selectedAttributeOptions = [];
        attributes.forEach((attribute: AttributeEntryModel) => {
            let selectedOptions = null;
            if (this.animalEntry.attributes) {
                selectedOptions = this.animalEntry.attributes
                    .filter(attr => attr.animal_attribute_id === attribute.id);
                if (selectedOptions.length > 0) {
                    this.selectedAttributeOptions.push(...selectedOptions.slice());
                }
            }

            const selOptions = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => option.name) : [];
            const dynamicCtrl = this.formBuilder.control(selOptions);
            this.animalAttributes.addControl(attribute.name, dynamicCtrl);

            const compactAttribute = attribute.options.map(option => {
                const compactOption: CompactAttributeEntryModel = {
                    id: option.id,
                    name: option.name,
                    animal_attribute_id: attribute.id
                };
                return compactOption;
            });

            dynamicCtrl.meta = [
                {key: 'options', value: compactAttribute}
            ];
        });
    }

    private initMedia(): void {
        this.mediaSub = this.actions$.pipe(
            ofType(fromMediaMediaActions.SET_MULTIPLE_MEDIA)
        ).subscribe((response: fromMediaMediaActions.SetMultipleMedia) => {
            if (response.payload.referrer !== MediaReferrer.AnimalAddEdit) {
                return;
            }

            const fetchedImages = response.payload.media.slice();

            if (response.payload.imageUploadType === ImageUploadType.ANIMAL_IMAGE) {
                this.assignedImages.push(...fetchedImages);
            } else if (response.payload.imageUploadType === ImageUploadType.SPONSOR) {
                this.sponsorLogoThumb = {
                    ...fetchedImages.pop()
                };
            } else { // ImageUploadType.NONE
                const assignedAnimalImages = fetchedImages.filter(img => img.id !== this.animalEntry.sponsorLogo);
                const sponsorLogo = fetchedImages.filter(img => img.id === this.animalEntry.sponsorLogo).pop();

                this.assignedImages.push(...assignedAnimalImages);
                this.sponsorLogoThumb = typeof sponsorLogo !== 'undefined' ? sponsorLogo : null;
            }

        });

        const imageIds = [...this.animalEntry.images];
        const sponsorId = this.animalEntry.sponsorLogo;

        if (sponsorId !== null && sponsorId >= 0) {
            imageIds.push(sponsorId);
        }

        if (imageIds && imageIds.length > 0) {
            this.mediaService.fetchMultipleMedia(imageIds, true, ImageUploadType.NONE, MediaReferrer.AnimalAddEdit);
        }
    }

    private invalidForm(): boolean {
        return (!this.basicClaimsForm.valid ||
            !this.additionalFeatures.valid ||
            !this.animalAttributes.valid ||
            !this.synchronizeWithExternalSystem.valid ||
            (!this.sponsoringForm.valid && !this.sponsoringForm.disabled));
    }

    private createDummyAnimalEntry(temporaryId: number): AnimalEntryModel {
        return {
            id: temporaryId,
            personId: -1,
            avatarId: null,
            attributes: null,
            accommodation: null,
            accommodationDescription: '',
            chipNumber: '',
            premiumAnimal: false,
            sponsoredAnimal: false,
            hiddenOnWebsite: false,
            noGivingAway: false,
            noGivingAwayReason: '',
            images: [],
            name: '',
            birthday: '',
            species: null,
            race: null,
            gender: '',
            castrated: '',
            careSpace: null,
            shelter: '',
            properties: '',
            specialCriteria: '',
            characteristics: null,
            type: null,
            dateCreated: '',
            reserved: false,
            reservedFor: '',
            reservedUntil: '',
            description: '',
            synchronizeExternalDatabase: false,
            fundtierDBRemark: '',
            fundtierId: null,
            sponsorName: '',
            sponsorLogo: -1,
            sponsorWebsite: '',
            sponsorText: '',
            lastMissingCheck: null,
            transactionHistory: []
        };
    }

    private checkIfPremiumExists(): void {
        const filterModel = {
            id: '',
            name: '',
            birthday: '',
            chipNumber: '',
            actionType: '',
            species: '',
            race: '',
            shelter: '',
            gender: '',
            castration: '',
            from: '',
            to: '',
            includeDeadAnimals: '',
            isPremium: 'true'
        };
        this.animalSub = this.animalService.fetchAnimals(-1, 0, Order.NONE, '', filterModel, false)
            .pipe(
                take(1)
            )
            .subscribe((response: any) => {
                if (response.animals.length > 0) {
                    const premiumAnimal = response.animals[0];
                    this.snackbarService.displaySnackbar(SnackStatusType.WARNING,
                        'Es ist bereits ein Premiumtier vorhanden! LfNr.: ' + premiumAnimal.id,
                        6);
                }
            });
    }

    private setLatestTransaction(): void {
        const filter = {
            animalId: this.animalEntry.id
        };

        this.latestTransactionSub = this.fetchLatestTransaction(filter).subscribe(transactions => {
            if (this.editMode) {
                if (transactions.length > 0) {
                    this.latestTransaction = transactions.slice().pop();
                    this.pensionPeriodExpired = this.animalService.pensionPeriodExpired(this.latestTransaction, this.animalEntry.type);
                }
            }
        });
    }

    private fetchLatestTransaction(filter: any): Observable<any> {
        return this.actionsService.fetchActions(-1, 1, Order.ASC, 'date_performed', filter)
            .pipe(
                take(1)
            );
    }

    private addOrUpdate(closeDialog = true): void {
        this.requestSent = true;
        this.displayErroneousInputs = false;

        const basicClaimsFormValue = this.basicClaimsForm.value;
        const speciesOption = this.allSpecies.find(item => item.value === basicClaimsFormValue.species);
        if (!speciesOption) {
            return;
        }

        const additionalFeaturesFormValue = this.additionalFeatures.value;

        const dictSpecies: DictionaryEntryModel = {
            id: +TupleHelper.getValue(speciesOption.meta, 'id'),
            name: speciesOption.viewValue
        };

        const racesOption = this.selectSearchRace.values.find(item => item.value === basicClaimsFormValue.race);
        if (!racesOption) {
            return;
        }
        const dictRace: DictionaryEntryModel = {
            id: +TupleHelper.getValue(racesOption.meta, 'id'),
            name: racesOption.viewValue
        };

        const accommodationOption = this.allAccommodations.find(item => item.value === additionalFeaturesFormValue.accommodation);
        const dictAccommodation: DictionaryEntryModel = {
            id: +TupleHelper.getValue(accommodationOption.meta, 'id'),
            name: accommodationOption.viewValue
        };

        const assignedImageIds = this.assignedImages.map(model => {
            return model.id;
        });

        const type = this.animalEntry.type;
        const synchronizeExternalSystemFormValue = this.synchronizeWithExternalSystem.value;

        const addOrUpdateAnimal: AnimalEntryModel = {
            ...this.animalEntry,
            avatarId: this.avatarId,
            type,
            name: basicClaimsFormValue.name,
            images: assignedImageIds,
            chipNumber: basicClaimsFormValue.chipNumber,
            birthday: moment(basicClaimsFormValue.birthday).format('YYYY-MM-DD'),
            species: dictSpecies,
            race: dictRace,
            shelter: basicClaimsFormValue.shelter,
            gender: basicClaimsFormValue.gender,
            castrated: basicClaimsFormValue.castration,
            specialCriteria: additionalFeaturesFormValue.specialCriteria,
            properties: additionalFeaturesFormValue.properties,
            characteristics: this.chipBar.getSelectedValues(), // Use control value
            accommodation: dictAccommodation,
            accommodationDescription: additionalFeaturesFormValue.accommodationDescription,
            hiddenOnWebsite: additionalFeaturesFormValue.hiddenOnWebsite,
            premiumAnimal: additionalFeaturesFormValue.premiumAnimal,
            sponsoredAnimal: additionalFeaturesFormValue.sponsoredAnimal,
            noGivingAway: additionalFeaturesFormValue.noGivingAway,
            noGivingAwayReason: additionalFeaturesFormValue.noGivingAwayReason,
            attributes: this.selectedAttributeOptions,
            reserved: additionalFeaturesFormValue.reserved,
            reservedFor: additionalFeaturesFormValue.reservedFor,
            reservedUntil: additionalFeaturesFormValue.reservedUntil ?
                moment(additionalFeaturesFormValue.reservedUntil).format('YYYY-MM-DD') : null,
            description: additionalFeaturesFormValue.description,
            synchronizeExternalDatabase: synchronizeExternalSystemFormValue.willSynchronize,
            fundtierDBRemark: synchronizeExternalSystemFormValue.fundtierDBRemark,
            fundtierId: synchronizeExternalSystemFormValue.fundtierDBId,
            sponsorName: this.sponsoringForm.controls.sponsorName.value,
            sponsorWebsite: this.sponsoringForm.controls.sponsorWebsite.value,
            sponsorText: this.sponsoringForm.controls.sponsorText.value,
            sponsorLogo: this.sponsorLogoThumb ? this.sponsorLogoThumb.id : null
        };

        if (this.editMode && !this.editTemporaryAnimal) {
            this.animalService.updateAnimal(addOrUpdateAnimal,
                this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn,
                this.filterModel);
            this.animalService.crudFinished.next(basicClaimsFormValue.name);
        }

        if (closeDialog) {
            this.dialogRef.close(addOrUpdateAnimal);
        }
    }

    private addTakeBackToShelterAction(actionTypeId: number): void {
        const backToShelterActionFormattedDate = {
            ...this.latestTransaction,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            actionTypeId
        };

        this.actionsService.addActions([backToShelterActionFormattedDate], -1, 1, Order.NONE, '');

        const backToShelterAction: TransactionEntryModel = {
            ...this.latestTransaction,
            actionTypeId
        };

        const newPrintingTransaction: TransactionEntryModel = {
            ...backToShelterAction,
            animal: null // To prevent circular dependency;
        };
        const updatedTransactionHistory = this.animalEntry.transactionHistory.slice();
        updatedTransactionHistory.push(newPrintingTransaction);
        this.animalEntry = {
            ...this.animalEntry,
            transactionHistory: updatedTransactionHistory.slice()
        };
    }

    private acquireAnimal(): void {
        this.careSpaceRequest = true;
        const updatedCareSpace: CareSpaceEntryModel = {
            ...this.animalEntry.careSpace,
            created: moment(this.animalEntry.careSpace.created).format('YYYY-MM-DD HH:mm:ss'),
            dateBackToShelter: moment().format('YYYY-MM-DD HH:mm:ss')
        };
        this.animalEntry = {
            ...this.animalEntry,
            careSpace: updatedCareSpace
        };
        this.careSpaceService.updateCareSpace(updatedCareSpace);
        this.careSpaceService.crudFinished.next(this.animalEntry);
    }

    private initEvents(): void {
        this.actions$.pipe(
            ofType(fromAnimalActions.SET_ANIMALS),
        ).subscribe((response: fromAnimalActions.SetAnimals) => {
            this.requestSent = false;

            if (this.backToShelterActionType) {
                this.addTakeBackToShelterAction(this.backToShelterActionType.id);
                this.animalService.crudFinished.next(response.payload.animals[0].name);
                this.backToShelterActionType = undefined;
            }
        });

        this.filterSub = this.animalService.filterEvent
            .subscribe(filterModel => {
                if (filterModel !== null) {
                    this.filterModel = filterModel;
                }
            });

        this.fetchedActionTypesSub = this.actionTypesService.fetchActionTypes()
            .subscribe(actionTypes => {
                this.fetchedActionTypes = actionTypes;
            });

        this.careSpaceSub = this.actions$.pipe(
            ofType(fromCareSpaceActions.SET_CARE_SPACES)
        ).subscribe((careSpaceState: fromCareSpaceActions.SetCareSpaces) => {
            const operation = careSpaceState.payload.operation;
            if (operation === CRUD.UPDATE) {
                this.careSpaceService.handleRequestSuccess(operation, 'Pflegeplatz', '');
                this.careSpaceRequest = false;
            }
        });

        this.fetchedTransactionSub = this.actions$.pipe(
            ofType(fromActionsActions.SET_ACTIONS)
        ).subscribe((actionsState: fromActionsActions.SetActions) => {
            if (this.clickedQuickNavIcon === this.simpleDialogType.GOING_TO_HEAVEN) {
                this.saveChangesBtn.disabled = false;
                if (actionsState.payload.actions.length > 0) {
                    this.latestTransaction = AnimalActionConverter.toTransactionEntryModel(actionsState.payload.actions.slice().pop());
                    this.animalEntry = {
                        ...this.animalEntry,
                        type: this.fetchedActionTypes
                            .find(actionType => actionType.id === this.latestTransaction.actionTypeId)
                    };
                    this.basicClaimsForm.patchValue({
                        shelter: ShelterStatus.NICHT_IM_HEIM.key
                    });
                    this.addOrUpdate(false);
                }
                this.clickedQuickNavIcon = '';
            }
        });

        this.fetchedFDRacesSub = this.fdRacesService.fetchFDRaces()
            .subscribe(fdRaces => {
                this.allFDRaces = fdRaces;
            });
    }
}
