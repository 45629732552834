import {DropdownOption} from '../shared/data-types/dropdown-option';
import {SelectMultiSearch} from '../shared/controls/select-box/select-multi-search';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, of, Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {SelectBoxUtility} from '../shared/controls/select-box/select-box-utility';
import {tap} from 'rxjs/internal/operators/tap';
import {MatOption} from '@angular/material/core';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {SelectBoxService} from '../shared/controls/select-box/select-box.service';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {ArticleEntryModel} from '../shared/models/pos/article-entry.model';
import {CRUD} from '../shared/services/http/crud';
import {Order} from '../shared/controls/data-table/ordering';
import {SelectSearch} from '../shared/services/select-box/select-search';
import {DictionaryEntryModel} from '../shared/models/dictionary-entry.model';
import * as fromArticlesActions from './store/articles/articles.actions';
import * as fromApp from '../store/app.reducer';
import * as fromCategoriesActions from './store/categories/catgories.actions';


@Injectable()
export class PosSystemService implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private categoryErrorEmerged = false;

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private selectBoxService: SelectBoxService,
                private snackbarService: SnackbarService) {
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public initArticleSelectBox(selectBox: SelectSearch, articles?: ArticleEntryModel[]): void {
        this.populateArticleSelectBox(selectBox, articles);
    }

    public initCategorySelectBox(selectBox: SelectMultiSearch<DropdownOption>): void {
        this.populateCategorySelectBox(selectBox);

        const strCallback = (cur: DropdownOption, result: string): boolean => {
            return cur.viewValue.toLowerCase().indexOf(result) > -1;
        };
        selectBox.valueChangeSubscribe(strCallback);
    }

    public addCategory(event: MouseEvent): void {
        event.stopPropagation();
        const dialogRef = this.selectBoxService.openAddDialog('Kategorie');

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== false) {
                const newModel: DictionaryEntryModel = {
                    id: -1,
                    name: result.name
                };

                this.store.dispatch(new fromCategoriesActions.AddCategory(newModel));
                this.actions$.pipe(
                    ofType(fromCategoriesActions.SET_CATEGORIES),
                    take(1)
                ).subscribe((categoryState: fromCategoriesActions.SetCategories) => {
                    if (categoryState.payload.operation === CRUD.CREATE) {
                        setTimeout(() => this.handleRequestSuccess(CRUD.CREATE, 'Kategorie', result.name));
                    }
                });
            }
        });
    }

    public updateCategory(event: MouseEvent,
                          matOption: MatOption,
                          category: DropdownOption,
                          updatedCategory: HTMLInputElement,
                          page: number,
                          size: number,
                          order: Order,
                          column: string): void {
        event.stopPropagation();

        if (updatedCategory.value === '' || updatedCategory.value === category.viewValue) {
            updatedCategory.value = category.viewValue;
            this.selectBoxService.disableEditMode(matOption);
            return;
        }

        const updatedModel: DictionaryEntryModel = {
            id: +category.meta.find(tuple => tuple.key === 'id').value,
            name: updatedCategory.value
        };
        this.selectBoxService.update(matOption, new fromCategoriesActions.UpdateCategory(updatedModel));
        this.actions$.pipe(
            ofType(fromCategoriesActions.SET_CATEGORIES),
            take(1),
            tap(() => {
                return this.store.dispatch(new fromArticlesActions.LoadArticles({
                    crud: CRUD.READ,
                    page,
                    size,
                    order,
                    column
                }));
            })
        ).subscribe(() => {
            if (!this.categoryErrorEmerged) {
                setTimeout(() => this.handleRequestSuccess(CRUD.UPDATE, 'Kategorie', updatedCategory.value));
            }
            this.categoryErrorEmerged = false;
        });
    }

    public deleteCategory(event: MouseEvent,
                          category: DropdownOption,
                          page: number,
                          size: number,
                          order: Order,
                          column: string): void {
        event.stopPropagation();
        const dialogRef = this.selectBoxService.delete('Kategorie', category.viewValue);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                const categoryId = +category.meta.find(tuple => tuple.key === 'id').value;
                this.store.dispatch(new fromCategoriesActions.DeleteCategory(categoryId));
                this.actions$.pipe(
                    ofType(fromCategoriesActions.SET_CATEGORIES),
                    take(1),
                    tap(() => {
                        return this.store.dispatch(new fromArticlesActions.LoadArticles({
                            crud: CRUD.READ,
                            page,
                            size,
                            order,
                            column
                        }));
                    })
                ).subscribe(() => {
                    setTimeout(() => this.handleRequestSuccess(CRUD.DELETE, 'Kategorie', category.viewValue));
                });
            }
        });
    }

    public addArticle(article: ArticleEntryModel, lastPage: number, entriesPerPage: number, order: Order, column: string): void {
        this.store.dispatch(new fromArticlesActions.AddArticle({
            article,
            page: lastPage,
            size: entriesPerPage,
            order,
            column
        }));
    }

    public updateArticle(updatedArticle: ArticleEntryModel,
                         size: number,
                         page: number,
                         order: Order,
                         column: string): void {
        this.store.dispatch(new fromArticlesActions.UpdateArticle({
            article: updatedArticle,
            size,
            page,
            order,
            column
        }));
    }

    public deleteArticle(articleId: number, size: number, page: number, order: Order, column: string): void {
        this.store.dispatch(new fromArticlesActions.DeleteArticle({
            articleId,
            size,
            page,
            order,
            column
        }));
    }

    public fetchArticles(size = -1, page = -1, order = Order.NONE, column = ''): Observable<ArticleEntryModel[]> {
        return this.store.select('articleList').pipe(
            take(1),
            map(articleState => {
                return articleState.articles;
            }),
            switchMap(articles => {
                if (articles.length === 0 || page >= 0) {
                    this.store.dispatch(new fromArticlesActions.LoadArticles({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column
                    }));
                    return this.actions$.pipe(
                        ofType(fromArticlesActions.SET_ARTICLES),
                        map((articleState: fromArticlesActions.SetArticles) => {
                            return articleState.payload.articles;
                        })
                    );
                } else {
                    return of(articles);
                }
            })
        );
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.categoryErrorEmerged = true;
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
        this.store.dispatch(new fromCategoriesActions.LoadCategories(CRUD.READ));
    }

    private populateArticleSelectBox(selectBox: SelectSearch, articleEntries?: ArticleEntryModel[]): void {
        if (articleEntries && articleEntries.length > 0) {
            const dropdownOptions: DropdownOption[] = articleEntries.map(element => {
                return {
                    value: SelectBoxUtility.getOptionsValue(element.name),
                    viewValue: element.name,
                    meta: [
                        {key: 'id', value: element.id.toString()},
                        {key: 'category', value: element.category.name}
                    ]
                };
            });

            selectBox.setValues(dropdownOptions);
        } else {
            const $articleObs = this.store.select('articleList').pipe(
                takeUntil(this.destroy$),
                map(articleState => {
                    return articleState.articles;
                }),
                switchMap(articles => {
                    if (articles.length === 0) {
                        this.store.dispatch(new fromArticlesActions.LoadArticles({
                            crud: CRUD.READ,
                            size: -1,
                            page: -1,
                            order: Order.NONE,
                            column: ''
                        }));
                        return this.actions$.pipe(
                            ofType(fromArticlesActions.SET_ARTICLES),
                            map((articleState: fromArticlesActions.SetArticles) => {
                                return articleState.payload;
                            })
                        );
                    } else {
                        return of(articles);
                    }
                }),
                map((articles: ArticleEntryModel[]) => {
                    return articles.map((element: ArticleEntryModel) => {
                        return {
                            value: SelectBoxUtility.getOptionsValue(element.name),
                            viewValue: element.name,
                            meta: [
                                {key: 'id', value: element.id.toString()},
                                {key: 'category', value: element.category.name}
                            ]
                        } as DropdownOption;
                    });
                })
            );

            selectBox.setValues($articleObs);
        }
    }

    private populateCategorySelectBox(selectSearch: SelectMultiSearch<DropdownOption>): void {
        const $categoryObs = this.store.select('categoryList').pipe(
            takeUntil(this.destroy$),
            map(categoryState => {
                return categoryState.categories;
            }),
            switchMap(categories => {
                if (categories.length === 0) {
                    this.store.dispatch(new fromCategoriesActions.LoadCategories(CRUD.READ));
                    return this.actions$.pipe(
                        ofType(fromCategoriesActions.SET_CATEGORIES),
                        map((categoryState: fromCategoriesActions.SetCategories) => {
                            return categoryState.payload.categories;
                        })
                    );
                } else {
                    return of(categories);
                }
            }),
            map((categories: DictionaryEntryModel[]) => {
                return categories.map((element: DictionaryEntryModel) => {
                    return {
                        value: SelectBoxUtility.getOptionsValue(element.name),
                        viewValue: element.name,
                        meta: [{key: 'id', value: element.id.toString()}]
                    } as DropdownOption;
                });
            })
        );

        selectSearch.init($categoryObs, this.selectBoxService.sortCallback);
    }
}
