import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {MediaEntryModel} from '../shared/models/media-entry.model';
import {EndpointService, HttpMethod} from '../shared/services/http/endpoint.service';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {CRUD} from '../shared/services/http/crud';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {ImageUploadType} from './ImageUploadType';
import * as fromApp from '../store/app.reducer';
import * as fromMediaActions from './store/media.actions';
import {Order} from '../shared/controls/data-table/ordering';

@Injectable()
export class MediaService {
    public progressStatus = new Subject<HttpEvent<any>>();
    public filterEvent = new Subject();

    constructor(private http: HttpClient,
                private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private endpointService: EndpointService,
                private snackbarService: SnackbarService) {
    }

    public upload(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.endpointService.media(HttpMethod.POST).pipe(
            take(1),
            switchMap(endpoint => {
                return this.http.post<any>(endpoint, formData, {
                        reportProgress: true,
                        observe: 'events'
                    }
                ).pipe(
                    map(event => {
                        this.progressStatus.next(event);
                    }),
                    catchError((error) => {
                        this.handleError(error);
                        return null;
                    })
                );
            })
        );
    }

    public fetchMedia(thumbnail = false,
                      size = -1,
                      page = -1,
                      order = Order.NONE,
                      column = '',
                      mediaFilter?: any): Observable<MediaEntryModel[]> {
        return this.store.select('mediaList').pipe(
            take(1),
            map(mediaState => {
                return mediaState.media;
            }),
            switchMap(media => {
                if (media.length === 0 || page >= 0) {
                    this.store.dispatch(new fromMediaActions.LoadMedia({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column,
                        thumbnail,
                        imgId: -1,
                        mediaFilter
                    }));
                    return this.actions$.pipe(
                        ofType(fromMediaActions.SET_MEDIA),
                        map((mediaState: fromMediaActions.SetMedia) => {
                            return mediaState.payload.media;
                        })
                    );
                } else {
                    return of(media);
                }
            })
        );
    }

    public fetchMultipleMedia(imgIds: number[],
                              thumbnail: boolean,
                              imageUploadType = ImageUploadType.NONE,
                              referrer = MediaReferrer.Misc): Observable<MediaEntryModel[]> {
        this.store.dispatch(new fromMediaActions.LoadMultipleMedia({
            crud: CRUD.READ,
            thumbnail,
            imgIds,
            imageUploadType,
            referrer
        }));
        return this.actions$.pipe(
            ofType(fromMediaActions.SET_MULTIPLE_MEDIA),
            take(1),
            map((mediaState: fromMediaActions.SetMedia) => {
                return mediaState.payload.media;
            })
        );
    }

    public updateMedia(mediaModel: MediaEntryModel,
                       thumbnail = false,
                       size = -1,
                       page = -1,
                       order = Order.NONE,
                       column = ''): void {
        this.store.dispatch(new fromMediaActions.UpdateMedia({
            media: mediaModel,
            size,
            page,
            order,
            column,
            thumbnail
        }));
    }

    public deleteMedia(imgId: number,
                       thumbnail = false,
                       size = -1,
                       page = -1,
                       order = Order.NONE,
                       column = ''): void {
        this.store.dispatch(new fromMediaActions.DeleteMedia({
            id: imgId,
            thumbnail,
            page,
            size,
            order,
            column
        }));
    }

    public handleError(errorMsg): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }
}

export enum MediaReferrer {
    AnimalList,
    AnimalAddEdit,
    Misc
}
