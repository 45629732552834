import {Component, OnInit} from '@angular/core';
import {PrintingDataAnimal, PrintingTemplate} from '../../TemplateTypes';
import {PersonEntryModel} from '../../../../shared/models/person-entry.model';
import {AnimalEntryModel} from '../../../../shared/models/animal/animal-entry.model';
import {ActionSpecificPrintingComponent} from '../action-specific-printing-component';
import {Age, CastrationStatus, Constants, Gender, Shelter} from '../../../../shared/constants';
import {CharacteristicService} from '../../../../animals/store/characteristics/characteristic.service';

@Component({
    selector: 'app-findling-printing',
    templateUrl: './findling-printing.component.html',
    styleUrls: ['./findling-printing.component.scss']
})
export class FindlingPrintingComponent implements ActionSpecificPrintingComponent, OnInit {
    public hideHeader = false;
    public printingTemplate = PrintingTemplate.ActionSpecific;
    public visible = false;
    public person: PersonEntryModel;
    public animals: AnimalEntryModel;
    public data: PrintingDataAnimal;
    public Age = Age;
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public shelter: Shelter;

    public shelterName = '';
    public address = '';
    public zipAndCity = '';
    public phone = '';
    public email = '';
    public website = '';

    constructor(public characteristicsService: CharacteristicService) {
    }

    ngOnInit(): void {
        if (this.shelter === Shelter.LINZ) {
            this.shelterName = Constants.LinzShelterName;
            this.address = Constants.LinzAddress;
            this.zipAndCity = Constants.LinzZipAndCity;
            this.phone = Constants.LinzPhone;
            this.email = Constants.LinzEmail;
            this.website = Constants.LinzWebsite;
        } else {
            this.shelterName = Constants.SteyrShelterName;
            this.address = Constants.SteyrAddress;
            this.zipAndCity = Constants.SteyrZipAndCity;
            this.phone = Constants.SteyrPhone;
            this.email = Constants.SteyrEmail;
            this.website = Constants.SteyrWebsite;
        }
    }
}
