import {PersonEntryModel} from '../models/person-entry.model';

export class PersonConverter {
    public static toHTTP(person: PersonEntryModel): any {
        const httpParams = {
            birthday: person.birthday,
            city: person.city,
            email: person.email,
            first_name: person.firstName,
            last_name: person.lastName,
            issuer: person.issuer,
            phone: person.phone,
            proof: person.proof,
            remark: person.remark,
            street: person.street,
            zip: person.zip
        };
        return httpParams;
    }

    public static toPersonEntryModel(person: any): PersonEntryModel {
        const mappedPerson: PersonEntryModel = {
            id: person.id,
            firstName: person.first_name,
            lastName: person.last_name,
            street: person.street,
            zip: person.zip,
            city: person.city,
            phone: person.phone,
            birthday: person.birthday,
            email: person.email,
            proof: person.proof,
            issuer: person.issuer,
            remark: person.remark
        };
        return mappedPerson;
    }
}
