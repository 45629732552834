<div class="title-bar">
    <h3>Profil bearbeiten</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Profildaten ändern</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="updateUserForm"
                          [ngClass]="{ 'displayErroneousInputs': displayUpdateUserErrors}"
                          (ngSubmit)="updateUser()">
                        <div class="row">
                            <div class="col-md-4 text-center">
                                <img src="assets/img/avatar/placeholder.png" width="233" height="233"
                                     class="preview img-fluid rounded-circle">
                                <div class="change-image mt-4">
                                    <button mat-raised-button color="primary"
                                            (click)="changeImage()">
                                        Bild ändern...
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-form-field>
                                            <mat-label>E-Mail</mat-label>
                                            <input matInput
                                                   readonly="readonly"
                                                   placeholder="E-Mail Adresse"
                                                   formControlName="email">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Vorname</mat-label>
                                            <input matInput
                                                   placeholder="Vorname"
                                                   formControlName="firstName">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field>
                                            <mat-label>Nachname</mat-label>
                                            <input matInput
                                                   placeholder="Nachname"
                                                   formControlName="lastName">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <button mat-raised-button color="primary" class="float-right mt-3">
                                            Speichern
                                            <i class="fas fa-exclamation-circle mr-2"
                                               style="color: #b71c1c"
                                               *ngIf="displayUpdateUserErrors">
                                            </i>
                                        </button>
                                        <mat-icon [hidden]="!(updateUserRequestSent && updateUserForm.valid)"
                                                  class="mr-2 float-right"
                                                  style="margin-top: 32px">
                                            <mat-spinner color="accent" diameter="20"></mat-spinner>
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Passwort ändern</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="updatePasswordForm"
                          [ngClass]="{ 'displayErroneousInputs': displayPasswordErrors}"
                          (ngSubmit)="updatePassword()">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field>
                                    <mat-label>Neues Passwort</mat-label>
                                    <input matInput
                                           type="password"
                                           placeholder="Neues Passwort"
                                           formControlName="newPassword">
                                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field>
                                    <mat-label>Neues Passwort Wiederholung</mat-label>
                                    <input matInput
                                           type="password"
                                           placeholder="Neues Passwort Wiederholung"
                                           formControlName="newPasswordRepetition">
                                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 d-flex align-items-center justify-content-between mt-3">
                                <button mat-raised-button
                                        color="primary">
                                    Speichern
                                    <i class="fas fa-exclamation-circle mr-2"
                                       style="color: #b71c1c"
                                       *ngIf="displayUpdateUserErrors">
                                    </i>
                                </button>
                                <mat-icon [hidden]="!(updatePasswordRequestSent && updatePasswordForm.valid)"
                                          class="mr-2 float-right"
                                          style="margin-top: 32px">
                                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                                </mat-icon>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
