<div id="missing-animals-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="occupationFrom.open()"
                           [ngxMatDatetimePicker]="occupationFrom"
                           placeholder="Von"
                           formControlName="occupationFrom"
                           aria-label="Von"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="occupationFrom"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #occupationFrom
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="occupationTo.open()"
                           [ngxMatDatetimePicker]="occupationTo"
                           placeholder="Bis"
                           formControlName="occupationTo"
                           aria-label="Bis"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="occupationTo"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #occupationTo
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col text-left">
                <button mat-raised-button
                        class="mt-4"
                        color="primary">
                    Berechnen
                </button>
            </div>
        </div>

    </form>
</div>
