import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {CRUD} from '../../../shared/services/http/crud';
import {Order} from '../../../shared/controls/data-table/ordering';
import * as fromUsersActions from './users.actions';
import {UserEntryModel} from '../../../shared/models/user-entry.model';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class UsersEffects {
    @Effect()
    loadUsers$ = this.actions$.pipe(
        ofType(fromUsersActions.LOAD_USERS),
        switchMap((userState: fromUsersActions.LoadUsers) => {
            const page = userState.payload.page;
            const size = userState.payload.size;
            const order = userState.payload.order;
            const orderColumn = userState.payload.column;
            const addedOrUpdatedUser = userState.payload.addedOrUpdatedUser;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            return this.endpointService.users(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params});
                    }),
                    map(response => {
                        const totalElements = response.data.pagination.totalElements;
                        const lastPage = response.data.pagination.pages;
                        const mappedUsers = response.data.data.map(user => {
                            const userModel: UserEntryModel = {
                                id: user.id,
                                isAdmin: user.is_admin,
                                firstName: user.first_name,
                                lastName: user.last_name,
                                shelter: user.shelter,
                                email: user.email,
                                password: '',
                                shelterId: user.shelter_id
                            };
                            return userModel;
                        });

                        return new fromUsersActions.SetUsers({
                            users: mappedUsers,
                            totalElements,
                            crud: userState.payload.crud,
                            lastPage,
                            addedOrUpdatedUser
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    addUser$ = this.actions$.pipe(
        ofType(fromUsersActions.ADD_USER),
        switchMap((userState: fromUsersActions.AddUser) => {
            return this.endpointService.users(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    const addedUser: UserEntryModel = {
                        id: userState.payload.user.id,
                        isAdmin: userState.payload.user.isAdmin,
                        email: userState.payload.user.email,
                        shelter: userState.payload.user.shelter,
                        password: userState.payload.user.password,
                        firstName: userState.payload.user.firstName,
                        lastName: userState.payload.user.lastName,
                        shelterId: userState.payload.user.shelterId
                    };

                    return this.http
                        .post<any>(endpoint,
                            {
                                first_name: userState.payload.user.firstName,
                                last_name: userState.payload.user.lastName,
                                email: userState.payload.user.email,
                                password: userState.payload.user.password,
                                confirm_password: userState.payload.user.password,
                                shelter_id: userState.payload.user.shelterId
                            }
                        ).pipe(
                            map(() => {
                                return new fromUsersActions.LoadUsers(
                                    {
                                        crud: CRUD.CREATE,
                                        order: userState.payload.order,
                                        column: userState.payload.column,
                                        size: userState.payload.size,
                                        page: userState.payload.page,
                                        addedOrUpdatedUser: addedUser
                                    }
                                );
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateUser$ = this.actions$.pipe(
        ofType(fromUsersActions.UPDATE_USER),
        switchMap((userState: fromUsersActions.UpdateUser) => {
            return this.endpointService.users(HttpMethod.PUT, userState.payload.user.id).pipe(
                take(1),
                switchMap(endpoint => {
                    const updatedUser: UserEntryModel = {
                        id: userState.payload.user.id,
                        isAdmin: userState.payload.user.isAdmin,
                        email: userState.payload.user.email,
                        firstName: userState.payload.user.firstName,
                        lastName: userState.payload.user.lastName,
                        shelterId: userState.payload.user.shelterId,
                        shelter: userState.payload.user.shelter
                    };

                    return this.http
                        .put<any>(endpoint,
                            {
                                first_name: userState.payload.user.firstName,
                                last_name: userState.payload.user.lastName,
                                shelter_id: userState.payload.user.shelterId
                            }
                        ).pipe(
                            map(() => {
                                return new fromUsersActions.LoadUsers({
                                    crud: CRUD.UPDATE,
                                    order: userState.payload.order,
                                    column: userState.payload.column,
                                    size: userState.payload.size,
                                    page: userState.payload.page,
                                    addedOrUpdatedUser: updatedUser
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updatePassword$ = this.actions$.pipe(
        ofType(fromUsersActions.UPDATE_PASSWORD),
        switchMap((userState: fromUsersActions.UpdatePassword) => {
            return this.endpointService.users(HttpMethod.PUT, userState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    const updatedUser: UserEntryModel = {
                        id: userState.payload.id,
                        isAdmin: userState.payload.isAdmin,
                        email: userState.payload.email,
                        firstName: userState.payload.firstName,
                        lastName: userState.payload.lastName,
                        password: userState.payload.password,
                        shelterId: userState.payload.shelterId,
                        shelter: userState.payload.shelter
                    };

                    return this.http
                        .put<any>(endpoint,
                            {
                                password: userState.payload.password,
                                confirm_password: userState.payload.password,
                            }
                        ).pipe(
                            map(() => {
                                return new fromUsersActions.LoadUsers({
                                    crud: CRUD.UPDATE,
                                    order: Order.NONE,
                                    column: '',
                                    size: -1,
                                    page: 1,
                                    addedOrUpdatedUser: updatedUser
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteUser$ = this.actions$.pipe(
        ofType(fromUsersActions.DELETE_USER),
        switchMap((useState: fromUsersActions.DeleteUser) => {
            return this.endpointService.users(HttpMethod.DELETE, useState.payload.userId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromUsersActions.LoadUsers({
                                    crud: CRUD.DELETE,
                                    order: useState.payload.order,
                                    column: useState.payload.column,
                                    size: useState.payload.size,
                                    page: useState.payload.page
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromUsersActions.HttpFail> => {
        return handleHTTPError(errorRes, fromUsersActions, 'Benutzern', this.loggingService);
    };
}
