import * as fromAutoCompleteActions from './auto-complete.actions';

export interface State {
    filteredOptions: [number, number | string][];
}

const initialState: State = {
    filteredOptions: [],
};

export function autoCompleteReducer(state = initialState, action: fromAutoCompleteActions.AutoCompleteActions): State {
    switch (action.type) {
        case fromAutoCompleteActions.SET_AUTOCOMPLETE_OPTIONS:
            return {
                ...state,
                filteredOptions: action.payload
            };
        case fromAutoCompleteActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
