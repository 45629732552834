import {Action} from '@ngrx/store';
import {ArticleEntryModel} from '../../../shared/models/pos/article-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_ARTICLES = '[Articles] Load Articles';
export const SET_ARTICLES = '[Articles] Set Articles';
export const ADD_ARTICLE = '[Articles] Add Article';
export const UPDATE_ARTICLE = '[Articles] Update Article';
export const DELETE_ARTICLE = '[Articles] Delete Article';
export const HTTP_FAIL = '[Articles] Http Fail';

export class LoadArticles implements Action {
    readonly type = LOAD_ARTICLES;

    constructor(public payload: { crud: CRUD, size: number, page: number, order: Order, column: string }) {
    }
}

export class SetArticles implements Action {
    readonly type = SET_ARTICLES;

    constructor(public payload: { articles: ArticleEntryModel[], crud: CRUD, totalElements: number, lastPage: number }) {
    }
}

export class AddArticle implements Action {
    readonly type = ADD_ARTICLE;

    constructor(public payload: { article: ArticleEntryModel, size: number, page: number, order: Order, column: string }) {
    }
}

export class UpdateArticle implements Action {
    readonly type = UPDATE_ARTICLE;

    constructor(public payload: {
        article: ArticleEntryModel,
        size: number, page: number,
        order: Order,
        column: string
    }) {
    }
}

export class DeleteArticle implements Action {
    readonly type = DELETE_ARTICLE;

    constructor(public payload: { articleId: number, size: number, page: number, order: Order, column: string }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type ArticlesActions =
    LoadArticles
    | SetArticles
    | AddArticle
    | UpdateArticle
    | DeleteArticle
    | HttpFail;
