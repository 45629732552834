<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Bisherige Pflegeplatztransaktionen
    </h3>
    <div class="position-absolute mt-4">
        <span>LFNr.: {{animal.id}}</span>
    </div>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="true" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-body">
            <ng-container *ngFor="let careSpace of careSpaces">
                <!-- Incoming entries -->
                <div class="care-space-entry incoming" *ngIf="careSpace.dateBackToShelter !== null">
                    <div class="body">
                        <div class="header row d-flex justify-content-between">
                            <div class="col-6">
                                <h4><span><i class="fas fa-home mr-3"></i>Zurück ins Tierheim</span></h4>
                            </div>

                            <div class="col-6 d-flex justify-content-end">
                                <div>{{careSpace.dateBackToShelter | date:'dd.MM.yyyy'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Outgoing entries -->
                <div class="care-space-entry outgoing">
                    <div class="body">
                        <div class="header row d-flex justify-content-between">
                            <div class="col-6">
                                <h4><span><i class="fas fa-male mr-3"></i>Übergabe zum Pflegeplatz</span></h4>
                            </div>

                            <div class="col-6 d-flex justify-content-end">
                                <div>{{careSpace.created | date:'dd.MM.yyyy'}}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <h5>Pleger/in</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row person-number">
                                    <div class="col-md-5">Personennummer:</div>
                                    <div class="col">{{careSpace.person.id}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">Name:</div>
                                    <div class="col">{{careSpace.person.lastName}} {{careSpace.person.firstName}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">Adresse:</div>
                                    <div class="col">{{careSpace.person.street}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">Ort:</div>
                                    <div class="col">{{careSpace.person.zip}} - {{careSpace.person.city}}</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-md-5">Geburtsdatum:</div>
                                    <div class="col">{{careSpace.person.birthday | date:'dd.MM.yyyy'}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">Telefonnummer:</div>
                                    <div class="col">{{careSpace.person.phone}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">Nachweis:</div>
                                    <div class="col">{{careSpace.person.proof}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">Aussteller:</div>
                                    <div class="col">{{careSpace.person.issuer}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row text-center" *ngIf="careSpaces !== undefined && careSpaces.length === 0">
                <div class="col-md-12">
                    <h4>Keine Pflegeplätze für dieses Tier gefunden.</h4>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <button class="float-right" mat-raised-button color="primary" (click)="cancel()">
                        Schließen
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
