import {BookingEntryModel} from '../../../shared/models/pos/booking-entry.model';
import * as BookingsActions from './booking.actions';

export interface State {
    bookings: BookingEntryModel[];
}

const initialState: State = {
    bookings: []
};

export function bookingReducer(state = initialState, action: BookingsActions.BookingActions): State {
    switch (action.type) {
        case BookingsActions.SET_BOOKINGS:
            return {
                ...state,
                bookings: [...action.payload.bookings]
            };
        case BookingsActions.ADD_BOOKING:
            return {
                ...state,
                bookings: [...state.bookings, action.payload.booking]
            };
        case BookingsActions.ADD_BOOKINGS:
            return {
                ...state,
                bookings: [...state.bookings, ...action.payload.bookings]
            };
        case BookingsActions.UPDATE_BOOKING:
            const updatedBooking = {
                ...state.bookings.find(booking => booking.id === action.payload.booking.id),
                ...action.payload
            };

            const updatedBookings = [...state.bookings];
            const arrIndex = updatedBookings.findIndex(element => element.id === updatedBooking.id);
            updatedBookings[arrIndex] = updatedBooking;

            return {
                ...state,
                bookings: updatedBookings
            };
        case BookingsActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
