import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {DialogType} from '../../dialogs/dialog-type';
import {Observable, of, Subscription} from 'rxjs';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {PersonDialogComponent} from '../person-dialog/person-dialog.component';
import {Actions, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {PersonsService} from '../persons.service';
import {Order} from '../../shared/controls/data-table/ordering';
import {AutoCompleteService} from '../../shared/services/auto-complete/auto-complete.service';
import {MatDialogRef} from '@angular/material/dialog';
import * as fromPersonsActions from '../store/persons.actions';
import * as moment from 'moment';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {SnackStatusType} from '../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../shared/components/snackbar/snackbar.service';
import {ZipCity, ZipCitySearchService} from '../../shared/services/zip-city-search/zip-city-search.service';
import {TupleHelper} from '../../shared/data-types/tuple';
import {MatOptionSelectionChange} from '@angular/material/core';
import {isArray} from 'rxjs/internal-compatibility';

@Component({
    selector: 'app-person-form',
    templateUrl: './person-form.component.html',
    styleUrls: ['./person-form.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class PersonFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public dialogType: string;
    @Input() public dialogRef: MatDialogRef<PersonDialogComponent>;
    @Input() public lastPage = 1;
    @Input() public entriesPerPage = 10;
    @Input() public order = Order.NONE;
    @Input() public orderedColumn = '';
    @Input() public person: PersonEntryModel = null;
    @Input() public readonly = false;

    public personForm: FormGroup;
    public displayErroneousInputs = false;
    public label: string;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public loading = false;
    public simpleDialogType = new DialogType();
    public filteredPersonIds$: Observable<[number, string | number][]>;
    public filteredPersonLastNames$: Observable<[number, string | number][]>;
    public filteredPersonFirstNames$: Observable<[number, string | number][]>;
    public filteredPersonStreets$: Observable<[number, string | number][]>;
    public filteredPersonZips$: Observable<[number, string | number][]>;
    public filteredPersonCities$: Observable<[number, string | number][]>;
    public filteredPersonEmails$: Observable<[number, string | number][]>;
    public filteredPersonProofs$: Observable<[number, string | number][]>;
    public filteredPersonIssuers$: Observable<[number, string | number][]>;
    public ZipCity = ZipCity;

    private personsSub: Subscription;

    constructor(public personService: PersonsService,
                public actions$: Actions,
                public zipCitySearchService: ZipCitySearchService,
                private snackbarService: SnackbarService,
                private autoCompleteService: AutoCompleteService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initErrorHandling();
        if (this.dialogType === this.simpleDialogType.SEARCH_PERSON) {
            this.initAutoComplete();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.person.currentValue !== 'undefined') {
            this.person = changes.person.currentValue;
            this.initForm();
        }
    }

    ngOnDestroy(): void {
        if (this.personsSub) {
            this.personsSub.unsubscribe();
        }
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (this.dialogType === this.simpleDialogType.NEW_PERSON) {
            this.createPerson();
        } else if (this.dialogType === this.simpleDialogType.SEARCH_PERSON) {
            this.searchPerson();
        } else if (this.dialogType === this.simpleDialogType.EDIT_PERSON) {
            this.updatePerson();
        }
    }

    public zipCityChanged(event: MatOptionSelectionChange): void {
        const option = event.source.value;
        const city = TupleHelper.getValue(option.meta, 'city');
        this.personForm.patchValue({
            city
        });
    }

    private createPerson(): void {
        if (!this.personForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const formValue = this.personForm.value;
        const age = formValue.birthday !== '' ? moment().diff(formValue.birthday, 'years') : null;
        if (age != null && (age < 18 || age > 150)) {
            this.snackbarService.displaySnackbar(SnackStatusType.ERROR, 'Ungültiges Alter: ' + age + ' Jahre', 10);
            return;
        }

        let zip = '';
        if (typeof formValue.zip !== 'string') {
            zip = TupleHelper.getValue(formValue.zip.meta, 'zip');
        } else {
            zip = formValue.zip;
        }

        this.loading = true;
        const personEntry: PersonEntryModel = {
            id: -1,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            street: formValue.street,
            zip,
            city: formValue.city,
            phone: formValue.phone,
            birthday: formValue.birthday !== '' && formValue.birthday !== null ?
                moment(formValue.birthday).format('YYYY-MM-DD') : null,
            email: formValue.email,
            proof: formValue.proof,
            issuer: formValue.issuer,
            remark: formValue.remark
        };

        this.personService.addPerson(personEntry, this.lastPage, this.entriesPerPage, this.order, this.orderedColumn);
        this.personService.crudFinished.next(formValue.firstName + ' ' + formValue.lastName);
        this.dialogRef.close();
    }

    private searchPerson(): void {
        if (!this.personForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;

        const formValue = this.personForm.value;
        let zip = '';
        if (typeof formValue.zip !== 'string') {
            zip = TupleHelper.getValue(formValue.zip.meta, 'zip');
        } else {
            zip = formValue.zip;
        }

        const searchModel = {
            id: formValue.id === null ? '' : formValue.id,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            street: formValue.street,
            zip,
            city: formValue.city,
            phone: formValue.phone,
            birthday: formValue.birthday !== '' ?
                moment(formValue.birthday).format('YYYY-MM-DD') : '',
            email: formValue.email,
            proof: formValue.proof,
            issuer: formValue.issuer,
            remark: formValue.remark
        };

        this.dialogRef.close(searchModel);
    }

    private updatePerson(): void {
        if (!this.personForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const age = this.personForm.value.birthday !== '' ? moment().diff(this.personForm.value.birthday, 'years') : null;
        if (age != null && (age < 18 || age > 150)) {
            this.snackbarService.displaySnackbar(SnackStatusType.ERROR, 'Ungültiges Alter: ' + age + ' Jahre', 10);
            return;
        }

        this.displayErroneousInputs = false;

        const formValue = this.personForm.value;
        let zip = '';
        if (typeof formValue.zip !== 'string') {
            zip = TupleHelper.getValue(formValue.zip.meta, 'zip');
        } else {
            zip = formValue.zip;
        }

        const updatedFormValue = {
            ...this.personForm.value,
            zip,
            birthday: this.personForm.value.birthday !== '' ?
                moment(this.personForm.value.birthday).format('YYYY-MM-DD') : null
        };
        this.dialogRef.close(updatedFormValue);
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromPersonsActions.HTTP_FAIL)
        ).subscribe(() => {
            this.loading = false;
        });
    }

    private initForm(): void {
        let required = true;
        if (this.dialogType === this.simpleDialogType.SEARCH_PERSON) {
            required = false;
        }
        this.personForm = this.personService.createForm(this.person, required);
    }

    private initAutoComplete(): void {
        this.filteredPersonIds$ = this.personForm.controls.id.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'id', val);
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonLastNames$ = this.personForm.controls.lastName.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService
                            .fetchOptions('person', 'last_name', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonFirstNames$ = this.personForm.controls.firstName.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'first_name', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonStreets$ = this.personForm.controls.street.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'street', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonZips$ = this.personForm.controls.zip.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'zip', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonCities$ = this.personForm.controls.city.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'city', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonEmails$ = this.personForm.controls.email.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'email', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonProofs$ = this.personForm.controls.proof.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'proof', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredPersonIssuers$ = this.personForm.controls.issuer.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autoCompleteService.fetchOptions('person', 'issuer', val)
                            .pipe(
                                map(result => {
                                    return this.filterDuplicates(result);
                                })
                            );
                    } else {
                        return of();
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );
    }

    private filterDuplicates(origArray: [number, string | number][]): any {
        let result = [];
        if (origArray.length > 0 && isArray(origArray)) {
            result = origArray.filter((item, pos, ary) => {
                const test = !pos || item[1] !== ary[pos - 1][1];
                return !pos || item[1] !== ary[pos - 1][1];
            });
        }
        return result;
    }

}
