import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import {CRUD} from '../../../shared/services/http/crud';
import * as fromRaceActions from './race.actions';
import {LoadRaces} from './race.actions';
import {TupleHelper} from '../../../shared/data-types/tuple';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class RacesEffects {
    @Effect()
    loadRaces$ = this.actions$.pipe(
        ofType(fromRaceActions.LOAD_RACES),
        switchMap((racesState: LoadRaces) => {
            return this.endpointService.races(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint).pipe(
                            map(response => response.data)
                        );
                }),
                map(response => {
                    const raceModels = response.map(entry => {
                        const mappedModel: DictionaryEntryModel = {
                            ...entry,
                            meta: [
                                {key: 'speciesId', value: entry.species_id.toString()}
                            ]
                        };
                        return mappedModel;
                    });
                    return new fromRaceActions.SetRaces({
                        races: raceModels,
                        operation: racesState.payload.operation,
                        alteredRace: racesState.payload.alteredRace
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addRace$ = this.actions$.pipe(
        ofType(fromRaceActions.ADD_RACE),
        switchMap((raceState: fromRaceActions.AddRace) => {
            return this.endpointService.races(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: raceState.payload.name,
                                species_id: TupleHelper.getValue(raceState.payload.meta, 'speciesId')
                            }
                        ).pipe(
                            map((response) => {
                                const newRace: DictionaryEntryModel = {
                                    id: response.data.id,
                                    name: response.data.name,
                                    meta: [
                                        {key: 'speciesId', value: response.data.species_id}
                                    ]
                                };

                                return new fromRaceActions.LoadRaces({
                                    operation: CRUD.CREATE,
                                    alteredRace: newRace
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateRace$ = this.actions$.pipe(
        ofType(fromRaceActions.UPDATE_RACE),
        switchMap((raceState: fromRaceActions.UpdateRace) => {
            return this.endpointService.races(HttpMethod.PUT, raceState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                name: raceState.payload.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromRaceActions.LoadRaces({operation: CRUD.UPDATE});
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteRace$ = this.actions$.pipe(
        ofType(fromRaceActions.DELETE_RACE),
        switchMap((raceState: fromRaceActions.DeleteRace) => {
            return this.endpointService.races(HttpMethod.DELETE, raceState.payload.deleteId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .request('delete', endpoint, {
                            body: {
                                update_race_id: raceState.payload.updateId.toString()
                            }
                        })
                        .pipe(
                            map(() => {
                                return new fromRaceActions.LoadRaces({operation: CRUD.DELETE});
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );


    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromRaceActions.HttpFail> => {
        return handleHTTPError(errorRes, fromRaceActions, 'Rassen', this.loggingService, 'race_already_exists');
    };
}
