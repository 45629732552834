import {Observable, of} from 'rxjs';
import * as AuthActions from './auth.actions';

export const handleError = (errorRes: any): Observable<AuthActions.HttpFail> => {
    let errorMessage = ErrorTypes.GENERAL;
    if (!(errorRes.error &&
        errorRes.error.data &&
        errorRes.error.data.errors)) {
        return of(new AuthActions.HttpFail(errorMessage));
    }

    if (errorRes.error.data.errors.email || errorRes.error.data.errors.password) {
        errorMessage = ErrorTypes.WRONG_CREDENTIALS;
    }
    return of(new AuthActions.HttpFail(errorMessage));
};

export enum ErrorTypes {
    WRONG_CREDENTIALS = 'Falscher Benutzername oder Passwort',
    GENERAL = 'Es ist ein unbekannter Fehler aufgetreten!'
}

