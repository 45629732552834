<form class="form"
      [formGroup]="personForm"
      [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
      (ngSubmit)="submit()">
    <div class="row">
        <div class="col-md-2" *ngIf="dialogType !== simpleDialogType.NEW_PERSON">
            <mat-form-field>
                <mat-label>PNr.</mat-label>
                <input matInput
                       placeholder="PNr."
                       formControlName="id"
                       [readonly]="readonly"
                       [matAutocomplete]="personId">
                <mat-autocomplete #personId="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonIds$ | async" [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>

        <div class="col-md-5">
            <mat-form-field>
                <mat-label>Nachname</mat-label>
                <input matInput
                       placeholder="Nachname"
                       formControlName="lastName"
                       [readonly]="readonly"
                       [matAutocomplete]="lastName">
                <mat-autocomplete #lastName="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonLastNames$ | async"
                                [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>

        <div class="col-md-5">
            <mat-form-field>
                <mat-label>Vorname</mat-label>
                <input matInput
                       placeholder="Vorname"
                       formControlName="firstName"
                       [readonly]="readonly"
                       [matAutocomplete]="firstName">
                <mat-autocomplete #firstName="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonFirstNames$ | async"
                                [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <mat-label>Straße</mat-label>
                <input matInput
                       placeholder="Straße"
                       formControlName="street"
                       [readonly]="readonly"
                       [matAutocomplete]="street">
                <mat-autocomplete #street="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonStreets$ | async" [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <mat-form-field>
                <mat-label>Postleitzahl</mat-label>
                <input matInput
                       placeholder="Postleitzahl"
                       formControlName="zip"
                       [readonly]="readonly"
                       (ngModelChange)="zipCitySearchService.valueChanged($event, ZipCity.ZIP)"
                       [matAutocomplete]="zip">
                <mat-autocomplete #zip="matAutocomplete" [displayWith]="zipCitySearchService.displayFn">
                    <mat-option *ngFor="let option of zipCitySearchService.values"
                                (onSelectionChange)="zipCityChanged($event)"
                                [value]="option">
                        {{option.viewValue}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <mat-form-field>
                <mat-label>Ort</mat-label>
                <input matInput
                       placeholder="Ort"
                       formControlName="city"
                       [readonly]="readonly"
                       [matAutocomplete]="city">
                <mat-autocomplete #city="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonCities$ | async" [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <mat-label>Telefon</mat-label>
                <input matInput
                       placeholder="Telefon"
                       formControlName="phone"
                       [readonly]="readonly">
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field [ngClass]="{'mat-datetime-picker': true}">
                <input matInput
                       (dateChange)="personService.dateChange($event)"
                       (click)="datePickerBirthday.open()"
                       [ngxMatDatetimePicker]="datePickerBirthday"
                       placeholder="Geburtsdatum"
                       formControlName="birthday"
                       [min]="dateTimeConfig.minDate"
                       [max]="dateTimeConfig.maxDate">
                <mat-datepicker-toggle matSuffix
                                       [for]="datePickerBirthday"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datePickerBirthday
                                         [stepHour]="dateTimeConfig.stepHour"
                                         [stepMinute]="dateTimeConfig.stepMinute"
                                         [hideTime]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>E-Mail</mat-label>
                <input matInput
                       placeholder="E-Mail"
                       formControlName="email"
                       [readonly]="readonly"
                       [matAutocomplete]="email">
                <mat-autocomplete #email="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonEmails$ | async" [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <mat-label>Nachweis</mat-label>
                <input matInput
                       placeholder="Nachweis"
                       formControlName="proof"
                       [readonly]="readonly"
                       [matAutocomplete]="proof">
                <mat-autocomplete #proof="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonProofs$ | async" [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-form-field>
                <mat-label>Aussteller</mat-label>
                <input matInput
                       placeholder="Aussteller"
                       formControlName="issuer"
                       [readonly]="readonly"
                       [matAutocomplete]="issuer">
                <mat-autocomplete #issuer="matAutocomplete">
                    <mat-option *ngFor="let tuple of filteredPersonIssuers$ | async" [value]="tuple[1]">
                        {{tuple[1]}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Bemerkung</mat-label>
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"
                          formControlName="remark"
                          [readonly]="readonly"></textarea>
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-12">
            <button mat-raised-button
                    color="warn"
                    [mat-dialog-close]="false"
                    *ngIf="dialogRef !== undefined"
                    (click)="cancel()">
                Abbrechen
            </button>
            <button class="float-right" mat-raised-button
                    *ngIf="dialogType === simpleDialogType.NEW_PERSON"
                    color="primary">
                <i class="fas fa-exclamation-circle mr-2"
                   style="color: #b71c1c"
                   *ngIf="displayErroneousInputs">
                </i>
                Erstellen
            </button>
            <button class="float-right" mat-raised-button
                    *ngIf="dialogType === simpleDialogType.SEARCH_PERSON"
                    color="primary">
                Suchen
            </button>
            <button class="float-right" mat-raised-button
                    *ngIf="dialogType === simpleDialogType.EDIT_PERSON"
                    color="primary">
                <i class="fas fa-exclamation-circle mr-2"
                   style="color: #b71c1c"
                   *ngIf="displayErroneousInputs">
                </i>
                Aktualisieren
            </button>
        </div>
    </div>
</form>
