import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {ArticleEntryModel} from '../../../shared/models/pos/article-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {Observable} from 'rxjs';
import {Order} from '../../../shared/controls/data-table/ordering';
import * as fromArticlesActions from './articles.actions';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class ArticlesEffects {
    @Effect()
    loadArticles$ = this.actions$.pipe(
        ofType(fromArticlesActions.LOAD_ARTICLES),
        switchMap((articleState: fromArticlesActions.LoadArticles) => {
            const page = articleState.payload.page;
            const size = articleState.payload.size;
            const order = articleState.payload.order;
            const orderColumn = articleState.payload.column;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }


            return this.endpointService.articles(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params}).pipe(
                                map(response => response.data)
                            );
                    }),
                    map((response) => {
                        const mappedArticleArr: ArticleEntryModel[] = response.data.map(article => {
                            const mappedArticle: ArticleEntryModel = {
                                id: article.id,
                                category: article.category,
                                name: article.name,
                                price: article.price
                            };
                            return mappedArticle;
                        });

                        const totalElements = response.pagination.totalElements;
                        const lastPage = response.pagination.pages;
                        return new fromArticlesActions.SetArticles({
                            articles: mappedArticleArr,
                            crud: articleState.payload.crud,
                            totalElements,
                            lastPage
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    addArticle$ = this.actions$
        .pipe(
            ofType(fromArticlesActions.ADD_ARTICLE),
            switchMap((articleState: fromArticlesActions.AddArticle) => {
                return this.endpointService.articles(HttpMethod.POST)
                    .pipe(
                        take(1),
                        switchMap(endpoint => {
                            return this.http
                                .post<any>(endpoint,
                                    {
                                        category_id: articleState.payload.article.category.id,
                                        name: articleState.payload.article.name,
                                        price: articleState.payload.article.price
                                    }
                                ).pipe(
                                    map(() => {
                                        return new fromArticlesActions.LoadArticles({
                                            crud: CRUD.CREATE,
                                            size: articleState.payload.size,
                                            page: articleState.payload.page,
                                            column: articleState.payload.column,
                                            order: articleState.payload.order
                                        });
                                    }),
                                    catchError(errorRes => {
                                        return this.handleError(errorRes);
                                    })
                                );
                        })
                    );
            })
        );

    @Effect()
    updateArticle$ = this.actions$
        .pipe(
            ofType(fromArticlesActions.UPDATE_ARTICLE),
            switchMap((articleState: fromArticlesActions.UpdateArticle) => {
                return this.endpointService.articles(HttpMethod.PUT, articleState.payload.article.id)
                    .pipe(
                        take(1),
                        switchMap(endpoint => {
                            return this.http
                                .put<any>(endpoint,
                                    {
                                        category_id: articleState.payload.article.category.id,
                                        name: articleState.payload.article.name,
                                        price: articleState.payload.article.price
                                    }
                                ).pipe(
                                    map(() => {
                                        return new fromArticlesActions.LoadArticles({
                                            crud: CRUD.UPDATE,
                                            page: articleState.payload.page,
                                            size: articleState.payload.size,
                                            column: articleState.payload.column,
                                            order: articleState.payload.order
                                        });
                                    }),
                                    catchError(errorRes => {
                                        return this.handleError(errorRes);
                                    })
                                );
                        })
                    );
            })
        );

    @Effect()
    deleteArticle$ = this.actions$
        .pipe(
            ofType(fromArticlesActions.DELETE_ARTICLE),
            switchMap((articleState: fromArticlesActions.DeleteArticle) => {
                return this.endpointService.articles(HttpMethod.DELETE, articleState.payload.articleId)
                    .pipe(
                        take(1),
                        switchMap(endpoint => {
                            return this.http
                                .delete<any>(endpoint)
                                .pipe(
                                    map(() => {
                                        return new fromArticlesActions.LoadArticles({
                                            crud: CRUD.DELETE,
                                            size: articleState.payload.size,
                                            page: articleState.payload.page,
                                            column: articleState.payload.column,
                                            order: articleState.payload.order
                                        });
                                    }),
                                    catchError(errorRes => {
                                        return this.handleError(errorRes);
                                    })
                                );
                        })
                    );
            })
        );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromArticlesActions.HttpFail> => {
        return handleHTTPError(errorRes, fromArticlesActions, 'Artikeln', this.loggingService);
    };
}
