<div [ngClass]="!visible ? 'not-printable' : ''" style="padding: 70px">
    <h1>Fundtieranzeige</h1>
    <section class="text-left">
        <div class="row">
            <div class="col-8">
                <div class="font-weight-bolder">Verwahrer:</div>
                <div>{{shelterName}}, {{address}}, {{zipAndCity}}, Telefon: {{phone}}</div>
            </div>
            <div class="col-4">
                <div class="font-weight-bolder">LaufNr: {{animals.id}}</div>
                <div class="font-weight-bolder">FundtierNr: <span
                        style="width:60px; border-bottom:2px solid #000; display: inline-block">{{animals.fundtierId || '' }}</span>
                </div>
            </div>
        </div>
    </section>

    <section class="text-left mt-5">
        <div class="row" *ngIf="person !== null && person !== undefined">
            <div class="col-12 font-weight-bolder">Finder:</div>
            <div class="col-3 mt-2">
                <div class="font-weight-bolder">{{person.firstName + ' ' + person.lastName}}</div>
                <div>{{person.street}}</div>
                <div>{{person.zip}} - {{person.city}}</div>
                <div>Tel.: {{person.phone}}</div>
            </div>

            <div class="col-9 mt-2">
                <div>PNr.: {{person.id}}</div>
                <div>Geb. Datum: {{person.birthday | date: 'dd.MM.yyyy'}}</div>
                <div>Nachweis: {{person.proof}}</div>
                <div>Aussteller: {{person.issuer}}</div>
                <div>E-Mail: {{person.email}}</div>
            </div>
        </div>
    </section>

    <section class="mt-5 text-left">
        <div class="row">
            <div class="col-2 font-weight-bolder">Auffindungsort:</div>
            <div class="col-10">{{data.streetFound}}, {{data.zipFound}}- {{data.cityFound}}</div>
        </div>
        <div class="row">
            <div class="col-2 font-weight-bolder">Fundzeitpunkt:</div>
            <div class="col-10">{{data.date | date:'dd.MM.yyyy'}}</div>
        </div>
    </section>

    <section class="mt-5 text-left">
        <div class="row">
            <div class="col font-weight-bolder">Tierbeschreibung:</div>
        </div>
        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Chip-Nr.:
            </div>
            <div class="col-8 text-left">
                {{animals.chipNumber}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Tierart:
            </div>
            <div class="col-8 text-left">
                {{animals.species.name}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Rasse:
            </div>
            <div class="col-8 text-left">
                {{animals.race.name}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Geschlecht:
            </div>
            <div class="col-8 text-left">
                {{Gender.getGender(animals.gender)}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Kastriert:
            </div>
            <div class="col-8 text-left">
                {{CastrationStatus.getCastrationStatus(animals.castrated)}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Beschreibung:
            </div>
            <div class="col-8 text-left">
                {{characteristicsService.printCharacteristics(animals.characteristics)}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Alter:
            </div>
            <div class="col-8 text-left">
                {{Age.printAge(animals.birthday)}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Geb.-Datum:
            </div>
            <div class="col-8 text-left">
                {{animals.birthday | date:'dd.MM.yyyy'}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 ml-3 font-weight-bolder">
                Besondere Kennzeichen:
            </div>
            <div class="col-8 text-left">
                {{animals.specialCriteria}}
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-5 font-weight-bolder">
                Nähere Umstände des Auffindens:
            </div>
            <div class="col-7 text-left">
                {{data.howAnimalWasFound}}
            </div>
        </div>
    </section>

    <section class="mt-5 text-left">
        <div class="font-weight-bolder row">
            <div class="col">Gesundheitszustand des Tieres:</div>
        </div>
        <div class="row">
            <div class="col-12">□ gesund</div>
            <div class="col-12">□ krank, verletzt</div>
            <div class="col-12">□ Standarduntersuchungen lt. Leistungskatalog bei Tierarzt</div>
        </div>
        <div class="row">
            <div class="col-2">Sonstige</div>
            <div class="col-8">
                <div>□ Erstbehandlungsmaßnahmen (gem. P. 7 TVV),</div>
                <div>□ Euthanasierungen (gem. P. 9 TVV) und/oder</div>
                <div>□ sonstige geplante Maßnahmen (gem. P. 10 TVV) ………………………………</div>
                <div>……………………………………………………………………………………………………………………………………………………………………</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div>bei Tierarzt Dr. …………………………………………………………………………………………………………………………………….</div>
                <div>wofür Kosten in der Höhe von ca. € ……………………… anfallen werden (angefallen sind).</div>
            </div>
        </div>
    </section>
</div>
