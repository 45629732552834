import {Component} from '@angular/core';
import {PrintableComponent} from '../../PrintableComponent';
import {PrintingDataCashbook, PrintingTemplate} from '../TemplateTypes';
import {Shelter} from '../../../shared/constants';
import * as moment from 'moment';

@Component({
    selector: 'app-cash-book-printing',
    templateUrl: './cash-book-printing.component.html',
    styleUrls: ['./cash-book-printing.component.scss']
})
export class CashBookPrintingComponent implements PrintableComponent {
    public printingTemplate = PrintingTemplate.Cashbook;
    public visible = false;
    public hideHeader = true;
    public shelter: Shelter;
    public data: PrintingDataCashbook;
    public moment = moment;
    public colWidths = ['2', '2', '2', '2', '1', '2', '1'];
}
