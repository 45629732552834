import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DataTable} from '../shared/data-types/data-table';
import {VeterinaryTreatmentEntryModel} from '../shared/models/veterinary-treatments/veterinary-treatment-entry.model';
import {Observable, Subscription} from 'rxjs';
import {Order} from '../shared/controls/data-table/ordering';
import {VeterinaryTreatmentsService} from './veterinary-treatments.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../shared/services/http/crud';
import {SimpleDialogComponent} from '../dialogs/simple-dialog/simple-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogType} from '../dialogs/dialog-type';
import {Sort} from '@angular/material/sort';
import * as fromTreatmentsActions from './store/veterinary-treatments/veterinary-treatments.actions';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-veterinary-treatments',
    templateUrl: './veterinary-treatments.component.html',
    styleUrls: ['./veterinary-treatments.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class VeterinaryTreatmentsComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    public addNewTreatment = false;
    public displayedColumns: string[] = [
        'id',
        'description',
        'invoiceText',
        'price',
        'controls'
    ];
    public expandedElement: VeterinaryTreatmentEntryModel | null;
    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'Maßnahmen Nr.',
            className: 'id'
        }, {
            key: 'description',
            header: 'Maßnahme',
            className: 'description'
        }, {
            key: 'invoiceText',
            header: 'Rechnungstext',
            className: 'invoiceText'
        }, {
            key: 'price',
            header: 'Kosten',
            className: 'price'
        }
    ];

    public displayedColumnNewTreatment: string[] = [
        'new-id',
        'new-description',
        'new-invoice-text',
        'new-price',
        'new-control'
    ];
    public simpleDialogType = new DialogType();
    public newTreatmentForm: FormGroup;
    public treatments$: Observable<VeterinaryTreatmentEntryModel[]>;
    public loading?: boolean = null;
    public totalTreatments = 0;
    public displayErroneousInputs = false;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.NONE;
    public orderedColumn = '';
    public addRequestSent = false;

    protected static treatmentIdentifier = '';

    private lastPage = 1;
    private treatmentsSub: Subscription;
    private notSortable = ['price'];

    constructor(protected actions$: Actions,
                protected treatmentService: VeterinaryTreatmentsService,
                private formBuilder: FormBuilder,
                private authService: AuthService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.initTreatmentTable();
        this.initForm();
        this.initErrorHandling();
    }

    ngOnDestroy(): void {
        if (this.treatmentsSub) {
            this.treatmentsSub.unsubscribe();
        }
    }

    public addTableRow(): void {
        this.addNewTreatment = true;
    }

    public canExpand($event: MouseEvent, element: any, expandedElement: VeterinaryTreatmentEntryModel): any {
        return DataTable.canExpand($event, element, expandedElement);
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.treatments$ = this.treatmentService
            .fetchVeterinaryTreatments(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);

        this.paginator.firstPage();
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.treatments$ = this.treatmentService
            .fetchVeterinaryTreatments(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active;
        if (this.orderedColumn === 'invoiceText') {
            this.orderedColumn = 'billing_text';
        } else if (this.orderedColumn === 'description') {
            this.orderedColumn = 'treatment';
        }

        this.treatments$ = this.treatmentService
            .fetchVeterinaryTreatments(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
    }

    public columnSortable(key: any): boolean {
        return !this.notSortable.includes(key);
    }

    public saveTreatment(): void {
        this.addRequestSent = true;
        if (!this.newTreatmentForm.valid) {
            this.addRequestSent = false;
            this.displayErroneousInputs = true;
            return;
        }
        this.displayErroneousInputs = false;
        const price = this.newTreatmentForm.value.newPrice.replace(/,/g, '.');
        const invoiceText = this.newTreatmentForm.value.newInvoiceText;
        const description = this.newTreatmentForm.value.newDescription;

        const newTreatment: VeterinaryTreatmentEntryModel = {
            id: -1, // Dummy -> Is not sent to the server
            invoiceText,
            description,
            price
        };

        this.treatmentService.addVeterinaryTreatment(newTreatment, this.lastPage, this.entriesPerPage, this.order, this.orderedColumn);
        VeterinaryTreatmentsComponent.treatmentIdentifier = description;
    }

    public deleteTreatment(element: VeterinaryTreatmentEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Maßnahme',
                identifier: element.description
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.treatmentService
                    .deleteVeterinaryTreatment(element.id, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
                VeterinaryTreatmentsComponent.treatmentIdentifier = element.description;
            }
        });
    }

    protected initForm(treatment?: VeterinaryTreatmentEntryModel): void {
        let description = '';
        let invoiceText = '';
        let price = '';

        if (typeof treatment !== 'undefined') {
            description = treatment.description;
            invoiceText = treatment.invoiceText;
            price = treatment.price.toString().replace(/\./g, ',');
        }

        this.newTreatmentForm = this.formBuilder.group({
            newDescription: [description, Validators.required],
            newInvoiceText: [invoiceText, Validators.required],
            newPrice: [
                price, [
                    Validators.required,
                    Validators.pattern(/^[0-9]+(,[0-9]{1,2})?$/)
                ]
            ]
        });
    }

    private initTreatmentTable(): void {
        this.loading = true;
        this.treatments$ = this.treatmentService.fetchVeterinaryTreatments(this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn);

        this.treatmentsSub = this.actions$.pipe(
            ofType(fromTreatmentsActions.SET_VETERINARY_TREATMENTS)
        ).subscribe((veterinaryState: fromTreatmentsActions.SetVeterinaryTreatments) => {
            const crudOperation = veterinaryState.payload.crud;
            this.totalTreatments = veterinaryState.payload.totalElements;
            this.lastPage = veterinaryState.payload.lastPage;
            this.loading = false;
            this.addNewTreatment = false;
            this.addRequestSent = false;

            if (crudOperation === CRUD.READ ||
                crudOperation === CRUD.NONE) {
                return;
            }

            if (crudOperation === CRUD.CREATE) {
                this.paginator.length = this.totalTreatments;
                this.paginator.lastPage();
                this.newTreatmentForm.reset();
            }

            this.treatmentService.handleRequestSuccess(crudOperation, 'Maßnahme', VeterinaryTreatmentsComponent.treatmentIdentifier);
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromTreatmentsActions.HTTP_FAIL)
        ).subscribe((httpFail: fromTreatmentsActions.HttpFail) => {
            this.treatmentService.handleRequestError(httpFail.payload.message);
            this.loading = false;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }

}
