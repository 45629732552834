<ng-container [formGroup]="acquisitionGiveAwayForm">
    <div class="row">
        <div class="col-md-4">
            <mat-form-field class="mat-datetime-picker">
                <input matInput
                       (dateChange)="dateChanged()"
                       (click)="datePickerFrom.open()"
                       [ngxMatDatetimePicker]="datePickerFrom"
                       placeholder="Aufenthalt von"
                       formControlName="stayFrom"
                       [min]="dateTimeConfig.minDate"
                       [max]="dateTimeConfig.maxDate">
                <mat-datepicker-toggle matSuffix
                                       [for]="datePickerFrom"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datePickerFrom
                                         [stepHour]="dateTimeConfig.stepHour"
                                         [stepMinute]="dateTimeConfig.stepMinute"
                                         [hideTime]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="mat-datetime-picker">
                <input matInput
                       (dateChange)="dateChanged()"
                       (click)="datePickerTo.open()"
                       [ngxMatDatetimePicker]="datePickerTo"
                       placeholder="Aufenthalt bis"
                       formControlName="stayUntil"
                       [min]="dateTimeConfig.minDate"
                       [max]="dateTimeConfig.maxDate">
                <mat-datepicker-toggle matSuffix
                                       [for]="datePickerTo"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datePickerTo
                                         [stepHour]="dateTimeConfig.stepHour"
                                         [stepMinute]="dateTimeConfig.stepMinute"
                                         [hideTime]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12"><h5 class="mb-0">Kostenstruktur und Aufenthalt</h5></div>
        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Kosten pro Tag (gesamt)</mat-label>
                <input matInput
                       (change)="formUpdated()"
                       formControlName="costsPerDayTotal"
                       placeholder="Kosten pro Tag (gesamt) in Euro">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Anzahlung (gesamt)</mat-label>
                <input matInput
                       (change)="formUpdated()"
                       formControlName="depositTotal"
                       placeholder="Anzahlung (gesamt) in Euro">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Sonstige Kosten (Tierarzt, ...) (gesamt)</mat-label>
                <input matInput
                       (change)="formUpdated()"
                       placeholder="Sonstige Kosten (Tierarzt, ...) (gesamt) in Euro"
                       formControlName="additionalCosts">
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-2">
            <h5>Gesamtbetrag:</h5>
        </div>
        <div class="col-2">
            <h5>{{totalCosts | currency:'EUR':'symbol':'1.2-2' }}</h5>
        </div>
    </div>
</ng-container>
