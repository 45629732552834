import {DropdownOption} from '../shared/data-types/dropdown-option';
import {CastrationStatus, Gender, ShelterStatus} from '../shared/constants';

export class AnimalDropdownOptions {
    public static readonly shelterStatusOptions: DropdownOption[] = [
        {value: ShelterStatus.IM_HEIM.key, viewValue: ShelterStatus.IM_HEIM.value},
        {value: ShelterStatus.NICHT_IM_HEIM.key, viewValue: ShelterStatus.NICHT_IM_HEIM.value},
        {value: ShelterStatus.PENSIONSTIER_IM_HEIM.key, viewValue: ShelterStatus.PENSIONSTIER_IM_HEIM.value}
    ];

    public static readonly castrationStatusOptions: DropdownOption[] = [
        {value: CastrationStatus.KASTRIERT.key, viewValue: CastrationStatus.KASTRIERT.value},
        {value: CastrationStatus.NICHT_KASTRIERT.key, viewValue: CastrationStatus.NICHT_KASTRIERT.value},
        {value: CastrationStatus.UNBEKANNT.key, viewValue: CastrationStatus.UNBEKANNT.value}
    ];

    public static readonly genderOptions: DropdownOption[] = [
        {value: Gender.MALE.key, viewValue: Gender.MALE.value},
        {value: Gender.FEMALE.key, viewValue: Gender.FEMALE.value},
        {value: Gender.UNKNOWN.key, viewValue: Gender.UNKNOWN.value}
    ];
}
