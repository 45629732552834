import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {SimpleDialogComponent} from '../../../dialogs/simple-dialog/simple-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from '../../../dialogs/dialog-type';
import {Action, Store} from '@ngrx/store';
import {DropdownOption} from '../../data-types/dropdown-option';
import * as fromApp from '../../../store/app.reducer';

@Injectable()
export class SelectBoxService {
    private renderer: Renderer2;
    private dialogType: DialogType = new DialogType();

    constructor(private rendererFactory: RendererFactory2,
                private dialog: MatDialog,
                private store: Store<fromApp.AppState>) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public sortCallback = (opt1: DropdownOption, opt2: DropdownOption): number => {
        const value1 = opt1.viewValue.toUpperCase(); // ignore upper and lowercase
        const value2 = opt2.viewValue.toUpperCase(); // ignore upper and lowercase
        if (value1 < value2) {
            return -1;
        }
        if (value1 > value2) {
            return 1;
        }
        // names must be equal
        return 0;
    };

    public activateEditMode(event: any, matOption: MatOption): void {
        event.stopPropagation();
        const textEl = matOption._getHostElement().querySelector('.option-wrapper .text-wrapper .text-element');
        this.renderer.addClass(textEl, 'd-none');

        const inputEl = matOption._getHostElement().querySelector('.option-wrapper .text-wrapper input');
        this.renderer.removeClass(inputEl, 'd-none');

        const editIcon = matOption._getHostElement().querySelector('.option-wrapper .controls .fa-edit');
        this.renderer.addClass(editIcon, 'd-none');

        const checkIcon = matOption._getHostElement().querySelector('.option-wrapper .controls .fa-check');
        this.renderer.removeClass(checkIcon, 'd-none');
    }

    public openAddDialog(entity: string, meta: unknown = null): MatDialogRef<SimpleDialogComponent, any> {
        return this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.dialogType.CREATE_GENERIC,
                entity,
                meta
            }
        });
    }

    public update(matOption: MatOption, updateAction: Action): void {
        this.disableEditMode(matOption);

        this.store.dispatch(updateAction);
    }

    public delete(entity: string,
                  identifier: string,
                  type = this.dialogType.DELETE_GENERIC,
                  options = null,
                  allRaces = null): MatDialogRef<SimpleDialogComponent, any> {
        return this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type,
                entity,
                identifier,
                options,
                allRaces
            }
        });
    }

    public disableEditMode(matOption: MatOption): void {
        const textEl = matOption._getHostElement().querySelector('.option-wrapper .text-wrapper .text-element');
        this.renderer.removeClass(textEl, 'd-none');

        const inputEl = matOption._getHostElement().querySelector('.option-wrapper .text-wrapper input');
        this.renderer.addClass(inputEl, 'd-none');

        const editIcon = matOption._getHostElement().querySelector('.option-wrapper .controls .fa-edit');
        this.renderer.removeClass(editIcon, 'd-none');

        const checkIcon = matOption._getHostElement().querySelector('.option-wrapper .controls .fa-check');
        this.renderer.addClass(checkIcon, 'd-none');
    }
}
