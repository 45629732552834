import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {SelectSearch} from '../shared/services/select-box/select-search';
import {SpeciesService} from '../animals/store/species/species.service';
import {AttributesService} from './attributes.service';
import {Observable} from 'rxjs';
import {AttributeEntryModel} from '../shared/models/attributes/attribute-entry.model';
import {SelectBoxUtility} from '../shared/controls/select-box/select-box-utility';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChipListService} from '../shared/services/chip-list/chip-list.service';
import {SimpleDialogComponent} from '../dialogs/simple-dialog/simple-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogType} from '../dialogs/dialog-type';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../shared/services/http/crud';
import {DropdownOption} from '../shared/data-types/dropdown-option';
import * as fromAttributesActions from './store/attributes/attributes.actions';
import * as fromOptionsActions from './store/options/options.actions';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-attributes',
    templateUrl: './attributes.component.html',
    styleUrls: ['./attributes.component.scss']
})
export class AttributesComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    @ViewChild('addAttributeIcon') public addAttributeIcon: ElementRef;
    public selectSearchSpecies = new SelectSearch();
    public attributes$: Observable<AttributeEntryModel[]>;
    public newAttributeForm: FormGroup;
    public showAddAttribute = false;
    public displayErroneousInputs = false;
    public speciesGroupName = '';
    public SelectBoxUtility = SelectBoxUtility;

    protected dialogType = new DialogType();
    protected identifier = '';
    protected crudOperation = CRUD.NONE;

    constructor(public chipListService: ChipListService,
                protected attributesService: AttributesService,
                protected actions$: Actions,
                protected dialog: MatDialog,
                protected renderer: Renderer2,
                private speciesService: SpeciesService,
                private authService: AuthService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit(attributeOptionComponent: boolean = false): void {
        if (!attributeOptionComponent) {
            this.initForm();
            this.speciesService.fetchSpecies().subscribe(options => {
                const filteredOptions = options.filter(option => option.viewValue === 'Hund' || option.viewValue === 'Katze');
                const smallAnimalOption: DropdownOption = {
                    value: 'kleintier',
                    viewValue: 'Kleintier',
                    meta: null
                };
                filteredOptions.push(smallAnimalOption);
                this.selectSearchSpecies.setValues(filteredOptions);
            });

            this.actions$.pipe(
                ofType(fromAttributesActions.SET_ATTRIBUTES)
            ).subscribe(() => {
                if (this.crudOperation === CRUD.CREATE ||
                    this.crudOperation === CRUD.UPDATE ||
                    this.crudOperation === CRUD.DELETE) {

                    setTimeout(() => {
                        const snackBarRef = this.attributesService.handleRequestSuccess(this.crudOperation, 'Attribut', this.identifier);
                        snackBarRef.afterOpened().subscribe(() => {
                            this.crudOperation = CRUD.NONE;
                        });
                    });
                }
                this.showAddAttribute = false;
            });
        }

        this.initErrorHandling();

    }

    ngAfterViewInit(): void {
        this.selectSearchSpecies.init(this.singleSelectSpecies);
    }

    ngOnDestroy(): void {
        this.selectSearchSpecies.destroy();
        this.crudOperation = CRUD.NONE;
    }

    public showAddAttributeInput(): void {
        this.showAddAttribute = true;
    }

    public selectSpecies($event: MatSelectChange): void {
        this.speciesGroupName = $event.value.viewValue;
        this.attributes$ = this.attributesService.fetchAttributes(this.speciesGroupName);
        this.crudOperation = CRUD.READ;
    }

    public addAttribute(): void {
        if (!this.newAttributeForm.valid) {
            this.displayErroneousInputs = true;
            this.renderer.addClass(this.addAttributeIcon.nativeElement, 'd-none');
            return;
        }

        const attribute = this.newAttributeForm.value.newAttribute;
        const option = this.newAttributeForm.value.newOption;

        this.attributesService.addAttribute(attribute, option, this.speciesGroupName);
        this.crudOperation = CRUD.CREATE;
        this.identifier = attribute;
        this.newAttributeForm.reset();
    }

    public updateAttribute(chip: HTMLDivElement, entry: AttributeEntryModel, updatedVal: HTMLInputElement): void {
        if (updatedVal.value === '') {
            updatedVal.value = entry.name;
            return;
        }

        this.chipListService.deactivateEditMode(chip);
        this.attributesService.updateAttribute(entry, updatedVal.value, entry.datasheetVisibility);
        this.crudOperation = CRUD.UPDATE;
        this.identifier = updatedVal.value;
    }

    public deleteAttribute(attribute: AttributeEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.dialogType.DELETE_GENERIC,
                entity: 'Attribut',
                identifier: attribute.name
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.attributesService.deleteAttribute(attribute);
                this.crudOperation = CRUD.DELETE;
                this.identifier = attribute.name;
            }
        });
    }

    public isValid(newAttributeInput: HTMLInputElement, newOptionInput: HTMLInputElement): void {
        if (newAttributeInput.value !== '' && newOptionInput.value !== '') {
            this.renderer.removeClass(this.addAttributeIcon.nativeElement, 'd-none');
        } else {
            this.renderer.addClass(this.addAttributeIcon.nativeElement, 'd-none');
        }
    }

    public toggleDatasheetVisibility(attribute: AttributeEntryModel, el: HTMLAnchorElement): void {
        const isActive = el.classList.contains('active');
        if (isActive) {
            this.renderer.removeClass(el, 'active');
        } else {
            this.renderer.addClass(el, 'active');
        }

        this.attributesService.updateAttribute(attribute, attribute.name, !isActive);
        this.crudOperation = CRUD.UPDATE;
    }

    private initForm(): void {
        this.newAttributeForm = this.formBuilder.group({
            newAttribute: [name, Validators.required],
            newOption: [name, Validators.required]
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromOptionsActions.HTTP_FAIL, fromAttributesActions.HTTP_FAIL)
        ).subscribe((httpFail: fromAttributesActions.HttpFail | fromOptionsActions.HttpFail) => {
            this.attributesService.handleRequestError(httpFail.payload.message);
            this.crudOperation = CRUD.NONE;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }
}
