<div class="title-bar not-printable">
    <h3>Aktionen</h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Übersicht</h4>
                </div>
                <div class="card-body">
                    <div id="action-list-wrapper">
                        <div id="top-controls" class="d-flex justify-content-between">
                            <div id="show-entries-container">
                                Zeige
                                <select name="entries"
                                        id="entries"
                                        class="mr-1 ml-1"
                                        (ngModelChange)="changeEntriesPerPage($event)"
                                        [(ngModel)]="entriesPerPage">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                                Einträge
                            </div>
                        </div>
                        <div style="overflow-x: auto;">
                            <table mat-table
                                   [dataSource]="transactions"
                                   (matSortChange)="orderData($event)"
                                   multiTemplateDataRows
                                   matSort
                                   class="mat-elevation-z0 no-expansion">
                                <!-- Position Column -->
                                <ng-container *ngFor="let def of tableDef">
                                    <ng-container [matColumnDef]="def.key">
                                        <th class="sortable-header" mat-header-cell mat-sort-header
                                            *matHeaderCellDef> {{def.header}} </th>
                                        <td [ngClass]="def.className" mat-cell
                                            *matCellDef="let element" [ngSwitch]="def.key">
                                            <ng-container *ngSwitchCase="'type'">
                                                <div class="d-flex align-items-center justify-content-between"
                                                     appActionsSymbol
                                                     [actionTypeId]="element['actionTypeId']">
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'date'">
                                                {{element[def.key] | date:'dd.MM.yyyy' }}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'personNumber'">
                                                <span *ngIf="element['person'] !== null">
                                                    {{element['person'].id }}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'fullName'">
                                                <span *ngIf="element['person'] !== null">
                                                    {{element['person'].lastName }} {{element['person'].firstName }}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'lfnr'">
                                                {{element['animal']?.id}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'animalName'">
                                                {{element['animal']?.name}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'species'">
                                                {{element['animal']?.species.name}}
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{element[def.key]}}
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </ng-container>

                                <ng-container matColumnDef="controls">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="fas fa-print icon"
                                           (click)="print(element)"></a>
                                        <a class="fas fa-trash-alt icon"
                                           matTooltip="Aktion löschen"
                                           (click)="deleteAction(element)"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                    class="table-row">
                                </tr>
                            </table>
                        </div>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline">
                            <div id="total-results">Gesamt: <span>{{totalActions}}</span></div>
                            <mat-paginator [length]="totalActions"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div class="default-entry mt-2" *ngIf="totalActions === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>
                </div>
                <div class="card-body pt-0">
                    <app-actions-filter></app-actions-filter>
                </div>
            </div>
        </div>
    </div>
</div>

<app-printing [templateType]="PrintingTemplate.AcquisitionAndGiveAway"
              [printDataSheets]="false"
              [actionTypes]="fetchedActionTypes"></app-printing>
