import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as SpeciesActions from './species.actions';

export interface State {
    species: DictionaryEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    species: [],
    operation: CRUD.NONE
};

export function speciesReducer(state = initialState, action: SpeciesActions.SpeciesActions): State {
    switch (action.type) {
        case SpeciesActions.SET_SPECIES:
            return {
                ...state,
                species: [...action.payload.species],
                operation: action.payload.operation
            };
        case SpeciesActions.ADD_SPECIES:
            return {
                ...state,
                species: [...state.species, action.payload]
            };
        case SpeciesActions.UPDATE_SPECIES:
            const updatedSpeciesState = {
                ...state.species.find(species => species.id === action.payload.id),
                ...action.payload
            };

            const updatedSpecies = [...state.species];
            const arrIndex = updatedSpecies.findIndex(element => element.id === updatedSpeciesState.id);
            updatedSpecies[arrIndex] = updatedSpeciesState;

            return {
                ...state,
                species: updatedSpecies
            };
        case SpeciesActions.DELETE_SPECIES:
            return {
                ...state,
                species: state.species.filter(species => species.id !== action.payload.deleteSpeciesId)
            };
        case SpeciesActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
