import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {EMPTY, Observable} from 'rxjs';
import {CRUD} from '../../../shared/services/http/crud';
import * as fromCharacteristicActions from './characteristic.actions';
import {TupleHelper} from '../../../shared/data-types/tuple';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {isArray} from 'rxjs/internal-compatibility';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class CharacteristicsEffects {
    @Effect()
    loadCharacteristics$ = this.actions$.pipe(
        ofType(fromCharacteristicActions.LOAD_CHARACTERISTICS),
        switchMap((characteristicsState: fromCharacteristicActions.LoadCharacteristics) => {
            const characteristicFilter = characteristicsState.payload.filter;

            let params = new HttpParams();
            if (characteristicFilter !== null) {
                params = params.append('filter', 'true');
                params = typeof characteristicFilter.raceId !== 'undefined' && characteristicFilter.raceId !== '' ?
                    params.append('race_id', characteristicFilter.raceId) : params;
                params = typeof characteristicFilter.excludeLegacyCharacteristics !== 'undefined' &&
                characteristicFilter.excludeLegacyCharacteristics !== '' ?
                    params.append('exclude_legacy_characteristics', characteristicFilter.excludeLegacyCharacteristics) : params;

                if (typeof characteristicFilter.raceIds !== 'undefined' &&
                    characteristicFilter.raceIds !== '' &&
                    isArray(characteristicFilter.raceIds)) {
                    characteristicFilter.raceIds.forEach(id => {
                        params = params.append('race_id', id);
                    });
                }

                return this.endpointService.characteristics(HttpMethod.GET).pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params}).pipe(
                                map(response => response.data)
                            );
                    }),
                    map(response => {
                        const characteristicModels = response.map(entry => {
                            const mappedModel: DictionaryEntryModel = {
                                id: entry.id,
                                name: entry.name,
                                meta: [
                                    {key: 'raceId', value: entry.race_id.toString()},
                                    {key: 'isLegacy', value: entry.is_legacy.toString()}
                                ]
                            };
                            return mappedModel;
                        });
                        return new fromCharacteristicActions.SetCharacteristics({
                            characteristics: characteristicModels,
                            operation: characteristicsState.payload.crud,
                            addedOrUpdatedCharacteristic: characteristicsState.payload.addedOrUpdatedCharacteristic,
                            filter: characteristicsState.payload.filter
                        });
                    }),
                    catchError(error => {
                        return this.handleError(error);
                    })
                );
            } else {
                return EMPTY;
            }
        })
    );

    @Effect()
    addCharacteristic$ = this.actions$.pipe(
        ofType(fromCharacteristicActions.ADD_CHARACTERISTIC),
        switchMap((characteristicState: fromCharacteristicActions.AddCharacteristic) => {
            return this.endpointService.characteristics(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: characteristicState.payload.newModel.name,
                                race_id: TupleHelper.getValue(characteristicState.payload.newModel.meta, 'raceId')
                            }
                        ).pipe(
                            map((response) => {
                                const addedCharacteristic: DictionaryEntryModel = {
                                    id: response.data.id,
                                    name: response.data.name
                                };
                                return new fromCharacteristicActions.LoadCharacteristics({
                                    crud: CRUD.CREATE,
                                    filter: characteristicState.payload.filter,
                                    addedOrUpdatedCharacteristic: addedCharacteristic
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateCharacteristic$ = this.actions$.pipe(
        ofType(fromCharacteristicActions.UPDATE_CHARACTERISTIC),
        switchMap((characteristicState: fromCharacteristicActions.UpdateCharacteristic) => {
            return this.endpointService.characteristics(HttpMethod.PUT, characteristicState.payload.updatedModel.id)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        const raceId = characteristicState.payload.filter && characteristicState.payload.filter.raceId ?
                            characteristicState.payload.filter.raceId : null;
                        return this.http
                            .put<any>(endpoint,
                                {
                                    name: characteristicState.payload.updatedModel.name,
                                    race_id: raceId
                                }
                            ).pipe(
                                map(() => {
                                    return new fromCharacteristicActions.LoadCharacteristics({
                                        crud: CRUD.UPDATE,
                                        filter: characteristicState.payload.filter
                                    });
                                }),
                                catchError(error => {
                                    return this.handleError(error);
                                })
                            );
                    })
                );
        })
    );

    @Effect()
    deleteCharacteristic$ = this.actions$.pipe(
        ofType(fromCharacteristicActions.DELETE_CHARACTERISTIC),
        switchMap((characteristicState: fromCharacteristicActions.DeleteCharacteristic) => {
            return this.endpointService.characteristics(HttpMethod.DELETE, characteristicState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromCharacteristicActions.LoadCharacteristics({
                                    crud: CRUD.DELETE,
                                    filter: characteristicState.payload.filter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromCharacteristicActions.HttpFail> => {
        return handleHTTPError(errorRes,
            fromCharacteristicActions,
            'Merkmalen',
            this.loggingService,
            'animal_characteristic_already_exists');
    };

}
