import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EndpointService, HttpMethod} from '../../shared/services/http/endpoint.service';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {MediaEntryModel} from '../../shared/models/media-entry.model';
import * as fromMediaActions from './media.actions';
import {CRUD} from '../../shared/services/http/crud';
import {Store} from '@ngrx/store';
import {Order} from '../../shared/controls/data-table/ordering';
import {LoggingService} from '../../shared/logging/logging.service';
import {handleHTTPError} from '../../shared/error-handling';

@Injectable()
export class MediaEffects {
    @Effect()
    mediaList$ = this.actions$.pipe(
        ofType(fromMediaActions.LOAD_MEDIA),
        switchMap((mediaState: fromMediaActions.LoadMedia) => {
            const page = mediaState.payload.page;
            const size = mediaState.payload.size;
            const order = mediaState.payload.order;
            const orderColumn = mediaState.payload.column;
            const thumbnail = mediaState.payload.thumbnail;
            const imgId = mediaState.payload.imgId;
            const mediaFilter = mediaState.payload.mediaFilter;

            let params = new HttpParams();

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (mediaFilter) {
                params = params.append('filter', 'true');
                params = mediaFilter.id && mediaFilter.id !== '' ? params.append('animal_id', mediaFilter.id) : params;
                params = mediaFilter.fileName !== '' ? params.append('filename', mediaFilter.fileName) : params;
                params = mediaFilter.created !== '' ? params.append('created', mediaFilter.created) : params;
            }

            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (thumbnail) {
                params = params.append('thumbnail', 'true');
            }

            return this.endpointService.media(HttpMethod.GET, imgId)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http.get<any>(endpoint, {params});
                    }),
                    map(response => {
                        let totalElements = 0;
                        let lastPage = 0;
                        let imgData = response.data;
                        let mappedMediaEntries: MediaEntryModel[] = null;

                        if (typeof imgId === 'undefined' || imgId < 0) {
                            totalElements = response.data.pagination.totalElements;
                            lastPage = response.data.pagination.pages;
                            imgData = response.data.data;

                            mappedMediaEntries = imgData.map(media => {
                                const mappedMediaEntry: MediaEntryModel = {
                                    id: media.id,
                                    altText: media.alt_text,
                                    data: media.data,
                                    created: media.created,
                                    title: media.title,
                                    lfnr: media.lfnr
                                };
                                return mappedMediaEntry;
                            });
                        } else {
                            mappedMediaEntries = [
                                {
                                    id: imgData.id,
                                    data: imgData.data,
                                    altText: imgData.alttext,
                                    title: imgData.title,
                                    lfnr: imgData.lfnr,
                                    created: imgData.created
                                }
                            ];
                        }

                        return new fromMediaActions.SetMedia({
                            media: mappedMediaEntries,
                            crud: mediaState.payload.crud,
                            totalElements,
                            lastPage
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    loadMultipleMedia$ = this.actions$.pipe(
        ofType(fromMediaActions.LOAD_MULTIPLE_MEDIA),
        switchMap((mediaState: fromMediaActions.LoadMultipleMedia) => {
            let params = new HttpParams();
            if (mediaState.payload.thumbnail) {
                params = params.append('thumbnail', 'true');
            }
            return this.endpointService.media(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        const requests = new Array<Observable<any>>();
                        mediaState.payload.imgIds.forEach(id => {
                            if (typeof id !== 'undefined' && id !== null) {
                                requests.push(this.http.get<any>(endpoint + '/' + id, {params}));
                            }
                        });

                        if (requests.length === 0) {
                            const dummyMedia = {
                                id: -1,
                                altText: '',
                                data: null,
                                title: '',
                                created: null,
                                lfnr: -1
                            };
                            return of([dummyMedia]);
                        } else {
                            return forkJoin(requests).pipe(
                                map(response => {
                                    const mappedEntries = response.map(media => {
                                        const mappedMediaEntry: MediaEntryModel = {
                                            id: media.data.id,
                                            altText: media.data.alttext,
                                            data: media.data.data,
                                            title: media.data.title,
                                            created: media.data.created,
                                            lfnr: media.data.lfnr
                                        };
                                        return mappedMediaEntry;
                                    });
                                    return mappedEntries;
                                })
                            );
                        }
                    }),
                    map(response => {
                        return new fromMediaActions.SetMultipleMedia({
                            media: response,
                            crud: mediaState.payload.crud,
                            imageUploadType: mediaState.payload.imageUploadType,
                            referrer: mediaState.payload.referrer
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    updateMedia$ = this.actions$.pipe(
        ofType(fromMediaActions.UPDATE_MEDIA),
        switchMap((mediaState: fromMediaActions.UpdateMedia) => {
            return this.endpointService.media(HttpMethod.PUT, mediaState.payload.media.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                id: mediaState.payload.media.id,
                                alttext: mediaState.payload.media.altText,
                                title: mediaState.payload.media.title
                            }
                        ).pipe(
                            map(() => {
                                return new fromMediaActions.LoadMedia({
                                    crud: CRUD.UPDATE,
                                    size: mediaState.payload.size,
                                    page: mediaState.payload.page,
                                    order: mediaState.payload.order,
                                    column: mediaState.payload.column,
                                    thumbnail: mediaState.payload.thumbnail
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteMedia$ = this.actions$.pipe(
        ofType(fromMediaActions.DELETE_MEDIA),
        switchMap((mediaState: fromMediaActions.DeleteMedia) => {
            return this.endpointService.media(HttpMethod.DELETE, mediaState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromMediaActions.LoadMedia({
                                    crud: CRUD.DELETE,
                                    size: mediaState.payload.size,
                                    page: mediaState.payload.page,
                                    order: mediaState.payload.order,
                                    column: mediaState.payload.column,
                                    thumbnail: mediaState.payload.thumbnail
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private store: Store,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {
    }

    private handleError = (errorRes: any): Observable<fromMediaActions.HttpFail> => {
        return handleHTTPError(errorRes, fromMediaActions, 'Medien', this.loggingService);
    };
}
