import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {AnimalsService} from '../../animals/animals.service';
import * as moment from 'moment';
import {Order} from '../../shared/controls/data-table/ordering';
import {MissingService} from '../../animals/store/missing/missing.service';
import {MissingAnimalEntryModel} from '../../shared/models/animal/missing-animal-entry.model';

@Component({
    selector: 'app-missing-animals-dialog',
    templateUrl: './missing-animals-dialog.component.html',
    styleUrls: ['./missing-animals-dialog.component.scss']
})
export class MissingAnimalsDialogComponent implements OnInit {
    public searchForm: FormGroup;
    public displayErroneousInputs = false;
    public metrics: any;
    public matchedAnimals: MissingAnimalEntryModel[] = [];

    private readonly pageIndex = 1;
    private readonly entriesPerPage = -1;
    private readonly order = Order.NONE;
    private readonly orderedColumn = '';
    private readonly filter: any;
    private readonly animal: AnimalEntryModel;

    constructor(private formBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private missingAnimalService: MissingService,
                private animalsService: AnimalsService) {
        this.animal = this.data.animal;

        if (typeof data.pageIndex !== 'undefined' &&
            typeof data.entriesPerPage !== 'undefined' &&
            typeof data.order !== 'undefined' &&
            typeof data.orderedColumn !== 'undefined') {

            this.pageIndex = data.pageIndex;
            this.entriesPerPage = data.entriesPerPage;
            this.order = data.order;
            this.orderedColumn = data.orderedColumn;
            this.filter = data.filter;
        }
    }

    ngOnInit(): void {
        this.initForm();
    }

    public search(): void {
        if (!this.searchForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const updatedAnimal: AnimalEntryModel = {
            ...this.animal,
            lastMissingCheck: moment().format('YYYY-MM-DD')
        };
        this.animalsService.updateAnimal(updatedAnimal,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn,
            this.filter);
        this.animalsService.crudFinished.next(this.animal.name);

        const radius = +this.searchForm.controls.radius.value;
        this.missingAnimalService.fetchMatchedAnimals(this.animal.id, radius)
            .subscribe(animals => {
                this.matchedAnimals = animals;
            });
    }

    private initForm(): void {
        this.searchForm = this.formBuilder.group({
            radius: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        });
    }
}
