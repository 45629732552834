import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_CATEGORIES = '[Categories] Load Categories';
export const SET_CATEGORIES = '[Categories] Set Categories';
export const ADD_CATEGORY = '[Categories] Add Category';
export const UPDATE_CATEGORY = '[Categories] Update Category';
export const DELETE_CATEGORY = '[Categories] Delete Category';
export const HTTP_FAIL = '[Categories] Http Fail';

export class LoadCategories implements Action {
    readonly type = LOAD_CATEGORIES;

    constructor(public payload: CRUD) {
    }
}

export class SetCategories implements Action {
    readonly type = SET_CATEGORIES;

    constructor(public payload: { categories: DictionaryEntryModel[], operation: CRUD }) {
    }
}

export class AddCategory implements Action {
    readonly type = ADD_CATEGORY;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class UpdateCategory implements Action {
    readonly type = UPDATE_CATEGORY;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class DeleteCategory implements Action {
    readonly type = DELETE_CATEGORY;

    constructor(public payload: number) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type CategoriesActions =
    LoadCategories
    | SetCategories
    | AddCategory
    | UpdateCategory
    | DeleteCategory
    | HttpFail;

