import {Injectable} from '@angular/core';
import {Order} from '../shared/controls/data-table/ordering';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {Actions, ofType} from '@ngrx/effects';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {Store} from '@ngrx/store';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {PersonEntryModel} from '../shared/models/person-entry.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDatetimePickerInputEvent} from '@angular-material-components/datetime-picker';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as fromPersonActions from './store/persons.actions';
import * as fromApp from '../store/app.reducer';
import {Tuple, TupleHelper} from '../shared/data-types/tuple';

@Injectable()
export class PersonsService {
    public selectedDate: Moment;
    public crudFinished: Subject<string> = new Subject();

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private formBuilder: FormBuilder,
                private snackbarService: SnackbarService) {
    }

    public addPerson(personModel: PersonEntryModel,
                     lastPage: number,
                     entriesPerPage: number,
                     order: Order,
                     column: string): void {
        this.store.dispatch(new fromPersonActions.AddPerson({
            person: personModel,
            page: lastPage,
            size: entriesPerPage,
            order,
            column
        }));
    }

    public updatePerson(personModel: PersonEntryModel,
                        size: number,
                        page: number,
                        order: Order,
                        column: string,
                        personFilter?: any): void {
        this.store.dispatch(new fromPersonActions.UpdatePerson({
            person: personModel,
            size,
            page,
            order,
            column,
            personFilter
        }));
    }

    public deletePerson(personId: number, size: number, page: number, order: Order, column: string, personFilter?: any): void {
        this.store.dispatch(new fromPersonActions.DeletePerson({
            personId,
            size,
            page,
            order,
            column,
            personFilter
        }));
    }

    public fetchPersons(size = -1, page = -1, order = Order.NONE, column = '', personFilter?: any): Observable<PersonEntryModel[]> {
        return this.store.select('personList').pipe(
            take(1),
            map(personState => {
                return personState.persons;
            }),
            switchMap(persons => {
                if (persons.length === 0 || page >= 0) {
                    this.store.dispatch(new fromPersonActions.LoadPersons({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column,
                        personFilter
                    }));
                    return this.actions$.pipe(
                        ofType(fromPersonActions.SET_PERSONS),
                        take(1),
                        map((personState: fromPersonActions.SetPersons) => {
                            return personState.payload.persons;
                        })
                    );
                } else {
                    return of(persons);
                }
            })
        );
    }


    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }

    public createForm(person?: PersonEntryModel, required = true): FormGroup {
        let id = null;
        let lastName = '';
        let firstName = '';
        let street = '';
        let zip = '';
        let city = '';
        let phone = '';
        let birthday = '';
        let email = '';
        let proof = '';
        let issuer = '';
        let remark = '';

        if (typeof person !== 'undefined' && person !== null) {
            id = person.id;
            lastName = person.lastName;
            firstName = person.firstName;
            street = person.street;
            zip = person.zip;
            city = person.city;
            phone = person.phone;
            birthday = person.birthday !== null ? person.birthday : '';
            email = person.email;
            proof = person.proof;
            issuer = person.issuer;
            remark = person.remark;
        }

        const form = this.formBuilder.group({
            id: [id, Validators.pattern('^[0-9]*$')],
            lastName: [lastName],
            firstName: [firstName],
            street: [street],
            zip: [zip],
            city: [city],
            phone: [phone],
            birthday: [birthday],
            email: [email],
            proof: [proof],
            issuer: [issuer],
            remark: [remark]
        });

        if (required) {
            form.controls.lastName.setValidators([Validators.required]);
            form.controls.firstName.setValidators([Validators.required]);
            form.controls.street.setValidators([Validators.required]);
            form.controls.zip.setValidators([Validators.required]);
            form.controls.city.setValidators([Validators.required]);
            form.controls.phone.setValidators([Validators.required]);
            form.controls.email.setValidators([Validators.email]);
        }

        return form;
    }

    public dateChange($event: MatDatetimePickerInputEvent<moment.Moment & any>): void {
        this.selectedDate = $event.value;
    }

    public hasRemark(meta: Tuple[]): boolean {
        const remark = TupleHelper.hasElement(meta, 'remark');
        return remark !== undefined && remark.value;
    }
}
