import {Injectable} from '@angular/core';
import {Order} from '../shared/controls/data-table/ordering';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {Actions, ofType} from '@ngrx/effects';
import {VeterinaryTreatmentEntryModel} from '../shared/models/veterinary-treatments/veterinary-treatment-entry.model';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {DropdownOption} from '../shared/data-types/dropdown-option';
import {SelectSearch} from '../shared/services/select-box/select-search';
import {SelectBoxUtility} from '../shared/controls/select-box/select-box-utility';
import {FormerTreatmentEntryModel} from '../shared/models/veterinary-treatments/former-veterinary-treatment-entry.model';
import * as fromApp from '../store/app.reducer';
import * as fromVeterinaryTreatmentActions from './store/veterinary-treatments/veterinary-treatments.actions';
import * as fromFormerTreatmentActions from './store/former-treatments/former-treatments.actions';

@Injectable()
export class VeterinaryTreatmentsService {

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public addVeterinaryTreatment(treatmentModel: VeterinaryTreatmentEntryModel,
                                  lastPage: number,
                                  entriesPerPage: number,
                                  order: Order,
                                  column: string): void {
        this.store.dispatch(new fromVeterinaryTreatmentActions.AddVeterinaryTreatment({
            treatment: treatmentModel,
            page: lastPage,
            size: entriesPerPage,
            order,
            column
        }));
    }

    public updateVeterinaryTreatment(treatmentModel: VeterinaryTreatmentEntryModel,
                                     size: number,
                                     page: number,
                                     order: Order,
                                     column: string): void {
        this.store.dispatch(new fromVeterinaryTreatmentActions.UpdateVeterinaryTreatment({
            treatment: treatmentModel,
            size,
            page,
            order,
            column
        }));
    }

    public deleteVeterinaryTreatment(treatmentId: number, size: number, page: number, order: Order, column: string): void {
        this.store.dispatch(new fromVeterinaryTreatmentActions.DeleteVeterinaryTreatment({
            treatmentId,
            size,
            page,
            order,
            column
        }));
    }

    public fetchVeterinaryTreatments(size = -1,
                                     page = -1,
                                     order = Order.NONE,
                                     column = '',
                                     filter?: any): Observable<VeterinaryTreatmentEntryModel[]> {
        return this.store.select('veterinaryTreatmentList').pipe(
            take(1),
            map(treatmentState => {
                return treatmentState.veterinaryTreatments;
            }),
            switchMap(treatments => {
                if (treatments.length === 0 || page >= 0) {
                    this.store.dispatch(new fromVeterinaryTreatmentActions.LoadVeterinaryTreatments({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column,
                        filter
                    }));
                    return this.actions$.pipe(
                        ofType(fromVeterinaryTreatmentActions.SET_VETERINARY_TREATMENTS),
                        map((veterinaryTreatmentState: fromVeterinaryTreatmentActions.SetVeterinaryTreatments) => {
                            return veterinaryTreatmentState.payload.treatments;
                        })
                    );
                } else {
                    return of(treatments);
                }
            })
        );
    }

    // Former Treatments
    public addFormerVeterinaryTreatment(formerTreatmentModel: FormerTreatmentEntryModel,
                                        size = -1,
                                        page = -1,
                                        order = Order.NONE,
                                        column = '',
                                        filter?: any): void {
        this.store.dispatch(new fromFormerTreatmentActions.AddFormerTreatment({
            newFormerTreatment: formerTreatmentModel,
            size,
            page,
            order,
            column,
            filter
        }));
    }

    public updateFormerVeterinaryTreatment(formerTreatmentModel: FormerTreatmentEntryModel, filter?: any): void {
        this.store.dispatch(new fromFormerTreatmentActions.UpdateFormerTreatment({
            updatedFormerTreatment: formerTreatmentModel,
            filter
        }));
    }

    public deleteFormerVeterinaryTreatment(formerTreatmentId: number, filter?: any): void {
        this.store.dispatch(new fromFormerTreatmentActions.DeleteFormerTreatment({
            formerTreatmentId,
            filter
        }));
    }

    public fetchFormerVeterinaryTreatments(size: number,
                                           page: number,
                                           order: Order,
                                           column: string,
                                           filter?: any): Observable<FormerTreatmentEntryModel[]> {
        return this.store.select('formerTreatmentList').pipe(
            take(1),
            map(formerTreatmentState => {
                return formerTreatmentState.formerTreatments;
            }),
            switchMap(() => {
                this.store.dispatch(new fromFormerTreatmentActions.LoadFormerTreatments({
                    operation: CRUD.READ,
                    size,
                    page,
                    order,
                    column,
                    filter
                }));
                return this.actions$.pipe(
                    ofType(fromFormerTreatmentActions.SET_FORMER_TREATMENTS),
                    map((formerTreatmentsState: fromFormerTreatmentActions.SetFormerTreatments) => {
                        return formerTreatmentsState.payload.formerTreatments;
                    })
                );
            })
        );
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }

    public populateVeterinaryTreatmentsBox(selectSearchVeterinaryTreatment: SelectSearch): void {
        const $articleObs = this.store.select('veterinaryTreatmentList').pipe(
            take(1),
            map(veterinaryTreatmentState => {
                return veterinaryTreatmentState.veterinaryTreatments;
            }),
            switchMap(veterinaryTreatments => {
                if (veterinaryTreatments.length === 0) {
                    this.store.dispatch(new fromVeterinaryTreatmentActions.LoadVeterinaryTreatments({
                        crud: CRUD.READ,
                        size: -1,
                        page: -1,
                        order: Order.NONE,
                        column: ''
                    }));
                    return this.actions$.pipe(
                        ofType(fromVeterinaryTreatmentActions.SET_VETERINARY_TREATMENTS),
                        map((veterinaryTreatmentState: fromVeterinaryTreatmentActions.SetVeterinaryTreatments) => {
                            return veterinaryTreatmentState.payload.treatments;
                        })
                    );
                } else {
                    return of(veterinaryTreatments);
                }
            }),
            map((veterinaryTreatments: VeterinaryTreatmentEntryModel[]) => {
                return veterinaryTreatments.map((element: VeterinaryTreatmentEntryModel) => {
                    return {
                        value: SelectBoxUtility.getOptionsValue(element.description),
                        viewValue: element.description,
                        meta: [
                            {key: 'id', value: element.id.toString()},
                            {key: 'invoiceText', value: element.invoiceText},
                            {key: 'price', value: element.price}
                        ]
                    } as DropdownOption;
                });
            })
        );

        selectSearchVeterinaryTreatment.setValues($articleObs);
    }
}
