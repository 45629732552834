import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDatetimePickerInputEvent, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {BookingEntryModel} from '../../shared/models/pos/booking-entry.model';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogType} from '../../dialogs/dialog-type';
import {Order} from '../../shared/controls/data-table/ordering';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {BookingService} from '../booking.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Constants} from '../../shared/constants';
import {Actions, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import * as fromBookingActions from '../store/booking/booking.actions';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import {AuthService} from '../../auth/auth.service';
import {CRUD} from '../../shared/services/http/crud';

@Component({
    selector: 'app-cancellation',
    templateUrl: './cancellation.component.html',
    styleUrls: ['./cancellation.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class CancellationComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public displayedColumns: string[] = [
        'id',
        'article',
        'type',
        'price',
        'dateCreated',
        'cashier',
        'isATM',
        'dateCancelled',
        'controls',
        'description',
        'remark'
    ];

    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'Buchungs-Nr.',
            className: 'id'
        }, {
            key: 'article',
            header: 'Artikel',
            className: 'article'
        }, {
            key: 'type',
            header: 'Typ',
            className: 'type'
        }, {
            key: 'price',
            header: 'Kosten',
            className: 'price'
        }, {
            key: 'description',
            header: 'Beschreibung',
            className: 'description'
        }, {
            key: 'remark',
            header: 'Bemerkung',
            className: 'remark'
        }, {
            key: 'dateCreated',
            header: 'Datum',
            className: 'dateCreated'
        }, {
            key: 'cashier',
            header: 'Kassier',
            className: 'cashier'
        }, {
            key: 'isATM',
            header: 'Bankomat',
            className: 'isATM'
        }, {
            key: 'dateCancelled',
            header: 'Stornierung',
            className: 'isCancelled'
        }
    ];
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public bookings: BookingEntryModel[];
    public entriesPerPage = 10;
    public bookingLength = 0;
    public Constants = Constants;
    public loading: boolean = null;
    public selectedDate: Moment;

    private simpleDialogType = new DialogType();
    private pageIndex = 1;
    private order = Order.NONE;
    private orderedColumn = '';
    private cancellationSub: Subscription;
    private notSortable = ['price', 'dateCreated'];
    private currentUser: UserEntryModel;

    constructor(private dialog: MatDialog,
                private authService: AuthService,
                private formBuilder: FormBuilder,
                private bookingService: BookingService,
                private actions$: Actions) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initEvents();
    }

    ngOnDestroy(): void {
        if (this.cancellationSub) {
            this.cancellationSub.unsubscribe();
        }
    }

    public switchPage($event: PageEvent): void {
        const dummyStart = this.selectedDate.set({hour: 0, minute: 0, second: 0}).clone();
        const dummyEnd = this.selectedDate.set({hour: 23, minute: 59, second: 59}).clone();
        this.pageIndex = $event.pageIndex + 1;
        this.bookingService
            .fetchBookings(this.entriesPerPage, this.pageIndex, dummyStart, dummyEnd, this.order, this.orderedColumn)
            .subscribe();
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        const dummyStart = this.selectedDate.set({hour: 0, minute: 0, second: 0}).clone();
        const dummyEnd = this.selectedDate.set({hour: 23, minute: 59, second: 59}).clone();
        this.bookingService
            .fetchBookings(this.entriesPerPage, this.pageIndex, dummyStart, dummyEnd, this.order, this.orderedColumn)
            .subscribe();
        this.paginator.firstPage();
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active;
        const dummyStart = this.selectedDate.set({hour: 0, minute: 0, second: 0}).clone();
        const dummyEnd = this.selectedDate.set({hour: 23, minute: 59, second: 59}).clone();
        this.bookingService
            .fetchBookings(this.entriesPerPage, this.pageIndex, dummyStart, dummyEnd, this.order, this.orderedColumn)
            .subscribe();
    }

    public columnSortable(key: any): boolean {
        return !this.notSortable.includes(key);
    }

    public search(): void {
        this.displayErroneousInputs = true;

        if (!this.filterForm.valid) {
            return;
        }

        this.loading = true;
        const dummyStart = this.selectedDate.set({hour: 0, minute: 0, second: 0}).clone();
        const dummyEnd = this.selectedDate.set({hour: 23, minute: 59, second: 59}).clone();
        this.bookingService
            .fetchBookings(this.entriesPerPage, this.pageIndex, dummyStart, dummyEnd, this.order, this.orderedColumn)
            .subscribe();

        this.cancellationSub = this.actions$.pipe(
            ofType(fromBookingActions.SET_BOOKINGS),
            tap((bookingState: fromBookingActions.SetBookings) => {
                this.bookingLength = bookingState.payload.totalElements;
                this.loading = false;
                this.bookings = bookingState.payload.bookings;
                if (bookingState.payload.operation === CRUD.CREATE) {
                    this.bookingService.handleRequestSuccess(CRUD.CREATE, 'Stornierung', '');
                }
            })
        ).subscribe();
    }

    public cancelBooking(booking: BookingEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.CANCEL_BOOKING,
                entity: 'Buchung',
                identifier: 'Buchung ' + booking.id + ' - ' + booking.article,
                currentUser: this.currentUser
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== false) {
                const start = moment(this.filterForm.controls.dateAt.value).set({
                    hour: 0,
                    minute: 0,
                    second: 0
                }).format('YYYY-MM-DD HH:mm:ss');

                const end = moment(this.filterForm.controls.dateAt.value).set({
                    hour: 23,
                    minute: 59,
                    second: 59
                }).format('YYYY-MM-DD HH:mm:ss');

                const updatedBooking: BookingEntryModel = {
                    ...booking,
                    cancellationReason: result.reason,
                    cancellationUser: result.cancellator
                };

                this.bookingService.updateBooking(updatedBooking,
                    this.pageIndex,
                    this.entriesPerPage,
                    this.order,
                    this.orderedColumn,
                    true,
                    false,
                    start,
                    end);

                const newCancelBookingEntry: BookingEntryModel = {
                    ...booking,
                    article: 'Storno ' + booking.article,
                    price: booking.price * -1,
                    cancellationReason: result.reason,
                    cancellationUser: result.cancellator
                };
                this.bookingService
                    .addBooking(
                        newCancelBookingEntry,
                        this.pageIndex,
                        this.entriesPerPage,
                        this.order,
                        this.orderedColumn,
                        true,
                        start,
                        end);
            }
        });
    }

    public dateChange($event: MatDatetimePickerInputEvent<moment.Moment & any>): void {
        this.selectedDate = $event.value.set({hour: 0, minute: 0, second: 0}).clone();
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            dateAt: [this.selectedDate, [Validators.required]]
        });
    }

    private initEvents(): void {
        this.authService.loggedInUser.subscribe(currentUser => {
            this.currentUser = currentUser;
        });
    }
}
