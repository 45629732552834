<div class="title-bar not-printable">
    <h3>Statistik</h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Tieraufenthalt</h4>
                </div>
                <div class="card-body">
                    <div *ngIf="occupationEntryModel" class="container">
                        <div class="row mb-3">
                            <div class="col">Gesamte Aufenthaltstage</div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col img-col">
                                <img width="30"
                                     height="30"
                                     alt="avatar"
                                     class="mr-2 ng-tns-c211-56"
                                     src="assets/img/avatar/dog-placeholder.png">
                            </div>
                            <div class="col-3">
                                Hunde:
                            </div>
                            <div class="col-3 font-weight-bolder">{{occupationEntryModel.dogs}}</div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col img-col">
                                <img width="33"
                                     height="30"
                                     alt="avatar"
                                     class="mr-2 ng-tns-c211-56"
                                     src="assets/img/avatar/cat-placeholder.png">
                            </div>
                            <div class="col-3">
                                Katzen:
                            </div>
                            <div class="col-3 font-weight-bolder">{{occupationEntryModel.cats}}</div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col img-col">
                                <img width="30"
                                     height="30"
                                     alt="avatar"
                                     class="mr-2 ng-tns-c211-56"
                                     src="assets/img/avatar/small-animal-placeholder.png">
                            </div>
                            <div class="col-3">
                                Kleintiere:
                            </div>
                            <div class="col-3 font-weight-bolder">{{occupationEntryModel.smallAnimals}}</div>
                        </div>
                    </div>

                    <div class="container mt-3">
                        <div class="row">
                            <div class="col" id="download-link"></div>
                        </div>
                    </div>

                    <div *ngIf="loading"
                         style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-spinner
                                color="accent"
                                mode="indeterminate">
                        </mat-progress-spinner>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>

                </div>
                <div class="card-body pt-0">
                    <app-statistics-filter (filterEvent)="filter($event)"></app-statistics-filter>
                </div>
            </div>
        </div>
    </div>
</div>

