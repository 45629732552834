<div id="image-upload-container" class="container p-0 mt-4">
    <div class="row">
        <div class="col-md-12">
            <div id="drop-area"
                 class="position-relative"
                 appDragDrop
                 *ngIf="showDropArea && loading === false"
                 (dragDropEmitter)="dragDropEvent($event)">
                <div id="overlay" class="position-absolute" [ngClass]="{'hoverWithImage': isUserAboutToDropFile}">
                </div>
                <div class="row inner">
                    <div class="col">
                        <h5 class='mb-2 font-weight-bolder'>Hier Datei ablegen</h5>
                        <div><p>oder</p></div>
                        <div class="input-wrapper">
                            <button type="button" color="primary" mat-raised-button (click)="fileInput.click()">
                                Datei wählen
                            </button>
                            <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file">
                        </div>
                        <div class="mt-4"><p class="mb-0 small">Maximale Dateigröße: 16MB</p></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row"
         id="image-crop-container"
         [hidden]="!showCropArea">
        <div class="col-md-12">
            <h4 class="font-weight-bold">{{filename}}</h4>
        </div>
        <div class="col-md-8 mt-4">
            <image-cropper
                #imageCropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="keepAspectRatio"
                [aspectRatio]="1160 / 920"
                [cropper]="cropper"
                format="jpeg"
                [canvasRotation]="rotation"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
            <div id="preview" class="mt-5">
                <h4>Vorschau</h4>
                <img [src]="croppedImage" width="200" />
            </div>
        </div>
        <div class="col-md 4">
            <div class="card crop-card">
                <div class="card-header">
                    <h2 class="card-title">Bild zuschneiden</h2>
                </div>
                <div class="card-body">
                    <form [formGroup]="cropForm">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field>
                                    <mat-label>Breite (ideal 1160)</mat-label>
                                    <input matInput
                                           readonly="true"
                                           placeholder="Breite (ideal 1160)"
                                           formControlName="width">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <mat-form-field>
                                    <mat-label>Höhe (ideal 920)</mat-label>
                                    <input matInput
                                           readonly="true"
                                           placeholder="Höhe (ideal 920)"
                                           formControlName="height">
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="card crop-card">
                <div class="card-header">
                    <h2 class="card-title">Bild drehen</h2>
                </div>
                <div class="card-body pb-2">
                    <div class="d-flex justify-content-around">
                        <div><a class="icon" (click)="rotation = rotation - 1">Links<i class="fas fa-undo ml-1"></i></a>
                        </div>
                        <div><a class="icon" (click)="rotation = rotation + 1">Rechts<i
                                class="fas fa-redo ml-1"></i></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5 align-items-center" [hidden]="!showCropArea">
        <div class="upload-image col-md-3">
            <button type="button" color="primary" mat-raised-button (click)="addImage()">
                Bild hinzufügen
            </button>
        </div>
        <div class="col-md-9">
            <div id="progress-container" [hidden]="!isUploading">
                <div id="progress-bar"
                     class="progress d-flex"
                     [@uploadExpand]="isUploading ? 'visible' : 'hidden'">
                    <div class="progress-value"
                         [style]="{'width': progress + '%'}"
                         #progressBar></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loading"
         style="display: flex; justify-content: center; align-items: center; background: white;">
        <mat-progress-spinner
                color="accent"
                mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>
