<div [ngClass]="!visible ? 'not-printable' : ''" class="d-flex flex-column">
    <section id="animal-data" class="d-flex justify-content-between border-0">
        <div class="container">
            <div class="row">
                <div class="col-9">
                    <div class="row">
                        <div class="col-4 text-right">
                            LfNr.:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{animal.id}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Geb.-Datum:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{animal.birthday | date:'dd.MM.yyyy'}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Alter:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{Age.printAge(animal.birthday)}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Tierart:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{animal.species.name}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Rasse:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{animal.race.name}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Geschlecht:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{Gender.getGender(animal.gender)}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Kastr.:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{CastrationStatus.getCastrationStatus(animal.castrated)}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Name:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{animal.name}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4 text-right">
                            Chip-Nr.:
                        </div>
                        <div class="col-8 text-left font-weight-bolder">
                            {{animal.chipNumber}}
                        </div>
                    </div>
                </div>

                <div class="col-3">
                    <span class="font-weight-bolder text-right mb-auto">{{data.date | date:'dd.MM.yyyy'}}</span>
                    <img [src]="this.sanitizeBase64(avatar)"
                         width="240"
                         height="190"
                         style="top: 198px; position: relative"
                         class="mr-2 mt-auto" alt="avatar"
                         *ngIf="avatar !== null && avatar !== undefined && avatar !== ''">
                </div>
            </div>

        </div>

    </section>

    <section class="text-left mt-3" style="height: 85px">
        <div class="text-left box-content" *ngIf="data.person !== null && data.person !== undefined">
            <ng-container *ngIf="actionType !== undefined">
                <ng-container *ngIf="actionType.name === ActionType.FINDLING.key">
                    {{actionTypeName}} — {{data.streetFound}},  {{data.zipFound}} -
                    {{data.cityFound}}
                </ng-container>
                <ng-container *ngIf="actionType.name !== ActionType.FINDLING.key">
                    {{actionTypeName}} — {{data.person.street}}, {{data.person.zip}} - {{data.person.city}}
                </ng-container>
            </ng-container>
        </div>
    </section>

    <div class="mt-4 mb-3 left-col font-weight-bolder text-left headline">bes. Kennzeichen</div>
    <section class="d-flex text-left" style="height: 120px">
        <div class="text-left box-content">
            {{animal.specialCriteria}}
        </div>
    </section>

    <div class="mt-4 mb-3 left-col font-weight-bolder text-left headline">Beschreibung</div>
    <section class="d-flex text-left" style="height: 90px">
        <div class="text-left box-content">
            {{characteristicsService.printCharacteristics(animal.characteristics)}}
        </div>
    </section>

    <div class="mt-4 mb-3 left-col font-weight-bolder text-left headline">Eigenschaften</div>
    <section class="d-flex text-left" style="height: 250px">
        <div class="text-left box-content">
            {{animal.properties}}
        </div>
    </section>

    <div class="mt-4 mb-3 left-col font-weight-bolder text-left headline" style="font-size:30px">Abgabegrund</div>
    <section class="d-flex text-left" style="height: 100px">
        <div class="text-left box-content" *ngIf="transactionHistory !== null && transactionHistory !== undefined">
            {{transactionHistory}}
        </div>
    </section>
</div>
