import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {of} from 'rxjs/internal/observable/of';
import * as fromAnimalActions from './animal.actions';
import {Injectable} from '@angular/core';
import {Order} from '../../../shared/controls/data-table/ordering';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {combineLatest, forkJoin, from, Observable} from 'rxjs';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {isArray} from 'rxjs/internal-compatibility';
import {AnimalActionConverter} from '../../../shared/converter/animal-action-converter';
import {LoggingService} from '../../../shared/logging/logging.service';
import {tap} from 'rxjs/internal/operators/tap';
import {defaultIfEmpty} from 'rxjs/internal/operators/defaultIfEmpty';
import * as fromCareSpaceActions from '../care-space/care-space.actions';
import {handleHTTPError} from '../../../shared/error-handling';
import {InvalidAPIRequest} from '../../../shared/data-types/error-types';

@Injectable()
export class AnimalEffects {
    @Effect()
    animalList = this.actions$.pipe(
        ofType(fromAnimalActions.LOAD_ANIMALS),
        switchMap((animalState: fromAnimalActions.LoadAnimals) => {
            const page = animalState.payload.page;
            const size = animalState.payload.size;
            const order = animalState.payload.order;
            const orderColumn = animalState.payload.column;
            const animalFilter = animalState.payload.animalFilter;
            const addedOrUpdatedAnimals = animalState.payload.addedOrUpdatedAnimals;
            const usePagination = animalState.payload.usePagination;
            const includeHistoricalTransactions = animalState.payload.includeHistoricalTransactions;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (usePagination) {
                params = params.append('use_pagination', usePagination.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            params = params.append('filter', 'true');

            if (animalFilter) {
                if (isArray(animalFilter.id)) {
                    animalFilter.id.forEach(id => {
                        params = params.append('id', id);
                    });
                } else {
                    params = typeof animalFilter.id !== 'undefined' && animalFilter.id !== '' ?
                        params.append('id', animalFilter.id) : params;
                }

                params = typeof animalFilter.fundtierId !== 'undefined' && animalFilter.fundtierId !== '' ?
                    params.append('fundtier_id', animalFilter.fundtierId) : params;
                params = typeof animalFilter.name !== 'undefined' && animalFilter.name !== '' ?
                    params.append('name', animalFilter.name) : params;
                params = typeof animalFilter.birthday !== 'undefined' && animalFilter.birthday !== '' ?
                    params.append('birthday', animalFilter.birthday) : params;
                params = typeof animalFilter.chipNumber !== 'undefined' && animalFilter.chipNumber !== '' ?
                    params.append('chipnumber', animalFilter.chipNumber) : params;
                params = typeof animalFilter.actionType !== 'undefined' && animalFilter.actionType !== '' ?
                    params.append('action_type', animalFilter.actionType) : params;
                params = typeof animalFilter.species !== 'undefined' && animalFilter.species !== '' ?
                    params.append('species', animalFilter.species) : params;
                params = typeof animalFilter.race !== 'undefined' && animalFilter.race !== '' ?
                    params.append('race', animalFilter.race) : params;
                params = typeof animalFilter.shelter !== 'undefined' && animalFilter.shelter !== '' ?
                    params.append('shelter_status', animalFilter.shelter) : params;
                params = typeof animalFilter.gender !== 'undefined' && animalFilter.gender !== '' ?
                    params.append('gender', animalFilter.gender) : params;
                params = typeof animalFilter.noGivingAway !== 'undefined' && animalFilter.noGivingAway !== '' ?
                    params.append('no_giving_away', animalFilter.noGivingAway) : params;
                params = typeof animalFilter.castration !== 'undefined' && animalFilter.castration !== '' ?
                    params.append('castration_status', animalFilter.castration) : params;
                params = typeof animalFilter.from !== 'undefined' && animalFilter.from !== '' ?
                    params.append('transactions_from', animalFilter.from) : params;
                params = typeof animalFilter.to !== 'undefined' && animalFilter.to !== '' ?
                    params.append('transactions_to', animalFilter.to) : params;
                params = typeof animalFilter.isPremium !== 'undefined' && animalFilter.isPremium !== '' ?
                    params.append('is_premium', animalFilter.isPremium) : params;
                params = typeof animalFilter.includeCareSpaceAnimals !== 'undefined' && animalFilter.includeCareSpaceAnimals !== '' ?
                    params.append('has_carespace', animalFilter.includeCareSpaceAnimals) :
                    params.append('has_carespace', 'false');
                params = typeof animalFilter.includeDeadAnimals !== 'undefined' && animalFilter.includeDeadAnimals !== '' ?
                    params.append('include_dead_animals', animalFilter.includeDeadAnimals) :
                    params.append('include_dead_animals', 'false');
                params = params.append('include_historical_transactions', includeHistoricalTransactions + '');
            } else {
                params = params.append('include_dead_animals', 'false');
                params = params.append('has_carespace', 'true');
            }

            return this.endpointService.animals(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params});
                    }),
                    map(response => {
                        const totalElements = usePagination ? response.data.pagination.totalElements : response.data.length;
                        const lastPage = usePagination ? response.data.pagination.pages : 1;
                        const fetchedEntries = usePagination ? response.data.data : response.data;
                        const mappedAnimalArr: AnimalEntryModel[] = fetchedEntries.map(animal => {
                            const mappedAnimal: AnimalEntryModel = AnimalActionConverter.toAnimalEntryModel(animal);
                            return mappedAnimal;
                        });

                        return new fromAnimalActions.SetAnimals({
                            animals: mappedAnimalArr,
                            totalElements,
                            crud: animalState.payload.crud,
                            lastPage,
                            assignDataSource: typeof animalState.payload.assignDataSource !== 'undefined' ?
                                animalState.payload.assignDataSource : true,
                            addedOrUpdatedAnimals,
                            invalidAPIRequests: animalState.payload.invalidAPIRequests
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    addAnimal$ = this.actions$.pipe(
        ofType(fromAnimalActions.ADD_ANIMAL),
        switchMap((animalState: fromAnimalActions.AddAnimal) => {
            return this.endpointService.animals(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    const addAnimalRequest$ = this.http
                        .post<any>(endpoint, AnimalActionConverter.toHttp(animalState.payload.animal));

                    return addAnimalRequest$.pipe(
                        take(1),
                        switchMap((response: any) => {
                            const newAnimalId = response.data.id;
                            const putImages$ = this.http.put<any>(endpoint + '/' + newAnimalId + '/images', {
                                image_ids: animalState.payload.assignedImageIds
                            });
                            return combineLatest([putImages$, of(response.data)]);
                        }),
                        map(([, animalResponseData]: [any, any]) => {
                            const animalEntry: AnimalEntryModel = AnimalActionConverter.toAnimalEntryModel(animalResponseData);
                            return new fromAnimalActions.LoadAnimals({
                                crud: CRUD.CREATE,
                                size: animalState.payload.size,
                                page: animalState.payload.page,
                                order: animalState.payload.order,
                                column: animalState.payload.column,
                                addedOrUpdatedAnimals: [animalEntry]
                            });
                        }),
                        catchError(error => {
                            return this.handleError(error);
                        })
                    );
                })
            );
        })
    );

    @Effect()
    addAnimals$ = this.actions$.pipe(
        ofType(fromAnimalActions.ADD_ANIMALS),
        switchMap((animalState: fromAnimalActions.AddAnimals) => {
            return this.endpointService.animals(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    const addAnimalsObs$ = [];
                    animalState.payload.animals.forEach(animal => {
                        const addAnimal$ = this.http
                            .post<any>(endpoint, AnimalActionConverter.toHttp(animal));
                        addAnimalsObs$.push(addAnimal$);
                    });

                    return forkJoin(addAnimalsObs$).pipe(
                        take(1),
                        map((response) => {
                            const newAnimals = response.map((entry: any) => {
                                return entry.data;
                            });
                            return new fromAnimalActions.LoadAnimals({
                                crud: CRUD.CREATE,
                                order: animalState.payload.order,
                                column: animalState.payload.column,
                                size: animalState.payload.size,
                                page: animalState.payload.page,
                                addedOrUpdatedAnimals: newAnimals
                            });
                        }),
                        catchError(error => {
                            return this.handleError(error);
                        })
                    );
                })
            );
        })
    );

    @Effect()
    updateAnimal$ = this.actions$.pipe(
        ofType(fromAnimalActions.UPDATE_ANIMAL),
        switchMap((animalState: fromAnimalActions.UpdateAnimal) => {
            return this.endpointService.animals(HttpMethod.PUT, animalState.payload.animal.id).pipe(
                take(1),
                switchMap(endpoint => {
                    const updateAnimalRequest$ = this.http
                        .put<any>(endpoint, AnimalActionConverter.toHttp(animalState.payload.animal));

                    return updateAnimalRequest$.pipe(
                        take(1),
                        map((response: any) => {
                            return new fromAnimalActions.LoadAnimals({
                                crud: CRUD.UPDATE,
                                order: animalState.payload.order,
                                column: animalState.payload.column,
                                size: animalState.payload.size,
                                page: animalState.payload.page,
                                animalFilter: animalState.payload.animalFilter,
                                addedOrUpdatedAnimals: [animalState.payload.animal],
                                usePagination: animalState.payload.usePagination,
                                invalidAPIRequests: response.data.invalid_api_requests.map(entry => {
                                    return {
                                        animalId: entry.animal_id,
                                        errorMsg: entry.message
                                    };
                                })
                            });
                        }),
                        catchError(error => {
                            return this.handleError(error);
                        })
                    );
                })
            );
        })
    );

    @Effect()
    updateAnimals$ = this.actions$.pipe(
        ofType(fromAnimalActions.UPDATE_ANIMALS),
        switchMap((animalState: fromAnimalActions.UpdateAnimals) => {
            const updateObsArr$ = [];
            animalState.payload.forEach(animal => {
                const obs$ = this.endpointService.animals(HttpMethod.PUT, animal.id).pipe(
                    take(1),
                    mergeMap(endpoint => {
                        return this.http
                            .put<any>(endpoint, AnimalActionConverter.toHttp(animal));
                    })
                );
                updateObsArr$.push(obs$);
            });

            return forkJoin(updateObsArr$).pipe(
                take(1),
                map(() => {
                    return new fromAnimalActions.LoadAnimals({
                        crud: CRUD.UPDATE,
                        order: Order.NONE,
                        column: '',
                        size: -1,
                        page: 1,
                        addedOrUpdatedAnimals: animalState.payload
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addAndUpdateAnimals$ = this.actions$.pipe(
        ofType(fromAnimalActions.ADD_AND_UPDATE_ANIMALS),
        switchMap((animalState: fromAnimalActions.AddAndUpdateAnimals) => {
            const mappedAnimals = [];
            let invalidAPIRequests: InvalidAPIRequest[] = null;

            const newAnimals = animalState.payload.newAnimals;
            const addAnimalsObs$ = from(newAnimals)
                .pipe(
                    concatMap(animal => {
                        return this.endpointService.animals(HttpMethod.POST).pipe(
                            take(1),
                            concatMap(endpoint => {
                                return this.http
                                    .post<any>(endpoint, AnimalActionConverter.toHttp(animal));
                            }),
                            tap(res => {
                                const animalModel = AnimalActionConverter.toAnimalEntryModel(res.data);
                                mappedAnimals.push(animalModel);
                            }),
                        );
                    }),
                    defaultIfEmpty(of())
                );

            const updateAnimals = animalState.payload.updatedAnimals;
            const updateAnimalsObs$ = from(updateAnimals)
                .pipe(
                    concatMap(animal => {
                        return this.endpointService.animals(HttpMethod.PUT, animal.id).pipe(
                            take(1),
                            concatMap(endpoint => {
                                return this.http
                                    .put<any>(endpoint, AnimalActionConverter.toHttp(animal));
                            }),
                            tap(res => {
                                const animalModel = AnimalActionConverter.toAnimalEntryModel(res.data.updated_animal);
                                invalidAPIRequests = res.data.invalid_api_requests.map(entry => {
                                    return {
                                        animalId: entry.animal_id,
                                        errorMsg: entry.message
                                    };
                                });
                                mappedAnimals.push(animalModel);
                            }),
                        );
                    }),
                    defaultIfEmpty(of())
                );

            return forkJoin([addAnimalsObs$, updateAnimalsObs$]).pipe(
                map((_) => {
                    if (animalState.payload.fetchAnimals) {
                        return new fromAnimalActions.LoadAnimals({
                            crud: CRUD.UPDATE,
                            order: Order.NONE,
                            column: '',
                            size: -1,
                            page: 1,
                            addedOrUpdatedAnimals: mappedAnimals,
                            fetchAnimals: animalState.payload.fetchAnimals
                        });
                    } else {
                        return new fromAnimalActions.SetAnimals({
                            crud: CRUD.UPDATE,
                            totalElements: 0,
                            assignDataSource: false,
                            lastPage: 1,
                            addedOrUpdatedAnimals: mappedAnimals,
                            animals: [],
                            invalidAPIRequests
                        });
                    }
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    deleteAnimal$ = this.actions$.pipe(
        ofType(fromAnimalActions.DELETE_ANIMAL),
        switchMap((animalState: fromAnimalActions.DeleteAnimal) => {
            return this.endpointService.animals(HttpMethod.DELETE, animalState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromAnimalActions.LoadAnimals({
                                    crud: CRUD.DELETE,
                                    order: animalState.payload.order,
                                    column: animalState.payload.column,
                                    size: animalState.payload.size,
                                    page: animalState.payload.page,
                                    usePagination: animalState.payload.usePagination
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {
    }

    private handleError = (errorRes: any): Observable<fromCareSpaceActions.HttpFail> => {
        return handleHTTPError(errorRes, fromAnimalActions, 'Tieren', this.loggingService);
    };
}
