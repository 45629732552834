<div class="row">
    <div class="col-md-4">
        Datum: {{datePerformed | date: 'dd.MM.yyyy'}}
    </div>
    <div class="col-md-5">
        Kontrolleur: {{supervisor}}
    </div>
    <div class="col-md-3 text-right">
        <a class="fas fa-trash-alt icon mr-1"
           style="font-size: 17px"
           (click)="deleteSupervision()"
           matTooltip="Eintrag löschen"
           matTooltipPosition="right"
           matTooltipClass="tooltip-popup"></a>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-4">
        <div class="person-number">Personennummer: <span>{{person.id}}</span></div>
        <div class="full-name">
            <span class="first-name">{{person.firstName}}</span>&nbsp;
            <span class="last-name">{{person.lastName}}</span>
        </div>
        <div class="address">{{person.street}}</div>
        <div class="city">{{person.zip}} - {{person.city}}</div>
    </div>
    <div class="col-md-8">
        <div class="row">
            <div class="col-4">Geburtsdatum:</div>
            <div class="col-8" class="birthday">{{person.birthday | date: 'dd.MM.yyyy'}}</div>
        </div>
        <div class="row">
            <div class="col-4">Telefonnummer:</div>
            <div class="col-8" class="phone">{{person.phone}}</div>
        </div>
        <div class="row">
            <div class="col-4">Nachweis:</div>
            <div class="col-8" class="proof">{{person.proof}}</div>
        </div>
    </div>
</div>

<div class="row mt-3">
    <div class="col-md-12">Report: {{report}}</div>
</div>
