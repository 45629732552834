import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import {CareSpaceEntryModel} from '../../../shared/models/animal/care-space-entry.model';
import * as fromApp from '../../../store/app.reducer';
import * as fromCareSpaceActions from './care-space.actions';
import {Order} from '../../../shared/controls/data-table/ordering';
import {FormGroup} from '@angular/forms';
import {PersonEntryModel} from '../../../shared/models/person-entry.model';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';

@Injectable({
    providedIn: 'root'
})
export class CareSpaceService {
    public crudFinished: Subject<AnimalEntryModel> = new Subject();

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public fetchCareSpaces(size = -1, page = -1, order = Order.NONE, column = '', filter?: any): Observable<CareSpaceEntryModel[]> {
        return this.store.select('careSpaceList').pipe(
            take(1),
            map(careSpaceService => {
                return careSpaceService.careSpaces;
            }),
            switchMap(() => {
                this.store.dispatch(new fromCareSpaceActions.LoadCareSpaces({
                    crud: CRUD.READ,
                    size,
                    page,
                    order,
                    column,
                    filter
                }));
                return this.actions$.pipe(
                    ofType(fromCareSpaceActions.SET_CARE_SPACES),
                    map((careSpaceService: fromCareSpaceActions.SetCareSpaces) => {
                        return careSpaceService.payload.careSpaces;
                    })
                );
            })
        );
    }

    public addCareSpaces(careSpaces: CareSpaceEntryModel[],
                         size = -1,
                         page = -1,
                         order = Order.NONE,
                         column = '',
                         filter?: any): void {
        this.store.dispatch(new fromCareSpaceActions.AddCareSpaces({
            careSpaces,
            size,
            page,
            order,
            column,
            filter
        }));
    }

    public updateCareSpace(careSpace: CareSpaceEntryModel): void {
        this.store.dispatch(new fromCareSpaceActions.UpdateCareSpace(careSpace));
    }

    public updateCareSpaces(careSpaces: CareSpaceEntryModel[],
                            size = -1,
                            page = -1,
                            order = Order.NONE,
                            column = '',
                            filter?: any): void {
        this.store.dispatch(new fromCareSpaceActions.UpdateCareSpaces({
                careSpaces,
                size,
                page,
                order,
                column,
                filter
            }
        ));
    }

    public initForm(careTaker: PersonEntryModel, careSpaceForm: FormGroup): void {
        careSpaceForm.patchValue({
            careTakerName: careTaker.firstName + ' ' + careTaker.lastName,
            careTakerAddress: careTaker.street + careTaker.zip + ' - ' + careTaker.city,
            careTakerPhone: careTaker.phone
        });

    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        const sbRef = this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);

        sbRef.afterOpened().subscribe(() => {
            this.snackbarService.opened.next(true);
        });
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
