import {Action} from '@ngrx/store';
import {ErrorTypes} from '../../data-types/error-types';

export const ADD_LOGGING = '[Loggings] Add Loggings';
export const HTTP_FAIL = '[Loggings] Http Fail';

export class AddLogging implements Action {
    readonly type = ADD_LOGGING;

    constructor(public payload: string) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type LoggingActions =
    | AddLogging
    | HttpFail;

