import {TransactionEntryModel} from '../models/transaction-entry.model';
import {SupervisionEntryModel} from '../models/supervision-entry.model';
import {AnimalEntryModel} from '../models/animal/animal-entry.model';
import {ActionTypeConverter} from './action-type-converter';
import {CareSpaceConverter} from './care-space-converter';
import {ActionConverter} from './action-converter';

export class AnimalActionConverter {
    public static toTransactionHTTP(action: TransactionEntryModel): any {
        return {
            person_id: typeof action.person !== 'undefined' && action.person !== null ?
                action.person.id : null,
            owner_id: typeof action.owner !== 'undefined' && action.owner !== null ?
                action.owner.id : null,
            action_type_id: action.actionTypeId,
            animal_id: action.animal.id,
            death_reason: action.deathReason,
            date_performed: action.date,
            give_away_reason: action.giveAwayReason,
            taking_over_reason: action.takingOverReason,
            public_authority: action.publicAuthority,
            stay_from: action.stayFrom,
            stay_until: action.stayUntil,
            deposit: action.deposit,
            street_found: action.streetFound,
            zip_found: action.zipFound,
            city_found: action.cityFound,
            how_animal_was_found: action.howAnimalWasFound,
            costs_per_day: action.costsPerDayTotal,
            deposit_total: action.depositTotal,
            shelter_status: action.shelter,
            additional_costs: action.additionalCosts,
            is_person_address_residence_address: action.isPersonAddressResidenceAddress,
            residence_street: action.residenceStreet,
            residence_zip: action.residenceZip,
            residence_city: action.residenceCity,
            has_fence: action.hasFence,
            has_garden: action.hasGarden,
            other_animals_in_household: action.otherAnimalsInHousehold,
            was_printed: action.wasPrinted
        };
    }

    public static toTransactionEntryModel(action: any): TransactionEntryModel {
        const transaction: TransactionEntryModel = {
            id: action.id,
            transactionId: action.transaction_id,
            supervisions: (ActionConverter.toSupervisionEntryModel(action.supervisions) as SupervisionEntryModel[]),
            person: ActionConverter.toPersonEntryModel(action.person),
            owner: ActionConverter.toPersonEntryModel(action.owner),
            actionTypeId: action.action_type_id,
            animal: AnimalActionConverter.toAnimalEntryModel(action.animal),
            date: action.date_performed,
            giveAwayReason: action.give_away_reason,
            takingOverReason: action.taking_over_reason, // Abnahmegrund
            publicAuthority: action.public_authority,
            stayFrom: action.stay_from,
            stayUntil: action.stay_until,
            deposit: action.deposit,
            streetFound: action.street_found,
            shelter: action.shelter_status,
            zipFound: action.zip_found,
            cityFound: action.city_found,
            howAnimalWasFound: action.how_animal_was_found,
            costsPerDayTotal: action.costs_per_day,
            depositTotal: action.deposit_total,
            additionalCosts: action.additional_costs,
            isPersonAddressResidenceAddress: action.is_person_address_residence_address,
            residenceStreet: action.residence_street,
            residenceZip: action.residence_zip,
            residenceCity: action.residence_city,
            hasFence: action.has_fence,
            hasGarden: action.has_garden,
            deathReason: action.death_reason,
            otherAnimalsInHousehold: action.other_animals_in_household,
            wasPrinted: action.was_printed
        };
        return transaction;
    }

    public static toHttp(animal: AnimalEntryModel): any {
        return {
            avatar_id: animal.avatarId,
            attribute_values: animal.attributes,
            image_ids: animal.images,
            accommodation_id: animal.accommodation.id,
            accommodation_description: animal.accommodationDescription,
            chipnumber: animal.chipNumber,
            action_type: ActionTypeConverter.toHTTP(animal.type),
            is_premium: animal.premiumAnimal,
            is_sponsored: animal.sponsoredAnimal,
            is_hidden_on_website: animal.hiddenOnWebsite,
            no_giving_away: animal.noGivingAway,
            no_giving_away_reason: animal.noGivingAwayReason,
            name: animal.name,
            birthday: animal.birthday,
            species_id: animal.species.id,
            race_id: animal.race.id,
            gender: animal.gender,
            castration_status: animal.castrated,
            shelter_status: animal.shelter,
            properties: animal.properties,
            special_criteria: animal.specialCriteria,
            characteristics: animal.characteristics,
            is_reserved: animal.reserved,
            reserved_for: animal.reservedFor,
            reserved_until: animal.reservedUntil,
            description: animal.description,
            should_synchronize_fundtier_database: animal.synchronizeExternalDatabase,
            fundtier_database_remark: animal.fundtierDBRemark,
            fundtier_id: animal.fundtierId,
            sponsor_logo: animal.sponsorLogo,
            sponsor_website: animal.sponsorWebsite,
            sponsor_text: animal.sponsorText,
            sponsor_name: animal.sponsorName,
            last_missing_check: animal.lastMissingCheck
        };
    }

    public static toAnimalEntryModel(animalResponseData: any): AnimalEntryModel {
        if (animalResponseData === null || typeof animalResponseData === 'undefined') {
            return null;
        } else {
            return {
                id: animalResponseData.id,
                personId: animalResponseData.person_id,
                attributes: animalResponseData.attribute_values,
                images: animalResponseData.image_ids,
                dateCreated: animalResponseData.created,
                avatarId: animalResponseData.avatar_id,
                accommodation: animalResponseData.accommodation,
                accommodationDescription: animalResponseData.accommodation_description,
                chipNumber: animalResponseData.chipnumber,
                premiumAnimal: animalResponseData.is_premium,
                sponsoredAnimal: animalResponseData.is_sponsored,
                hiddenOnWebsite: animalResponseData.is_hidden_on_website,
                noGivingAway: animalResponseData.no_giving_away,
                noGivingAwayReason: animalResponseData.no_giving_away_reason,
                name: animalResponseData.name,
                birthday: animalResponseData.birthday,
                species: animalResponseData.species,
                race: animalResponseData.race,
                gender: animalResponseData.gender,
                castrated: animalResponseData.castration_status,
                careSpace: animalResponseData.carespace ? CareSpaceConverter.toCareSpaceModel(animalResponseData.carespace) : null,
                shelter: animalResponseData.shelter_status,
                properties: animalResponseData.properties,
                specialCriteria: animalResponseData.special_criteria,
                characteristics: animalResponseData.characteristics,
                type: ActionTypeConverter.toActionType(animalResponseData.action_type),
                reserved: animalResponseData.is_reserved,
                reservedFor: animalResponseData.reserved_for,
                reservedUntil: animalResponseData.reserved_until,
                description: animalResponseData.description,
                synchronizeExternalDatabase: animalResponseData.should_synchronize_fundtier_database,
                fundtierDBRemark: animalResponseData.fundtier_database_remark,
                fundtierId: animalResponseData.fundtier_id ? +animalResponseData.fundtier_id : null,
                sponsorLogo: animalResponseData.sponsor_logo,
                sponsorWebsite: animalResponseData.sponsor_website,
                sponsorText: animalResponseData.sponsor_text,
                sponsorName: animalResponseData.sponsor_name,
                lastMissingCheck: animalResponseData.last_missing_check,
                transactionHistory: animalResponseData.transactions.map(a => AnimalActionConverter.toTransactionEntryModel(a))
            };
        }
    }
}
