import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {SnackbarComponent} from './snackbar.component';
import {Tuple, TupleHelper} from '../../data-types/tuple';
import {SnackStatusType} from './snack-status-type';
import {CRUD} from '../../services/http/crud';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    public opened: Subject<boolean> = new Subject<boolean>();

    constructor(private snackBar: MatSnackBar) {
    }

    public displaySnackbar(status: SnackStatusType, message, durationInSeconds = 6): void {
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: durationInSeconds * 1000,
            horizontalPosition: 'center',
            panelClass: [status.toString(), 'not-printable'],
            data: {
                status,
                message
            }
        });
    }

    public displaySnackbarWithCrud(operation: CRUD, status: SnackStatusType, fragments: Tuple[]): MatSnackBarRef<SnackbarComponent> {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            horizontalPosition: 'center',
            panelClass: [status.toString(), 'not-printable'],
            data: {
                status,
                message: SnackbarService.genereateCrudMessage(operation, fragments)
            }
        });
    }

    private static genereateCrudMessage(operation: CRUD, fragments: Tuple[]): string {
        switch (operation) {
            case CRUD.CREATE:
                return TupleHelper.getValue(fragments, 'identifier') + ' wurde erfolgreich gespeichert.';
            case CRUD.UPDATE:
                return TupleHelper.getValue(fragments, 'identifier') + ' wurde erfolgreich aktualisiert.';
            case CRUD.DELETE:
                return TupleHelper.getValue(fragments, 'identifier') + ' wurde erfolgreich gelöscht.';
        }
    }
}
