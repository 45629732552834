import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {Router} from '@angular/router';
import {EndpointService} from '../../shared/services/http/endpoint.service';
import {handleError} from './auth-error';
import * as AuthActions from './auth.actions';
import {UserEntryModel} from '../../shared/models/user-entry.model';

@Injectable()
export class AuthEffects {
    @Effect()
    readonly authLogin = this.actions$.pipe(
        ofType(AuthActions.LOGIN_START),
        switchMap((authData: AuthActions.LoginStart) => {
            return this.http.post<LoginResponseData>(this.endpointService.login(), {
                email: authData.payload.emailOrUsername,
                password: authData.payload.password,
                remember: authData.payload.rememberMe
            }).pipe(
                map(() => {
                    return new AuthActions.GetLoggedInUser();
                }),
                catchError(error => {
                    return handleError(error);
                })
            );
        })
    );

    @Effect()
    readonly getLoggedInUser = this.actions$.pipe(
        ofType(AuthActions.GET_LOGGED_IN_USER),
        switchMap(() => {
                return this.http.get<CurrentUserResponseData>(this.endpointService.currentUser()).pipe(
                    map(resData => {
                        const currentUser: UserEntryModel = {
                            id: resData.data.id,
                            email: resData.data.email,
                            firstName: resData.data.first_name,
                            lastName: resData.data.last_name,
                            shelter: resData.data.shelter,
                            shelterId: resData.data.shelter_id,
                            password: '',
                            isAdmin: resData.data.is_admin
                        };
                        return new AuthActions.SetUser(currentUser);
                    }),
                    catchError(error => {
                        return handleError(error);
                    })
                );
            }
        )
    );

    @Effect({dispatch: false})
    authLogout = this.actions$.pipe(
        ofType(AuthActions.LOGOUT),
        switchMap(() => {
                return this.http.post<any>(this.endpointService.logout(), {}).pipe(
                    tap(() => this.router.navigate(['/auth'])),
                    catchError(error => {
                        return handleError(error);
                    })
                );
            }
        )
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private router: Router,
                private endpointService: EndpointService
    ) {
    }
}
