<div class="wrapper">
    <app-navbar></app-navbar>
    <div id="content-wrapper">
        <div class="sidebar not-printable" data-color="danger" data-background-color="white">
            <app-sidebar></app-sidebar>
        </div>
        <div class="main-panel">
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>
