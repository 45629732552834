import {Component, OnInit} from '@angular/core';
import {PrintingDataAnimal, PrintingTemplate} from '../../TemplateTypes';
import {ActionSpecificPrintingComponent} from '../action-specific-printing-component';
import {AnimalEntryModel} from '../../../../shared/models/animal/animal-entry.model';
import {PersonEntryModel} from '../../../../shared/models/person-entry.model';
import {Age, CastrationStatus, Constants, Gender, Shelter} from '../../../../shared/constants';
import {CharacteristicService} from '../../../../animals/store/characteristics/characteristic.service';

@Component({
    selector: 'app-abnahme-printing',
    templateUrl: './abnahme-printing.component.html',
    styleUrls: ['./abnahme-printing.component.scss']
})
export class AbnahmePrintingComponent implements ActionSpecificPrintingComponent, OnInit {
    public hideHeader = false;
    public printingTemplate = PrintingTemplate.ActionSpecific;
    public visible = false;
    public data: PrintingDataAnimal;
    public animals: AnimalEntryModel[];
    public person: PersonEntryModel;
    public shelter: Shelter;
    public Shelter = Shelter;
    public Constants = Constants;
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public Age = Age;

    public cityName = '';

    constructor(private characteristicsService: CharacteristicService) {
    }

    ngOnInit(): void {
        if (this.shelter === Shelter.LINZ) {
            this.cityName = Constants.LinzCityName;
        } else {
            this.cityName = Constants.SteyrCityName;
        }
    }
}
