import {Action} from '@ngrx/store';
import {VeterinaryTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/veterinary-treatment-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_VETERINARY_TREATMENTS = '[VeterinaryTreatments] Load VeterinaryTreatments';
export const SET_VETERINARY_TREATMENTS = '[VeterinaryTreatments] Set VeterinaryTreatments';
export const ADD_VETERINARY_TREATMENT = '[VeterinaryTreatments] Add VeterinaryTreatment';
export const UPDATE_VETERINARY_TREATMENT = '[VeterinaryTreatments] Update VeterinaryTreatment';
export const DELETE_VETERINARY_TREATMENT = '[VeterinaryTreatments] Delete VeterinaryTreatment';
export const HTTP_FAIL = '[VeterinaryTreatments] Http Fail';

export class LoadVeterinaryTreatments implements Action {
    readonly type = LOAD_VETERINARY_TREATMENTS;

    constructor(public payload: { crud: CRUD, size: number, page: number, order: Order, column: string, filter?: any }) {
    }
}

export class SetVeterinaryTreatments implements Action {
    readonly type = SET_VETERINARY_TREATMENTS;

    constructor(public payload: { treatments: VeterinaryTreatmentEntryModel[], crud: CRUD, totalElements: number, lastPage: number }) {
    }
}

export class AddVeterinaryTreatment implements Action {
    readonly type = ADD_VETERINARY_TREATMENT;

    constructor(public payload: { treatment: VeterinaryTreatmentEntryModel, size: number, page: number, order: Order, column: string }) {
    }
}

export class UpdateVeterinaryTreatment implements Action {
    readonly type = UPDATE_VETERINARY_TREATMENT;

    constructor(public payload: { treatment: VeterinaryTreatmentEntryModel, size: number, page: number, order: Order, column: string }) {
    }
}

export class DeleteVeterinaryTreatment implements Action {
    readonly type = DELETE_VETERINARY_TREATMENT;

    constructor(public payload: { treatmentId: number, size: number, page: number, order: Order, column: string }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type VeterinaryTreatmentsActions =
    LoadVeterinaryTreatments
    | SetVeterinaryTreatments
    | AddVeterinaryTreatment
    | UpdateVeterinaryTreatment
    | DeleteVeterinaryTreatment
    | HttpFail;
