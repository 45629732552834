import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as FDRacesActions from './fd-race.actions';

export interface State {
    fdRaces: DictionaryEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    fdRaces: [],
    operation: CRUD.NONE
};

export function racesReducer(state = initialState, action: FDRacesActions.FDRacesActions): State {
    switch (action.type) {
        case FDRacesActions.SET_FD_RACES:
            return {
                ...state,
                fdRaces: [...action.payload.fdRaces],
                operation: action.payload.operation
            };
        case FDRacesActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
