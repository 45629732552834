<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1 *ngIf="isIncomeComponent">Eingangsbestätigung</h1>
    <h1 *ngIf="!isIncomeComponent">Ausgangsbestätigung</h1>
    <span class="font-weight-bolder">OÖ Landestierschutzverein <br />
        {{shelterName}} <br />
        {{address}}, {{phone}}, {{zipAndCity}} <br />
        {{data.date | date:'dd.MM.yyyy HH:mm:ss'}}
        </span>

    <section class="mt-5">
        <div class="container entry-container">
            <div class="row justify-content-between" *ngFor="let entry of data.bookingEntries">
                <div class="col-4 text-left">{{entry.article}}</div>
                <div class="col-4">{{entry.description}} {{ '-' + entry.remark === '' ? '' : entry.remark}}</div>
                <div class="col-4 text-right">{{entry.price |  currency:'EUR':'symbol':'1.2-2'}}</div>
            </div>
            <div class="row result">
                <div class="col-5"></div>
                <div class="col-5 text-right">Summe</div>
                <div class="col-2 text-right">{{sum |  currency:'EUR':'symbol':'1.2-2'}}</div>
            </div>
            <div class="row" *ngIf="cash > 0">
                <div class="col-4 text-left">Bar</div>
                <div class="col-8 text-right">{{cash | currency:'EUR':'symbol':'1.2-2'}}</div>
            </div>
            <div class="row" *ngIf="atm > 0">
                <div class="col-4 text-left">Bankomat</div>
                <div class="col-8 text-right">{{atm | currency:'EUR':'symbol':'1.2-2'}}</div>
            </div>
        </div>
    </section>

    <section class="text-left mt-5">
        Es bediente Sie: {{user.firstName + ' ' + user.lastName}}
    </section>

    <section class="font-weight-bolder">Wir danken für Ihren Beitrag!</section>
</div>
