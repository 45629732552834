import {MatPaginatorIntl} from '@angular/material/paginator';

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return '0 von 0';
    }

    const max = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < max ?
        Math.min(startIndex + pageSize, max) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} von ${max}`;
};


export function getGermanPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.nextPageLabel = '';
    paginatorIntl.previousPageLabel = '';
    paginatorIntl.firstPageLabel = '';
    paginatorIntl.lastPageLabel = '';
    paginatorIntl.getRangeLabel = dutchRangeLabel;

    return paginatorIntl;
}
