import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../../shared/controls/date-time-picker/DateTimeConfig';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-annahme-pensionstier',
    templateUrl: './annahme-pensionstier.component.html',
    styleUrls: ['./annahme-pensionstier.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class AnnahmePensionstierComponent implements OnInit {
    @Input() public acquisitionGiveAwayForm: FormGroup;
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('datePickerTo') public datePickerTo: any;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();

    ngOnInit(): void {
        this.acquisitionGiveAwayForm.addControl('stayFrom', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('stayUntil', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('deposit', new FormControl('', Validators.pattern(/^[0-9]+(,[0-9]{1,2})?$/)));
    }

    public dateChanged(): void {
        this.acquisitionGiveAwayForm.controls.stayFrom.setErrors(null);
        this.acquisitionGiveAwayForm.controls.stayUntil.setErrors(null);
    }
}
