<ng-container [formGroup]="acquisitionGiveAwayForm">
    <div class="row mt-2">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Abgabegrund</mat-label>
                <textarea matInput
                          cdkTextareaAutosize
                          formControlName="giveAwayReason"
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
        </div>
    </div>
</ng-container>
