import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../shared/services/http/endpoint.service';
import {CRUD} from '../../shared/services/http/crud';
import {SupervisionEntryModel} from '../../shared/models/supervision-entry.model';
import * as fromSupervisionActions from './supervision.actions';
import {ActionConverter} from '../../shared/converter/action-converter';
import {LoggingService} from '../../shared/logging/logging.service';
import {handleHTTPError} from '../../shared/error-handling';

@Injectable()
export class SupervisionEffects {
    @Effect()
    loadSupervisions$ = this.actions$.pipe(
        ofType(fromSupervisionActions.LOAD_SUPERVISIONS),
        switchMap((supervisionState: fromSupervisionActions.LoadSupervisions) => {
            const page = supervisionState.payload.page;

            let params = new HttpParams();
            if (page >= 0) {
                params = params.append('page', page.toString());
            }

            params = params.append('order', '-date_performed');

            const animalId = supervisionState.payload.animalId;
            if (supervisionState.payload.animalId >= 0) {
                params = params.append('filter', 'true');
                params = params.append('animal_id', animalId.toString());
            }

            return this.endpointService.supervisions(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params});
                }),
                map(response => {
                    const totalElements = response.data.pagination.totalElements;
                    const lastPage = response.data.pagination.pages;
                    const values = response.data.data;
                    const mappedSupervisionArr: SupervisionEntryModel[] = values.map(supervision => {
                        return ActionConverter.toSupervisionEntryModel(supervision);
                    });

                    return new fromSupervisionActions.SetSupervisions({
                        supervisions: mappedSupervisionArr,
                        totalElements,
                        crud: supervisionState.payload.crud,
                        lastPage
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addSupervision$ = this.actions$.pipe(
        ofType(fromSupervisionActions.ADD_SUPERVISION),
        switchMap((supervisionState: fromSupervisionActions.AddSupervision) => {
            return this.endpointService.supervisions(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                animal_id: supervisionState.payload.supervision.animal.id,
                                date_performed: supervisionState.payload.supervision.datePerformed,
                                report: supervisionState.payload.supervision.report,
                                supervisor: supervisionState.payload.supervision.supervisor
                            }
                        ).pipe(
                            map(() => {
                                return new fromSupervisionActions.LoadSupervisions({
                                    crud: CRUD.CREATE,
                                    page: 1,
                                    animalId: supervisionState.payload.supervision.animal.id
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteSupervision$ = this.actions$.pipe(
        ofType(fromSupervisionActions.DELETE_SUPERVISION),
        switchMap((supervisionState: fromSupervisionActions.DeleteSupervision) => {
            return this.endpointService.supervisions(HttpMethod.DELETE, supervisionState.payload.supervisionId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromSupervisionActions.LoadSupervisions({
                                    crud: CRUD.DELETE,
                                    page: supervisionState.payload.page,
                                    animalId: supervisionState.payload.animalId
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {
    }

    private handleError = (errorRes: any): Observable<fromSupervisionActions.HttpFail> => {
        return handleHTTPError(errorRes, fromSupervisionActions, 'Kontrollen', this.loggingService);
    };
}
