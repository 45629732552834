import {Component, OnInit, ViewChild} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../../shared/services/http/crud';
import {DataTable} from '../../shared/data-types/data-table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Order} from '../../shared/controls/data-table/ordering';
import {Subscription} from 'rxjs';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import {UserService} from '../user-service.service';
import * as fromUsersActions from '../store/manage-users/users.actions';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {DialogType} from '../../dialogs/dialog-type';
import {MatDialog} from '@angular/material/dialog';
import {CreateUserDialogComponent} from '../create-user-dialog/create-user-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class ManageUsersComponent implements OnInit {
    @ViewChild('paginator') public paginator: MatPaginator;
    public displayedColumns: string[] = [
        'id',
        'email',
        'firstName',
        'lastName',
        'controls'
    ];
    public expandedElement: UserEntryModel | null;
    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'Benutzer Nr.',
            className: 'id'
        }, {
            key: 'email',
            header: 'E-Mail',
            className: 'email'
        }, {
            key: 'firstName',
            header: 'Vorname',
            className: 'invoiceText'
        }, {
            key: 'lastName',
            header: 'Nachname',
            className: 'Nachname'
        }
    ];
    public users: UserEntryModel[];
    public currentUser: UserEntryModel;
    public loading?: boolean = null;
    public totalUsers = 0;
    public displayErroneousInputs = false;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public lastPage = 1;
    public order = Order.NONE;
    public orderedColumn = '';

    private usersSub: Subscription;
    private userIdentifier: string;
    private simpleDialogType = new DialogType();

    constructor(protected formBuilder: FormBuilder,
                protected actions$: Actions,
                protected userService: UserService,
                private dialog: MatDialog,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.initEvents();
        this.initErrorHandling();
    }

    public canExpand($event: MouseEvent, element: any, expandedElement: UserEntryModel): any {
        return DataTable.canExpand($event, element, expandedElement);
    }

    public changeEntriesPerPage($event: any): void {
        this.entriesPerPage = $event;
        this.userService
            .fetchUsers(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn)
            .subscribe(users => {
                this.users = users;
            });

        this.paginator.firstPage();
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.userService
            .fetchUsers(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn)
            .subscribe(users => {
                this.users = users;
            });
    }

    public deleteUser(element: any): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Benutzer',
                identifier: element.firstName + ' ' + element.lastName,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.userService
                    .deleteUser(element.id, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
                this.userIdentifier = element.firstName + ' ' + element.lastName;
            }
        });
    }

    public openDialog(): void {
        this.dialog.open(CreateUserDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                lastPage: this.lastPage,
                entriesPerPage: this.entriesPerPage,
                order: this.order,
                column: this.orderedColumn,
                currentUser: this.currentUser
            }
        });
    }

    private initUsersTable(currentUser: UserEntryModel): void {
        this.loading = true;
        this.usersSub = this.userService.fetchUsers(this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn)
            .subscribe();

        this.actions$.pipe(
            ofType(fromUsersActions.SET_USERS)
        ).subscribe((usersState: fromUsersActions.SetUsers) => {
            const crudOperation = usersState.payload.crud;
            this.lastPage = usersState.payload.lastPage;
            this.users = usersState.payload.users.filter(u => u.id !== currentUser.id);
            this.totalUsers = this.users.length;
            this.loading = false;

            if (crudOperation === CRUD.READ ||
                crudOperation === CRUD.NONE) {
                return;
            }

            if (crudOperation === CRUD.CREATE) {
                this.dialog.closeAll();
                this.paginator.length = this.totalUsers;
                this.paginator.lastPage();
            }

            this.userService.handleRequestSuccess(crudOperation, 'Benutzer', this.userIdentifier);
        });
    }

    private initEvents(): void {
        this.userService.crudFinished.subscribe(payload => {
            this.userIdentifier = payload;
        });

        this.authService.loggedInUser.subscribe(currentUser => {
            this.currentUser = currentUser;
            this.initUsersTable(currentUser);
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromUsersActions.HTTP_FAIL)
        ).subscribe((httpFail: fromUsersActions.HttpFail) => {
            this.userService.handleRequestError(httpFail.payload.message);
            this.loading = false;
        });
    }
}
