import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {SelectBoxService} from '../../../shared/controls/select-box/select-box.service';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import * as fromApp from '../../../store/app.reducer';
import * as fromFDRacesActions from '../../../animals/store/fd-races/fd-race.actions';
import {LoadingService} from '../../../shared/services/loading/loading.service';
import {TupleHelper} from '../../../shared/data-types/tuple';

@Injectable({providedIn: 'root'})
export class FdRaceService implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private loadingService: LoadingService,
                private snackbarService: SnackbarService,
                private selectBoxService: SelectBoxService) {
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public fetchFDRaces(filter?: any): Observable<DropdownOption[]> {
        return this.store.select('fdRaceList').pipe(
            map(fdRaceState => {
                return fdRaceState.fdRaces;
            }),
            switchMap(races => {
                if (races.length === 0) {
                    this.store.dispatch(new fromFDRacesActions.LoadFDRaces({crud: CRUD.READ, filter}));
                    return this.actions$.pipe(
                        ofType(fromFDRacesActions.SET_FD_RACES),
                        map((raceState: fromFDRacesActions.SetFDRaces) => {
                            return raceState.payload;
                        })
                    );
                } else {
                    return of(races);
                }
            }),
            map((races: DictionaryEntryModel[]) => {
                return races
                    .map((element: DictionaryEntryModel) => {
                        return {
                            value: element.id.toString(),
                            viewValue: element.name,
                            meta: element.meta
                        } as DropdownOption;
                    })
                    .sort(this.selectBoxService.sortCallback);
            }),
            takeUntil(this.destroy$)
        );
    }

    public addFDRace(race: DictionaryEntryModel, fdRace: DropdownOption): void {

        this.store.dispatch(new fromFDRacesActions.AddFDRace({
            mappingId: fdRace.value,
            speciesGroupName: TupleHelper.getValue(fdRace.meta, 'species'),
            raceId: race.id
        }));
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
