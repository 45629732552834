<div id="animals-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Laufnummer</mat-label>
                    <input matInput
                           id="animalId"
                           placeholder="Laufnummer"
                           aria-label="Laufnummer"
                           formControlName="id"
                           maxlength="7"
                           [matAutocomplete]="animalId">
                    <mat-autocomplete #animalId="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredAnimalIds$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Fundtier ID</mat-label>
                    <input matInput
                           id="fundtierId"
                           placeholder="Fundtier ID"
                           aria-label="Fundtier ID"
                           formControlName="fundtierId"
                           maxlength="10">
                </mat-form-field>
            </div>
        </div>

        <div class="row" [hidden]="!isInformationPage">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Tiername</mat-label>
                    <input matInput
                           placeholder="Tiername"
                           formControlName="name"
                           aria-label="Tiername"
                           [matAutocomplete]="animalName">
                    <mat-autocomplete #animalName="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredAnimalNames$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row" [hidden]="!isInformationPage">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerBirthday.open()"
                           [ngxMatDatetimePicker]="datePickerBirthday"
                           placeholder="Geburtsdatum"
                           formControlName="birthday"
                           aria-label="Geburtsdatum"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerBirthday"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerBirthday
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row" [hidden]="!isInformationPage">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Chipnummer</mat-label>
                    <input matInput
                           placeholder="Chipnummer"
                           formControlName="chipNumber"
                           aria-label="Chipnummer"
                           [matAutocomplete]="chipNumber">
                    <mat-autocomplete #chipNumber="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredChipNumbers$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label *ngIf="!isInformationPage">Aktionstyp</mat-label>
                    <mat-label *ngIf="isInformationPage">Vergangener Aktionstyp</mat-label>
                    <mat-select #actionTypeSelect
                                (selectionChange)="selChange()"
                                (keydown.enter)="filter()"
                                formControlName="actionType">
                        <mat-option *ngFor="let action of actionTypes"
                                    [value]="action"
                                    [attr.symbol]="TupleHelper.getValue(action.meta, 'symbol')">
                            {{action.viewValue}}
                            <div class="animal-type fas"
                                 [style]="{'background-color': TupleHelper.getValue(action.meta, 'color')}"
                                 [ngClass]="TupleHelper.getValue(action.meta, 'symbol')"></div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Tierart</mat-label>
                    <mat-select #singleSelectSpecies
                                formControlName="species"
                                (ngModelChange)="selectSpecies($event)"
                                (keydown.enter)="filter()">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchSpecies.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                [value]="species">
                            {{species.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Tierrasse</mat-label>
                    <mat-select #singleSelectRace
                                formControlName="race"
                                (keydown.enter)="filter()">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchRace.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let race of selectSearchRace.filteredElements | async"
                                [value]="race">
                            {{race.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Heim</mat-label>
                    <mat-select formControlName="shelter"
                                (keydown.enter)="filter()">
                        <mat-option *ngFor="let shelter of shelterStatusOptions"
                                    [value]="shelter">
                            {{shelter.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Geschlecht</mat-label>
                    <mat-select formControlName="gender"
                                (keydown.enter)="filter()">
                        <mat-option *ngFor="let gender of genderOptions"
                                    [value]="gender.value">
                            {{gender.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Kastration</mat-label>
                    <mat-select formControlName="castration"
                                (keydown.enter)="filter()">
                        <mat-option *ngFor="let castration of castrationStatusOptions"
                                    [value]="castration.value">
                            {{castration.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" [hidden]="!isInformationPage">
            <div class="col-md-6">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerFrom.open()"
                           [ngxMatDatetimePicker]="datePickerFrom"
                           placeholder="Von"
                           formControlName="from"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerFrom"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerFrom
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerTo.open()"
                           [ngxMatDatetimePicker]="datePickerTo"
                           placeholder="Bis"
                           formControlName="to"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerTo"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerTo
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-checkbox formControlName="includeCareSpaceAnimals">
                    Tiere "Auf Pflegeplatz" inkludieren
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-checkbox
                        formControlName="includeDeadAnimals">Tiere im Himmel inkludieren
                </mat-checkbox>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col text-right">
                <button mat-raised-button
                        class="mt-4 float-right"
                        color="primary">
                    Suchen
                </button>
            </div>

            <div class="col">
                <button mat-raised-button
                        class="mt-4 float-left"
                        color="warn"
                        (click)="reset()">
                    Zurücksetzen
                </button>
            </div>
        </div>
    </form>
</div>
