import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as fromCharacteristics from './characteristic.actions';

export interface State {
    characteristics: DictionaryEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    characteristics: [],
    operation: CRUD.NONE
};

export function characteristicReducer(state = initialState, action: fromCharacteristics.CharacteristicsActions): State {
    switch (action.type) {
        case fromCharacteristics.SET_CHARACTERISTICS:
            return {
                ...state,
                characteristics: [...action.payload.characteristics],
                operation: action.payload.operation
            };
        case fromCharacteristics.ADD_CHARACTERISTIC:
            return {
                ...state,
                characteristics: [...state.characteristics, action.payload.newModel]
            };
        case fromCharacteristics.UPDATE_CHARACTERISTIC:
            const updatedCharacteristic = {
                ...state.characteristics.find(characteristic => characteristic.id === action.payload.updatedModel.id),
                ...action.payload
            };

            const updatedCharacteristics = [...state.characteristics];
            const arrIndex = updatedCharacteristics.findIndex(element => element.id === updatedCharacteristic.id);
            updatedCharacteristics[arrIndex] = updatedCharacteristic;

            return {
                ...state,
                characteristics: updatedCharacteristics
            };
        case fromCharacteristics.DELETE_CHARACTERISTIC:
            return {
                ...state,
                characteristics: state.characteristics.filter(characteristic => characteristic.id !== action.payload.id)
            };
        case fromCharacteristics.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
