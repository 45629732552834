import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {CUSTOM_DATE_FORMATS} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {ActivatedRoute} from '@angular/router';
import {MatDatetimePickerInputEvent, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {PosSystemService} from '../pos-system.service';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {ArticleEntryModel} from '../../shared/models/pos/article-entry.model';
import {Subscription} from 'rxjs';
import {BookingEntryFormComponent} from './booking-entry-form/booking-entry-form.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BookingService} from '../booking.service';
import {BookingEntryModel} from '../../shared/models/pos/booking-entry.model';
import {combineLatest} from 'rxjs/operators';
import {Constants} from '../../shared/constants';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as fromBookingActions from '../store/booking/booking.actions';
import {NumberConverter} from '../../shared/converter/number-converter';
import {PrintingIncomeOutcome, PrintingTemplate} from '../../printing/templates/TemplateTypes';
import {PrintingService} from '../../printing/printing.service';
import {CRUD} from '../../shared/services/http/crud';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-income',
    templateUrl: './income-outcome.component.html',
    styleUrls: ['./income-outcome.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
    ]
})
export class IncomeOutcomeComponent implements OnInit, OnDestroy {
    @ViewChildren('entry') public entry: any;
    @ViewChild('articleEntryContainer', {read: ViewContainerRef}) public articleEntryContainer: ViewContainerRef;

    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public classes = ['id', 'category', 'article', 'price', 'tax', 'six'];
    // Template related
    public isIncomeComponent = true;
    public headline = 'Eingang';
    public cardTitle = 'Eingangsrechnung anlegen';
    public moneyReceivedForm: FormGroup;
    public sum = 0;
    public change = 0;
    public addRequestSent = false;
    public displayErroneousInputs = false;
    public donate = false;
    public validEntryForm = false;
    public entryComponentCollection = new Array<ComponentRef<BookingEntryFormComponent>>();
    public selectedDate: Moment;
    public dateForm: FormGroup;
    public PrintingTemplate = PrintingTemplate;

    private articles: ArticleEntryModel[];
    private articleSub: Subscription;
    private userSub: Subscription;
    private currentUser: UserEntryModel;

    constructor(private posSystemService: PosSystemService,
                private bookingService: BookingService,
                private formBuilder: FormBuilder,
                private store: Store,
                private actions$: Actions,
                private route: ActivatedRoute,
                private authService: AuthService,
                private printingService: PrintingService,
                private componentFactoryResolver: ComponentFactoryResolver) {

        route.url.subscribe(() => {
            this.isIncomeComponent = route.routeConfig.path === 'income';

            if (this.isIncomeComponent) {
                this.headline = 'Eingang';
                this.cardTitle = 'Eingangsrechnung anlegen';
            } else {
                this.headline = 'Ausgang';
                this.cardTitle = 'Ausgangsrechnung anlegen';
            }
        });
    }

    ngOnInit(): void {
        this.initBookings();
        this.initCurrentUser();
        this.initForm();
        this.initErrorHandling();
    }

    ngOnDestroy(): void {
        this.articleSub.unsubscribe();
        this.userSub.unsubscribe();
    }

    public addBookingEntry(isDonation = false): void {
        const resolver = this.componentFactoryResolver.resolveComponentFactory(BookingEntryFormComponent);
        const newArticleComponent = this.generateComponent(resolver);

        if (isDonation) {
            newArticleComponent.instance.articleEntryForm.patchValue({
                searchArticle: Constants.Donation,
                newDescription: Constants.Donation,
                newPrice: NumberConverter.toGermanNumberFormat(this.change)
            });
            newArticleComponent.instance.price = +NumberConverter
                .toEnglishNumberFormat(newArticleComponent.instance.articleEntryForm.value.newPrice);
            newArticleComponent.instance.selectedArticle = Constants.Donation;
            newArticleComponent.instance.correspondingCategory = Constants.Donation;
            newArticleComponent.instance.displayRemarkInput = true;
        }
        this.entryComponentCollection.push(newArticleComponent);
    }

    public moneyReceivedChanged(): void {
        if (this.moneyReceivedForm.valid) {
            this.change = +NumberConverter.toEnglishNumberFormat(this.moneyReceivedForm.value.moneyReceived.toString()) - this.sum;
        } else {
            this.change = 0;
        }
    }

    public submitForm(): void {
        this.addRequestSent = true;
        this.validEntryForm = true;

        for (const entryComponentRef of this.entryComponentCollection) {
            const entryComp = entryComponentRef.instance;
            if (!entryComp.articleEntryForm.valid) {
                this.validEntryForm = false;
                this.displayErroneousInputs = true;
            }
        }

        if (!this.validEntryForm || this.entryComponentCollection.length === 0) {
            this.addRequestSent = false;
            return;
        }

        let cashier = '';
        if (this.currentUser) {
            cashier = this.currentUser.firstName + ' ' + this.currentUser.lastName;
        }

        const entries: BookingEntryModel[] = [];
        const date = moment(this.selectedDate, 'DD.MM.YYYY HH:mm:ss', 'de').format('YYYY-MM-DD HH:mm:ss');
        for (const entryComponent of this.entryComponentCollection) {
            const comp = entryComponent.instance;
            const newBookingEntry: BookingEntryModel = {
                dateCreated: date,
                type: this.isIncomeComponent ? Constants.Income : Constants.Outcome,
                article: comp.selectedArticle,
                category: comp.correspondingCategory,
                price: comp.price,
                description: comp.articleEntryForm.value.newDescription,
                remark: comp.articleEntryForm.value.newRemark == null ? '' : comp.articleEntryForm.value.newRemark,
                isATM: comp.articleEntryForm.value.newATM !== false && comp.articleEntryForm.value.newATM !== '',
                cashier
            };
            entries.push(newBookingEntry);
        }

        this.bookingService.addBookings(entries, date, date, 1, -1);

        const data: PrintingIncomeOutcome = {
            date,
            bookingEntries: entries
        };
        this.printingService.dataFetched.next(data);
    }

    public insertDonationEntry(): void {
        this.donate = true;
        this.addBookingEntry(true);
        this.change = 0;
    }

    public payWithATM(): void {
        for (const entryComponent of this.entryComponentCollection) {
            const comp = entryComponent.instance;
            comp.articleEntryForm.patchValue({
                newATM: 'checked'
            });
        }
    }

    public dateChange($event: MatDatetimePickerInputEvent<moment.Moment & any>): void {
        this.selectedDate = $event.value;
    }

    private initBookings(): void {
        // Load all articles from server
        this.articleSub = this.posSystemService.fetchArticles(100000, 1).subscribe(articles => {
            this.articles = articles;
        });

        // noinspection JSDeprecatedSymbols
        this.actions$.pipe(
            ofType(fromBookingActions.SET_BOOKINGS),
            combineLatest(this.printingService.finishedPrinting)
        ).subscribe(([bookingState, finishedPrinting]: [fromBookingActions.SetBookings, boolean]) => {
            if (bookingState.payload.operation === CRUD.CREATE && finishedPrinting) {
                this.posSystemService.handleRequestSuccess(
                    CRUD.CREATE,
                    this.isIncomeComponent ? 'Eingangsrechnung' : 'Ausgangsrechnung',
                    '');
                this.displayErroneousInputs = false;
                this.printingService.finishedPrinting.next(false);
                this.dateForm.reset();
                this.moneyReceivedForm.reset();
                this.entryComponentCollection = [];
                this.articleEntryContainer.clear();
                this.sum = 0;
                this.change = 0;
            }

            this.validEntryForm = false;
        });
    }

    private initForm(): void {
        this.moneyReceivedForm = new FormGroup({
            moneyReceived: new FormControl('0,00', [Validators.required, Validators.pattern(/^[-+]?[0-9]+(,[0-9]{1,2})?$/)])
        });

        this.selectedDate = moment();
        this.dateForm = this.formBuilder.group({
            dateAt: [this.selectedDate, Validators.required]
        });
    }

    private sumPrices(): void {
        this.sum = 0;
        for (const entryComponent of this.entryComponentCollection) {
            this.sum += +entryComponent.instance.price;
        }

        if (this.moneyReceivedForm.valid) {
            const received = +NumberConverter.toEnglishNumberFormat(this.moneyReceivedForm.value.moneyReceived.toLocaleString());
            if (!isNaN(received)) {
                this.change = received - this.sum;
            }
        }
    }

    private generateComponent(resolver: ComponentFactory<BookingEntryFormComponent>): ComponentRef<BookingEntryFormComponent> {
        const newArticleComponent = this.articleEntryContainer.createComponent(resolver);
        newArticleComponent.instance.index = this.entryComponentCollection.length;
        newArticleComponent.instance.isIncomeComponent = this.isIncomeComponent;
        newArticleComponent.instance.priceChangedEvent.subscribe(() => {
            this.sumPrices();
        });
        newArticleComponent.instance.removeEntryChangedEvent.subscribe(index => {
            if (!this.entryComponentCollection || this.entryComponentCollection.length === 0) {
                return;
            }

            if (this.entryComponentCollection[index].instance.selectedArticle === Constants.Donation) {
                this.donate = false;
            }

            this.entryComponentCollection = this.entryComponentCollection.filter(entry => entry.instance.index !== index);
            this.entryComponentCollection.forEach((entry, i) => {
                entry.instance.index = i;
            });
            this.articleEntryContainer.remove(index);
            this.sumPrices();
        });
        newArticleComponent.changeDetectorRef.detectChanges();
        return newArticleComponent;
    }

    private initCurrentUser(): void {
        this.userSub = this.authService.loggedInUser.subscribe(currentUser => {
            this.currentUser = currentUser;
        });
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromBookingActions.HTTP_FAIL)
        ).subscribe((httpFail: fromBookingActions.HttpFail) => {
            this.posSystemService.handleRequestError(httpFail.payload.message);
            this.addRequestSent = false;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }
}
