import {CRUD} from '../../../shared/services/http/crud';
import * as fromFormerTreatmentsActions from './former-treatments.actions';
import {FormerTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/former-veterinary-treatment-entry.model';

export interface State {
    formerTreatments: FormerTreatmentEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    formerTreatments: [],
    operation: CRUD.NONE
};

export function formerTreatmentsReducer(state = initialState,
                                        action: fromFormerTreatmentsActions.FormerTreatmentsActions): State {
    switch (action.type) {
        case fromFormerTreatmentsActions.SET_FORMER_TREATMENTS:
            return {
                ...state,
                formerTreatments: [...action.payload.formerTreatments],
                operation: action.payload.crud
            };
        case fromFormerTreatmentsActions.ADD_FORMER_TREATMENT:
            return {
                ...state,
                formerTreatments: [...state.formerTreatments, action.payload.newFormerTreatment]
            };
        case fromFormerTreatmentsActions.UPDATE_FORMER_TREATMENT:
            const updatedFormerTreatment = {
                ...state.formerTreatments.find(formerTreatment => formerTreatment.id === action.payload.updatedFormerTreatment.id),
                ...action.payload
            };

            const updatedFormerTreatments = [...state.formerTreatments];
            const arrIndex = updatedFormerTreatments.findIndex(element => element.id === updatedFormerTreatment.id);
            updatedFormerTreatments[arrIndex] = updatedFormerTreatment;

            return {
                ...state,
                formerTreatments: updatedFormerTreatments
            };
        case fromFormerTreatmentsActions.DELETE_FORMER_TREATMENT:
            return {
                ...state,
                formerTreatments: state.formerTreatments
                    .filter(formerTreatment => formerTreatment.id !== action.payload.formerTreatmentId)
            };
        case fromFormerTreatmentsActions.HTTP_FAIL:
            return {
                ...state,
                operation: CRUD.NONE
            };
        default:
            return state;
    }
}
