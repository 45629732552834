<ng-container [formGroup]="acquisitionGiveAwayForm">
    <div class="row mt-4">
        <div class="col-md-4">
            <mat-form-field class="mat-datetime-picker">
                <input matInput
                       (dateChange)="dateChanged()"
                       (click)="datePickerFrom.open()"
                       [ngxMatDatetimePicker]="datePickerFrom"
                       formControlName="stayFrom"
                       placeholder="Aufenthalt von"
                       [min]="dateTimeConfig.minDate"
                       [max]="dateTimeConfig.maxDate">
                <mat-datepicker-toggle matSuffix
                                       [for]="datePickerFrom"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datePickerFrom
                                         [stepHour]="dateTimeConfig.stepHour"
                                         [stepMinute]="dateTimeConfig.stepMinute"
                                         [hideTime]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field class="mat-datetime-picker">
                <input matInput
                       (dateChange)="dateChanged()"
                       (click)="datePickerTo.open()"
                       [ngxMatDatetimePicker]="datePickerTo"
                       formControlName="stayUntil"
                       placeholder="Aufenthalt bis"
                       [min]="dateTimeConfig.minDate"
                       [max]="dateTimeConfig.maxDate">
                <mat-datepicker-toggle matSuffix
                                       [for]="datePickerTo"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #datePickerTo
                                         [stepHour]="dateTimeConfig.stepHour"
                                         [stepMinute]="dateTimeConfig.stepMinute"
                                         [hideTime]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Anzahlung in €</mat-label>
                <input matInput
                       formControlName="deposit"
                       placeholder="Anzahlung">
            </mat-form-field>
        </div>
    </div>
</ng-container>

