import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminLayoutRoutes} from './admin-layout.routing';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {ComponentsModule} from '../../components/components.module';
import {AnimalsComponent} from '../../animals/animals.component';
import {PersonsComponent} from '../../persons/persons.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {AnimalsFilterComponent} from '../../animals/animals-filter/animals-filter.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AnimalAddEditComponent} from '../../animals/animal-add-edit/animal-add-edit.component';
import {AnimalListComponent} from '../../animals/animal-list/animal-list.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {PosSystemComponent} from '../../pos-system/pos-system.component';
import {ActionsComponent} from '../../actions/actions.component';
import {AttributesComponent} from '../../attributes/attributes.component';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {QuicknavComponent} from '../../animals/quicknav/quicknav.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {QuickEditComponent} from '../../animals/animal-list/quick-edit/quick-edit.component';
import {AcquisitionAndGiveAwayComponent} from '../../animals/acquisition-and-give-away/acquisition-and-give-away.component';
import {PersonalizedActionComponent} from '../../animals/personalized-action/personalized-action.component';
import {MissingComponent} from '../../animals/missing/missing.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FindlingComponent} from '../../animals/action-type-specific/findling/findling.component';
import {VergabeComponent} from '../../animals/action-type-specific/vergabe/vergabe.component';
import {AnnahmePensionstierComponent} from '../../animals/action-type-specific/annahme-pensionstier/annahme-pensionstier.component';
import {MatRadioModule} from '@angular/material/radio';
import {RueckgabePensionstierComponent} from '../../animals/action-type-specific/rueckgabe-pensionstier/rueckgabe-pensionstier.component';
import {PersonsFilterComponent} from '../../persons/persons-filter/persons-filter.component';
import {AssociatedAnimalsComponent} from '../../persons/associated-animals/associated-animals.component';
import {AbgabeComponent} from '../../animals/action-type-specific/abgabe/abgabe.component';
import {AbnahmeComponent} from '../../animals/action-type-specific/abnahme/abnahme.component';
import {PersonDialogComponent} from '../../persons/person-dialog/person-dialog.component';
import {SupervisionComponent} from '../../supervision/supervision.component';
import {FormerSupervisionsComponent} from '../../supervision/former-supervisions/former-supervisions.component';
import {SupervisionFilterComponent} from '../../supervision/supervision-filter/supervision-filter.component';
import {MissingAnimalsFilterComponent} from '../../animals/missing/missing-animals-filter/missing-animals-filter.component';
import {IncomeOutcomeComponent} from '../../pos-system/income-outcome/income-outcome.component';
import {CancellationComponent} from '../../pos-system/cancellation/cancellation.component';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {ActionsFilterComponent} from '../../actions/actions-filter/actions-filter.component';
import {PersonQuickEditComponent} from '../../persons/person-quick-edit/person-quick-edit.component';
import {ArticleListComponent} from '../../pos-system/article-list/article-list.component';
// tslint:disable-next-line:max-line-length
import {VeterinaryTreatmentQuickEditComponent} from '../../veterinary-treatments/veterinary-treatment-quick-edit/veterinary-treatment-quick-edit.component';
import {CashbookComponent} from '../../pos-system/cashbook/cashbook.component';
import {FormerVeterinaryTreatmentsComponent} from '../../veterinary-treatments/former-veterinary-treatments/former-veterinary-treatments.component';
import {CareSpaceComponent} from '../../animals/care-space/care-space.component';
import {FormerCareSpacesComponent} from '../../animals/care-space/former-care-spaces/former-care-spaces.component';
import {ArticleQuickEditComponent} from '../../pos-system/article-list/article-quick-edit/article-quick-edit.component';
import {VeterinaryTreatmentsComponent} from '../../veterinary-treatments/veterinary-treatments.component';
import {FormerVeterinaryTreatmentsQuickEditComponent} from '../../veterinary-treatments/former-veterinary-treatments/former-veterinary-treatments-quick-edit/former-veterinary-treatments-quick-edit.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SelectBoxService} from '../../shared/controls/select-box/select-box.service';
import {PosSystemService} from '../../pos-system/pos-system.service';
import {RaceService} from '../../animals/store/races/race.service';
import {SpeciesService} from '../../animals/store/species/species.service';
import {AttributesOptionComponent} from '../../attributes/attributes-option/attributes-option.component';
import {AttributesService} from '../../attributes/attributes.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BookingService} from '../../pos-system/booking.service';
import {BookingEntryFormComponent} from '../../pos-system/income-outcome/booking-entry-form/booking-entry-form.component';
import {VeterinaryTreatmentsService} from '../../veterinary-treatments/veterinary-treatments.service';
import {PersonsService} from '../../persons/persons.service';
import {UserManagementModule} from '../../user-management/user-management.module';
import {SharedModule} from '../../shared/shared.module';
import {AnimalsService} from '../../animals/animals.service';
import {MediaCentreComponent} from '../../media-centre/media-centre.component';
import {AccommodationService} from '../../animals/store/accommodation/accommodation.service';
import {ActionTypesService} from '../../animals/store/action-types/action-types.service';
import {CharacteristicService} from '../../animals/store/characteristics/characteristic.service';
import {MediaThumbComponent} from '../../media-centre/media-thumb/media-thumb.component';
import {ImageFullScreenComponent} from '../../dialogs/image-full-screen/image-full-screen.component';
import {MediaService} from '../../media-centre/media.service';
import {ImageUploadComponent} from '../../shared/image-handling/upload/image-upload.component';
import {SafeHtmlPipe} from '../../shared/image-handling/safe-html.pipe';
import {EditProfileComponent} from '../../user-management/edit-profile/edit-profile.component';
import {MediaFilterComponent} from '../../media-centre/media-filter/media-filter.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {SupervisionService} from '../../supervision/supervision.service';
import {FormerSupervisionContentComponent} from '../../supervision/former-supervisions/former-supervision-content/former-supervision-content.component';
import {ActionsService} from '../../actions/actions.service';
import {ActionsSymbolDirective} from '../../actions/actions-symbol.directive';
import {CareSpaceService} from '../../animals/store/care-space/care-space.service';
import {TransferPersonDataComponent} from '../../persons/transfer-person-data/transfer-person-data.component';
import {PersonFormComponent} from '../../persons/person-form/person-form.component';
import {LoggingService} from '../../shared/logging/logging.service';
import {MissingAnimalsDialogComponent} from '../../dialogs/missing-animals-dialog/missing-animals-dialog.component';
import {RueckgabePensionstierService} from '../../animals/action-type-specific/rueckgabe-pensionstier/rueckgabe-pensionstier.service';
import {PrintingComponent} from '../../printing/printing.component';
import {CareSpacePrintingComponent} from '../../printing/templates/care-space-printing/care-space-printing.component';
import {DataSheetComponent} from '../../printing/templates/data-sheet/data-sheet.component';
import {HandoverContractComponent} from '../../printing/templates/handover-contract/handover-contract.component';
import {ChipContractComponent} from '../../printing/templates/chip-contract/chip-contract.component';
import {CashBookPrintingComponent} from '../../printing/templates/cash-book-printing/cash-book-printing.component';
import {FindlingPrintingComponent} from '../../printing/templates/action-specific/findling-printing/findling-printing.component';
import {AnnahmePensionstierPrintingComponent} from '../../printing/templates/action-specific/annahme-pensionstier-printing/annahme-pensionstier-printing.component';
import {AbnahmePrintingComponent} from '../../printing/templates/action-specific/abnahme-printing/abnahme-printing.component';
import {RueckgabePensionstierPrintingComponent} from '../../printing/templates/action-specific/rueckgabe-pensionstier-printing/rueckgabe-pensionstier-printing.component';
import {RueckgabePrintingComponent} from '../../printing/templates/action-specific/rueckgabe-printing/rueckgabe-printing.component';
import {AbgabePrintingComponent} from '../../printing/templates/action-specific/abgabe-printing/abgabe-printing.component';
import {IncomeOutcomePrintingComponent} from '../../printing/templates/income-outcome-printing/income-outcome-printing.component';
// tslint:disable-next-line:max-line-length
import {CreateUpdateMissingAnimalComponent} from '../../animals/missing/create-update-missing-animal/create-update-missing-animal.component';
import {SupervisionPrintingComponent} from '../../printing/templates/supervision-printing/supervision-printing.component';
import {StatisticsComponent} from '../../statistics/statistics.component';
import {StatisticsFilterComponent} from '../../statistics/statistics-filter/statistics-filter.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(AdminLayoutRoutes),
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        ComponentsModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatCheckboxModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatIconModule,
        NgxMatSelectSearchModule,
        MatDatepickerModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,
        MatExpansionModule,
        MatDialogModule,
        MatRadioModule,
        MatSnackBarModule,
        DragDropModule,
        UserManagementModule,
        SharedModule,
        ImageCropperModule
    ],
    declarations: [
        AnimalsComponent,
        PersonsComponent,
        AnimalsFilterComponent,
        AnimalAddEditComponent,
        AnimalListComponent,
        PosSystemComponent,
        ActionsComponent,
        AttributesComponent,
        AttributesOptionComponent,
        QuickEditComponent,
        QuicknavComponent,
        AcquisitionAndGiveAwayComponent,
        PersonalizedActionComponent,
        MissingComponent,
        FindlingComponent,
        VergabeComponent,
        AnnahmePensionstierComponent,
        RueckgabePensionstierComponent,
        SimpleDialogComponent,
        PersonsFilterComponent,
        AssociatedAnimalsComponent,
        AbgabeComponent,
        AbnahmeComponent,
        PersonDialogComponent,
        SupervisionComponent,
        FormerSupervisionsComponent,
        SupervisionFilterComponent,
        MissingAnimalsFilterComponent,
        CreateUpdateMissingAnimalComponent,
        IncomeOutcomeComponent,
        CancellationComponent,
        ActionsFilterComponent,
        PersonQuickEditComponent,
        VeterinaryTreatmentsComponent,
        ArticleListComponent,
        VeterinaryTreatmentQuickEditComponent,
        CashbookComponent,
        FormerVeterinaryTreatmentsComponent,
        CareSpaceComponent,
        FormerCareSpacesComponent,
        ArticleQuickEditComponent,
        FormerVeterinaryTreatmentsQuickEditComponent,
        BookingEntryFormComponent,
        MediaCentreComponent,
        MediaThumbComponent,
        ImageFullScreenComponent,
        ImageUploadComponent,
        SafeHtmlPipe,
        EditProfileComponent,
        MediaFilterComponent,
        FormerSupervisionContentComponent,
        ActionsSymbolDirective,
        TransferPersonDataComponent,
        PersonFormComponent,
        MissingAnimalsDialogComponent,
        PrintingComponent,
        CareSpacePrintingComponent,
        DataSheetComponent,
        HandoverContractComponent,
        ChipContractComponent,
        CashBookPrintingComponent,
        FindlingPrintingComponent,
        AbnahmePrintingComponent,
        AbgabePrintingComponent,
        AnnahmePensionstierPrintingComponent,
        RueckgabePensionstierPrintingComponent,
        RueckgabePrintingComponent,
        IncomeOutcomePrintingComponent,
        SupervisionPrintingComponent,
        StatisticsComponent,
        StatisticsFilterComponent
    ],
    providers: [
        SelectBoxService,
        PosSystemService,
        AttributesService,
        RaceService,
        AccommodationService,
        SpeciesService,
        BookingService,
        VeterinaryTreatmentsService,
        PersonsService,
        AnimalsService,
        ActionTypesService,
        CharacteristicService,
        MediaService,
        SupervisionService,
        ActionsService,
        CareSpaceService,
        LoggingService,
        RueckgabePensionstierService
    ]
})

export class AdminLayoutModule {
}
