import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PersonalizedActionComponent} from '../personalized-action/personalized-action.component';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {FormerSupervisionsComponent} from '../../supervision/former-supervisions/former-supervisions.component';
import {DialogType} from '../../dialogs/dialog-type';
import {FormerVeterinaryTreatmentsComponent} from '../../veterinary-treatments/former-veterinary-treatments/former-veterinary-treatments.component';
import {FormerCareSpacesComponent} from '../care-space/former-care-spaces/former-care-spaces.component';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {ActionsService} from '../../actions/actions.service';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {PrintingService} from '../../printing/printing.service';
import {PrintingDataAnimal} from '../../printing/templates/TemplateTypes';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {ActionType} from '../store/action-types/action-types.service';
import {Order} from '../../shared/controls/data-table/ordering';
import {MatButton} from '@angular/material/button';
import {MediaService} from '../../media-centre/media.service';

@Component({
    selector: 'app-quicknav',
    templateUrl: './quicknav.component.html',
    styleUrls: ['./quicknav.component.scss']
})
export class QuicknavComponent {
    @Input() public animal: AnimalEntryModel;
    @Input() public latestTransaction: TransactionEntryModel;
    @Input() public animalFilter: any;
    @Input() public pageIndex = 0;
    @Input() public entriesPerPage = 10;
    @Input() public order = Order.NONE;
    @Input() public filterModel: any;
    @Input() public orderedColumn = '';
    @Input() public saveChangesBtn: MatButton;
    @Output() public clickedIcon: EventEmitter<string> = new EventEmitter();
    @Output() public enableSaveAnimalBtn: EventEmitter<boolean> = new EventEmitter();

    public readonly PERSONALIZED_ACTIONS = 'personalized-actions';
    public readonly FORMER_SUPERVISIONS = 'former-supervisions';
    public readonly FORMER_VETERINARY_TREATMENTS = 'former-veterinary-treatments';
    public readonly FORMER_CARE_SPACES = 'former-care-spaces';
    public readonly simpleDialogType = new DialogType();

    constructor(private dialog: MatDialog,
                private mediaService: MediaService,
                private printingService: PrintingService,
                private actionsService: ActionsService) {
    }

    public openDialog(dialogType: string): void {
        let component = null;
        let width = 900;
        const data = {
            animal: this.animal
        };

        if (dialogType === this.PERSONALIZED_ACTIONS) {
            component = PersonalizedActionComponent;
            this.open(component, data, width);
            this.clickedIcon.emit(this.PERSONALIZED_ACTIONS);
        } else if (dialogType === this.simpleDialogType.GOING_TO_HEAVEN) {
            const heavenData = {
                type: this.simpleDialogType.GOING_TO_HEAVEN,
                meta: [
                    {key: 'latestTransaction', value: this.latestTransaction}
                ]
            };
            const dialogRef = this.open(SimpleDialogComponent, heavenData, width);
            dialogRef.afterClosed().subscribe(response => {
                if (typeof response !== 'undefined' && response) {
                    this.actionsService.sendToHeaven(response,
                        this.latestTransaction,
                        this.animal,
                        this.pageIndex,
                        this.entriesPerPage,
                        this.order,
                        this.orderedColumn);
                    this.enableSaveAnimalBtn.emit(false);
                }
            });
            this.clickedIcon.emit(this.simpleDialogType.GOING_TO_HEAVEN);
        } else if (dialogType === this.FORMER_SUPERVISIONS) {
            component = FormerSupervisionsComponent;
            this.open(component, data, width);
            this.clickedIcon.emit(this.FORMER_SUPERVISIONS);
        } else if (dialogType === this.FORMER_VETERINARY_TREATMENTS) {
            component = FormerVeterinaryTreatmentsComponent;
            width = 1400;
            this.open(component, data, width);
            this.clickedIcon.emit(this.FORMER_VETERINARY_TREATMENTS);
        } else if (dialogType === this.FORMER_CARE_SPACES) {
            component = FormerCareSpacesComponent;
            this.open(component, data, width);
            this.clickedIcon.emit(this.FORMER_CARE_SPACES);
        }
    }

    public printAnimal(): void {
        this.dialog.closeAll();
        this.dialog.afterAllClosed
            .pipe(take(1))
            .subscribe(() => {
                const mappedAnimal: AnimalEntryModel = {
                    ...this.animal
                };

                let streetFound = '';
                let zipFound = '';
                let cityFound = '';

                if (this.animal.type.name === ActionType.FINDLING.key) {
                    const latestTransaction = this.animal.transactionHistory?.slice().pop();
                    if (latestTransaction) {
                        streetFound = latestTransaction.streetFound;
                        zipFound = latestTransaction.zipFound;
                        cityFound = latestTransaction.cityFound;
                    }
                }

                let printingDataAnimal: PrintingDataAnimal = {
                    animals: [mappedAnimal],
                    person: this.latestTransaction?.person,
                    date: moment().toString(),
                    actionType: this.animal.type,
                    streetFound,
                    zipFound,
                    cityFound
                };

                const avatarId = mappedAnimal.avatarId;
                if (avatarId) {
                    this.mediaService.fetchMultipleMedia([avatarId], false)
                        .subscribe(mediaModels => {
                            printingDataAnimal = {
                                ...printingDataAnimal,
                                avatars: mediaModels
                            };
                            this.printingService.dataFetched.next(printingDataAnimal);
                        });
                } else {
                    this.printingService.dataFetched.next(printingDataAnimal);
                }

            });
    }

    private open(component: any, data: any, width: number): MatDialogRef<any> {
        return this.dialog.open(component, {
            width: width + 'px',
            panelClass: 'component-wrapper',
            data,
            autoFocus: false
        });
    }
}
