import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormerVeterinaryTreatmentsComponent} from '../former-veterinary-treatments.component';
import {FormerTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/former-veterinary-treatment-entry.model';
import * as moment from 'moment';

@Component({
    selector: 'app-former-veterinary-treatments-quick-edit',
    templateUrl: './former-veterinary-treatments-quick-edit.component.html',
    styleUrls: ['./former-veterinary-treatments-quick-edit.component.scss']
})
export class FormerVeterinaryTreatmentsQuickEditComponent extends FormerVeterinaryTreatmentsComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @Input() public formerTreatment: FormerTreatmentEntryModel;
    public updateRequestSent = false;

    private toBeUpdatedTreatment: FormerTreatmentEntryModel;

    ngOnInit(): void {
        super.initForm(this.formerTreatment);
        super.initVeterinaryTreatmentSelectBox();
    }

    ngAfterViewInit(): void {
        this.selectSearchVeterinaryTreatment.init(this.singleSelectVeterinaryTreatment);
    }

    ngOnDestroy(): void {
        this.selectSearchVeterinaryTreatment.destroy();
    }

    public updateFormerTreatment(): void {
        if (!this.addNewFormerTreatmentForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }
        this.updateRequestSent = true;
        this.displayErroneousInputs = false;

        const description = this.addNewFormerTreatmentForm.value.treatmentName.viewValue;
        const invoiceText = this.addNewFormerTreatmentForm.value.invoiceText;
        const price = this.addNewFormerTreatmentForm.value.price.replace(/,/g, '.');
        const report = this.addNewFormerTreatmentForm.value.report;
        const date = moment(this.addNewFormerTreatmentForm.value.date).format('YYYY-MM-DD');
        const updatedFormerTreatment: FormerTreatmentEntryModel = {
            id: this.formerTreatment.id,
            date,
            description,
            invoiceText,
            price,
            report,
            animalId: this.formerTreatment.animalId
        };

        const filter = {
            animalId: this.animal.id
        };
        this.veterinaryTreatmentService
            .updateFormerVeterinaryTreatment(updatedFormerTreatment, filter);
    }
}
