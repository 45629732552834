import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as RacesActions from './race.actions';

export interface State {
    races: DictionaryEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    races: [],
    operation: CRUD.NONE
};

export function racesReducer(state = initialState, action: RacesActions.RacesActions): State {
    switch (action.type) {
        case RacesActions.SET_RACES:
            return {
                ...state,
                races: [...action.payload.races],
                operation: action.payload.operation
            };
        case RacesActions.ADD_RACE:
            return {
                ...state,
                races: [...state.races, action.payload]
            };
        case RacesActions.UPDATE_RACE:
            const updatedRace = {
                ...state.races.find(race => race.id === action.payload.id),
                ...action.payload
            };

            const updatedRaces = [...state.races];
            const arrIndex = updatedRaces.findIndex(element => element.id === updatedRace.id);
            updatedRaces[arrIndex] = updatedRace;

            return {
                ...state,
                races: updatedRaces
            };
        case RacesActions.DELETE_RACE:
            return {
                ...state,
                races: state.races.filter(race => race.id !== action.payload.deleteId)
            };
        case RacesActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
