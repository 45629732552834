import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {Order} from '../../shared/controls/data-table/ordering';
import {ofType} from '@ngrx/effects';
import {PersonsComponent} from '../persons.component';
import {FormGroup} from '@angular/forms';
import * as fromPersonActions from '../store/persons.actions';
import * as moment from 'moment';
import {SnackStatusType} from '../../shared/components/snackbar/snack-status-type';
import {ZipCity} from 'app/shared/services/zip-city-search/zip-city-search.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {TupleHelper} from '../../shared/data-types/tuple';

@Component({
    selector: 'app-person-quick-edit',
    templateUrl: './person-quick-edit.component.html',
    styleUrls: ['./person-quick-edit.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class PersonQuickEditComponent extends PersonsComponent implements OnInit, OnDestroy {
    @ViewChild('datePickerBirthday') public datePickerBirthday: any;
    @Input() public person: PersonEntryModel;
    @Input() public pageIndex: number;
    @Input() public entriesPerPage: number;
    @Input() public order: Order;
    @Input() public orderedColumn: string;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public updateRequestSent = false;
    public personForm: FormGroup;
    public ZipCity = ZipCity;

    ngOnInit(): void {
        this.personForm = this.personService.createForm(this.person);
        this.personForm.patchValue({
            zip: {
                viewValue: this.person.zip,
                value: this.person.zip,
                meta: [
                    {
                        key: 'zip',
                        value: this.person.zip
                    }
                ]
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public updatePerson(): void {
        if (!this.personForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const formValue = this.personForm.value;
        const age = formValue.birthday !== '' ? moment().diff(formValue.birthday, 'years') : null;

        if (age != null && (age < 18 || age > 150)) {
            this.snackbarService.displaySnackbar(SnackStatusType.ERROR, 'Ungültiges Alter: ' + age + ' Jahre', 10);
            return;
        }

        let zip = '';
        if (typeof formValue.zip === 'string') {
            zip = formValue.zip;
        } else {
            zip = TupleHelper.getValue(formValue.zip.meta, 'zip');
        }

        this.updateRequestSent = true;
        this.displayErroneousInputs = false;

        const updatedPerson: PersonEntryModel = {
            ...this.person,
            id: this.person.id,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            street: formValue.street,
            zip,
            city: formValue.city,
            phone: formValue.phone,
            birthday: formValue.birthday !== '' ?
                moment(formValue.birthday).format('YYYY-MM-DD') : null,
            email: formValue.email,
            proof: formValue.proof,
            issuer: formValue.issuer,
            remark: formValue.remark
        };

        this.personService.updatePerson(updatedPerson, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);
        this.personService.crudFinished.next(formValue.firstName + ' ' + formValue.lastName);

        this.actions$.pipe(
            ofType(fromPersonActions.SET_PERSONS)
        ).subscribe(() => {
            this.updateRequestSent = false;
        });
    }

    public zipCityChanged(event: MatAutocompleteSelectedEvent): void {
        const option = event.option.value;
        const city = TupleHelper.getValue(option.meta, 'city');
        this.personForm.patchValue({
            city
        });
    }
}
