import {Component, OnInit} from '@angular/core';
import {PrintableComponent} from '../../PrintableComponent';
import {PrintingIncomeOutcome, PrintingTemplate} from '../TemplateTypes';
import {Constants, Shelter} from '../../../shared/constants';
import {AuthService} from '../../../auth/auth.service';
import {UserEntryModel} from '../../../shared/models/user-entry.model';

@Component({
    selector: 'app-income-outcome-printing',
    templateUrl: './income-outcome-printing.component.html',
    styleUrls: ['./income-outcome-printing.component.scss']
})
export class IncomeOutcomePrintingComponent implements PrintableComponent, OnInit {
    public isIncomeComponent: boolean;
    public hideHeader: boolean;
    public printingTemplate = PrintingTemplate.IncomeOutcome;
    public shelter: Shelter;
    public visible: boolean;
    public data: PrintingIncomeOutcome;

    public shelterName = '';
    public address = '';
    public zipAndCity = '';
    public cityName = '';
    public phone = '';
    public sum = 0;
    public atm = 0;
    public cash = 0;
    public user: UserEntryModel;

    constructor(private authService: AuthService) {
    }


    ngOnInit(): void {
        if (this.shelter === Shelter.LINZ) {
            this.shelterName = Constants.LinzShelterName;
            this.address = Constants.LinzAddress;
            this.zipAndCity = Constants.LinzZipAndCity;
            this.cityName = Constants.LinzCityName;
            this.phone = Constants.LinzPhone;
        } else {
            this.shelterName = Constants.SteyrShelterName;
            this.address = Constants.SteyrAddress;
            this.zipAndCity = Constants.SteyrZipAndCity;
            this.cityName = Constants.SteyrCityName;
            this.phone = Constants.SteyrPhone;
        }

        this.data.bookingEntries.forEach(entry => {
            this.sum += entry.price;
            this.atm += entry.isATM ? entry.price : 0;
            this.cash += !entry.isATM ? entry.price : 0;
        });

        this.user = this.authService.loggedInUser.value;

    }
}
