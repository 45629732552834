<div class="title-bar not-printable">
    <h3>{{headline}}</h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{cardTitle}}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <form [formGroup]="dateForm"
                                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (click)="datePicker.open()"
                                           (dateChange)="dateChange($event)"
                                           [ngxMatDatetimePicker]="datePicker"
                                           placeholder="Datum"
                                           formControlName="dateAt"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="datePicker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #datePicker
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>

                    <div id="article-wrapper" class="mt-5">
                        <form [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                            <ng-container #articleEntryContainer>
                            </ng-container>
                        </form>

                        <div class="row add-new-article mt-3 pt-3 align-items-center">
                            <div class="col-md-8 d-flex justify-content-start">
                                <a class="add-btn big-btn fas fa-plus d-flex justify-content-center align-items-center"
                                   (click)="addBookingEntry()">
                                </a>
                            </div>
                        </div>
                        <div class="row" *ngIf="entryComponentCollection.length > 0 && isIncomeComponent">
                            <div class="col-md-12 d-flex justify-content-end">
                                <button mat-raised-button color="primary" class="float-right"
                                        (click)="payWithATM()">
                                    Mit Bankomat bezahlen
                                </button>
                            </div>
                        </div>
                    </div>

                    <div id="aggregated-price" class="row mt-5 font-weight-bolder pt-4">
                        <div class="col-md-2">
                            <span class="lead">Summe:</span>
                        </div>
                        <div class="col-md-3">
                            <span class="lead">{{sum | currency:'EUR':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                    <div class="row mt-3 d-flex align-items-center" *ngIf="isIncomeComponent">
                        <div class="col-md-2">
                            <span>Erhalten:</span>
                        </div>
                        <div class="col-md-3">
                            <form [formGroup]="moneyReceivedForm">
                                <mat-form-field class="text-input">
                                    <mat-label>Preis in €</mat-label>
                                    <input matInput
                                           placeholder="Preis in €"
                                           (keyup)="moneyReceivedChanged()"
                                           formControlName="moneyReceived">
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="isIncomeComponent">
                        <div class="col-md-2">
                            <span>Retour:</span>
                        </div>
                        <div class="col-md-2" [ngClass]="{'surplus': change > 0, 'loss': change < 0}">
                            {{change | currency:'EUR':'symbol':'1.2-2'}}
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <button mat-raised-button
                                    color="primary"
                                    class="float-right"
                                    [disabled]="addRequestSent && moneyReceivedForm.valid && validEntryForm"
                                    (click)="submitForm()">
                                <i class="fas fa-exclamation-circle mr-2"
                                   style="color: #b71c1c"
                                   *ngIf="displayErroneousInputs">
                                </i>
                                Speichern und drucken
                            </button>
                            <button class="float-right mr-3" mat-raised-button color="primary"
                                    (click)="insertDonationEntry()"
                                    *ngIf="isIncomeComponent && change > 0 && donate === false">
                                Rest ist Spende
                            </button>
                            <mat-icon
                                    [hidden]="!(addRequestSent && moneyReceivedForm.valid && validEntryForm)"
                                    class="mr-1 float-right" style="margin-top: 7px">
                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-printing [templateType]="PrintingTemplate.IncomeOutcome" [isIncomeComponent]="isIncomeComponent"></app-printing>
