<ng-container [formGroup]="acquisitionGiveAwayForm">
    <div class="row mt-4">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Abnahmegrund</mat-label>
                <textarea matInput
                          placeholder="Abnahmegrund"
                          formControlName="takingOverReason"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5">
                </textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Behörde</mat-label>
                <input matInput
                       placeholder="Behörde"
                       formControlName="publicAuthority">
            </mat-form-field>
        </div>
    </div>
</ng-container>
