<div class="title-bar">
    <h3>Artikelliste</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Artikel</h4>
                </div>
                <div class="card-body">
                    <div id="top-controls" class="d-flex justify-content-between">
                        <div id="show-entries-container">
                            Zeige
                            <select name="entries"
                                    id="entries"
                                    class="mr-1 ml-1"
                                    (ngModelChange)="changeEntriesPerPage($event)"
                                    [(ngModel)]="entriesPerPage">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            Einträge
                        </div>
                        <div id="add-new-article">
                            <a class="d-flex align-items-center no-color"
                               (click)="addTableRow()">
                                Neuen Artikel erstellen <i
                                    class="add-btn fas fa-plus d-flex justify-content-center align-items-center ml-3"></i>
                            </a>
                        </div>
                    </div>
                    <div style="overflow-x: auto">
                        <table mat-table
                               [dataSource]="articles$"
                               (matSortChange)="orderData($event)"
                               multiTemplateDataRows
                               matSort
                               class="mat-elevation-z0 extensible">
                            <ng-container *ngFor="let def of tableDef">
                                <ng-container [matColumnDef]="def.key">
                                    <th mat-header-cell
                                        mat-sort-header
                                        *matHeaderCellDef> {{def.header}}
                                    </th>
                                    <td [ngClass]="def.className"
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngSwitch]="def.key">
                                        <ng-container *ngSwitchCase="'category'">
                                            {{element[def.key].name}}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'price'">
                                            {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{element[def.key]}}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container [formGroup]="newArticleForm">
                                <ng-container matColumnDef="new-id">
                                    <td mat-footer-cell *matFooterCellDef class="pl-0 pr-0">
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-category">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Kategorie</mat-label>
                                            <mat-select placeholder="Kategorie"
                                                        [multiple]="false"
                                                        formControlName="newCategory">
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                            [formControl]="selectSearchPOSCategory.multiFilterCtrl">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                                    <a class="add-new"
                                                       (click)="posSystemService.addCategory($event)"
                                                       style="font-weight: 600">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <div>Neue Kategorie erstellen...</div>
                                                            <div>
                                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </mat-option>
                                                <mat-option
                                                        *ngFor="let category of selectSearchPOSCategory.filteredOptions | async"
                                                        [value]="category"
                                                        #option>
                                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                                        <div class="text-wrapper">
                                                            <span class="text-element">{{category.viewValue}}</span>
                                                            <input class="d-none"
                                                                   matInput
                                                                   #updatedVal
                                                                   (click)="$event.stopPropagation()"
                                                                   (keydown.enter)="posSystemService.updateCategory($event,
                                                                                           option,
                                                                                           category,
                                                                                           updatedVal,
                                                                                           pageIndex,
                                                                                           entriesPerPage,
                                                                                           order,
                                                                                           orderedColumn)"
                                                                   (keydown.space)="$event.stopPropagation()"
                                                                   [value]="category.viewValue" />
                                                        </div>
                                                        <div class="controls"
                                                             *ngIf="category.value !== 'unkategorisiert' && !lockedCategory(category.viewValue)">
                                                            <a class="fas fa-edit icon"
                                                               (click)="selectBoxService.activateEditMode($event, option)"
                                                               matTooltip="Kategorie ändern"
                                                               matTooltipPosition="right"
                                                               matTooltipClass="tooltip-popup"></a>
                                                            <a class="fas fa-check icon d-none"
                                                               (click)="posSystemService.updateCategory($event,
                                                                                                       option,
                                                                                                       category,
                                                                                                       updatedVal,
                                                                                                       pageIndex,
                                                                                                       entriesPerPage,
                                                                                                       order,
                                                                                                       orderedColumn)"
                                                               matTooltip="Änderung speichern"
                                                               matTooltipPosition="right"
                                                               matTooltipClass="tooltip-popup"></a>
                                                            <a class="fas fa-trash icon"
                                                               (click)="posSystemService.deleteCategory($event,
                                                                                                       category,
                                                                                                       pageIndex,
                                                                                                       entriesPerPage,
                                                                                                       order,
                                                                                                       orderedColumn)"
                                                               matTooltip="Kategorie löschen"
                                                               matTooltipPosition="right"
                                                               matTooltipClass="tooltip-popup"></a>
                                                        </div>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-name">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                        <mat-form-field>
                                            <mat-label>Name</mat-label>
                                            <input matInput
                                                   placeholder="Artikel"
                                                   formControlName="newName">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-price">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                        <mat-form-field>
                                            <mat-label>Kosten</mat-label>
                                            <input matInput
                                                   placeholder="Kosten"
                                                   formControlName="newPrice">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-control">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-0">
                                        <a class="ml-2 remove-btn fas fa-minus"
                                           (click)="addNewArticle = false"
                                           matTooltip="Abbrechen"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"
                                        ></a>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container matColumnDef="controls">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="control-wrapper d-flex align-items-center justify-content-center"
                                         *ngIf="element.name !== 'Spende'">
                                        <a class="fas fa-edit icon"
                                           matTooltip="Artikel bearbeiten"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                        <a class="fas fa-trash-alt icon prevent-opening"
                                           (click)="delete(element)"
                                           matTooltip="Artikel löschen"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
                                    <div class="table-element-detail w-100"
                                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <app-article-quick-edit class="w-100"
                                                                [article]="element"
                                                                [pageIndex]="pageIndex"
                                                                [entriesPerPage]="entriesPerPage"
                                                                [order]="order"
                                                                [orderedColumn]="orderedColumn"></app-article-quick-edit>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="table-row"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = canExpand($event, element, expandedElement)">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

                            <tr mat-footer-row
                                [ngStyle]="{'opacity': addNewArticle ? '1' : '0'}"
                                [ngClass]="{'displayErroneousInputs': displayErroneousInputs}"
                                *matFooterRowDef="displayedColumnNewArticle"></tr>
                        </table>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline"
                             [ngStyle]="{'bottom': addNewArticle ? '0' : '94px'}">
                            <div id="total-results">Gesamt: <span>{{totalArticles}}</span></div>
                            <mat-paginator [length]="totalArticles"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div class="default-entry mt-2" *ngIf="totalArticles === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>

                    <div class="row" *ngIf="addNewArticle">
                        <div class="col-md-12 mt-4">
                            <button class="float-right ml-4"
                                    mat-raised-button color="primary"
                                    [disabled]="addRequestSent && newArticleForm.valid"
                                    (click)="saveArticle()">
                                <i class="fas fa-exclamation-circle mr-2"
                                   style="color: #b71c1c"
                                   *ngIf="displayErroneousInputs">
                                </i>
                                Artikel speichern
                            </button>
                            <mat-icon
                                    [hidden]="!(addRequestSent && newArticleForm.valid)"
                                    class="mr-1 float-right" style="margin-top: 7px">
                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
