import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {Order} from '../shared/controls/data-table/ordering';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {UserEntryModel} from '../shared/models/user-entry.model';
import * as fromApp from '../store/app.reducer';
import * as fromUserActions from './store/manage-users/users.actions';

@Injectable()
export class UserService {
    public crudFinished: Subject<string> = new Subject();

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public addUser(userModel: UserEntryModel,
                   lastPage: number,
                   entriesPerPage: number,
                   order: Order,
                   column: string): void {
        this.store.dispatch(new fromUserActions.AddUser({
            user: userModel,
            page: lastPage,
            size: entriesPerPage,
            order,
            column
        }));
    }

    public updateUser(userModel: UserEntryModel,
                      size: number,
                      page: number,
                      order: Order,
                      column: string): void {
        this.store.dispatch(new fromUserActions.UpdateUser({
            user: userModel,
            size,
            page,
            order,
            column
        }));
    }

    public updatePassword(updateUser: UserEntryModel): void {
        this.store.dispatch(new fromUserActions.UpdatePassword(updateUser));
    }

    public deleteUser(userId: number, size: number, page: number, order: Order, column: string): void {
        this.store.dispatch(new fromUserActions.DeleteUser({
            userId,
            size,
            page,
            order,
            column
        }));
    }

    public fetchUsers(size = -1, page = -1, order = Order.NONE, column = ''): Observable<UserEntryModel[]> {
        return this.store.select('usersList').pipe(
            take(1),
            map(userState => {
                return userState.users;
            }),
            switchMap(users => {
                if (users.length === 0 || page >= 0) {
                    this.store.dispatch(new fromUserActions.LoadUsers({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column
                    }));
                    return this.actions$.pipe(
                        ofType(fromUserActions.SET_USERS),
                        map((veterinaryTreatmentState: fromUserActions.SetUsers) => {
                            return veterinaryTreatmentState.payload.users;
                        })
                    );
                } else {
                    return of(users);
                }
            })
        );
    }


    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
