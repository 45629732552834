import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {FormerTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/former-veterinary-treatment-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_FORMER_TREATMENTS = '[FormerTreatments] Load FormerTreatments';
export const SET_FORMER_TREATMENTS = '[FormerTreatments] Set FormerTreatments';
export const ADD_FORMER_TREATMENT = '[FormerTreatments] Add FormerTreatment';
export const UPDATE_FORMER_TREATMENT = '[FormerTreatments] Update FormerTreatment';
export const DELETE_FORMER_TREATMENT = '[FormerTreatments] Delete FormerTreatment';
export const HTTP_FAIL = '[FormerTreatments] Http Fail';

export class LoadFormerTreatments implements Action {
    readonly type = LOAD_FORMER_TREATMENTS;

    constructor(public payload: {
        operation: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class SetFormerTreatments implements Action {
    readonly type = SET_FORMER_TREATMENTS;

    constructor(public payload: { formerTreatments: FormerTreatmentEntryModel[], crud: CRUD }) {
    }
}

export class AddFormerTreatment implements Action {
    readonly type = ADD_FORMER_TREATMENT;

    constructor(public payload: {
        size: number,
        page: number,
        order: Order,
        column: string,
        newFormerTreatment: FormerTreatmentEntryModel, filter?: any
    }) {
    }
}

export class UpdateFormerTreatment implements Action {
    readonly type = UPDATE_FORMER_TREATMENT;

    constructor(public payload: { updatedFormerTreatment: FormerTreatmentEntryModel, filter?: any }) {
    }
}

export class DeleteFormerTreatment implements Action {
    readonly type = DELETE_FORMER_TREATMENT;

    constructor(public payload: { formerTreatmentId: number, filter?: any }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type FormerTreatmentsActions =
    LoadFormerTreatments
    | SetFormerTreatments
    | AddFormerTreatment
    | UpdateFormerTreatment
    | DeleteFormerTreatment
    | HttpFail;
