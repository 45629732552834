import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../../shared/controls/date-time-picker/DateTimeConfig';
import {Order} from '../../../shared/controls/data-table/ordering';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {AnimalDropdownOptions} from '../../animal-dropdown-options';
import {take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {TupleHelper} from '../../../shared/data-types/tuple';
import {Subscription} from 'rxjs';
import {ChipBar} from '../../../shared/controls/chip-bar';
import * as moment from 'moment';
import * as fromAnimalActions from '../../store/animals/animal.actions';
import {CareSpaceEntryModel} from '../../../shared/models/animal/care-space-entry.model';
import * as fromCareSpaceActions from '../../store/care-space/care-space.actions';
import {CRUD} from '../../../shared/services/http/crud';
import {ActionType} from '../../store/action-types/action-types.service';
import {MissingAnimalsDialogComponent} from '../../../dialogs/missing-animals-dialog/missing-animals-dialog.component';
import {CareSpaceService} from '../../store/care-space/care-space.service';
import {AnimalsService} from '../../animals.service';
import {CharacteristicService} from '../../store/characteristics/characteristic.service';
import {MatDialog} from '@angular/material/dialog';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';

@Component({
    selector: 'app-quick-edit',
    templateUrl: './quick-edit.component.html',
    styleUrls: ['./quick-edit.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class QuickEditComponent implements OnInit, AfterViewInit, OnDestroy {
    public animalEntry: AnimalEntryModel;
    public pageIndex: number;
    public entriesPerPage: number;
    public order: Order;
    public orderedColumn: string;
    @ViewChild('characteristicInput') public characteristicInput: ElementRef<HTMLInputElement>;
    @ViewChild('datePickerBirthday') public datePickerBirthday: any;
    @ViewChild('datePickerBirthday') public datePickerReservedAt: any;

    public updateRequestSent = false;
    public quickForm: FormGroup;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public shelterStatusOptions = AnimalDropdownOptions.shelterStatusOptions;
    public castrationStatusOptions = AnimalDropdownOptions.castrationStatusOptions;
    public genderOptions = AnimalDropdownOptions.genderOptions;
    public displayErroneousInputs = false;
    public filterModel: any;
    public careSpaceForm: FormGroup;
    public careSpaceRequest = false;
    public chipBar: ChipBar;
    public ActionType = ActionType;
    public species: DropdownOption[];
    public races: DropdownOption[];

    private transactionSub: Subscription;
    private setCareSpaceSub: Subscription;
    private chipBarSub: Subscription;
    private animalsUpdatedSub: Subscription;
    private speciesRacesUpdatedSub: Subscription;
    private characteristicsSub: Subscription;

    constructor(private careSpaceService: CareSpaceService,
                private animalService: AnimalsService,
                private dialog: MatDialog,
                private formBuilder: FormBuilder,
                private actions$: Actions,
                private characteristicService: CharacteristicService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initChipBar();
        this.initEvents();
    }

    ngAfterViewInit(): void {
        this.chipBar.setInput(this.characteristicInput);
    }

    ngOnDestroy(): void {
        this.chipBar.setValues([]);

        if (this.transactionSub) {
            this.transactionSub.unsubscribe();
        }
        if (this.chipBarSub) {
            this.chipBarSub.unsubscribe();
        }
        if (this.setCareSpaceSub) {
            this.setCareSpaceSub.unsubscribe();
        }
        if (this.animalsUpdatedSub) {
            this.animalsUpdatedSub.unsubscribe();
        }
        if (this.speciesRacesUpdatedSub) {
            this.speciesRacesUpdatedSub.unsubscribe();
        }
        if (this.characteristicsSub) {
            this.characteristicsSub.unsubscribe();
        }
    }

    public updateAnimal(): void {
        if (!this.quickForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.updateRequestSent = true;
        this.displayErroneousInputs = false;

        const formValue = this.quickForm.value;
        const speciesOption = this.species.find(item => item.value === formValue.species);

        const dictSpecies: DictionaryEntryModel = {
            id: +TupleHelper.getValue(speciesOption.meta, 'id'),
            name: speciesOption.viewValue
        };

        const racesOption = this.races.find(item => item.value === formValue.race);
        const dictRace: DictionaryEntryModel = {
            id: +TupleHelper.getValue(racesOption.meta, 'id'),
            name: racesOption.viewValue
        };

        const updateAnimal: AnimalEntryModel = {
            ...this.animalEntry,
            name: formValue.name,
            birthday: formValue.birthday ? moment(formValue.birthday).format('YYYY-MM-DD') : '',
            species: dictSpecies,
            race: dictRace,
            shelter: formValue.shelter,
            gender: formValue.gender,
            castrated: formValue.castration,
            hiddenOnWebsite: formValue.hiddenOnWebsite,
            premiumAnimal: formValue.premiumAnimal,
            sponsoredAnimal: formValue.sponsoredAnimal,
            noGivingAway: formValue.noGivingAway,
            noGivingAwayReason: formValue.noGivingAwayReason,
            characteristics: this.chipBar.getSelectedValues(),
            reserved: formValue.reserved,
            reservedFor: formValue.reservedFor ? formValue.reservedFor : '',
            reservedUntil: formValue.reservedUntil ? moment(formValue.reservedUntil).format('YYYY-MM-DD') : null
        };

        this.animalService.updateAnimal(
            updateAnimal,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn,
            this.filterModel);
        this.animalService.crudFinished.next(formValue.name);
    }

    public compareWithMissingAnimals(): void {
        this.dialog.open(MissingAnimalsDialogComponent, {
            width: '1200px',
            panelClass: 'component-wrapper',
            data: {
                animal: this.animalEntry,
                pageIndex: this.pageIndex,
                entriesPerPage: this.entriesPerPage,
                order: this.order,
                orderedColumn: this.orderedColumn,
                filter: this.filterModel
            }
        });
    }

    private acquireAnimal(): void {
        this.careSpaceRequest = true;
        const updatedCareSpace: CareSpaceEntryModel = {
            ...this.animalEntry.careSpace,
            created: moment(this.animalEntry.careSpace.created).format('YYYY-MM-DD HH:mm:ss'),
            dateBackToShelter: moment().format('YYYY-MM-DD HH:mm:ss')
        };
        this.animalEntry = {
            ...this.animalEntry,
            careSpace: updatedCareSpace
        };
        this.careSpaceService.updateCareSpace(updatedCareSpace);
        this.careSpaceService.crudFinished.next(this.animalEntry);
    }

    private initForm(): void {
        this.quickForm = this.formBuilder.group({
            name: [this.animalEntry.name, Validators.required],
            birthday: [this.animalEntry.birthday, Validators.required],
            species: [SelectBoxUtility.getOptionsValue(this.animalEntry.species.name), Validators.required],
            race: [SelectBoxUtility.getOptionsValue(this.animalEntry.race.name), Validators.required],
            shelter: [this.animalEntry.shelter, Validators.required],
            gender: [this.animalEntry.gender, Validators.required],
            castration: [SelectBoxUtility.getOptionsValue(this.animalEntry.castrated), Validators.required],
            hiddenOnWebsite: this.animalEntry.hiddenOnWebsite,
            premiumAnimal: this.animalEntry.premiumAnimal,
            sponsoredAnimal: this.animalEntry.sponsoredAnimal,
            noGivingAway: this.animalEntry.noGivingAway,
            noGivingAwayReason: this.animalEntry.noGivingAwayReason,
            reserved: this.animalEntry.reserved,
            reservedFor: this.animalEntry.reservedFor,
            reservedUntil: this.animalEntry.reservedUntil,
            careTakerName: '',
            careTakerAddress: '',
            careTakerPhone: ''
        });

        if (this.animalEntry.careSpace !== null) {
            const careTaker = this.animalEntry.careSpace.person;
            this.careSpaceForm = this.formBuilder.group({
                careTakerName: careTaker.lastName + ' ' + careTaker.firstName,
                careTakerAddress: careTaker.street + ', ' + careTaker.city + '-' + careTaker.zip,
                careTakerPhone: careTaker.phone
            });
        }
    }

    private initEvents(): void {
        this.animalsUpdatedSub = this.actions$.pipe(
            take(1),
            ofType(fromAnimalActions.SET_ANIMALS)
        ).subscribe(() => {
            this.updateRequestSent = false;
        });

        this.setCareSpaceSub = this.actions$.pipe(
            ofType(fromCareSpaceActions.SET_CARE_SPACES)
        ).subscribe((careSpaceState: fromCareSpaceActions.SetCareSpaces) => {
            const operation = careSpaceState.payload.operation;
            if (operation === CRUD.UPDATE) {
                this.careSpaceService.handleRequestSuccess(operation, 'Pflegeplatz', '');
                this.careSpaceRequest = false;
            }
        });
    }

    private initChipBar(): void {
        this.chipBar = new ChipBar();
        this.chipBarSub = this.characteristicService.characteristics
            .subscribe(characteristics => {
                this.chipBar.removeAllChips();
                const raceId = this.animalEntry.race.id;
                this.chipBar.setValues(characteristics.filter(c => +TupleHelper.getValue(c.meta, 'raceId') === raceId));
                this.animalEntry.characteristics.forEach(characteristic => {
                    this.chipBar.addChip(characteristic);
                });
            });
    }
}
