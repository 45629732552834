import {Component, Input, OnInit} from '@angular/core';
import {ManageUsersComponent} from '../manage-users.component';
import {FormGroup, Validators} from '@angular/forms';
import {UserEntryModel} from '../../../shared/models/user-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ofType} from '@ngrx/effects';
import * as fromUsersActions from '../../store/manage-users/users.actions';

@Component({
    selector: 'app-manage-users-quick-edit',
    templateUrl: './manage-users-quick-edit.component.html',
    styleUrls: ['./manage-users-quick-edit.component.scss']
})
export class ManageUsersQuickEditComponent extends ManageUsersComponent implements OnInit {
    @Input() userEntry: UserEntryModel;
    @Input() public pageIndex: number;
    @Input() public entriesPerPage: number;
    @Input() public order: Order;
    @Input() public orderedColumn: string;
    public userEditForm: FormGroup;
    public displayErroneousInputs = false;
    public updateRequestSent = false;

    ngOnInit(): void {
        this.initForm();
    }

    public updateUser(): void {
        if (!this.userEditForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.updateRequestSent = true;
        const updatedUser: UserEntryModel = {
            ...this.userEntry,
            firstName: this.userEditForm.controls.firstName.value,
            lastName: this.userEditForm.controls.lastName.value,
            email: this.userEditForm.controls.email.value
        };

        this.userService.updateUser(updatedUser, this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn);

        this.actions$.pipe(
            ofType(fromUsersActions.SET_USERS)
        ).subscribe(() => {
            this.updateRequestSent = false;
        });
    }

    private initForm(): void {
        this.userEditForm = this.formBuilder.group({
            email: [this.userEntry.email, Validators.required],
            firstName: [this.userEntry.firstName, Validators.required],
            lastName: [this.userEntry.lastName, Validators.required]
        });
    }
}
