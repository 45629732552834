<ng-container [formGroup]="acquisitionGiveAwayForm">
    <div class="row">
        <div class="col-md-12"><h5 class="mb-0 mt-4">Fundort</h5></div>
        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Strasse</mat-label>
                <input placeholder="Strasse"
                       formControlName="streetFound"
                       matInput>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Postleitzahl</mat-label>
                <input type="text"
                       placeholder="Postleitzahl"
                       matInput
                       (ngModelChange)="zipCitySearchService.valueChanged($event, ZipCity.ZIP)"
                       formControlName="zipFound"
                       [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption
                                  #auto="matAutocomplete"
                                  (optionSelected)="zipCityChanged($event)"
                                  [displayWith]="zipCitySearchService.displayFn">
                    <mat-option *ngFor="let option of zipCitySearchService.values"
                                [value]="option">
                        {{option.viewValue}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Ort</mat-label>
                <input placeholder="Ort"
                       formControlName="cityFound"
                       matInput>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Umstand des Auffindens</mat-label>
                <textarea matInput
                          placeholder="Umstand des Auffindens"
                          formControlName="howAnimalWasFound"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5">Findling</textarea>
            </mat-form-field>
        </div>
    </div>
</ng-container>
