<div class="edit-former-veterinary-treatment">
    <form class="form container-fluid"
          [formGroup]="addNewFormerTreatmentForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
        <div class="row">
            <div class="col-md-2">
                <mat-form-field class="select-search">
                    <mat-select id="veterinary-treatment-select"
                                formControlName="treatmentName"
                                (selectionChange)="treatmentChanged($event)"
                                placeholder="Medizinische Maßnahme">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchVeterinaryTreatment.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let treatment of selectSearchVeterinaryTreatment.filteredElements | async"
                                [value]="treatment.value">
                            {{treatment.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerSupervision.open()"
                           [ngxMatDatetimePicker]="datePickerSupervision"
                           placeholder="Datum"
                           formControlName="date"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerSupervision"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerSupervision
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Rechnungstext</mat-label>
                    <input matInput placeholder="Rechnungstext"
                           formControlName="invoiceText">
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <mat-label>Preis</mat-label>
                    <input matInput placeholder="Preis in €"
                           formControlName="price">
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <mat-label>Befund</mat-label>
                    <input matInput
                           placeholder="Befund"
                           formControlName="report">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button class="float-right mt-4" mat-raised-button color="primary"
                        (click)="updateFormerTreatment()">
                    <i class="fas fa-exclamation-circle mr-2"
                       style="color: #b71c1c"
                       *ngIf="displayErroneousInputs">
                    </i>
                    Aktualisieren
                </button>
            </div>
        </div>
    </form>
</div>

