import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {Subscription} from 'rxjs';
import {CareSpaceService} from '../../store/care-space/care-space.service';
import {CareSpaceEntryModel} from '../../../shared/models/animal/care-space-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';

@Component({
    selector: 'app-former-care-spaces',
    templateUrl: './former-care-spaces.component.html',
    styleUrls: ['./former-care-spaces.component.scss']
})
export class FormerCareSpacesComponent implements OnInit, OnDestroy {
    public animal: AnimalEntryModel;
    public careSpaces: CareSpaceEntryModel[];

    private fetchedCareSpaceSub: Subscription;

    constructor(private dialogRef: MatDialogRef<FormerCareSpacesComponent>,
                private careSpaceService: CareSpaceService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        if (this.data !== null && typeof this.data.animal !== 'undefined') {
            this.animal = this.data.animal;
        }
    }

    ngOnInit(): void {
        this.initFormerCareSpaces();
    }

    ngOnDestroy(): void {
        if (this.fetchedCareSpaceSub) {
            this.fetchedCareSpaceSub.unsubscribe();
        }
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    private initFormerCareSpaces(): void {
        const filterModel = {
            animalId: this.animal.id
        };

        this.fetchedCareSpaceSub = this.careSpaceService
            .fetchCareSpaces(-1, 1, Order.DESC, 'date_performed', filterModel)
            .subscribe((careSpaces: CareSpaceEntryModel[]) => {
                this.careSpaces = careSpaces;
            });
    }
}
