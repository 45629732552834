import {Component, OnInit} from '@angular/core';
import {PrintableComponent} from '../../PrintableComponent';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {Age, CastrationStatus, Gender, Shelter} from '../../../shared/constants';
import * as moment from 'moment';
import {CharacteristicService} from '../../../animals/store/characteristics/characteristic.service';
import {PrintingDataAnimal, PrintingTemplate} from '../TemplateTypes';
import {ActionType, ActionTypesService} from '../../../animals/store/action-types/action-types.service';
import {ActionTypeEntryModel} from '../../../shared/models/action-type-entry.model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-data-sheet',
    templateUrl: './data-sheet.component.html',
    styleUrls: ['./data-sheet.component.scss']
})
export class DataSheetComponent implements PrintableComponent, OnInit {
    public animal: AnimalEntryModel;
    public visible = false;
    public Age = Age;
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public moment = moment;
    public printingTemplate = PrintingTemplate.DataSheet;
    public hideHeader = true;
    public data: PrintingDataAnimal;
    public shelter: Shelter;
    public actionTypeName: string;
    public actionType: ActionTypeEntryModel;
    public actionTypes: ActionTypeEntryModel[];
    public transactionHistory = '';
    public ActionType = ActionType;
    public avatar = '';

    constructor(public characteristicsService: CharacteristicService,
                private sanitizer: DomSanitizer,
                private actionTypesService: ActionTypesService) {
    }

    ngOnInit(): void {
        if (this.data.avatars && this.animal.avatarId) {
            this.avatar = this.data.avatars.find(media => media.id === this.animal.avatarId).data;
        }
        if (this.actionTypes) {
            const transactionHistoryEntries = this.animal.transactionHistory;
            this.actionTypeName = this.actionTypesService.getActionTypeText(this.data.actionType.name);
            const annahmeActions = this.actionTypes.filter(at => at.isAcquisition === true);

            transactionHistoryEntries?.filter(te => annahmeActions.find(a => a.id === te.actionTypeId))
                .forEach(transaction => {
                    this.actionType = this.actionTypes.find(at => at.id === transaction.actionTypeId);
                    let remark = this.actionTypesService.getActionTypeText(this.actionType.name) + ' ';

                    if (this.actionType.name === ActionType.FINDLING.key) {
                        remark += transaction.howAnimalWasFound ? '(' + transaction.howAnimalWasFound + ') / ' : ' / ';
                    } else if (this.actionType.name === ActionType.ABNAHME.key) {
                        remark += transaction.takingOverReason ? '(' + transaction.takingOverReason + ') / ' : ' / ';
                    } else if (this.actionType.name === ActionType.ABGABE.key) {
                        remark += transaction.giveAwayReason ? '(' + transaction.giveAwayReason + ') / ' : ' / ';
                    } else if (this.actionType.name === ActionType.RUECKGABE.key) {
                        remark += ' / ';
                    } else if (this.actionType.name === ActionType.ANNAHME_PENSIONSTIER.key) {
                        remark += ' / ';
                    } else if (this.actionType.name === ActionType.RUECKGABE_PENSIONSTIER.key) {
                        remark += ' / ';
                    } else if (this.actionType.name === ActionType.VERGABE.key) {
                        remark += ' / ';
                    } else if (this.actionType.name === ActionType.BACK_TO_SHELTER.key) {
                        remark += ' / ';
                    }

                    this.transactionHistory += remark;
                });
            this.transactionHistory = this.transactionHistory.slice(0, -2);
        }
    }

    private sanitizeBase64(bas64: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + bas64);
    }
}
