<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Kassabuchauszug</h1>

    <section class="text-left">
        <div *ngIf="!data.grouped">Detailauszug vom {{data.from | date:'dd.MM.yyyy'}}
            bis {{data.to | date:'dd.MM.yyyy'}}</div>
        <div *ngIf="data.grouped">Gruppierter Auszug vom {{data.from | date:'dd.MM.yyyy'}}
            bis {{data.to | date:'dd.MM.yyyy'}}</div>
        <div>erstellt am {{moment().format('dd.MM.yyyy')}}</div>
    </section>

    <section class="mt-5 container print-table text-left">
        <div class="row header">
            <div class="col-{{colWidths[0]}}">Kategorie</div>
            <ng-container *ngIf="!data.grouped">
                <div class="col-{{colWidths[1]}}">Beschreibung</div>
                <div class="col-{{colWidths[2]}}">Bemerkung</div>
            </ng-container>
            <div class="col-{{colWidths[3]}}" style="flex-basis: 12%">Eingang</div>
            <div class="col-{{colWidths[4]}}">Ausgang</div>
            <div class="col-{{colWidths[5]}}">{{data.grouped ? 'Differenz' : 'Datum'}}</div>
            <div class="col-{{colWidths[6]}} text-right">Bankomat</div>
        </div>
        <div class="row">
            <div class="col-{{colWidths[0]}}">SALDOVORTAG</div>
            <ng-container *ngIf="!data.grouped">
                <div class="col-{{colWidths[1]}}"></div>
                <div class="col-{{colWidths[2]}}"></div>
            </ng-container>
            <div class="col-{{colWidths[3]}}" style="flex-basis: 12%"><span
                    *ngIf="data.cashPosition >=0">{{data.cashPosition | currency:'EUR':'symbol':'1.2-2'}}</span></div>
            <div class="col-{{colWidths[4]}}"><span
                    *ngIf="data.cashPosition < 0">{{data.cashPosition | currency:'EUR':'symbol':'1.2-2'}}</span></div>
            <div class="col-{{colWidths[5]}}"></div>
            <div class="col-{{colWidths[6]}}text-right"></div>
        </div>
        <ng-container *ngIf="!data.grouped">
            <div class="row" *ngFor="let entry of data.cashbookEntries">
                <div class="col-{{colWidths[0]}}">{{entry.category}}</div>
                <div class="col-{{colWidths[1]}}">{{entry.description}}</div>
                <div class="col-{{colWidths[2]}}">{{entry.remark}}</div>
                <div class="col-{{colWidths[3]}}"
                     style="flex-basis: 12%">{{entry.income | currency:'EUR':'symbol':'1.2-2'}}</div>
                <div class="col-{{colWidths[4]}}">{{entry.outcome | currency:'EUR':'symbol':'1.2-2'}}</div>
                <div class="col-{{colWidths[5]}}">{{entry.dateCreated | date:'dd.MM.yyyy'}}</div>
                <div class="col-{{colWidths[6]}} text-right">{{entry.atm | currency:'EUR':'symbol':'1.2-2'}}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="data.grouped">
            <div class="row" *ngFor="let entry of data.cashbookEntries">
                <div class="col-2">{{entry.category}}</div>
                <div class="col-2" style="flex-basis: 12%">{{entry.income | currency:'EUR':'symbol':'1.2-2'}}</div>
                <div class="col-1">{{entry.outcome | currency:'EUR':'symbol':'1.2-2'}}</div>
                <div class="col-2">{{entry.delta | currency:'EUR':'symbol':'1.2-2'}}</div>
                <div class="col-1">{{entry.atm | currency:'EUR':'symbol':'1.2-2'}}</div>
            </div>
        </ng-container>
        <div class="row sum font-weight-bolder">
            <div class="col-{{colWidths[0]}}">SUMME</div>
            <ng-container *ngIf="!data.grouped">
                <div class="col-{{colWidths[1]}}"></div>
                <div class="col-{{colWidths[2]}}"></div>
            </ng-container>
            <div class="col-{{colWidths[3]}}"
                 style="flex-basis: 12%">{{data.sumIncome | currency:'EUR':'symbol':'1.2-2'}}</div>
            <div class="col-{{colWidths[4]}}">{{data.sumOutcome | currency:'EUR':'symbol':'1.2-2'}}</div>
            <div class="col-{{colWidths[5]}}">{{data.sumDelta | currency:'EUR':'symbol':'1.2-2'}}</div>
            <div class="col-{{colWidths[6]}} text-right">{{data.sumATM | currency:'EUR':'symbol':'1.2-2'}}</div>
        </div>
        <div class="row cash-position font-weight-bolder mt-3">
            <div class="col-1">Saldo:</div>
            <div class="col-11">{{data.newCashPosition | currency:'EUR':'symbol':'1.2-2'}}</div>
        </div>
    </section>
</div>
