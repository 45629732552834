import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private renderer: Renderer2;
    private readonly htmlElement: HTMLElement;
    private readonly overlayElement: HTMLElement;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.htmlElement = document.getElementsByTagName('html')[0];
        this.overlayElement = document.getElementById('loading-overlay');
    }

    public showLoadingScreen(): void {
        if (screen) {
            this.renderer.removeClass(this.overlayElement, 'd-none');
            this.renderer.addClass(this.overlayElement, 'd-flex');

            this.renderer.removeClass(this.htmlElement, 'cdk-global-scrollblock');
        }
    }

    public hideLoadingScreen(): void {
        const screen = document.getElementById('loading-overlay');
        if (screen) {
            this.renderer.removeClass(this.overlayElement, 'd-flex');
            this.renderer.addClass(this.overlayElement, 'd-none');

            this.renderer.addClass(this.htmlElement, 'cdk-global-scrollblock');
        }
    }
}
