import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChipListService {
    private renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public activateEditMode(event: any, chip: HTMLDivElement): void {
        event.stopPropagation();

        const textElWrapper = chip.querySelector('.text-wrapper');
        this.renderer.addClass(textElWrapper, 'is-editing');

        const textEl = chip.querySelector('.text-wrapper .text-element');
        this.renderer.addClass(textEl, 'd-none');

        const inputEl = chip.querySelector('.option .text-wrapper input');
        this.renderer.removeClass(inputEl, 'd-none');

        const editIcon = chip.querySelector('.option .controls .fa-edit');
        this.renderer.addClass(editIcon, 'd-none');

        const checkIcon = chip.querySelector('.option .controls .fa-check');
        this.renderer.removeClass(checkIcon, 'd-none');
    }

    public deactivateEditMode(chip: HTMLDivElement): void {
        const textElWrapper = chip.querySelector('.text-wrapper');
        this.renderer.removeClass(textElWrapper, 'is-editing');

        const textEl = chip.querySelector('.text-wrapper .text-element');
        this.renderer.removeClass(textEl, 'd-none');

        const inputEl = chip.querySelector('.option .text-wrapper input');
        this.renderer.addClass(inputEl, 'd-none');

        const editIcon = chip.querySelector('.option .controls .fa-edit');
        this.renderer.removeClass(editIcon, 'd-none');

        const checkIcon = chip.querySelector('.option .controls .fa-check');
        this.renderer.addClass(checkIcon, 'd-none');
    }

    public setTextElement(chip: HTMLDivElement, text: string): void {
        const textEl = chip.querySelector('.text-wrapper .text-element');
        this.renderer.setProperty(textEl, 'innerHTML', text);
    }


}
