import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageFullScreenComponent} from '../../dialogs/image-full-screen/image-full-screen.component';
import {MediaEntryModel} from '../../shared/models/media-entry.model';
import {MediaCentreMode} from '../media-centre.component';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../../shared/services/http/crud';
import {Subscription} from 'rxjs';
import * as fromMediaMediaActions from '../store/media.actions';

@Component({
    selector: 'app-media-thumb',
    templateUrl: './media-thumb.component.html',
    styleUrls: ['./media-thumb.component.scss']
})
export class MediaThumbComponent implements OnDestroy {
    @Input() public media: MediaEntryModel;
    @Input() public mode: MediaCentreMode;
    @Input() public entriesPerPage = -1;
    @Input() public pageIndex = -1;
    @Input() public isAnimalImage = true;
    @Input() public avatarId = null;
    @Output() public thumbRemovedEvent = new EventEmitter<MediaEntryModel>();
    @Output() public toggleAvatarEvent = new EventEmitter<number>();
    public MediaCenterMode = MediaCentreMode;

    private actionsSub: Subscription;

    constructor(private dialog: MatDialog,
                private actions$: Actions) {
    }

    ngOnDestroy(): void {
        if (this.actionsSub) {
            this.actionsSub.unsubscribe();
        }
    }

    public openInFullscreen(): void {
        let dialogRef: MatDialogRef<ImageFullScreenComponent> = null;
        if (this.mode === MediaCentreMode.LOAD_IMAGES) {
            dialogRef = this.dialog.open(ImageFullScreenComponent, {
                width: '95%',
                height: '800px',
                panelClass: ['component-wrapper', 'no-max-width'],
                data: {
                    id: this.media.id,
                    entriesPerPage: this.entriesPerPage,
                    pageIndex: this.pageIndex
                }
            });
        }

        this.actionsSub = this.actions$.pipe(
            ofType(fromMediaMediaActions.SET_MEDIA)
        ).subscribe((mediaState: fromMediaMediaActions.SetMedia) => {
            const crudOperation = mediaState.payload.crud;
            if ((crudOperation === CRUD.UPDATE || crudOperation === CRUD.DELETE)
                && dialogRef !== null) {
                dialogRef.close();
            }
        });
    }

    public removeImage(): void {
        this.thumbRemovedEvent.emit(this.media);
    }

    public toggleAvatar(): void {
        this.toggleAvatarEvent.emit(this.media.id);
    }
}
