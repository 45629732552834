import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as OptionsActions from './options.actions';

export interface State {
    options: DictionaryEntryModel[];
}

const initialState: State = {
    options: []
};

export function optionsReducer(state = initialState, action: OptionsActions.OptionsActions): State {
    switch (action.type) {
        case OptionsActions.ADD_OPTION:
            return {
                ...state,
                options: [...state.options, action.payload.option]
            };
        case OptionsActions.UPDATE_OPTION:
            const updatedOption = {
                ...state.options.find(option => option.id === action.payload.option.id),
                ...action.payload.option
            };

            const updatedOptions = [...state.options];
            const arrIndex = updatedOptions.findIndex(element => element.id === updatedOption.id);

            if (updatedOptions[arrIndex]) {
                updatedOptions[arrIndex] = updatedOption;
            } else {
                updatedOptions.push(updatedOption);
            }

            return {
                ...state,
                options: updatedOptions
            };
        case OptionsActions.DELETE_OPTION:
            return {
                ...state,
                options: state.options.filter(option => option.id !== action.payload.optionId)
            };
        case OptionsActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
