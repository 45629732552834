import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../shared/services/http/endpoint.service';
import {CRUD} from '../../shared/services/http/crud';
import {Order} from '../../shared/controls/data-table/ordering';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import * as fromPersonActions from './persons.actions';
import {isArray} from 'rxjs/internal-compatibility';
import {PersonConverter} from '../../shared/converter/person-converter';
import {LoggingService} from '../../shared/logging/logging.service';
import {handleHTTPError} from '../../shared/error-handling';

@Injectable()
export class PersonEffects {
    @Effect()
    loadPersons$ = this.actions$.pipe(
        ofType(fromPersonActions.LOAD_PERSONS),
        switchMap((personState: fromPersonActions.LoadPersons) => {
            const page = personState.payload.page;
            const size = personState.payload.size;
            const order = personState.payload.order;
            const orderColumn = personState.payload.column;
            const personFilter = personState.payload.personFilter;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (personFilter) {
                params = params.append('filter', 'true');

                if (isArray(personFilter.id)) {
                    personFilter.id.forEach(id => {
                        params = params.append('id', id);
                    });
                } else {
                    params = typeof personFilter.id !== 'undefined' &&
                    personFilter.id !== null &&
                    personFilter.id !== '' ?
                        params.append('id', personFilter.id) : params;
                }

                params = typeof personFilter.firstName !== 'undefined' && personFilter.firstName !== '' ?
                    params.append('first_name', personFilter.firstName) : params;
                params = typeof personFilter.lastName !== 'undefined' && personFilter.lastName !== '' ?
                    params.append('last_name', personFilter.lastName) : params;
                params = typeof personFilter.street !== 'undefined' && personFilter.street !== '' ?
                    params.append('street', personFilter.street) : params;
                params = typeof personFilter.zip !== 'undefined' && personFilter.zip !== '' ?
                    params.append('zip', personFilter.zip) : params;
                params = typeof personFilter.city !== 'undefined' && personFilter.city !== '' ?
                    params.append('city', personFilter.city) : params;
                params = typeof personFilter.birthday !== 'undefined' && personFilter.birthday !== '' ?
                    params.append('birthday', personFilter.birthday) : params;
                params = typeof personFilter.email !== 'undefined' && personFilter.email !== '' ?
                    params.append('email', personFilter.email) : params;
                params = typeof personFilter.proof !== 'undefined' && personFilter.proof !== '' ?
                    params.append('proof', personFilter.proof) : params;
                params = typeof personFilter.issuer !== 'undefined' && personFilter.issuer !== '' ?
                    params.append('issuer', personFilter.issuer) : params;
            }

            return this.endpointService.persons(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params});
                }),
                map(response => {
                    const totalElements = response.data.pagination.totalElements;
                    const lastPage = response.data.pagination.pages;
                    const values = response.data.data;
                    const mappedPersonArr: PersonEntryModel[] = values.map(person => {
                        return PersonConverter.toPersonEntryModel(person);
                    });

                    return new fromPersonActions.SetPersons({
                        persons: mappedPersonArr,
                        totalElements,
                        crud: personState.payload.crud,
                        lastPage
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addPerson$ = this.actions$.pipe(
        ofType(fromPersonActions.ADD_PERSON),
        switchMap((personState: fromPersonActions.AddPerson) => {
            return this.endpointService.persons(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint, PersonConverter.toHTTP(personState.payload.person)
                        ).pipe(
                            map(() => {
                                return new fromPersonActions.LoadPersons({
                                    crud: CRUD.CREATE,
                                    order: personState.payload.order,
                                    column: personState.payload.column,
                                    size: personState.payload.size,
                                    page: personState.payload.page
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updatePerson$ = this.actions$.pipe(
        ofType(fromPersonActions.UPDATE_PERSON),
        switchMap((personState: fromPersonActions.UpdatePerson) => {
            return this.endpointService.persons(HttpMethod.PUT, personState.payload.person.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint, PersonConverter.toHTTP(personState.payload.person)
                        ).pipe(
                            map(() => {
                                return new fromPersonActions.LoadPersons({
                                    crud: CRUD.UPDATE,
                                    order: personState.payload.order,
                                    column: personState.payload.column,
                                    size: personState.payload.size,
                                    page: personState.payload.page,
                                    personFilter: personState.payload.personFilter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deletePerson$ = this.actions$.pipe(
        ofType(fromPersonActions.DELETE_PERSON),
        switchMap((personState: fromPersonActions.DeletePerson) => {
            return this.endpointService.persons(HttpMethod.DELETE, personState.payload.personId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromPersonActions.LoadPersons({
                                    crud: CRUD.DELETE,
                                    order: personState.payload.order,
                                    column: personState.payload.column,
                                    size: personState.payload.size,
                                    page: personState.payload.page,
                                    personFilter: personState.payload.personFilter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {
    }

    private handleError = (errorRes: any): Observable<fromPersonActions.HttpFail> => {
        return handleHTTPError(errorRes, fromPersonActions, 'Personen', this.loggingService);
    };
}
