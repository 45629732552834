import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import {CRUD} from '../../../shared/services/http/crud';
import * as fromMissingAnimalActions from './missing.actions';
import {isArray} from 'rxjs/internal-compatibility';
import {MissingAnimalActionConverter} from '../../../shared/converter/missing-animal-converter';
import {Order} from '../../../shared/controls/data-table/ordering';
import {MissingAnimalEntryModel} from '../../../shared/models/animal/missing-animal-entry.model';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class MissingAnimalsEffects {
    @Effect()
    loadMissingAnimals$ = this.actions$.pipe(
        ofType(fromMissingAnimalActions.LOAD_MISSING_ANIMALS),
        switchMap((missingAnimalsState: fromMissingAnimalActions.LoadMissingAnimals) => {
            const missingAnimalFilter = missingAnimalsState.payload.filter;
            const page = missingAnimalsState.payload.page;
            const size = missingAnimalsState.payload.size;
            const order = missingAnimalsState.payload.order;
            const orderColumn = missingAnimalsState.payload.column;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (missingAnimalFilter) {
                params = params.append('filter', 'true');
                params = typeof missingAnimalFilter.raceId !== 'undefined' && missingAnimalFilter.raceId !== '' ?
                    params.append('race_id', missingAnimalFilter.raceId) : params;

                if (typeof missingAnimalFilter.raceIds !== 'undefined' &&
                    missingAnimalFilter.raceIds !== '' &&
                    isArray(missingAnimalFilter.raceIds)) {
                    missingAnimalFilter.raceIds.forEach(id => {
                        params = params.append('race_id', id);
                    });
                }

                params = typeof missingAnimalFilter.missingSince !== 'undefined' && missingAnimalFilter.missingSince !== '' ?
                    params.append('missing_since', missingAnimalFilter.missingSince) : params;
                params = typeof missingAnimalFilter.missingStreet !== 'undefined' && missingAnimalFilter.missingStreet !== '' ?
                    params.append('missing_street', missingAnimalFilter.missingStreet) : params;
                params = typeof missingAnimalFilter.missingZip !== 'undefined' && missingAnimalFilter.missingZip !== '' ?
                    params.append('missing_zip', missingAnimalFilter.missingZip) : params;
                params = typeof missingAnimalFilter.missingCity !== 'undefined' && missingAnimalFilter.missingCity !== '' ?
                    params.append('missing_city', missingAnimalFilter.missingCity) : params;
                params = typeof missingAnimalFilter.radius !== 'undefined' && missingAnimalFilter.radius !== '' ?
                    params.append('radius', missingAnimalFilter.radius) : params;
                params = typeof missingAnimalFilter.race !== 'undefined' && missingAnimalFilter.race !== '' ?
                    params.append('race', missingAnimalFilter.race) : params;
                params = typeof missingAnimalFilter.species !== 'undefined' && missingAnimalFilter.species !== '' ?
                    params.append('species', missingAnimalFilter.species) : params;
                params = typeof missingAnimalFilter.name !== 'undefined' && missingAnimalFilter.name !== '' ?
                    params.append('name', missingAnimalFilter.name) : params;
                params = typeof missingAnimalFilter.owner !== 'undefined' && missingAnimalFilter.owner !== '' ?
                    params.append('owner_name', missingAnimalFilter.owner) : params;
                params = typeof missingAnimalFilter.characteristics !== 'undefined' && missingAnimalFilter.characteristics !== '' ?
                    params.append('characteristic', missingAnimalFilter.characteristics) : params;
                params = typeof missingAnimalFilter.additionalInfo !== 'undefined' && missingAnimalFilter.additionalInfo !== '' ?
                    params.append('missing_additional_info', missingAnimalFilter.additionalInfo) : params;
            }

            return this.endpointService.missingAnimals(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params}).pipe(
                            map(response => response.data)
                        );
                }),
                map(response => {
                    const totalElements = response.pagination.totalElements;
                    const missingAnimalModels = response.data.map(animal => {
                        const mappedAnimal = MissingAnimalActionConverter.toMissingAnimalEntryModel(animal);
                        return mappedAnimal;
                    });

                    return new fromMissingAnimalActions.SetMissingAnimals({
                        missingAnimals: missingAnimalModels,
                        operation: missingAnimalsState.payload.crud,
                        totalElements,
                        filter: missingAnimalsState.payload.filter
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addMissingAnimal$ = this.actions$.pipe(
        ofType(fromMissingAnimalActions.ADD_MISSING_ANIMAL),
        switchMap((missingAnimalState: fromMissingAnimalActions.AddMissingAnimal) => {
            return this.endpointService.missingAnimals(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint, MissingAnimalActionConverter.toHTTP(missingAnimalState.payload.newModel)).pipe(
                            map(() => {
                                return new fromMissingAnimalActions.LoadMissingAnimals({
                                    crud: CRUD.CREATE,
                                    size: missingAnimalState.payload.size,
                                    page: missingAnimalState.payload.page,
                                    order: missingAnimalState.payload.order,
                                    column: missingAnimalState.payload.column,
                                    filter: missingAnimalState.payload.filter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateMissingAnimal$ = this.actions$.pipe(
        ofType(fromMissingAnimalActions.UPDATE_MISSING_ANIMAL),
        switchMap((missingAnimalState: fromMissingAnimalActions.UpdateMissingAnimal) => {
            return this.endpointService.missingAnimals(HttpMethod.PUT, missingAnimalState.payload.updatedModel.id)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        const raceId = missingAnimalState.payload.filter && missingAnimalState.payload.filter.raceId ?
                            missingAnimalState.payload.filter.raceId : null;
                        return this.http
                            .put<any>(endpoint, MissingAnimalActionConverter.toHTTP(missingAnimalState.payload.updatedModel)).pipe(
                                map(() => {
                                    return new fromMissingAnimalActions.LoadMissingAnimals({
                                        crud: CRUD.UPDATE,
                                        size: missingAnimalState.payload.size,
                                        page: missingAnimalState.payload.page,
                                        order: missingAnimalState.payload.order,
                                        column: missingAnimalState.payload.column,
                                        filter: missingAnimalState.payload.filter
                                    });
                                }),
                                catchError(error => {
                                    return this.handleError(error);
                                })
                            );
                    })
                );
        })
    );

    @Effect()
    deleteMissingAnimal$ = this.actions$.pipe(
        ofType(fromMissingAnimalActions.DELETE_MISSING_ANIMAL),
        switchMap((missingAnimalState: fromMissingAnimalActions.DeleteMissingAnimal) => {
            return this.endpointService.missingAnimals(HttpMethod.DELETE, missingAnimalState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromMissingAnimalActions.LoadMissingAnimals({
                                    crud: CRUD.DELETE,
                                    size: missingAnimalState.payload.size,
                                    page: missingAnimalState.payload.page,
                                    order: missingAnimalState.payload.order,
                                    column: missingAnimalState.payload.column,
                                    filter: missingAnimalState.payload.filter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    matchMissingAnimal$ = this.actions$.pipe(
        ofType(fromMissingAnimalActions.MATCH_MISSING_ANIMAL),
        switchMap((missingAnimalState: fromMissingAnimalActions.MatchMissingAnimal) => {
            return this.endpointService.matchMissingAnimals(missingAnimalState.payload.id, missingAnimalState.payload.radius).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint)
                        .pipe(
                            map((response: any) => {
                                const matchedAnimals = response.data.data.map(entry => {
                                    const mappedMissingAnimal: MissingAnimalEntryModel =
                                        MissingAnimalActionConverter.toMissingAnimalEntryModel(entry);
                                    return mappedMissingAnimal;
                                });
                                return new fromMissingAnimalActions.SetMatchedAnimals(matchedAnimals);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromMissingAnimalActions.HttpFail> => {
        return handleHTTPError(errorRes, fromMissingAnimalActions, 'vermissten Tieren', this.loggingService);
    };

}
