import {Action} from '@ngrx/store';
import {ErrorTypes} from '../../../data-types/error-types';

export const LOAD_AUTOCOMPLETE_OPTIONS = '[Autocomplete] Load Autocomplete Options';
export const SET_AUTOCOMPLETE_OPTIONS = '[Autocomplete] Set Autocomplete Options';
export const HTTP_FAIL = '[Autocomplete] Http Fail';

export class LoadAutoCompleteOptions implements Action {
    readonly type = LOAD_AUTOCOMPLETE_OPTIONS;

    constructor(public payload: { type: string, field: string, value: string }) {
    }

}

export class SetAutoCompleteOptions implements Action {
    readonly type = SET_AUTOCOMPLETE_OPTIONS;

    constructor(public payload: [number, number | string][]) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type AutoCompleteActions =
    LoadAutoCompleteOptions
    | SetAutoCompleteOptions
    | HttpFail;

