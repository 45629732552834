import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserEntryModel} from '../shared/models/user-entry.model';
import {Shelter} from '../shared/constants';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public loggedInUser: BehaviorSubject<UserEntryModel> = new BehaviorSubject(null);
    public httpErrorOccured = false;

    constructor(private store: Store<fromApp.AppState>,
                private router: Router) {

    }

    public getShelter(): Shelter {
        return this.loggedInUser.value.shelter.toLowerCase().includes('linz') ? Shelter.LINZ : Shelter.STEYR;
    }

    /**
     * If session cookie is deleted or not valid anymore, send user to auth page and let him/her log in again.
     */
    public sendLogout(): void {
        this.httpErrorOccured = true;
        this.router.navigate(['/auth']);
    }
}
