<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Pensionstiervertrag</h1>

    <section *ngIf="animals !== null && animals !== undefined" style="overflow:visible !important;" class="mt-4">
        <ng-container *ngFor="let animal of animals">
            <div class="entry d-flex flex-wrap">
                <div class="left-col text-left">
                    <div class="font-weight-bolder">LfNr.: {{animal.id}}</div>
                    <div><span class="font-weight-bolder">Name:</span> {{animal.name}}</div>
                    <div><span class="font-weight-bolder">Tierart:</span> {{animal.species.name}}</div>
                    <div><span class="font-weight-bolder">Geschlecht:</span> {{Gender.getGender(animal.gender)}}</div>
                    <div><span
                            class="font-weight-bolder">Beschreibung:</span> {{characteristicsService.printCharacteristics(animal.characteristics)}}
                    </div>
                </div>

                <div class="right-col text-left">
                    <div class="font-weight-bolder">Chip-Nr.: {{animal.chipNumber}}</div>
                    <div><span class="font-weight-bolder">Rasse:</span> {{animal.race.name}}</div>
                    <div><span
                            class="font-weight-bolder">Kastriert:</span> {{CastrationStatus.getCastrationStatus(animal.castrated)}}
                    </div>
                    <div><span class="font-weight-bolder">Alter:</span> {{Age.printAge(animal.birthday)}}</div>
                </div>
                <div class="horLine w-100 pb-4"></div>
            </div>
        </ng-container>

    </section>

    <section class="mt-3 text-left">

    </section>

    <section *ngIf="person !== null && person !== undefined" class="container mt-3 text-left">
        <div class="row">
            <div id="person" class="col-6">
                <div class="font-weight-bolder">Überbringer:</div>
                <div class="mt-3">
                    <div><span class="font-weight-bolder">PNr.:</span> {{person.id}}</div>
                    <div>{{person.firstName + ' ' + person.lastName}}</div>
                    <div>{{person.street}}</div>
                    <div>{{person.zip}} - {{person.city}}</div>
                    <div><span class="font-weight-bolder">Tel.:</span> {{person.phone}}</div>
                    <div><span class="font-weight-bolder">Geb. Datum:</span> {{person.birthday | date: 'dd.MM.yyyy'}}
                    </div>
                    <div><span class="font-weight-bolder">Nachweis:</span> {{person.proof}}</div>
                    <div><span class="font-weight-bolder">E-Mail:</span> {{person.email}}</div>
                </div>
            </div>

            <div id="owner" *ngIf="owner !== null && owner !== undefined && person.id !== owner.id" class="col-5">
                <div class="font-weight-bolder">Besitzer:</div>
                <div class="mt-3">
                    <div><span class="font-weight-bolder">PNr.:</span> {{owner.id}}</div>
                    <div>{{owner.firstName + ' ' + owner.lastName}}</div>
                    <div>{{owner.street}}</div>
                    <div>{{owner.zip}} - {{owner.city}}</div>
                    <div><span class="font-weight-bolder">Tel.:</span> {{owner.phone}}</div>
                    <div><span
                            class="font-weight-bolder">Geb. Datum:</span> {{owner.birthday | date: 'dd.MM.yyyy'}}
                    </div>
                    <div><span class="font-weight-bolder">Nachweis:</span> {{owner.proof}}</div>
                    <div><span class="font-weight-bolder">E-Mail:</span> {{owner.email}}</div>
                </div>
            </div>
        </div>
    </section>

    <section class="mt-5 text-left">
        <div>Aufenthalt der Tiere von {{data.stayFrom | date: 'dd.MM.yyyy'}} voraussichtlich
            bis {{data.stayUntil | date: 'dd.MM.yyyy'}}</div>
    </section>

    <section class="mt-3 text-left">
        <div><span class="font-weight-bolder">Anzahlung</span> {{data.deposit |  currency:'EUR':'symbol':'1.2-2'}}</div>
    </section>

    <section class="mt-2 text-left">
        Der Besitzer bzw. Überbringer verpflichtet sich, bei einer evtl. Verlängerung der Verwahrdauer, vor Ablauf
        dieser Frist das {{shelterName}} zu unterrichten. Sollte innerhalb einer Woche nach Ablauf der Frist keine
        Abholung bzw. kein Antrag auf Verlängerung dieser Frist erfolgt sein, geht das Tier automatisch in das Eigentum
        des Tierheims {{cityName}} über. Nach Eigentumsübertragung erlöschen somit alle Ansprüche des Vorbesitzers
        gegenüber dem
        {{shelterName}}. Das {{shelterName}} verpflichtet sich, das Tier während der Verwahrungsdauer gut zu pflegen und
        medizinisch zu versorgen. Der Besitzer bzw. Überbringer bestätigt, dass er das {{shelterName}} über alle
        möglichen
        Erkrankungen des Tieres wahrheitsgemäß unterrichtet hat. Der Besitzer bzw. Überbringer übernimmt im Falle, dass
        das Tier eine tierärztliche Versorgung benötigt, sämtliche anfallenden Kosten.
    </section>

    <div class="text text-left h-100" style="margin-top: 15px">
        <section class="disclaimer small mt-4 text-left">
            Information zum Datenschutz: <br />
            Name, Adress- und Kontaktdaten der überbringenden Peron und die Chipnummer des Tieres werden zum Zweck der
            Administration und Dokumentation der Tiere gespeichert. Die Daten werden nicht weitergegeben und mindestens
            drei Jahre – sofern in §21 Tierschutzgesetz nicht anders bestimmt – lang aufbewahrt. Im Rahmen der
            fachlichen Aufsicht dürfen diese Daten von der zuständigen Bezirksverwaltungsbehörde eingesehen werden. Die
            Chipnummer des Tieres kann vom Amt der OÖ Landesregierung, Abteilung Gesundheit eingesehen werden.
        </section>

        <div class="d-flex justify-content-around mt-4 align-items-end">
            <div class="left">
                <div class="mt-5 mb-4">{{cityName}}, am {{data.date | date:'dd.MM.yyyy'}}</div>
            </div>

            <div class="right text-center">
                <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                <div>Unterschrift</div>
            </div>
        </div>
    </div>
</div>
