import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {Order} from '../../shared/controls/data-table/ordering';
import {ActionsService} from '../../actions/actions.service';
import {Subscription} from 'rxjs';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {ActionTypeEntryModel} from '../../shared/models/action-type-entry.model';
import {ActionType, ActionTypesService} from '../store/action-types/action-types.service';

@Component({
    selector: 'app-personalized-action',
    templateUrl: './personalized-action.component.html',
    styleUrls: ['./personalized-action.component.scss']
})
export class PersonalizedActionComponent implements OnInit, OnDestroy {
    public personalizedTransactions: TransactionEntryModel[];
    public ActionType = ActionType;
    public animal: AnimalEntryModel;

    private fetchedActionsSub: Subscription;
    private actionTypes: ActionTypeEntryModel[];
    private actionTypesSub: Subscription;

    constructor(private dialogRef: MatDialogRef<PersonalizedActionComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private actionTypesService: ActionTypesService,
                private actionsService: ActionsService) {
        if (this.data !== null && typeof this.data.animal !== 'undefined') {
            this.animal = this.data.animal;
        }
    }

    ngOnInit(): void {
        this.initActionTypes();
        this.initPersonalizedActions();
    }

    ngOnDestroy(): void {
        if (this.fetchedActionsSub) {
            this.fetchedActionsSub.unsubscribe();
        }

        if (this.actionTypesSub) {
            this.actionTypesSub.unsubscribe();
        }
    }

    public getActionType(id): ActionTypeEntryModel {
        return this.actionTypes.find(at => at.id === id);
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public getActionTypeText(actionTypeId: number): string {
        const actionType = this.getActionType(actionTypeId);
        if (!actionType) {
            return '';
        }
        return this.actionTypesService.getActionTypeText(actionType.name);
    }

    public showPerson(transaction: TransactionEntryModel): boolean {
        // Don't show person if animal is dead and it used to be an acquisition animal.
        if (this.getActionType(transaction.actionTypeId).name === ActionType.HIMMELFAHRT.key &&
            this.personalizedTransactions.length > 1) {
            const prevTransaction = this.personalizedTransactions.slice(1, 2).pop();

            const actionType = this.getActionType(prevTransaction.actionTypeId);
            return !actionType.isAcquisition;
        }
        return true;
    }

    private initActionTypes(): void {
        this.actionTypesSub = this.actionTypesService.fetchActionTypes()
            .subscribe(actionTypes => {
                this.actionTypes = actionTypes;
            });
    }

    private initPersonalizedActions(): void {
        const filterModel = {
            animalId: this.animal.id
        };

        this.fetchedActionsSub = this.actionsService.fetchActions(-1, 1, Order.DESC, 'date_performed', filterModel, false)
            .subscribe((transactions: TransactionEntryModel[]) => {
                this.personalizedTransactions = transactions;
            });
    }
}
