import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {SelectBoxService} from '../../../shared/controls/select-box/select-box.service';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import {MatOption} from '@angular/material/core';
import {DialogType} from '../../../dialogs/dialog-type';
import {Order} from '../../../shared/controls/data-table/ordering';
import {AbstractControl} from '@angular/forms';
import * as fromApp from '../../../store/app.reducer';
import * as fromRacesActions from '../../../animals/store/races/race.actions';
import * as fromAnimalActions from '../../../animals/store/animals/animal.actions';
import {Tuple, TupleHelper} from '../../../shared/data-types/tuple';
import {LoadingService} from '../../../shared/services/loading/loading.service';
import {FdRaceService} from '../fd-races/fd-race.service';

@Injectable({providedIn: 'root'})
export class RaceService implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private simpleDialogType = new DialogType();
    private errorEmerged = false;

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private loadingService: LoadingService,
                private snackbarService: SnackbarService,
                private fdRacesService: FdRaceService,
                private selectBoxService: SelectBoxService) {
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public fetchRaces(force = false): Observable<DropdownOption[]> {
        return this.store.select('raceList').pipe(
            map(raceState => {
                return raceState.races;
            }),
            switchMap(races => {
                if (races.length === 0 || force === true) {
                    this.store.dispatch(new fromRacesActions.LoadRaces({operation: CRUD.READ}));
                    return this.actions$.pipe(
                        ofType(fromRacesActions.SET_RACES),
                        map((raceState: fromRacesActions.SetRaces) => {
                            return raceState.payload.races;
                        })
                    );
                } else {
                    return of(races);
                }
            }),
            map((races: DictionaryEntryModel[]) => {
                return races
                    .map((element: DictionaryEntryModel) => {
                        return {
                            value: SelectBoxUtility.getOptionsValue(element.name),
                            viewValue: element.name,
                            meta: [
                                {key: 'id', value: element.id.toString()},
                                {key: 'speciesId', value: TupleHelper.getValue(element.meta, 'speciesId')}
                            ]
                        } as DropdownOption;
                    })
                    .sort(this.selectBoxService.sortCallback);
            }),
            takeUntil(this.destroy$)
        );
    }

    public addRace(event: MouseEvent,
                   allFDRaces: DropdownOption[],
                   allSpecies: DropdownOption[] = null,
                   singleSelectRace: AbstractControl,
                   selectedSpeciesId?: string): void {
        event.stopPropagation();
        const meta: Tuple[] = [
            {key: 'allOptions', value: allSpecies},
            {key: 'selectedSpeciesId', value: selectedSpeciesId},
            {key: 'allFDRaces', value: allFDRaces}
        ];
        const dialogRef = this.selectBoxService.openAddDialog('Tierrasse', meta);

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== false) {
                const speciesId = +TupleHelper.getValue(result.species.meta, 'id');
                const newModel: DictionaryEntryModel = {
                    id: -1,
                    name: result.name,
                    meta: [{key: 'speciesId', value: speciesId}]
                };

                this.store.dispatch(new fromRacesActions.AddRace(newModel));
                this.actions$.pipe(
                    ofType(fromRacesActions.SET_RACES),
                    take(1)
                ).subscribe((raceState: fromRacesActions.SetRaces) => {
                    if (raceState.payload.operation === CRUD.CREATE) {
                        singleSelectRace.setValue(SelectBoxUtility.getOptionsValue(result.name));
                        /// Send race to Fundtierdatenbank
                        this.fdRacesService.addFDRace(raceState.payload.alteredRace, result.fdRace);

                        setTimeout(() => this.handleRequestSuccess(CRUD.CREATE, 'Tierrasse', result.name));
                    }
                });
            }
        });
    }

    public updateRace(event: MouseEvent,
                      matOption: MatOption,
                      race: DropdownOption,
                      updatedRace: HTMLInputElement,
                      pageIndex: number,
                      entriesPerPage: number,
                      order: Order,
                      orderedColumn: string,
                      raceControl: AbstractControl): void {
        event.stopPropagation();

        if (updatedRace.value === '' || updatedRace.value === race.viewValue) {
            updatedRace.value = race.viewValue;
            this.selectBoxService.disableEditMode(matOption);
            return;
        }

        const updatedModel: DictionaryEntryModel = {
            id: +race.meta.find(tuple => tuple.key === 'id').value,
            name: updatedRace.value
        };
        this.selectBoxService.update(matOption, new fromRacesActions.UpdateRace(updatedModel));
        this.actions$.pipe(
            ofType(fromRacesActions.SET_RACES),
            take(1)
        ).subscribe(() => {
            if (!this.errorEmerged && raceControl) {
                raceControl.setValue(SelectBoxUtility.getOptionsValue(updatedRace.value));
                this.store.dispatch(new fromAnimalActions.LoadAnimals({
                    crud: CRUD.READ,
                    page: pageIndex,
                    size: entriesPerPage,
                    order,
                    column: orderedColumn,
                    usePagination: true
                }));
                setTimeout(() => this.handleRequestSuccess(CRUD.UPDATE, 'Tierrasse', updatedRace.value));
            }
            this.errorEmerged = false;
        });
    }

    public deleteRace(event: MouseEvent,
                      race: DropdownOption,
                      allRaces: DropdownOption[],
                      pageIndex: number,
                      entriesPerPage: number,
                      order: Order,
                      orderedColumn: string,
                      raceControl: AbstractControl): void {
        event.stopPropagation();

        const remainingOptions = allRaces.filter(option => option.value !== race.value);
        const dialogRef = this.selectBoxService.delete('Tierrasse',
            race.viewValue,
            this.simpleDialogType.DELETE_RACE,
            remainingOptions);

        dialogRef.afterClosed().subscribe(result => {
            const newRace = result.newSpeciesOrRace;
            if (typeof newRace !== 'undefined' && newRace !== false) {
                const updateId = +newRace.meta.find(tuple => tuple.key === 'id').value;
                const deleteId = +race.meta.find(tuple => tuple.key === 'id').value;

                this.loadingService.showLoadingScreen();
                this.store.dispatch(new fromRacesActions.DeleteRace({deleteId, updateId}));
                this.actions$.pipe(
                    ofType(fromRacesActions.SET_RACES),
                    take(1),
                    tap(() => {
                        return this.store.dispatch(new fromRacesActions.LoadRaces({operation: CRUD.READ}));
                    })
                ).subscribe(() => {
                    this.loadingService.hideLoadingScreen();
                    if (!this.errorEmerged && raceControl) {
                        raceControl.setValue(SelectBoxUtility.getOptionsValue(newRace.value));
                        this.store.dispatch(new fromAnimalActions.LoadAnimals({
                            crud: CRUD.READ,
                            page: pageIndex,
                            size: entriesPerPage,
                            order,
                            column: orderedColumn,
                            usePagination: true
                        }));
                        setTimeout(() => this.handleRequestSuccess(CRUD.DELETE, 'Tierrasse', race.viewValue));
                    }
                    this.errorEmerged = false;
                });
            }
        });
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.errorEmerged = true;
        this.store.dispatch(new fromRacesActions.LoadRaces({operation: CRUD.READ}));
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
