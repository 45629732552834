import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {RueckgabePensionstierCostInfo} from './rueckgabe-pensionstier.component';
import {PersonEntryModel} from '../../../shared/models/person-entry.model';

@Injectable()
export class RueckgabePensionstierService {
    public animalInTableSelected: Subject<number> = new Subject();
    public costInfoUpdated: Subject<RueckgabePensionstierCostInfo[]> = new Subject<RueckgabePensionstierCostInfo[]>();
    public personsFetched: BehaviorSubject<PersonEntryModel[]> = new BehaviorSubject(null);
    public formResetted: Subject<boolean> = new Subject();
}
