import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import {map, switchMap, take} from 'rxjs/operators';
import * as fromZipCityActions from '../zip-city-search/store/zip-city.actions';
import {Actions, ofType} from '@ngrx/effects';
import {DropdownOption} from '../../data-types/dropdown-option';
import {SelectBoxUtility} from '../../controls/select-box/select-box-utility';
import {TupleHelper} from '../../data-types/tuple';

@Injectable({
    providedIn: 'root'
})
export class ZipCitySearchService {
    public values: DropdownOption[] = [];

    private type: ZipCity = null;

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions) {
    }

    public valueChanged(value: any, type: ZipCity): void {
        if (!value || isNaN(value)) {
            return;
        }

        this.type = type;
        this.store.select('zipCityList').pipe(
            take(1),
            map(zipCityState => {
                return zipCityState.zipCities;
            }),
            switchMap(_ => {
                this.store.dispatch(new fromZipCityActions.LoadZipCities({
                    type,
                    value
                }));
                return this.actions$.pipe(
                    ofType(fromZipCityActions.SET_ZIP_CITIES),
                    take(1),
                    map((actionState: fromZipCityActions.SetZipCities) => {
                        if (actionState.payload.length > 0) {
                            this.values = actionState.payload.map(responseValue => {
                                const fullAddress = responseValue.postal_code + ' - ' + responseValue.city;
                                const option: DropdownOption = {
                                    value: SelectBoxUtility.getOptionsValue(fullAddress),
                                    viewValue: fullAddress,
                                    meta: [
                                        {key: 'city', value: responseValue.city},
                                        {key: 'zip', value: responseValue.postal_code}
                                    ]
                                };
                                return option;
                            });
                        }
                    })
                );
            })
        ).subscribe();
    }

    public displayFn(option: DropdownOption): string {
        if (!option) {
            return '';
        }

        if (typeof option === 'string') {
            return option;
        }

        if (this.type !== null && 'meta' in option) {
            if (this.type === ZipCity.CITY) {
                return TupleHelper.getValue(option.meta, 'city');
            } else {
                return TupleHelper.getValue(option.meta, 'zip');
            }
        } else {
            return option.viewValue;
        }


    }
}

export enum ZipCity {
    CITY,
    ZIP
}
