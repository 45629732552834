import {Component, OnInit} from '@angular/core';
import {PersonEntryModel} from '../../../shared/models/person-entry.model';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {PrintableComponent} from '../../PrintableComponent';
import {Age, CastrationStatus, Constants, Gender, Shelter} from '../../../shared/constants';
import {CharacteristicService} from '../../../animals/store/characteristics/characteristic.service';
import {PrintingTemplate} from '../TemplateTypes';

@Component({
    selector: 'app-handover-contract',
    templateUrl: './handover-contract.component.html',
    styleUrls: ['./handover-contract.component.scss']
})
export class HandoverContractComponent implements PrintableComponent, OnInit {
    public species: string;
    public person: PersonEntryModel;
    public animals: AnimalEntryModel[];
    public owner: PersonEntryModel;
    public hasFence: boolean;
    public hasGarden: boolean;
    public otherAnimalsInHousehold: string;
    public animalPlace: boolean;
    public residenceStreet: string;
    public residenceZip: string;
    public residenceCity: string;
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public Age = Age;
    public visible = false;
    public printingTemplate = PrintingTemplate.HandOver;
    public hideHeader = false;
    public shelter: Shelter;
    public date: string;

    public shelterName = '';
    public address = '';
    public zipAndCity = '';
    public cityName = '';

    constructor(public characteristicsService: CharacteristicService) {
    }

    ngOnInit(): void {
        if (this.shelter === Shelter.LINZ) {
            this.shelterName = Constants.LinzShelterName;
            this.address = Constants.LinzAddress;
            this.zipAndCity = Constants.LinzZipAndCity;
            this.cityName = Constants.LinzCityName;
        } else {
            this.shelterName = Constants.SteyrShelterName;
            this.address = Constants.SteyrAddress;
            this.zipAndCity = Constants.SteyrZipAndCity;
            this.cityName = Constants.SteyrCityName;
        }
    }
}
