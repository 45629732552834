import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AttributesComponent} from '../attributes.component';
import {CRUD} from '../../shared/services/http/crud';
import {ofType} from '@ngrx/effects';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {DictionaryEntryModel} from '../../shared/models/dictionary-entry.model';
import * as fromOptionsActions from '../store/options/options.actions';

@Component({
    selector: 'app-attributes-option',
    templateUrl: './attributes-option.component.html',
    styleUrls: ['./attributes-option.component.scss']
})
export class AttributesOptionComponent extends AttributesComponent implements OnInit, OnDestroy {
    @Input() option: DictionaryEntryModel;
    @Input() speciesGroupName: string;
    @Input() attributeId: number;
    @Input() addIcon: HTMLAnchorElement;
    @Input() addInput: HTMLInputElement;
    @ViewChild('chip') chip: ElementRef;


    ngOnInit(): void {
        super.ngOnInit(true);

        this.actions$.pipe(
            ofType(fromOptionsActions.FINISHED_CRUD)
        ).subscribe(() => {
            if (this.crudOperation === CRUD.CREATE || this.crudOperation === CRUD.UPDATE || this.crudOperation === CRUD.DELETE) {
                setTimeout(() => {
                    const snackBarRef = this.attributesService.handleRequestSuccess(this.crudOperation, 'Option', this.identifier);
                    snackBarRef.afterOpened().subscribe(() => {
                        this.crudOperation = CRUD.NONE;
                    });
                });
                this.attributesService.executeFetchRequest(this.speciesGroupName);
            }
        });

        this.addIcon.addEventListener('click', () => {
            this.addOption();
        });

        this.addInput.addEventListener('keydown', (event: KeyboardEvent) => {
            this.renderer.removeClass(this.addIcon, 'd-none');
            if (event.key === 'Enter') {
                this.addOption();
            }
        });
    }

    ngOnDestroy(): void {
        this.crudOperation = CRUD.NONE;
    }

    public addOption(): void {
        const value = this.addInput.value;
        if (value === '') {
            return;
        }

        this.attributesService.addOption(value, this.attributeId);
        this.crudOperation = CRUD.CREATE;
        this.identifier = value;
    }

    public updateOption(updatedVal: HTMLInputElement): void {
        if (updatedVal.value === '') {
            updatedVal.value = this.option.name;
            return;
        }

        const htmlChip = this.chip.nativeElement as HTMLDivElement;
        this.chipListService.deactivateEditMode(htmlChip);
        this.attributesService.updateOption(this.option, updatedVal.value, this.attributeId);
        this.crudOperation = CRUD.UPDATE;
        this.identifier = updatedVal.value;
    }

    public deleteOption(entry: DictionaryEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.dialogType.DELETE_GENERIC,
                entity: 'Option',
                identifier: entry.name
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.attributesService.deleteOption(entry.id, this.attributeId);
                this.crudOperation = CRUD.DELETE;
                this.identifier = entry.name;
            }
        });
    }
}
