import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {ActionTypeEntryModel} from '../../shared/models/action-type-entry.model';
import {BookingEntryModel} from '../../shared/models/pos/booking-entry.model';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {SupervisionEntryModel} from '../../shared/models/supervision-entry.model';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import {MediaEntryModel} from '../../shared/models/media-entry.model';

export enum PrintingTemplate {
    DataSheet,
    AcquisitionAndGiveAway,
    CareSpace,
    HandOver,
    ChipContract,
    ActionSpecific,
    Cashbook,
    IncomeOutcome,
    Supervision
}

export type PrintingDataAnimal = {
    animals: AnimalEntryModel[];
    person?: PersonEntryModel,
    date?: string,
    actionType?: ActionTypeEntryModel,
    owner?: PersonEntryModel,
    hasFence?: boolean,
    hasGarden?: boolean,
    otherAnimalsInHousehold?: string,
    animalPlace?: boolean,
    residenceStreet?: string,
    residenceZip?: string,
    residenceCity?: string,
    giveAwayReason?: string,
    takingOverReason?: string,
    howAnimalWasFound?: string,
    streetFound?: string,
    zipFound?: string,
    cityFound?: string,
    stayFrom?: string,
    stayUntil?: string,
    deposit?: number,
    depositTotal?: number,
    costsPerDayAccumulated?: number,
    additionalCostsAccumulated?: number,
    avatars?: MediaEntryModel[]
};

export type PrintingIncomeOutcome = {
    date: string;
    bookingEntries: BookingEntryModel[]
};

export type PrintingDataCashbook = {
    cashier: UserEntryModel,
    from: string,
    to: string,
    cashbookEntries: any[],
    grouped: boolean,
    sumIncome: number,
    sumOutcome: number,
    sumDelta: number,
    sumATM: number,
    cashPosition: number,
    newCashPosition: number
};

export type PrintingDataAction = {
    actions: TransactionEntryModel[],
    supervisions?: SupervisionEntryModel[]
};
