import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-abgabe',
    templateUrl: './abgabe.component.html',
    styleUrls: ['./abgabe.component.scss']
})
export class AbgabeComponent implements OnInit {
    @Input() public acquisitionGiveAwayForm: FormGroup;

    ngOnInit(): void {
        this.acquisitionGiveAwayForm.addControl('giveAwayReason', new FormControl(''));
    }
}
