import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragDropDirective} from './image-handling/upload/drag-drop.directive';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        DragDropDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DragDropDirective,
        MatButtonModule
    ]
})
export class SharedModule {
}
