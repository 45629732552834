import {AttributeEntryModel} from '../../../shared/models/attributes/attribute-entry.model';
import * as AttributesActions from './attributes.actions';

export interface State {
    attributes: AttributeEntryModel[];
}

const initialState: State = {
    attributes: []
};

export function attributesReducer(state = initialState, action: AttributesActions.AttributesActions): State {
    switch (action.type) {
        case AttributesActions.SET_ATTRIBUTES:
            return {
                ...state,
                attributes: [...action.payload]
            };
        case AttributesActions.ADD_ATTRIBUTE:
            return {
                ...state,
                attributes: [...state.attributes, action.payload]
            };
        case AttributesActions.UPDATE_ATTRIBUTE:
            const updatedAttribute = {
                ...state.attributes.find(attribute => attribute.id === action.payload.id),
                ...action.payload
            };

            const updatedAttributes = [...state.attributes];
            const arrIndex = updatedAttributes.findIndex(element => element.id === updatedAttribute.id);
            updatedAttributes[arrIndex] = updatedAttribute;

            return {
                ...state,
                attributes: updatedAttributes
            };
        case AttributesActions.DELETE_ATTRIBUTE:
            return {
                ...state,
                attributes: state.attributes.filter(attribute => attribute.id !== action.payload.id)
            };
        case AttributesActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
