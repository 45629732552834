import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {CareSpaceEntryModel} from '../../../shared/models/animal/care-space-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_CARE_SPACES = '[CareSpaces] Load CareSpaces';
export const SET_CARE_SPACES = '[CareSpaces] Set CareSpaces';
export const ADD_CARE_SPACE = '[CareSpaces] Add CareSpaces';
export const ADD_CARE_SPACES = '[CareSpaces] Add multiple CareSpaces';
export const UPDATE_CARE_SPACE = '[CareSpaces] Update CareSpace';
export const UPDATE_CARE_SPACES = '[CareSpaces] Update CareSpaces';
export const DELETE_CARE_SPACE = '[CareSpaces] Delete CareSpaces';
export const HTTP_FAIL = '[CareSpaces] Http Fail';

export class LoadCareSpaces implements Action {
    readonly type = LOAD_CARE_SPACES;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        newCareSpaces?: CareSpaceEntryModel[], filter?: any
    }) {
    }
}

export class SetCareSpaces implements Action {
    readonly type = SET_CARE_SPACES;

    constructor(public payload: { careSpaces: CareSpaceEntryModel[], operation: CRUD }) {
    }
}

export class AddCareSpace implements Action {
    readonly type = ADD_CARE_SPACE;

    constructor(public payload: CareSpaceEntryModel) {
    }
}

export class AddCareSpaces implements Action {
    readonly type = ADD_CARE_SPACES;

    constructor(public payload: {
        careSpaces: CareSpaceEntryModel[],
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class UpdateCareSpace implements Action {
    readonly type = UPDATE_CARE_SPACE;

    constructor(public payload: CareSpaceEntryModel) {
    }
}

export class UpdateCareSpaces implements Action {
    readonly type = UPDATE_CARE_SPACES;

    constructor(public payload: {
        careSpaces: CareSpaceEntryModel[],
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class DeleteCareSpace implements Action {
    readonly type = DELETE_CARE_SPACE;

    constructor(public payload: number) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type CareSpaceActions =
    LoadCareSpaces
    | SetCareSpaces
    | AddCareSpace
    | AddCareSpaces
    | UpdateCareSpace
    | UpdateCareSpaces
    | DeleteCareSpace
    | HttpFail;

