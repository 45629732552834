import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as fromApp from '../../../store/app.reducer';
import * as fromAutoCompleteActions from './store/auto-complete.actions';

@Injectable({
    providedIn: 'root'
})
export class AutoCompleteService {

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions) {
    }

    public fetchOptions(type: string, field: string, value: string): Observable<any> {
        return this.store.select('autoCompleteList').pipe(
            take(1),
            switchMap(() => {
                this.store.dispatch(new fromAutoCompleteActions.LoadAutoCompleteOptions({
                    type,
                    field,
                    value
                }));
                return this.actions$.pipe(
                    ofType(fromAutoCompleteActions.SET_AUTOCOMPLETE_OPTIONS),
                    map((autoCompleteState: fromAutoCompleteActions.SetAutoCompleteOptions) => {
                        return autoCompleteState.payload;
                    })
                );
            })
        );
    }
}
