<div [ngClass]="!visible ? 'not-printable' : ''" class="position-relative" style="float: none;">
    <header class="d-flex justify-content-between">
        <div class="left">
            <img src="assets/img/misc/animaldata_logo.png" width="270" height="">
            <div class="mt-1">INTERNATIONALE TIERKENNZEICHNUNGSDATENBANK</div>
            <div>3500 Krems / Donau</div>

            <div class="mt-1">Telefon: +43 (0) 664 166 20 55 FAX: +43 (0) 2732 788 96</div>
        </div>
        <div class="right">
            <img src="assets/img/misc/voek.jpg" width="350">
        </div>
    </header>
    <main>
        <h1 class="mt-2 mb2">Registrierungsantrag</h1>
        <section>
            <table style="width: 100%">
                <tr>
                    <td>
                        <div class="small"><span class="font-weight-bolder">Tierhalter</span> - Familienname</div>
                        <div>{{data.person.lastName}}</div>
                    </td>
                    <td>
                        <div class="small">Vorname</div>
                        <div>{{data.person.firstName}}</div>

                    </td>
                    <td>
                        <div class="small">Titel</div>

                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="small">Adresse</div>
                        <div>{{data.person.street}}</div>
                    </td>
                    <td>
                        <div class="small">E-Mail</div>
                        <div>{{data.person.email}}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="small">PLZ</div>
                        <div>{{data.person.zip}}</div>
                    </td>
                    <td>
                        <div class="small">Ort</div>
                        <div>{{data.person.city}}</div>
                    </td>
                    <td>
                        <div class="small">Land</div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="small">Telefon 1</div>
                        <div>{{data.person.phone}}</div>
                    </td>
                    <td>
                        <div class="small">Telefon 2</div>
                    </td>
                    <td>
                        <div class="small">Fax</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="small">Lichtbildausweis *</div>
                        <div>{{data.person.proof}}</div>
                    </td>
                    <td>
                        <div class="small">Ausweisnummer *</div>
                        <div>{{data.person.proof}}</div>
                    </td>
                    <td>
                        <div class="small">Geburtsdatum *</div>
                        <div>{{data.person.birthday}}</div>
                    </td>
                </tr>
            </table>
        </section>

        <section class="mt-5">
            <table style="width: 100%">
                <tr>
                    <td>
                        <div class="small"><span class="font-weight-bolder">Heimtier</span> - Rasse</div>
                        <div>{{animal.race.name}}</div>
                    </td>
                    <td>
                        <div class="small">Geschlecht</div>
                        <div>Geschlecht: {{Gender.getGender(animal.gender)}}</div>
                    </td>
                    <td>
                        <div class="small">Geburtsdatum</div>
                        <div>{{animal.birthday | date: 'dd.MM.yyyy'}}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="small">Farbe</div>
                        <div>{{characteristicsService.printCharacteristics(animal.characteristics)}}</div>
                    </td>
                    <td>
                        <div class="small">Name</div>
                        <div>{{animal.id + ' - ' + animal.name}}</div>
                    </td>
                    <td>
                        <div class="small">Geburtsland (A, D, HU, CZ, SK...)*</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="small">Mikrochipnummer</div>
                        <div>{{animal.chipNumber}}</div>
                    </td>
                    <td>
                        <div class="small">Haltungsbeginn Datum *</div>
                        {{data.date | date:'dd.MM.yyyy'}}
                    </td>
                    <td>
                        <div class="small">Wunschpasswort: (max. 10 Zeichen) *</div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="small">EU -Heimtierpassnummer</div>
                    </td>
                    <td>
                        <div class="small">Implantationsdatum</div>
                    </td>
                    <td>
                        <div class="small">Implantationsstelle: Ohrgrund links</div>
                    </td>
                </tr>
            </table>
            <div class="mt-3 font-weight-bolder text-center">*) Durch die Angabe der für die amtliche Hunderegistrierung
                erforderlichen Pflichtdaten wird ANIMALDATA.COM
                beauftragt, die Meidung an das amtlichen Hunderegister gemaß § 24a Tierschutzgesetz vorzunehmen.
            </div>
        </section>

        <section class="mt-4">
            <div class="text-center">Der Tierhalter versichert, die vorstehenden Angaben nach bestem Wissen und Gewissen
                richtig vollständig gemacht und die Geschaftsbedingungen von ANIMALDATA.COM akzeptiert zu haben. Der
                Abrufbarkeit personenbezogener Daten über das Internet wird ausdrücklich zugestimmt.
            </div>
        </section>

        <section class="mt-4">
            <div class="d-flex justify-content-around align-items-end text-left">
                <div class="left">
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">(Unterschrift und Stampiglie des Tierarztes)</div>
                </div>
                <div class="right">
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">(Unterschrift des Tierbesitzers)</div>
                </div>
            </div>
        </section>

        <section class="mt-4 position-relative break-after" style="page-break-after: always">
            <div>
                Bitte in BLOCKSCHRIFT ausfülllen ! Nur übermitteln, wenn die Anmeldung NICHT ONLINE erfolgt!
            </div>
        </section>

        <section class="text-left mt-4 agb position-relative" style="page-break-before: always">
            <h2 style="text-align: center !important;" class="mb-2 mt-4">Geschäftsbedingungen</h2>
            1. Die Internationale Tierkennzeichnungsdatenbank <b>ANIMALDATA.COM</b> - Dr. Miller KG - im folgenden:
            <b>ANIMALDATA.COM</b> ist
            ein Service der Vereinigung Österreichischer Kleintiermediziner (VOK) zur Ermittlung der Besitzer
            aufgegriffener Tiere, die
            mittels Mikrochips gekennzeichnet sind.<br /><br />

            2. <b>ANIMALDATA.COM</b> steht als Internetanwendung unter der Adresse http://www.<b>ANIMALDATA.COM</b> zur
            Registrierung
            der Nummern
            von Mikrochiptranspondern und der zugehörigen Tier- und Besitzerdaten und zur Abfrage der Kontaktdaten des
            Tierbesitzers
            zur Verfüigung. Hierbei kann die Dateneingabe online oder offline erfolgen. Die entsprechenden
            Schnittstellenbeschreibungen
            sind verbindlich.<br /><br />

            3. Jeder Nutzer von <b>ANIMALDATA.COM</b> muss diesen Geschäftsbedingungen in vollem Umfang
            zustimmen.<br /><br />

            4. Registrierungen dürfen bei <b>ANIMALDATA.COM</b> nur durchgeführt werden, wenn ein Tierarzt eingebunden
            ist, um
            sicher zu
            stellen, dass die Implantation des Mikrochiptransponders tierschutzgerecht erfolgt ist.<br /><br />

            5. Um im Auftrag von Tierbesitzern Registrierungen oder Änderungen bei <b>ANIMALDATA.COM</b> durchzuführen,
            müssen
            Tierärzte
            und Tierheime eine Zugangsberechtigung online beantragen. <br /><br />

            6. Vor der Registrierung hat jeder Tierbesitzer die Geschäftsbedingungen von <b>ANIMALDATA.COM</b> zu
            unterzeichnen. Nimmt
            ein Tierarzt oder ein Tierheim die Registrierung im Auftrag eines Tierbesitzers online vor, bestätigt
            dieser/dieses mit dem Absenden,
            den Tierbesitzer über die Geschaftsbedingungen von <b>ANIMALDATA.COM</b> nachweislich informiert zu
            haben.<br /><br />

            7. Mit der Registrierung bei <b>ANIMALDATA.COM</b>, erklärt sich der Tierbesitzer ausdrücklich damit
            einverstanden,
            dass die bekannt
            gegebenen Daten elektronisch gespeichert und die Kontaktdaten (Telefon, FAX, E-Mail-Adresse) im Internet
            anhand der
            Mikrochiptransponder- oder einer anderen Kennnummer, für jedermann abrufbar bereit gestellt werden.<br />
            Er nimmt weiters zur Kenntnis, dass die Daten auf Grund gesetzlicher Vorschriften Behörden zur Verfügung
            gestellt werden und
            die Mikrochipnummer an die Suchmaschinen ‘EuroPetNet' und PETMAXX weitergegeben wird.<br /><br />

            8. Der Tierbesitzer beauftragt <b>ANIMALDATA.COM</b> für die Rückfihrung seines aufgegriffenen Tieres auch
            weitere
            Daten an
            den Finder, an Tierschutzorganisationen, Behörden oder an die Exekutive weiter zu geben, wenn eine
            Erreichbarkeit mit den
            angegebenen Kontaktdaten (Telefon, FAX, e-Mail-Adresse) nicht möglich ist.<br /><br />

            9. Ais Meldestelle gem. § 24a Tierschutzgesetz, nimmt <b>ANIMALDATA.COM</b> im Auftrag des Tierbesitzers die
            Meldung an das
            amtliche Hunderegister für bei <b>ANIMALDATA.COM</b> registrierte österreichische Hunde vor. Die
            Beauftragung zur
            Meldung
            erfolgt durch die Bekanntgabe der hierfür erforderlichen Pflichtdaten (Geburtsdatum des Tierhalters,
            Ausweistyp, Ausweisnummer,
            Haltungsbeginn, Herkunftsland des Hundes) und die Begleichung der Registrierkosten bei <b>ANIMALDATA.COM</b>.
            Die Übermittlung der Daten erfolgt unmittelbar, sobald diese Bedingungen erfüllt wurden.<br /><br />

            10. Die Verantwortung für die Richtigkeit der Daten liegt jederzeit beim eingetragenen Tierbesitzer.
            <b>ANIMALDATA.COM</b> haftet
            nicht für Nachteile, die dem Tierbesitzer oder Dritten auf Grund unzutreffender oder unrichtiger Daten
            erwachsen.<br /><br />

            11. Zu Änderungen an einem Datensatz ist jedermann befugt, der sich durch Angabe eines Änderungspassworts
            oder durch
            Vorlage anderer geeigneter Nachweise als Tierbesitzer legitimiert. Tierärzte und Tierheime, die Änderungen
            durchführen, müssen
            vom Tierbesitzer nachweislich dafür beauftragt worden sein. Tierärzte oder Tierheime, die ohne Beauftragung
            eines Tierbesitzers
            Änderungen vornehmen, haften für allfällige Nachteile, die sich für <b>ANIMALDATA.COM</b> oder Dritte daraus
            ergeben.<br /><br />

            12. Die bei <b>ANIMALDATA.COM</b> gespeicherten Daten dürfen nur zur Ermittlung der Besitzer aufgegriffener
            Tiere
            verwendet
            werden. Eine Weitergabe oder Verwendung fur andere Zwecke ist nicht zulässig.<br /><br />

            13. Mangels Überprüfbarkeit, der im Zuge der Erstregistrierung oder bei einer Änderung bekannt gegebenen
            Daten, haftet
            <b>ANIMALDATA.COM</b> nicht für die Richtigkeit und Vollständigkeit, der registrierten und gemaß § 24a
            Tierschutzgesetz übermittelten
            Daten.<br /><br />

            14. Gleichermaßen haftet <b>ANIMALDATA.COM</b> nicht für die missbrauchliche Verwendung der bekannt
            gegebenen Daten
            und
            für Nachteile, die sich aufgrund der missbräuchlichen Verknüpfung von Online-Abfragen bei
            <b>ANIMALDATA.COM</b> mit
            Abfragen
            bei anderen Datenbanken ergeben. <b>Insbesondere ist kein Rückschluss auf den rechtmäßigen Eigentümer eines
            Tieres
            aus den Registrierungsdaten zulassig.</b><br /><br />

            15. Die Registrierung jedes Tieres erfoigt jeweils für zwanzig Jahre. Nach dieser Zeit wird der Datensatz
            automatisch gelöscht.<br /><br />

            16. Über jede Erstregistrierung wird dem Tierbesitzer eine Bestätigung und ein persönlicher Änderungscode an
            die eingetragene
            Adresse zugesandt. Die ANIMALDATA-CARD und die ANIMALDATA-Noftfallplankette für Hunde sind kostenlose
            Werbegeschenke bei
            Erstregistrierungen. Bei Verlust besteht hierfür <b>kein</b> kostenloser Ersatzanspruch. Weitere
            Registrierungsbestätigungen
            und Ersatz-Notfallplanketten können kostenpflichtig angefordert werden.<br /><br />

            <span class="font-weight-bolder d-block" style="text-align: center !important;">ANIMALDATA.COM - Dr. Miller KG, FN 255466k, LG Krems, UID: ATU61291444, DVR: 1052012, IBAN: AT761200000458634011</span>

        </section>
    </main>
</div>
