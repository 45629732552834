import {Component} from '@angular/core';
import {PrintableComponent} from '../../PrintableComponent';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {PrintingDataAnimal, PrintingTemplate} from '../TemplateTypes';
import {Age, CastrationStatus, Gender, Shelter} from '../../../shared/constants';
import {CharacteristicService} from '../../../animals/store/characteristics/characteristic.service';

@Component({
    selector: 'app-chip-contract',
    templateUrl: './chip-contract.component.html',
    styleUrls: ['./chip-contract.component.scss']
})
export class ChipContractComponent implements PrintableComponent {
    public animal: AnimalEntryModel;
    public visible = false;
    public printingTemplate = PrintingTemplate.ChipContract;
    public hideHeader = true;
    public shelter: Shelter;
    public data: PrintingDataAnimal;
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public Age = Age;

    constructor(public characteristicsService: CharacteristicService) {
    }
}
