<div class="title-bar" *ngIf="!hideControls">
    <h3>Vermisst</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header" *ngIf="!hideControls">
                    <h4 class="card-title">Übersicht</h4>
                </div>
                <div class="card-body">
                    <div id="top-controls" class="d-flex justify-content-between align-items-center"
                         *ngIf="!hideControls">
                        <div id="show-entries-container">
                            Show
                            <select name="entries" id="entries" class="mr-1 ml-1">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entries
                        </div>
                        <div id="add-new-missing-animal">
                            <a class="d-flex align-items-center justify-content-start no-color"
                               (click)="openDialog()">
                                Vermisstes Tier erstellen <i
                                    class="add-btn fas fa-plus d-flex justify-content-center align-items-center ml-3"></i>
                            </a>
                        </div>
                    </div>
                    <div class="entry-container mt-5">
                        <div class="missing-animal-entry" *ngFor="let animal of missingAnimals">
                            <div class="body">
                                <div class="row justify-content-between">
                                    <div class="col">
                                        <h5><i class="fas fa-male mr-3"></i>Besitzer</h5>
                                    </div>
                                    <div class="col-md-6 text-right" *ngIf="!hideControls">
                                        <a class="fas fa-edit icon"
                                           (click)="openDialog(animal)"
                                           style="font-size: 15px"
                                           matTooltip="Eintrag bearbeiten"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                        <a class="fas fa-trash-alt icon mr-1"
                                           (click)="deleteMissingAnimal(animal)"
                                           style="font-size: 15px"
                                           matTooltip="Eintrag löschen"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                    </div>
                                    <div class="col text-right" *ngIf="displayProbability">
                                        Trefferquote: {{NumberConverter.toGermanNumberFormat(animal.weight * 100)}} %
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <div class="row">
                                            <div class="col-md-5">Name:</div>
                                            <div class="col">{{animal.ownerName}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5">Adresse:</div>
                                            <div class="col">{{animal.ownerAddress}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5">Telefonnummer:</div>
                                            <div class="col">{{animal.ownerPhone}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-6">
                                        <h5><i class="fas fa-paw mr-3"></i>Tierdaten</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <div class="row">
                                            <div class="col-md-5">Name:</div>
                                            <div class="col">{{animal.name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5">Tierart:</div>
                                            <div class="col">{{animal.species.name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5">Rasse:</div>
                                            <div class="col">{{animal.race.name}}</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-4">Merkmale:</div>
                                            <div class="col">{{displayAttributes(animal.characteristics)}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">Abgängig seit:</div>
                                            <div class="col">{{animal.missingSince | date:'dd.MM.yyyy'}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">Abgängig in:</div>
                                            <div class="col">{{animal.missingStreet}}, {{animal.missingZip}}
                                                - {{animal.missingCity}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-2">Zusatzinfo:</div>
                                    <div class="col" style="margin-left:5px">
                                        {{animal.missingAdditionalInfo}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="default-entry mt-2"
                         *ngIf="totalAnimals === 0 &&
                         loading === false">
                        Keine Ergebnisse gefunden.
                    </div>

                    <div id="bottom-controls"
                         *ngIf="!hideControls"
                         class="controls d-flex justify-content-between align-items-baseline">
                        <div id="total-results">Gesamt: <span>{{totalAnimals}}</span></div>
                        <mat-paginator [length]="totalAnimals"
                                       [pageSize]="entriesPerPage"
                                       (page)="switchPage($event)"
                                       #paginator
                                       showFirstLastButtons
                                       hidePageSize>
                        </mat-paginator>
                    </div>

                    <div *ngIf="loading"
                         style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-spinner
                                color="accent"
                                mode="indeterminate">
                        </mat-progress-spinner>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-3" *ngIf="!hideFilter">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Suche</h4>
                </div>
                <div class="card-body pt-0">
                    <app-missing-animals-filter></app-missing-animals-filter>
                </div>
            </div>
        </div>
    </div>
</div>
