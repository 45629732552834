import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as CategoriesActions from './catgories.actions';

export interface State {
    categories: DictionaryEntryModel[];
}

const initialState: State = {
    categories: []
};

export function categoriesReducer(state = initialState, action: CategoriesActions.CategoriesActions): State {
    switch (action.type) {
        case CategoriesActions.SET_CATEGORIES:
            return {
                ...state,
                categories: [...action.payload.categories]
            };
        case CategoriesActions.ADD_CATEGORY:
            return {
                ...state,
                categories: [...state.categories, action.payload]
            };
        case CategoriesActions.UPDATE_CATEGORY:
            const updatedCategory = {
                ...state.categories.find(category => category.id === action.payload.id),
                ...action.payload
            };

            const updatedCategories = [...state.categories];
            const arrIndex = updatedCategories.findIndex(element => element.id === updatedCategory.id);
            updatedCategories[arrIndex] = updatedCategory;

            return {
                ...state,
                categories: updatedCategories
            };
        case CategoriesActions.DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter(category => category.id !== action.payload)
            };
        case CategoriesActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
