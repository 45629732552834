<div class="title-bar not-printable">
    <h3>Artikelliste</h3>
</div>

<div class="container-fluid main-card-wrapper not-printable">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Artikel</h4>
                </div>
                <div class="card-body">
                    <form [formGroup]="filterForm"
                          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
                          (ngSubmit)="search()">
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (dateChange)="dateChanged($event, true)"
                                           (click)="datePickerFrom.open()"
                                           [ngxMatDatetimePicker]="datePickerFrom"
                                           placeholder="Von"
                                           formControlName="from"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="datePickerFrom"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #datePickerFrom
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute"
                                                             [hideTime]="true">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (click)="datePickerTo.open()"
                                           (dateChange)="dateChanged($event, false)"
                                           [ngxMatDatetimePicker]="datePickerTo"
                                           placeholder="Bis"
                                           formControlName="to"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="datePickerTo"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #datePickerTo
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute"
                                                             [hideTime]="true">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 d-flex align-items-center" style="margin-top: 8px">
                                <mat-checkbox formControlName="grouped" [checked]="false"
                                              (change)="groupByCategory($event.checked)">
                                    Gruppiert
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 d-flex align-items-center ml-5 mt-2">
                                <button mat-raised-button color="primary" class="float-right">
                                    Suchen
                                </button>
                            </div>
                        </div>
                    </form>

                    <div id="top-controls" class="d-flex mt-5 justify-content-end">
                        <a class="fas fa-print icon"
                           style="font-size:17px"
                           (click)="print()"></a>
                    </div>

                    <div style="overflow-x: auto">
                        <table mat-table
                               [dataSource]="tableEntries"
                               (matSortChange)="orderData($event)"
                               multiTemplateDataRows
                               matSort
                               [ngClass]="{ 'grouped': grouped}"
                               class="mat-elevation-z0 no-expansion">
                            <ng-container *ngFor="let def of tableDef">
                                <ng-container [matColumnDef]="def.key">
                                    <th [ngClass]="{ 'sortable-header': columnSortable(def.key) }" mat-header-cell
                                        mat-sort-header *matHeaderCellDef> {{def.header}} </th>
                                    <td [ngClass]="def.className"
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngSwitch]="def.key">
                                        <ng-container *ngSwitchCase="'income'">
                                            {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'outcome'">
                                            {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'delta'">
                                            <span [ngClass]="calcDelta(element) > 0 ? 'surplus' : 'loss'">{{calcDelta(element) | currency:'EUR':'symbol':'1.2-2'}}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'dateCreated'">
                                            {{element[def.key] | date:'dd.MM.yyyy HH:mm' }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'atm'">
                                            {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{element[def.key]}}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container matColumnDef="cancellation">
                                <th mat-header-cell *matHeaderCellDef>Stornierung</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.dateCancelled | date:'dd.MM.yyyy HH:mm'}}<br />
                                    {{element.cancellationUser}}<br /><br />
                                    {{element.cancellationReason}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="table-row">
                            </tr>
                        </table>

                        <div class="default-entry mt-2" *ngIf="bookingLength === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>

                    <div id="sum-section" class="mt-5 pt-4">
                        <div class="row">
                            <div class="col lead">Summe</div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-2">
                                <span>Eingang:</span>
                            </div>
                            <div class="col-md-3">
                                <span>{{groupedSumIncome | currency:'EUR':'symbol':'1.2-2'}}</span>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-md-2">
                                <span>Ausgang:</span>
                            </div>
                            <div class="col-md-3">
                                <span>{{groupedSumOutcome | currency:'EUR':'symbol':'1.2-2'}}</span>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-md-2">
                                <span>Differenz:</span>
                            </div>
                            <div class="col-md-3">
                                <span>
                                    {{groupedSumDelta | currency:'EUR':'symbol':'1.2-2'}}
                                </span>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-md-2">
                                <span>Bankomat:</span>
                            </div>
                            <div class="col-md-3">
                                <span>{{groupedSumATM | currency:'EUR':'symbol':'1.2-2'}}</span>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-2">
                                <span>Saldo:</span>
                            </div>
                            <div class="col-md-3">
                                <span [ngClass]="newCashPosition > 0 ? 'surplus' : ''">{{newCashPosition | currency:'EUR':'symbol':'1.2-2'}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>

<app-printing [templateType]="PrintingTemplate.Cashbook"></app-printing>
