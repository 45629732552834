<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Kontrollen
    </h3>
    <div class="position-absolute mt-4">
        <span>LFNr.: {{animal.id}}</span>
    </div>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="true" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-body">
            <form class="mb-5"
                  *ngIf="animal.type.name === ActionType.VERGABE.key && alreadyPrinted"
                  [formGroup]="newSupervisionForm"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="mat-datetime-picker">
                            <input matInput
                                   (click)="datePickerSupervision.open()"
                                   [ngxMatDatetimePicker]="datePickerSupervision"
                                   placeholder="Datum"
                                   formControlName="supervisionDate"
                                   [min]="dateTimeConfig.minDate"
                                   [max]="dateTimeConfig.maxDate">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datePickerSupervision"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #datePickerSupervision
                                                     [stepHour]="dateTimeConfig.stepHour"
                                                     [stepMinute]="dateTimeConfig.stepMinute"
                                                     [hideTime]="true">
                            </ngx-mat-datetime-picker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Kontrolleur</mat-label>
                            <input matInput
                                   placeholder="Kontrolleur"
                                   formControlName="supervisor">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-10">
                        <mat-form-field>
                            <input matInput
                                   placeholder="Person"
                                   [readonly]="true"
                                   formControlName="person">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 d-flex align-items-center">
                        <a class="fas fa-eye icon" (click)="showPerson()"></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Report</mat-label>
                            <textarea matInput
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"
                                      formControlName="report"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <button class="float-right mt-4"
                                [disabled]="addRequestSent && newSupervisionForm.valid"
                                mat-raised-button color="primary"
                                (click)="addSupervision()">
                            <i class="fas fa-exclamation-circle mr-2"
                               style="color: #b71c1c"
                               *ngIf="displayErroneousInputs">
                            </i>
                            Speichern
                        </button>
                        <mat-icon
                                [hidden]="!(addRequestSent && newSupervisionForm.valid)"
                                class="mr-1 float-right" style="margin-top: 7px">
                            <mat-spinner color="accent" diameter="20"></mat-spinner>
                        </mat-icon>
                    </div>
                </div>
            </form>

            <div class="action-entry" *ngFor="let supervision of formerSupervisions">
                <app-former-supervision-content [id]="supervision.id"
                                                [animalId]="animal.id"
                                                [person]="supervision.person"
                                                [datePerformed]="supervision.datePerformed"
                                                [supervisor]="supervision.supervisor"
                                                [report]="supervision.report">
                </app-former-supervision-content>
            </div>
            <div class="row text-center" *ngIf="totalSupervisions === 0">
                <div class="col-md-12">
                    <h4>Keine Kontrollen für dieses Tier gefunden.</h4>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <button class="float-right" mat-raised-button color="primary" (click)="cancel()">
                        Schließen
                    </button>
                </div>
            </div>

            <div *ngIf="loading"
                 style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner
                        color="accent"
                        mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</mat-dialog-content>
