import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {SelectSearch} from '../../../shared/services/select-box/select-search';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {ArticleEntryModel} from '../../../shared/models/pos/article-entry.model';
import {PosSystemService} from '../../pos-system.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {ArticleListComponent} from '../../article-list/article-list.component';
import {NumberConverter} from '../../../shared/converter/number-converter';
import {LoggingService} from '../../../shared/logging/logging.service';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
    selector: 'app-booking-entry-form',
    templateUrl: './booking-entry-form.component.html',
    styleUrls: ['./booking-entry-form.component.scss'],
    providers: [CurrencyPipe]
})
export class BookingEntryFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('singleSelectArticle') public singleSelectArticle: MatSelect;
    @Output() priceChangedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeEntryChangedEvent: EventEmitter<number> = new EventEmitter<number>();

    public index = -1;
    public articleEntryForm: FormGroup;
    public categoriesWithTwoInputs = ArticleListComponent.categoriesWithTwoInputs;
    public displayRemarkInput = false;
    public price = 0;
    public selectedArticle: string;
    public correspondingCategory: string;
    public isIncomeComponent = false;
    // Complex controls
    public selectSearchArticle = new SelectSearch();

    private articles: ArticleEntryModel[];
    private articleSub: Subscription;

    constructor(private posSystemService: PosSystemService,
                private formBuilder: FormBuilder,
                private loggingService: LoggingService,
                private snackbarService: SnackbarService,
                private currencyPipe: CurrencyPipe) {
    }

    ngOnInit(): void {
        this.initFormGroup();
        this.articleSub = this.posSystemService.fetchArticles(100000, 1).subscribe(articles => {
            this.articles = articles;
            this.posSystemService.initArticleSelectBox(this.selectSearchArticle, this.articles);
        });
    }

    ngAfterViewInit(): void {
        this.selectSearchArticle.init(this.singleSelectArticle);
    }

    ngOnDestroy(): void {
        this.selectSearchArticle.destroy();
        if (this.articleSub) {
            this.articleSub.unsubscribe();
        }
    }

    public articleChanged($event: any): void {
        const optionValue = $event;
        if (this.articles) {
            const relatedArticle = this.articles.find(article => SelectBoxUtility.getOptionsValue(article.name) === optionValue);
            if (!relatedArticle) {
                return;
            }
            this.price = +relatedArticle.price;
            this.articleEntryForm.patchValue({
                newPrice: this.currencyPipe.transform(this.price, 'EUR', '', '1.2-2')
            });
            this.priceChangedEvent.emit();

            const category = relatedArticle.category.name;
            this.displayRemarkInput = this.categoriesWithTwoInputs.includes(category);
            const remarkCtrl = this.articleEntryForm.get('newRemark');
            if (this.displayRemarkInput) {
                remarkCtrl.setValidators([Validators.required]);
            } else {
                remarkCtrl.clearValidators();
            }
            remarkCtrl.updateValueAndValidity();
            this.selectedArticle = relatedArticle.name;
            this.correspondingCategory = category;
        }
    }

    public removeEntry(): void {
        this.removeEntryChangedEvent.next(this.index);
    }

    public priceChange(): void {
        if (this.articleEntryForm.controls.newPrice.valid) {
            this.price = +NumberConverter.toEnglishNumberFormat(this.articleEntryForm.value.newPrice.toString());
        } else {
            this.price = 0;
        }
        this.priceChangedEvent.emit();
    }

    private initFormGroup(): void {
        this.articleEntryForm = this.formBuilder.group({
            searchArticle: ['', Validators.required],
            newDescription: ['', Validators.required],
            newRemark: [''],
            newPrice: ['', [Validators.required, Validators.pattern(/^[-+]?[0-9]+(,[0-9]{1,2})?$/)]],
            newATM: ['']
        });
    }
}
