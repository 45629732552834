import {Injectable} from '@angular/core';
import {Order} from '../shared/controls/data-table/ordering';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {AnimalEntryModel} from '../shared/models/animal/animal-entry.model';
import {map, switchMap, take} from 'rxjs/operators';
import {CRUD} from '../shared/services/http/crud';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {SnackbarService} from '../shared/components/snackbar/snackbar.service';
import {SnackStatusType} from '../shared/components/snackbar/snack-status-type';
import {DropdownOption} from '../shared/data-types/dropdown-option';
import {ActionType} from './store/action-types/action-types.service';
import {TransactionEntryModel} from '../shared/models/transaction-entry.model';
import {ActionTypeEntryModel} from '../shared/models/action-type-entry.model';
import * as fromApp from '../store/app.reducer';
import * as fromAnimalActions from './store/animals/animal.actions';
import * as moment from 'moment';

@Injectable()
export class AnimalsService {
    public crudFinished: BehaviorSubject<string> = new BehaviorSubject(null);
    public speciesAndRaces: ReplaySubject<[DropdownOption[], DropdownOption[]]> =
        new ReplaySubject<[DropdownOption[], DropdownOption[]]>(null);
    public filterEvent = new BehaviorSubject(null);
    public filterMissingAnimalsEvent = new BehaviorSubject(null);
    public animalsSelected = new Subject<[AnimalEntryModel[], boolean]>();
    public animalsInTableChanged: BehaviorSubject<[AnimalEntryModel[], boolean]>; // Boolean value indicates add or remove operation.

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public fetchAnimals(size = -1,
                        page = -1,
                        order = Order.NONE,
                        column = '',
                        animalFilter?: any,
                        assignDataSource = true,
                        usePagination = true,
                        includeHistoricalTransactions = false): Observable<any> {
        return this.store.select('animalList').pipe(
            take(1),
            map(animalState => {
                return animalState.animals;
            }),
            switchMap(animals => {
                if (animals.length === 0 || page >= 0) {
                    this.store.dispatch(new fromAnimalActions.LoadAnimals({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column,
                        animalFilter,
                        assignDataSource,
                        usePagination,
                        includeHistoricalTransactions
                    }));
                    return this.actions$.pipe(
                        ofType(fromAnimalActions.SET_ANIMALS),
                        map((animalState: fromAnimalActions.SetAnimals) => {
                            return animalState.payload;
                        })
                    );
                } else {
                    return of(animals);
                }
            })
        );
    }

    public updateAnimal(animalEntryModel: AnimalEntryModel,
                        size: number,
                        page: number,
                        order: Order,
                        column: string,
                        animalFilter?: any,
                        usePagination = true): void {
        this.store.dispatch(new fromAnimalActions.UpdateAnimal({
            animal: animalEntryModel,
            size,
            page,
            order,
            column,
            animalFilter,
            usePagination
        }));
    }

    public addAndUpdateAnimals(newAnimalModels: AnimalEntryModel[],
                               updatedAnimalModels: AnimalEntryModel[],
                               fetchAnimals = true): void {
        this.store.dispatch(new fromAnimalActions.AddAndUpdateAnimals({
            newAnimals: newAnimalModels,
            updatedAnimals: updatedAnimalModels,
            fetchAnimals
        }));
    }

    public deleteAnimal(id: number, size: number, page: number, order: Order, column: string, usePagination = true): void {
        this.store.dispatch(new fromAnimalActions.DeleteAnimal({
            id,
            size,
            page,
            order,
            column,
            usePagination
        }));
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }

    public pensionPeriodExpired(transaction: TransactionEntryModel, actionType: ActionTypeEntryModel): boolean {
        const now = moment();
        const end = moment(transaction.stayUntil);
        return actionType.name === ActionType.ANNAHME_PENSIONSTIER.key && now.isAfter(end);
    }
}
