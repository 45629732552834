<div id="actions-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Transaktionsnummer</mat-label>
                    <input matInput
                           placeholder="Transaktionsnummer"
                           formControlName="transactionId">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="date.open()"
                           [ngxMatDatetimePicker]="date"
                           placeholder="Datum"
                           formControlName="date"
                           [min]="dateTimeConfig.minDate" [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="date"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #date
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Laufnummer</mat-label>
                    <input matInput
                           placeholder="Laufnummer"
                           formControlName="animalId">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Tiername</mat-label>
                    <input matInput
                           placeholder="Tiername"
                           formControlName="animalName">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Aktionstyp</mat-label>
                    <mat-select #actionTypeSelect
                                (selectionChange)="selChange()"
                                formControlName="actionType">
                        <mat-option value="{{defaultSelectClass}}">Alle</mat-option>
                        <mat-option *ngFor="let action of actionTypes$ | async"
                                    [value]="action"
                                    [attr.symbol]="TupleHelper.getValue(action.meta, 'symbol')">
                            {{action.viewValue}}
                            <div class="animal-type fas"
                                 [style]="{'background-color': TupleHelper.getValue(action.meta, 'color')}"
                                 [ngClass]="TupleHelper.getValue(action.meta, 'symbol')"></div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Tierart</mat-label>
                    <mat-select #singleSelectSpecies
                                formControlName="species"
                                (keydown.enter)="filter()">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchSpecies.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                [value]="species">
                            {{species.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Personnummer</mat-label>
                    <input matInput
                           placeholder="Personnummer"
                           formControlName="personId">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Person Nachname</mat-label>
                    <input matInput
                           placeholder="Person Nachname"
                           formControlName="personName">
                </mat-form-field>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col text-right">
                <button mat-raised-button
                        class="mt-4 float-right"
                        color="primary">
                    Suchen
                </button>
            </div>

            <div class="col">
                <button mat-raised-button
                        class="mt-4 float-left"
                        color="warn"
                        (click)="reset()">
                    Zurücksetzen
                </button>
            </div>
        </div>
    </form>
</div>
