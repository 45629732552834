import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {SimpleDialogComponent} from '../../../dialogs/simple-dialog/simple-dialog.component';
import {FormGroup, Validators} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {DateTimeConfig} from '../../../shared/controls/date-time-picker/DateTimeConfig';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../../shared/controls/date-time-picker/CustomFormat';
import {DialogType} from '../../../dialogs/dialog-type';
import {combineLatest, Subscription} from 'rxjs';
import {SelectSearch} from '../../../shared/services/select-box/select-search';
import {AnimalAddEditComponent} from '../../animal-add-edit/animal-add-edit.component';
import {TupleHelper} from '../../../shared/data-types/tuple';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {MissingAnimalEntryModel} from '../../../shared/models/animal/missing-animal-entry.model';
import * as moment from 'moment';
import {ofType} from '@ngrx/effects';
import * as fromMissingAnimals from '../../store/missing/missing.actions';
import {CRUD} from '../../../shared/services/http/crud';
import {AnimalDropdownOptions} from '../../animal-dropdown-options';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {ZipCity} from 'app/shared/services/zip-city-search/zip-city-search.service';
import * as fromOptionsActions from '../../../attributes/store/options/options.actions';
import * as fromAttributesActions from '../../../attributes/store/attributes/attributes.actions';
import * as fromRaceActions from '../../store/races/race.actions';
import * as fromSpeciesActions from '../../store/species/species.actions';
import * as fromCharacteristicActions from '../../store/characteristics/characteristic.actions';

@Component({
    selector: 'app-create-update-missing-animal',
    templateUrl: './create-update-missing-animal.component.html',
    styleUrls: ['./create-update-missing-animal.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class CreateUpdateMissingAnimalComponent extends AnimalAddEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('characteristicInput') public characteristicInput: ElementRef<HTMLInputElement>;
    @ViewChild('chipAutoComplete') public matAutocomplete: MatAutocomplete;
    @ViewChild('datePickerMissingSince') public datePickerMissingFrom: any;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    @ViewChild('singleSelectRace') public singleSelectRace: MatSelect;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public selectSearchSpecies = new SelectSearch();
    public selectSearchRace = new SelectSearch();
    public newMissingAnimalForm: FormGroup;
    public simpleDialogType = new DialogType();
    public genderOptions = AnimalDropdownOptions.genderOptions;
    public castrationStatusOptions = AnimalDropdownOptions.castrationStatusOptions;
    public ZipCity = ZipCity;
    public raceId: number;

    private missingChipBarSub: Subscription;
    private dropdownSub: Subscription;
    private fetchedMissingFDRacesSub: Subscription;

    ngOnInit(): void {
        this.initMissingForm();
        this.initDropdown();
        this.initMissingChipBar();
        this.initMissingAniamlEvents();
        this.initMissingErrorHandling();
    }

    ngAfterViewInit(): void {
        this.chipBar.setInput(this.characteristicInput);
        this.selectSearchSpecies.init(this.singleSelectSpecies);
        this.selectSearchRace.init(this.singleSelectRace);
    }

    ngOnDestroy(): void {
        this.chipBar.setValues([]);

        if (this.dropdownSub) {
            this.dropdownSub.unsubscribe();
        }
        if (this.missingChipBarSub) {
            this.missingChipBarSub.unsubscribe();
        }
        if (this.fetchedMissingFDRacesSub) {
            this.fetchedMissingFDRacesSub.unsubscribe();
        }
    }

    public openDialog(dialogType: string): void {
        this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: dialogType
            }
        });
    }

    public cancel(): void {
        this.dialog.closeAll();
    }

    public selectSpecies(option: string): void {
        const selectedSpecies = option;
        if (selectedSpecies === '') {
            return;
        }
        const species = this.allSpecies.find(s => s.value === selectedSpecies);
        const speciesId = +TupleHelper.getValue(species.meta, 'id');
        if (this.allRaces) {
            const filteredRaces = this.allRaces.filter(r => +TupleHelper.getValue(r.meta, 'speciesId') === speciesId);
            this.selectSearchRace.setValues(filteredRaces);
        }
        this.chipBar.removeAllChips();
        this.chipBar.setValues([]);
        this.singleSelectSpecies.close();
    }

    public addOrUpdateMissingAnimal(): void {
        if (!this.newMissingAnimalForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const missingAnimalFormValue = this.newMissingAnimalForm.value;

        const speciesOption = this.allSpecies.find(item => item.value === missingAnimalFormValue.species);
        if (!speciesOption) {
            return;
        }
        const dictSpecies: DictionaryEntryModel = {
            id: +TupleHelper.getValue(speciesOption.meta, 'id'),
            name: speciesOption.viewValue
        };

        const racesOption = this.selectSearchRace.values.find(item => item.value === missingAnimalFormValue.race);
        if (!racesOption) {
            return;
        }
        const dictRace: DictionaryEntryModel = {
            id: +TupleHelper.getValue(racesOption.meta, 'id'),
            name: racesOption.viewValue
        };

        let missingZip = '';
        if (typeof missingAnimalFormValue.missingZip === 'string') {
            missingZip = missingAnimalFormValue.missingZip;
        } else {
            missingZip = TupleHelper.getValue(missingAnimalFormValue.missingZip.meta, 'zip');
        }

        const missingAnimalModel: MissingAnimalEntryModel = {
            id: -1, // Dummy -> is not sent to the server
            species: dictSpecies,
            race: dictRace,
            name: missingAnimalFormValue.name,
            castrated: missingAnimalFormValue.castration,
            gender: missingAnimalFormValue.gender,
            characteristics: this.chipBar.getSelectedValues(), // Use control value
            missingAdditionalInfo: missingAnimalFormValue.additionalInfo,
            missingCity: missingAnimalFormValue.missingCity,
            missingZip,
            missingStreet: missingAnimalFormValue.missingStreet,
            missingSince: moment(missingAnimalFormValue.missingSince).format('YYYY-MM-DD'),
            ownerName: missingAnimalFormValue.ownerName,
            ownerAddress: missingAnimalFormValue.ownerAddress,
            ownerPhone: missingAnimalFormValue.ownerPhone,
            weight: 0
        };

        if (!this.data.missingAnimal) {
            this.missingAnimalService.addMissingAnimal(missingAnimalModel,
                this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn);
        } else {
            const updatedModel = {
                ...missingAnimalModel,
                id: this.data.missingAnimal.id
            };
            this.missingAnimalService.updateMissingAnimal(updatedModel,
                this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn);
        }
        this.requestSent = true;
    }

    public zipCityChanged(event: MatAutocompleteSelectedEvent): void {
        const option = event.option.value;
        const missingCity = TupleHelper.getValue(option.meta, 'city');
        this.newMissingAnimalForm.patchValue({
            missingCity
        });
    }

    private initMissingForm(): void {
        let species = '';
        let race = '';
        let name = 'Unbekannt';
        let missingSince = '';
        let missingStreet = '';
        let missingZip = '';
        let missingCity = '';
        let additionalInfo = '';
        let ownerName = '';
        let ownerAddress = '';
        let ownerPhone = '';
        let gender = '';
        let castration = '';

        if (this.data.missingAnimal) {
            const missingAnimal = this.data.missingAnimal;
            species = SelectBoxUtility.getOptionsValue(missingAnimal.species.name);
            race = SelectBoxUtility.getOptionsValue(missingAnimal.race.name);
            name = missingAnimal.name;
            missingSince = missingAnimal.missingSince;
            missingStreet = missingAnimal.missingStreet;
            missingZip = missingAnimal.missingZip;
            missingCity = missingAnimal.missingCity;
            additionalInfo = missingAnimal.missingAdditionalInfo;
            ownerName = missingAnimal.ownerName;
            ownerAddress = missingAnimal.ownerAddress;
            ownerPhone = missingAnimal.ownerPhone;
            gender = missingAnimal.gender;
            castration = missingAnimal.castrated;
            this.selectedRaceId = this.data.missingAnimal.race.id;
        }

        this.newMissingAnimalForm = this.formBuilder.group({
            species: [species, Validators.required],
            race: [race, Validators.required],
            name: [name, Validators.maxLength(50)],
            missingSince: [missingSince, Validators.required],
            missingStreet: [missingStreet, Validators.required],
            missingZip: [missingZip, Validators.required],
            missingCity: [missingCity, Validators.required],
            additionalInfo: [additionalInfo, Validators.maxLength(500)],
            ownerName: [ownerName, Validators.required],
            ownerAddress: [ownerAddress, Validators.required],
            ownerPhone: [ownerPhone, Validators.required],
            gender: [gender, Validators.required],
            castration: [castration, Validators.required]
        });

        this.newMissingAnimalForm.patchValue({
            missingZip: {
                viewValue: missingZip,
                value: missingZip,
                meta: [
                    {
                        key: 'zip',
                        value: missingZip
                    }
                ]
            }
        });
    }

    private initDropdown(): void {
        this.dropdownSub = combineLatest([
            this.speciesService.fetchSpecies(),
            this.raceService.fetchRaces()
        ]).subscribe(([species, races]) => {
            const selectedSpecies = species.find(s => s.value === this.newMissingAnimalForm.controls.species.value);
            const speciesId = typeof selectedSpecies !== 'undefined' ?
                +TupleHelper.getValue(selectedSpecies.meta, 'id') : -1;
            this.allRaces = races;
            this.allSpecies = species;
            this.selectSearchSpecies.setValues(species);
            this.selectSearchRace.setValues(races.filter(r => +TupleHelper.getValue(r.meta, 'speciesId') === speciesId));
        });
    }

    private initMissingAniamlEvents(): void {
        this.actions$.pipe(
            ofType(fromMissingAnimals.SET_MISSING_ANIMALS)
        ).subscribe((missingAnimalState: fromMissingAnimals.SetMissingAnimals) => {
            this.requestSent = false;
            const operation = missingAnimalState.payload.operation;

            if (operation === CRUD.CREATE || operation === CRUD.UPDATE) {
                this.dialog.closeAll();
            }
        });

        this.fetchedMissingFDRacesSub = this.fdRacesService.fetchFDRaces()
            .subscribe(fdRaces => {
                this.allFDRaces = fdRaces;
            });
    }

    private initMissingChipBar(): void {
        if (this.allRaces) {
            const selectedRace = this.allRaces.find(r => r.value === this.newMissingAnimalForm.controls.race.value);
            this.raceId = selectedRace ? +TupleHelper.getValue(selectedRace.meta, 'id') : null;
            let filter = null;
            if (this.raceId !== null) {
                filter = {
                    raceId: this.raceId,
                    excludeLegacyCharacteristics: true
                };
            }
            this.missingChipBarSub = this.characteristicService
                .fetchCharacteristics(filter)
                .subscribe(characteristics => {
                    this.chipBar.setValues(characteristics);
                    if (this.characteristicsInitialized) {
                        this.chipBar.focusCharacteristicsInput();
                    }
                });

            this.data.missingAnimal?.characteristics.forEach(characteristic => {
                this.chipBar.addChip(characteristic);
            });
        }
    }

    private initMissingErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromOptionsActions.HTTP_FAIL,
                fromAttributesActions.HTTP_FAIL,
                fromRaceActions.HTTP_FAIL,
                fromSpeciesActions.HTTP_FAIL,
                fromCharacteristicActions.HTTP_FAIL)
        ).subscribe((httpFail: fromAttributesActions.HttpFail |
            fromOptionsActions.HttpFail |
            fromRaceActions.HttpFail |
            fromSpeciesActions.HttpFail |
            fromCharacteristicActions.HttpFail) => {
            switch (httpFail.type) {
                case fromSpeciesActions.HTTP_FAIL:
                    this.speciesService.handleRequestError(httpFail.payload.message);
                    break;
                case fromRaceActions.HTTP_FAIL:
                    this.raceService.handleRequestError(httpFail.payload.message);
                    break;
                case fromAttributesActions.HTTP_FAIL || fromOptionsActions.HTTP_FAIL:
                    this.attributesService.handleRequestError(httpFail.payload.message);
                    break;
                case fromCharacteristicActions.HTTP_FAIL:
                    this.characteristicService.handleRequestError(httpFail.payload.message, this.selectedRaceId, this.chipBar);
                    break;
                default:
                    this.animalService.handleRequestError('Unbekannter Fehler.');
            }
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }
}
