import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {first} from 'rxjs/internal/operators/first';
import {Observable} from 'rxjs';
import * as fromApp from '../../../store/app.reducer';

@Injectable({
    providedIn: 'root'
})
export class EndpointService {
    private readonly PREFIX = environment.apiPath;
    private readonly SHELTER_PREFIX = this.PREFIX + '/shelters';

    constructor(private store: Store<fromApp.AppState>) {
    }

    // ////////////############ Auth ############////////////
    public login(): string {
        return this.PREFIX + '/login';
    }

    public logout(): string {
        return this.PREFIX + '/logout';
    }

    public currentUser(): string {
        return this.PREFIX + '/users/current';
    }

    // ////////////############ Animal Stuff ############////////////
    // ---------- Animals
    public animals(method: HttpMethod, animalId = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + animalId : '';

        postfix = animalId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/animals' + postfix;
            })
        );
    }

    // ---------- Species
    public species(method: HttpMethod, speciesId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + speciesId : '';

        postfix = speciesId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/species' + postfix;
            })
        );
    }

    // ---------- Races
    public races(method: HttpMethod, raceId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + raceId : '';

        postfix = raceId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/races' + postfix;
            })
        );
    }

    // ---------- Fundtierdatenbank Races
    public fdRaces(method: HttpMethod): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/fd-races';
            })
        );
    }

    public fdMappings(method: HttpMethod): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/fd-mappings';
            })
        );
    }

    // ---------- Accommodations
    public accommodations(method: HttpMethod, accommodationId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + accommodationId : '';

        postfix = accommodationId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/accommodations' + postfix;
            })
        );
    }

    // ---------- Characteristics
    public characteristics(method: HttpMethod, characteristicId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + characteristicId : '';

        postfix = characteristicId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/characteristics' + postfix;
            })
        );
    }

    // ---------- Action Types
    public actionTypes(): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/action-types';
            })
        );
    }

    // ---------- Care spaces
    public careSpaces(method: HttpMethod, careSpaceId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + careSpaceId : '';

        postfix = careSpaceId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/care-space' + postfix;
            })
        );
    }

    // ---------- Missing animals
    public missingAnimals(method: HttpMethod, careSpaceId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + careSpaceId : '';

        postfix = careSpaceId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/animal-missing' + postfix;
            })
        );
    }

    // ---------- Match missing animals
    public matchMissingAnimals(animalId: number, radius: number): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/animals/' + animalId + '/match-missing?radius=' + radius;
            })
        );
    }

    // ---------- Statistics - Occupation
    public occupation(): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/occupancy';
            })
        );
    }

    // ////////////############ Persons ############////////////
    public persons(method: HttpMethod, personId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + personId : '';

        postfix = personId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/persons' + postfix;
            })
        );
    }

    // ////////////############ Aktionen ############////////////
    public transactions(method: HttpMethod, actionId: number = -1, fetchLatestTransaction = false): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + actionId : '';

        postfix = actionId === -1 && method === HttpMethod.GET ? '' : postfix;

        if (!fetchLatestTransaction) {
            return this.getShelterId().pipe(
                map(id => {
                    return this.SHELTER_PREFIX + '/' + id + '/transactions' + postfix;
                })
            );
        } else {
            return this.getShelterId().pipe(
                map(id => {
                    return this.SHELTER_PREFIX + '/' + id + '/transactions/latest';
                })
            );
        }
    }

    // ////////////############ Veterinary Treatments ############////////////
    // ---------- Veterinary Treatments
    public treatments(method: HttpMethod, treatmentId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + treatmentId : '';

        postfix = treatmentId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/veterinary-treatments' + postfix;
            })
        );
    }

    // ---------- Former Treatments
    public formerTreatments(method: HttpMethod, formerTreatmentId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + formerTreatmentId : '';

        postfix = formerTreatmentId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/former-treatments' + postfix;
            })
        );
    }

    // ////////////############ Attributes ############////////////
    // ---------- Attributes
    public attributes(method: HttpMethod, attributeId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + attributeId : '';

        postfix = attributeId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/animal-attributes' + postfix;
            })
        );
    }

    // ---------- Attribute Options
    public options(method: HttpMethod, attributeId: number = -1, optionId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/values/' + optionId : '';

        postfix = method === HttpMethod.POST || method === HttpMethod.GET ? '/values' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/animal-attributes/' + attributeId + postfix;
            })
        );
    }

    // ////////////############ Supervisions ############////////////
    public supervisions(method: HttpMethod, supervisionId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + supervisionId : '';

        postfix = supervisionId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/supervisions' + postfix;
            })
        );
    }

    // ////////////############ POS System ############////////////
    // ---------- Articles
    public articles(method: HttpMethod, articleId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + articleId : '';

        postfix = articleId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/articles' + postfix;
            })
        );
    }

    public bookings(method: HttpMethod, bookingId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + bookingId : '';

        postfix = bookingId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/bookings' + postfix;
            })
        );
    }

    // ---------- Categories
    public categories(method: HttpMethod, categoryId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + categoryId : '';

        postfix = categoryId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/articles/categories' + postfix;
            })
        );
    }

    // ---------- Cash Position
    public cashPosition(endDate: string): Observable<string> {
        const postfix = '?date_until=' + endDate;
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/cash-position' + postfix;
            })
        );
    }

    // ////////////############ Media Upload ############////////////
    public media(method: HttpMethod, imageId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + imageId : '';

        postfix = imageId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/media' + postfix;
            })
        );
    }

    // ////////////############ User Management ############////////////
    public users(method: HttpMethod, userId: number = -1): Observable<string> {
        let postfix = method === HttpMethod.GET ||
        method === HttpMethod.PUT ||
        method === HttpMethod.DELETE ? '/' + userId : '';

        postfix = userId === -1 && method === HttpMethod.GET ? '' : postfix;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/users' + postfix;
            })
        );
    }


    // ////////////############ Auto Complete ############////////////
    public autoComplete(type: string, field: string, value: string): Observable<string> {
        const postfix = '?type=' + type + '&' + field + '=' + value;

        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/auto-complete' + postfix;
            })
        );
    }

    // ////////////############ Zips and Cities ############////////////
    public zipCities(): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/city';
            })
        );
    }

    // ////////////############ Logging ############////////////
    public logging(): Observable<string> {
        return this.getShelterId().pipe(
            map(id => {
                return this.SHELTER_PREFIX + '/' + id + '/logging';
            })
        );
    }

    private getShelterId(): Observable<number> {
        return this.store.select('auth')
            .pipe(
                first(),
                map(auth => {
                        if (auth === null || auth.user === null) {
                            throw new Error('User is not set in angular store.');
                        } else {
                            return auth.user.shelterId;
                        }
                    }
                ));
    }
}

export enum HttpMethod {
    GET,
    POST,
    PUT,
    DELETE
}
