export class NumberConverter {
    public static toGermanNumberFormat(value: number): string {
        return (Math.round(value * 100) / 100).toFixed(2).replace('.', ',');
    }

    public static toEnglishNumberFormat(value: string): string {
        if (value !== null) {
            return value.replace(',', '.');
        } else {
            return '';
        }
    }
}
