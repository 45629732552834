import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {MatOption, MatOptionSelectionChange} from '@angular/material/core';
import {CUSTOM_DATE_FORMATS} from '../../shared/controls/date-time-picker/CustomFormat';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AnimalAddEditComponent} from '../animal-add-edit/animal-add-edit.component';
import {Tuple, TupleHelper} from '../../shared/data-types/tuple';
import {PersonDialogComponent} from '../../persons/person-dialog/person-dialog.component';
import {DialogType} from '../../dialogs/dialog-type';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {PersonSelect} from '../../shared/controls/person-select';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {ActionType, ActionTypesService} from '../store/action-types/action-types.service';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {AnimalsService} from '../animals.service';
import {SelectMultiSearch} from '../../shared/controls/select-box/select-multi-search';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {combineLatest, debounceTime, take} from 'rxjs/operators';
import {ActionTypeEntryModel} from '../../shared/models/action-type-entry.model';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../../shared/services/http/crud';
import {tap} from 'rxjs/internal/operators/tap';
import {isArray} from 'rxjs/internal-compatibility';
import {Order} from '../../shared/controls/data-table/ordering';
import {PersonsService} from '../../persons/persons.service';
import {SnackbarService} from '../../shared/components/snackbar/snackbar.service';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {ActionsService} from '../../actions/actions.service';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {AssociatedAnimalsComponent} from '../../persons/associated-animals/associated-animals.component';
import {ShelterStatus} from '../../shared/constants';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import * as fromActionTypes from '../store/action-types/action-types.actions';
import * as moment from 'moment';
import * as fromAnimalActions from '../store/animals/animal.actions';
import * as fromPersonsActions from '../../persons/store/persons.actions';
import * as fromActionAction from '../../actions/store/actions.actions';
import {CareSpaceService} from '../store/care-space/care-space.service';
import {RueckgabePensionstierCostInfo} from '../action-type-specific/rueckgabe-pensionstier/rueckgabe-pensionstier.component';
import {RueckgabePensionstierService} from '../action-type-specific/rueckgabe-pensionstier/rueckgabe-pensionstier.service';
import {SnackStatusType} from '../../shared/components/snackbar/snack-status-type';
import {PrintingService} from '../../printing/printing.service';
import {PrintingDataAnimal, PrintingTemplate} from '../../printing/templates/TemplateTypes';
import {MatRadioChange} from '@angular/material/radio';
import {AuthService} from '../../auth/auth.service';
import {ExternalApiService} from '../../shared/services/external-api.service';
import {AnimalActionConverter} from '../../shared/converter/animal-action-converter';
import {MediaService} from '../../media-centre/media.service';
import {MediaEntryModel} from '../../shared/models/media-entry.model';

@Component({
    selector: 'app-acquisition-and-give-away',
    templateUrl: './acquisition-and-give-away.component.html',
    styleUrls: ['./acquisition-and-give-away.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
    ]
})
export class AcquisitionAndGiveAwayComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('actionTypeSelect', {static: true}) public actionTypeSelect: MatSelect;
    @ViewChild('personSelect') public personSelect: MatSelect;
    @ViewChild('ownerSelect') public ownerSelect: MatSelect;
    @ViewChild('card') public card: ElementRef;

    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public selectAnimalsCtrl = new SelectMultiSearch();
    public personSelectSearch = new PersonSelect();
    public ownerSelectSearch = new PersonSelect();
    public isAcquisition: boolean;
    public simpleDialogType = new DialogType();
    public selected: string;
    public displayErroneousInputs = false;
    public actionTypes: DropdownOption[] = [];
    public TupleHelper = TupleHelper;
    public ActionType = ActionType;
    public personSelectOneLabel = 'Person';
    public acquisitionGiveAwayForm: FormGroup;
    public loadAnimals = false;
    public addRequestSent = false;
    public animalsInTable: AnimalEntryModel[] = [];
    public PrintingTemplate = PrintingTemplate;
    public fetchedActionTypes: ActionTypeEntryModel[] = [];
    public readonly SHOW_ASSOCIATED_ANIMALS_DIALOG = 'associated-animals-dialog';

    protected fetchedPersons: PersonEntryModel[] = [];

    private searchForPerson = false;
    private currentSelectClass: string;
    private fetchedActionTypesSub: Subscription;
    private actionTypesSub: Subscription;
    private animalIdInputSub: Subscription;
    private animalsSub: Subscription;
    private fetchedAnimalsSub: Subscription;
    private personsSub: Subscription;
    private fetchedPersonsSub: Subscription;
    private animalHolderBoxClicked = false;
    private latestTransactionSub: Subscription;
    private actionsSub: Subscription;
    private setCreatedPersonSub: Subscription;
    private rueckgabePensionstierSub: Subscription;
    private dialogType = new DialogType();
    private newTransactions: TransactionEntryModel[] = [];
    private rueckgabePensionstierCostInfo: RueckgabePensionstierCostInfo[] = [];
    private printingData: any;
    private addedOrUpdatedAnimals: AnimalEntryModel[] = [];
    private apiErrorOccurred = false;

    constructor(protected formBuilder: FormBuilder,
                protected dialog: MatDialog,
                protected actionTypesService: ActionTypesService,
                protected animalService: AnimalsService,
                protected actions$: Actions,
                protected careSpaceService: CareSpaceService,
                protected printingService: PrintingService,
                protected personService: PersonsService,
                private renderer: Renderer2,
                private router: Router,
                private mediaService: MediaService,
                private authService: AuthService,
                private snackbarService: SnackbarService,
                private rueckgabePensionstierService: RueckgabePensionstierService,
                private actionsService: ActionsService,
                private externalAPIService: ExternalApiService
    ) {
    }

    ngOnInit(): void {
        this.animalService.animalsInTableChanged = new BehaviorSubject<[AnimalEntryModel[], boolean]>(null);
        this.isAcquisition = this.router.url.split('/').pop() === 'acquisition';
        this.selected = this.isAcquisition ? ActionType.FINDLING.key : ActionType.VERGABE.key;
        this.initEvents();
        this.initForm();
        this.initActionTypeSelectBox();
        this.initErrorHandling();
    }

    ngAfterViewInit(): void {
        // Add card bg color
        this.renderer.addClass(this.card.nativeElement, this.currentSelectClass + '-bg');
    }

    ngOnDestroy(): void {
        this.selectAnimalsCtrl.destroy();
        this.personSelectSearch.destroy();
        this.ownerSelectSearch.destroy();

        if (this.fetchedActionTypesSub) {
            this.fetchedActionTypesSub.unsubscribe();
        }
        if (this.animalIdInputSub) {
            this.animalIdInputSub.unsubscribe();
        }
        if (this.animalsSub) {
            this.animalsSub.unsubscribe();
        }
        if (this.personsSub) {
            this.personsSub.unsubscribe();
        }
        if (this.fetchedAnimalsSub) {
            this.fetchedAnimalsSub.unsubscribe();
        }
        if (this.actionTypesSub) {
            this.actionTypesSub.unsubscribe();
        }
        if (this.latestTransactionSub) {
            this.latestTransactionSub.unsubscribe();
        }
        if (this.actionsSub) {
            this.actionsSub.unsubscribe();
        }
        if (this.setCreatedPersonSub) {
            this.setCreatedPersonSub.unsubscribe();
        }
        if (this.fetchedPersonsSub) {
            this.fetchedPersonsSub.unsubscribe();
        }
        if (this.rueckgabePensionstierSub) {
            this.rueckgabePensionstierSub.unsubscribe();
        }
        this.fetchedPersons = [];
    }

    public actionTypeChanged(): void {
        const selectedOption = (this.actionTypeSelect.selected as MatOption);
        const action = this.actionTypes.find(actionEntry => actionEntry.value === selectedOption.value);

        const natEl = this.actionTypeSelect._elementRef.nativeElement.children[0].children[0].children[1];
        const color = TupleHelper.getValue(action.meta, 'color');
        const selectedSymbol = TupleHelper.getValue(action.meta, 'symbol');
        this.renderer.setStyle(natEl, 'background-color', color);
        this.renderer.addClass(natEl, selectedSymbol);

        this.selected = selectedOption.value;
        this.personSelectOneLabel = 'Person';

        if (this.selected === ActionType.RUECKGABE_PENSIONSTIER.key) {
            this.personSelectOneLabel = 'Abholer';
        } else if (this.selected === ActionType.ANNAHME_PENSIONSTIER.key) {
            this.personSelectOneLabel = 'Überbringer';
        } else {
            this.renderer.removeClass(natEl, 'fas');
            this.renderer.removeClass(natEl, 'fa-hand-holding-heart');
        }

        // Set card background
        this.renderer.removeClass(this.card.nativeElement, this.currentSelectClass + '-bg');
        this.renderer.addClass(this.card.nativeElement, action.value + '-bg');

        this.currentSelectClass = action.value;
        this.acquisitionGiveAwayForm.patchValue({
            actionType: this.acquisitionGiveAwayForm.controls.actionType.value,
            bringerReceiverAreSame: 'yes'
        });
        this.updateFormControls();
    }

    public openDialog(contentType: string,
                      isAnimalHolder = true,
                      element: DropdownOption = null,
                      careSpaceForm?: FormGroup): void {
        this.animalHolderBoxClicked = isAnimalHolder;
        if (contentType === this.simpleDialogType.NEW_ANIMAL) {
            const actionTypeName = this.acquisitionGiveAwayForm.controls.actionType.value;
            const actionTypeOption = this.actionTypes.find(type => type.value === actionTypeName);
            const actionType: ActionTypeEntryModel = {
                id: TupleHelper.getValue(actionTypeOption.meta, 'id'),
                color: TupleHelper.getValue(actionTypeOption.meta, 'color'),
                symbol: TupleHelper.getValue(actionTypeOption.meta, 'symbol'),
                name: actionTypeOption.value,
                isAcquisition: TupleHelper.getValue(actionTypeOption.meta, 'isAcquisition')
            };
            const lowestIdInTable = this.animalsInTable.length > 0 ?
                Math.min(...this.animalsInTable.map(a => a.id)) : 0;
            const dialogRef = this.dialog.open(AnimalAddEditComponent, {
                width: '1200px',
                panelClass: 'component-wrapper',
                data: {
                    edit: false,
                    actionType,
                    temporaryAnimalId: lowestIdInTable < 0 ? lowestIdInTable - 1 : (lowestIdInTable * -1) - 1
                }
            });

            dialogRef.afterClosed().subscribe(response => {
                if (typeof response !== 'undefined' && response !== false) {
                    const newAnimal: AnimalEntryModel = {
                        ...response,
                        dateCreated: moment()
                    };
                    this.animalService.animalsSelected.next([[newAnimal], true]);
                }
            });
        } else if (contentType === this.simpleDialogType.SEARCH_PERSON) {
            this.searchForPerson = true;
            const dialogRef = this.dialog.open(PersonDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: contentType
                }
            });

            dialogRef.afterClosed().subscribe(response => {
                if (typeof response !== 'undefined' && response !== false) {
                    this.fetchedPersonsSub = this.personService.fetchPersons(-1, 1, Order.NONE, '', response)
                        .subscribe(persons => {
                            const person = persons.slice()[0];
                            if (isAnimalHolder) {
                                this.personSelectSearch.createPersonSelectBox(persons);
                                this.acquisitionGiveAwayForm.patchValue({
                                    person
                                });

                                if (careSpaceForm) {
                                    careSpaceForm.patchValue({
                                        person: this.acquisitionGiveAwayForm.controls.person.value
                                    });
                                }
                            } else {
                                this.ownerSelectSearch.createPersonSelectBox(persons);
                                this.acquisitionGiveAwayForm.patchValue({
                                    owner: person
                                });
                            }
                            this.personService.crudFinished.next('');
                        });
                }
            });
        } else if (contentType === this.simpleDialogType.EDIT_PERSON) {
            const id = +element.value;
            const filter = {
                id
            };
            this.personService.fetchPersons(1, 1, Order.NONE, '', filter)
                .subscribe(persons => {
                    if (persons && persons.length > 0) {
                        const selectedPerson = persons.slice().pop();
                        const dialogRef = this.dialog.open(PersonDialogComponent, {
                            width: '900px',
                            panelClass: 'component-wrapper',
                            data: {
                                type: contentType,
                                person: selectedPerson
                            }
                        });

                        dialogRef.afterOpened().subscribe(() => {
                            this.personSelect.close();
                            if (typeof this.ownerSelect !== 'undefined') {
                                this.ownerSelect.close();
                            }
                        });

                        dialogRef.afterClosed().subscribe(response => {
                            if (typeof response !== 'undefined' && response !== false) {
                                const updateFilter = {
                                    id: response.id
                                };
                                this.personService.updatePerson(response, -1, 1, Order.NONE, '', updateFilter);
                                this.personService.crudFinished.next(response.firstName + ' ' + response.lastName);
                            }
                        });
                    }
                });
        } else if (contentType === this.simpleDialogType.NEW_PERSON) {
            this.dialog.open(PersonDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: contentType,
                    entriesPerPage: 1,
                    order: Order.DESC,
                    column: 'id'
                }
            });
        } else if (contentType === this.SHOW_ASSOCIATED_ANIMALS_DIALOG) {
            const selectedPerson = this.fetchedPersons.find(person => person.id === +element.value);
            if (selectedPerson !== null) {
                this.dialog.open(AssociatedAnimalsComponent, {
                    width: '1200px',
                    panelClass: 'component-wrapper',
                    data: {
                        selectedPerson,
                        actionTypes: this.fetchedActionTypes
                    }
                });
            }
        }
    }

    public personRemark(meta: Tuple[]): string {
        const remark = TupleHelper.getValue(meta, 'remark');
        if (remark !== undefined && remark !== null && remark !== '') {
            return remark;
        } else {
            return '';
        }
    }

    public addAnimalToTable(animals: AnimalEntryModel[]): void {
        if (animals !== null && typeof animals[0] !== 'undefined') {
            this.animalService.animalsSelected.next([animals, true]);
        }
    }

    public submit(): void {
        const acquisitionGiveAwayValue = this.acquisitionGiveAwayForm.value;
        const selectedStart = acquisitionGiveAwayValue.stayFrom;
        const selectedEnd = acquisitionGiveAwayValue.stayUntil;

        if ((acquisitionGiveAwayValue.actionType === ActionType.RUECKGABE.key ||
            acquisitionGiveAwayValue.actionType === ActionType.RUECKGABE_PENSIONSTIER.key) &&
            this.incorrectChipNumber(true)) {
            const dialogRef = this.dialog.open(SimpleDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: this.dialogType.REMARK,
                    entity: 'Ungültige Chipnummer',
                    content: 'Mindestens ein Tier hat eine ungültige Chipnummer. Trotzdem speichern?'
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true &&
                    !this.hasErrors(acquisitionGiveAwayValue, selectedStart, selectedEnd)) {
                    this.saveAndPrint(acquisitionGiveAwayValue, selectedStart, selectedEnd);
                }
            });
        } else if (!this.hasErrors(acquisitionGiveAwayValue, selectedStart, selectedEnd)) {
            this.saveAndPrint(acquisitionGiveAwayValue, selectedStart, selectedEnd);
        }
    }

    public animalAddedToTable(event: [AnimalEntryModel[], boolean]): void {
        this.animalsInTable = event[0].slice();
        const isAdded = event[1];
        this.animalService.animalsInTableChanged.next([this.animalsInTable, isAdded]);
    }

    public openEditDialog(animal: AnimalEntryModel): void {
        this.dialog.open(AnimalAddEditComponent, {
            width: '1200px',
            panelClass: 'component-wrapper',
            disableClose: true,
            data: {
                edit: true,
                animal,
                editTemporaryAnimal: false,
                pageIndex: 1,
                entriesPerPage: 1,
                order: Order.NONE,
                orderedColumn: ''
            }
        });
    }

    public animalSelected($event: MatOptionSelectionChange): void {
        const actionTypeName = this.acquisitionGiveAwayForm.controls.actionType.value;
        const actionTypeOption = this.actionTypes.find(type => type.value === actionTypeName);

        if (actionTypeOption.value === ActionType.VERGABE.key) {
            const filter = {
                animalId: $event.source.value.id
            };
            this.latestTransactionSub = this.actionsService.fetchActions(1, 1, Order.DESC, 'date_performed', filter)
                .subscribe(transactions => {
                    if (transactions.length > 0) {
                        const latestTransaction = transactions.slice().pop();
                        const currentActionType = this.fetchedActionTypes.find(at =>
                            at.id === latestTransaction.actionTypeId);
                        const datePerformed = moment(latestTransaction.date);
                        const now = moment();
                        const daysDiff = now.diff(datePerformed, 'days');
                        if (currentActionType.name === ActionType.FINDLING.key && daysDiff < 31) {
                            this.dialog.open(SimpleDialogComponent, {
                                width: '900px',
                                panelClass: 'component-wrapper',
                                data: {
                                    type: this.dialogType.TRIVIAL,
                                    entity: 'Tier in Fundtierfrist',
                                    content: 'Dieses Tier ist noch in der Fundtierfrist (gefunden am ' +
                                        datePerformed.format('DD.MM.YYYY') + ').'
                                }
                            });
                        }
                    }
                });
        }
    }

    public personSelectChange(entry: any): void {
        if (entry === null || typeof entry === 'undefined') {
            return;
        }

        let selectedPerson = null;
        if ('meta' in entry &&
            this.acquisitionGiveAwayForm.controls.actionType.value === ActionType.RUECKGABE_PENSIONSTIER.key) {
            this.rueckgabePensionstierService.personsFetched
                .pipe(take(1))
                .subscribe(persons => {
                    if (persons && persons.length > 0) {
                        this.fetchedPersons = persons;
                        selectedPerson = this.fetchedPersons.find(p => p.id === +entry.value);
                        this.showRemark(selectedPerson.remark);
                    }
                });
        } else {
            this.showRemark(entry.remark);
        }
    }

    public comparePerson(o1: any, o2: any): boolean {
        return true;
    }


    public toggleOwnerActive($event: MatRadioChange): void {
        if ($event.value === 'yes') {
            this.acquisitionGiveAwayForm.controls.owner.clearValidators();
            this.acquisitionGiveAwayForm.controls.owner.setErrors(null);
        } else {
            this.acquisitionGiveAwayForm.controls.owner.setValidators([Validators.required]);
        }
    }

    protected initForm(): void {
        this.acquisitionGiveAwayForm = this.formBuilder.group({
            actionType: ['', Validators.required],
            date: [moment(), Validators.required],
            animalId: [''],
            person: ['', Validators.required],
            bringerReceiverAreSame: ['yes'],
            owner: ['']
        });
    }

    protected initEvents(fromCareSpace = false): void {
        this.animalIdInputSub = this.selectAnimalsCtrl.multiFilterCtrl.valueChanges
            .pipe(
                tap(() => {
                    this.loadAnimals = true;
                }),
                debounceTime(1000)
            )
            .subscribe(value => {
                const ids = value.split(',');
                if (ids != null && isArray(ids) && ids[0] !== '') {
                    let idFilter = new Array<number>();
                    ids.forEach(id => {
                        if (isNaN(id)) {
                            idFilter = [];
                            return;
                        } else {
                            idFilter.push(+id);
                        }
                    });

                    if (idFilter.length === 0) {
                        this.loadAnimals = false;
                        return;
                    }

                    let filter: any = {
                        includeDeadAnimals: 'false',
                        id: idFilter
                    };

                    if (this.acquisitionGiveAwayForm.value.actionType === ActionType.VERGABE.key) {
                        filter = {
                            ...filter,
                            noGivingAway: 'false'
                        };
                    }

                    this.fetchedAnimalsSub = this.animalService.fetchAnimals(-1, 1, Order.NONE, '', filter)
                        .pipe(
                            take(1)
                        )
                        .subscribe(response => {
                            this.selectAnimalsCtrl.init(response.animals);
                            this.loadAnimals = false;
                        });
                } else {
                    this.loadAnimals = false;
                }
            });

        if (!fromCareSpace) {
            this.animalsSub = this.actions$.pipe(
                ofType(fromAnimalActions.SET_ANIMALS),
                combineLatest(this.animalService.crudFinished)
            ).subscribe(([animalState]: [fromAnimalActions.SetAnimals, string]) => {
                if (animalState.payload.crud === CRUD.UPDATE &&
                    !this.addRequestSent &&
                    animalState.payload.addedOrUpdatedAnimals.length > 0) {
                    const updatedAnimal = {
                        ...animalState.payload.addedOrUpdatedAnimals.slice().pop()
                    };
                    this.animalService.animalsSelected.next([[updatedAnimal], false]);
                    this.acquisitionGiveAwayForm.controls.animalId.setValue('');
                    this.selectAnimalsCtrl.reset();
                    this.animalService.handleRequestSuccess(CRUD.UPDATE, 'Tier',
                        updatedAnimal.name + ' (' + updatedAnimal.id + ')');
                } else if (this.addRequestSent) {
                    let newAnimalCounter = 0;
                    if (this.newTransactions.length > 0 && typeof animalState.payload.addedOrUpdatedAnimals !== 'undefined') {
                        const updatedTransactions = this.newTransactions.map(tr => {
                            if (tr.animal.id < 0) {
                                const updatedTransaction: TransactionEntryModel = {
                                    ...tr,
                                    animal: animalState.payload.addedOrUpdatedAnimals[newAnimalCounter]
                                };
                                newAnimalCounter++;
                                return updatedTransaction;
                            } else {
                                return tr;
                            }
                        });
                        this.addedOrUpdatedAnimals = animalState.payload.addedOrUpdatedAnimals;
                        this.actionsService.addActions(updatedTransactions, 1, -1, Order.NONE, '', false);
                    }
                }
            });
        }


        this.actions$.pipe(
            ofType(fromPersonsActions.SET_PERSONS),
            combineLatest(this.personService.crudFinished)
        ).subscribe(([personState, identifier]: [fromPersonsActions.SetPersons, string]) => {
            const crudOperation = personState.payload.crud;
            const personResponse = personState.payload.persons.slice();
            this.fetchedPersons.push(...personResponse);
            if (crudOperation === CRUD.READ && this.searchForPerson) {
                this.searchForPerson = false;
            } else if (crudOperation === CRUD.CREATE || crudOperation === CRUD.UPDATE) {
                this.populatePersonSelect(personResponse);
                this.personService.handleRequestSuccess(crudOperation, 'Person', identifier);
            }
        });

        this.actionTypesSub = this.actionTypesService.fetchActionTypes()
            .subscribe(actionTypes => {
                this.fetchedActionTypes = actionTypes;
            });

        // noinspection JSDeprecatedSymbols
        this.actionsSub = this.actions$.pipe(
            ofType(fromActionAction.SET_ACTIONS)
        ).subscribe((actionState: fromActionAction.SetActions) => {
            setTimeout(() => {
                if (actionState.payload.crud === CRUD.CREATE) {
                    this.addRequestSent = false;
                    this.acquisitionGiveAwayForm.reset({
                        bringerReceiverAreSame: 'yes',
                        actionType: this.acquisitionGiveAwayForm.controls.actionType.value,
                        date: moment().format('YYYY-MM-DD HH:mm:ss'),
                        animalPlace: 'yes',
                        hasFence: 'no',
                        hasGarden: 'no',
                        takingOverReason: 'Abnahme durch Amtstierarzt'
                    });
                    this.acquisitionGiveAwayForm.controls.owner.clearValidators();
                    this.acquisitionGiveAwayForm.controls.owner.setErrors(null);

                    if (actionState.payload.invalidAPIRequests && actionState.payload.invalidAPIRequests.length > 0) {
                        this.apiErrorOccurred = true;
                    }

                    //// Printing
                    const newPrintingTransaction: TransactionEntryModel = {
                        ...this.newTransactions.slice().pop(),
                        animal: null // To prevent circular dependency
                    };
                    let animals = this.animalsInTable
                        .concat(this.addedOrUpdatedAnimals)
                        .filter((a, i, arr) => a.id >= 0 && arr.findIndex(t => t.id === a.id) === i);

                    const fetchedAnimals = actionState.payload.actions.map(as => as.animal);
                    animals = animals.map(a => {
                        const fetchedAnimal = AnimalActionConverter.toAnimalEntryModel(fetchedAnimals.find(fa => fa.id === a.id));
                        if (fetchedAnimal) {
                            return {
                                ...a,
                                fundtierId: fetchedAnimal.fundtierId
                            };
                        }
                        return a;
                    });

                    const avatarIds = animals.filter(a => a.avatarId).map(a => a.avatarId);
                    if (avatarIds.length > 0) {
                        this.mediaService.fetchMultipleMedia(avatarIds, false)
                            .subscribe(avatars => {
                                this.print(animals, newPrintingTransaction, actionState, avatars);
                            });
                    } else {
                        this.print(animals, newPrintingTransaction, actionState);
                    }
                }
            }, 0);

            this.externalAPIService.handleInvalidRequests(actionState.payload.invalidAPIRequests);
        });

        this.rueckgabePensionstierSub = this.rueckgabePensionstierService.costInfoUpdated
            .subscribe(costInfo => {
                this.rueckgabePensionstierCostInfo = costInfo.slice();
            });
    }

    protected initErrorHandling(): void {
        this.actions$.pipe(
            ofType(
                fromAnimalActions.HTTP_FAIL,
                fromPersonsActions.HTTP_FAIL,
                fromActionAction.HTTP_FAIL,
                fromActionTypes.HTTP_FAIL
            )
        ).subscribe((httpFail: fromAnimalActions.HttpFail |
            fromPersonsActions.HttpFail |
            fromActionAction.HttpFail |
            fromActionTypes.HttpFail
        ) => {
            this.animalService.handleRequestError(httpFail.payload.message);
            this.addRequestSent = false;
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }

    private saveAndPrint(acquisitionGiveAwayValue: any, selectedStart: any, selectedEnd: any): void {
        this.addRequestSent = true;
        this.displayErroneousInputs = false;

        let selectedPerson = acquisitionGiveAwayValue.person;
        if ('meta' in selectedPerson) {
            selectedPerson = this.fetchedPersons.find(p => p.id === +selectedPerson.value);
        }

        let selectedOwner = null;
        if (acquisitionGiveAwayValue.owner) {
            selectedOwner = acquisitionGiveAwayValue.owner;
            if ('meta' in selectedOwner) {
                selectedOwner = this.fetchedPersons.find(p => p.id === +selectedOwner.value);
            }
        }

        const selectedActionType = this.fetchedActionTypes.find(actionType => actionType.name === acquisitionGiveAwayValue.actionType);
        const depositTotal = acquisitionGiveAwayValue.depositTotal;
        const deposit = acquisitionGiveAwayValue.deposit;
        const updatedAnimals = [];
        const newAnimals = [];
        let costsPerDayForAnimal = '';
        let additionalCostsForAnimal = '';
        let additionalCostsAccumulated = 0;
        let costsPerDayAccumulated = 0;

        this.animalsInTable.forEach(animal => {
            const zip = this.getZip(acquisitionGiveAwayValue);

            const updatedAnimal: AnimalEntryModel = {
                ...animal,
                type: selectedActionType
            };

            let rueckgabePensionstierCostInfo = null;
            if (this.rueckgabePensionstierCostInfo && this.rueckgabePensionstierCostInfo.length > 0) {
                rueckgabePensionstierCostInfo = this.rueckgabePensionstierCostInfo.find(entry => entry.animalId === animal.id);
                costsPerDayForAnimal = rueckgabePensionstierCostInfo.costsPerDay.toString();
                additionalCostsForAnimal = rueckgabePensionstierCostInfo.additionalCosts.toString();
            }

            let shelter;
            if (selectedActionType.name === ActionType.ANNAHME_PENSIONSTIER.key) {
                shelter = ShelterStatus.PENSIONSTIER_IM_HEIM.key;
            } else if (selectedActionType.name === ActionType.ABGABE.key ||
                selectedActionType.name === ActionType.ABNAHME.key ||
                selectedActionType.name === ActionType.FINDLING.key) {
                shelter = ShelterStatus.IM_HEIM.key;
            } else {
                shelter = ShelterStatus.NICHT_IM_HEIM.key;
            }

            const transactionModel: TransactionEntryModel = {
                id: -1,
                transactionId: -1,
                supervisions: [],
                actionTypeId: selectedActionType.id,
                date: moment(acquisitionGiveAwayValue.date).format('YYYY-MM-DD HH:mm:ss'),
                person: selectedPerson,
                animal: updatedAnimal,
                giveAwayReason: acquisitionGiveAwayValue.giveAwayReason ? acquisitionGiveAwayValue.giveAwayReason : '',
                takingOverReason: acquisitionGiveAwayValue.takingOverReason ? acquisitionGiveAwayValue.takingOverReason : '',
                publicAuthority: acquisitionGiveAwayValue.publicAuthority ? acquisitionGiveAwayValue.publicAuthority : '',
                owner: selectedOwner,
                stayFrom: typeof selectedStart !== 'undefined' && selectedStart !== null && selectedStart !== '' ?
                    moment(acquisitionGiveAwayValue.stayFrom).format('YYYY-MM-DD') : null,
                stayUntil: typeof selectedEnd !== 'undefined' && selectedEnd !== null && selectedEnd !== '' ?
                    moment(acquisitionGiveAwayValue.stayUntil).format('YYYY-MM-DD') : null,
                deposit: typeof deposit !== 'undefined' && deposit !== null && deposit !== '' ?
                    deposit.replace(/,/g, '.') : '0',
                shelter,
                streetFound: acquisitionGiveAwayValue.streetFound ? acquisitionGiveAwayValue.streetFound : '',
                zipFound: zip,
                cityFound: acquisitionGiveAwayValue.cityFound ? acquisitionGiveAwayValue.cityFound : '',
                howAnimalWasFound: acquisitionGiveAwayValue.howAnimalWasFound,
                costsPerDayTotal: costsPerDayForAnimal !== '' ?
                    costsPerDayForAnimal.replace(/,/g, '.') : '0',
                depositTotal: typeof depositTotal !== 'undefined' && depositTotal !== null && depositTotal !== '' ?
                    depositTotal.replace(/,/g, '.') : '0',
                additionalCosts: additionalCostsForAnimal !== '' ?
                    additionalCostsForAnimal.replace(/,/g, '.') : '0',
                isPersonAddressResidenceAddress: acquisitionGiveAwayValue.isPersonAddressResidenceAddress ?
                    acquisitionGiveAwayValue.isPersonAddressResidenceAddress : false,
                residenceStreet: acquisitionGiveAwayValue.residenceStreet ? acquisitionGiveAwayValue.residenceStreet : '',
                residenceZip: acquisitionGiveAwayValue.residenceZip ? acquisitionGiveAwayValue.residenceZip : '',
                residenceCity: acquisitionGiveAwayValue.residenceCity ? acquisitionGiveAwayValue.residenceCity : '',
                hasFence: acquisitionGiveAwayValue.hasFence !== '',
                hasGarden: acquisitionGiveAwayValue.hasGarden !== '',
                otherAnimalsInHousehold: acquisitionGiveAwayValue.otherAnimalsInHousehold,
                deathReason: '',
                wasPrinted: false
            };
            additionalCostsAccumulated += +transactionModel.additionalCosts;
            costsPerDayAccumulated += +transactionModel.costsPerDayTotal;
            this.newTransactions.push(transactionModel);

            const modifiedAnimal: AnimalEntryModel = {
                ...animal,
                type: selectedActionType
            };

            if (animal.id < 0) {
                newAnimals.push(modifiedAnimal);
            } else {
                updatedAnimals.push(modifiedAnimal);
            }
        });

        this.printingData = {
            selectedPerson,
            selectedOwner,
            selectedActionType,
            acquisitionGiveAwayValue,
            selectedStart,
            selectedEnd,
            deposit,
            depositTotal,
            costsPerDayAccumulated,
            additionalCostsAccumulated
        };

        this.animalService.addAndUpdateAnimals(newAnimals, updatedAnimals, false);
        this.fetchedPersons = [];
    }

    private hasErrors(acquisitionGiveAwayValue: any, selectedStart: any, selectedEnd: any): boolean {
        if ((acquisitionGiveAwayValue.actionType === ActionType.RUECKGABE_PENSIONSTIER.key ||
            acquisitionGiveAwayValue.actionType === ActionType.ANNAHME_PENSIONSTIER.key) &&
            (!selectedStart || !selectedEnd || selectedStart.isAfter(selectedEnd))) {
            this.acquisitionGiveAwayForm.controls.stayFrom.setErrors({incorrect: true});
            this.acquisitionGiveAwayForm.controls.stayUntil.setErrors({incorrect: true});
            this.displayErroneousInputs = true;
            return true;
        }

        if (acquisitionGiveAwayValue.bringerReceiverAreSame === 'no' &&
            (acquisitionGiveAwayValue.owner === '' || acquisitionGiveAwayValue.owner === null)) {
            this.acquisitionGiveAwayForm.controls.owner.setErrors({incorrect: true});
            this.displayErroneousInputs = true;
            return true;
        }

        if (!this.acquisitionGiveAwayForm.valid || this.animalsInTable.length === 0) {
            this.displayErroneousInputs = true;
            return true;
        }

        if (acquisitionGiveAwayValue.actionType === ActionType.VERGABE.key && this.incorrectChipNumber()) {
            this.snackbarService.displaySnackbar(SnackStatusType.ERROR,
                'Ein Hund oder Katze hat eine ungültige Chipnummer!', 10);
            return true;
        }

        return false;
    }

    private incorrectChipNumber(includeSmallAnimals = false): boolean {
        let incorrectNumber = false;
        this.animalsInTable.forEach(animal => {
            const isCorrectSpecies = includeSmallAnimals ? true :
                (animal.species.name === 'Hund' || animal.species.name === 'Katze');

            if (isCorrectSpecies && !/^\d{15}$/.test(animal.chipNumber) &&
                !incorrectNumber) {
                incorrectNumber = true;
            }
        });
        return incorrectNumber;
    }

    private initActionTypeSelectBox(): void {
        this.fetchedActionTypesSub = this.actionTypesService.populateActionTypeSelectBox()
            .subscribe(actionTypes => {
                const natEl = this.actionTypeSelect._elementRef.nativeElement;
                const typeDiv = this.renderer.createElement('div');
                this.acquisitionGiveAwayForm.controls.actionType.setValue(this.selected);

                this.renderer.addClass(typeDiv, 'animal-type');
                this.renderer.addClass(typeDiv, 'selected');
                this.renderer.addClass(typeDiv, this.selected);
                this.renderer.appendChild(natEl.children[0].children[0], typeDiv);

                this.actionTypes = actionTypes.filter(option => {
                    if (this.selected === option.value) {
                        const color = TupleHelper.getValue(option.meta, 'color');
                        this.renderer.setStyle(typeDiv, 'background-color', color);
                    }

                    if (option.value !== ActionType.HIMMELFAHRT.key &&
                        option.value !== ActionType.VERMISST.key &&
                        option.value !== ActionType.BACK_TO_SHELTER.key &&
                        option.value) {
                        const optionIsAcquisition = TupleHelper.getValue(option.meta, 'isAcquisition');
                        if ((this.isAcquisition && optionIsAcquisition) ||
                            (!this.isAcquisition && !optionIsAcquisition)) {
                            return option;
                        }
                    }
                });

                // Reorder give away actions
                if (!this.isAcquisition) {
                    const reorderedTypesArr = new Array<DropdownOption>();
                    reorderedTypesArr.push(this.actionTypes.find(type => type.value === ActionType.VERGABE.key));
                    reorderedTypesArr.push(this.actionTypes.find(type => type.value === ActionType.RUECKGABE.key));
                    reorderedTypesArr.push(this.actionTypes.find(type => type.value === ActionType.RUECKGABE_PENSIONSTIER.key));
                    this.actionTypes = reorderedTypesArr;
                }
            });
        this.currentSelectClass = this.selected;
    }

    private populatePersonSelect(content: PersonEntryModel[]): void {
        if (this.animalHolderBoxClicked) {
            this.personSelectSearch.createPersonSelectBox(content);
            this.acquisitionGiveAwayForm.patchValue({
                person: content.slice().pop()
            });
        } else {
            this.ownerSelectSearch.createPersonSelectBox(content);
            this.acquisitionGiveAwayForm.patchValue({
                owner: content.slice().pop()
            });
        }
    }

    private showRemark(remark: string): void {
        if (typeof remark !== 'undefined' && remark !== null && remark !== '' && !this.isAcquisition) {
            const dialogRef = this.dialog.open(SimpleDialogComponent, {
                width: '900px',
                panelClass: 'component-wrapper',
                data: {
                    type: this.dialogType.TRIVIAL,
                    entity: 'Bemerkung vorhanden',
                    content: 'Bei dieser Person ist folgende Bemerkung vorhanden: ' + remark
                }
            });

            dialogRef.afterOpened().subscribe(() => {
                this.personSelect.close();
                if (typeof this.ownerSelect !== 'undefined') {
                    this.ownerSelect.close();
                }
            });
        }
    }

    private emitPrintingData(animals: AnimalEntryModel[],
                             person: PersonEntryModel,
                             owner: PersonEntryModel,
                             actionType: ActionTypeEntryModel,
                             acquisitionGiveAwayValue: any,
                             selectedStart: any,
                             selectedEnd: any,
                             deposit: any,
                             depositTotal: any,
                             costsPerDayAccumulated,
                             additionalCostsAccumulated,
                             newPrintingTransaction: TransactionEntryModel,
                             avatars?: MediaEntryModel[]): void {
        const mappedAnimals = animals.map(animal => {
            const updatedHistory = animal.transactionHistory?.slice();
            updatedHistory.push(newPrintingTransaction);
            const updatedAnimal: AnimalEntryModel = {
                ...animal,
                transactionHistory: updatedHistory
            };
            return updatedAnimal;
        });

        const zipFound = this.getZip(acquisitionGiveAwayValue);

        const printingData: PrintingDataAnimal = {
            animals: mappedAnimals,
            person,
            owner,
            date: moment(acquisitionGiveAwayValue.date).format('YYYY-MM-DD'),
            actionType,
            animalPlace: acquisitionGiveAwayValue.animalPlace === 'yes' || acquisitionGiveAwayValue.animalPlace === '',
            residenceStreet: acquisitionGiveAwayValue.residenceStreet,
            residenceZip: acquisitionGiveAwayValue.residenceZip,
            residenceCity: acquisitionGiveAwayValue.residenceCity,
            hasFence: acquisitionGiveAwayValue.hasFence && acquisitionGiveAwayValue.hasFence.toLowerCase() === 'yes',
            hasGarden: acquisitionGiveAwayValue.hasGarden && acquisitionGiveAwayValue.hasGarden.toLowerCase() === 'yes',
            otherAnimalsInHousehold: acquisitionGiveAwayValue.otherAnimalsInHousehold,
            giveAwayReason: acquisitionGiveAwayValue.giveAwayReason ? acquisitionGiveAwayValue.giveAwayReason : '',
            takingOverReason: acquisitionGiveAwayValue.takingOverReason ? acquisitionGiveAwayValue.takingOverReason : '',
            howAnimalWasFound: acquisitionGiveAwayValue.howAnimalWasFound,
            streetFound: acquisitionGiveAwayValue.streetFound ? acquisitionGiveAwayValue.streetFound : '',
            zipFound,
            cityFound: acquisitionGiveAwayValue.cityFound ? acquisitionGiveAwayValue.cityFound : '',
            stayFrom: typeof selectedStart !== 'undefined' && selectedStart !== null && selectedStart !== '' ?
                moment(acquisitionGiveAwayValue.stayFrom).format('YYYY-MM-DD') : null,
            stayUntil: typeof selectedEnd !== 'undefined' && selectedEnd !== null && selectedEnd !== '' ?
                moment(acquisitionGiveAwayValue.stayUntil).format('YYYY-MM-DD') : null,
            deposit: typeof deposit !== 'undefined' && deposit !== null && deposit !== '' ?
                deposit.replace(/,/g, '.') : '0',
            depositTotal: typeof depositTotal !== 'undefined' && depositTotal !== null && depositTotal !== '' ?
                depositTotal.replace(/,/g, '.') : '0',
            costsPerDayAccumulated,
            additionalCostsAccumulated,
            avatars
        };
        this.printingService.dataFetched.next(printingData);
    }

    private updateFormControls(): void {
        this.acquisitionGiveAwayForm.removeControl('streetFound');
        this.acquisitionGiveAwayForm.removeControl('zipFound');
        this.acquisitionGiveAwayForm.removeControl('cityFound');
        this.acquisitionGiveAwayForm.removeControl('howAnimalWasFound');
        this.acquisitionGiveAwayForm.removeControl('costsPerDayTotal');
        this.acquisitionGiveAwayForm.removeControl('depositTotal');
        this.acquisitionGiveAwayForm.removeControl('additionalCosts');
        this.acquisitionGiveAwayForm.removeControl('stayFrom');
        this.acquisitionGiveAwayForm.removeControl('stayUntil');
        this.acquisitionGiveAwayForm.removeControl('animalPlace');
        this.acquisitionGiveAwayForm.removeControl('residenceStreet');
        this.acquisitionGiveAwayForm.removeControl('residenceZip');
        this.acquisitionGiveAwayForm.removeControl('residenceCity');
        this.acquisitionGiveAwayForm.removeControl('hasFence');
        this.acquisitionGiveAwayForm.removeControl('hasGarden');
        this.acquisitionGiveAwayForm.removeControl('otherAnimalsInHousehold');
        this.acquisitionGiveAwayForm.removeControl('takingOverReason');
        this.acquisitionGiveAwayForm.removeControl('publicAuthority');
        this.acquisitionGiveAwayForm.removeControl('giveAwayReason');
    }

    private getZip(acquisitionGiveAwayValue: any): string {
        let zip = '';
        if (acquisitionGiveAwayValue.zipFound) {
            if (typeof acquisitionGiveAwayValue.zipFound === 'string') {
                zip = acquisitionGiveAwayValue.zipFound;
            } else {
                zip = TupleHelper.getValue(acquisitionGiveAwayValue.zipFound.meta, 'zip');
            }
        }

        return zip;
    }

    private print(animals: AnimalEntryModel[],
                  newPrintingTransaction: TransactionEntryModel,
                  actionState: fromActionAction.SetActions,
                  avatars?: MediaEntryModel[]): void {
        if (this.printingData !== null && typeof this.printingData !== 'undefined') {
            this.emitPrintingData(animals,
                this.printingData.selectedPerson,
                this.printingData.selectedOwner,
                this.printingData.selectedActionType,
                this.printingData.acquisitionGiveAwayValue,
                this.printingData.selectedStart,
                this.printingData.selectedEnd,
                this.printingData.deposit,
                this.printingData.depositTotal,
                this.printingData.costsPerDayAccumulated,
                this.printingData.additionalCostsAccumulated,
                newPrintingTransaction,
                avatars);
        }

        /// Reset
        this.animalsInTable = [];
        this.newTransactions = [];
        this.rueckgabePensionstierCostInfo = [];
        this.animalService.animalsSelected.next(null);
        this.animalService.animalsInTableChanged.next(null);
        this.printingService.finishedPrinting.next(false);
        this.personSelectSearch.createPersonSelectBox([]);
        this.ownerSelectSearch.createPersonSelectBox([]);
        this.rueckgabePensionstierService.formResetted.next(true);
        if (!this.apiErrorOccurred) {
            this.actionsService.handleRequestSuccess(actionState.payload.crud, 'Transaktion', '');
        } else {
            this.snackbarService.displaySnackbar(SnackStatusType.ERROR, 'API Error', 10);
            this.apiErrorOccurred = false;
        }
    }
}
