import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {CRUD} from '../../../shared/services/http/crud';
import {VeterinaryTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/veterinary-treatment-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import * as fromTreatmentsActions from './veterinary-treatments.actions';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class VeterinaryTreatmentsEffects {
    @Effect()
    loadVeterinaryTreatments$ = this.actions$.pipe(
        ofType(fromTreatmentsActions.LOAD_VETERINARY_TREATMENTS),
        switchMap((veterinaryState: fromTreatmentsActions.LoadVeterinaryTreatments) => {
            const page = veterinaryState.payload.page;
            const size = veterinaryState.payload.size;
            const order = veterinaryState.payload.order;
            const orderColumn = veterinaryState.payload.column;
            const filter = veterinaryState.payload.filter;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (filter) {
                params = params.append('filter', 'true');
                params = typeof filter.id !== 'undefined' && filter.id !== '' ?
                    params.append('id', filter.id) : params;
                params = typeof filter.firstName !== 'undefined' && filter.firstName !== '' ?
                    params.append('first_name', filter.firstName) : params;
                params = typeof filter.lastName !== 'undefined' && filter.lastName !== '' ?
                    params.append('last_name', filter.lastName) : params;
                params = typeof filter.street !== 'undefined' && filter.street !== '' ?
                    params.append('street', filter.street) : params;
                params = typeof filter.zip !== 'undefined' && filter.zip !== '' ?
                    params.append('zip', filter.zip) : params;
                params = typeof filter.city !== 'undefined' && filter.city !== '' ?
                    params.append('city', filter.city) : params;
                params = typeof filter.birthday !== 'undefined' && filter.birthday !== '' ?
                    params.append('birthday', filter.birthday) : params;
            }

            return this.endpointService.treatments(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params});
                    }),
                    map(response => {
                        const totalElements = response.data.pagination.totalElements;
                        const lastPage = response.data.pagination.pages;
                        const mappedTreatmentArr: VeterinaryTreatmentEntryModel[] = response.data.data.map(treatment => {
                            const mappedTreatment: VeterinaryTreatmentEntryModel = {
                                id: treatment.id,
                                description: treatment.treatment,
                                invoiceText: treatment.billing_text,
                                price: treatment.price
                            };
                            return mappedTreatment;
                        });

                        return new fromTreatmentsActions.SetVeterinaryTreatments({
                            treatments: mappedTreatmentArr,
                            totalElements,
                            crud: veterinaryState.payload.crud,
                            lastPage
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    addVeterinaryTreatment$ = this.actions$.pipe(
        ofType(fromTreatmentsActions.ADD_VETERINARY_TREATMENT),
        switchMap((treatmentState: fromTreatmentsActions.AddVeterinaryTreatment) => {
            return this.endpointService.treatments(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                treatment: treatmentState.payload.treatment.description,
                                billing_text: treatmentState.payload.treatment.invoiceText,
                                price: treatmentState.payload.treatment.price
                            }
                        ).pipe(
                            map(() => {
                                return new fromTreatmentsActions.LoadVeterinaryTreatments(
                                    {
                                        crud: CRUD.CREATE,
                                        order: treatmentState.payload.order,
                                        column: treatmentState.payload.column,
                                        size: treatmentState.payload.size,
                                        page: treatmentState.payload.page
                                    }
                                );
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateVeterinaryTreatment$ = this.actions$.pipe(
        ofType(fromTreatmentsActions.UPDATE_VETERINARY_TREATMENT),
        switchMap((treatmentState: fromTreatmentsActions.UpdateVeterinaryTreatment) => {
            return this.endpointService.treatments(HttpMethod.PUT, treatmentState.payload.treatment.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                treatment: treatmentState.payload.treatment.description,
                                billing_text: treatmentState.payload.treatment.invoiceText,
                                price: treatmentState.payload.treatment.price
                            }
                        ).pipe(
                            map(() => {
                                return new fromTreatmentsActions.LoadVeterinaryTreatments({
                                    crud: CRUD.UPDATE,
                                    order: treatmentState.payload.order,
                                    column: treatmentState.payload.column,
                                    size: treatmentState.payload.size,
                                    page: treatmentState.payload.page
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteVeterinaryTreatment$ = this.actions$.pipe(
        ofType(fromTreatmentsActions.DELETE_VETERINARY_TREATMENT),
        switchMap((treatmentState: fromTreatmentsActions.DeleteVeterinaryTreatment) => {
            return this.endpointService.treatments(HttpMethod.DELETE, treatmentState.payload.treatmentId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromTreatmentsActions.LoadVeterinaryTreatments({
                                    crud: CRUD.DELETE,
                                    order: treatmentState.payload.order,
                                    column: treatmentState.payload.column,
                                    size: treatmentState.payload.size,
                                    page: treatmentState.payload.page
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromTreatmentsActions.HttpFail> => {
        return handleHTTPError(errorRes, fromTreatmentsActions, 'medizinischen Maßnahmen', this.loggingService);
    };
}
