import {ErrorTypes} from './data-types/error-types';
import {Observable, of} from 'rxjs';
import {LoggingService} from './logging/logging.service';

export function handleHTTPError(errorRes: any,
                                actionType: any,
                                identifier: string,
                                loggingService: LoggingService,
                                errorObjKey?: string): Observable<any> {
    const errorObj: ErrorTypes = {
        isAuthorized: true,
        message: ''
    };

    if (errorRes?.error?.status_code === 401) {
        errorObj.isAuthorized = false;
        errorObj.message = 'Sie sind nicht eingeloggt!';
        return of(new actionType.HttpFail(errorObj));
    } else {
        errorObj.message = 'Ein allgemeiner Fehler bei den ' + identifier + '  ist aufgetreten. ';
        if (errorRes.error &&
            errorRes.error.data &&
            errorRes.error.data.errors) {
            if (errorObjKey) {
                errorObj.message += ' ' + errorRes.error.data.errors[errorObjKey];
            }
            loggingService.addLogging(errorRes.error.data.errors);
            return of(new actionType.HttpFail(errorObj));
        }

        if (errorRes.message) {
            errorObj.message += 'Beschreibung: ';
            errorObj.message += errorRes.message;
            loggingService.addLogging(errorObj.message);
            return of(new actionType.HttpFail(errorObj));
        }
        return of(new actionType.HttpFail(errorObj));
    }
}
