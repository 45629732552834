import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDatetimePickerInputEvent, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {BookingService} from '../booking.service';
import {Observable, Subscription} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Order} from '../../shared/controls/data-table/ordering';
import {Actions, ofType} from '@ngrx/effects';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map, take, tap} from 'rxjs/operators';
import {BookingEntryModel} from '../../shared/models/pos/booking-entry.model';
import {Constants} from '../../shared/constants';
import {PosSystemService} from '../pos-system.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {GroupedBookingEntryModel} from '../../shared/models/pos/grouped-booking-entry.model';
import * as fromBookingActions from '../store/booking/booking.actions';
import {PrintingService} from '../../printing/printing.service';
import {PrintingDataCashbook, PrintingTemplate} from '../../printing/templates/TemplateTypes';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-cashbook',
    templateUrl: './cashbook.component.html',
    styleUrls: ['./cashbook.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class CashbookComponent implements OnInit, OnDestroy {
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('datePickerTo') public datePickerTo: any;
    @ViewChild('paginator') public paginator: MatPaginator;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public displayedColumns: string[];
    public entriesPerPage = 10000;
    public pageIndex = 1;
    public order = Order.NONE;
    public orderedColumn = '';
    public groupedSumIncome = 0;
    public groupedSumOutcome = 0;
    public groupedSumDelta = 0;
    public groupedSumATM = 0;
    public PrintingTemplate = PrintingTemplate;

    public tableDef: Array<{ key: string, header: string, className: string }> = [
        {
            key: 'id',
            header: 'Buchungs-Nr.',
            className: 'booking-number'
        }, {
            key: 'category',
            header: 'Kategorie',
            className: 'category'
        }, {
            key: 'article',
            header: 'Artikel',
            className: 'article'
        }, {
            key: 'description',
            header: 'Beschreibung',
            className: 'description'
        }, {
            key: 'remark',
            header: 'Bemerkung',
            className: 'remark'
        }, {
            key: 'income',
            header: 'Eingang',
            className: 'income'
        }, {
            key: 'outcome',
            header: 'Ausgang',
            className: 'outcome'
        }, {
            key: 'delta',
            header: 'Differenz',
            className: 'delta'
        }, {
            key: 'dateCreated',
            header: 'Datum',
            className: 'dateCreated'
        }, {
            key: 'cashier',
            header: 'Kassier',
            className: 'cashier'
        }, {
            key: 'atm',
            header: 'Bankomat',
            className: 'atm'
        }, {
            key: 'dateCancelled',
            header: 'Stornierung',
            className: 'isCancelled'
        }
    ];
    public loading = false;
    public bookingLength = 0;
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public tableEntries: any[] = [];
    public grouped = false;
    public selectedStart: Moment;
    public selectedEnd: Moment;
    public newCashPosition = 0;

    private extendedColumns: string[] = [
        'id',
        'category',
        'article',
        'income',
        'outcome',
        'delta',
        'dateCreated',
        'cashier',
        'atm',
        'cancellation',
        'description',
        'remark'
    ];
    private reducedColumns: string[] = [
        'category',
        'income',
        'outcome',
        'delta',
        'atm'
    ];
    private detailedCashbookEntries: any[];
    private bookingSub: Subscription;
    private notSortable = ['price', 'income', 'outcome', 'delta', 'atm'];
    private cashbookSub: Subscription;

    constructor(private posSystemService: PosSystemService,
                private bookingService: BookingService,
                private formBuilder: FormBuilder,
                private authService: AuthService,
                private actions$: Actions,
                private printingService: PrintingService) {
    }

    ngOnInit(): void {
        this.initCashbookTable();
        this.initForm();
        this.initErrorHandling();
        this.displayedColumns = this.extendedColumns;
    }

    ngOnDestroy(): void {
        if (this.bookingSub) {
            this.bookingSub.unsubscribe();
        }
        if (this.cashbookSub) {
            this.cashbookSub.unsubscribe();
        }
    }

    public orderData($event: Sort): void {
        if ($event.direction === 'desc') {
            this.order = Order.DESC;
        } else if ($event.direction === 'asc') {
            this.order = Order.ASC;
        } else {
            this.order = Order.NONE;
        }

        this.orderedColumn = $event.active === 'dateCreated' ? 'date_created' : $event.active;
        this.getCashbookEntries(this.entriesPerPage,
            this.pageIndex,
            this.selectedStart,
            this.selectedEnd,
            this.order,
            this.orderedColumn,
            this.grouped).subscribe(bookings => {
            this.detailedCashbookEntries = bookings;
            this.tableEntries = bookings;
            this.calculateSummations(bookings);
        });
    }

    public columnSortable(key: any): boolean {
        return !this.notSortable.includes(key);
    }

    public calcDelta(element: any): number {
        if (this.grouped) {
            return element.delta;
        } else {
            const income = element.income !== 0 ? element.income : element.atm;
            const outcome = element.outcome;
            return income - outcome;
        }
    }

    public groupByCategory(checked: boolean): void {
        if (this.bookingLength > 0 && this.filterForm.valid) {
            this.grouped = checked;
            this.displayedColumns = this.grouped ? this.reducedColumns : this.extendedColumns;
            this.search();
        }
    }

    public dateChanged($event: MatDatetimePickerInputEvent<Moment>, isStart: boolean): void {
        if (isStart) {
            this.selectedStart = $event.value.set({hour: 0, minute: 0, second: 0});
        } else {
            this.selectedEnd = $event.value.set({hour: 23, minute: 59, second: 59});
        }
    }

    public search(): void {
        this.displayErroneousInputs = true;

        if (!this.filterForm.valid) {
            return;
        }

        if (!this.selectedStart || !this.selectedEnd || this.selectedStart.isAfter(this.selectedEnd)) {
            this.filterForm.controls.from.setErrors({incorrect: true});
            return;
        }

        this.loading = true;
        this.cashbookSub = this.getCashbookEntries(this.entriesPerPage,
            this.pageIndex,
            this.selectedStart,
            this.selectedEnd,
            this.order,
            this.orderedColumn,
            this.grouped).subscribe((bookings: BookingEntryModel[] | GroupedBookingEntryModel[]) => {
            this.detailedCashbookEntries = bookings;
            this.tableEntries = bookings;
            if (this.grouped) {
                this.calculateGroupedSummations(bookings as GroupedBookingEntryModel[]);
            } else {
                this.calculateSummations(bookings as BookingEntryModel[]);
            }
        });
    }

    public print(): void {
        if (this.tableEntries.length > 0) {
            this.bookingService.fetchCashPosition(this.selectedStart.format('YYYY-MM-DD'));

            this.actions$.pipe(
                ofType(fromBookingActions.SET_CASH_POSITION),
                take(1)
            ).subscribe((response: fromBookingActions.SetCashPosition) => {
                const cashPosition = response.payload;
                this.cashbookSub = this.getCashbookEntries(this.entriesPerPage,
                    1,
                    this.selectedStart,
                    this.selectedEnd,
                    this.order,
                    this.orderedColumn,
                    this.grouped).subscribe((bookings: BookingEntryModel[] | GroupedBookingEntryModel[]) => {
                    const printingData: PrintingDataCashbook = {
                        from: this.selectedStart.format('YYYY-MM-DD HH:mm:ss'),
                        to: this.selectedEnd.format('YYYY-MM-DD HH:mm:ss'),
                        cashbookEntries: bookings,
                        grouped: this.grouped,
                        sumIncome: this.groupedSumIncome,
                        sumOutcome: this.groupedSumOutcome,
                        sumDelta: this.groupedSumDelta,
                        sumATM: this.groupedSumATM,
                        cashPosition,
                        newCashPosition: this.newCashPosition,
                        cashier: this.authService.loggedInUser.value
                    };
                    this.printingService.dataFetched.next(printingData);
                });
            });
        }
    }

    private initForm(): void {
        this.selectedStart = moment().set({hour: 0, minute: 0, second: 0});
        this.selectedEnd = moment().set({hour: 23, minute: 59, second: 59});
        this.filterForm = this.formBuilder.group({
            from: [this.selectedStart, [Validators.required]],
            to: [this.selectedEnd, [Validators.required]],
            grouped: ['']
        });
    }

    private getCashbookEntries(entriesPerPage = -1,
                               pageIndex = -1,
                               selectedStart = null,
                               selectedEnd = null,
                               order = Order.NONE,
                               orderedColumn = '',
                               grouped = false): Observable<BookingEntryModel[]> {
        return this.bookingService.fetchBookings(entriesPerPage,
            pageIndex,
            selectedStart,
            selectedEnd,
            order,
            orderedColumn,
            grouped).pipe(
            map((bookingEntries: BookingEntryModel[]) => {
                return bookingEntries.map(bookingEntry => {
                    if (grouped) {
                        return bookingEntry;
                    } else {
                        return {
                            ...bookingEntry,
                            article: bookingEntry.article,
                            category: bookingEntry.category,
                            date: bookingEntry.dateCreated,
                            income: bookingEntry.type === Constants.Income && !bookingEntry.isATM ? +bookingEntry.price : 0,
                            outcome: bookingEntry.type === Constants.Outcome && !bookingEntry.isATM ? +bookingEntry.price : 0,
                            atm: bookingEntry.isATM ? +bookingEntry.price : 0
                        };
                    }
                });
            })
        );
    }

    private calculateSummations(entries: BookingEntryModel[]): void {
        this.groupedSumDelta = 0;
        this.groupedSumIncome = 0;
        this.groupedSumOutcome = 0;
        this.groupedSumATM = 0;

        entries.forEach(entry => {
            const income = entry.type === Constants.Income && !entry.isATM ? +entry.price : 0;
            const outcome = entry.type === Constants.Outcome && !entry.isATM ? +entry.price : 0;
            const atm = entry.isATM ? +entry.price : 0;
            const deltaIncome = income !== 0 ? income : atm;

            this.groupedSumDelta += deltaIncome - outcome;
            this.groupedSumIncome += income;
            this.groupedSumOutcome += outcome;
            this.groupedSumATM += atm;
        });
    }

    private calculateGroupedSummations(entries: GroupedBookingEntryModel[]): void {
        this.groupedSumDelta = 0;
        this.groupedSumIncome = 0;
        this.groupedSumOutcome = 0;
        this.groupedSumATM = 0;

        entries.forEach(entry => {
            this.groupedSumDelta += entry.delta;
            this.groupedSumIncome += entry.income;
            this.groupedSumOutcome += entry.outcome;
            this.groupedSumATM += entry.atm;
        });
    }

    private initCashbookTable(): void {
        this.bookingSub = this.actions$.pipe(
            ofType(fromBookingActions.SET_BOOKINGS),
            tap((bookingState: fromBookingActions.SetBookings) => {
                this.bookingLength = bookingState.payload.totalElements;
                this.loading = false;
                this.newCashPosition = bookingState.payload.cashPosition;
            })
        ).subscribe();
    }

    private initErrorHandling(): void {
        this.actions$.pipe(
            ofType(fromBookingActions.HTTP_FAIL)
        ).subscribe((httpFail: fromBookingActions.HttpFail) => {
            this.posSystemService.handleRequestError(httpFail.payload.message);
            if (!httpFail.payload.isAuthorized) {
                this.authService.sendLogout();
            }
        });
    }
}
