<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Übergabevertrag</h1>
    <span class="d-inline-block mt-4">abgeschlossen zwischen dem <br />
        <span class="font-weight-bolder position-relative">
            <span class="position-relative" style="left:45px">OÖ Landestierschutzverein</span>
            <span class="position-relative" style="left:200px;">{{date | date: 'dd.MM.yyyy'}}</span> <br />
            {{shelterName}} <br />
            {{address}} <br />
            {{zipAndCity}} </span> <br />
    als „Übergeber“ einerseits und
</span>

    <div class="d-flex justify-content-around mt-5" *ngIf="person !== null && person !== undefined">
        <div class="left-col text-left">
            <div class="font-weight-bolder">{{person.firstName + ' ' + person.lastName}}</div>
            <div>{{person.street}}</div>
            <div>{{person.zip}} - {{person.city}}</div>
            <div>Tel.: {{person.phone}}</div>
        </div>

        <div class="right-col text-left">
            <div>PNr.: {{person.id}}</div>
            <div>Geb. Datum: {{person.birthday | date: 'dd.MM.yyyy'}}</div>
            <div>Nachweis: {{person.proof}}</div>
            <div>E-Mail: {{person.email}}</div>
        </div>
    </div>

    <div class="mt-5">im Folgenden „Übernehmer“ genannt, andererseits.</div>

    <div class="mt-4 mb-5">Folgende Tiere werden mit heutigem Datum der oa. Person übergeben:</div>

    <section *ngIf="animals !== null && animals !== undefined" style="overflow:visible !important;">
        <ng-container *ngFor="let animal of animals">
            <div class="d-flex flex-wrap justify-content-between mt-4 entry">
                <div class="left-col text-left">
                    <div class="font-weight-bolder">LfNr.: {{animal.id}}</div>
                    <div>Name: {{animal.name}}</div>
                    <div>Tierart: {{animal.species.name}}</div>
                    <div>Geschlecht: {{Gender.getGender(animal.gender)}}</div>
                    <div>Beschreibung: {{characteristicsService.printCharacteristics(animal.characteristics)}}</div>
                </div>

                <div class="right-col text-left">
                    <div>Chip-Nr.: {{animal.chipNumber}}</div>
                    <div>Rasse: {{animal.race.name}}</div>
                    <div>Kastriert: {{CastrationStatus.getCastrationStatus(animal.castrated)}}</div>
                    <div>Alter: {{Age.printAge(animal.birthday)}}</div>
                </div>
                <div class="horLine w-100 pb-4"></div>
            </div>
        </ng-container>
    </section>

    <section class="text-left" style="margin-top: 100px">
        <div>
            <span>Das Tier wird an folgender Adresse gehalten: </span>
            <span *ngIf="animalPlace">Adresse wie oben</span>
            <span *ngIf="!animalPlace">{{residenceStreet}}, {{residenceZip}} - {{residenceCity}}</span>
        </div>
        <div>Garten vorhanden: {{hasGarden ? 'Ja' : 'Nein'}}</div>
        <div>Sicherer Zaun vorhanden: {{hasFence ? 'Ja' : 'Nein'}}</div>
        <div>Es leben noch folgende Tiere in diesem Haushalt:
            {{otherAnimalsInHousehold === '' ? 'Keine' : otherAnimalsInHousehold}}
        </div>
    </section>

    <ng-container *ngIf="species === 'katze'">  <!-- Katzen -->
        <section class="text-left" style="page-break-before: always">
            <h3>I. Pflichten als Tierhalter</h3>

            1. Mit Unterzeichnung dieses Vertrages und der Übergabe und Übernahme des Tieres übernimmt der Halter dieses
            in
            seine Verantwortung und Obsorge. Die Haltereigenschaft sowie alle damit verbundenen Rechte und Pflichten
            gehen
            auf den Übernehmer über. <br /><br />

            2. Dies bedeutet, dass ab diesem Zeitpunkt der Übergeber nicht mehr für Kosten und Schäden, die aus der
            Tierhaltung entstehen (wie Tierarztkosten, Futter, Versicherung, Steuer, Bissverletzungen...), aufkommt bzw.
            haftet.<br /><br />

            3. Der Übernehmer verpflichtet sich, als Tierhalter die Katze art- und tierschutzgerecht nach den
            Bestimmungen
            des österreichischen Bundestierschutzgesetzes zu halten, zu ernähren und zu versorgen. Dies bedeutet
            insbesondere auch regelmäßige tierärztliche Kontrollen und die erforderliche tierärztliche Behandlung (wie
            Impfungen,...) für das übernommene Tier zu gewährleisten.<br /><br />

            4. Der Übernehmer sorgt vor, dass die Katze auch während seiner Abwesenheiten eine tierschutzgerechte
            Versorgung
            erhält.<br /><br />

            5. Der Übernehmer verpflichtet sich, die Katze nicht zur Züchtung bzw. für Tierversuche zu verwenden. Der
            Übernehmer verpflichtet sich, das Tier weder in Zwingerhaltung, noch in Keller-, Garagen-, Heiz-, oder
            Stallräumen zu halten.

            <h3>II. Gewährleistungsausschluss</h3>

            Die Katze wurde in einem Tierheim des OÖ. Landestierschutzvereines von einem Tierarzt für abgabetauglich
            erklärt.<br />
            Der Übernehmer erklärt, das Tier eingehend besichtigt und begutachtet zu haben. Er übernimmt es aus
            besonderer
            Vorliebe und unter Verzicht auf jegliche Gewährleistung.<br />
            Der Übernehmer nimmt zur Kenntnis, dass die Vorgeschichte von Tieren, welche im Tierheim betreut wurden,
            nicht
            immer gänzlich geklärt ist. Die Beschreibungen von Charakter und Gesundheitszustand erfolgen nach bestem
            Wissen
            und Gewissen und nach Beobachtung der Katze durch den Übergeber. Es kann das Vorliegen von Umständen und
            Verhaltensweisen, die dem Übergeber verborgen geblieben sind, nicht ausgeschlossen werden. Jegliche
            Gewährleistung sowie sonstige Haftung des Übergebers werden daher ausdrücklich ausgeschlossen.<br />
            Davon ausgenommen ist der Fall, dass die Katze innerhalb von 3 Tagen nach der Übergabe erkrankt (wie
            Durchfall,
            Fieber, Erbrechen,...). In diesem Fall wenden Sie sich bitte umgehend an das Tierheim.<br />
            Bei Inanspruchnahme eines anderen Tierarztes können keine Kosten rückerstattet werden.

            <h3>III. Weitergabe</h3>

            Der Übernehmer verpflichtet sich, die Weitergabe, den Verlust oder Tod der Katze dem Übergeber zu melden.
            Vor Weitergabe an einen Dritten ist die Zustimmung des Übergebers einzuholen.
            Adressänderungen des Übernehmers sind unverzüglich bekanntzugeben.

            <h3>IV. Kontrollrechte des OÖ Landestierschutzvereines</h3>

            Der Übernehmer gestattet dem Übergeber bzw. den von diesem beauftragten Vertretern (unter Wahrung der
            Verhältnismäßigkeit), sich am Ort, an dem die Katze gehalten wird, von der Qualität der Tierhaltung und der
            Einhaltung der Bestimmungen dieses Vertrages zu überzeugen.<br /><br />
            Zu diesem Zweck gestattet der Übernehmer dem Übergeber bzw. den von diesem beauftragten Vertretern das
            Betreten
            seiner Liegenschaft und seiner Wohnung/seines Hauses, sowie allen anderen Räumlichkeiten oder Anlagen, in
            denen
            das Tier gehalten wird.<br /><br />
            Der Übernehmer nimmt dies durch Unterfertigung der Vereinbarung ausdrücklich zur Kenntnis und erklärt sich
            mit
            der dort beschriebenen Vorgangsweise ausdrücklich einverstanden. Dies schließt somit die Geltendmachung
            allfälliger, wie auch immer gearteter Rechtsansprüche gegenüber dem Übergeber aus.

            <h3>V. Rückabwicklung des Vertrags bei Gefährdung des Wohls der Katze</h3>

            Sollten – unter anderem bei Kontrollen nach Punkt IV. dieser Vereinbarung – vom Übergeber Verstöße gegen
            geltende tierschutzrechtliche Bestimmungen oder gegen Bestimmungen dieses Vertrages festgestellt werden, die
            das
            Wohl der Katze gefährden, ist das Tier auf Verlangen des Übergebers herauszugeben.<br />
            Ein Anspruch auf Rückerstattung des vom Übernehmer bei Übernahme des Tieres an den Übergeber geleisteten
            Unkostenbeitrags (Punkt IX) besteht nicht.<br />


            <h3>VI. Fundtiere</h3>

            Handelt es sich bei dem übergebenen Tier um ein Fundtier, so wird dieses nur unter folgender Bedingung
            übergeben: Falls sich der bisherige Eigentümer innerhalb eines Monats ab Datum der Kundmachung meldet, ist
            das
            Tier umgehend dem Übergeber zurückzustellen. In diesem Fall wird der Beitrag gem. Punkt IX. dieser
            Vereinbarung
            rückerstattet.

            <h3>VII. Kastrationspflicht</h3>

            Mit Übernahme der Katze verpflichtet sich der Übernehmer, bei Erlangen der Geschlechtsreife diese von einem
            Tierarzt auf seine Kosten kastrieren zu lassen.

            <h3>VIII. Registrierung</h3>

            Der Übernehmer akzeptiert die Verwendung seiner persönlichen Daten zur Registrierung der gechippten Katze in
            einer offiziellen Tier-Datenbank.

            <h3>IX. Unkostenbeitrag</h3>

            Der Übernehmer verpflichtet sich, als Beitrag zu den bisherigen Aufwendungen des Übergebers für die Haltung,
            Betreuung und Versorgung (wie Impfungen, Kastration, Chippen,..) des Tieres den Betrag von _____ Euro zu
            entrichten. Dieser Beitrag ist nicht als Entgelt für die Übergabe des Tieres anzusehen.

            <h3>X. Gerichtsstandort</h3>

            Änderungen und Ergänzungen dieses Vertrages müssen in Schriftform erfolgen. Bei Zuwiderhandlung gegen diese
            vertragliche Vereinbarung verpflichtet sich der Übernehmer zur Zahlung von € 300,- Euro Vertragsstrafe an
            den Übergeber. Für Streitfälle gilt österreichisches Recht und der Gerichtsstandort Linz.
        </section>

        <section class="disclaimer small mt-4 text-left">
            Information zum Datenschutz: <br />
            Name, Adress- und Kontaktdaten der überbringenden Peron und die Chipnummer des Tieres werden zum Zweck der
            Administration und Dokumentation der Tiere gespeichert. Die Daten werden nicht weitergegeben und mindestens
            drei Jahre – sofern in §21 Tierschutzgesetz nicht anders bestimmt – lang aufbewahrt. Im Rahmen der
            fachlichen Aufsicht dürfen diese Daten von der zuständigen Bezirksverwaltungsbehörde eingesehen werden. Die
            Chipnummer des Tieres kann vom Amt der OÖ Landesregierung, Abteilung Gesundheit eingesehen werden.
        </section>

        <section class="mt-3" style="margin-bottom: 55px">
            <div class="d-flex justify-content-around align-items-end text-left">
                <div class="left">
                    <div class="mt-3 mb-4">{{cityName}}, am {{date | date: 'dd.MM.yyyy'}}
                    </div>
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">Für das Tierheim (Übergeber)</div>
                </div>
                <div class="right">
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">Übernehmer</div>
                </div>
            </div>
        </section>

        <hr />

        <section class="mt-3">
            <h5 class="font-weight-bolder">Beitrittserklärung</h5>
            <div class="text-left">
                Ich erkläre hiermit meinen Beitritt zum OÖ Landestierschutzverein und unterstütze durch meinen
                Mitgliedsbeitrag die Tierheime sowie Tierschutzaufgaben in ganz OÖ. Der Jahresbeitrag beträgt € 22,-
                Mitglieder erhalten 4 mal jährlich die Vereinszeitschrift „Streuner“.
            </div>
            <div class="mt-4 d-flex justify-content-around">
                <div class="left">
                    {{cityName}}, am {{date | date: 'dd.MM.yyyy'}}
                </div>
                <div class="right">
                    Unterschrift: <span class="d-inline-block"
                                        style="width: 300px; height: 2px; background: #000"></span>
                </div>
            </div>
        </section>
    </ng-container> <!-- End Katzen -->

    <ng-container *ngIf="species === 'hund'">  <!-- Hunde -->
        <section class="text-left" style="page-break-before: always">
            <h3>I. Pflichten als Tierhalter</h3>

            1. Mit Unterzeichnung dieses Vertrages und der Übergabe und Übernahme des Tieres/der Tiere übernimmt der
            Halter
            dieses in seine Verantwortung und Obsorge. Die Haltereigenschaft sowie alle damit verbundenen Rechte und
            Pflichten gehen auf den Übernehmer über. Dies bedeutet, dass ab diesem Zeitpunkt der Übergeber nicht mehr
            für
            Kosten und Schäden, die aus der Tierhaltung entstehen (wie Tierarztkosten, Futter, Versicherung, Steuer,
            Bissverletzungen...), aufkommt bzw. haftet.<br /><br />

            2. Der Übernehmer verpflichtet sich, als Tierhalter den Hund art- und tierschutzgerecht nach den
            Bestimmungen
            des österreichischen Bundestierschutzgesetzes zu halten, zu ernähren und zu versorgen. Dies bedeutet,
            insbesondere auch regelmäßige tierärztliche Kontrollen und die erforderliche tierärztliche Behandlung (wie
            Impfungen,...) für den übernommenen Hund zu gewährleisten.<br /><br />

            3. Der Übernehmer sorgt vor, dass das Tier auch während seiner Abwesenheiten eine tierschutzgerechte
            Versorgung
            erhält.<br /><br />

            4. Der Übernehmer verpflichtet sich, das Tier nicht zur Züchtung bzw. für Tierversuche zu verwenden. Eine
            Trächtigkeit ist in jedem Fall zu vermeiden!<br /><br />

            5. Der Übernehmer verpflichtet sich, das Tier weder in Zwingerhaltung, noch in Keller-, Garagen-, Heiz-,
            oder
            Stallräumen zu halten.<br /><br />

            6. Der Übernehmer verpflichtet sich, den Hund grundsätzlich im Wohnbereich zu halten und nicht länger als 6
            Stunden alleine zu lassen (Ausnahme: Welpen bis zu einem Alter von 5 Monaten dürfen nicht alleine gelassen
            werden).

            <h3>II. Gewährleistungsausschluss</h3>

            Der Hund wurde in einem Tierheim des OÖ. Landestierschutzvereines von einem Tierarzt für abgabetauglich
            erklärt.<br /><br />

            Der Übernehmer erklärt, das Tier eingehend besichtigt und begutachtet zu haben. Er übernimmt es aus
            besonderer
            Vorliebe und unter Verzicht auf jegliche Gewährleistung.
            Der Übernehmer nimmt zur Kenntnis, dass die Vorgeschichte von Tieren, welche im Tierheim betreut wurden,
            nicht
            immer gänzlich geklärt ist. Die Beschreibungen von Charakter und Gesundheitszustand erfolgen nach bestem
            Wissen
            und Gewissen und nach Beobachtung des Hundes durch den Übergeber. Es kann das Vorliegen von Umständen und
            Verhaltensweisen, die dem Übergeber verborgen geblieben sind, nicht ausgeschlossen werden. <br /><br />

            Jegliche
            Gewährleistung sowie sonstige Haftung des Übergebers werden daher ausdrücklich ausgeschlossen.
            Davon ausgenommen ist der Fall, dass der Hund innerhalb von 3 Tagen nach der Übergabe erkrankt (Durchfall,
            Fieber, Erbrechen,...). In diesem Fall wenden Sie sich bitte umgehend an das Tierheim.
            Bei Inanspruchnahme eines anderen Tierarztes können keine Kosten rückerstattet werden.

            <h3>III. Weitergabe</h3>
            Der Übernehmer verpflichtet sich, die Weitergabe, den Verlust oder Tod des Hundes dem Übergeber zu melden.
            Vor
            Weitergabe an einen Dritten ist die Zustimmung des Übergebers einzuholen.
            Adressänderungen des Übernehmers sind ebenfalls umgehend bekanntzugeben.

            <h3>IV. Kontrollrechte des OÖ. Landestierschutzvereines</h3>
            Der Übernehmer gestattet dem Übergeber bzw. den von diesem beauftragten Vertretern (unter Wahrung der
            Verhältnismäßigkeit), sich am Ort, an dem der Hund gehalten wird, von der Qualität der Tierhaltung und der
            Einhaltung der Bestimmungen dieses Vertrages zu überzeugen.<br /><br />

            Zu diesem Zweck gestattet der Übernehmer dem Übergeber bzw. den von diesem beauftragten Vertretern das
            Betreten
            seiner Liegenschaft und seiner Wohnung/seines Hauses, sowie allen anderen Räumlichkeiten oder Anlagen, in
            denen
            das Tier gehalten wird.<br /><br />

            Der Übernehmer nimmt dies durch Unterfertigung der Vereinbarung ausdrücklich zur Kenntnis und erklärt sich
            mit
            der dort beschriebenen Vorgangsweise ausdrücklich einverstanden. Dies schließt somit die Geltendmachung
            allfälliger, wie auch immer gearteter Rechtsansprüche gegenüber dem Übergeber aus.

            <h3>V. Rückabwicklung des Vertrags bei Gefährdung des Wohls des Hundes</h3>
            Sollten – unter anderem bei Kontrollen nach Punkt IV. dieser Vereinbarung – vom Übergeber Verstöße gegen
            geltende tierschutzrechtliche Bestimmungen oder gegen Bestimmungen dieses Vertrages festgestellt werden, die
            das
            Wohl des Hundes gefährden, ist das Tier auf Verlangen des Übergebers herauszugeben.
            Ein Anspruch auf Rückerstattung des vom Übernehmer bei Übernahme des Tieres an den Übergeber geleisteten
            Unkostenbeitrags (Punkt VIII) besteht nicht.

            <h3>VI. Fundtiere</h3>
            Handelt es sich bei dem übergebenen Hund um ein Fundtier, so wird er nur unter folgender Bedingung
            übergeben:
            Falls sich der bisherige Eigentümer innerhalb eines Monats ab Datum der Kundmachung meldet, ist das Tier
            umgehend dem Übergeber zurückzustellen. In diesem Fall wird der Beitrag gem. Punkt VIII. dieser Vereinbarung
            rückerstattet.

            <h3>VII. Chip- und Registrierungspflicht gem. § 24a TSchG</h3>
            Das Tierheim hat die Registrierung des Hundes in der Heimtierdatenbank auf den neuen Halter zu veranlassen
            und
            im Anschluss dem Übernehmer die dazu erforderliche Nummer (Registrierungsnummer) auszuhändigen.
            Darüber hinaus hat der Übernehmer die Meldepflichten nach dem OÖ. Hundehaltegesetz zu beachten.

            <h3>VIII. Unkostenbeitrag</h3>
            Der Übernehmer verpflichtet sich, als Beitrag zu den bisherigen Aufwendungen des Übergebers für die Haltung,
            Betreuung und Versorgung (wie Impfungen, Kastration, Chippen,..) des Hundes den Betrag von _____ Euro zu
            entrichten. Dieser Beitrag ist nicht als Entgelt für die Übergabe des Tieres anzusehen.

            <h3>IX. Gerichtsstandort</h3>
            Änderungen und Ergänzungen dieses Vertrages müssen in Schriftform erfolgen. Bei Zuwiderhandlung gegen diese
            vertragliche Vereinbarung verpflichtet sich der Übernehmer zur Zahlung von € 300,- Euro Vertragsstrafe an
            den
            Übergeber.
            Für Streitfälle gilt österreichisches Recht und der Gerichtsstandort Linz.
        </section>

        <section class="disclaimer small mt-4 text-left">
            Information zum Datenschutz: <br />
            Name, Adress- und Kontaktdaten der überbringenden Peron und die Chipnummer des Tieres werden zum Zweck der
            Administration und Dokumentation der Tiere gespeichert. Die Daten werden nicht weitergegeben und mindestens
            drei Jahre – sofern in §21 Tierschutzgesetz nicht anders bestimmt – lang aufbewahrt. Im Rahmen der
            fachlichen Aufsicht dürfen diese Daten von der zuständigen Bezirksverwaltungsbehörde eingesehen werden. Die
            Chipnummer des Tieres kann vom Amt der OÖ Landesregierung, Abteilung Gesundheit eingesehen werden.
        </section>

        <section class="mt-3" style="margin-bottom: 55px">
            <div class="d-flex justify-content-around align-items-end text-left">
                <div class="left">
                    <div class="mt-3 mb-4">{{cityName}}, am {{date | date: 'dd.MM.yyyy'}}
                    </div>
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">Für das Tierheim (Übergeber)</div>
                </div>
                <div class="right">
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">Übernehmer</div>
                </div>
            </div>
        </section>

        <hr />

        <section class="mt-3">
            <h5 class="font-weight-bolder">Beitrittserklärung</h5>
            <div class="text-left">
                Ich erkläre hiermit meinen Beitritt zum OÖ Landestierschutzverein und unterstütze durch meinen
                Mitgliedsbeitrag die Tierheime sowie Tierschutzaufgaben in ganz OÖ. Der Jahresbeitrag beträgt € 22,-
                Mitglieder erhalten 4 mal jährlich die Vereinszeitschrift „Streuner“.
            </div>
            <div class="mt-4 d-flex justify-content-around">
                <div class="left">
                    {{cityName}}, am {{date | date: 'dd.MM.yyyy'}}
                </div>
                <div class="right">
                    Unterschrift: <span class="d-inline-block"
                                        style="width: 300px; height: 2px; background: #000"></span>
                </div>
            </div>
        </section>
    </ng-container> <!-- End Hunde -->

    <ng-container *ngIf="species === 'kleintier'">  <!-- Kleintiere -->
        <section class="text-left" style="page-break-before: always">
            <h3>I. Pflichten als Tierhalter</h3>
            1. Mit Unterzeichnung dieses Vertrages und der Übergabe und Übernahme des Tieres/der Tiere übernimmt der
            Halter diese in seine Verantwortung und Obsorge. Die Haltereigenschaft sowie alle damit verbundenen Rechte
            und Pflichten gehen auf den Übernehmer über.<br /><br />

            2. Dies bedeutet, dass ab diesem Zeitpunkt der Übergeber nicht mehr für Kosten und Schäden, die aus der
            Tierhaltung entstehen (wie Tierarztkosten, Futter, Versicherung, Steuer, Bissverletzungen...), aufkommt bzw.
            haftet.<br /><br />

            3. Der Übernehmer verpflichtet sich, als Tierhalter das Tier art- und tierschutzgerecht und wenn vertraglich
            nicht anderes vereinbart mindestens nach den Bestimmungen des österreichischen Bundestierschutzgesetzes zu
            halten, zu ernähren und zu versorgen. Dies bedeutet insbesondere auch regelmäßige tierärztliche Kontrollen
            und die erforderliche tierärztliche Behandlung (wie Impfungen,...) für das übernommene Tier zu
            gewährleisten.<br /><br />

            Für folgende Tierarten werden die nachstehenden Mindestanforderungen in der Haltung vereinbart:<br /><br />

            3.1. Mindestanforderungen für die Haltung von Chinchillas:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 150×100×150 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden. Es ist
            den Tieren regelmäßiger gesicherter Freilauf zu gewähren.<br /><br />

            3.2. Mindestanforderungen für die Haltung von Gerbil:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 80×40×40 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt
            werden.<br /><br />

            3.3. Mindestanforderungen für die Haltung von Hamster:
            Die Tiere sind einzeln zu halten. Die Käfiggröße muss für Zwerghamster mindestens 80×30×40 cm
            (Länge×Breite×Höhe) und für Mittelhamster mindestens 100×40×50 cm (Länge×Breite×Höhe) betragen. Es ist den
            Tieren regelmäßiger gesicherter Freilauf zu gewähren.<br /><br />

            3.4. Mindestanforderungen für die Haltung von Farbmäusen:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 80×30×40 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden. Die
            Haltung in einem Terrarium ist nicht zulässig. Es ist den Tieren regelmäßiger gesicherter Freilauf zu
            gewähren.<br /><br />

            3.5. Mindestanforderungen für die Haltung von Meerschweinchen:
            Die Tiere sind mindestens paarweise zu halten. Die Gehegegröße muss pro Paar in Außenhaltung mindestens 2 m²
            betragen und es ist auf eine gute Absicherung gegenüber Beutegreifern zu achten. In Innenhaltung muss die
            Käfiggröße mindestens 150×50×70 cm (Länge×Breite×Höhe) betragen. Es ist den Tieren täglicher gesicherter
            Freilauf zu gewähren. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden.<br /><br />

            3.6. Mindestanforderungen für die Haltung von Kaninchen:
            Die Tiere sind mindestens paarweise zu halten. Die Gehegegröße muss pro Paar in Außenhaltung mindestens 4 m²
            betragen und es ist auf eine gute Absicherung gegenüber Beutegreifern zu achten. In Innenhaltung muss die
            Käfiggröße mindestens 150×50×70 cm (Länge×Breite×Höhe) betragen. Es ist den Tieren täglicher gesicherter
            Freilauf zu gewähren. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden.<br /><br />

            3.7. Mindestanforderungen für die Haltung von Ratten:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 100×50×150 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden. Die
            Haltung in einem Terrarium ist nicht zulässig. Es ist den Tieren regelmäßiger gesicherter Freilauf zu
            gewähren.<br /><br />

            3.8. Mindestanforderungen für die Haltung von Wellensittichen:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 150×80×100 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden. Es ist
            den Tieren regelmäßiger gesicherter Freiflug zu gewähren.<br /><br />

            3.9. Mindestanforderungen für die Haltung von Nymphensittichen:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 150×80×150 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden. Es ist
            den Tieren regelmäßiger gesicherter Freiflug zu gewähren.<br /><br />

            3.10. Mindestanforderungen für die Haltung von Kanarienvögeln und Zierfinkenarten:
            Die Tiere sind mindestens paarweise zu halten. Die Käfiggröße muss pro Paar mindestens 100×80×100 cm
            (Länge×Breite×Höhe) betragen. Für jedes weitere adulte Tier muss entsprechend Fläche ergänzt werden. Es ist
            den Tieren regelmäßiger gesicherter Freiflug zu gewähren.<br /><br />

            4. Der Übernehmer sorgt vor, dass das Tier auch während seiner Abwesenheiten eine tierschutzgerechte
            Versorgung erhält.<br /><br />

            5. Der Übernehmer verpflichtet sich, das Tier nicht zur Züchtung bzw. für Tierversuche zu
            verwenden.<br /><br />

            <h3>II. Gewährleistungsausschluss</h3>
            Das Tier wurde in einem Tierheim des OÖ. Landestierschutzvereines von einem Tierarzt für abgabetauglich
            erklärt. <br /><br />

            Der Übernehmer erklärt, das Tier eingehend besichtigt und begutachtet zu haben. Er übernimmt es aus
            besonderer Vorliebe und unter Verzicht auf jegliche Gewährleistung.
            Der Übernehmer nimmt zur Kenntnis, dass die Vorgeschichte von Tieren, welche im Tierheim Linz bzw. Steyr des
            OÖ. Landestierschutzvereines betreut wurden, nicht immer gänzlich geklärt ist. Die Beschreibungen von
            Charakter und Gesundheitszustand erfolgen nach bestem Wissen und Gewissen und nach Beobachtung des Tieres
            durch den Übergeber. Es kann das Vorliegen von Umständen und Verhaltensweisen, die dem Übergeber verborgen
            geblieben sind, nicht ausgeschlossen werden. Jegliche Gewährleistung und sonstige Haftung des Übergebers
            werden daher ausdrücklich ausgeschlossen.<br /><br />

            Davon ausgenommen ist der Fall, dass das Tier innerhalb von 3 Tagen nach der Übergabe erkrankt (wie
            Durchfall, Fieber, Erbrechen,...). In diesem Fall wenden Sie sich bitte umgehend an das Tierheim
            Bei Inanspruchnahme eines anderen Tierarztes können keine Kosten rückerstattet werden.

            <h3>III. Weitergabe</h3>
            Der Übernehmer verpflichtet sich, die Weitergabe, den Verlust oder Tod des Tieres dem Übergeber zu melden.
            Vor Weitergabe an einen Dritten ist die Zustimmung des Übergebers einzuholen.
            Der Übernehmer verpflichtet sich, eine Änderung seines Namens oder seines Wohnortes unverzüglich dem
            Übergeber bekannt zu geben.

            <h3>IV. Kontrollrechte des Übergebers</h3>
            Der Übernehmer gestattet dem Übergeber bzw. den von diesem beauftragten Vertretern (unter Wahrung der
            Verhältnismäßigkeit), sich am Ort, an dem das Tier gehalten wird, von der Qualität der Tierhaltung und der
            Einhaltung der Bestimmungen dieses Vertrages zu überzeugen. <br /><br />

            Zu diesem Zweck gestattet der Übernehmer dem Übergeber bzw. den von diesem beauftragten Vertretern das
            Betreten seiner Liegenschaft und seiner Wohnung/seines Hauses, sowie allen anderen Räumlichkeiten oder
            Anlagen, in denen das Tier gehalten wird.<br /><br />

            Der Übernehmer nimmt dies durch Unterfertigung der Vereinbarung ausdrücklich zur Kenntnis und erklärt sich
            mit der dort beschriebenen Vorgangsweise ausdrücklich einverstanden. Dies schließt somit die Geltendmachung
            allfälliger, wie auch immer gearteter Rechtsansprüche gegenüber dem Übergeber aus.

            <h3>V. Rückabwicklung des Vertrags bei Gefährdung des Wohls des Tieres</h3>
            Sollten – unter anderem bei Kontrollen nach Punkt IV. dieser Vereinbarung – vom Übergeber Verstöße gegen
            geltende tierschutzrechtliche Bestimmungen oder gegen Bestimmungen dieses Vertrages festgestellt werden, die
            das Wohl des Tieres gefährden, ist das Tier auf Verlangen des Übergebers herauszugeben. Ein Anspruch auf
            Rückerstattung des vom Übernehmer bei Übernahme des Tieres an den Übergeber geleisteten Unkostenbeitrags
            (Punkt VIII) besteht nicht.

            <h3>VI. Fundtiere</h3>
            Handelt es sich bei dem übergebenen Tier um ein Fundtier, so wird dieses nur unter folgender Bedingung
            übergeben: Falls sich der bisherige Eigentümer innerhalb eines Monats ab Datum der Kundmachung meldet, ist
            das Tier umgehend dem Übergeber zurückzustellen. In diesem Fall wird der Beitrag gem. Punkt VIII. dieser
            Vereinbarung rückerstattet.

            <h3>VII. Kastrationspflicht</h3>
            Mit Übernahme des Tieres verpflichtet sich der Übernehmer, dass eine Trächtigkeit des Tieres in jedem Fall
            durch Kastration bei Geschlechtsreife bzw. andere geeignete Maßnahmen zu verhindern ist.

            <h3>VIII. Unkostenbeitrag</h3>
            Der Übernehmer verpflichtet sich, als Beitrag zu den bisherigen Aufwendungen des Übergebers für die Haltung,
            Betreuung und Versorgung (wie Impfungen, Kastration, Chippen,..) des Tieres den Betrag von _____ Euro zu
            entrichten. Dieser Beitrag ist nicht als Entgelt für die Übergabe des Tieres anzusehen.

            <h3>IX. Gerichtsstandort</h3>
            Änderungen und Ergänzungen dieses Vertrages müssen in Schriftform erfolgen. Bei Zuwiderhandlung gegen diese
            vertragliche Vereinbarung verpflichtet sich der Übernehmer zur Zahlung von 300,- Euro Vertragsstrafe an den
            Übergeber.

            Für Streitfälle gilt österreichisches Recht und der Gerichtsstandort Linz.
        </section>

        <section class="mt-3" style="margin-bottom: 70px">
            <div class="d-flex justify-content-around align-items-end text-left">
                <div class="left">
                    <div class="mt-3 mb-4">{{cityName}}, am {{date | date: 'dd.MM.yyyy'}}
                    </div>
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">Für das Tierheim (Übergeber)</div>
                </div>
                <div class="right">
                    <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                    <div class="small">Übernehmer</div>
                </div>
            </div>
        </section>

        <hr />

        <section class="mt-3">
            <h5 class="font-weight-bolder">Beitrittserklärung</h5>
            <div class="text-left">
                Ich erkläre hiermit meinen Beitritt zum OÖ Landestierschutzverein und unterstütze durch meinen
                Mitgliedsbeitrag die Tierheime sowie Tierschutzaufgaben in ganz OÖ. Der Jahresbeitrag beträgt € 22,-
                Mitglieder erhalten 4 mal jährlich die Vereinszeitschrift „Streuner“.
            </div>
            <div class="mt-4 d-flex justify-content-around">
                <div class="left">
                    {{cityName}}, am {{date | date: 'dd.MM.yyyy'}}
                </div>
                <div class="right">
                    Unterschrift: <span class="d-inline-block"
                                        style="width: 300px; height: 2px; background: #000"></span>
                </div>
            </div>
        </section>
    </ng-container> <!-- Kleintiere -->
</div>
