import {Injectable} from '@angular/core';
import {SnackStatusType} from '../components/snackbar/snack-status-type';
import {SnackbarService} from '../components/snackbar/snackbar.service';
import {InvalidAPIRequest} from '../data-types/error-types';

@Injectable({
    providedIn: 'root'
})
export class ExternalApiService {

    constructor(private snackbarService: SnackbarService) {
    }

    public handleInvalidRequests(invalidAPIRequests: InvalidAPIRequest[]): void {
        if (invalidAPIRequests?.length > 0) {
            let message = '';
            message += invalidAPIRequests.map(entry => 'LFNr.: ' + entry.animalId + ' ' + entry.errorMsg + '<br>');
            window.setTimeout(() => {
                this.snackbarService.displaySnackbar(SnackStatusType.ERROR, message, 10);
            }, 2000);
        }
    }
}
