import {Action} from '@ngrx/store';
import {ErrorTypes} from './auth-error';
import {UserEntryModel} from '../../shared/models/user-entry.model';

export const LOGIN_START = '[Auth] Login Start';
export const GET_LOGGED_IN_USER = '[Auth] Get logged in User';
export const SET_USER = '[Auth] Set User';
export const KILL_USER = '[Auth] Kill User';
export const HTTP_FAIL = '[Auth] HTTP Fail';
export const LOGOUT = '[Auth] Logout';

export class LoginStart implements Action {
    readonly type = LOGIN_START;

    constructor(public payload: { emailOrUsername: string; password: string; rememberMe: boolean }) {
    }
}

export class GetLoggedInUser implements Action {
    readonly type = GET_LOGGED_IN_USER;
}

export class SetUser implements Action {
    readonly type = SET_USER;

    constructor(public payload: UserEntryModel) {
    }
}

export class KillUser implements Action {
    readonly type = KILL_USER;
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export class Logout implements Action {
    readonly type = LOGOUT;
}

export type AuthActions = LoginStart | SetUser | GetLoggedInUser | KillUser | HttpFail | Logout;
