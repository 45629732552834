import {ActionTypeEntryModel} from '../models/action-type-entry.model';

export class ActionTypeConverter {
    public static toHTTP(actionTypeModel: ActionTypeEntryModel): any {
        const httpModel = {
            id: actionTypeModel.id,
            color: actionTypeModel.color,
            is_acquisition: actionTypeModel.isAcquisition,
            name: actionTypeModel.name,
            symbol: actionTypeModel.symbol
        };
        return httpModel;
    }

    public static toActionType(httpModel: any): ActionTypeEntryModel {
        const actionTypeEntryModel: ActionTypeEntryModel = {
            id: httpModel.id,
            name: httpModel.name,
            symbol: httpModel.symbol,
            isAcquisition: httpModel.is_acquisition,
            color: httpModel.color
        };
        return actionTypeEntryModel;
    }
}
