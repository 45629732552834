import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import localeDEAT from '@angular/common/locales/de';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';
import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {registerLocaleData} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AnimalEffects} from './animals/store/animals/animal.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {ArticlesEffects} from './pos-system/store/articles/articles.effects';
import {AuthEffects} from './auth/store/auth.effects';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {CategoriesEffects} from './pos-system/store/categories/categories.effects';
import {SnackbarComponent} from './shared/components/snackbar/snackbar.component';
import {RacesEffects} from './animals/store/races/race.effects';
import {FDRacesEffects} from './animals/store/fd-races/fd-race.effects';
import {AttributesEffects} from './attributes/store/attributes/attributes.effects';
import {PersonEffects} from './persons/store/persons.effects';
import {SpeciesEffects} from './animals/store/species/species.effects';
import {OptionsEffects} from './attributes/store/options/options.effects';
import {BookingEffects} from './pos-system/store/booking/booking.effects';
import {VeterinaryTreatmentsEffects} from './veterinary-treatments/store/veterinary-treatments/veterinary-treatments.effects';
import {UserManagementModule} from './user-management/user-management.module';
import {UsersEffects} from './user-management/store/manage-users/users.effects';
import {AccommodationsEffects} from './animals/store/accommodation/accommodation.effects';
import {AdminLayoutModule} from './layouts/admin-layout/admin-layout.module';
import {ActionTypesEffects} from './animals/store/action-types/action-types.effects';
import {CharacteristicsEffects} from './animals/store/characteristics/characteristic.effects';
import {MediaEffects} from './media-centre/store/media.effects';
import {ActionsEffects} from './actions/store/actions.effects';
import {SupervisionEffects} from './supervision/store/supervision.effects';
import {FormerTreatmentsEffects} from './veterinary-treatments/store/former-treatments/former-treatments.effects';
import {AutoCompleteEffects} from './shared/services/auto-complete/store/auto-complete.effects';
import {CareSpaceEffects} from './animals/store/care-space/care-space.effects';
import {LoggingEffects} from './shared/logging/store/logging.effects';
import * as fromApp from './store/app.reducer';
import {CustomErrorHandlerService} from './shared/logging/custom-error-handler.service';
import {MissingAnimalsEffects} from './animals/store/missing/missing.effects';
import {ZipCityEffects} from './shared/services/zip-city-search/store/zip-city.effects';
import {OccupationsEffects} from './animals/store/statistics/occupation/occupation.effects';

registerLocaleData(localeDEAT);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        ComponentsModule,
        StoreModule.forRoot(fromApp.appReducer),
        EffectsModule.forRoot([
            AnimalEffects,
            SpeciesEffects,
            RacesEffects,
            FDRacesEffects,
            AttributesEffects,
            OptionsEffects,
            ArticlesEffects,
            CategoriesEffects,
            AuthEffects,
            PersonEffects,
            BookingEffects,
            VeterinaryTreatmentsEffects,
            FormerTreatmentsEffects,
            UsersEffects,
            AccommodationsEffects,
            ActionTypesEffects,
            CharacteristicsEffects,
            MediaEffects,
            ActionsEffects,
            SupervisionEffects,
            AutoCompleteEffects,
            CareSpaceEffects,
            LoggingEffects,
            MissingAnimalsEffects,
            ZipCityEffects,
            OccupationsEffects
        ]),
        RouterModule,
        StoreDevtoolsModule.instrument({logOnly: environment.production}),
        AppRoutingModule,
        UserManagementModule,
        AdminLayoutModule,
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        SnackbarComponent,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'de'
        },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
        {provide: ErrorHandler, useClass: CustomErrorHandlerService}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
