<div id="supervision-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerFrom.open()"
                           [ngxMatDatetimePicker]="datePickerFrom"
                           placeholder="Von"
                           formControlName="from"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerFrom"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerFrom
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerTo.open()"
                           [ngxMatDatetimePicker]="datePickerTo"
                           placeholder="Bis"
                           formControlName="to"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerTo"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerTo
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Tierart</mat-label>
                    <mat-select #singleSelectSpecies
                                formControlName="species"
                                (keydown.enter)="filter()">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchSpecies.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                [value]="species">
                            {{species.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12 position-relative">
                <mat-checkbox
                        formControlName="onlyUnsupervisedAnimals">Nur nicht
                    kontrollierte Tiere anzeigen
                </mat-checkbox>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col text-right">
                <button mat-raised-button
                        class="mt-4 float-right"
                        color="primary">
                    Suchen
                </button>
            </div>

            <div class="col">
                <button mat-raised-button
                        class="mt-4 float-left"
                        color="warn"
                        (click)="reset()">
                    Zurücksetzen
                </button>
            </div>
        </div>
    </form>
</div>
