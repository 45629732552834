import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, ReplaySubject} from 'rxjs';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {Store} from '@ngrx/store';
import {SelectBoxService} from '../../../shared/controls/select-box/select-box.service';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import {MatOption} from '@angular/material/core';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import * as fromApp from '../../../store/app.reducer';
import * as fromCharacteristicActions from './characteristic.actions';
import {MatDialogRef} from '@angular/material/dialog';
import {SimpleDialogComponent} from '../../../dialogs/simple-dialog/simple-dialog.component';
import {Tuple, TupleHelper} from '../../../shared/data-types/tuple';
import {ChipBar} from '../../../shared/controls/chip-bar';

@Injectable({
    providedIn: 'root'
})
export class CharacteristicService {
    public characteristics: ReplaySubject<DropdownOption[]> =
        new ReplaySubject<DropdownOption[]>(null);
    private errorEmerged = false;

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService,
                private selectBoxService: SelectBoxService) {
    }

    public fetchCharacteristics(filter?: any): Observable<DropdownOption[]> {
        return this.store.select('characteristicList').pipe(
            take(1),
            map(characteristicState => {
                return characteristicState.characteristics;
            }),
            switchMap(() => {
                this.store.dispatch(new fromCharacteristicActions.LoadCharacteristics({
                    crud: CRUD.READ,
                    filter
                }));
                return this.actions$.pipe(
                    ofType(fromCharacteristicActions.SET_CHARACTERISTICS),
                    map((characteristicState: fromCharacteristicActions.SetCharacteristics) => {
                        return characteristicState.payload.characteristics;
                    })
                );
            }),
            map((characteristics: DictionaryEntryModel[]) => {
                return characteristics
                    .map((element: DictionaryEntryModel) => {
                        return {
                            value: SelectBoxUtility.getOptionsValue(element.name),
                            viewValue: element.name,
                            meta: [
                                {key: 'id', value: element.id.toString()},
                                {key: 'raceId', value: TupleHelper.getValue(element.meta, 'raceId')},
                                {key: 'isLegacy', value: TupleHelper.getValue(element.meta, 'isLegacy')}
                            ]
                        } as DropdownOption;
                    })
                    .sort(this.selectBoxService.sortCallback);
            })
        );
    }

    public addCharacteristic(event: MouseEvent,
                             allRaces: DropdownOption[],
                             chipBar: ChipBar,
                             filter: any): void {
        event.stopPropagation();
        const meta: Tuple[] = [
            {key: 'allOptions', value: allRaces},
            {key: 'selectedRaceId', value: filter !== null ? filter.raceId : null}
        ];
        const dialogRef = this.selectBoxService.openAddDialog('Merkmal', meta);
        let currentSelectedRaceId;
        if (filter) {
            currentSelectedRaceId = filter.raceId;
        }

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== false) {
                const raceId = +TupleHelper.getValue(result.race.meta, 'id');
                const newModel: DictionaryEntryModel = {
                    id: -1,
                    name: result.name,
                    meta: [{key: 'raceId', value: raceId}]
                };

                filter = {
                    raceId,
                    excludeLegacyCharacteristics: true
                };

                this.store.dispatch(new fromCharacteristicActions.AddCharacteristic({
                    newModel,
                    filter
                }));
                this.actions$.pipe(
                    ofType(fromCharacteristicActions.SET_CHARACTERISTICS),
                    take(1)
                ).subscribe((characteristicState: fromCharacteristicActions.SetCharacteristics) => {
                    if (characteristicState.payload.operation === CRUD.CREATE) {
                        if (raceId && currentSelectedRaceId && raceId === currentSelectedRaceId) {
                            chipBar.addChip(characteristicState.payload.addedOrUpdatedCharacteristic);
                        }
                        setTimeout(() => this.handleRequestSuccess(CRUD.CREATE, 'Merkmal', result.name));
                    }
                });
            }
        });
    }

    public updateCharacteristic(event: MouseEvent,
                                matOption: MatOption,
                                characteristic: DropdownOption,
                                updatedModel: DictionaryEntryModel,
                                filter?: any): void {
        event.stopPropagation();

        if (updatedModel.name === '') {
            updatedModel.name = characteristic.viewValue;
            return;
        }

        this.selectBoxService.update(matOption, new fromCharacteristicActions.UpdateCharacteristic({
            updatedModel,
            filter
        }));
        this.actions$.pipe(
            ofType(fromCharacteristicActions.SET_CHARACTERISTICS),
            take(1)
        ).subscribe(() => {
            if (!this.errorEmerged) {
                setTimeout(() => this.handleRequestSuccess(CRUD.UPDATE, 'Merkmal', updatedModel.name));
                this.errorEmerged = false;
            }
        });
    }

    public deleteCharacteristic(event: MouseEvent,
                                characteristic: DropdownOption,
                                filter?: any): MatDialogRef<SimpleDialogComponent, any> {
        event.stopPropagation();
        const dialogRef = this.selectBoxService.delete('Merkmal', characteristic.viewValue);
        dialogRef.afterClosed()
            .subscribe(result => {
                if (result === true) {
                    const characteristicId = +characteristic.meta.find(tuple => tuple.key === 'id').value;
                    this.store.dispatch(new fromCharacteristicActions.DeleteCharacteristic({
                        id: characteristicId,
                        filter
                    }));
                    this.actions$.pipe(
                        ofType(fromCharacteristicActions.SET_CHARACTERISTICS),
                        take(1)
                    ).subscribe(() => {
                        setTimeout(() => this.handleRequestSuccess(CRUD.DELETE, 'Merkmal', characteristic.viewValue));
                    });
                }
            });
        return dialogRef;
    }

    public printCharacteristics(characteristics: DictionaryEntryModel[]): string {
        let printableCharacteristics = '';
        characteristics.forEach((characteristic, index) => {
            printableCharacteristics += characteristic.name;
            if (index < characteristics.length - 1) {
                printableCharacteristics += ', ';
            }
        });
        return printableCharacteristics;
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string, raceId: number, chipBar: ChipBar): void {
        this.errorEmerged = true;
        const filter = {
            raceId,
            excludeLegacyCharacteristics: true
        };
        this.fetchCharacteristics(filter)
            .pipe(take(1))
            .subscribe(characteristics => {
                chipBar.setValues(characteristics);
            });
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
