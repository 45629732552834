import {Routes} from '@angular/router';
import {PersonsComponent} from '../../persons/persons.component';
import {ActionsComponent} from '../../actions/actions.component';
import {AttributesComponent} from '../../attributes/attributes.component';
import {PosSystemComponent} from '../../pos-system/pos-system.component';
import {AcquisitionAndGiveAwayComponent} from '../../animals/acquisition-and-give-away/acquisition-and-give-away.component';
import {AnimalsComponent} from '../../animals/animals.component';
import {MissingComponent} from '../../animals/missing/missing.component';
import {SupervisionComponent} from '../../supervision/supervision.component';
import {CancellationComponent} from '../../pos-system/cancellation/cancellation.component';
import {ArticleListComponent} from '../../pos-system/article-list/article-list.component';
import {IncomeOutcomeComponent} from '../../pos-system/income-outcome/income-outcome.component';
import {CashbookComponent} from '../../pos-system/cashbook/cashbook.component';
import {CareSpaceComponent} from '../../animals/care-space/care-space.component';
import {VeterinaryTreatmentsComponent} from '../../veterinary-treatments/veterinary-treatments.component';
import {AuthGuard} from '../../auth/auth.guard';
import {MediaCentreComponent} from '../../media-centre/media-centre.component';
import {TransferPersonDataComponent} from '../../persons/transfer-person-data/transfer-person-data.component';
import {StatisticsComponent} from '../../statistics/statistics.component';

export const AdminLayoutRoutes: Routes = [
    {path: '', redirectTo: '/animals/overview', pathMatch: 'full', canActivate: [AuthGuard]},
    {
        path: 'animals', canActivateChild: [AuthGuard], children: [
            {path: '', pathMatch: 'full', redirectTo: 'overview'},
            {path: 'overview', component: AnimalsComponent},
            {path: 'acquisition', component: AcquisitionAndGiveAwayComponent},
            {path: 'give-away', component: AcquisitionAndGiveAwayComponent},
            {path: 'care-space', component: CareSpaceComponent},
            {path: 'missing', component: MissingComponent},
            {path: 'information', component: AnimalsComponent, data: {isInformationPage: true}},
            {path: 'statistics', component: StatisticsComponent, data: {isInformationPage: true}},
        ]
    },
    {path: 'persons', redirectTo: '/persons/overview', pathMatch: 'full', canActivate: [AuthGuard]},
    {
        path: 'persons', canActivateChild: [AuthGuard], children: [
            {path: '', pathMatch: 'full', redirectTo: 'overview'},
            {path: 'overview', component: PersonsComponent},
            {path: 'transfer-person-data', component: TransferPersonDataComponent},
        ]
    },
    {path: 'actions', canActivate: [AuthGuard], component: ActionsComponent},
    {path: 'supervisions', canActivate: [AuthGuard], component: SupervisionComponent},
    {path: 'veterinary-treatments', canActivate: [AuthGuard], component: VeterinaryTreatmentsComponent},
    {path: 'attributes', canActivate: [AuthGuard], component: AttributesComponent},
    {
        path: 'pos-system', canActivateChild: [AuthGuard], component: PosSystemComponent, children: [
            {path: '', pathMatch: 'full', redirectTo: 'income'},
            {path: 'income', component: IncomeOutcomeComponent},
            {path: 'outcome', component: IncomeOutcomeComponent},
            {path: 'cashbook-excerpt', component: CashbookComponent},
            {path: 'cancellation', component: CancellationComponent},
            {path: 'article-list', component: ArticleListComponent}
        ]
    },
    {path: 'media-centre', canActivate: [AuthGuard], component: MediaCentreComponent}
];
