import {Action} from '@ngrx/store';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_ACCOMMODATIONS = '[Accommodations] Load Accommodations';
export const SET_ACCOMMODATIONS = '[Accommodations] Set Accommodations';
export const ADD_ACCOMMODATION = '[Accommodations] Add Accommodations';
export const UPDATE_ACCOMMODATION = '[Accommodations] Update Accommodations';
export const DELETE_ACCOMMODATION = '[Accommodations] Delete Accommodations';
export const HTTP_FAIL = '[Accommodations] Http Fail';

export class LoadAccommodations implements Action {
    readonly type = LOAD_ACCOMMODATIONS;

    constructor(public payload: CRUD) {
    }
}

export class SetAccommodations implements Action {
    readonly type = SET_ACCOMMODATIONS;

    constructor(public payload: { accommodations: DictionaryEntryModel[], operation: CRUD }) {
    }
}

export class AddAccommodation implements Action {
    readonly type = ADD_ACCOMMODATION;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class UpdateAccommodation implements Action {
    readonly type = UPDATE_ACCOMMODATION;

    constructor(public payload: DictionaryEntryModel) {
    }
}

export class DeleteAccommodation implements Action {
    readonly type = DELETE_ACCOMMODATION;

    constructor(public payload: number) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type AccommodationsActions =
    LoadAccommodations
    | SetAccommodations
    | AddAccommodation
    | UpdateAccommodation
    | DeleteAccommodation
    | HttpFail;

