import {Action} from '@ngrx/store';
import {CRUD} from '../../shared/services/http/crud';
import {MediaEntryModel} from '../../shared/models/media-entry.model';
import {ImageUploadType} from '../ImageUploadType';
import {Order} from '../../shared/controls/data-table/ordering';
import {MediaReferrer} from '../media.service';
import {ErrorTypes} from '../../shared/data-types/error-types';

export const LOAD_MEDIA = '[Media] Load Media';
export const LOAD_MULTIPLE_MEDIA = '[MEDIA] Load Multiple Media';
export const SET_MULTIPLE_MEDIA = '[MEDIA] Set Multiple Media';
export const SET_MEDIA = '[Media] Set Media';
export const UPDATE_MEDIA = '[Media] Update Media';
export const DELETE_MEDIA = '[Media] Delete Media';
export const HTTP_FAIL = '[Media] Http Fail';

export class LoadMedia implements Action {
    readonly type = LOAD_MEDIA;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        thumbnail: boolean,
        imgId?: number, mediaFilter?: any
    }) {
    }
}

export class SetMedia implements Action {
    readonly type = SET_MEDIA;

    constructor(public payload: { media: MediaEntryModel[], crud: CRUD, totalElements: number, lastPage: number }) {
    }
}

export class LoadMultipleMedia implements Action {
    readonly type = LOAD_MULTIPLE_MEDIA;

    constructor(public payload: {
        crud: CRUD,
        thumbnail: boolean,
        imgIds: number[],
        imageUploadType: ImageUploadType,
        referrer: MediaReferrer
    }) {
    }
}

export class SetMultipleMedia implements Action {
    readonly type = SET_MULTIPLE_MEDIA;

    constructor(public payload: {
        media: MediaEntryModel[],
        crud: CRUD,
        imageUploadType: ImageUploadType,
        referrer: MediaReferrer
    }) {
    }
}

export class UpdateMedia implements Action {
    readonly type = UPDATE_MEDIA;

    constructor(public payload: {
        media: MediaEntryModel,
        size: number,
        page: number,
        order: Order,
        column: string,
        thumbnail: boolean
    }) {
    }
}

export class DeleteMedia implements Action {
    readonly type = DELETE_MEDIA;

    constructor(public payload: {
        id: number,
        size: number,
        page: number,
        order: Order,
        column: string
        thumbnail: boolean
    }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type MediaActions =
    LoadMedia
    | SetMedia
    | LoadMultipleMedia
    | SetMultipleMedia
    | UpdateMedia
    | DeleteMedia
    | HttpFail;


