import {CRUD} from '../../../shared/services/http/crud';
import * as fromMissingAnimals from './missing.actions';
import {MissingAnimalEntryModel} from '../../../shared/models/animal/missing-animal-entry.model';

export interface State {
    missingAnimals: MissingAnimalEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    missingAnimals: [],
    operation: CRUD.NONE
};

export function missingAnimalReducer(state = initialState, action: fromMissingAnimals.MissingAnimalsActions): State {
    switch (action.type) {
        case fromMissingAnimals.SET_MISSING_ANIMALS:
            return {
                ...state,
                missingAnimals: [...action.payload.missingAnimals],
                operation: action.payload.operation
            };
        case fromMissingAnimals.ADD_MISSING_ANIMAL:
            return {
                ...state,
                missingAnimals: [...state.missingAnimals, action.payload.newModel]
            };
        case fromMissingAnimals.UPDATE_MISSING_ANIMAL:
            const updatedCharacteristic = {
                ...state.missingAnimals.find(missingAnimal => missingAnimal.id === action.payload.updatedModel.id),
                ...action.payload
            };

            const updatedCharacteristics = [...state.missingAnimals];
            const arrIndex = updatedCharacteristics.findIndex(element => element.id === updatedCharacteristic.id);
            updatedCharacteristics[arrIndex] = updatedCharacteristic;

            return {
                ...state,
                missingAnimals: updatedCharacteristics
            };
        case fromMissingAnimals.DELETE_MISSING_ANIMAL:
            return {
                ...state,
                missingAnimals: state.missingAnimals.filter(missingAnimal => missingAnimal.id !== action.payload.id)
            };
        case fromMissingAnimals.SET_MATCHED_ANIMALS:
            return {
                ...state,
                missingAnimals: [...action.payload]
            };
        case fromMissingAnimals.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
