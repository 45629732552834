<div class="quicknav d-flex justify-content-end mb-3">
    <a (click)="printAnimal()"
       class="fas fa-print icon"
       matTooltipPosition="right"
       matTooltipClass="tooltip-popup"></a>
    <a (click)="openDialog(FORMER_SUPERVISIONS)"
       class="fas fa-smile-beam icon"
       matTooltip="Kontrollen anzeigen und eintragen"
       matTooltipPosition="right"
       matTooltipClass="tooltip-popup"></a>
    <a (click)="openDialog(FORMER_VETERINARY_TREATMENTS)"
       class="fas fa-syringe icon"
       matTooltip="Tierärztliche Behandlungen anzeigen"
       matTooltipPosition="right"
       matTooltipClass="tooltip-popup"></a>
    <a (click)="openDialog(PERSONALIZED_ACTIONS)"
       class="fas fa-user-clock icon"
       matTooltip="Personenbezogene Aktionen anzeigen"
       matTooltipPosition="right"
       matTooltipClass="tooltip-popup"></a>
    <a (click)="openDialog(FORMER_CARE_SPACES)"
       class="fas fa-file-signature icon"
       matTooltip="Pflegeplätze anzeigen"
       matTooltipPosition="right"
       matTooltipClass="tooltip-popup"></a>
    <a (click)="openDialog(simpleDialogType.GOING_TO_HEAVEN)"
       class="fas fa-skull-crossbones icon"
       matTooltip="Himmelfahrt eintragen"
       matTooltipPosition="right"
       matTooltipClass="tooltip-popup"></a>
</div>
