<div id="missing-animals-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerBirthday.open()"
                           [ngxMatDatetimePicker]="datePickerBirthday"
                           placeholder="Abgängig seit"
                           formControlName="missingSince"
                           aria-label="Abgängig seit"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerBirthday"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerBirthday
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Abgängig Straße</mat-label>
                    <input formControlName="missingStreet"
                           matInput
                           placeholder="Abgängig Straße"
                           [matAutocomplete]="animalStreet">
                    <mat-autocomplete #animalStreet="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredMissingStreets$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Abgängig Postleitzahl</mat-label>
                    <input formControlName="missingZip"
                           matInput
                           placeholder="Abgängig Postleitzahl"
                           [matAutocomplete]="animalZip">
                    <mat-autocomplete #animalZip="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredMissingZips$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Abgängig Ort</mat-label>
                    <input formControlName="missingCity"
                           matInput
                           placeholder="Abgängig Ort"
                           [matAutocomplete]="animalCities">
                    <mat-autocomplete #animalCities="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredMissingCities$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Umkreis in km</mat-label>
                    <input matInput
                           placeholder="Umkreis in km"
                           formControlName="radius"
                           (keydown.enter)="filter()">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Tierart</mat-label>
                    <mat-select #singleSelectSpecies
                                (ngModelChange)="selectSpecies($event)"
                                formControlName="species"
                                (keydown.enter)="filter()">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchSpecies.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                [value]="species">
                            {{species.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field appearance="fill">
                    <mat-label>Tierrasse</mat-label>
                    <mat-select #singleSelectRace
                                formControlName="race"
                                (keydown.enter)="filter()">
                        <mat-option>
                            <ngx-mat-select-search
                                    [formControl]="selectSearchRace.filterCtrl">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                                *ngFor="let race of selectSearchRace.filteredElements | async"
                                [value]="race">
                            {{race.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Tiername</mat-label>
                    <input matInput
                           placeholder="Tiername"
                           formControlName="name"
                           aria-label="Tiername"
                           [matAutocomplete]="animalName">
                    <mat-autocomplete #animalName="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredAnimalNames$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Besitzer</mat-label>
                    <input matInput
                           (keydown.enter)="filter()"
                           formControlName="owner"
                           placeholder="Besitzer"
                           [matAutocomplete]="ownerName">
                    <mat-autocomplete #ownerName="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredOwnerNames$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Merkmale</mat-label>
                    <input matInput
                           (keydown.enter)="filter()"
                           formControlName="characteristics"
                           placeholder="Merkmale">
                </mat-form-field>
            </div>


            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Zusatzinfo</mat-label>
                    <input matInput
                           (keydown.enter)="filter()"
                           formControlName="additionalInfo"
                           placeholder="Zusatzinfo"
                           [matAutocomplete]="additionalInfo">
                    <mat-autocomplete #additionalInfo="matAutocomplete">
                        <mat-option *ngFor="let tuple of filteredMissingAdditionalInfos$ | async" [value]="tuple[1]">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col text-right">
                <button mat-raised-button
                        class="mt-4 float-right"
                        color="primary">
                    Suchen
                </button>
            </div>

            <div class="col">
                <button mat-raised-button
                        class="mt-4 float-left"
                        color="warn"
                        (click)="reset()">
                    Zurücksetzen
                </button>
            </div>
        </div>

    </form>
</div>
