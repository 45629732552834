import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SelectSearch} from '../../shared/services/select-box/select-search';
import {MatSelect} from '@angular/material/select';
import {Subscription} from 'rxjs';
import {DataTable} from '../../shared/data-types/data-table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {VeterinaryTreatmentsService} from '../veterinary-treatments.service';
import {TupleHelper} from '../../shared/data-types/tuple';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CurrencyPipe} from '@angular/common';
import {SelectBoxUtility} from '../../shared/controls/select-box/select-box-utility';
import {FormerTreatmentEntryModel} from '../../shared/models/veterinary-treatments/former-veterinary-treatment-entry.model';
import {Actions, ofType} from '@ngrx/effects';
import {CRUD} from '../../shared/services/http/crud';
import {AnimalEntryModel} from '../../shared/models/animal/animal-entry.model';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {DialogType} from '../../dialogs/dialog-type';
import * as fromFormerTreatments from '../store/former-treatments/former-treatments.actions';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import * as moment from 'moment';
import {Order} from '../../shared/controls/data-table/ordering';

@Component({
    selector: 'app-former-veterinary-activities',
    templateUrl: './former-veterinary-treatments.component.html',
    styleUrls: ['./former-veterinary-treatments.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ],
    providers: [
        CurrencyPipe,
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class FormerVeterinaryTreatmentsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('singleSelectVeterinaryTreatment') public singleSelectVeterinaryTreatment: MatSelect;
    public selectSearchVeterinaryTreatment = new SelectSearch();
    public loading = false;
    public addNewVeterinaryTreatment = false;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public displayedColumns: string[] = [
        'description',
        'dateCreated',
        'invoiceText',
        'price',
        'report',
        'controls'
    ];
    public expandedElement: FormerTreatmentEntryModel | null;
    public addNewFormerTreatmentForm: FormGroup;
    public tableDef: Array<any> = [
        {
            key: 'id',
            header: 'Nr.',
            className: 'id'
        }, {
            key: 'description',
            header: 'Maßnahme',
            className: 'description'
        }, {
            key: 'dateCreated',
            header: 'Durchgeführt am',
            className: 'created'
        }, {
            key: 'invoiceText',
            header: 'Rechnungstext',
            className: 'invoiceText'
        }, {
            key: 'price',
            header: 'Kosten',
            className: 'price'
        }, {
            key: 'report',
            header: 'Befund',
            className: 'report'
        }
    ];

    // Additional 'filter' column list
    public displayedColumnNewTreatment: string[] = [
        'new-veterinary-treatment',
        'new-date',
        'new-invoice-text',
        'new-price',
        'new-report',
        'new-controls'
    ];
    public totalCosts = 0;
    public displayErroneousInputs = false;
    public formerTreatments: FormerTreatmentEntryModel[];
    public totalFormerTreatments = 0;
    public animal: AnimalEntryModel;

    protected addRequestSent = false;
    private formerTreatmentsSub: Subscription;
    private actionsSub: Subscription;
    private simpleDialogType = new DialogType();

    constructor(protected formBuilder: FormBuilder,
                protected actions$: Actions,
                protected currencyPipe: CurrencyPipe,
                protected veterinaryTreatmentService: VeterinaryTreatmentsService,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private dialog: MatDialog,
                private dialogRef: MatDialogRef<FormerVeterinaryTreatmentsComponent>) {
        if (this.data !== null && typeof this.data.animal !== 'undefined') {
            this.animal = this.data.animal;
        }
    }

    ngOnInit(): void {
        this.initVeterinaryTreatmentSelectBox();
        this.initTable();
        this.initForm();
    }

    ngAfterViewInit(): void {
        this.selectSearchVeterinaryTreatment.init(this.singleSelectVeterinaryTreatment);
    }

    ngOnDestroy(): void {
        this.selectSearchVeterinaryTreatment.destroy();

        if (this.formerTreatmentsSub) {
            this.formerTreatmentsSub.unsubscribe();
        }

        if (this.actionsSub) {
            this.actionsSub.unsubscribe();
        }
    }

    public addTableRow(): void {
        this.addNewVeterinaryTreatment = true;
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public canExpand($event: MouseEvent, element: any, expandedElement: FormerTreatmentEntryModel): any {
        return DataTable.canExpand($event, element, expandedElement);
    }

    public saveVeterinaryTreatment(): void {
        this.addRequestSent = true;

        if (!this.addNewFormerTreatmentForm.valid) {
            this.addRequestSent = false;
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;
        const description = this.addNewFormerTreatmentForm.value.treatmentName.viewValue;
        const invoiceText = this.addNewFormerTreatmentForm.value.invoiceText;
        const price = this.addNewFormerTreatmentForm.value.price.replace(/,/g, '.');
        const report = this.addNewFormerTreatmentForm.value.report;
        const date = moment(this.addNewFormerTreatmentForm.value.date).format('YYYY-MM-DD');
        const newFormerTreatment: FormerTreatmentEntryModel = {
            id: -1, // Dummy -> Is not sent to the server
            description,
            date,
            invoiceText,
            price,
            report,
            animalId: this.animal.id
        };


        this.addNewFormerTreatmentForm.reset();
        const filter = {
            animalId: this.animal.id
        };
        this.veterinaryTreatmentService
            .addFormerVeterinaryTreatment(newFormerTreatment, -1, -1, Order.DESC, 'date_performed', filter);
    }

    public treatmentChanged($event: any): void {
        const entry = $event.value;
        const invoiceText = TupleHelper.getValue(entry.meta, 'invoiceText');
        const price = TupleHelper.getValue(entry.meta, 'price');

        this.addNewFormerTreatmentForm.patchValue({
            invoiceText,
            price: this.currencyPipe.transform(price, 'EUR', '', '1.2-2')
        });
    }

    public deleteFormerTreatment(formerTreatment: FormerTreatmentEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Medizinische Maßnahme',
                identifier: ' Maßnahme ' + formerTreatment.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                const filter = {
                    animalId: this.animal.id
                };

                this.veterinaryTreatmentService
                    .deleteFormerVeterinaryTreatment(formerTreatment.id, filter);
            }
        });
    }

    protected initVeterinaryTreatmentSelectBox(): void {
        this.veterinaryTreatmentService.populateVeterinaryTreatmentsBox(this.selectSearchVeterinaryTreatment);
    }

    protected initForm(formerTreatment?: FormerTreatmentEntryModel): void {
        let treatmentName = '';
        let invoiceText = '';
        let price = '';
        let report = '';
        let date = moment().format('YYYY-MM-DD');
        if (typeof formerTreatment !== 'undefined') {
            treatmentName = SelectBoxUtility.getOptionsValue(formerTreatment.description);
            invoiceText = formerTreatment.invoiceText;
            price = formerTreatment.price.toString().replace(/\./g, ',');
            report = formerTreatment.report;
            date = formerTreatment.date;
        }

        this.addNewFormerTreatmentForm = this.formBuilder.group({
            treatmentName: [treatmentName, Validators.required],
            date: [date, Validators.required],
            invoiceText: [invoiceText, Validators.required],
            price: [price, [Validators.required, Validators.pattern(/^[0-9]+(,[0-9]{1,2})?$/)]],
            report
        });
    }

    private initTable(): void {
        this.loading = true;
        const filter = {
            animalId: this.animal.id
        };

        this.formerTreatmentsSub = this.veterinaryTreatmentService
            .fetchFormerVeterinaryTreatments(-1, 1, Order.DESC, 'date_performed', filter)
            .subscribe();

        this.actions$.pipe(
            ofType(fromFormerTreatments.SET_FORMER_TREATMENTS)
        ).subscribe((formerTreatmentsState: fromFormerTreatments.SetFormerTreatments) => {
            this.loading = false;
            this.addRequestSent = false;
            this.addNewVeterinaryTreatment = false;

            this.formerTreatments = formerTreatmentsState.payload.formerTreatments.slice();
            this.totalFormerTreatments = this.formerTreatments.length;
            if (this.formerTreatments.length > 0) {
                const aggregated = this.formerTreatments.reduce((prev, next) => {
                    return {
                        ...prev,
                        price: +prev.price + +next.price
                    };
                });
                this.totalCosts = aggregated.price;
            }

            if (formerTreatmentsState.payload.crud === CRUD.CREATE ||
                formerTreatmentsState.payload.crud === CRUD.UPDATE ||
                formerTreatmentsState.payload.crud === CRUD.DELETE) {
                this.veterinaryTreatmentService.handleRequestSuccess(formerTreatmentsState.payload.crud, 'Medizinische Maßnahme', '');
            }
        });
    }
}
