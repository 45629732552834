<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Pensionstierrückgabebestätigung</h1>

    <section *ngIf="animals !== null && animals !== undefined" style="overflow:visible !important;" class="mt-4">
        <ng-container *ngFor="let animal of animals">
            <div class="entry d-flex flex-wrap">
                <div class="left-col text-left">
                    <div class="font-weight-bolder">LfNr.: {{animal.id}}</div>
                    <div><span class="font-weight-bolder">Name:</span> {{animal.name}}</div>
                    <div><span class="font-weight-bolder">Tierart:</span> {{animal.species.name}}</div>
                    <div><span class="font-weight-bolder">Geschlecht:</span> {{Gender.getGender(animal.gender)}}</div>
                    <div><span
                            class="font-weight-bolder">Beschreibung:</span> {{characteristicsService.printCharacteristics(animal.characteristics)}}
                    </div>
                </div>

                <div class="right-col text-left">
                    <div class="font-weight-bolder">Chip-Nr.: {{animal.chipNumber}}</div>
                    <div><span class="font-weight-bolder">Rasse:</span> {{animal.race.name}}</div>
                    <div><span
                            class="font-weight-bolder">Kastriert:</span> {{CastrationStatus.getCastrationStatus(animal.castrated)}}
                    </div>
                    <div><span class="font-weight-bolder">Alter:</span> {{Age.printAge(animal.birthday)}}</div>
                </div>
                <div class="horLine w-100 pb-4"></div>
            </div>
        </ng-container>

    </section>

    <section class="mt-3 text-left">

    </section>

    <section *ngIf="person !== null && person !== undefined" class="container mt-3 text-left">
        <div class="row">
            <div id="person" class="col-6">
                <div class="font-weight-bolder">Abholer:</div>
                <div class="mt-3">
                    <div><span class="font-weight-bolder">PNr.:</span> {{person.id}}</div>
                    <div>{{person.firstName + ' ' + person.lastName}}</div>
                    <div>{{person.street}}</div>
                    <div>{{person.zip}} - {{person.city}}</div>
                    <div><span class="font-weight-bolder">Tel.:</span> {{person.phone}}</div>
                    <div><span class="font-weight-bolder">Geb. Datum:</span> {{person.birthday | date: 'dd.MM.yyyy'}}
                    </div>
                    <div><span class="font-weight-bolder">Nachweis:</span> {{person.proof}}</div>
                    <div><span class="font-weight-bolder">E-Mail:</span> {{person.email}}</div>
                </div>
            </div>

            <div id="owner" *ngIf="owner !== null && owner !== undefined && person.id !== owner.id" class="col-5">
                <div class="font-weight-bolder">Besitzer:</div>
                <div class="mt-3">
                    <div><span class="font-weight-bolder">PNr.:</span> {{owner.id}}</div>
                    <div>{{owner.firstName + ' ' + owner.lastName}}</div>
                    <div>{{owner.street}}</div>
                    <div>{{owner.zip}} - {{owner.city}}</div>
                    <div><span class="font-weight-bolder">Tel.:</span> {{owner.phone}}</div>
                    <div><span
                            class="font-weight-bolder">Geb. Datum:</span> {{owner.birthday | date: 'dd.MM.yyyy'}}
                    </div>
                    <div><span class="font-weight-bolder">Nachweis:</span> {{owner.proof}}</div>
                    <div><span class="font-weight-bolder">E-Mail:</span> {{owner.email}}</div>
                </div>
            </div>
        </div>
    </section>

    <section class="mt-5 text-left">
        <div class="row">
            <div class="col-6">
                Aufenthalt der Tiere von {{data.stayFrom | date: 'dd.MM.yyyy'}}
                bis {{data.stayUntil | date: 'dd.MM.yyyy'}}
                <div class="row text-right mt-4">
                    <div class="col-8">
                        <div class="font-weight-bolder">(Kosten/Tag)*Tage</div>
                    </div>
                    <div class="col-4">
                        {{costs |  currency:'EUR':'symbol':'1.2-2'}}
                    </div>
                </div>
                <div class="row text-right">
                    <div class="col-8">
                        <div class="font-weight-bolder">sonstige Kosten (Tierarzt,etc.)</div>
                    </div>
                    <div class="col-4">
                        {{additionalCostsTotal |  currency:'EUR':'symbol':'1.2-2'}}
                    </div>
                </div>
                <div class="row text-right" style="border-bottom: 2px solid #000">
                    <div class="col-8">
                        <div class="font-weight-bolder">- Anzahlung</div>
                    </div>
                    <div class="col-4">
                        {{depositTotal |  currency:'EUR':'symbol':'1.2-2'}}
                    </div>
                </div>
                <div class="row text-right" style="border-bottom: 3px solid #000">
                    <div class="col-8">
                        <div class="font-weight-bolder">GESAMT</div>
                    </div>
                    <div class="col-4">
                        {{total |  currency:'EUR':'symbol':'1.2-2'}}
                    </div>
                </div>
            </div>
        </div>

    </section>

    <section class="mt-2 text-left">
        Der Abholer bestätigt mit seiner Unterschrift, dass ihm heute das oben beschriebene Tier zur Mitnahme übergeben
        wurde.
        Entstandene Kosten des Tierheims sind mit erfolgter Zahlung gedeckt, eventuell Forderungen Dritter (Tierrettung,
        Tierklinik, …) sind nicht berücksichtigt.
    </section>

    <div class="text text-left h-100" style="margin-top: 20px">
        <section class="disclaimer small mt-4 text-left">
            Information zum Datenschutz: <br />
            Name, Adress- und Kontaktdaten der überbringenden Peron und die Chipnummer des Tieres werden zum Zweck der
            Administration und Dokumentation der Tiere gespeichert. Die Daten werden nicht weitergegeben und mindestens
            drei Jahre – sofern in §21 Tierschutzgesetz nicht anders bestimmt – lang aufbewahrt. Im Rahmen der
            fachlichen Aufsicht dürfen diese Daten von der zuständigen Bezirksverwaltungsbehörde eingesehen werden. Die
            Chipnummer des Tieres kann vom Amt der OÖ Landesregierung, Abteilung Gesundheit eingesehen werden.
        </section>

        <section class="font-weight-bolder">
            □ Ich bin mit der Weitergabe meiner Kontaktdaten an die bei der Rückführung des Tieres beteiligten Dritten
            <br /> (
            <div style="width:120px; border-bottom: 1px solid black; display: inline-block;"></div>
            ) einverstanden.
        </section>

        <div class="d-flex justify-content-around mt-4 align-items-end">
            <div class="left">
                <div class="mt-5 mb-4">{{cityName}}, am {{data.date | date:'dd.MM.yyyy'}}</div>
            </div>

            <div class="right text-center">
                <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                <div>Unterschrift</div>
            </div>
        </div>
    </div>
</div>
