import {Action} from '@ngrx/store';
import {ZipCity} from '../zip-city-search.service';
import {ErrorTypes} from '../../../data-types/error-types';

export const LOAD_ZIP_CITIES = '[ZipCities] Load ZipCities';
export const SET_ZIP_CITIES = '[ZipCities] Set ZipCities';
export const HTTP_FAIL = '[ZipCities] Http Fail';

export class LoadZipCities implements Action {
    readonly type = LOAD_ZIP_CITIES;

    constructor(public payload: {
        type: ZipCity,
        value: string
    }) {
    }
}

export class SetZipCities implements Action {
    readonly type = SET_ZIP_CITIES;

    constructor(public payload: any[]) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type ZipCityActions =
    LoadZipCities
    | SetZipCities
    | HttpFail;

