import {Component} from '@angular/core';
import {OccupationService} from '../animals/store/statistics/occupation/occupation.service';
import * as moment from 'moment';
import {OccupationEntryModel} from '../shared/models/occupation-entry.model';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
    public loading = false;
    public occupationEntryModel: OccupationEntryModel;

    private link: HTMLAnchorElement;

    constructor(private occupationService: OccupationService) {
    }

    public filter($event: any): void {
        this.occupationEntryModel = null;

        if (this.link) {
            document.body.querySelector('#download-link').removeChild(this.link);
            this.link = null;
        }

        if ($event && $event.occupationFrom && $event.occupationTo) {
            const from = moment($event.occupationFrom + ' 00:00:00');
            const to = moment($event.occupationTo + ' 23:59:59');

            if (to.isBefore(from)) {
                return;
            }

            const fromStr = from.format('YYYY-MM-DD HH:mm:ss');
            const toStr = to.format('YYYY-MM-DD HH:mm:ss');
            this.loading = true;
            this.occupationService.fetchOccupation(fromStr, toStr).subscribe(occupationEntryModel => {
                this.occupationEntryModel = occupationEntryModel;

                const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
                this.link = document.createElement('a');
                this.link.href = mediaType + this.occupationEntryModel.fileContent;
                this.link.download = `tierheimauslastung_${$event.occupationFrom}-${$event.occupationTo}.xlsx`;
                this.link.textContent = 'Datei herunterladen!';
                document.body.querySelector('#download-link').appendChild(this.link);
                this.loading = false;
            });
        }
    }
}
