import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, switchMap, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as fromLoggingActions from './logging.actions';
import {EndpointService} from '../../services/http/endpoint.service';
import {ErrorTypes} from '../../data-types/error-types';

@Injectable()
export class LoggingEffects {
    @Effect({dispatch: false})
    addLogging$ = this.actions$.pipe(
        ofType(fromLoggingActions.ADD_LOGGING),
        switchMap((loggingState: fromLoggingActions.AddLogging) => {
            return this.endpointService.logging().pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint, {
                            data: loggingState.payload
                        })
                        .pipe(
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService) {
    }

    private handleError = (errorRes: any): Observable<fromLoggingActions.HttpFail> => {
        const errorObj: ErrorTypes = {
            isAuthorized: true,
            message: ''
        };

        let errorMessage = 'Ein allgemeiner Fehler beim Logging ist aufgetreten. ';
        if (errorRes.error &&
            errorRes.error.data &&
            errorRes.error.data.errors) {
            errorObj.message = errorMessage;
            return of(new fromLoggingActions.HttpFail(errorObj));
        }

        if (errorRes.message) {
            errorMessage += 'Beschreibung: ';
            errorMessage += errorRes.message;
        }
        errorObj.message = errorMessage;
        return of(new fromLoggingActions.HttpFail(errorObj));
    };
}
