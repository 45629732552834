import * as moment from 'moment';

export class Constants {
    public static readonly Income = 'Eingang';
    public static readonly Outcome = 'Ausgang';

    public static readonly Donation = 'Spende';
    public static readonly GodParenthood = 'Patenschaft';
    public static readonly AnimalPension = 'Tierpension';
    public static readonly IncomingInvoice = 'Eingangsrechnung';
    public static readonly Unknown = 'Unbekannt';

    public static readonly SteyrShelterName = 'Tierheim Steyr';
    public static readonly SteyrAddress = 'Neustifter-hauptstraße 11';
    public static readonly SteyrZipAndCity = '4407 Steyr-Gleink';
    public static readonly SteyrPhone = '07252/71650';
    public static readonly SteyrWebsite = 'www.tierheim-steyr.at';
    public static readonly SteyrEmail = 'tierheim-steyr@aon.at';

    public static readonly LinzShelterName = 'Tierheim Linz';
    public static readonly LinzAddress = 'Mostnystraße 16';
    public static readonly LinzZipAndCity = '4040 - Linz-Urfahr';
    public static readonly LinzPhone = '0732/24 78 87';
    public static readonly LinzWebsite = 'www.tierheim-linz.at';
    public static readonly LinzEmail = 'office@tierheim-linz.at';

    public static readonly LinzCityName = 'Linz';
    public static readonly SteyrCityName = 'Steyr';
}

export class CastrationStatus {
    public static readonly KASTRIERT = new CastrationStatus('kastriert', 'Kastriert');
    public static readonly NICHT_KASTRIERT = new CastrationStatus('nicht_kastriert', 'Nicht kastriert');
    public static readonly UNBEKANNT = new CastrationStatus('unbekannt', 'Unbekannt');

    public static getCastrationStatus(castrationStatus: string): string {
        if (castrationStatus === 'kastriert') {
            return CastrationStatus.KASTRIERT.value;
        } else if (castrationStatus === 'nicht_kastriert') {
            return CastrationStatus.NICHT_KASTRIERT.value;
        } else {
            return CastrationStatus.UNBEKANNT.value;
        }
    }

    public static getViewValue(key: string): string {
        switch (key) {
            case this.KASTRIERT.key:
                return this.KASTRIERT.value;
            case this.NICHT_KASTRIERT.key:
                return this.NICHT_KASTRIERT.value;
            case this.UNBEKANNT.key:
                return this.UNBEKANNT.value;
        }
    }

    private constructor(public readonly key: string, public readonly value: any) {
    }

}

export class ShelterStatus {
    public static readonly IM_HEIM = new ShelterStatus('im_heim', 'Im Heim');
    public static readonly NICHT_IM_HEIM = new ShelterStatus('nicht_im_heim', 'Nicht im Heim');
    public static readonly PENSIONSTIER_IM_HEIM = new ShelterStatus('pensionstier_im_heim', 'Pensionstier im Heim');

    private constructor(public readonly key: string, public readonly value: any) {
    }
}

export class Gender {
    public static readonly MALE = new Gender('male', 'Männlich');
    public static readonly FEMALE = new Gender('female', 'Weiblich');
    public static readonly UNKNOWN = new Gender('unknown', 'Unbekannt');

    public static getGender(gender: string): string {
        if (gender === 'male') {
            return Gender.MALE.value;
        } else if (gender === 'female') {
            return Gender.FEMALE.value;
        } else {
            return Gender.UNKNOWN.value;
        }
    }

    private constructor(public readonly key: string, public readonly value: any) {
    }
}

export class Age {
    public static printAge(birthday: string): string {
        const ageYears = birthday !== null && birthday !== '' ?
            moment().diff(birthday, 'years') : 0;
        const ageMonths = birthday !== null && birthday !== '' ?
            moment().diff(birthday, 'months') % 12 : 0;

        let output = '';
        if (ageYears >= 0) {
            output += ageYears + ' Jahr(e)';
        }
        if (ageMonths >= 0) {
            output += ', ' + ageMonths + ' Monate';
        }

        return output === '' ? '0' : output;
    }
}

export enum Shelter {
    LINZ,
    STEYR
}
