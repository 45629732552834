import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';
import * as fromFDRaceActions from './fd-race.actions';
import {CRUD} from '../../../shared/services/http/crud';

@Injectable()
export class FDRacesEffects {
    @Effect()
    loadFDRaces$ = this.actions$.pipe(
        ofType(fromFDRaceActions.LOAD_FD_RACES),
        switchMap((fdRaceState: fromFDRaceActions.LoadFDRaces) => {
            const fdRaceFilter = fdRaceState.payload.filter;

            let params = new HttpParams();

            if (fdRaceFilter) {
                params = params.append('filter', 'true');
                params = typeof fdRaceFilter.speciesGroupName !== 'undefined' && fdRaceFilter.speciesGroupName !== '' ?
                    params.append('species_group_name', fdRaceFilter.speciesGroupName) : params;
            }

            return this.endpointService.fdRaces(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params}).pipe(
                            map(response => response.data)
                        );
                }),
                map(response => {
                    const raceModels = response.map(entry => {
                        const mappedModel: DictionaryEntryModel = {
                            id: entry.fundtier_db_id,
                            name: entry.value.toString(),
                            meta: [
                                {key: 'species', value: entry.species}
                            ]
                        };
                        return mappedModel;
                    });
                    return new fromFDRaceActions.SetFDRaces({
                        fdRaces: raceModels,
                        operation: fdRaceState.payload.crud
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addFDRace$ = this.actions$.pipe(
        ofType(fromFDRaceActions.ADD_FD_RACE),
        switchMap((raceState: fromFDRaceActions.AddFDRace) => {
            return this.endpointService.fdMappings(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                mapping_id: raceState.payload.mappingId,
                                species_group_name: raceState.payload.speciesGroupName,
                                race_id: raceState.payload.raceId
                            }
                        ).pipe(
                            map((response) => {
                                const newRace: DictionaryEntryModel = {
                                    id: response.data.id,
                                    name: response.data.name,
                                    meta: [
                                        {key: 'speciesId', value: response.data.species_id}
                                    ]
                                };

                                return new fromFDRaceActions.LoadFDRaces({
                                    crud: CRUD.CREATE
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromFDRaceActions.HttpFail> => {
        return handleHTTPError(errorRes,
            fromFDRaceActions,
            'Fundtierdatenbank Rassen',
            this.loggingService,
            'race_already_exists');
    };
}
