<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Pflegevertrag</h1>
    <span class="d-inline-block mt-4">abgeschlossen zwischen dem <br />
    <span class="font-weight-bolder">OÖ Landestierschutzverein <br />
        {{shelterName}} <br />
        {{address}} <br />
        {{zipAndCity}} </span> <br />
    als „Übergeber“ einerseits und
</span>

    <div class="d-flex justify-content-around mt-5" *ngIf="person !== null && person !== undefined">
        <div class="left-col text-left">
            <div class="font-weight-bolder">{{person.firstName + ' ' + person.lastName}}</div>
            <div>{{person.street}}</div>
            <div>{{person.zip}} - {{person.city}}</div>
            <div>Tel.: {{person.phone}}</div>
        </div>

        <div class="right-col text-left">
            <div>PNr.: {{person.id}}</div>
            <div>Geb. Datum: {{person.birthday | date: 'dd.MM.yyyy'}}</div>
            <div>Nachweis: {{person.proof}}</div>
            <div>E-Mail: {{person.email}}</div>
        </div>
    </div>

    <div class="mt-5">im Folgenden „Übernehmer“ genannt, andererseits.</div>

    <div class="mt-4 mb-5">Folgende Tiere werden mit heutigem Datum der oa. Person zur Pflege übergeben:</div>

    <div *ngIf="animals !== null && animals !== undefined" style="overflow:visible !important;">
        <ng-container *ngFor="let animal of animals">
            <div class="d-flex flex-wrap justify-content-between mt-4 entry">
                <div class="left-col text-left">
                    <div class="font-weight-bolder">LfNr.: {{animal.id}}</div>
                    <div>Name: {{animal.name}}</div>
                    <div>Tierart: {{animal.species.name}}</div>
                    <div>Geschlecht: {{Gender.getGender(animal.gender)}}</div>
                    <div>Beschreibung: {{characteristicsService.printCharacteristics(animal.characteristics)}}</div>
                </div>

                <div class="right-col text-left">
                    <div>Chip-Nr.: {{animal.chipNumber}}</div>
                    <div>Rasse: {{animal.race.name}}</div>
                    <div>Kastriert: {{CastrationStatus.getCastrationStatus(animal.castrated)}}</div>
                    <div>Alter: {{Age.printAge(animal.birthday)}}</div>
                </div>
                <div class="horLine w-100 pb-4"></div>
            </div>
        </ng-container>
    </div>

    <div class="text text-left h-100" style="margin-top: 100px">
        <p>
            Der Übernehmer nimmt zur Kenntnis, dass gemäß dem Bundestierschutzgesetz das Tier lediglich in Pflege
            übernommen
            wird. Dadurch wird kein Anspruch des Übernehmenden auf Übertragung des Eigentums am Tier begründet. Eine
            Vermittlung des Tieres („Vergabe“) erfolgt ausschließlich durch das {{shelterName}} und setzt den Abschluss
            eines
            gesonderten Vergabevertrags voraus. Der Pflegevertrag kann jederzeit von beiden Parteien ohne Angabe von
            Gründen
            gekündigt werden, was zur Folge hat, dass die übernommenen Tiere unverzüglich dem {{shelterName}}
            zurückzustellen
            sind.
        </p>
        <p>
            Der Übernehmer verpflichtet sich, für ordnungsgemäße Pflege, ausreichende und artgerechte Fütterung sowie
            für
            tierärztliche Behandlung zu sorgen. Die tierärztliche Behandlung innerhalb der Behaltefrist hat nach
            vorhergehender
            telefonischer Ankündigung durch die Tierärzte des Tierheims {{cityName}} zu erfolgen. Kosten, die aus der
            Behandlung
            durch
            andere Tierärzte entstehen, werden nur ausnahmsweise bei Gefahr in Verzug übernommen.</p>

        <p>
            Jede Quälerei oder Misshandlung ist zu unterlassen und auch nicht zu dulden. Bei Zuwiderhandeln ist der OÖ
            Landestierschutzverein berechtigt, eine Strafzahlung von € 500,- einzuheben und das Tier sofort zu
            entziehen.
            Sonstige straf- und zivilrechtliche Bestimmungen bleiben davon unberührt.</p>

        <p>
            Der OÖ Landestierschutzverein haftet nicht für durch das übernommene Tier verursachte Schäden (§ 1320 ABGB).
            Der
            Übernehmer erklärt, dass er vom Übergeber über die Eigenheiten bzw Wesenszüge des Tieres aufgeklärt
            wurde.</p>

        <p>
            <span class="mr-2">☐</span> Diese Aufklärung erfolgte auch in einem separaten - diesem Vertrag angeschlossen
            -
            Zusatzblatt („Ergänzung
            Pflegevertrag“).<br />
            <span class="mr-2">☐</span> Der Übernehmer nimmt weiters zur Kenntnis, dass der OÖ Landestierschutzverein
            selbst
            nur Verwahrer des Tieres
            ist
            und das Eigentum dieses Tiers (noch) nicht übertragen kann.</p>

        <p class="small">
            Der Übernehmer erklärt sich mit der Speicherung seiner Daten nach Art. 6 Abs 1 lit a DSGVO einverstanden
            (siehe
            DSGVO-Erklärung auf http://www.tierheim-linz.at). Ein Widerruf dieser Zustimmung ist jederzeit möglich.</p>

        <div class="d-flex justify-content-around mt-4 align-items-end">
            <div class="left">
                <div class="d-flex mb-3">
                    <div>{{cityName}}, am
                    </div>
                    <div class="ml-3">
                        <div>{{date | date: 'dd.MM.yyyy'}}</div>
                        <div class="d-inline-block" style="width: 200px"></div>
                    </div>
                </div>
                <div>Für das {{shelterName}}:</div>
                <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
            </div>
            <div class="right">
                <div>Übernehmer:</div>
                <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
            </div>
        </div>
    </div>
</div>
