<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Bisherige medizinische Maßnahmen
    </h3>
    <div class="position-absolute mt-4">
        <span>LFNr.: {{animal.id}}</span>
    </div>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="true" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-body">
            <div id="top-controls" class="d-flex justify-content-between">
                <div id="add-new-treatment">
                    <a class="d-flex align-items-center no-color"
                       (click)="addTableRow()">
                        Neue medizinische Maßnahme hinzufügen <i
                            class="add-btn fas fa-plus d-flex justify-content-center align-items-center ml-3"></i>
                    </a>
                </div>
            </div>
            <div>
                <table mat-table
                       multiTemplateDataRows
                       [dataSource]="formerTreatments"
                       class="mat-elevation-z0 extensible">
                    <ng-container *ngFor="let def of tableDef">
                        <ng-container [matColumnDef]="def.key">
                            <th mat-header-cell *matHeaderCellDef> {{def.header}} </th>
                            <td [ngClass]="def.className"
                                mat-cell
                                *matCellDef="let element"
                                [ngSwitch]="def.key">
                                <ng-container *ngSwitchCase="'price'">
                                    {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'dateCreated'">
                                    {{element['date'] | date:'dd.MM.yyyy'}}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{element[def.key]}}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>

                    <ng-container [formGroup]="addNewFormerTreatmentForm">
                        <ng-container matColumnDef="new-veterinary-treatment">
                            <td mat-footer-cell *matFooterCellDef class="pr-2">
                                <mat-form-field class="select-search">
                                    <mat-select id="veterinary-treatment-select"
                                                formControlName="treatmentName"
                                                (selectionChange)="treatmentChanged($event)"
                                                placeholder="Medizinische Maßnahme"
                                                #singleSelectVeterinaryTreatment>
                                        <mat-option>
                                            <ngx-mat-select-search
                                                    [formControl]="selectSearchVeterinaryTreatment.filterCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option
                                                *ngFor="let treatment of selectSearchVeterinaryTreatment.filteredElements | async"
                                                [value]="treatment">
                                            {{treatment.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="new-date">
                            <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (click)="datePickerSupervision.open()"
                                           [ngxMatDatetimePicker]="datePickerSupervision"
                                           placeholder="Datum"
                                           formControlName="date"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="datePickerSupervision"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #datePickerSupervision
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute"
                                                             [hideTime]="true">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="new-invoice-text">
                            <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                <mat-form-field>
                                    <mat-label>Rechnungstext</mat-label>
                                    <input matInput
                                           placeholder="Rechnungstext"
                                           formControlName="invoiceText"
                                           value="Rechnungstext">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="new-price">
                            <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                <mat-form-field>
                                    <mat-label>Preis in €</mat-label>
                                    <input matInput placeholder="Preis in €"
                                           formControlName="price">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="new-report">
                            <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                <mat-form-field>
                                    <mat-label>Befund</mat-label>
                                    <input matInput
                                           placeholder="Befund"
                                           formControlName="report">
                                </mat-form-field>
                            </td>
                        </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="new-controls">
                        <td mat-footer-cell *matFooterCellDef class="pl-2 pr-0 text-center">
                            <a class="mr-2 remove-btn fas fa-minus"
                               (click)="addNewVeterinaryTreatment = false"
                               matTooltip="Abbrechen"
                               matTooltipPosition="right"
                               matTooltipClass="tooltip-popup"
                            ></a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="controls">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <div class="control-wrapper d-flex align-items-center justify-content-center">
                                <a class="fas fa-edit icon"
                                   matTooltip="Maßnahme bearbeiten"
                                   matTooltipPosition="right"
                                   matTooltipClass="tooltip-popup"></a>
                                <a class="fas fa-trash-alt icon prevent-opening"
                                   matTooltip="Maßnahme löschen"
                                   (click)="deleteFormerTreatment(element)"
                                   matTooltipPosition="right"
                                   matTooltipClass="tooltip-popup"></a>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
                            <div class="table-element-detail w-100"
                                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <app-former-veterinary-treatments-quick-edit class="w-100"
                                                                             [formerTreatment]="element">
                                </app-former-veterinary-treatments-quick-edit>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                        class="table-row"
                        [class.expanded-row]="expandedElement === element"
                        (click)="expandedElement = canExpand($event, element, expandedElement)">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

                    <tr mat-footer-row
                        [ngStyle]="{'opacity': addNewVeterinaryTreatment ? '1' : '0'}"
                        [ngClass]="{'displayErroneousInputs': displayErroneousInputs}"
                        *matFooterRowDef="displayedColumnNewTreatment"></tr>
                </table>

                <div *ngIf="loading"
                     style="display: flex; justify-content: center; align-items: center; background: white;">
                    <mat-progress-spinner
                            color="accent"
                            mode="indeterminate">
                    </mat-progress-spinner>
                </div>

                <div id="bottom-controls"
                     class="controls d-flex justify-content-between align-items-baseline mt-2"
                     [ngStyle]="{'bottom': addNewVeterinaryTreatment ? '0' : '94px'}">
                    <div id="total-results">Gesamt: <span>{{totalFormerTreatments}}</span></div>
                </div>

                <div class="default-entry mt-2" *ngIf="totalFormerTreatments === 0 && loading === false">
                    Keine Ergebnisse gefunden.
                </div>
            </div>

            <div class="row" *ngIf="addNewVeterinaryTreatment">
                <div class="col-md-12">
                    <button class="float-right mt-4 ml-4"
                            mat-raised-button color="primary"
                            [disabled]="addRequestSent && addNewFormerTreatmentForm.valid"
                            (click)="saveVeterinaryTreatment()">
                        <i class="fas fa-exclamation-circle mr-2"
                           style="color: #b71c1c"
                           *ngIf="displayErroneousInputs">
                        </i>
                        Maßnahme speichern
                    </button>
                    <mat-icon
                            [hidden]="!(addRequestSent && addNewFormerTreatmentForm.valid)"
                            class="mr-1 float-right" style="margin-top: 33px">
                        <mat-spinner color="accent" diameter="20"></mat-spinner>
                    </mat-icon>
                </div>
            </div>

            <div id="aggregated-price" class="font-weight-bolder mt-4">
                <div class="border-wrapper row">
                    <div class="col-md-2">
                        <span class="lead">Gesamtkosten:</span>
                    </div>
                    <div class="col-md-3">
                        <span class="lead">{{totalCosts | currency:'EUR':'symbol':'1.2-2'}}</span>
                    </div>
                </div>
            </div>

            <div class="row mt-5" *ngIf="!addNewVeterinaryTreatment">
                <div class="col">
                    <button class="float-right" mat-raised-button color="primary" (click)="cancel()">
                        Schließen
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
