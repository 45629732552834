import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {CRUD} from '../../../../shared/services/http/crud';
import {SnackStatusType} from '../../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../../shared/components/snackbar/snackbar.service';
import * as fromApp from '../../../../store/app.reducer';
import * as fromOccupation from './occupation.actions';
import {OccupationEntryModel} from '../../../../shared/models/occupation-entry.model';

@Injectable({
    providedIn: 'root'
})
export class OccupationService {
    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public fetchOccupation(from: string, to: string): Observable<OccupationEntryModel> {
        return this.store.select('occupationList').pipe(
            take(1),
            map(occupationState => {
                return occupationState.occupationEntryModel;
            }),
            switchMap(() => {
                this.store.dispatch(new fromOccupation.LoadOccupation({
                    from,
                    to
                }));
                return this.actions$.pipe(
                    ofType(fromOccupation.SET_OCCUPATION),
                    take(1),
                    map((occupationState: fromOccupation.SetOccupation) => {
                        return occupationState.payload.occupationEntryModel;
                    })
                );
            })
        );
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
