import {CareSpaceEntryModel} from '../models/animal/care-space-entry.model';
import {PersonConverter} from './person-converter';

export class CareSpaceConverter {
    public static toCareSpaceHTTP(careSpace: CareSpaceEntryModel): any {
        const httpEntry = {
            id: careSpace.id,
            animal_id: careSpace.animalId,
            person: careSpace.person,
            date_performed: careSpace.created,
            date_back_to_shelter: careSpace.dateBackToShelter
        };
        return httpEntry;
    }

    public static toCareSpaceModel(careSpace: any): CareSpaceEntryModel {
        const careSpaceEntry: CareSpaceEntryModel = {
            id: careSpace.id,
            animalId: careSpace.animal_id,
            person: PersonConverter.toPersonEntryModel(careSpace.person),
            created: careSpace.date_performed,
            dateBackToShelter: careSpace.date_back_to_shelter
        };
        return careSpaceEntry;
    }

}
