<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        {{title}}
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="false" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card mt-0">
        <div class="card-body">
            <form class="form"
                  [formGroup]="simpleForm"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <ng-container [ngSwitch]="type">
                    <ng-container *ngSwitchCase="simpleDialogType.DELETE_SPECIES">
                        <ng-container [ngTemplateOutlet]="deleteSpeciesAndRaces"
                                      [ngTemplateOutletContext]="{form: simpleForm, type: type, identifier: identifier}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.DELETE_RACE">
                        <ng-container [ngTemplateOutlet]="deleteSpeciesAndRaces"
                                      [ngTemplateOutletContext]="{form: simpleForm, type: type, identifier: identifier}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.GOING_TO_HEAVEN">
                        <ng-container [ngTemplateOutlet]="goingToHeaven"
                                      [ngTemplateOutletContext]="{form: simpleForm}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.CANCEL_BOOKING">
                        <ng-container [ngTemplateOutlet]="cancelBooking"
                                      [ngTemplateOutletContext]="{form: simpleForm, identifier: identifier}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.CREATE_GENERIC">
                        <ng-container [ngTemplateOutlet]="createGeneric"
                                      [ngTemplateOutletContext]="{form: simpleForm, entity: entity}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.DELETE_GENERIC">
                        <ng-container [ngTemplateOutlet]="deleteGeneric"
                                      [ngTemplateOutletContext]="{identifier: identifier}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.TRIVIAL">
                        <ng-container [ngTemplateOutlet]="trivial"
                                      [ngTemplateOutletContext]="{content: content}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.REMARK">
                        <ng-container [ngTemplateOutlet]="remark"
                                      [ngTemplateOutletContext]="{content: content}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.UPLOAD">
                        <ng-container *ngTemplateOutlet="upload"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="simpleDialogType.UNSAVED_CHANGES">
                        <ng-container *ngTemplateOutlet="unsavedChanges"></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="simpleDialogType.NO_SYNCHRONIZATION">
                        <ng-container *ngTemplateOutlet="noSynchronization"></ng-container>
                    </ng-container>
                </ng-container>
            </form>
        </div>
    </div>
</mat-dialog-content>

<ng-template #goingToHeaven let-form="form">
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="col-md-3">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="dateDied.open()"
                           [ngxMatDatetimePicker]="dateDied"
                           placeholder="Datum"
                           formControlName="dateDied"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="dateDied"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #dateDied
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Todesursache</mat-label>
                    <textarea matInput
                              formControlName="deathReason"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <button mat-raised-button color="warn" [mat-dialog-close]="false">Abbrechen
                </button>
                <button *ngIf="deathReason === null || deathReason === ''"
                        class="float-right"
                        mat-raised-button
                        color="primary"
                        (click)="onSubmit()">
                    Speichern
                </button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #deleteSpeciesAndRaces let-form="form" let-type="type" let-identifier="identifier">
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="col-md-12">
                Soll die {{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}} <span
                    class="font-weight-bolder">{{identifier}}</span>
                wirklich <span
                    class="font-weight-bolder text-danger">gelöscht</span> werden? Dies kann nicht rückgängig gemacht
                werden.
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6">
                Alle Tiere mit der {{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}} <span
                    class="font-weight-bolder">{{identifier}}</span> werden
                folgender {{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}}
                zugewiesen:
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">
                <mat-form-field appearance="fill">
                    <mat-label>{{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}}</mat-label>
                    <mat-select
                            formControlName="newSpeciesOrRace"
                            (selectionChange)="speciesOrRaceChanged($event)"
                            [multiple]="false">
                        <mat-option
                                *ngFor="let option of options"
                                [value]="option">
                            <div class="option-wrapper d-flex justify-content-between align-items-center">
                                <div>
                                    {{option.viewValue}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="type === simpleDialogType.DELETE_SPECIES">
                <mat-form-field appearance="fill">
                    <mat-label>Rasse</mat-label>
                    <mat-select
                            formControlName="filteredRaces"
                            [multiple]="false">
                        <mat-option
                                *ngFor="let race of selectSearchFilteredRaces.filteredElements | async"
                                [value]="race">
                            <div class="option-wrapper d-flex justify-content-between align-items-center">
                                <div>
                                    {{race.viewValue}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <button mat-raised-button color="warn" [mat-dialog-close]="false">Abbrechen</button>
                <button class="float-right" mat-raised-button color="warn" (click)="onSubmit()">
                    Löschen
                </button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template let-form="form" let-type="type" let-identifier="identifier">
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="col-md-12">
                Soll die Untekunft <span
                    class="font-weight-bolder">{{identifier}}</span>
                wirklich <span
                    class="font-weight-bolder text-danger">gelöscht</span> werden? Dies kann nicht rückgängig gemacht
                werden.
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                Alle Tiere mit der {{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}} <span
                    class="font-weight-bolder">Hund</span> werden
                folgender {{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}}
                zugewiesen:
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">
                <mat-form-field appearance="fill">
                    <mat-label>{{type === simpleDialogType.DELETE_SPECIES ? 'Art' : 'Rasse'}}</mat-label>
                    <mat-select
                            formControlName="newSpeciesOrRace"
                            [multiple]="false">
                        <mat-option
                                *ngFor="let option of options"
                                [value]="option">
                            <div class="option-wrapper d-flex justify-content-between align-items-center">
                                <div>
                                    {{option.viewValue}}
                                </div>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <button mat-raised-button color="warn" [mat-dialog-close]="false">Abbrechen</button>
                <button class="float-right" mat-raised-button color="warn" (click)="onSubmit()">
                    Löschen
                </button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #cancelBooking let-form="form" let-identifier="identifier">
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="col-md-12">
                Soll <span class="font-weight-bolder">{{identifier}}</span> wirklich <span
                    class="font-weight-bolder text-danger">storniert</span> werden? Dies kann nicht rückgängig gemacht
                werden.
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <mat-form-field class="text-input">
                    <mat-label>Stornierer</mat-label>
                    <input matInput
                           formControlName="cancellator"
                           placeholder="Kassier">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Grund</mat-label>
                    <input formControlName="reason"
                           matInput
                           placeholder="Name">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col">
                <button mat-raised-button color="warn" [mat-dialog-close]>
                    Abbrechen
                </button>
                <button class="float-right" mat-raised-button color="warn" (click)="onSubmit()">
                    Stornieren
                </button>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #createGeneric let-form="form" let-entity="entity">
    <div [formGroup]="form" class="row">
        <div class="col-md-12" *ngIf="entity === 'Tierrasse'">
            <mat-form-field appearance="fill">
                <mat-label>Tierart</mat-label>
                <mat-select #singleSelectSpecies
                            (ngModelChange)="speciesChanged($event)"
                            formControlName="species">
                    <mat-option>
                        <ngx-mat-select-search
                                [formControl]="selectSearchSpecies.filterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                            *ngFor="let species of selectSearchSpecies.filteredElements | async"
                            [value]="species">
                        {{species.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="entity === 'Merkmal'">
            <mat-form-field appearance="fill">
                <mat-label>Tierrasse</mat-label>
                <mat-select #singleSelectRace
                            formControlName="race">
                    <mat-option>
                        <ngx-mat-select-search
                                [formControl]="selectSearchRace.filterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                            *ngFor="let race of selectSearchRace.filteredElements | async"
                            [value]="race">
                        {{race.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input formControlName="name"
                       matInput
                       placeholder="Name">
                <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="entity === 'Tierrasse'">
            <mat-form-field appearance="fill">
                <mat-label>Fundtierdatenbank Rasse</mat-label>
                <mat-select #singleSelectFDRace
                            formControlName="fdRace">
                    <mat-option>
                        <ngx-mat-select-search
                                [formControl]="selectSearchFDRaces.filterCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                            *ngFor="let fdRace of selectSearchFDRaces.filteredElements | async"
                            [value]="fdRace">
                        {{fdRace.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row  mt-5">
        <div class="col">
            <button mat-raised-button color="warn" [mat-dialog-close]>
                Abbrechen
            </button>
            <button class="float-right" mat-raised-button color="primary" (click)="onSubmit()">
                Speichern
            </button>
        </div>
    </div>
</ng-template>

<ng-template #deleteGeneric let-identifier="identifier">
    <div class="row">
        <div class="col-md-12">
            Soll <span class="font-weight-bolder">{{identifier}}</span> wirklich <span
                class="font-weight-bolder text-danger">gelöscht</span> werden? Dies kann nicht rückgängig gemacht
            werden.
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <button mat-raised-button color="warn" [mat-dialog-close]="false">
                Abbrechen
            </button>
            <button class="float-right" mat-raised-button color="warn" [mat-dialog-close]="true">
                Löschen
            </button>
        </div>
    </div>
</ng-template>

<ng-template #trivial let-content="content">
    <div class="row">
        <div class="col-md-12">
            {{content}}
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <button class="float-right" mat-raised-button color="primary" [mat-dialog-close]>
                Ok
            </button>
        </div>
    </div>
</ng-template>

<ng-template #remark let-content="content">
    <div class="row">
        <div class="col-md-12">
            {{content}}
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <button mat-raised-button color="warn" [mat-dialog-close]="false">
                Abbrechen
            </button>
            <button class="float-right" mat-raised-button color="primary" [mat-dialog-close]="true">
                Ok
            </button>
        </div>
    </div>
</ng-template>

<ng-template #upload>
    <app-image-upload [keepAspectRatio]=keepAspectRatio [dialogRef]="dialogRef"></app-image-upload>
</ng-template>

<ng-template #unsavedChanges>
    <div class="row">
        <div class="col-md-12">
            Achtung! Sollen die ungespeicherten Änderungen verworfen werden?
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <button mat-raised-button color="warn" [mat-dialog-close]="false">
                Nein
            </button>
            <button class="float-right" mat-raised-button color="primary" [mat-dialog-close]="true">
                Ja
            </button>
        </div>
    </div>
</ng-template>

<ng-template #noSynchronization>
    <div class="row">
        <div class="col-md-12">
            Achtung! Tier wird nicht mit der Fundtierdatenbank synchronisiert. Trotzdem speichern?
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <button mat-raised-button color="warn" [mat-dialog-close]="false">
                Nein
            </button>
            <button class="float-right" mat-raised-button color="primary" [mat-dialog-close]="true">
                Ja
            </button>
        </div>
    </div>
</ng-template>
