import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {UserEntryModel} from '../../../shared/models/user-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_USERS = '[Users] Load Users';
export const SET_USERS = '[Users] Set Users';
export const ADD_USER = '[Users] Add User';
export const UPDATE_USER = '[Users] Update User';
export const UPDATE_PASSWORD = '[Users] Update Password';
export const DELETE_USER = '[Users] Delete User';
export const HTTP_FAIL = '[Users] Http Fail';

export class LoadUsers implements Action {
    readonly type = LOAD_USERS;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        addedOrUpdatedUser?: UserEntryModel
    }) {
    }
}

export class SetUsers implements Action {
    readonly type = SET_USERS;

    constructor(public payload: {
        users: UserEntryModel[],
        crud: CRUD,
        totalElements: number,
        lastPage: number,
        addedOrUpdatedUser: UserEntryModel
    }) {
    }
}

export class AddUser implements Action {
    readonly type = ADD_USER;

    constructor(public payload: { user: UserEntryModel, size: number, page: number, order: Order, column: string }) {
    }
}

export class UpdateUser implements Action {
    readonly type = UPDATE_USER;

    constructor(public payload: { user: UserEntryModel, size: number, page: number, order: Order, column: string }) {
    }
}

export class UpdatePassword implements Action {
    readonly type = UPDATE_PASSWORD;

    constructor(public payload: UserEntryModel) {
    }
}

export class DeleteUser implements Action {
    readonly type = DELETE_USER;

    constructor(public payload: { userId: number, size: number, page: number, order: Order, column: string }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type UsersActions =
    LoadUsers
    | SetUsers
    | AddUser
    | UpdateUser
    | UpdatePassword
    | DeleteUser
    | HttpFail;
