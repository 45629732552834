import {CRUD} from '../../../../shared/services/http/crud';
import * as fromOccupationActions from './occupation.actions';
import {OccupationEntryModel} from '../../../../shared/models/occupation-entry.model';


export interface State {
    occupationEntryModel: OccupationEntryModel;
}

const initialState: State = {
    occupationEntryModel: null,
};

export function occupationReducer(state = initialState, action: fromOccupationActions.OccupationActions): State {
    switch (action.type) {
        case fromOccupationActions.SET_OCCUPATION:
            return {
                ...state,
                occupationEntryModel: state.occupationEntryModel
            };
        case fromOccupationActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
