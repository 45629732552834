import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {FormerTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/former-veterinary-treatment-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import * as fromFormerTreatmentsActions from './former-treatments.actions';
import {Order} from '../../../shared/controls/data-table/ordering';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class FormerTreatmentsEffects {
    @Effect()
    loadFormerTreatments$ = this.actions$.pipe(
        ofType(fromFormerTreatmentsActions.LOAD_FORMER_TREATMENTS),
        switchMap((formerTreatmentState: fromFormerTreatmentsActions.LoadFormerTreatments) => {
            const page = formerTreatmentState.payload.page;
            const size = formerTreatmentState.payload.size;
            const order = formerTreatmentState.payload.order;
            const orderColumn = formerTreatmentState.payload.column;
            const formerTreatmentFilter = formerTreatmentState.payload.filter;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (formerTreatmentFilter) {
                params = params.append('filter', 'true');

                params = typeof formerTreatmentFilter.animalId !== 'undefined' && formerTreatmentFilter.animalId !== '' ?
                    params.append('animal_id', formerTreatmentFilter.animalId) : params;
                params = typeof formerTreatmentFilter.from !== 'undefined' && formerTreatmentFilter.from !== '' ?
                    params.append('start_date', formerTreatmentFilter.from) : params;
                params = typeof formerTreatmentFilter.until !== 'undefined' && formerTreatmentFilter.until !== '' ?
                    params.append('end_date', formerTreatmentFilter.until) : params;
            }

            return this.endpointService.formerTreatments(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params});
                    }),
                    map(response => {
                        const mappedFormerTreatmentArr: FormerTreatmentEntryModel[] = response.data.data.map(formerTreatment => {
                            const mappedFormerTreatment: FormerTreatmentEntryModel = {
                                id: formerTreatment.id,
                                description: formerTreatment.treatment,
                                date: formerTreatment.date_performed,
                                invoiceText: formerTreatment.billing_text,
                                price: formerTreatment.price,
                                report: formerTreatment.report,
                                animalId: formerTreatment.animal_id
                            };
                            return mappedFormerTreatment;
                        });

                        return new fromFormerTreatmentsActions.SetFormerTreatments({
                            formerTreatments: mappedFormerTreatmentArr,
                            crud: formerTreatmentState.payload.operation
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    addFormerTreatment$ = this.actions$.pipe(
        ofType(fromFormerTreatmentsActions.ADD_FORMER_TREATMENT),
        switchMap((formerTreatmentState: fromFormerTreatmentsActions.AddFormerTreatment) => {
            return this.endpointService.formerTreatments(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                treatment: formerTreatmentState.payload.newFormerTreatment.description,
                                date_performed: formerTreatmentState.payload.newFormerTreatment.date,
                                billing_text: formerTreatmentState.payload.newFormerTreatment.invoiceText,
                                price: formerTreatmentState.payload.newFormerTreatment.price,
                                report: formerTreatmentState.payload.newFormerTreatment.report,
                                animal_id: formerTreatmentState.payload.newFormerTreatment.animalId
                            }
                        ).pipe(
                            map(() => {
                                return new fromFormerTreatmentsActions.LoadFormerTreatments({
                                    operation: CRUD.CREATE,
                                    size: formerTreatmentState.payload.size,
                                    page: formerTreatmentState.payload.page,
                                    order: formerTreatmentState.payload.order,
                                    column: formerTreatmentState.payload.column,
                                    filter: formerTreatmentState.payload.filter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateFormerTreatment$ = this.actions$.pipe(
        ofType(fromFormerTreatmentsActions.UPDATE_FORMER_TREATMENT),
        switchMap((formerTreatmentState: fromFormerTreatmentsActions.UpdateFormerTreatment) => {
            return this.endpointService.formerTreatments(HttpMethod.PUT, formerTreatmentState.payload.updatedFormerTreatment.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                id: formerTreatmentState.payload.updatedFormerTreatment.id,
                                date_performed: formerTreatmentState.payload.updatedFormerTreatment.date,
                                description: formerTreatmentState.payload.updatedFormerTreatment.description,
                                billing_text: formerTreatmentState.payload.updatedFormerTreatment.invoiceText,
                                price: formerTreatmentState.payload.updatedFormerTreatment.price,
                                report: formerTreatmentState.payload.updatedFormerTreatment.report,
                                animal_id: formerTreatmentState.payload.updatedFormerTreatment.animalId
                            }
                        ).pipe(
                            map(() => {
                                return new fromFormerTreatmentsActions.LoadFormerTreatments({
                                    operation: CRUD.UPDATE,
                                    size: -1,
                                    page: 1,
                                    order: Order.DESC,
                                    column: 'date_performed',
                                    filter: formerTreatmentState.payload.filter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteFormerTreatment$ = this.actions$.pipe(
        ofType(fromFormerTreatmentsActions.DELETE_FORMER_TREATMENT),
        switchMap((treatmentState: fromFormerTreatmentsActions.DeleteFormerTreatment) => {
            return this.endpointService.formerTreatments(HttpMethod.DELETE, treatmentState.payload.formerTreatmentId).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromFormerTreatmentsActions.LoadFormerTreatments({
                                    size: -1,
                                    page: 1,
                                    order: Order.DESC,
                                    column: 'date_performed',
                                    operation: CRUD.DELETE,
                                    filter: treatmentState.payload.filter
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromFormerTreatmentsActions.HttpFail> => {
        return handleHTTPError(errorRes, fromFormerTreatmentsActions, 'durchgeführten Maßnahmen', this.loggingService);
    };
}
