import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import * as fromCategoriesActions from '../categories/catgories.actions';
import {CRUD} from '../../../shared/services/http/crud';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class CategoriesEffects {
    @Effect()
    loadCategories$ = this.actions$.pipe(
        ofType(fromCategoriesActions.LOAD_CATEGORIES),
        switchMap((categoriesState: fromCategoriesActions.LoadCategories) => {
            return this.endpointService.categories(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint).pipe();
                }),
                map(categories => {
                    return new fromCategoriesActions.SetCategories({
                        categories: categories.data,
                        operation: categoriesState.payload
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addCategory$ = this.actions$.pipe(
        ofType(fromCategoriesActions.ADD_CATEGORY),
        switchMap((categoryState: fromCategoriesActions.AddCategory) => {
            return this.endpointService.categories(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: categoryState.payload.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromCategoriesActions.LoadCategories(CRUD.CREATE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateCategory$ = this.actions$.pipe(
        ofType(fromCategoriesActions.UPDATE_CATEGORY),
        switchMap((categoryState: fromCategoriesActions.UpdateCategory) => {
            return this.endpointService.categories(HttpMethod.PUT, categoryState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                name: categoryState.payload.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromCategoriesActions.LoadCategories(CRUD.UPDATE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteCategory$ = this.actions$.pipe(
        ofType(fromCategoriesActions.DELETE_CATEGORY),
        switchMap((categoryState: fromCategoriesActions.DeleteCategory) => {
            return this.endpointService.categories(HttpMethod.DELETE, categoryState.payload).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromCategoriesActions.LoadCategories(CRUD.DELETE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );


    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }


    private handleError = (errorRes: any): Observable<fromCategoriesActions.HttpFail> => {
        return handleHTTPError(errorRes, fromCategoriesActions, 'Kategorien', this.loggingService);
    };

}
