<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1 style="margin-bottom: 15px !important;">Tierabgabevertrag</h1>
    <span class="d-inline-block">abgeschlossen zwischen dem <br />
    <span class="font-weight-bolder">OÖ Landestierschutzverein <br />
        {{shelter === Shelter.LINZ ? Constants.LinzShelterName : Constants.SteyrShelterName }}<br />
        {{shelter === Shelter.LINZ ? Constants.LinzAddress : Constants.SteyrAddress }} <br />
        {{shelter === Shelter.LINZ ? Constants.LinzZipAndCity : Constants.SteyrZipAndCity }} </span> <br />
   einerseits und
</span>

    <div class="container d-flex justify-content-around mt-5" *ngIf="person !== null && person !== undefined">
        <div class="left-col text-left">
            <div class="font-weight-bolder">{{person.firstName + ' ' + person.lastName}}</div>
            <div>{{person.street}}</div>
            <div>{{person.zip}} - {{person.city}}</div>
            <div>Tel.: {{person.phone}}</div>
        </div>

        <div class="right-col text-left">
            <div>PNr.: {{person.id}}</div>
            <div>Geb. Datum: {{person.birthday | date: 'dd.MM.yyyy'}}</div>
            <div>Nachweis: {{person.proof}}</div>
            <div>E-Mail: {{person.email}}</div>
        </div>
    </div>

    <div class="mt-3">als bisheriger Tierhalter andererseits.</div>

    <div class="mt-3 mb-2">Folgende(s) Tiere(e) wird/werden aufgrund dieses Vertrages an den OÖ Landestierschutzverein
        übergeben:
    </div>

    <div *ngIf="animals !== null && animals !== undefined" style="overflow:visible !important;" class="mt-3">
        <ng-container *ngFor="let animal of animals">
            <div class="entry d-flex flex-wrap">
                <div class="left-col text-left">
                    <div class="font-weight-bolder">LfNr.: {{animal.id}}</div>
                    <div>Name: {{animal.name}}</div>
                    <div>Tierart: {{animal.species.name}}</div>
                    <div>Geschlecht: {{Gender.getGender(animal.gender)}}</div>
                    <div>Beschreibung: {{characteristicsService.printCharacteristics(animal.characteristics)}}</div>
                </div>

                <div class="right-col text-left">
                    <div class="font-weight-bolder">FundtierNr:
                        <span style="width:60px; border-bottom:2px solid #000; display: inline-block">{{animal.fundtierId || ''}}</span>
                    </div>
                    <div>Chip-Nr.: {{animal.chipNumber}}</div>
                    <div>Rasse: {{animal.race.name}}</div>
                    <div>Kastriert: {{CastrationStatus.getCastrationStatus(animal.castrated)}}</div>
                    <div>Alter: {{Age.printAge(animal.birthday)}}</div>
                </div>
                <div class="horLine w-100 pb-4"></div>
            </div>
        </ng-container>
    </div>

    <div class="text text-left h-100" style="margin-top: 50px">
        <ol>
            <li>Mit seiner Unterschrift erklärt der Tierhalter, dass das/die von ihm übergebene(n) Tier(e) sein
                unbestrittenes Eigentum ist(sind).
            </li>
            <li>Mit Abschluss dieses Vertrages geht das/die vorbezeichnete(n) Tier(e) in das Eigentum des OÖ
                Landestierschutzvereins über. Anspruch auf Rückgabe besteht nicht mehr.
            </li>
            <li>Der OÖ Landestierschutzverein kann das übereignete Tier weitervermitteln. Name und Adresse des neuen
                Halters werden aus Datenschutzgründen nicht weitergegeben.
            </li>
            <li>Der Tierhalter bestätigt mit seiner Unterschrift, dass die unter dem Punkt Eigenschaften gemachten
                Angaben über das Tier vollständig sind und der Wahrheit entsprechen.
            </li>
        </ol>


        <section class="disclaimer small mt-0 text-left">
            Information zum Datenschutz: <br />
            Name, Adress- und Kontaktdaten der überbringenden Peron und die Chipnummer des Tieres werden zum Zweck der
            Administration und Dokumentation der Tiere gespeichert. Die Daten werden nicht weitergegeben und mindestens
            drei Jahre – sofern in §21 Tierschutzgesetz nicht anders bestimmt – lang aufbewahrt. Im Rahmen der
            fachlichen Aufsicht dürfen diese Daten von der zuständigen Bezirksverwaltungsbehörde eingesehen werden. Die
            Chipnummer des Tieres kann vom Amt der OÖ Landesregierung, Abteilung Gesundheit eingesehen werden.
        </section>

        <div class="d-flex justify-content-around mt-0 align-items-end">
            <div class="left">
                <div class="mt-4 mb-5">{{shelter === Shelter.LINZ ? Constants.LinzCityName : Constants.SteyrCityName}},
                    am {{data.date | date:'dd.MM.yyyy'}}</div>
            </div>

            <div class="right text-center">
                <div class="mt-3 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                <div>Unterschrift</div>
            </div>
        </div>
    </div>
</div>
