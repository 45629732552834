<div [ngClass]="!visible ? 'not-printable' : ''">
    <h1>Tierabnahme</h1>

    <section *ngIf="animals !== null && animals !== undefined" style="overflow:visible !important;" class="mt-4">
        <ng-container *ngFor="let animal of animals">
            <div class="entry d-flex flex-wrap">
                <div class="left-col text-left">
                    <div class="font-weight-bolder">LfNr.: {{animal.id}}</div>
                    <div>Name: {{animal.name}}</div>
                    <div>Tierart: {{animal.species.name}}</div>
                    <div>Geschlecht: {{Gender.getGender(animal.gender)}}</div>
                    <div>Beschreibung: {{characteristicsService.printCharacteristics(animal.characteristics)}}</div>
                </div>

                <div class="right-col text-left">
                    <div class="font-weight-bolder">FundtierNr:
                        <span style="width:60px; border-bottom:2px solid #000; display: inline-block">{{animal.fundtierId || ''}}</span>
                    </div>
                    <div>Chip-Nr.: {{animal.chipNumber}}</div>
                    <div>Rasse: {{animal.race.name}}</div>
                    <div>Kastriert: {{CastrationStatus.getCastrationStatus(animal.castrated)}}</div>
                    <div>Alter: {{Age.printAge(animal.birthday)}}</div>
                </div>
                <div class="horLine w-100 pb-4"></div>
            </div>
        </ng-container>
    </section>

    <section class="mt-3 text-left">
        <div class="font-weight-bolder">Ehemaliger Eigentümer:</div>
    </section>

    <section class="container d-flex justify-content-around mt-5" *ngIf="person !== null && person !== undefined">
        <div class="left-col text-left">
            <div class="font-weight-bolder">{{person.firstName + ' ' + person.lastName}}</div>
            <div>{{person.street}}</div>
            <div>{{person.zip}} - {{person.city}}</div>
            <div>Tel.: {{person.phone}}</div>
        </div>

        <div class="right-col text-left">
            <div>PNr.: {{person.id}}</div>
            <div>Geb. Datum: {{person.birthday | date: 'dd.MM.yyyy'}}</div>
            <div>Nachweis: {{person.proof}}</div>
            <div>E-Mail: {{person.email}}</div>
        </div>
    </section>

    <section class="mt-5 text-left">
        <div>Abnahmegrund: {{data.takingOverReason}}</div>
    </section>

    <div class="text text-left h-100" style="margin-top: 100px">
        <section class="disclaimer small mt-4 text-left">
            Information zum Datenschutz: <br />
            Name, Adress- und Kontaktdaten der überbringenden Peron und die Chipnummer des Tieres werden zum Zweck der
            Administration und Dokumentation der Tiere gespeichert. Die Daten werden nicht weitergegeben und mindestens
            drei Jahre – sofern in §21 Tierschutzgesetz nicht anders bestimmt – lang aufbewahrt. Im Rahmen der
            fachlichen Aufsicht dürfen diese Daten von der zuständigen Bezirksverwaltungsbehörde eingesehen werden. Die
            Chipnummer des Tieres kann vom Amt der OÖ Landesregierung, Abteilung Gesundheit eingesehen werden.
        </section>

        <div class="d-flex justify-content-around mt-4 align-items-end">
            <div class="left">
                <div class="mt-5 mb-4">{{cityName}}, am {{data.date | date:'dd.MM.yyyy'}}</div>
            </div>

            <div class="right text-center">
                <div class="mt-4 d-inline-block" style="width: 400px; height: 2px; background: #000"></div>
                <div>Unterschrift</div>
            </div>
        </div>
    </div>
</div>
