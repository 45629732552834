import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import * as fromAttributeActions from './attributes.actions';
import {LoadAttributes} from './attributes.actions';
import {AttributeEntryModel} from '../../../shared/models/attributes/attribute-entry.model';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class AttributesEffects {
    @Effect()
    loadattributes$ = this.actions$.pipe(
        ofType(fromAttributeActions.LOAD_ATTRIBUTES),
        switchMap((loadAttributes: LoadAttributes) => {
            let params = new HttpParams();
            params = params.append('species_group_name', loadAttributes.payload);

            return this.endpointService.attributes(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params}).pipe(
                            map(response => response.data)
                        );
                })
            );
        }),
        map(attributes => {
            const mappedAttributes: AttributeEntryModel[] = attributes.map(attribute => {
                const mappedAttribute: AttributeEntryModel = {
                    id: attribute.id,
                    name: attribute.name,
                    datasheetVisibility: attribute.datasheet_visibility,
                    speciesGroupName: attribute.species_group_name,
                    options: attribute.values
                };
                return mappedAttribute;
            });
            return new fromAttributeActions.SetAttributes(mappedAttributes);
        }),
        catchError(error => {
            return this.handleError(error);
        })
    );

    @Effect()
    addAttribute$ = this.actions$.pipe(
        ofType(fromAttributeActions.ADD_ATTRIBUTE),
        switchMap((attributeState: fromAttributeActions.AddAttributes) => {
            return this.endpointService.attributes(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: attributeState.payload.name,
                                values: [{name: attributeState.payload.options[0].name}],
                                species_group_name: attributeState.payload.speciesGroupName
                            }
                        ).pipe(
                            map(() => {
                                return new fromAttributeActions.LoadAttributes(attributeState.payload.speciesGroupName);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateAttribute$ = this.actions$.pipe(
        ofType(fromAttributeActions.UPDATE_ATTRIBUTE),
        switchMap((attributeState: fromAttributeActions.UpdateAttribute) => {
            return this.endpointService.attributes(HttpMethod.PUT, attributeState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                name: attributeState.payload.name,
                                datasheet_visibility: attributeState.payload.datasheetVisibility
                            }
                        ).pipe(
                            map(() => {
                                return new fromAttributeActions.LoadAttributes(attributeState.payload.speciesGroupName);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteAttribute$ = this.actions$.pipe(
        ofType(fromAttributeActions.DELETE_ATTRIBUTE),
        switchMap((attributeState: fromAttributeActions.DeleteAttribute) => {
            return this.endpointService.attributes(HttpMethod.DELETE, attributeState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromAttributeActions.LoadAttributes(attributeState.payload.speciesGroupName);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );


    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }


    private handleError = (errorRes: any): Observable<fromAttributeActions.HttpFail> => {
        return handleHTTPError(errorRes, fromAttributeActions, 'Attributen', this.loggingService);
    };

}
