<div class="quick-edit">
    <form class="form"
          [formGroup]="quickForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput
                           placeholder="Name"
                           formControlName="name">
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerBirthday.open()"
                           [ngxMatDatetimePicker]="datePickerBirthday"
                           placeholder="Geburtsdatum"
                           formControlName="birthday"
                           (keydown.enter)="updateAnimal()"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerBirthday"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerBirthday
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Heim</mat-label>
                    <mat-select formControlName="shelter"
                                (keydown.enter)="updateAnimal()">
                        <mat-option *ngFor="let shelter of shelterStatusOptions"
                                    [value]="shelter.value">
                            {{shelter.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Geschlecht</mat-label>
                    <mat-select formControlName="gender"
                                (keydown.enter)="updateAnimal()">
                        <mat-option *ngFor="let gender of genderOptions"
                                    [value]="gender.value">
                            {{gender.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <mat-form-field appearance="fill">
                    <mat-label>Kastration</mat-label>
                    <mat-select formControlName="castration"
                                (keydown.enter)="updateAnimal()">
                        <mat-option *ngFor="let castration of castrationStatusOptions"
                                    [value]="castration.value">
                            {{castration.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field class="chip-list">
                    <mat-chip-list
                        #chipList
                        aria-label="Beschreibung">
                        <mat-chip
                                *ngFor="let characteristic of chipBar.getSelectedValues()"
                                [selectable]="chipBar.selectable"
                                [removable]="chipBar.removable"
                                (removed)="chipBar.removeChip(characteristic.name)">
                            {{characteristic.name}}
                            <mat-icon matChipRemove *ngIf="chipBar.removable">cancel</mat-icon>

                        </mat-chip>
                        <input
                                placeholder="Beschreibung"
                                #characteristicInput
                                (click)="chipBar.focusCharacteristicsInput()"
                                (ngModelChange)="chipBar.filterCharacteristics($event)"
                                [formControl]="chipBar.characteristicsCtrl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList">
                    </mat-chip-list>
                    <mat-autocomplete #auto (optionSelected)="chipBar.selected($event, characteristicInput)">
                        <mat-option
                                *ngFor="let characteristic of chipBar.filteredCharacteristics | async"
                                [value]="characteristic">
                            <div class="option-wrapper d-flex justify-content-between align-items-center">
                                <span class="text-element">{{characteristic.viewValue}}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-left">
                <mat-checkbox formControlName="hiddenOnWebsite">Nicht auf Website anzeigen <i
                        class="fas fa-eye-slash"></i>
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 text-left" style="margin-top:8px">
                <mat-checkbox
                        formControlName="reserved"
                        #reserved>
                    Reserviert
                </mat-checkbox>
            </div>
            <div class="col-md-4 text-left" *ngIf="reserved.checked">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerReservedAt.open()"
                           [ngxMatDatetimePicker]="datePickerReservedAt"
                           placeholder="Ab"
                           formControlName="reservedUntil"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerReservedAt"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerReservedAt
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div style="margin-top: 1px" class="col-md-5 text-left" *ngIf="reserved.checked">
                <mat-form-field>
                    <mat-label>Für</mat-label>
                    <input matInput
                           placeholder="Für"
                           formControlName="reservedFor">
                </mat-form-field>
            </div>
        </div>

        <div class="row" style="min-height: 87px">
            <div class="col-md-3 text-left" style="margin-top:8px">
                <mat-checkbox
                    #noGivingAway
                    formControlName="noGivingAway">Nicht zur Vergabe <i class="fas fa-user-slash"></i>
                </mat-checkbox>
            </div>
            <div class="col-md-9 text-left" *ngIf="noGivingAway.checked">
                <mat-form-field>
                    <mat-label>Grund</mat-label>
                    <input matInput
                           placeholder="Grund"
                           formControlName="noGivingAwayReason">
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-5 mb-5"
             *ngIf="animalEntry.careSpace !== null && animalEntry.careSpace.dateBackToShelter === null">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="text-left"><i class="fas fa-user-nurse mr-2"></i>Tier hat einen Pflegeplatz</h5>
                    </div>
                </div>
                <form [formGroup]="careSpaceForm">
                    <div class="row mt-2">
                        <div class="col-md-3">
                            <mat-form-field class="text-input">
                                <mat-label>Name des Pflegers</mat-label>
                                <input matInput
                                       placeholder="Name des Pflegers"
                                       readonly
                                       formControlName="careTakerName">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="text-input">
                                <mat-label>Adresse des Pflegers</mat-label>
                                <input matInput
                                       placeholder="Adresse des Pflegers"
                                       readonly
                                       formControlName="careTakerAddress">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="text-input">
                                <mat-label>Telefonnummer des Pflegers</mat-label>
                                <input matInput
                                       placeholder="Telefonnummer des Pflegers"
                                       readonly
                                       formControlName="careTakerPhone">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <button class="float-right mr-3 mt-4"
                                    mat-raised-button color="primary"
                                    (click)="acquireAnimal()">
                                Zurück ins Tierheim
                            </button>
                            <mat-icon [hidden]="!careSpaceRequest" class="mr-2 float-right"
                                      style="margin-top: 33px">
                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                            </mat-icon>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-6">
                <div class="mr-3 d-flex align-items-center"
                     *ngIf="animalEntry.type.name === ActionType.FINDLING.key">
                    <button mat-raised-button color="primary"
                            (click)="compareWithMissingAnimals()">
                        Vermisste Tiere abgleichen
                    </button>
                    <div id="last-check" class="ml-3" *ngIf="animalEntry.lastMissingCheck">
                        Zuletzt abgeglichen am <span>{{animalEntry.lastMissingCheck | date:'dd.MM.yyyy'}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-right">
                    <mat-icon [hidden]="!(updateRequestSent && quickForm.valid)"
                              class="mr-2"
                              style="padding-top: 9px">
                        <mat-spinner color="accent" diameter="20"></mat-spinner>
                    </mat-icon>
                    <button mat-raised-button color="primary"
                            (click)="updateAnimal()"
                            [disabled]="updateRequestSent && quickForm.valid">
                        <i class="fas fa-exclamation-circle mr-2"
                           style="color: #b71c1c"
                           *ngIf="displayErroneousInputs">
                        </i>
                        Aktualisieren
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>
