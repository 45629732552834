<div class="edit-user">
    <form class="form container-fluid"
          [formGroup]="userEditForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>E-Mail</mat-label>
                    <input matInput
                           placeholder="E-Mail"
                           formControlName="email">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Vorname</mat-label>
                    <input matInput
                           placeholder="Vorname"
                           formControlName="firstName">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Nachname</mat-label>
                    <input matInput
                           placeholder="Nachname"
                           formControlName="lastName">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button class="float-right mt-4"
                        mat-raised-button
                        color="primary"
                        [disabled]="updateRequestSent && userEditForm.valid"
                        (click)="updateUser()">
                    <i class="fas fa-exclamation-circle mr-2"
                       style="color: #b71c1c"
                       *ngIf="displayErroneousInputs">
                    </i>
                    Aktualisieren
                </button>
                <mat-icon [hidden]="!(updateRequestSent && userEditForm.valid)" class="mr-2 float-right"
                          style="margin-top: 32px">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </div>
        </div>

    </form>
</div>

