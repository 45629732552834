<div class="title-bar  position-relative">
    <h3 mat-dialog-title>
        Media Center
    </h3>
    <div *ngIf="mode === Mode.CHANGE_IMAGE"
         class="fa fa-close float-right mt-1 position-absolute"
         mat-button
         [mat-dialog-close]="false"
         aria-label="close-dialog"></div>
</div>

<mat-dialog-content [ngClass]="{'max-height-100': mode === Mode.LOAD_IMAGES}">
    <div class="container-fluid main-card-wrapper">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header" *ngIf="mode === Mode.LOAD_IMAGES">
                        <h4 class="card-title">Medien</h4>
                    </div>
                    <div class="card-body" #card [ngClass]="{'p-0': mode === Mode.CHANGE_IMAGE}">
                        <div id="top-controls" class="d-flex justify-content-between">
                            <div id="show-entries-container">
                                Zeige
                                <select name="entries"
                                        id="entries"
                                        class="mr-1 ml-1"
                                        (ngModelChange)="changeEntriesPerPage($event)"
                                        [(ngModel)]="entriesPerPage">
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                </select>
                                Einträge
                            </div>
                            <div id="add-new-media"
                                 [ngStyle]="{'margin-right' : mode === Mode.CHANGE_IMAGE ? '25px' : '5px'}">
                                <button class="float-right" mat-raised-button color="primary" (click)="uploadImage()">
                                    Bild hinzufügen
                                </button>
                            </div>
                        </div>

                        <div id="media-container" class="mt-5">
                            <ng-container *ngFor="let media of media$ | async">
                                <app-media-thumb [media]="media"
                                                 [mode]="mode"
                                                 [entriesPerPage]="entriesPerPage"
                                                 [pageIndex]="pageIndex"
                                                 [ngClass]="{'active': selectedItemIds.indexOf(media.id) >= 0}"
                                                 (click)="thumbClicked(media.id)"></app-media-thumb>
                            </ng-container>
                        </div>

                        <div class="default-entry mt-2" *ngIf="totalMedia === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline">
                            <div id="total-results">Gesamt: <span>{{totalMedia}}</span></div>
                            <mat-paginator [length]="totalMedia"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>


                        <div class="row mt-5 pb-1" *ngIf="mode === Mode.CHANGE_IMAGE">
                            <div class="col">
                                <button mat-raised-button color="warn" [mat-dialog-close]="false">Abbrechen</button>
                                <button class="float-right" mat-raised-button color="primary"
                                        [mat-dialog-close]="selectedItemIds">
                                    Einfügen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4" [ngClass]="mode === Mode.CHANGE_IMAGE ? 'pl-5' : ''">
                <div class="card filter-card" [ngClass]="mode === Mode.CHANGE_IMAGE ? 'change' : 'edit'">
                    <div class="card-header">
                        <h2 class="card-title">Filter</h2>
                    </div>
                    <div class="card-body">
                        <app-media-filter [mode]="mode"></app-media-filter>
                    </div>
                </div>
            </div>

        </div>
    </div>
</mat-dialog-content>
