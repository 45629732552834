import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService} from '../../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import {LoggingService} from '../../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../../shared/error-handling';
import * as fromOccupationActions from './occupation.actions';
import {OccupationEntryModel} from '../../../../shared/models/occupation-entry.model';

@Injectable()
export class OccupationsEffects {
    @Effect()
    loadOccupations$ = this.actions$.pipe(
        ofType(fromOccupationActions.LOAD_OCCUPATION),
        switchMap((occupationsState: fromOccupationActions.LoadOccupation) => {
            const from = occupationsState.payload.from;
            const to = occupationsState.payload.to;

            if (!from || !to) {
                throw new Error('Date not set.');
            }

            let params = new HttpParams();
            params = params.append('start_date', from.toString());
            params = params.append('end_date', to.toString());

            return this.endpointService.occupation().pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint, {params}).pipe(
                            map(response => response.data)
                        );
                }),
                map(response => {
                    const occupationEntryModel: OccupationEntryModel = {
                        fileContent: response.file_content,
                        dogs: response.Hund,
                        cats: response.Katze,
                        smallAnimals: response.Kleintier,

                    };
                    return new fromOccupationActions.SetOccupation({
                        occupationEntryModel
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromOccupationActions.HttpFail> => {
        return handleHTTPError(errorRes, fromOccupationActions, 'vermissten Tieren', this.loggingService);
    };

}
