import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {Observable, Subscription} from 'rxjs';
import {TupleHelper} from '../../shared/data-types/tuple';
import {ActionTypesService} from '../../animals/store/action-types/action-types.service';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActionsService} from '../actions.service';
import {AnimalsService} from '../../animals/animals.service';
import {SelectSearch} from '../../shared/services/select-box/select-search';
import {SpeciesService} from '../../animals/store/species/species.service';

@Component({
    selector: 'app-actions-filter',
    templateUrl: './actions-filter.component.html',
    styleUrls: ['./actions-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class ActionsFilterComponent implements OnInit, OnDestroy {
    @ViewChild('date') public date: any;
    @ViewChild('actionTypeSelect', {static: true}) public actionTypeSelect: MatSelect;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;

    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public defaultSelectClass = 'any';
    public actionTypes$: Observable<DropdownOption[]>;
    public TupleHelper = TupleHelper;
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public selectSearchSpecies = new SelectSearch();

    private inputIndicator: HTMLDivElement;
    private selectedSymbol: string;
    private selectBoxSub: Subscription;

    constructor(private actionTypesService: ActionTypesService,
                private formBuilder: FormBuilder,
                private actionsService: ActionsService,
                private animalsService: AnimalsService,
                private speciesService: SpeciesService,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.actionTypesService.fetchedActionsEvent
            .subscribe(actionTypes => {
                if (actionTypes !== null) {
                    this.actionTypes$ = this.actionTypesService.populateActionTypeSelectBox(actionTypes);

                    const natEl = this.actionTypeSelect._elementRef.nativeElement.children[0];
                    this.inputIndicator = this.renderer.createElement('div');
                    this.renderer.addClass(this.inputIndicator, 'animal-type');
                    this.renderer.addClass(this.inputIndicator, 'input');
                    this.renderer.addClass(this.inputIndicator, 'fas');
                    this.renderer.appendChild(natEl, this.inputIndicator);
                }
            });

        this.initForm();
        this.selectBoxSub = this.speciesService.fetchSpecies()
            .subscribe((species) => {
                this.selectSearchSpecies.setValues(species);
            });
    }

    ngOnDestroy(): void {
        if (this.selectBoxSub) {
            this.selectBoxSub.unsubscribe();
        }

        this.selectSearchSpecies.destroy();
    }

    public selChange(): void {
        this.renderer.removeClass(this.inputIndicator, this.selectedSymbol);

        const selectedOption = (this.actionTypeSelect.selected as MatOption);
        const value = (selectedOption.value) as DropdownOption;
        const color = TupleHelper.getValue(value.meta, 'color');
        this.selectedSymbol = TupleHelper.getValue(value.meta, 'symbol');

        this.renderer.setStyle(this.inputIndicator, 'background-color', color);
        this.renderer.addClass(this.inputIndicator, this.selectedSymbol);
    }

    public reset(): void {
        this.filterForm.reset();
    }

    public filter(): void {
        if (!this.filterForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        this.displayErroneousInputs = false;

        const value = this.filterForm.value;
        const filterModel = {
            transactionId: value.transactionId ? value.transactionId : '',
            dateFrom: value.date ? value.date.format('YYYY-MM-DD') : '',
            dateTo: value.date ? value.date.format('YYYY-MM-DD') : '',
            animalId: value.animalId ? value.animalId : '',
            animalName: value.animalName ? value.animalName : '',
            actionType: value.actionType ? value.actionType.value : '',
            species: value.species ? value.species.viewValue : '',
            personId: value.personId ? value.personId : '',
            lastName: value.personName ? value.personName : ''
        };

        this.actionsService.filterEvent.next(filterModel);
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            transactionId: '',
            date: [''],
            animalId: ['', [Validators.maxLength(50), Validators.pattern('^[0-9]*$')]],
            animalName: ['', Validators.maxLength(50)],
            actionType: ['', Validators.maxLength(50)],
            species: ['', Validators.maxLength(50)],
            personId: ['', [Validators.maxLength(50), Validators.pattern('^[0-9]*$')]],
            personName: ['', Validators.maxLength(50)]
        });
    }
}
