/* tslint:disable:import-spacing */
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DateTimeConfig} from '../../../shared/controls/date-time-picker/DateTimeConfig';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../../shared/controls/date-time-picker/CustomFormat';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Actions, ofType} from '@ngrx/effects';
import {Subscription} from 'rxjs';
import {ActionsService} from '../../../actions/actions.service';
import {AnimalsService} from '../../animals.service';
import {ActionType} from '../../store/action-types/action-types.service';
import {Order} from '../../../shared/controls/data-table/ordering';
import {PersonSelect} from '../../../shared/controls/person-select';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {take} from 'rxjs/operators';
import {RueckgabePensionstierService} from './rueckgabe-pensionstier.service';
import {NumberConverter} from '../../../shared/converter/number-converter';
import {VeterinaryTreatmentsService} from '../../../veterinary-treatments/veterinary-treatments.service';
import * as fromFormerTreatmentsActions from '../../../veterinary-treatments/store/former-treatments/former-treatments.actions';
import {CRUD} from '../../../shared/services/http/crud';

@Component({
    selector: 'app-rueckgabe-pensionstier',
    templateUrl: './rueckgabe-pensionstier.component.html',
    styleUrls: ['./rueckgabe-pensionstier.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class RueckgabePensionstierComponent implements OnInit, OnDestroy {
    @Input() public acquisitionGiveAwayForm: FormGroup;
    @Input() public personSelectSearch: PersonSelect;
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('datePickerTo') public datePickerTo: any;
    public totalCosts = 0;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();

    private transactionSub: Subscription;
    private animalsChangedSub: Subscription;
    private animalSelectedSub: Subscription;
    private fetchedAnnahmeActionSub: Subscription;
    private formerTreatmentsSub: Subscription;
    private costsInfo: RueckgabePensionstierCostInfo[] = [];
    private selectedIndex = -1;
    private deposit = 0;
    private animalAdded = false;

    constructor(private actions$: Actions,
                private rueckgabePensionstierService: RueckgabePensionstierService,
                private animalsService: AnimalsService,
                private veterinaryTreatmentsService: VeterinaryTreatmentsService,
                private actionsService: ActionsService) {
    }

    ngOnInit(): void {
        this.acquisitionGiveAwayForm.addControl('costsPerDayTotal', new FormControl(
            {value: '', disabled: true}, Validators.pattern(/^[0-9]+(,[0-9]{1,2})?$/)));

        this.acquisitionGiveAwayForm.addControl('depositTotal', new FormControl('', Validators.pattern(/^[0-9]+(,[0-9]{1,2})?$/)));
        this.acquisitionGiveAwayForm.addControl('additionalCosts', new FormControl({
            value: '',
            disabled: true
        }, Validators.pattern(/^[0-9]+(,[0-9]{1,2})?$/)));
        this.acquisitionGiveAwayForm.addControl('stayFrom', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('stayUntil', new FormControl(moment()));

        this.initEvents();
    }

    ngOnDestroy(): void {
        if (this.transactionSub) {
            this.transactionSub.unsubscribe();
        }
        if (this.animalsChangedSub) {
            this.animalsChangedSub.unsubscribe();
        }
        if (this.animalSelectedSub) {
            this.animalSelectedSub.unsubscribe();
        }
        if (this.fetchedAnnahmeActionSub) {
            this.fetchedAnnahmeActionSub.unsubscribe();
        }
    }

    public dateChanged(): void {
        this.acquisitionGiveAwayForm.controls.stayFrom.setErrors(null);
        this.acquisitionGiveAwayForm.controls.stayUntil.setErrors(null);
    }

    public formUpdated(): void {
        const formValue = this.acquisitionGiveAwayForm.value;
        if (this.selectedIndex >= 0) {
            this.costsInfo[this.selectedIndex].additionalCosts = +NumberConverter.toEnglishNumberFormat(formValue.additionalCosts);
            this.costsInfo[this.selectedIndex].costsPerDay = +NumberConverter.toEnglishNumberFormat(formValue.costsPerDayTotal);
            this.rueckgabePensionstierService.costInfoUpdated.next(this.costsInfo);
        }
        this.calculateTotalCosts();
    }

    private initEvents(): void {
        this.animalSelectedSub = this.rueckgabePensionstierService
            .animalInTableSelected
            .subscribe(index => {
                this.selectRow(index);
            });

        this.animalsChangedSub = this.animalsService.animalsInTableChanged
            .subscribe((data: [AnimalEntryModel[], boolean]) => {
                if (data === null) {
                    return;
                }

                const animals = data[0];
                this.animalAdded = data[1];

                if (this.animalAdded) {
                    const addedAnimal = animals.slice().pop();
                    this.fetchAnnahmeAction(addedAnimal, animals);
                } else {
                    this.costsInfo.splice(this.selectedIndex, 1);
                    if (this.costsInfo.length > 0) {
                        this.selectRow(0);
                    } else {
                        this.acquisitionGiveAwayForm.patchValue({
                            costsPerDayTotal: 0,
                            additionalCosts: 0
                        });
                    }
                }

                this.calculateTotalCosts();
            });

        this.rueckgabePensionstierService.formResetted
            .subscribe(resetted => {
                if (resetted) {
                    this.totalCosts = 0;
                    this.costsInfo = [];
                    this.selectedIndex = -1;
                    this.deposit = 0;
                    this.acquisitionGiveAwayForm.patchValue({
                        stayFrom: '',
                        stayUntil: moment(),
                    });
                    this.acquisitionGiveAwayForm.controls.costsPerDayTotal.patchValue('');
                    this.acquisitionGiveAwayForm.controls.additionalCosts.patchValue('');
                    this.acquisitionGiveAwayForm.controls.costsPerDayTotal.disable();
                    this.acquisitionGiveAwayForm.controls.additionalCosts.disable();
                }
            });
    }

    private fetchAnnahmeAction(animal: AnimalEntryModel, currentAnimals: AnimalEntryModel[]): void {
        const actionFilter = {
            latestOnlyActionType: ActionType.ANNAHME_PENSIONSTIER.key,
            animalId: animal.id
        };
        this.fetchedAnnahmeActionSub = this.actionsService.fetchActions(1, 1, Order.NONE, '', actionFilter, false)
            .subscribe(actions => {
                let from = '';
                const to = this.acquisitionGiveAwayForm.controls.stayUntil?.value.format('YYYY-MM-DD');

                if (actions.length > 0) {
                    const annahmeAction = actions.slice().pop();
                    const persons = [annahmeAction.person];
                    if (annahmeAction.owner !== null) {
                        persons.push(annahmeAction.owner);
                    }
                    this.personSelectSearch.createPersonSelectBox(persons);
                    this.rueckgabePensionstierService.personsFetched.next(persons);
                    this.deposit = +annahmeAction.deposit;
                    from = annahmeAction.stayFrom ? moment(annahmeAction.stayFrom).format('YYYY-MM-DD') :
                        this.acquisitionGiveAwayForm.controls.stayFrom?.value.format('YYYY-MM-DD');
                    this.acquisitionGiveAwayForm.patchValue({
                        person: annahmeAction.person,
                        depositTotal: NumberConverter.toGermanNumberFormat(+annahmeAction.deposit),
                        stayFrom: moment(annahmeAction.stayFrom)
                    });
                } else {
                    const stayFromValue = this.acquisitionGiveAwayForm.controls.stayFrom?.value;
                    from = typeof stayFromValue !== 'undefined' && stayFromValue !== '' ?
                        this.acquisitionGiveAwayForm.controls.stayFrom?.value.format('YYYY-MM-DD') : null;
                }

                const currentCostInfoData = this.costsInfo.slice();
                currentAnimals.forEach(a => {
                    if (!currentCostInfoData.find(ci => ci.animalId === a.id)) {
                        const newCostInfo: RueckgabePensionstierCostInfo = {
                            animalId: a.id,
                            additionalCosts: 0,
                            costsPerDay: 0
                        };
                        this.calculateVeterinaryTreatmentsCosts(a.id, from, to);
                        this.costsInfo.push(newCostInfo);
                    }
                });
            });
    }

    private calculateTotalCosts(): void {
        let costsPerDayTotal = 0;
        let additionalCosts = 0;
        let days = 0;
        const stayFromVal = this.acquisitionGiveAwayForm.controls.stayFrom?.value;
        const stayUntilVal = this.acquisitionGiveAwayForm.controls.stayUntil?.value;
        const from = stayFromVal !== null && stayFromVal !== '' ? stayFromVal.startOf('day') : null;
        const until = stayUntilVal !== null && stayUntilVal !== '' ? stayUntilVal.startOf('day') : null;
        if (from && from !== '' && until && until !== '') {
            days = until.diff(from, 'days') + 1;
        }
        this.costsInfo.forEach(entry => {
            costsPerDayTotal += days * +entry.costsPerDay;
            additionalCosts += +entry.additionalCosts;
        });
        this.deposit = +NumberConverter.toEnglishNumberFormat(this.acquisitionGiveAwayForm.controls.depositTotal.value);

        this.totalCosts = costsPerDayTotal + additionalCosts - this.deposit;
    }

    private calculateVeterinaryTreatmentsCosts(animalId: number, stayFrom: string, stayUntil: string): void {
        const from = stayFrom !== null && stayFrom !== '' ? stayFrom : null;
        const until = stayUntil !== null && stayUntil !== '' ? stayUntil : null;

        if (!from || !until) {
            return;
        }

        const filter = {
            animalId,
            from,
            until
        };
        this.formerTreatmentsSub = this.veterinaryTreatmentsService
            .fetchFormerVeterinaryTreatments(1, -1, Order.NONE, '', filter)
            .pipe(take(1))
            .subscribe(formerTreatments => {
                let costs = 0;
                formerTreatments.forEach(treatment => {
                    costs += +treatment.price;
                });
                this.acquisitionGiveAwayForm.patchValue({
                    additionalCosts: NumberConverter.toGermanNumberFormat(costs)
                });

                this.costsInfo.find(animal => animal.animalId === animalId).additionalCosts = costs;
            });

        this.actions$.pipe(
            ofType(fromFormerTreatmentsActions.SET_FORMER_TREATMENTS)
        ).subscribe((veterinaryTreatmentState: fromFormerTreatmentsActions.SetFormerTreatments) => {
            const operation = veterinaryTreatmentState.payload.crud;
            if (operation === CRUD.CREATE || operation === CRUD.UPDATE || operation === CRUD.DELETE) {
                let costs = 0;
                const stayFromVal = this.acquisitionGiveAwayForm.controls.stayFrom?.value;
                const stayUntilVal = this.acquisitionGiveAwayForm.controls.stayUntil?.value;
                veterinaryTreatmentState.payload.formerTreatments.forEach(treatment => {
                    if (stayFromVal && stayUntilVal && moment(treatment.date).isBetween(stayFromVal, stayUntilVal, 'days', '[]')) {
                        costs += +treatment.price;
                    }
                });
                this.acquisitionGiveAwayForm.patchValue({
                    additionalCosts: NumberConverter.toGermanNumberFormat(costs)
                });

                if (this.selectedIndex >= 0) {
                    this.costsInfo[this.selectedIndex].additionalCosts = costs;
                }
            }
            this.calculateTotalCosts();
        });
    }

    private selectRow(index: number): void {
        this.acquisitionGiveAwayForm.controls.costsPerDayTotal.enable();
        this.acquisitionGiveAwayForm.controls.additionalCosts.enable();

        this.selectedIndex = index;
        const costsInfo = this.costsInfo[index];
        let costsPerDayTotal = 0;
        let additionalCosts = 0;
        if (costsInfo) {
            costsPerDayTotal = costsInfo.costsPerDay;
            additionalCosts = costsInfo.additionalCosts;
        }
        this.acquisitionGiveAwayForm.patchValue({
            costsPerDayTotal: NumberConverter.toGermanNumberFormat(+costsPerDayTotal),
            additionalCosts: NumberConverter.toGermanNumberFormat(+additionalCosts)
        });
    }
}

export type RueckgabePensionstierCostInfo = {
    animalId: number,
    costsPerDay: number,
    additionalCosts: number
};
