import {Injectable, OnDestroy} from '@angular/core';
import {DropdownOption} from '../../../shared/data-types/dropdown-option';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import {SelectBoxService} from '../../../shared/controls/select-box/select-box.service';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {CRUD} from '../../../shared/services/http/crud';
import {SelectBoxUtility} from '../../../shared/controls/select-box/select-box-utility';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {MatOption} from '@angular/material/core';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import {AbstractControl} from '@angular/forms';
import * as fromApp from '../../../store/app.reducer';
import * as fromAccommodationActions from '../accommodation/accommodation.actions';

@Injectable()
export class AccommodationService implements OnDestroy {
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService,
                private selectBoxService: SelectBoxService) {
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public fetchAccommodations(): Observable<DropdownOption[]> {
        return this.store.select('accommodationList').pipe(
            takeUntil(this.destroy$),
            map(accommodationState => {
                return accommodationState.accommodations;
            }),
            switchMap(accommodations => {
                if (accommodations.length === 0) {
                    this.store.dispatch(new fromAccommodationActions.LoadAccommodations(CRUD.READ));
                    return this.actions$.pipe(
                        ofType(fromAccommodationActions.SET_ACCOMMODATIONS),
                        map((accommodationState: fromAccommodationActions.SetAccommodations) => {
                            return accommodationState.payload.accommodations;
                        })
                    );
                } else {
                    return of(accommodations);
                }
            }),
            map((accommodations: DictionaryEntryModel[]) => {
                return accommodations
                    .map((element: DictionaryEntryModel) => {
                        return {
                            value: SelectBoxUtility.getOptionsValue(element.name),
                            viewValue: element.name,
                            meta: [{key: 'id', value: element.id.toString()}]
                        } as DropdownOption;
                    })
                    .sort(this.selectBoxService.sortCallback);
            })
        );
    }

    public addAccommodation(event: MouseEvent): void {
        event.stopPropagation();
        const dialogRef = this.selectBoxService.openAddDialog('Unterkunft');

        dialogRef.afterClosed().subscribe(result => {
            if (typeof result !== 'undefined' && result !== false) {
                const newModel: DictionaryEntryModel = {
                    id: -1,
                    name: result.name
                };

                this.store.dispatch(new fromAccommodationActions.AddAccommodation(newModel));
                this.actions$.pipe(
                    ofType(fromAccommodationActions.SET_ACCOMMODATIONS),
                    take(1)
                ).subscribe((AccommodationState: fromAccommodationActions.SetAccommodations) => {
                    if (AccommodationState.payload.operation === CRUD.CREATE) {
                        setTimeout(() => this.handleRequestSuccess(CRUD.CREATE, 'Unterkunft', result.name));
                    }
                });
            }
        });
    }

    public updateAccommodation(event: MouseEvent,
                               matOption: MatOption,
                               accommodation: DropdownOption,
                               updatedAccommodation: HTMLInputElement,
                               accommodationControl: AbstractControl): void {
        event.stopPropagation();

        if (updatedAccommodation.value === '') {
            updatedAccommodation.value = accommodation.viewValue;
            return;
        }

        const updatedModel: DictionaryEntryModel = {
            id: +accommodation.meta.find(tuple => tuple.key === 'id').value,
            name: updatedAccommodation.value
        };
        this.selectBoxService.update(matOption, new fromAccommodationActions.UpdateAccommodation(updatedModel));
        this.actions$.pipe(
            ofType(fromAccommodationActions.SET_ACCOMMODATIONS),
            take(1)
        ).subscribe(() => {
            accommodationControl.setValue(SelectBoxUtility.getOptionsValue(updatedAccommodation.value));
            setTimeout(() => this.handleRequestSuccess(CRUD.UPDATE, 'Unterkunft', updatedAccommodation.value));
        });
    }

    public deleteAccommodation(event: MouseEvent,
                               accommodation: DropdownOption,
                               animalEntry: AnimalEntryModel,
                               accommodationControl: any,
                               selectedValue: string): void {
        event.stopPropagation();
        const dialogRef = this.selectBoxService.delete('Unterkunft', accommodation.viewValue);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                const accommodationId = +accommodation.meta.find(tuple => tuple.key === 'id').value;
                this.store.dispatch(new fromAccommodationActions.DeleteAccommodation(accommodationId));
                this.actions$.pipe(
                    ofType(fromAccommodationActions.SET_ACCOMMODATIONS),
                    take(1)
                ).subscribe(() => {
                    if (accommodationId === animalEntry.accommodation.id) {
                        accommodationControl.setValue('unbekannt');
                    } else {
                        accommodationControl.setValue(selectedValue);
                    }
                    setTimeout(() => this.handleRequestSuccess(CRUD.DELETE, 'Kategorie', accommodation.viewValue));
                });
            }
        });
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }
}

