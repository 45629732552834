import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {MissingAnimalEntryModel} from '../../../shared/models/animal/missing-animal-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_MISSING_ANIMALS = '[MissingAnimals] Load MissingAnimals';
export const SET_MISSING_ANIMALS = '[MissingAnimals] Set MissingAnimals';
export const ADD_MISSING_ANIMAL = '[MissingAnimals] Add MissingAnimals';
export const UPDATE_MISSING_ANIMAL = '[MissingAnimals] Update MissingAnimals';
export const DELETE_MISSING_ANIMAL = '[MissingAnimals] Delete MissingAnimals';
export const MATCH_MISSING_ANIMAL = '[MissingAnimals] Match MissingAnimals';
export const SET_MATCHED_ANIMALS = '[MissingAnimals] Load Matched MissingAnimals';
export const HTTP_FAIL = '[MissingAnimals] Http Fail';

export class LoadMissingAnimals implements Action {
    readonly type = LOAD_MISSING_ANIMALS;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class SetMissingAnimals implements Action {
    readonly type = SET_MISSING_ANIMALS;

    constructor(public payload: {
        missingAnimals: MissingAnimalEntryModel[],
        operation: CRUD,
        totalElements: number,
        filter?: any
    }) {
    }
}

export class AddMissingAnimal implements Action {
    readonly type = ADD_MISSING_ANIMAL;

    constructor(public payload: {
        newModel: MissingAnimalEntryModel,
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class UpdateMissingAnimal implements Action {
    readonly type = UPDATE_MISSING_ANIMAL;

    constructor(public payload: {
        updatedModel: MissingAnimalEntryModel,
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class DeleteMissingAnimal implements Action {
    readonly type = DELETE_MISSING_ANIMAL;

    constructor(public payload: {
        id: number,
        size: number,
        page: number,
        order: Order,
        column: string,
        filter?: any
    }) {
    }
}

export class MatchMissingAnimal implements Action {
    readonly type = MATCH_MISSING_ANIMAL;

    constructor(public payload: {
        id: number,
        radius: number
    }) {
    }
}

export class SetMatchedAnimals implements Action {
    readonly type = SET_MATCHED_ANIMALS;

    constructor(public payload: MissingAnimalEntryModel[]) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type MissingAnimalsActions =
    LoadMissingAnimals
    | SetMissingAnimals
    | AddMissingAnimal
    | UpdateMissingAnimal
    | DeleteMissingAnimal
    | MatchMissingAnimal
    | SetMatchedAnimals
    | HttpFail;

