import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import * as fromAccommodationActions from './accommodation.actions';

export interface State {
    accommodations: DictionaryEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    accommodations: [],
    operation: CRUD.NONE
};

export function accommodationReducer(state = initialState, action: fromAccommodationActions.AccommodationsActions): State {
    switch (action.type) {
        case fromAccommodationActions.SET_ACCOMMODATIONS:
            return {
                ...state,
                accommodations: [...action.payload.accommodations],
                operation: action.payload.operation
            };
        case fromAccommodationActions.ADD_ACCOMMODATION:
            return {
                ...state,
                accommodations: [...state.accommodations, action.payload]
            };
        case fromAccommodationActions.UPDATE_ACCOMMODATION:
            const updatedAccommodation = {
                ...state.accommodations.find(accommodation => accommodation.id === action.payload.id),
                ...action.payload
            };

            const updatedAccommodations = [...state.accommodations];
            const arrIndex = updatedAccommodations.findIndex(element => element.id === updatedAccommodation.id);
            updatedAccommodations[arrIndex] = updatedAccommodation;

            return {
                ...state,
                accommodations: updatedAccommodations
            };
        case fromAccommodationActions.DELETE_ACCOMMODATION:
            return {
                ...state,
                accommodations: state.accommodations.filter(accommodation => accommodation.id !== action.payload)
            };
        case fromAccommodationActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
