import {AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {NGX_MAT_DATE_FORMATS, NgxMatDatetimePicker} from '@angular-material-components/datetime-picker';
import {DropdownOption} from '../../shared/data-types/dropdown-option';
import {ActionTypesService} from '../store/action-types/action-types.service';
import {Observable, Subscription} from 'rxjs';
import {TupleHelper} from '../../shared/data-types/tuple';
import {MatOption} from '@angular/material/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AnimalDropdownOptions} from '../animal-dropdown-options';
import {SelectSearch} from '../../shared/services/select-box/select-search';
import {AnimalsService} from '../animals.service';
import {Actions} from '@ngrx/effects';
import {map, switchMap, take} from 'rxjs/operators';
import {AutoCompleteService} from '../../shared/services/auto-complete/auto-complete.service';
import {of} from 'rxjs/internal/observable/of';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

@Component({
    selector: 'app-animals-filter',
    templateUrl: './animals-filter.component.html',
    styleUrls: ['./animals-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class AnimalsFilterComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() allSpecies: DropdownOption[];
    @Input() allRaces: DropdownOption[];
    @ViewChild('actionTypeSelect', {static: true}) public actionTypeSelect: MatSelect;
    @ViewChild('datePickerBirthday') public datePickerBirthday: NgxMatDatetimePicker<any>;
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('datePickerTo') public datePickerTo: any;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    @ViewChild('singleSelectRace') public singleSelectRace: MatSelect;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public actionTypes: DropdownOption[];
    public TupleHelper = TupleHelper;
    public filterForm: FormGroup;
    public displayErroneousInputs = false;
    public shelterStatusOptions = AnimalDropdownOptions.shelterStatusOptions;
    public castrationStatusOptions = AnimalDropdownOptions.castrationStatusOptions;
    public genderOptions = AnimalDropdownOptions.genderOptions;
    public selectSearchSpecies = new SelectSearch();
    public selectSearchRace = new SelectSearch();
    public filteredAnimalIds$: Observable<[number, string | number][]>;
    public filteredAnimalNames$: Observable<[number, string | number][]>;
    public filteredChipNumbers$: Observable<[number, string | number][]>;
    public isInformationPage = false;

    private inputIndicator: HTMLDivElement;
    private selectedSymbol: string;
    private selectBoxSub: Subscription;
    private callbackSub: Subscription;
    private actionTypesSub: Subscription;
    private routeSub: Subscription;

    constructor(private actionTypesService: ActionTypesService,
                private autocompleteService: AutoCompleteService,
                private renderer: Renderer2,
                private actions$: Actions,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private animalsService: AnimalsService) {
    }

    ngOnInit(): void {
        const natEl = this.actionTypeSelect._elementRef.nativeElement.children[0];
        this.inputIndicator = this.renderer.createElement('div');
        this.renderer.addClass(this.inputIndicator, 'animal-type');
        this.renderer.addClass(this.inputIndicator, 'input');
        this.renderer.addClass(this.inputIndicator, 'fas');
        this.renderer.appendChild(natEl, this.inputIndicator);

        this.initForm();
        this.initActionTypes();
        this.selectBoxSub = this.animalsService.speciesAndRaces.subscribe(([species, races]) => {
            this.allRaces = races;
            this.selectSearchSpecies.setValues(species);
            this.selectSearchRace.setValues(races);
        });

        this.routeSub = this.route
            .data
            .subscribe((data) => {
                this.isInformationPage = data.isInformationPage;
            });
    }

    ngAfterViewInit(): void {
        this.selectSearchSpecies.init(this.singleSelectSpecies);
        this.selectSearchRace.init(this.singleSelectRace);
    }

    ngOnDestroy(): void {
        if (this.selectBoxSub) {
            this.selectBoxSub.unsubscribe();
        }
        if (this.callbackSub) {
            this.callbackSub.unsubscribe();
        }
        if (this.actionTypesSub) {
            this.actionTypesSub.unsubscribe();
        }
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }

        this.selectSearchSpecies.destroy();
        this.selectSearchRace.destroy();
    }

    public selChange(): void {
        this.renderer.removeClass(this.inputIndicator, this.selectedSymbol);

        const selectedOption = (this.actionTypeSelect.selected as MatOption);
        const value = (selectedOption.value) as DropdownOption;
        const color = TupleHelper.getValue(value.meta, 'color');
        this.selectedSymbol = TupleHelper.getValue(value.meta, 'symbol');

        this.renderer.setStyle(this.inputIndicator, 'background-color', color);
        this.renderer.addClass(this.inputIndicator, this.selectedSymbol);
    }

    public selectSpecies(option: DropdownOption): void {
        if (option) {
            const filteredRaces = this.allRaces
                .filter(race => +TupleHelper.getValue(race.meta, 'speciesId') === +TupleHelper.getValue(option.meta, 'id'));
            this.selectSearchRace.setValues(filteredRaces);
            this.filterForm.patchValue({
                race: ''
            });
        }
        this.singleSelectSpecies.close();
    }

    public filter(): void {
        if (!this.filterForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const value = this.filterForm.value;
        const from = value.from ? moment(value.from).set({
            hour: 0,
            minute: 0,
            second: 0
        }).format('YYYY-MM-DD HH:mm:ss') : '';

        const to = value.to ? moment(value.to).set({
            hour: 23,
            minute: 59,
            second: 59
        }).format('YYYY-MM-DD HH:mm:ss') : '';

        this.displayErroneousInputs = false;
        const filterModel = {
            id: value.id ? value.id : '',
            fundtierId: value.fundtierId ?? '',
            name: value.name ? value.name : '',
            birthday: value.birthday ? value.birthday.format('YYYY-MM-DD') : '',
            chipNumber: value.chipNumber ? value.chipNumber : '',
            actionType: value.actionType ? value.actionType.value : '',
            species: value.species ? value.species.viewValue : '',
            race: value.race ? value.race.viewValue : '',
            shelter: value.shelter ? value.shelter.value : '',
            gender: value.gender ? value.gender : '',
            castration: value.castration ? value.castration : '',
            from,
            to,
            includeCareSpaceAnimals: value.includeCareSpaceAnimals ? value.includeCareSpaceAnimals : '',
            includeDeadAnimals: value.includeDeadAnimals ? value.includeDeadAnimals : '',
            isPremium: ''
        };

        this.animalsService.filterEvent.next(filterModel);
    }

    public reset(): void {
        this.filterForm.reset({
            includeCareSpaceAnimals: [true]
        });
        this.renderer.removeClass(this.inputIndicator, this.selectedSymbol);
        this.renderer.setStyle(this.inputIndicator, 'background-color', 'transparent');
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            id: ['', [Validators.maxLength(7), Validators.pattern('^[0-9]*$')]],
            fundtierId: ['', [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
            name: ['', Validators.maxLength(50)],
            birthday: ['', Validators.maxLength(50)],
            chipNumber: ['', Validators.maxLength(50)],
            actionType: ['', Validators.maxLength(50)],
            species: ['', Validators.maxLength(50)],
            race: ['', Validators.maxLength(50)],
            shelter: ['', Validators.maxLength(50)],
            gender: ['', Validators.maxLength(50)],
            castration: ['', Validators.maxLength(50)],
            from: ['', Validators.maxLength(50)],
            to: ['', Validators.maxLength(50)],
            includeCareSpaceAnimals: [true],
            includeDeadAnimals: ['']
        });

        this.filteredAnimalIds$ = this.filterForm.controls.id.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('animal', 'id', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredAnimalNames$ = this.filterForm.controls.name.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('animal', 'name', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );

        this.filteredChipNumbers$ = this.filterForm.controls.chipNumber.valueChanges
            .pipe(
                map(val => {
                    if (val !== null && val.length >= 1) {
                        return this.autocompleteService.fetchOptions('animal', 'chipnumber', val);
                    } else {
                        return of([]);
                    }
                }),
                switchMap(result => {
                    return result;
                })
            );
    }

    private initActionTypes(): void {
        this.actionTypesSub = this.actionTypesService.populateActionTypeSelectBox()
            .pipe(
                take(1)
            )
            .subscribe(options => {
                this.actionTypes = options;
            });
    }
}
