import {AfterViewInit, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren, ViewEncapsulation} from '@angular/core';
import {PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlSerializer} from '@angular/router';
import {AdminLayoutService} from '../../layouts/admin-layout/admin-layout.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    class: string;
    subRoutes?: Array<RouteInfo>;
}

export const ROUTES: RouteInfo[] = [
    {
        path: '/animals', title: 'Tiere', icon: 'paw', class: 'animals', subRoutes:
            [
                {path: 'overview', title: 'Übersicht', class: 'overview'},
                {path: 'acquisition', title: 'Annahme', class: 'acquisition'},
                {path: 'give-away', title: 'Vergabe', class: 'give-away'},
                {path: 'care-space', title: 'Pflegeplatz', class: 'care-space'},
                {path: 'missing', title: 'Vermisst', class: 'missing'},
                {path: 'information', title: 'Auskunft', class: 'information'},
                {path: 'statistics', title: 'Statistik', class: 'Statistik'}
            ]
    },
    {
        path: '/persons', title: 'Personen', icon: 'male', class: 'persons', subRoutes:
            [
                {path: 'overview', title: 'Übersicht', class: 'overview'},
                {path: 'transfer-person-data', title: 'Doppelte Personen', class: 'transfer-person-data'}
            ]
    },
    {path: '/actions', title: 'Aktionen', icon: 'exchange-alt', class: 'actions'},
    {path: '/veterinary-treatments', title: 'Maßnahmen', icon: 'syringe', class: 'veterinary-treatments'},
    {path: '/supervisions', title: 'Kontrollen', icon: 'smile-beam', class: 'supervision'},
    {path: '/attributes', title: 'Tierattribute', icon: 'filter', class: 'attributes'},
    {
        path: '/pos-system', title: 'Kassa', icon: 'cash-register', class: 'pos-system', subRoutes:
            [
                {path: 'income', title: 'Eingang', class: 'income'},
                {path: 'outcome', title: 'Ausgang', class: 'outcome'},
                {path: 'cashbook-excerpt', title: 'Kassabuchauszug', class: 'cashbook-excerpt'},
                {path: 'cancellation', title: 'Storno', class: 'cancellation'},
                {path: 'article-list', title: 'Artikelliste', class: 'article-list'}
            ]
    }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, AfterViewInit {
    @ViewChildren('parentListItem') public parentListItem: QueryList<ElementRef>;
    public menuItems: RouteInfo[];
    public submenuItemHeight = 40;

    private readonly currentRoute: string;

    constructor(private router: Router,
                private serializer: UrlSerializer,
                private renderer: Renderer2,
                private adminLayoutService: AdminLayoutService) {
        const tree = serializer.parse(router.url);
        const group: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const segments: UrlSegment[] = group.segments;
        this.currentRoute = segments[0].path;
    }

    ngOnInit(): void {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.adminLayoutService.menuItemsClosed.subscribe(closed => {
            this.closeAll();
        });
    }

    ngAfterViewInit(): void {
        this.parentListItem.forEach(el => {
            if (el.nativeElement.classList.contains(this.currentRoute)) {
                if (el.nativeElement.classList.contains('has-submenu')) {
                    this.renderer.addClass(el.nativeElement, 'expanded');
                }
                this.renderer.addClass(el.nativeElement, 'active');
            }
        });
    }

    public toggleMenu(parentListEl: HTMLElement): void {
        const item = parentListEl.getAttribute('data-item');
        this.closeMenus(item);

        const classList = parentListEl.classList;
        if (classList.contains('expanded')) {
            parentListEl.classList.remove('expanded');
        } else {
            parentListEl.classList.add('expanded');
        }
    }

    public closeMenus(item: string): void {
        this.parentListItem.forEach(el => {
            if (!el.nativeElement.classList.contains(item)) {
                this.renderer.removeClass(el.nativeElement, 'expanded');
            }
        });
    }

    public closeAll(): void {
        this.menuItems.forEach(item => {
            this.closeMenus(item.class);
        });
    }

    public goTo(path: string, parentListEl: HTMLElement): void {
        this.removeActiveClass();
        this.router.navigate([path]).then((success) => {
            if (success) {
                const item = parentListEl.getAttribute('data-item');
                this.closeMenus(item);

                this.renderer.addClass(parentListEl, 'active');
            }
        });
    }

    public removeActiveClass(): void {
        this.parentListItem.forEach(el => {
            this.renderer.removeClass(el.nativeElement, 'active');
        });
    }
}
