import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as fromLoggingActions from './store/logging.actions';

@Injectable()
export class LoggingService {

    constructor(private store: Store<fromApp.AppState>) {
    }

    public addLogging(message: string): void {
        this.store.dispatch(new fromLoggingActions.AddLogging(message));
    }
}
