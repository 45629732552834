import {NgxMatDateFormats} from '@angular-material-components/datetime-picker';

export class CustomFormat {
    public static Date(): any {
        return {
            parse: {
                dateInput: 'DD.MM.YYYY'
            },
            display: {
                dateInput: 'DD.MM.YYYY',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
            }
        };
    }
}

export const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'l, LTS'
    },
    display: {
        dateInput: 'DD.MM.YYYY HH:mm:ss',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

