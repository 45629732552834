import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../shared/services/http/endpoint.service';
import {CRUD} from '../../shared/services/http/crud';
import {Order} from '../../shared/controls/data-table/ordering';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import * as fromActionsActions from './actions.actions';
import {AnimalActionConverter} from '../../shared/converter/animal-action-converter';
import {LoggingService} from '../../shared/logging/logging.service';
import {handleHTTPError} from '../../shared/error-handling';
import {InvalidAPIRequest} from '../../shared/data-types/error-types';

@Injectable()
export class ActionsEffects {
    @Effect()
    loadActions$ = this.actions$.pipe(
        ofType(fromActionsActions.LOAD_ACTIONS),
        switchMap((actionsState: fromActionsActions.LoadActions) => {
            const page = actionsState.payload.page;
            const size = actionsState.payload.size;
            const order = actionsState.payload.order;
            const orderColumn = actionsState.payload.column;
            const actionFilter = actionsState.payload.actionFilter;
            const usePagination = actionsState.payload.usePagination;
            const refreshTable = actionsState.payload.refreshTable;

            let params = new HttpParams();
            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (usePagination) {
                params = params.append('use_pagination', usePagination.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (actionFilter) {
                params = params.append('filter', 'true');

                params = typeof actionFilter.transactionId !== 'undefined' && actionFilter.transactionId !== '' ?
                    params.append('transaction_id', actionFilter.transactionId) : params;
                params = typeof actionFilter.dateFrom !== 'undefined' && actionFilter.dateFrom !== '' ?
                    params.append('date_performed_from', actionFilter.dateFrom) : params;
                params = typeof actionFilter.dateTo !== 'undefined' && actionFilter.dateTo !== '' ?
                    params.append('date_performed_to', actionFilter.dateTo) : params;
                params = typeof actionFilter.actionId !== 'undefined' && actionFilter.actionId !== '' ?
                    params.append('action_id', actionFilter.actionId) : params;
                params = typeof actionFilter.actionName !== 'undefined' && actionFilter.actionName !== '' ?
                    params.append('action_name', actionFilter.actionName) : params;
                params = typeof actionFilter.actionType !== 'undefined' && actionFilter.actionType !== '' ?
                    params.append('action_type', actionFilter.actionType) : params;
                params = typeof actionFilter.animalId !== 'undefined' && actionFilter.animalId !== '' ?
                    params.append('animal_id', actionFilter.animalId) : params;
                params = typeof actionFilter.animalName !== 'undefined' && actionFilter.animalName !== '' ?
                    params.append('animal_name', actionFilter.animalName) : params;
                params = typeof actionFilter.species !== 'undefined' && actionFilter.species !== '' ?
                    params.append('species', actionFilter.species) : params;
                params = typeof actionFilter.personId !== 'undefined' && actionFilter.personId !== '' ?
                    params.append('person_id', actionFilter.personId) : params;
                params = typeof actionFilter.lastName !== 'undefined' && actionFilter.lastName !== '' ?
                    params.append('last_name', actionFilter.lastName) : params;
                params = typeof actionFilter.latestOnlyActionType !== 'undefined' && actionFilter.latestOnlyActionType !== '' ?
                    params.append('latest_only_action_type', actionFilter.latestOnlyActionType) : params;

                if (typeof actionFilter.isSupervised !== 'undefined' &&
                    actionFilter.isSupervised !== '' &&
                    actionFilter.isSupervised === 'false') {
                    params = params.append('is_supervised', 'false');
                }
            }

            return this.endpointService.transactions(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params});
                    }),
                    map(response => {
                        const totalElements = usePagination ? response.data.pagination.totalElements : response.data.length;
                        const lastPage = usePagination ? response.data.pagination.pages : 1;
                        const fetchedEntries = usePagination ? response.data.data : response.data;
                        const mappedActionArr: TransactionEntryModel[] = fetchedEntries.map(action => {
                            return AnimalActionConverter.toTransactionEntryModel(action);
                        });

                        return new fromActionsActions.SetActions({
                            actions: mappedActionArr,
                            invalidAPIRequests: actionsState.payload.invalidAPIRequests,
                            totalElements,
                            crud: actionsState.payload.crud,
                            lastPage,
                            transactionId: actionsState.payload.transactionId,
                            refreshTable
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    fetchLatestAction$ = this.actions$.pipe(
        ofType(fromActionsActions.FETCH_LATEST_TRANSACTION),
        switchMap(() => {
            return this.endpointService.transactions(HttpMethod.GET, -1, true)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint);
                    }),
                    map(response => {
                        return new fromActionsActions.SetLatestAction(response.data.transaction_id);
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
        })
    );

    @Effect()
    addActions$ = this.actions$.pipe(
        ofType(fromActionsActions.ADD_ACTIONS),
        switchMap((actionState: fromActionsActions.AddActions) => {
            return this.endpointService.transactions(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    const newTransactions = [];
                    actionState.payload.actions.forEach(transaction => {
                        const newHTTPTransaction = AnimalActionConverter.toTransactionHTTP(transaction);
                        newTransactions.push(newHTTPTransaction);
                    });

                    return this.http
                        .post<any>(endpoint, newTransactions)
                        .pipe(
                            map((response) => {
                                if (actionState.payload.fetchActions) {
                                    return new fromActionsActions.LoadActions(
                                        {
                                            crud: CRUD.CREATE,
                                            order: actionState.payload.order,
                                            column: actionState.payload.column,
                                            size: actionState.payload.size,
                                            page: actionState.payload.page,
                                            fetchActions: actionState.payload.fetchActions,
                                            usePagination: true,
                                            invalidAPIRequests: response.data.invalid_api_requests.map(entry => {
                                                return {
                                                    animalId: entry.animal_id,
                                                    errorMsg: entry.message
                                                };
                                            })
                                        }
                                    );
                                } else {
                                    return new fromActionsActions.SetActions({
                                        lastPage: 1,
                                        totalElements: 0,
                                        crud: CRUD.CREATE,
                                        actions: [...response.data.created_transactions],
                                        invalidAPIRequests: response.data.invalid_api_requests.map(entry => {
                                            return {
                                                animalId: entry.animal_id,
                                                errorMsg: entry.message
                                            };
                                        })
                                    });
                                }
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateActions$ = this.actions$.pipe(
        ofType(fromActionsActions.UPDATE_ACTIONS),
        switchMap((actionState: fromActionsActions.UpdateActions) => {
            const updateObsArr$ = [];
            actionState.payload.actions.forEach(action => {
                const obs$ = this.endpointService.transactions(HttpMethod.PUT, action.id).pipe(
                    take(1),
                    mergeMap(endpoint => {
                        return this.http
                            .put<any>(endpoint, AnimalActionConverter.toTransactionHTTP(action));
                    })
                );
                updateObsArr$.push(obs$);
            });

            if (updateObsArr$.length > 0) {
                return forkJoin(updateObsArr$).pipe(
                    take(1),
                    map((response: any) => {
                        const requests: InvalidAPIRequest[] = [];
                        response.forEach(actionResp => {
                            actionResp.data.invalid_api_requests.forEach(entry => {
                                requests.push({
                                    animalId: entry.animal_id,
                                    errorMsg: entry.message
                                });
                            });
                        });

                        return new fromActionsActions.LoadActions({
                            crud: CRUD.UPDATE,
                            order: actionState.payload.order,
                            column: actionState.payload.column,
                            size: actionState.payload.size,
                            page: actionState.payload.page,
                            actionFilter: actionState.payload.filter,
                            usePagination: true,
                            invalidAPIRequests: requests
                        });
                    }),
                    catchError(error => {
                        return this.handleError(error);
                    })
                );
            } else {
                return of(new fromActionsActions.LoadActions({
                    crud: CRUD.UPDATE,
                    order: actionState.payload.order,
                    column: actionState.payload.column,
                    size: actionState.payload.size,
                    page: actionState.payload.page,
                    actionFilter: actionState.payload.filter
                }));
            }
        })
    );

    @Effect()
    deleteAction$ = this.actions$.pipe(
        ofType(fromActionsActions.DELETE_ACTION),
        switchMap((actionsState: fromActionsActions.DeleteAction) => {
            return this.endpointService.transactions(HttpMethod.DELETE, actionsState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromActionsActions.LoadActions({
                                    crud: CRUD.DELETE,
                                    order: actionsState.payload.order,
                                    column: actionsState.payload.column,
                                    size: actionsState.payload.size,
                                    page: actionsState.payload.page,
                                    usePagination: actionsState.payload.usePagination
                                });
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteActions$ = this.actions$.pipe(
        ofType(fromActionsActions.DELETE_ACTIONS),
        switchMap((actionsState: fromActionsActions.DeleteActions) => {
            const deleteObsArr$ = [];
            actionsState.payload.forEach(actionId => {
                const obs$ = this.endpointService.transactions(HttpMethod.DELETE, actionId).pipe(
                    take(1),
                    mergeMap(endpoint => {
                        return this.http
                            .delete<any>(endpoint);
                    })
                );
                deleteObsArr$.push(obs$);
            });

            return forkJoin(deleteObsArr$).pipe(
                take(1),
                map(() => {
                    return new fromActionsActions.LoadActions({
                        crud: CRUD.DELETE,
                        order: Order.DESC,
                        column: 'date_performed',
                        size: -1,
                        page: 1
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromActionsActions.HttpFail> => {
        return handleHTTPError(errorRes, fromActionsActions, 'Aktionen', this.loggingService);
    };
}
