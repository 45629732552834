import {MediaEntryModel} from '../../shared/models/media-entry.model';
import * as fromMediaActions from './media.actions';

export interface State {
    media: MediaEntryModel[];
}

const initialState: State = {
    media: []
};

export function mediaReducer(state = initialState, action: fromMediaActions.MediaActions): State {
    switch (action.type) {
        case fromMediaActions.SET_MEDIA: {
            return {
                ...state,
                media: [...action.payload.media]
            };
        }
        case fromMediaActions.SET_MULTIPLE_MEDIA: {
            return {
                ...state,
                media: [...action.payload.media]
            };
        }
        case fromMediaActions.UPDATE_MEDIA:
            const updatedMedia = {
                ...state.media.find(media => media.id === action.payload.media.id),
                ...action.payload
            };

            const updatedMediaEntries = [...state.media];
            const arrIndex = updatedMediaEntries.findIndex(element => element.id === updatedMedia.id);
            updatedMediaEntries[arrIndex] = updatedMedia;

            return {
                ...state,
                media: updatedMediaEntries
            };
        case fromMediaActions.DELETE_MEDIA: {
            return {
                ...state,
                media: state.media.filter(media => media.id !== action.payload.id)
            };
        }
        case fromMediaActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
