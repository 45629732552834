<div class="edit-person">
    <form class="form container-fluid"
          [formGroup]="personForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Nachname</mat-label>
                    <input matInput
                           placeholder="Nachname"
                           formControlName="lastName">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Vorname</mat-label>
                    <input matInput
                           placeholder="Vorname"
                           formControlName="firstName">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Straße</mat-label>
                    <input matInput
                           placeholder="Straße"
                           formControlName="street">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Postleitzahl</mat-label>
                    <input type="text"
                           placeholder="Postleitzahl"
                           matInput
                           (ngModelChange)="zipCitySearchService.valueChanged($event, ZipCity.ZIP)"
                           formControlName="zip"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption
                                      #auto="matAutocomplete"
                                      (optionSelected)="zipCityChanged($event)"
                                      [displayWith]="zipCitySearchService.displayFn">
                        <mat-option *ngFor="let option of zipCitySearchService.values"
                                    [value]="option">
                            {{option.viewValue}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Ort</mat-label>
                    <input matInput
                           placeholder="Ort"
                           formControlName="city">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Telefon</mat-label>
                    <input matInput
                           placeholder="Telefon"
                           formControlName="phone">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field [ngClass]="{'mat-datetime-picker': true}">
                    <input matInput
                           (dateChange)="personService.dateChange($event)"
                           (click)="datePickerBirthday.open()"
                           [ngxMatDatetimePicker]="datePickerBirthday"
                           placeholder="Geburtsdatum"
                           formControlName="birthday"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerBirthday"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerBirthday
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>E-Mail</mat-label>
                    <input matInput
                           placeholder="E-Mail"
                           formControlName="email">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Nachweis</mat-label>
                    <input matInput
                           placeholder="Nachweis"
                           formControlName="proof">
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Aussteller</mat-label>
                    <textarea matInput
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5"
                              formControlName="issuer"></textarea>
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Bemerkung</mat-label>
                    <textarea matInput
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5"
                              formControlName="remark"></textarea>
                    <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button class="float-right mt-4"
                        mat-raised-button
                        color="primary"
                        [disabled]="updateRequestSent && personForm.valid"
                        (click)="updatePerson()">
                    <i class="fas fa-exclamation-circle mr-2"
                       style="color: #b71c1c"
                       *ngIf="displayErroneousInputs">
                    </i>
                    Aktualisieren
                </button>
                <mat-icon [hidden]="!(updateRequestSent && personForm.valid)" class="mr-2 float-right"
                          style="margin-top: 32px">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </div>
        </div>

    </form>
</div>

