import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user-service.service';
import {Order} from '../../shared/controls/data-table/ordering';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import {Actions} from '@ngrx/effects';
import {MustMatch} from './must-match.validator';

@Component({
    selector: 'app-create-user-dialog',
    templateUrl: './create-user-dialog.component.html',
    styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent implements OnInit {
    public createUserForm: FormGroup;
    public displayErroneousInputs = false;
    public pageIndex = 1;
    public entriesPerPage = 10;
    public order = Order.NONE;
    public orderedColumn = '';

    private currentUser: UserEntryModel;

    constructor(private formBuilder: FormBuilder,
                private actions$: Actions,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private usersService: UserService) {
        if (this.data === null) {
            return;
        }

        if (typeof data.pageIndex &&
            typeof data.entriesPerPage &&
            typeof data.order &&
            typeof data.orderedColumn &&
            typeof data.currentUser) {
            this.pageIndex = data.pageIndex;
            this.entriesPerPage = data.entriesPerPage;
            this.order = data.order;
            this.orderedColumn = data.orderedColumn;
            this.currentUser = data.currentUser;
        }
    }

    ngOnInit(): void {
        this.initForm();
    }

    public createUser(): void {
        if (!this.createUserForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }

        const newUser: UserEntryModel = {
            id: -1,
            isAdmin: false,
            email: this.createUserForm.controls.email.value,
            password: this.createUserForm.controls.password.value,
            firstName: this.createUserForm.controls.firstName.value,
            lastName: this.createUserForm.controls.lastName.value,
            shelterId: this.currentUser.shelterId,
            shelter: ''
        };
        this.usersService.addUser(newUser, this.entriesPerPage, this.pageIndex, Order.NONE, '');
        this.usersService.crudFinished.next(newUser.firstName + ' ' + newUser.lastName);
    }

    private initForm(): void {
        this.createUserForm = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            password: ['', Validators.required],
            passwordRepetition: ['', Validators.required]
        }, {
            validator: [MustMatch('password', 'passwordRepetition')]
        });
    }
}
