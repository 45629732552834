<div id="animals-filter" class="filter-bar">
    <form [formGroup]="filterForm"
          [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }"
          (ngSubmit)="filter()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Personnummer</mat-label>
                    <input matInput
                           placeholder="Personnummer"
                           formControlName="id"
                           [matAutocomplete]="personId">
                    <mat-autocomplete #personId="matAutocomplete"
                                      (optionSelected)="optionSelected($event, 'id')">
                        <mat-option *ngFor="let tuple of filteredPersonIds$ | async" [value]="tuple">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Nachname</mat-label>
                    <input matInput
                           placeholder="Nachname"
                           formControlName="lastName"
                           [matAutocomplete]="lastName">
                    <mat-autocomplete
                        #lastName="matAutocomplete"
                        (optionSelected)="optionSelected($event, 'lastName')">
                        <mat-option *ngFor="let tuple of filteredPersonLastNames$ | async" [value]="tuple">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Vorname</mat-label>
                    <input matInput
                           placeholder="Vorname"
                           formControlName="firstName"
                           [matAutocomplete]="firstName">
                    <mat-autocomplete
                        #firstName="matAutocomplete"
                        (optionSelected)="optionSelected($event, 'firstName')">
                        <mat-option *ngFor="let tuple of filteredPersonFirstNames$ | async" [value]="tuple">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Straße</mat-label>
                    <input matInput
                           placeholder="Straße"
                           formControlName="street"
                           [matAutocomplete]="street">
                    <mat-autocomplete #street="matAutocomplete"
                                      (optionSelected)="optionSelected($event, 'street')">
                        <mat-option *ngFor="let tuple of filteredPersonStreets$ | async" [value]="tuple">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Postleitzahl</mat-label>
                    <input matInput
                           placeholder="Postleitzahl"
                           formControlName="zip"
                           [matAutocomplete]="zip">
                    <mat-autocomplete #zip="matAutocomplete"
                                      (optionSelected)="optionSelected($event, 'zip')">
                        <mat-option *ngFor="let tuple of filteredPersonZips$ | async" [value]="tuple">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Ort</mat-label>
                    <input matInput
                           placeholder="Ort"
                           formControlName="city"
                           [matAutocomplete]="city">
                    <mat-autocomplete #city="matAutocomplete"
                                      (optionSelected)="optionSelected($event, 'city')">
                        <mat-option *ngFor="let tuple of filteredPersonCities$ | async" [value]="tuple">
                            {{tuple[1]}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mat-datetime-picker">
                    <input matInput
                           (click)="datePickerBirthday.open()"
                           [ngxMatDatetimePicker]="datePickerBirthday"
                           placeholder="Geburtsdatum"
                           formControlName="birthday"
                           [min]="dateTimeConfig.minDate"
                           [max]="dateTimeConfig.maxDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="datePickerBirthday"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #datePickerBirthday
                                             [stepHour]="dateTimeConfig.stepHour"
                                             [stepMinute]="dateTimeConfig.stepMinute"
                                             [hideTime]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>

        <div class="row d-flex justify-content-between flex-row-reverse">
            <div class="col">
                <button mat-raised-button
                        class="mt-4 pull-right float-right"
                        color="primary">
                    Suchen
                </button>
            </div>

            <div class="col text-right" *ngIf="resettable">
                <button mat-raised-button
                        class="mt-4 float-left"
                        color="warn"
                        (click)="reset()">
                    Zurücksetzen
                </button>
            </div>
        </div>
    </form>
</div>
