import {Action} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {DictionaryEntryModel} from '../../../shared/models/dictionary-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_CHARACTERISTICS = '[Characteristics] Load Characteristics';
export const SET_CHARACTERISTICS = '[Characteristics] Set Characteristics';
export const ADD_CHARACTERISTIC = '[Characteristics] Add Characteristics';
export const UPDATE_CHARACTERISTIC = '[Characteristics] Update Characteristics';
export const DELETE_CHARACTERISTIC = '[Characteristics] Delete Characteristics';
export const HTTP_FAIL = '[Characteristics] Http Fail';

export class LoadCharacteristics implements Action {
    readonly type = LOAD_CHARACTERISTICS;

    constructor(public payload: {
        crud: CRUD,
        addedOrUpdatedCharacteristic?: DictionaryEntryModel,
        filter?: any
    }) {
    }
}

export class SetCharacteristics implements Action {
    readonly type = SET_CHARACTERISTICS;

    constructor(public payload: {
        characteristics: DictionaryEntryModel[],
        addedOrUpdatedCharacteristic?: DictionaryEntryModel,
        operation: CRUD, filter?: any
    }) {
    }
}

export class AddCharacteristic implements Action {
    readonly type = ADD_CHARACTERISTIC;

    constructor(public payload: {
        newModel: DictionaryEntryModel,
        filter?: any
    }) {
    }
}

export class UpdateCharacteristic implements Action {
    readonly type = UPDATE_CHARACTERISTIC;

    constructor(public payload: {
        updatedModel: DictionaryEntryModel,
        filter?: any
    }) {
    }
}

export class DeleteCharacteristic implements Action {
    readonly type = DELETE_CHARACTERISTIC;

    constructor(public payload: {
        id: number,
        filter?: any
    }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type CharacteristicsActions =
    LoadCharacteristics
    | SetCharacteristics
    | AddCharacteristic
    | UpdateCharacteristic
    | DeleteCharacteristic
    | HttpFail;

