import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {CRUD} from '../../../shared/services/http/crud';
import {SnackStatusType} from '../../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../../shared/components/snackbar/snackbar.service';
import * as fromApp from '../../../store/app.reducer';
import * as fromMissingAnimalActions from './missing.actions';
import {MissingAnimalEntryModel} from '../../../shared/models/animal/missing-animal-entry.model';
import {Order} from '../../../shared/controls/data-table/ordering';

@Injectable({
    providedIn: 'root'
})
export class MissingService {
    constructor(private store: Store<fromApp.AppState>,
                private actions$: Actions,
                private snackbarService: SnackbarService) {
    }

    public fetchMissingAnimals(size = -1,
                               page = 1,
                               order = Order.NONE,
                               column = '',
                               filter?: any): Observable<MissingAnimalEntryModel[]> {
        return this.store.select('missingAnimalList').pipe(
            take(1),
            map(missingAnimalState => {
                return missingAnimalState.missingAnimals;
            }),
            switchMap(missingAnimals => {
                if (missingAnimals.length === 0 || page >= 0) {
                    this.store.dispatch(new fromMissingAnimalActions.LoadMissingAnimals({
                        crud: CRUD.READ,
                        size,
                        page,
                        order,
                        column,
                        filter
                    }));
                    return this.actions$.pipe(
                        ofType(fromMissingAnimalActions.SET_MISSING_ANIMALS),
                        take(1),
                        map((missingAnimalState: fromMissingAnimalActions.SetMissingAnimals) => {
                            return missingAnimalState.payload.missingAnimals;
                        })
                    );
                } else {
                    return of(missingAnimals);
                }
            })
        );
    }

    public addMissingAnimal(newModel: MissingAnimalEntryModel,
                            size: number,
                            page: number,
                            order: Order,
                            column: string,
                            filter?: any): void {
        this.store.dispatch(new fromMissingAnimalActions.AddMissingAnimal({
            newModel,
            size,
            page,
            order,
            column,
            filter
        }));
    }

    public updateMissingAnimal(updatedModel: MissingAnimalEntryModel,
                               size: number,
                               page: number,
                               order: Order,
                               column: string,
                               filter?: any): void {
        this.store.dispatch(new fromMissingAnimalActions.UpdateMissingAnimal({
            updatedModel,
            size,
            page,
            order,
            column,
            filter
        }));
    }

    public deleteMissingAnimal(id: number,
                               size: number,
                               page: number,
                               order: Order,
                               column: string,
                               filter?: any): void {
        this.store.dispatch(new fromMissingAnimalActions.DeleteMissingAnimal({
            id,
            size,
            page,
            order,
            column,
            filter
        }));
    }

    public fetchMatchedAnimals(id: number, radius: number): Observable<MissingAnimalEntryModel[]> {
        return this.store.select('missingAnimalList').pipe(
            take(1),
            map(missingAnimalState => {
                return missingAnimalState.missingAnimals;
            }),
            switchMap(() => {
                this.store.dispatch(new fromMissingAnimalActions.MatchMissingAnimal({
                    id,
                    radius
                }));
                return this.actions$.pipe(
                    ofType(fromMissingAnimalActions.SET_MATCHED_ANIMALS),
                    take(1),
                    map((matchedAnimalState: fromMissingAnimalActions.SetMatchedAnimals) => {
                        return matchedAnimalState.payload;
                    })
                );
            })
        );
    }

    public handleRequestSuccess(crudOperation: CRUD, type: string, identifier: string): void {
        this.snackbarService.displaySnackbarWithCrud(crudOperation,
            SnackStatusType.SUCCESS,
            [
                {
                    key: 'identifier',
                    value: type + ' ' + identifier
                }
            ]);
    }

    public handleRequestError(errorMsg: string): void {
        this.snackbarService.displaySnackbar(SnackStatusType.ERROR, errorMsg, 10);
    }
}
