import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateUpdateMissingAnimalComponent} from './create-update-missing-animal/create-update-missing-animal.component';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Actions, ofType} from '@ngrx/effects';
import * as fromMissingAnimals from '../store/missing/missing.actions';
import {CRUD} from '../../shared/services/http/crud';
import {MissingService} from '../store/missing/missing.service';
import {Subscription} from 'rxjs';
import {MissingAnimalEntryModel} from '../../shared/models/animal/missing-animal-entry.model';
import {DictionaryEntryModel} from '../../shared/models/dictionary-entry.model';
import {AnimalsService} from '../animals.service';
import {Order} from '../../shared/controls/data-table/ordering';
import {SimpleDialogComponent} from '../../dialogs/simple-dialog/simple-dialog.component';
import {DialogType} from '../../dialogs/dialog-type';
import {NumberConverter} from 'app/shared/converter/number-converter';

@Component({
    selector: 'app-missing',
    templateUrl: './missing.component.html',
    styleUrls: ['./missing.component.scss']
})
export class MissingComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public hideFilter = false;
    @Input() public hideControls = false;
    @Input() public displayProbability = false;
    @Input() public metrics = false;
    @Input() public missingAnimals: MissingAnimalEntryModel[] = null;
    @Input() loading?: boolean = null;
    @ViewChild('paginator') public paginator: MatPaginator;
    public totalAnimals = 0;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.DESC;
    public column = 'id';
    public NumberConverter = NumberConverter;

    private filter: any;
    private missingAnimalsSub: Subscription;
    private filterSub: Subscription;
    private simpleDialogType = new DialogType();

    constructor(private dialog: MatDialog,
                private animalsService: AnimalsService,
                private missingAnimalService: MissingService,
                private actions$: Actions) {
    }

    ngOnInit(): void {
        if (this.missingAnimals === null) {
            this.fetchMissingAnimals();
            this.initFilter();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.totalAnimals = changes.missingAnimals.currentValue.length;
    }

    ngOnDestroy(): void {
        if (this.missingAnimalsSub) {
            this.missingAnimalsSub.unsubscribe();
        }
        if (this.filterSub) {
            this.filterSub.unsubscribe();
        }
    }

    public openDialog(missingAnimal?: MissingAnimalEntryModel): void {
        this.dialog.open(CreateUpdateMissingAnimalComponent, {
            width: '1000px',
            panelClass: 'component-wrapper',
            data: {
                entriesPerPage: this.entriesPerPage,
                pageIndex: this.pageIndex,
                order: this.order,
                orderedColumn: this.column,
                missingAnimal
            }
        });
    }

    public switchPage($event: PageEvent): void {
        this.pageIndex = $event.pageIndex + 1;
        this.missingAnimalService.fetchMissingAnimals(this.entriesPerPage, this.pageIndex, this.filter)
            .subscribe();
    }

    public displayAttributes(attributes: DictionaryEntryModel[]): string {
        let strAttributes = '';
        attributes.forEach(attribute => {
            strAttributes += attribute.name + ', ';
        });
        return strAttributes.slice(0, -2);
    }

    public deleteMissingAnimal(animal: MissingAnimalEntryModel): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Tier',
                identifier: animal.name
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.missingAnimalService.deleteMissingAnimal(animal.id,
                    this.entriesPerPage,
                    this.pageIndex,
                    this.order,
                    this.column,
                    this.filter);
            }
        });
    }

    private fetchMissingAnimals(): void {
        this.loading = true;
        this.missingAnimalsSub = this.missingAnimalService.fetchMissingAnimals(this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.column,
            this.filter)
            .subscribe(response => {
                this.missingAnimals = response;
                this.totalAnimals = response.length;
                this.loading = false;
            });

        this.actions$.pipe(
            ofType(fromMissingAnimals.SET_MISSING_ANIMALS)
        ).subscribe((missingAnimalState: fromMissingAnimals.SetMissingAnimals) => {
            const operation = missingAnimalState.payload.operation;
            this.totalAnimals = missingAnimalState.payload.missingAnimals.length;
            if (operation === CRUD.NONE || operation === CRUD.READ) {
                return;
            }

            if (operation === CRUD.CREATE) {
                this.missingAnimalService.handleRequestSuccess(CRUD.CREATE, 'Vermisstes Tier', '');
            } else if (operation === CRUD.UPDATE) {
                this.missingAnimalService.handleRequestSuccess(CRUD.UPDATE, 'Vermisstes Tier', '');
            } else if (operation === CRUD.DELETE) {
                this.missingAnimalService.handleRequestSuccess(CRUD.DELETE, 'Vermisstes Tier', '');
            }

            this.missingAnimals = missingAnimalState.payload.missingAnimals;
        });
    }

    private initFilter(): void {
        this.filterSub = this.animalsService.filterMissingAnimalsEvent.subscribe(filterModel => {
            this.filter = filterModel;
            if (filterModel !== null) {
                this.missingAnimalService
                    .fetchMissingAnimals(this.entriesPerPage, 1, Order.NONE, '', filterModel)
                    .subscribe(response => {
                        this.missingAnimals = response;
                    });
                if (typeof this.paginator !== 'undefined') {
                    this.paginator.firstPage();
                }
            }
        });
    }
}
