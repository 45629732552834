<div class="title-bar">
    <h3>Benutzer verwalten</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Benutzer</h4>
                </div>
                <div class="card-body">
                    <div id="top-controls" class="d-flex justify-content-between">
                        <div id="show-entries-container">
                            Zeige
                            <select name="entries"
                                    id="entries"
                                    class="mr-1 ml-1"
                                    (ngModelChange)="changeEntriesPerPage($event)"
                                    [(ngModel)]="entriesPerPage">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            Einträge
                        </div>
                        <div id="add-new-user" *ngIf="currentUser !== undefined && currentUser.isAdmin">
                            <a class="d-flex align-items-center no-color"
                               (click)="openDialog()">
                                Neuen Benutzer erstellen <i
                                    class="add-btn fas fa-plus d-flex justify-content-center align-items-center ml-3"></i>
                            </a>
                        </div>
                    </div>
                    <div style="overflow-x: auto">
                        <table mat-table
                               [dataSource]="users"
                               multiTemplateDataRows
                               class="mat-elevation-z0">
                            <!-- Position Column -->
                            <ng-container *ngFor="let def of tableDef">
                                <ng-container [matColumnDef]="def.key">
                                    <th class="sortable-header" mat-header-cell mat-sort-header
                                        *matHeaderCellDef> {{def.header}} </th>
                                    <td [ngClass]="def.className" mat-cell
                                        *matCellDef="let element">
                                        {{element[def.key]}}
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container matColumnDef="controls">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <a class="fas fa-trash-alt icon prevent-opening"
                                       (click)="deleteUser(element);"
                                       *ngIf="currentUser !== undefined && currentUser.isAdmin"
                                       matTooltip="Person löschen"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"></a>
                                </td>
                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
                                    <div class="table-element-detail w-100"
                                         [@detailExpand]="'collapsed'">
                                        <app-manage-users-quick-edit class="w-100"
                                                                     [userEntry]="element"
                                                                     [pageIndex]="pageIndex"
                                                                     [entriesPerPage]="entriesPerPage"
                                                                     [order]="order"
                                                                     [orderedColumn]="orderedColumn"></app-manage-users-quick-edit>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="table-row"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = canExpand($event, element, expandedElement)">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
                        </table>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline">
                            <div id="total-results">Gesamt: <span>{{totalUsers}}</span></div>
                            <mat-paginator [length]="totalUsers"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div class="default-entry mt-2" *ngIf="totalUsers === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
