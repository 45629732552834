<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        Personenbezogene Aktionen
    </h3>
    <div class="position-absolute mt-4">
        <span>LFNr.: {{animal.id}}</span>
    </div>
    <div class="fa fa-close float-right mt-1 position-absolute"
         mat-button
         [mat-dialog-close]="true" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-body">
            <div class="action-entry" *ngFor="let transaction of personalizedTransactions">
                <div class="header d-flex justify-content-between">
                    <h4><span>{{getActionTypeText(transaction.actionTypeId)}}</span>
                        <div class="animal-type ml-2 findling"
                             [ngStyle]="{'background-color': getActionType(transaction.actionTypeId).color}"></div>
                    </h4>
                    <div>
                        <div class="font-weight-bolder">Transaktionsnummer: {{transaction.transactionId}}</div>
                        <div>{{transaction.date | date:'dd.MM.yyyy HH:mm:ss'}}</div>
                    </div>
                </div>
                <div class="body">
                    <div class="row" *ngIf="showPerson(transaction)">
                        <div class="col-md-3">
                            <div class="person-number font-weight-bolder">Personennummer:
                                <span>{{transaction.person.id}}</span></div>
                            <div>{{transaction.person.lastName}} {{transaction.person.firstName}}</div>
                            <div>{{transaction.person.street}}</div>
                            <div>{{transaction.person.zip}} - {{transaction.person.city}}</div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-3">Geburtsdatum:</div>
                                <div class="col-3 ml-2">{{transaction.person.birthday | date:'dd.MM.yyyy' }}</div>
                            </div>
                            <div class="row">
                                <div class="col-3">Telefonnummer:</div>
                                <div class="col-3  ml-2">{{transaction.person.phone}}</div>
                            </div>
                            <div class="row">
                                <div class="col-3">Nachweis:</div>
                                <div class="col-3 ml-2">{{transaction.person.proof}}</div>
                            </div>
                            <div class="row">
                                <div class="col-3">Aussteller:</div>
                                <div class="col-3 ml-2">{{transaction.person.issuer}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <ng-container [ngSwitch]="getActionType(transaction.actionTypeId).name">
                            <ng-container *ngSwitchCase="ActionType.FINDLING.key">
                                <div class="col-12">
                                    Fundort: <span>{{transaction.streetFound}}, {{transaction.zipFound}}
                                    - {{transaction.cityFound}}</span>
                                </div>
                                <div class="col-12 mt-2">
                                    {{transaction.howAnimalWasFound}}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ActionType.VERGABE.key">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-3">Adresse des Tiers:</div>
                                        <div class="col" *ngIf="transaction.residenceStreet === null">Wie bei Person
                                        </div>
                                        <div class="col"
                                             *ngIf="transaction.residenceStreet !== null">
                                            {{transaction.residenceStreet}}
                                            — {{transaction.residenceCity}} {{transaction.residenceZip}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">Garten:</div>
                                        <div class="col">{{transaction.hasGarden === true ? 'ja' : 'nein'}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">Zaun:</div>
                                        <div class="col">{{transaction.hasFence === true ? 'ja' : 'nein'}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">Weitere Tiere:</div>
                                        <div class="col" *ngIf="transaction.otherAnimalsInHousehold === ''">Keine
                                        </div>
                                        <div class="col"
                                             *ngIf="transaction.otherAnimalsInHousehold !== ''">
                                            {{transaction.otherAnimalsInHousehold}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ActionType.ABGABE.key">
                                <div class="col">
                                    {{transaction.giveAwayReason}}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ActionType.ABNAHME.key">
                                <div class="col-md-12">
                                    {{transaction.takingOverReason}}
                                </div>
                                <div class="col-md-12 mt-2">
                                    Behörde: {{transaction.publicAuthority}}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ActionType.ANNAHME_PENSIONSTIER.key">
                                <ng-container *ngIf="transaction.owner !== null">
                                    <h6 class="col-12 mt-3">Besitzer:</h6>
                                    <div class="col-md-3">
                                        <div class="person-number font-weight-bolder">Personennummer:
                                            <span>{{transaction.owner.id}}</span></div>
                                        <div>{{transaction.owner.lastName}} {{transaction.owner.firstName}}</div>
                                        <div>{{transaction.owner.street}}</div>
                                        <div>{{transaction.owner.zip}} - {{transaction.owner.city}}</div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-3">Geburtsdatum:</div>
                                            <div class="col-3 ml-2">{{transaction.owner.birthday | date:'dd.MM.yyyy'
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">Telefonnummer:</div>
                                            <div class="col-3  ml-2">{{transaction.owner.phone}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">Nachweis:</div>
                                            <div class="col-3 ml-2">{{transaction.owner.proof}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">Aussteller:</div>
                                            <div class="col-3 ml-2">{{transaction.owner.issuer}}</div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="col-md-3 mt-3">
                                    Aufenthalt:
                                </div>
                                <div class="col-md-9 mt-3">
                                    Von {{transaction.stayFrom | date:'dd.MM.yyyy'}}
                                    bis {{transaction.stayUntil | date:'dd.MM.yyyy'}} <br />
                                </div>
                                <div class="col-md-3">
                                    Anzahlung:
                                </div>
                                <div class="col-md-9">
                                    {{transaction.deposit !== null ? (transaction.deposit |
                                    currency:'EUR':'symbol':'1.2-2') : '0'}}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ActionType.RUECKGABE_PENSIONSTIER.key">
                                <ng-container *ngIf="transaction.owner !== null">
                                    <h6 class="col-12 mt-3">Besitzer:</h6>
                                    <div class="col-md-3">
                                        <div class="person-number font-weight-bolder">Personennummer:
                                            <span>{{transaction.owner.id}}</span></div>
                                        <div>{{transaction.owner.lastName}} {{transaction.owner.firstName}}</div>
                                        <div>{{transaction.owner.street}}</div>
                                        <div>{{transaction.owner.zip}} - {{transaction.owner.city}}</div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-3">Geburtsdatum:</div>
                                            <div class="col-3 ml-2">{{transaction.owner.birthday | date:'dd.MM.yyyy' }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">Telefonnummer:</div>
                                            <div class="col-3  ml-2">{{transaction.owner.phone}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">Nachweis:</div>
                                            <div class="col-3 ml-2">{{transaction.owner.proof}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">Aussteller:</div>
                                            <div class="col-3 ml-2">{{transaction.owner.issuer}}</div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="col-12 mt-3">
                                    Kosten pro Tag: {{transaction.costsPerDayTotal | currency:'EUR':'symbol':'1.2-2'}}
                                    <br />
                                    Anzahlung: {{transaction.depositTotal | currency:'EUR':'symbol':'1.2-2'}} <br />
                                    Sonstige Kosten: {{transaction.additionalCosts | currency:'EUR':'symbol':'1.2-2'}}
                                </div>
                                <div class="col-md-3 mt-2">
                                    Aufenthalt:
                                </div>
                                <div class="col-md-9 mt-2">
                                    Von {{transaction.stayFrom | date:'dd.MM.yyyy'}}
                                    bis {{transaction.stayUntil | date:'dd.MM.yyyy'}} <br />
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ActionType.HIMMELFAHRT.key">
                                <div class="col-12">
                                    Todesursache: {{transaction.deathReason}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <button class="float-right" mat-raised-button color="primary" (click)="cancel()">
                        Schließen
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
