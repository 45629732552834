import {FormControl} from '@angular/forms';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';
import {DropdownOption} from '../../data-types/dropdown-option';
import {isArray} from 'rxjs/internal-compatibility';

// select autocomplete, see https://www.npmjs.com/package/ngx-mat-select-search
export class SelectSearch {
    public control: FormControl = new FormControl();
    public filterCtrl: FormControl = new FormControl();
    public filteredElements: ReplaySubject<DropdownOption[]> = new ReplaySubject<DropdownOption[]>(1);
    public values: DropdownOption[];

    private singleSelect: MatSelect;
    private onDestroy = new Subject<void>();

    public init(singleSelect: MatSelect): void {
        this.singleSelect = singleSelect;

        // listen for search field value changes
        this.filterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterOptions();
            });
    }

    public setValues(options: Observable<DropdownOption[]> | DropdownOption[]): void {
        this.control.setValue('');
        if (isArray(options)) {
            this.values = [];
            this.values = options;
            this.filteredElements.next(options.slice());
        } else {
            options.pipe(
                takeUntil(this.onDestroy)
            ).subscribe(values => {
                this.values = [];
                this.values = values;
                this.filteredElements.next(values.slice());
            });
        }

    }

    public destroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }


    private filterOptions(): void {
        if (!this.values) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrl.value;
        if (!search) {
            this.filteredElements.next(this.values.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the options
        this.filteredElements.next(
            this.values.filter(option => option.viewValue.toLowerCase().indexOf(search) > -1)
        );
    }
}
