import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-abnahme',
    templateUrl: './abnahme.component.html',
    styleUrls: ['./abnahme.component.scss']
})
export class AbnahmeComponent implements OnInit {
    @Input() public acquisitionGiveAwayForm: FormGroup;

    ngOnInit(): void {
        this.acquisitionGiveAwayForm.addControl('takingOverReason', new FormControl('Abnahme durch Amtstierarzt.'));
        this.acquisitionGiveAwayForm.addControl('publicAuthority', new FormControl(''));
    }
}
