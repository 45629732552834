import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-vergabe',
    templateUrl: './vergabe.component.html',
    styleUrls: ['./vergabe.component.scss']
})
export class VergabeComponent implements OnInit {
    @Input() public acquisitionGiveAwayForm: FormGroup;
    public animalPlace = 'yes';

    ngOnInit(): void {
        this.acquisitionGiveAwayForm.addControl('animalPlace', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('residenceStreet', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('residenceZip', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('residenceCity', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('hasFence', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('hasGarden', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('otherAnimalsInHousehold', new FormControl(''));
    }
}
