import {ActionTypeEntryModel} from '../../../shared/models/action-type-entry.model';
import * as fromActionTypeActions from './action-types.actions';

export interface State {
    actionTypes: ActionTypeEntryModel[];
}

const initialState: State = {
    actionTypes: []
};

export function actionTypeReducer(state = initialState, action: fromActionTypeActions.ActionTypesActions): State {
    switch (action.type) {
        case fromActionTypeActions.SET_ACTION_TYPES:
            return {
                ...state,
                actionTypes: [...action.payload]
            };
        case fromActionTypeActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
