import {VeterinaryTreatmentEntryModel} from '../../../shared/models/veterinary-treatments/veterinary-treatment-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import * as fromVeterinaryTreamtentsActions from './veterinary-treatments.actions';

export interface State {
    veterinaryTreatments: VeterinaryTreatmentEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    veterinaryTreatments: [],
    operation: CRUD.NONE
};

export function veterinaryTreatmentsReducer(state = initialState,
                                            action: fromVeterinaryTreamtentsActions.VeterinaryTreatmentsActions): State {
    switch (action.type) {
        case fromVeterinaryTreamtentsActions.SET_VETERINARY_TREATMENTS:
            return {
                ...state,
                veterinaryTreatments: [...action.payload.treatments],
                operation: action.payload.crud
            };
        case fromVeterinaryTreamtentsActions.ADD_VETERINARY_TREATMENT:
            return {
                ...state,
                veterinaryTreatments: [...state.veterinaryTreatments, action.payload.treatment]
            };
        case fromVeterinaryTreamtentsActions.UPDATE_VETERINARY_TREATMENT:
            const updatedVeterinaryTreatment = {
                ...state.veterinaryTreatments.find(veterinaryTreatment => veterinaryTreatment.id === action.payload.treatment.id),
                ...action.payload
            };

            const updatedVeterinaryTreatments = [...state.veterinaryTreatments];
            const arrIndex = updatedVeterinaryTreatments.findIndex(element => element.id === updatedVeterinaryTreatment.id);
            updatedVeterinaryTreatments[arrIndex] = updatedVeterinaryTreatment;

            return {
                ...state,
                veterinaryTreatments: updatedVeterinaryTreatments
            };
        case fromVeterinaryTreamtentsActions.DELETE_VETERINARY_TREATMENT:
            return {
                ...state,
                veterinaryTreatments: state.veterinaryTreatments
                    .filter(veterinaryTreatment => veterinaryTreatment.id !== action.payload.treatmentId)
            };
        case fromVeterinaryTreamtentsActions.HTTP_FAIL:
            return {
                ...state,
                operation: CRUD.NONE
            };
        default:
            return state;
    }
}
