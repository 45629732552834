import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {LoggingService} from './logging.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
    private loggingService: LoggingService;

    constructor(private injector: Injector) {
        super();
        setTimeout(() => this.loggingService = injector.get(LoggingService));
    }

    public handleError(error: any): void {
        this.loggingService?.addLogging(this.createErrorMsg(error));
        super.handleError(error);
    }

    private createErrorMsg(error: any): string {
        let message = '';
        if (typeof error.message !== 'undefined') {
            message += error.message;
        }

        if (typeof error.stack !== 'undefined') {
            message += error.stack;
        }

        return message;
    }
}
