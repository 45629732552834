import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActionsService} from '../../actions/actions.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {Order} from '../../shared/controls/data-table/ordering';
import {AnimalsService} from '../../animals/animals.service';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {ActionTypesService} from '../../animals/store/action-types/action-types.service';
import {ActionTypeEntryModel} from '../../shared/models/action-type-entry.model';
import {MediaService} from '../../media-centre/media.service';
import {Actions, ofType} from '@ngrx/effects';
import {MediaEntryModel} from '../../shared/models/media-entry.model';
import {CastrationStatus, Gender} from '../../shared/constants';
import {DictionaryEntryModel} from '../../shared/models/dictionary-entry.model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import * as fromActionsActions from '../../actions/store/actions.actions';

@Component({
    selector: 'app-associated-animals',
    templateUrl: './associated-animals.component.html',
    styleUrls: ['./associated-animals.component.scss']
})
export class AssociatedAnimalsComponent implements OnInit, OnDestroy {
    @ViewChild('paginator') public paginator: MatPaginator;
    public selectedPerson: PersonEntryModel;
    public transactions: TransactionEntryModel[];
    public Gender = Gender;
    public CastrationStatus = CastrationStatus;
    public requestFinished = false;
    public totalActions = 0;
    public entriesPerPage = 10;
    public pageIndex = 1;
    public order = Order.DESC;
    public orderedColumn = 'date_performed';

    private actionTypes: ActionTypeEntryModel[];
    private animalSub: Subscription;
    private mediaSub: Subscription;
    private setActionsSub: Subscription;
    private fetchTransactionSub: Subscription;
    private avatars: MediaEntryModel[];

    constructor(private actionsService: ActionsService,
                private animalsService: AnimalsService,
                private mediaService: MediaService,
                private actions$: Actions,
                private actionTypeService: ActionTypesService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.selectedPerson = data.selectedPerson;
        this.actionTypes = data.actionTypes;
    }

    ngOnInit(): void {
        this.fetchTransactions();
    }

    ngOnDestroy(): void {
        if (this.animalSub) {
            this.animalSub.unsubscribe();
        }
        if (this.mediaSub) {
            this.mediaSub.unsubscribe();
        }
        if (this.setActionsSub) {
            this.setActionsSub.unsubscribe();
        }
        if (this.fetchTransactionSub) {
            this.fetchTransactionSub.unsubscribe();
        }
    }

    public getActionType(id): ActionTypeEntryModel {
        return this.actionTypes.find(at => at.id === id);
    }

    public getMedia(avatarId: number): MediaEntryModel {
        if (avatarId !== null &&
            typeof avatarId !== 'undefined' &&
            this.avatars !== null &&
            typeof this.avatars !== 'undefined') {
            return this.avatars.find(a => a.id === avatarId);
        }
        return null;
    }

    public displayAttributes(attributes: DictionaryEntryModel[]): string {
        let strAttributes = '';
        attributes.forEach(attribute => {
            strAttributes += attribute.name + ', ';
        });
        return strAttributes.slice(0, -2);
    }

    public switchPage($event: PageEvent): void {
        const filterModel = {
            personId: this.selectedPerson.id
        };
        this.pageIndex = $event.pageIndex + 1;
        this.actionsService
            .fetchActions(this.entriesPerPage, this.pageIndex, this.order, this.orderedColumn, filterModel)
            .subscribe(transactions => {
                this.transactions = transactions;
            });
    }

    private fetchTransactions(): void {
        const filterModel = {
            personId: this.selectedPerson.id
        };

        this.fetchTransactionSub = this.actionsService
            .fetchActions(this.entriesPerPage,
                this.pageIndex,
                this.order,
                this.orderedColumn,
                filterModel)
            .subscribe((transactions: TransactionEntryModel[]) => {
                this.transactions = transactions;
            });

        this.setActionsSub = this.actions$.pipe(
            ofType(fromActionsActions.SET_ACTIONS)
        ).subscribe((actionState: fromActionsActions.SetActions) => {
            this.totalActions = actionState.payload.totalElements;
            this.requestFinished = true;
            const imageIds = [];
            this.transactions = actionState.payload.actions;
            this.transactions.forEach(transaction => {
                const avatarId = transaction.animal.avatarId;
                if (typeof avatarId !== 'undefined' && !imageIds.includes(avatarId)) {
                    imageIds.push(avatarId);
                }
            });

            this.mediaSub = this.mediaService
                .fetchMultipleMedia(imageIds, true)
                .subscribe(media => {
                    this.avatars = media;
                });
        });
    }
}
