import {Action} from '@ngrx/store';
import {BookingEntryModel} from '../../../shared/models/pos/booking-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {Order} from '../../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_BOOKINGS = '[Bookings] Load Bookings';
export const SET_BOOKINGS = '[Bookings] Set Bookings';
export const ADD_BOOKING = '[Bookings] Add Booking';
export const ADD_BOOKINGS = '[Bookings] Add Bookings';
export const UPDATE_BOOKING = '[Bookings] Update Booking';
export const FINISHED_CRUD = '[Bookings] Finished Crud';
export const LOAD_CASH_POSITION = '[Bookings] Load Cash Position';
export const SET_CASH_POSITION = '[Bookings] Set Cash Position';
export const HTTP_FAIL = '[Bookings] Http Fail';

export class LoadBookings implements Action {
    readonly type = LOAD_BOOKINGS;

    constructor(public payload: {
        crud: CRUD,
        size: number,
        page: number,
        start: string,
        end: string,
        order: Order,
        column: string,
        grouped: boolean
    }) {
    }
}

export class SetBookings implements Action {
    readonly type = SET_BOOKINGS;

    constructor(public payload: {
        bookings: BookingEntryModel[],
        totalElements: number, cashPosition: number,
        operation: CRUD
    }) {
    }
}

export class AddBooking implements Action {
    readonly type = ADD_BOOKING;

    constructor(public payload: {
        booking: BookingEntryModel,
        size: number,
        page: number,
        order: Order,
        column: string,
        setCancelled: boolean,
        start: string,
        end: string
    }) {
    }
}

export class AddBookings implements Action {
    readonly type = ADD_BOOKINGS;

    constructor(public payload: {
        bookings: BookingEntryModel[],
        start: string,
        end: string,
        size: number,
        page: number,
    }) {
    }
}

export class UpdateBooking implements Action {
    readonly type = UPDATE_BOOKING;

    constructor(public payload: {
        booking: BookingEntryModel,
        size: number,
        page: number,
        order: Order,
        column: string,
        setCancelled: boolean,
        grouped: boolean,
        start: string,
        end: string
    }) {
    }
}

export class FinishedCrud implements Action {
    readonly type = FINISHED_CRUD;

    constructor(public payload: CRUD) {
    }
}

export class LoadCashPosition implements Action {
    readonly type = LOAD_CASH_POSITION;

    constructor(public end: string) {
    }
}

export class SetCashPosition implements Action {
    readonly type = SET_CASH_POSITION;

    constructor(public payload: number) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type BookingActions =
    LoadBookings
    | SetBookings
    | AddBooking
    | AddBookings
    | UpdateBooking
    | FinishedCrud
    | LoadCashPosition
    | SetCashPosition
    | HttpFail;
