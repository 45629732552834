import {ArticleEntryModel} from '../../../shared/models/pos/article-entry.model';
import * as ArticlesActions from './articles.actions';
import {CRUD} from '../../../shared/services/http/crud';

export interface State {
    articles: ArticleEntryModel[];
    operation: CRUD;
}

const initialState: State = {
    articles: [],
    operation: CRUD.NONE
};

export function articlesReducer(state = initialState, action: ArticlesActions.ArticlesActions): State {
    switch (action.type) {
        case ArticlesActions.SET_ARTICLES:
            return {
                ...state,
                articles: [...action.payload.articles],
                operation: action.payload.crud
            };
        case ArticlesActions.ADD_ARTICLE:
            return {
                ...state,
                articles: [...state.articles, action.payload.article]
            };
        case ArticlesActions.UPDATE_ARTICLE:
            const updatedArticle = {
                ...state.articles.find(article => article.id === action.payload.article.id),
                ...action.payload
            };

            const updatedArticles = [...state.articles];
            const arrIndex = updatedArticles.findIndex(element => element.id === updatedArticle.id);
            updatedArticles[arrIndex] = updatedArticle;

            return {
                ...state,
                articles: updatedArticles
            };
        case ArticlesActions.DELETE_ARTICLE:
            return {
                ...state,
                articles: state.articles.filter(article => article.id !== action.payload.articleId)
            };
        case ArticlesActions.HTTP_FAIL:
            return {
                ...state,
                operation: CRUD.NONE
            };
        default:
            return state;
    }
}
