import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {Observable} from 'rxjs';
import {CRUD} from '../../../shared/services/http/crud';
import * as fromAccommodationActions from './accommodation.actions';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class AccommodationsEffects {
    @Effect()
    loadAccommodations$ = this.actions$.pipe(
        ofType(fromAccommodationActions.LOAD_ACCOMMODATIONS),
        switchMap((accommodationState: fromAccommodationActions.LoadAccommodations) => {
            return this.endpointService.accommodations(HttpMethod.GET).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .get<any>(endpoint).pipe(
                            map(response => response.data)
                        );
                }),
                map(accommodations => {
                    return new fromAccommodationActions.SetAccommodations({
                        accommodations,
                        operation: accommodationState.payload
                    });
                }),
                catchError(error => {
                    return this.handleError(error);
                })
            );
        })
    );

    @Effect()
    addAccommodation$ = this.actions$.pipe(
        ofType(fromAccommodationActions.ADD_ACCOMMODATION),
        switchMap((accommodationState: fromAccommodationActions.AddAccommodation) => {
            return this.endpointService.accommodations(HttpMethod.POST).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .post<any>(endpoint,
                            {
                                name: accommodationState.payload.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromAccommodationActions.LoadAccommodations(CRUD.CREATE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    updateAccommodation$ = this.actions$.pipe(
        ofType(fromAccommodationActions.UPDATE_ACCOMMODATION),
        switchMap((accommodationState: fromAccommodationActions.UpdateAccommodation) => {
            return this.endpointService.accommodations(HttpMethod.PUT, accommodationState.payload.id).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .put<any>(endpoint,
                            {
                                name: accommodationState.payload.name
                            }
                        ).pipe(
                            map(() => {
                                return new fromAccommodationActions.LoadAccommodations(CRUD.UPDATE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );

    @Effect()
    deleteAccommodation$ = this.actions$.pipe(
        ofType(fromAccommodationActions.DELETE_ACCOMMODATION),
        switchMap((categoryState: fromAccommodationActions.DeleteAccommodation) => {
            return this.endpointService.accommodations(HttpMethod.DELETE, categoryState.payload).pipe(
                take(1),
                switchMap(endpoint => {
                    return this.http
                        .delete<any>(endpoint)
                        .pipe(
                            map(() => {
                                return new fromAccommodationActions.LoadAccommodations(CRUD.DELETE);
                            }),
                            catchError(error => {
                                return this.handleError(error);
                            })
                        );
                })
            );
        })
    );


    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }


    private handleError = (errorRes: any): Observable<fromAccommodationActions.HttpFail> => {
        return handleHTTPError(errorRes, fromAccommodationActions, 'Unterkünften', this.loggingService);
    };

}
