import {AnimalEntryModel} from '../../../shared/models/animal/animal-entry.model';
import * as AnimalListActions from './animal.actions';

export interface State {
    animals: AnimalEntryModel[];
}

const initialState: State = {
    animals: []
};

export function animalReducer(state: State = initialState, action: AnimalListActions.AnimalActions): State {
    switch (action.type) {
        case AnimalListActions.SET_ANIMALS:
            return {
                ...state,
                animals: [...action.payload.animals]
            };
        case AnimalListActions.ADD_ANIMAL:
            return {
                ...state,
                animals: [...state.animals, action.payload.animal]
            };
        case AnimalListActions.UPDATE_ANIMAL:
            const updatedAnimal = {
                ...state.animals.find(animal => animal.id === action.payload.animal.id),
                ...action.payload.animal
            };

            const updatedAnimals = [
                ...state.animals
            ];
            updatedAnimals[action.payload.animal.id] = updatedAnimal;

            return {
                ...state,
                animals: updatedAnimals
            };
        case AnimalListActions.DELETE_ANIMAL:
            return {
                ...state,
                animals: state.animals.filter(animal => animal.id !== action.payload.id)
            };
        case AnimalListActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
