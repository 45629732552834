<div class="title-bar">
    <h3>Tierattribute</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Alle tierartabhängigen Attribute</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Tierart</mat-label>
                                <mat-select #singleSelectSpecies
                                            (selectionChange)="selectSpecies($event)">
                                    <mat-option>
                                        <ngx-mat-select-search
                                                [formControl]="selectSearchSpecies.filterCtrl">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                            *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                            [value]="species">
                                        {{species.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="chip-list">
                        <div id="all-attributes-wrapper" class="row">
                            <ng-container *ngFor="let attribute of attributes$ | async">
                                <div class="col-md-3 mt-5">
                                    <div class="chip-options-wrapper d-flex flex-column">
                                        <div class="mat-chip mat-chip-select-title mat-standard-chip mat-chip-with-trailing-icon"
                                             #chip>
                                            <div class="option d-flex justify-content-between"
                                                 [attr.data-value]="SelectBoxUtility.getOptionsValue(attribute.name)">
                                                <div class="text-wrapper">
                                                    <div class="text-element">{{attribute.name}}</div>
                                                    <input class="d-none"
                                                           matInput
                                                           #updatedVal
                                                           [value]="attribute.name"
                                                           (click)="$event.stopPropagation()"
                                                           (keydown.enter)="updateAttribute(chip, attribute, updatedVal)" />
                                                </div>
                                                <div class="controls d-flex align-items-center">
                                                    <a class="toggle-datasheet-visibility icon"
                                                       #datasheetToggler
                                                       [ngClass]="attribute.datasheetVisibility ? 'active':''"
                                                       (click)="toggleDatasheetVisibility(attribute, datasheetToggler)"
                                                       matTooltip="Auf Datenblatt anzeigen"
                                                       matTooltipPosition="right"
                                                       matTooltipClass="tooltip-popup"></a>
                                                    <a class="fas fa-edit icon"
                                                       (click)="chipListService.activateEditMode($event, chip)"
                                                       matTooltip="Attribut bearbeiten"
                                                       matTooltipPosition="right"
                                                       matTooltipClass="tooltip-popup"></a>
                                                    <a class="fas fa-check icon d-none"
                                                       (click)="updateAttribute(chip, attribute, updatedVal)"
                                                       matTooltip="Änderungen speichern"
                                                       matTooltipPosition="right"
                                                       matTooltipClass="tooltip-popup"></a>
                                                    <a class=" fas fa-trash icon"
                                                       (click)="deleteAttribute(attribute)"
                                                       matTooltip="Attribut löschen"
                                                       matTooltipPosition="right"
                                                       matTooltipClass="tooltip-popup"></a>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let option of attribute.options; let i = index">
                                            <app-attributes-option [option]="option"
                                                                   [speciesGroupName]="speciesGroupName"
                                                                   [attributeId]="attribute.id"
                                                                   [addIcon]="addIcon"
                                                                   [addInput]="addInput">
                                                <div class="position-relative"
                                                     [hidden]="i < attribute.options.length - 1">
                                                    <mat-form-field class="text-input mt-2 confirm-input">
                                                        <mat-label>Option hinzufügen</mat-label>
                                                        <input matInput
                                                               #addInput
                                                               placeholder="Option hinzufügen">
                                                        <a class="d-none fas fa-check icon position-absolute"
                                                           #addIcon
                                                           matTooltip="Option erstellen"
                                                           matTooltipPosition="right"
                                                           matTooltipClass="tooltip-popup"></a>
                                                    </mat-form-field>
                                                </div>
                                            </app-attributes-option>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <div id="new-attribute-container" class="col-md-3" *ngIf="showAddAttribute">
                                <form [formGroup]="newAttributeForm"
                                      [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                                    <mat-form-field class="text-input mt-2 ml-0">
                                        <mat-label>Attribut hinzufügen</mat-label>
                                        <input type="text"
                                               #newAttributeInput
                                               matInput
                                               placeholder="Attribut hinzufügen"
                                               formControlName="newAttribute"
                                               (input)="isValid(newAttributeInput, newOptionInput)"
                                               (keydown.enter)="addAttribute()">
                                        <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field class="text-input mt-2 ml-0 confirm-input">
                                        <mat-label>Option hinzufügen</mat-label>
                                        <input type="text"
                                               #newOptionInput
                                               matInput
                                               placeholder="Option hinzufügen"
                                               formControlName="newOption"
                                               (input)="isValid(newAttributeInput, newOptionInput)"
                                               (keydown.enter)="addAttribute()">
                                        <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        <a class="d-none fas fa-check icon position-absolute"
                                           (click)="addAttribute()"
                                           #addAttributeIcon
                                           matTooltip="Attribut erstellen"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"></a>
                                    </mat-form-field>
                                </form>
                            </div>

                            <div id="add-attribute-control"
                                 class="col-md-3 d-flex align-items-center justify-content-center"
                                 *ngIf="speciesGroupName !== ''">
                                <div class="add-attribute d-flex justify-content-center mt-2">
                                    <a class="add-btn really-big-btn fas fa-plus d-flex justify-content-center align-items-center"
                                       matTooltip="Attribut hinzufügen"
                                       matTooltipPosition="right"
                                       matTooltipClass="tooltip-popup"
                                       (click)="showAddAttributeInput()"></a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
