import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import 'rxjs-compat/add/operator/take';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getGermanPaginatorIntl} from './shared/controls/data-table/german-paginator-intl';
import * as fromApp from './store/app.reducer';
import * as fromAuthActions from './/auth/store/auth.actions';
import {Actions, ofType} from '@ngrx/effects';
import {AuthService} from './auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()}
    ]
})
export class AppComponent implements OnInit {
    constructor(private actions$: Actions,
                private authService: AuthService,
                private store: Store<fromApp.AppState>
    ) {
    }

    ngOnInit(): void {
        this.store.dispatch(new fromAuthActions.GetLoggedInUser());
        this.actions$.pipe(
            ofType(fromAuthActions.SET_USER)
        ).subscribe((response: any) => {
            this.authService.loggedInUser.next(response.payload);
        });
    }
}
