<div class="title-bar position-relative">
    <h3 mat-dialog-title>
        <span id="name">{{selectedPerson.firstName}} {{selectedPerson.lastName}}</span>
        <span class="ml-4">PNr.: <span>{{selectedPerson.id}}</span></span>
    </h3>
    <div class="fa fa-close float-right mt-1 position-absolute" mat-button
         [mat-dialog-close]="true" aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-body">
            <ng-container *ngIf="transactions !== undefined && transactions.length > 0">
                <div class="action-entry" *ngFor="let transaction of transactions">
                    <div class="header d-flex justify-content-between">
                        <ng-container [ngTemplateOutlet]="action"
                                      [ngTemplateOutletContext]="{actionType: getActionType(transaction.actionTypeId)}"></ng-container>
                        <ng-template #action let-actionType="actionType">
                            <h4><span>{{actionTypeService.getActionTypeText(actionType.name)}}</span>
                                <div class="transaction.animal-type ml-2 findling"
                                     [ngStyle]="{'background-color': actionType.color}"></div>
                            </h4>
                        </ng-template>
                        <div>
                            <div class="transaction-number">Transaktionsnummer: {{transaction.transactionId}}</div>
                            <div>{{transaction.date | date:'dd.MM.yyyy'}}</div>
                        </div>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-4">
                                <div class="row laufnummer">
                                    <div class="col-5">Laufnummer:</div>
                                    <div class="col-7">{{transaction.animal.id}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-5">Name:</div>
                                    <div class="col-7">{{transaction.animal.name}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-5">Art:</div>
                                    <div class="col-7">{{transaction.animal.species.name}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-5">Rasse:</div>
                                    <div class="col-7">{{transaction.animal.race.name}}</div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-5">Geschlecht:</div>
                                    <div class="col-7">{{Gender.getGender(transaction.animal.gender)}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-5">Kastration:</div>
                                    <div class="col-7">{{CastrationStatus.getCastrationStatus(transaction.animal.castrated)}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-5">Geburtsdatum:</div>
                                    <div class="col-7">{{transaction.animal.birthday | date:'dd.MM.yyyy'}}</div>
                                </div>
                                <div class="row" *ngIf="getActionType(transaction.actionTypeId).name === 'abgabe'">
                                    <div class="col-5">Abgabegrund:</div>
                                    <div class="col-7">zu viele Tiere</div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-5">Merkmale:</div>
                                    <div class="col-7">{{displayAttributes(transaction.animal.characteristics)}}</div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-5">Besondere Kennzeichen:</div>
                                    <div class="col-7">{{transaction.animal.specialCriteria}}</div>
                                </div>
                            </div>
                            <div class="col-3">
                                <ng-container [ngTemplateOutlet]="avatar"
                                              [ngTemplateOutletContext]="{media: getMedia(transaction.animal.avatarId)}"></ng-container>
                                <ng-template #avatar let-media="media">
                                    <ng-container *ngIf="media !== null">
                                        <img class="avatar position-absolute"
                                             title="{{media.title}}"
                                             alt="{{media.altText}}"
                                             [src]="'data:image/jpg;base64, ' + media.data | safeHtml">
                                    </ng-container>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="requestFinished && (transactions === undefined || transactions.length === 0)">
                <div class="default-entry mt-2">
                    Keine Ergebnisse gefunden.
                </div>
            </ng-container>
            <ng-container *ngIf="!requestFinished">
                <div class="default-entry mt-2 d-flex justify-content-center align-items-center">
                    <mat-progress-spinner
                            color="accent"
                            mode="indeterminate">
                    </mat-progress-spinner>
                </div>
            </ng-container>

            <div id="bottom-controls"
                 class="controls d-flex justify-content-between align-items-baseline">
                <div id="total-results">Gesamt: <span>{{totalActions}}</span></div>
                <mat-paginator [length]="totalActions"
                               [pageSize]="entriesPerPage"
                               (page)="switchPage($event)"
                               #paginator
                               showFirstLastButtons
                               hidePageSize>
                </mat-paginator>
            </div>

        </div>
    </div>
</mat-dialog-content>
