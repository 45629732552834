import {Action} from '@ngrx/store';
import {AttributeEntryModel} from '../../../shared/models/attributes/attribute-entry.model';
import {ErrorTypes} from '../../../shared/data-types/error-types';

export const LOAD_ATTRIBUTES = '[Attributes] Load Attributes';
export const SET_ATTRIBUTES = '[Attributes] Set Attribute';
export const ADD_ATTRIBUTE = '[Attributes] Add Attribute';
export const UPDATE_ATTRIBUTE = '[Attributes] Update Attribute';
export const DELETE_ATTRIBUTE = '[Attributes] Delete Attribute';
export const HTTP_FAIL = '[Attributes] Http Fail';

export class LoadAttributes implements Action {
    readonly type = LOAD_ATTRIBUTES;

    constructor(public payload: string) {
    }
}

export class SetAttributes implements Action {
    readonly type = SET_ATTRIBUTES;

    constructor(public payload: AttributeEntryModel[]) {
    }
}

export class AddAttributes implements Action {
    readonly type = ADD_ATTRIBUTE;

    constructor(public payload: AttributeEntryModel) {
    }
}

export class UpdateAttribute implements Action {
    readonly type = UPDATE_ATTRIBUTE;

    constructor(public payload: AttributeEntryModel) {
    }
}

export class DeleteAttribute implements Action {
    readonly type = DELETE_ATTRIBUTE;

    constructor(public payload: AttributeEntryModel) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type AttributesActions =
    LoadAttributes
    | SetAttributes
    | AddAttributes
    | UpdateAttribute
    | DeleteAttribute
    | HttpFail;

