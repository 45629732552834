import {Component, Input} from '@angular/core';
import {PersonEntryModel} from '../../../shared/models/person-entry.model';
import {SupervisionService} from '../../supervision.service';
import {SimpleDialogComponent} from '../../../dialogs/simple-dialog/simple-dialog.component';
import {DialogType} from '../../../dialogs/dialog-type';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-former-supervision-content',
    templateUrl: './former-supervision-content.component.html',
    styleUrls: ['./former-supervision-content.component.scss']
})
export class FormerSupervisionContentComponent {
    @Input() id: number;
    @Input() animalId: number;
    @Input() person: PersonEntryModel;
    @Input() datePerformed: string;
    @Input() supervisor: string;
    @Input() report: string;
    public simpleDialogType = new DialogType();

    constructor(private supervisionService: SupervisionService,
                private dialog: MatDialog) {
    }

    public deleteSupervision(): void {
        const dialogRef = this.dialog.open(SimpleDialogComponent, {
            width: '900px',
            panelClass: 'component-wrapper',
            data: {
                type: this.simpleDialogType.DELETE_GENERIC,
                entity: 'Kontrolle',
                identifier: 'Kontrolle ' + this.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.supervisionService.deleteSupervision(this.id, 1, this.animalId);
            }
        });
    }
}
