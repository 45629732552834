import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EndpointService, HttpMethod} from '../../../shared/services/http/endpoint.service';
import {BookingEntryModel} from '../../../shared/models/pos/booking-entry.model';
import {CRUD} from '../../../shared/services/http/crud';
import {Observable} from 'rxjs';
import {Order} from '../../../shared/controls/data-table/ordering';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import * as moment from 'moment';
import * as fromBookingActions from './booking.actions';
import {LoggingService} from '../../../shared/logging/logging.service';
import {handleHTTPError} from '../../../shared/error-handling';

@Injectable()
export class BookingEffects {
    @Effect()
    loadBookings$ = this.actions$.pipe(
        ofType(fromBookingActions.LOAD_BOOKINGS),
        switchMap((bookingState: fromBookingActions.LoadBookings) => {
            const start = typeof bookingState.payload.start === 'undefined' || bookingState.payload.start === '' ?
                moment().set({
                    hour: 0,
                    minute: 0,
                    second: 0
                }).format('YYYY-MM-DD HH:mm:ss') : bookingState.payload.start;
            const end = typeof bookingState.payload.end === 'undefined' || bookingState.payload.end === '' ?
                moment().set({
                    hour: 23,
                    minute: 59,
                    second: 59
                }).format('YYYY-MM-DD HH:mm:ss') : bookingState.payload.end;
            const page = bookingState.payload.page;
            const size = bookingState.payload.size;
            const order = bookingState.payload.order;
            const orderColumn = bookingState.payload.column;
            const grouped = bookingState.payload.grouped;

            let params = new HttpParams();
            params = params.append('start_date', start);
            params = params.append('end_date', end);

            if (page >= 0 && size >= 0) {
                params = params.append('page', page.toString());
                params = params.append('size', size.toString());
            }

            if (order !== Order.NONE) {
                let value = order === Order.DESC ? '-' : '';
                value += orderColumn;
                params = params.append('order', value);
            }

            if (grouped) {
                params = params.append('grouped', grouped + '');
            }

            const bookingRequest$ = this.endpointService.bookings(HttpMethod.GET)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {params});
                    })
                );

            const obsArr$ = [bookingRequest$];
            if (end !== '') {
                const cashObs$ = this.endpointService.cashPosition(end)
                    .pipe(
                        take(1),
                        switchMap(endpoint => {
                            return this.http
                                .get<any>(endpoint, {});
                        })
                    );
                obsArr$.push(cashObs$);
            }

            return forkJoin(obsArr$).pipe(
                take(1),
                map(([bookingsResponse, cashPositionResp]) => {
                    const totalElements = bookingsResponse.data.pagination.totalElements;
                    let mappedBookingArr = null;
                    if (grouped) {
                        mappedBookingArr = bookingsResponse.data.data.map(booking => {
                            const mappedBooking = {
                                category: booking.category,
                                income: booking.income,
                                outcome: booking.outcome,
                                delta: booking.delta,
                                atm: booking.atm
                            };
                            return mappedBooking;
                        });
                    } else {
                        mappedBookingArr = bookingsResponse.data.data.map(booking => {
                            const mappedBooking: BookingEntryModel = {
                                id: booking.id,
                                article: booking.article,
                                category: booking.category,
                                type: booking.type,
                                description: booking.description,
                                remark: booking.description_2,
                                cashier: booking.cashier,
                                price: booking.price,
                                isATM: booking.is_atm,
                                dateCreated: booking.date_created,
                                cancellationUser: booking.cancellation_user,
                                cancellationReason: booking.cancellation_reason,
                                dateCancelled: booking.date_cancelled
                            };
                            return mappedBooking;
                        });
                    }

                    return new fromBookingActions.SetBookings({
                        bookings: mappedBookingArr,
                        totalElements,
                        cashPosition: +cashPositionResp.data.cash_position,
                        operation: bookingState.payload.crud
                    });
                }),
                catchError(errorRes => {
                    return this.handleError(errorRes);
                })
            );
        })
    );

    @Effect()
    addBooking$ = this.actions$
        .pipe(
            ofType(fromBookingActions.ADD_BOOKING),
            switchMap((bookingState: fromBookingActions.AddBooking) => {
                return this.endpointService.bookings(HttpMethod.POST)
                    .pipe(
                        take(1),
                        switchMap(endpoint => {
                            return this.http
                                .post<any>(endpoint,
                                    {
                                        date_created: bookingState.payload.booking.dateCreated,
                                        type: bookingState.payload.booking.type,
                                        article: bookingState.payload.booking.article,
                                        category: bookingState.payload.booking.category,
                                        price: bookingState.payload.booking.price.toString(),
                                        description: bookingState.payload.booking.description,
                                        description_2: bookingState.payload.booking.remark,
                                        is_atm: bookingState.payload.booking.isATM,
                                        cashier: bookingState.payload.booking.cashier,
                                        cancellation_user: bookingState.payload.booking.cancellationUser,
                                        cancellation_reason: bookingState.payload.booking.cancellationReason,
                                        is_cancellation: bookingState.payload.setCancelled + ''
                                    }
                                ).pipe(
                                    map(() => {
                                        return new fromBookingActions.LoadBookings({
                                            crud: CRUD.CREATE,
                                            size: bookingState.payload.size,
                                            page: bookingState.payload.page,
                                            order: Order.NONE,
                                            column: '',
                                            start: bookingState.payload.start,
                                            end: bookingState.payload.end,
                                            grouped: false
                                        });
                                    }),
                                    catchError(errorRes => {
                                        return this.handleError(errorRes);
                                    })
                                );
                        })
                    );
            })
        );

    @Effect()
    addBookings$ = this.actions$
        .pipe(
            ofType(fromBookingActions.ADD_BOOKINGS),
            switchMap((bookingState: fromBookingActions.AddBookings) => {
                const obsArr$: Observable<any>[] = [];
                bookingState.payload.bookings.forEach(entry => {
                    const obs$ = this.endpointService.bookings(HttpMethod.POST)
                        .pipe(
                            take(1),
                            switchMap(endpoint => {
                                return this.http
                                    .post<any>(endpoint,
                                        {
                                            date_created: entry.dateCreated,
                                            type: entry.type,
                                            article: entry.article,
                                            category: entry.category,
                                            price: entry.price.toString(),
                                            description: entry.description,
                                            description_2: entry.remark,
                                            is_atm: entry.isATM,
                                            cashier: entry.cashier,
                                        }
                                    );
                            })
                        );
                    obsArr$.push(obs$);
                });

                return forkJoin(obsArr$).pipe(
                    take(1),
                    map(() => {
                        return new fromBookingActions.LoadBookings({
                            crud: CRUD.CREATE,
                            size: bookingState.payload.size,
                            page: bookingState.payload.page,
                            order: Order.NONE,
                            column: '',
                            start: bookingState.payload.start,
                            end: bookingState.payload.end,
                            grouped: false
                        });
                    }),
                    catchError(errorRes => {
                        return this.handleError(errorRes);
                    })
                );
            })
        );

    @Effect()
    updateBooking$ = this.actions$
        .pipe(
            ofType(fromBookingActions.UPDATE_BOOKING),
            switchMap((bookingState: fromBookingActions.UpdateBooking) => {
                return this.endpointService.bookings(HttpMethod.PUT, bookingState.payload.booking.id)
                    .pipe(
                        take(1),
                        switchMap(endpoint => {
                            return this.http
                                .put<any>(endpoint,
                                    {
                                        cancellation_user: bookingState.payload.booking.cancellationUser,
                                        cancellation_reason: bookingState.payload.booking.cancellationReason,
                                        is_cancellation: bookingState.payload.setCancelled + ''
                                    }
                                ).pipe(
                                    map(() => {
                                        return new fromBookingActions.LoadBookings({
                                            crud: CRUD.UPDATE,
                                            size: bookingState.payload.size,
                                            page: bookingState.payload.page,
                                            start: bookingState.payload.start,
                                            end: bookingState.payload.end,
                                            order: Order.NONE,
                                            column: '',
                                            grouped: bookingState.payload.grouped
                                        });
                                    }),
                                    catchError(errorRes => {
                                        return this.handleError(errorRes);
                                    })
                                );
                        })
                    );
            })
        );

    @Effect()
    cashPositionRequest$ = this.actions$.pipe(
        ofType(fromBookingActions.LOAD_CASH_POSITION),
        switchMap((bookingState: fromBookingActions.LoadCashPosition) => {
            return this.endpointService.cashPosition(bookingState.end)
                .pipe(
                    take(1),
                    switchMap(endpoint => {
                        return this.http
                            .get<any>(endpoint, {});
                    }),
                    map((response) => {
                        const cashPosition = +response.data.cash_position;
                        return new fromBookingActions.SetCashPosition(cashPosition);
                    })
                );
        })
    );

    constructor(private actions$: Actions,
                private http: HttpClient,
                private endpointService: EndpointService,
                private loggingService: LoggingService) {

    }

    private handleError = (errorRes: any): Observable<fromBookingActions.HttpFail> => {
        return handleHTTPError(errorRes, fromBookingActions, 'Buchungen', this.loggingService);
    };
}
