import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {PersonsService} from '../persons.service';
import {Order} from '../../shared/controls/data-table/ordering';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {Subscription} from 'rxjs';
import {PersonFormComponent} from '../person-form/person-form.component';
import {ActionsService} from '../../actions/actions.service';
import {Actions, ofType} from '@ngrx/effects';
import * as fromActionAction from '../../actions/store/actions.actions';
import {TransactionEntryModel} from '../../shared/models/transaction-entry.model';
import {SnackStatusType} from '../../shared/components/snackbar/snack-status-type';
import {SnackbarService} from '../../shared/components/snackbar/snackbar.service';
import {CareSpaceService} from '../../animals/store/care-space/care-space.service';
import {CareSpaceEntryModel} from '../../shared/models/animal/care-space-entry.model';
import {take} from 'rxjs/operators';
import {CRUD} from '../../shared/services/http/crud';
import {ExternalApiService} from '../../shared/services/external-api.service';

@Component({
    selector: 'app-transfer-person-data',
    templateUrl: './transfer-person-data.component.html',
    styleUrls: ['./transfer-person-data.component.scss']
})
export class TransferPersonDataComponent implements OnInit, OnDestroy {
    @ViewChildren(PersonFormComponent) personFormComponents: QueryList<PersonFormComponent>;
    public displayErroneousInputs = false;
    public requestSent = false;
    public sourcePerson: PersonEntryModel;
    public targetPerson: PersonEntryModel;

    private sourcePersonSub: Subscription;
    private targetPersonSub: Subscription;
    private actionsSub: Subscription;

    constructor(private personService: PersonsService,
                private actions$: Actions,
                private careSpaceService: CareSpaceService,
                private snackbarService: SnackbarService,
                private actionsService: ActionsService,
                private externalAPIService: ExternalApiService) {
    }

    ngOnInit(): void {
        this.initEvents();
    }

    ngOnDestroy(): void {
        if (this.sourcePersonSub) {
            this.sourcePersonSub.unsubscribe();
        }

        if (this.targetPersonSub) {
            this.sourcePersonSub.unsubscribe();
        }

        if (this.actionsSub) {
            this.actionsSub.unsubscribe();
        }
    }

    public filterSourcePersons($event: any): void {
        this.sourcePersonSub = this.personService
            .fetchPersons(1, 1, Order.NONE, '', $event)
            .subscribe((persons: PersonEntryModel[]) => {
                if (persons.length > 0) {
                    this.sourcePerson = persons.slice().pop();
                } else {
                    this.sourcePerson = null;
                }
            });
    }

    public filterTargetPersons($event: any): void {
        this.targetPersonSub = this.personService
            .fetchPersons(1, 1, Order.NONE, '', $event)
            .subscribe((persons: PersonEntryModel[]) => {
                if (persons.length > 0) {
                    this.targetPerson = persons.slice().pop();
                } else {
                    this.targetPerson = null;
                }
            });
    }

    public transferActionsAndCareSpaces(): void {
        let formValid = true;
        this.personFormComponents.forEach((personFormComponent) => {
            if (!personFormComponent.personForm.valid) {
                formValid = false;
                personFormComponent.displayErroneousInputs = true;
            }
        });

        if (!formValid) {
            return;
        }

        if (this.sourcePerson.id === this.targetPerson.id) {
            this.snackbarService.displaySnackbar(SnackStatusType.ERROR,
                'Ausgangsperson und Zielperson sind ident!',
                10);
            return;
        }

        const filterModel = {
            personId: this.sourcePerson.id
        };

        this.actionsService.fetchActions(-1, 1, Order.ASC, 'date_performed', filterModel, false)
            .subscribe(transactions => {
                const updatedTransactions = transactions.map(transaction => {
                    const updatedTransaction: TransactionEntryModel = {
                        ...transaction,
                        person: this.targetPerson
                    };
                    return updatedTransaction;
                });
                this.requestSent = true;
                this.actionsService.updateActions(updatedTransactions, -1, 1, Order.NONE, '', filterModel);
            });

        this.careSpaceService
            .fetchCareSpaces(-1, 1, Order.NONE, '', filterModel)
            .pipe(
                take(1)
            )
            .subscribe(careSpaces => {
                const updatedCareSpaces = careSpaces.map(careSpace => {
                    const updateCareSpace: CareSpaceEntryModel = {
                        ...careSpace,
                        person: this.targetPerson
                    };
                    return updateCareSpace;
                });

                this.careSpaceService.updateCareSpaces(updatedCareSpaces, -1, 1, Order.NONE, '', filterModel);
            });
    }

    private initEvents(): void {
        this.actionsSub = this.actions$.pipe(
            ofType(fromActionAction.SET_ACTIONS)
        ).subscribe((actionState: fromActionAction.SetActions) => {
            if (actionState.payload.crud === CRUD.UPDATE) {
                const personFilter = {
                    id: this.targetPerson.id
                };
                this.personService.deletePerson(this.sourcePerson.id, -1, 1, Order.NONE, '', personFilter);
                this.requestSent = false;
                this.actionsService.handleRequestSuccess(actionState.payload.crud, 'Person',
                    this.targetPerson.firstName + ' ' + this.targetPerson.lastName);
                this.personFormComponents.forEach((personFormComponent) => {
                    personFormComponent.personForm.reset();
                });

                this.externalAPIService.handleInvalidRequests(actionState.payload.invalidAPIRequests);
            }
        });
    }
}
