<div class="sidebar-wrapper">
    <ul class="nav">
        <li #parentListItem routerLinkActive="active" *ngFor="let menuItem of menuItems"
            [attr.data-item]="menuItem.class"
            [ngClass]="[menuItem.class, 'nav-item', menuItem.subRoutes ? 'has-submenu' : '']">
            <a (click)="menuItem.subRoutes ? toggleMenu(parentListItem) : goTo(menuItem.path, parentListItem)"
               [className]="menuItem.subRoutes ? 'expandable d-block': 'nav-link d-block'">
                <div class="d-flex align-items-center justify-content-start">
                    <i class="fas fa-{{menuItem.icon}} mr-2"></i>
                    <p>{{menuItem.title}}</p>
                </div>
                <i *ngIf="menuItem.subRoutes" class="fas fa-chevron-left collapsed-icon position-absolute"></i>
            </a>

            <ng-container *ngIf="menuItem.subRoutes">
                <ul class="nav subnav"
                    [style.height.px]="menuItem.subRoutes.length * submenuItemHeight"
                    [style.max-height.px]="menuItem.subRoutes.length * submenuItemHeight">
                    <li *ngFor="let subItem of menuItem.subRoutes" [style.height.px]="submenuItemHeight"
                        routerLinkActive="active">
                        <a class="nav-link text-left" [routerLink]="[menuItem.path, subItem.path]"
                           (click)="removeActiveClass()">
                            <p>{{subItem.title}}</p>
                        </a>
                    </li>
                </ul>
            </ng-container>

        </li>
    </ul>
</div>
