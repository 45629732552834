import * as fromZipCitySearchActions from './zip-city.actions';
import {DropdownOption} from '../../../data-types/dropdown-option';

export interface State {
    zipCities: DropdownOption[];
}

const initialState: State = {
    zipCities: []
};

export function zipCityReducer(state = initialState, action: fromZipCitySearchActions.ZipCityActions): State {
    switch (action.type) {
        case fromZipCitySearchActions.SET_ZIP_CITIES:
            return {
                ...state,
                zipCities: [...action.payload]
            };
        case fromZipCitySearchActions.HTTP_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
}
