<div id="wrapper" class="d-flex align-items-center justify-content-center pl-2 pr-4">
    <div class="icon fas mr-4"
         [ngClass]="{
         'fa-check': status === SnackStatus.SUCCESS,
         'fa-times-circle': status === SnackStatus.ERROR,
        'fa-radiation': status === SnackStatus.WARNING,
        'fa-info-circle': status === SnackStatus.INFO
        }"></div>
    <div class="message">
        <h4 class="text-uppercase font-weight-bold mb-1">
            <ng-container [ngSwitch]="status">
                <ng-container *ngSwitchCase="SnackStatus.SUCCESS">
                    Erfolgreich gespeichert!
                </ng-container>
                <ng-container *ngSwitchCase="SnackStatus.ERROR">
                    Es ist ein Fehler aufgetreten!
                </ng-container>
                <ng-container *ngSwitchCase="SnackStatus.WARNING">
                    Achtung!
                </ng-container>
                <ng-container *ngSwitchCase="SnackStatus.INFO">
                    Hinweis!
                </ng-container>
            </ng-container>
        </h4>
        <span [innerHTML]="message"></span>
    </div>
</div>
