import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {DialogType} from '../../dialogs/dialog-type';
import {Order} from '../../shared/controls/data-table/ordering';
import {PersonEntryModel} from '../../shared/models/person-entry.model';

@Component({
    selector: 'app-person-dialog',
    templateUrl: './person-dialog.component.html',
    styleUrls: ['./person-dialog.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class PersonDialogComponent {
    public dialogType: string;
    public label: string;
    public readonly = false;
    public simpleDialogType = new DialogType();
    public readonly lastPage = 1;
    public readonly entriesPerPage = 10;
    public readonly order = Order.NONE;
    public readonly orderedColumn = '';
    public readonly person: PersonEntryModel = null;

    constructor(public dialogRef: MatDialogRef<PersonDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: any) {
        this.dialogType = data.type;

        if (this.dialogType === this.simpleDialogType.PERSON_DATA) {
            this.label = 'Personendaten anzeigen';
            this.readonly = true;
            this.person = this.data.person;
        } else if (this.dialogType === this.simpleDialogType.SEARCH_PERSON) {
            this.label = 'Person suchen';
        } else if (this.dialogType === this.simpleDialogType.EDIT_PERSON) {
            this.label = 'Person bearbeiten';
            this.person = this.data.person;
        } else {
            this.label = 'Neue Person erstellen';
            if (typeof data.lastPage !== 'undefined') {
                this.lastPage = data.lastPage;
            }
            if (typeof data.entriesPerPage !== 'undefined') {
                this.entriesPerPage = data.entriesPerPage;
            }
            if (typeof data.order !== 'undefined') {
                this.order = data.order;
            }
            if (typeof data.column !== 'undefined') {
                this.orderedColumn = data.column;
            }
        }
    }

}
