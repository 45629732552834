<ng-container [formGroup]="acquisitionGiveAwayForm">
    <div class="row">
        <div class="col-md-6 mt-3">
            <label>Adresse als Tierhalteplatz übernehmen?</label>
            <mat-radio-group formControlName="animalPlace"
                             aria-label="Where is the animal held?"
                             name="animalPlace">
                <mat-radio-button value="yes" [checked]="true">Ja</mat-radio-button>
                <mat-radio-button value="no">Nein</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="row" *ngIf="acquisitionGiveAwayForm.controls.animalPlace.value === 'no'">
        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Strasse</mat-label>
                <input matInput
                       placeholder="Strasse"
                       formControlName="residenceStreet">
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Postleitzahl</mat-label>
                <input matInput
                       placeholder="Postleitzahl"
                       formControlName="residenceZip">
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field>
                <mat-label>Ort</mat-label>
                <input matInput
                       placeholder="Ort"
                       formControlName="residenceCity">
            </mat-form-field>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-4">
            <label>Garten vorhanden?</label>
            <mat-radio-group aria-label="Garten vorhanden?"
                             formControlName="hasGarden"
                             aria-placeholder="Garten vorhanden?">
                <mat-radio-button value="yes">Ja</mat-radio-button>
                <mat-radio-button value="no" [checked]="true">Nein</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="col-md-8">
            <label>Zaun vorhanden?</label>
            <mat-radio-group aria-label="Zaun vorhanden?"
                             formControlName="hasFence"
                             aria-placeholder="Zaun vorhanden?">
                <mat-radio-button value="yes">Ja</mat-radio-button>
                <mat-radio-button value="no" [checked]="true">Nein</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Weitere Tiere in diesem Haushalt</mat-label>
                <textarea matInput
                          formControlName="otherAnimalsInHousehold"
                          placeholder="Weitere Tiere in diesem Haushalt"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
        </div>
    </div>
</ng-container>
