import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {Order} from '../../shared/controls/data-table/ordering';
import {VeterinaryTreatmentEntryModel} from '../../shared/models/veterinary-treatments/veterinary-treatment-entry.model';
import {VeterinaryTreatmentsComponent} from '../veterinary-treatments.component';
import {take} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import * as fromTreatmentsActions from '../store/veterinary-treatments/veterinary-treatments.actions';

@Component({
    selector: 'app-veterinary-treatment-quick-edit',
    templateUrl: './veterinary-treatment-quick-edit.component.html',
    styleUrls: ['./veterinary-treatment-quick-edit.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class VeterinaryTreatmentQuickEditComponent extends VeterinaryTreatmentsComponent implements OnInit, OnDestroy {
    @Input() public treatment: VeterinaryTreatmentEntryModel;
    @Input() public pageIndex: number;
    @Input() public entriesPerPage: number;
    @Input() public order: Order;
    @Input() public orderedColumn: string;
    public updateRequestSent = false;

    ngOnInit(): void {
        super.initForm(this.treatment);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public updateTreatment(): void {
        if (!this.newTreatmentForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }
        this.updateRequestSent = true;
        this.displayErroneousInputs = false;

        const id = this.treatment.id;
        const value = this.newTreatmentForm.value;
        const description = value.newDescription;
        const invoiceText = value.newInvoiceText;
        const price = value.newPrice.replace(/,/g, '.');

        const updatedTreatment: VeterinaryTreatmentEntryModel = {
            id,
            description,
            invoiceText,
            price
        };

        this.treatmentService.updateVeterinaryTreatment(updatedTreatment,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn);
        VeterinaryTreatmentsComponent.treatmentIdentifier = description;

        this.actions$.pipe(
            take(1),
            ofType(fromTreatmentsActions.SET_VETERINARY_TREATMENTS)
        ).subscribe(() => {
            this.updateRequestSent = false;
        });
    }
}
