export class DialogType {
    public readonly TRIVIAL = 'trivial';
    public readonly ADD_RACE = 'add-race';
    public readonly ACCOMMODATION = 'accommodation';
    public readonly GOING_TO_HEAVEN = 'going-to-heaven';
    public readonly NEW_ANIMAL = 'new-animal';
    public readonly NEW_PERSON = 'new-person';
    public readonly PERSON_DATA = 'person-data';
    public readonly SEARCH_PERSON = 'search-person';
    public readonly EDIT_PERSON = 'edit-person';
    public readonly DELETE_RACE = 'delete-race';
    public readonly DELETE_SPECIES = 'delete-species';
    public readonly NEW_CHARACTERISTIC = 'new-characteristic';
    public readonly CANCEL_BOOKING = 'delete-booking';
    public readonly CREATE_GENERIC = 'create-generic';
    public readonly DELETE_GENERIC = 'delete-generic';
    public readonly UPLOAD = 'upload';
    public readonly UNSAVED_CHANGES = 'unsaved-changes';
    public readonly NO_SYNCHRONIZATION = 'no-synchronization';
    public readonly REMARK = 'remark';
}
