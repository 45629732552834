import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MediaCentreComponent, MediaCentreMode} from '../../media-centre/media-centre.component';
import {AuthService} from '../../auth/auth.service';
import {UserEntryModel} from '../../shared/models/user-entry.model';
import {UserService} from '../user-service.service';
import {Order} from '../../shared/controls/data-table/ordering';
import {Actions, ofType} from '@ngrx/effects';
import * as fromUserActions from '../store/manage-users/users.actions';
import {CRUD} from '../../shared/services/http/crud';
import {MustMatch} from '../create-user-dialog/must-match.validator';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
    public updateUserForm: FormGroup;
    public updatePasswordForm: FormGroup;
    public displayUpdateUserErrors: boolean;
    public displayPasswordErrors: boolean;
    public updateUserRequestSent = false;
    public updatePasswordRequestSent = false;

    private currentUser: UserEntryModel;

    constructor(private formBuilder: FormBuilder,
                private authService: AuthService,
                private userService: UserService,
                private actions$: Actions,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.initForms();
        this.initEvents();
    }

    public changeImage(): void {
        const dialogRef = this.dialog.open(MediaCentreComponent, {
            width: '1200px',
            panelClass: 'component-wrapper',
            data: {
                mode: MediaCentreMode.CHANGE_IMAGE
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // Todo: assign image to user
        });
    }

    public updateUser(): void {
        if (!this.updateUserForm.valid) {
            this.displayUpdateUserErrors = true;
            return;
        }
        this.displayUpdateUserErrors = false;
        this.updateUserRequestSent = true;

        const formData = this.updateUserForm.value;
        const updatedUser: UserEntryModel = {
            id: this.currentUser.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            shelterId: this.currentUser.shelterId,
            shelter: this.currentUser.shelter,
            email: this.currentUser.email,
            isAdmin: this.currentUser.isAdmin
        };
        this.userService.updateUser(updatedUser, -1, 1, Order.NONE, '');
    }

    public updatePassword(): void {
        if (!this.updatePasswordForm.valid) {
            this.displayPasswordErrors = true;
            return;
        }

        this.displayUpdateUserErrors = false;
        this.updatePasswordRequestSent = true;

        const formData = this.updatePasswordForm.value;
        const updatedUser: UserEntryModel = {
            id: this.currentUser.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.newPassword,
            shelterId: this.currentUser.shelterId,
            shelter: this.currentUser.shelter,
            email: this.currentUser.email,
            isAdmin: this.currentUser.isAdmin
        };
        this.userService.updatePassword(updatedUser);
    }

    private initForms(): void {
        this.updateUserForm = this.formBuilder.group({
            email: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required]
        });

        this.updatePasswordForm = this.formBuilder.group({
            newPassword: ['', Validators.required],
            newPasswordRepetition: ['', Validators.required]
        }, {
            validator: [MustMatch('newPassword', 'newPasswordRepetition')]
        });
    }

    private initEvents(): void {
        this.authService.loggedInUser.subscribe(currentUser => {
            this.currentUser = currentUser;
            this.updateUserForm.patchValue({
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                email: currentUser.email
            });
        });

        this.actions$.pipe(
            ofType(fromUserActions.SET_USERS)
        ).subscribe((userState: fromUserActions.SetUsers) => {
            const operation = userState.payload.crud;
            if (operation === CRUD.NONE || operation === CRUD.READ) {
                return;
            }

            if (this.updateUserRequestSent) {
                this.updateUserRequestSent = false;
                const updatedUser = userState.payload.addedOrUpdatedUser;
                this.authService.loggedInUser.next(updatedUser);
                this.userService.handleRequestSuccess(operation, 'Profil', '');
            }

            if (this.updatePasswordRequestSent) {
                this.updatePasswordRequestSent = false;
                this.userService.handleRequestSuccess(operation, 'Passwort', '');
            }
        });
    }
}
