<div class="attachment" (click)="openInFullscreen()">
    <div class="icon-container d-flex position-absolute justify-content-between">
        <div [class.invisible]="avatarId !== media.id"
             [ngStyle]="{'color': avatarId === media.id ? '#28acec' : ''}"
             (click)="toggleAvatar()"
             class="set-avatar position-relative"
             *ngIf="mode === MediaCenterMode.PREVIEW">
            <i class="far fa-user-circle" [hidden]="!isAnimalImage"></i>
        </div>
        <div class="remove-thumb position-relative"
             (click)="removeImage()"
             *ngIf="mode === MediaCenterMode.PREVIEW">
            <div class="fas fa-minus remove"></div>
        </div>
    </div>
    <div class="attachment-preview">
        <div class="thumbnail">
            <img title="{{media.title}}"
                 alt="{{media.altText}}"
                 [src]="'data:image/jpg;base64, ' + media.data | safeHtml">
        </div>
    </div>
</div>
