import * as AuthActions from './auth.actions';
import {AuthEntryModel} from '../../shared/models/auth-entry.model';

export interface State {
    user: AuthEntryModel;
    authError: string;
    loading: boolean;
}

const initialState: State = {
    user: null,
    authError: null,
    loading: null // Set boolean to null initially so it becomes false after request has been finished (needed for guard).
};

export function authReducer(state = initialState, action: AuthActions.AuthActions): State {
    switch (action.type) {
        case AuthActions.LOGIN_START:
            return {
                ...state,
                authError: null,
                loading: true
            };
        case AuthActions.SET_USER:
            const user: AuthEntryModel = {
                id: action.payload.id,
                email: action.payload.email,
                shelterId: action.payload.shelterId
            };
            return {
                ...state,
                user,
                authError: null,
                loading: false
            };
        case AuthActions.HTTP_FAIL:
            return {
                ...state,
                user: null,
                authError: action.payload,
                loading: false
            };
        case AuthActions.LOGOUT:
            return {
                ...state,
                user: null
            };
        default:
            return state;
    }
}
