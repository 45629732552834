import {Action} from '@ngrx/store';
import {PersonEntryModel} from '../../shared/models/person-entry.model';
import {CRUD} from '../../shared/services/http/crud';
import {Order} from '../../shared/controls/data-table/ordering';
import {ErrorTypes} from '../../shared/data-types/error-types';

export const LOAD_PERSONS = '[Persons] Load Persons';
export const SET_PERSONS = '[Persons] Set Persons';
export const ADD_PERSON = '[Persons] Add Persons';
export const UPDATE_PERSON = '[Persons] Update Persons';
export const DELETE_PERSON = '[Persons] Delete Persons';
export const HTTP_FAIL = '[Persons] Http Fail';

export class LoadPersons implements Action {
    readonly type = LOAD_PERSONS;

    constructor(public payload: { crud: CRUD, size: number, page: number, order: Order, column: string, personFilter?: any }) {
    }
}

export class SetPersons implements Action {
    readonly type = SET_PERSONS;

    constructor(public payload: { persons: PersonEntryModel[], crud: CRUD, totalElements: number, lastPage: number }) {
    }
}

export class AddPerson implements Action {
    readonly type = ADD_PERSON;

    constructor(public payload: { person: PersonEntryModel, size: number, page: number, order: Order, column: string }) {
    }
}

export class UpdatePerson implements Action {
    readonly type = UPDATE_PERSON;

    constructor(public payload: {
        person: PersonEntryModel, size: number, page: number, order: Order, column: string, personFilter?: any
    }) {
    }
}

export class DeletePerson implements Action {
    readonly type = DELETE_PERSON;

    constructor(public payload: { personId: number, size: number, page: number, order: Order, column: string, personFilter?: any }) {
    }
}

export class HttpFail implements Action {
    readonly type = HTTP_FAIL;

    constructor(public payload: ErrorTypes) {
    }
}

export type PersonsActions =
    LoadPersons
    | SetPersons
    | AddPerson
    | UpdatePerson
    | DeletePerson
    | HttpFail;

