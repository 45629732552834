<div class="title-bar position-relative">
    <h3 mat-dialog-title class="w-auto">
        <ng-container *ngIf="!editMode">Tier erstellen</ng-container>
        <ng-container *ngIf="editMode">Tier bearbeiten</ng-container>
    </h3>
    <app-quicknav style="margin-right: 125px; margin-bottom: 9px"
                  (clickedIcon)="clickedNavIcon($event)"
                  (enableSaveAnimalBtn)="saveChangesBtn.disabled = !$event"
                  *ngIf="editMode && animalEntry.id >= 0"
                  [animal]="animalEntry"
                  [animalFilter]="filterModel"
                  [pageIndex]="pageIndex"
                  [entriesPerPage]="entriesPerPage"
                  [orderedColumn]="orderedColumn"
                  [order]="order"
                  [filterModel]="filterModel"
                  [latestTransaction]="latestTransaction"></app-quicknav>
    <div class="fa fa-close float-right mt-1 position-absolute"
         mat-button
         (click)="cancel()"
         aria-label="close-dialog"></div>
</div>

<mat-dialog-content>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Stammdaten</h4>
        </div>
        <div class="card-body">
            <form class="form"
                  [formGroup]="basicClaimsForm"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <div class="row">
                    <div class="col-md" *ngIf="editMode">
                        <mat-form-field>
                            <mat-label>Laufnummer</mat-label>
                            <input [readonly]="true"
                                   formControlName="lfnr"
                                   matInput
                                   placeholder="Laufnummer">
                            <div *ngIf="animalEntry.type && animalEntry.type.name !== ActionType.HIMMELFAHRT.key"
                                 class="animal-type ml-0 position-absolute" style="top:3px; right: 6px;"
                                 [style]="{background: animalEntry.type !== null ? animalEntry.type.color: ''}"
                                 [ngClass]="animalEntry.type !== null ? animalEntry.type.color: ''"
                                 [matTooltip]="animalEntry.type !== null ? actionTypesService.getActionTypeText(animalEntry.type.name): ''"
                                 matTooltipPosition="right"
                                 matTooltipClass="tooltip-popup">
                            </div>
                            <i *ngIf="animalEntry.type && animalEntry.type.name === ActionType.HIMMELFAHRT.key"
                               class="fas fa-skull-crossbones position-absolute"
                               style="font-size:16px; top: 26px; right: 19px;"
                               matTooltip="Im Himmel"
                               matTooltipPosition="right"
                               matTooltipClass="tooltip-popup"></i>
                        </mat-form-field>
                    </div>

                    <div class="col-md">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input
                                    formControlName="name"
                                    (keydown.enter)="addOrUpdateAnimal()"
                                    matInput
                                    placeholder="Name">
                        </mat-form-field>
                    </div>

                    <div class="col-md">
                        <mat-form-field class="mat-datetime-picker">
                            <input matInput
                                   (click)="datePickerBirthday.open()"
                                   (dateChange)="birthdayChanged($event)"
                                   [ngxMatDatetimePicker]="datePickerBirthday"
                                   placeholder="Geburtsdatum"
                                   formControlName="birthday"
                                   (keydown.enter)="addOrUpdateAnimal()"
                                   [min]="dateTimeConfig.minDate"
                                   [max]="dateTimeConfig.maxDate">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datePickerBirthday"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #datePickerBirthday
                                                     [stepHour]="dateTimeConfig.stepHour"
                                                     [stepMinute]="dateTimeConfig.stepMinute"
                                                     [hideTime]="true">
                            </ngx-mat-datetime-picker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Jahre</mat-label>
                            <input formControlName="ageYears"
                                   (ngModelChange)="ageChanged()"
                                   (keydown.enter)="addOrUpdateAnimal()"
                                   matInput
                                   placeholder="Jahre">
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <mat-form-field>
                            <mat-label>Monate</mat-label>
                            <input formControlName="ageMonths"
                                   (ngModelChange)="ageChanged()"
                                   (keydown.enter)="addOrUpdateAnimal()"
                                   matInput
                                   placeholder="Monate">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Tierart</mat-label>
                            <mat-select placeholder="Art"
                                        [multiple]="false"
                                        (keydown.enter)="addOrUpdateAnimal()"
                                        (ngModelChange)="selectSpecies($event)"
                                        formControlName="species"
                                        #singleSelectSpecies>
                                <mat-option>
                                    <ngx-mat-select-search
                                            [formControl]="selectSearchSpecies.filterCtrl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new" (click)="speciesService.addSpecies($event,
                                    chipBar,
                                    basicClaimsForm.controls.species)"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neue Art erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option
                                        *ngFor="let species of selectSearchSpecies.filteredElements | async"
                                        [value]="species.value"
                                        #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{species.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown)="$event.stopPropagation()"
                                                   (keydown.enter)="speciesService.updateSpecies($event,
                                                                                           option,
                                                                                           species,
                                                                                           updatedVal,
                                                                                           pageIndex,
                                                                                           entriesPerPage,
                                                                                           order,
                                                                                           orderedColumn,
                                                                                           basicClaimsForm.controls.species)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="species.viewValue" />
                                        </div>
                                        <div class="controls"
                                             *ngIf="species.viewValue !== 'Hund' && species.viewValue !== 'Katze'">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Tierart ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"
                                            ></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="speciesService.updateSpecies($event,
                                                                               option,
                                                                               species,
                                                                               updatedVal,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               basicClaimsForm.controls.species)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="speciesService.deleteSpecies($event,
                                                                               species,
                                                                               selectSearchSpecies.values,
                                                                               allRaces,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               basicClaimsForm.controls.species,
                                                                               basicClaimsForm.controls.race)"
                                               matTooltip="Tierart löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Heim</mat-label>
                            <mat-select formControlName="shelter"
                                        (keydown.enter)="addOrUpdateAnimal()">
                                <mat-option *ngFor="let shelter of shelterStatusOptions"
                                            [value]="shelter.value">
                                    {{shelter.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Tierrasse</mat-label>
                            <mat-select placeholder="Rasse"
                                        [multiple]="false"
                                        (keydown.enter)="addOrUpdateAnimal()"
                                        (ngModelChange)="raceChanged($event)"
                                        formControlName="race"
                                        #singleSelectRace>
                                <mat-option>
                                    <ngx-mat-select-search
                                            [formControl]="selectSearchRace.filterCtrl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new"
                                       (click)="raceService.addRace($event,
                                       allFDRaces,
                                       allSpecies,
                                       basicClaimsForm.controls.race,
                                       basicClaimsForm.controls.species.value)"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neue Rasse erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option
                                        *ngFor="let race of selectSearchRace.filteredElements | async"
                                        [value]="race.value"
                                        #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{race.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown)="$event.stopPropagation()"
                                                   (keydown.enter)="raceService.updateRace($event,
                                                                                           option,
                                                                                           race,
                                                                                           updatedVal,
                                                                                           pageIndex,
                                                                                           entriesPerPage,
                                                                                           order,
                                                                                           orderedColumn,
                                                                                           basicClaimsForm.controls.race)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="race.viewValue" />
                                        </div>
                                        <div class="controls">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Rasse ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"
                                            ></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="raceService.updateRace($event,
                                                                               option,
                                                                               race,
                                                                               updatedVal,
                                                                                pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               basicClaimsForm.controls.race)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="raceService.deleteRace($event,
                                                                               race,
                                                                               selectSearchRace.values,
                                                                               pageIndex,
                                                                               entriesPerPage,
                                                                               order,
                                                                               orderedColumn,
                                                                               basicClaimsForm.controls.race)"
                                               matTooltip="Rasse löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Geschlecht</mat-label>
                            <mat-select formControlName="gender"
                                        (keydown.enter)="addOrUpdateAnimal()">
                                <mat-option *ngFor="let gender of genderOptions"
                                            [value]="gender.value">
                                    {{gender.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Kastration</mat-label>
                            <mat-select formControlName="castration"
                                        (keydown.enter)="addOrUpdateAnimal()">
                                <mat-option *ngFor="let castration of castrationStatusOptions"
                                            [value]="castration.value">
                                    {{castration.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Chipnummer</mat-label>
                            <input (keydown.enter)="addOrUpdateAnimal()"
                                   formControlName="chipNumber"
                                   matInput
                                   placeholder="Chipnummer">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Weitere Eigenschaften -->
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Weitere Eigenschaften</h4>
        </div>

        <div class="card-body">
            <form class="form"
                  [formGroup]="additionalFeatures"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Besondere Kennzeichen</mat-label>
                            <textarea matInput
                                      (keydown.enter)="addOrUpdateAnimal()"
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"
                                      formControlName="specialCriteria"></textarea>
                        </mat-form-field>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Kurztext</mat-label>
                            <textarea matInput
                                      (keydown.enter)="addOrUpdateAnimal()"
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="2"
                                      cdkAutosizeMaxRows="2"
                                      maxlength="270"
                                      formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Eigenschaften</mat-label>
                            <textarea matInput
                                      (keydown.enter)="addOrUpdateAnimal()"
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="3"
                                      cdkAutosizeMaxRows="5"
                                      formControlName="properties"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-12">
                        <mat-form-field class="chip-list">
                            <mat-chip-list
                                #chipList
                                aria-label="Beschreibung">
                                <mat-chip *ngFor="let characteristic of chipBar.getSelectedValues()"
                                          [selectable]="chipBar.selectable"
                                          [removable]="chipBar.removable"
                                          (removed)="chipBar.removeChip(characteristic.name)">
                                    {{characteristic.name}}
                                    <mat-icon matChipRemove *ngIf="chipBar.removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Beschreibung"
                                       #characteristicInput
                                       #characteristicsTrigger="matAutocompleteTrigger"
                                       (click)="chipBar.focusCharacteristicsInput()"
                                       (ngModelChange)="chipBar.filterCharacteristics($event)"
                                       [formControl]="chipBar.characteristicsCtrl"
                                       [matAutocomplete]="auto"
                                       [matChipInputFor]="chipList">
                            </mat-chip-list>
                            <mat-autocomplete #auto (optionSelected)="chipBar.selected($event, characteristicInput)">
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new"
                                       (click)="addCharacteristic($event, allRaces)"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neues Merkmal erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option
                                        *ngFor="let characteristic of chipBar.filteredCharacteristics | async"
                                        [value]="characteristic"
                                        #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{characteristic.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown.enter)="updateCharacteristic($event,
                                                                                           option,
                                                                                           characteristic,
                                                                                           updatedVal)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="characteristic.viewValue" />
                                        </div>
                                        <div class="controls">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Merkmal ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="updateCharacteristic($event,
                                                                               option,
                                                                               characteristic,
                                                                               updatedVal)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="deleteCharacteristic($event,
                                                                             characteristic)"
                                               matTooltip="Merkmal löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Unterbringung</mat-label>
                            <mat-select placeholder="Unterbringung"
                                        [multiple]="false"
                                        (keydown.enter)="addOrUpdateAnimal()"
                                        formControlName="accommodation"
                                        #selectAccommodation>
                                <mat-option>
                                    <ngx-mat-select-search
                                            [formControl]="selectSearchAccommodation.filterCtrl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [classList]="'mat-option hidden-checkbox'">
                                    <a class="add-new" (click)="accommodationService.addAccommodation($event);"
                                       style="font-weight: 600">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>Neue Unterbringung erstellen...</div>
                                            <div>
                                                <i class="add-btn fas fa-plus d-flex justify-content-center align-items-center"></i>
                                            </div>
                                        </div>
                                    </a>
                                </mat-option>
                                <mat-option
                                        *ngFor="let accommodation of selectSearchAccommodation.filteredElements | async"
                                        [value]="accommodation.value"
                                        #option>
                                    <div class="option-wrapper d-flex justify-content-between align-items-center">
                                        <div class="text-wrapper">
                                            <span class="text-element">{{accommodation.viewValue}}</span>
                                            <input class="d-none"
                                                   matInput
                                                   #updatedVal
                                                   (click)="$event.stopPropagation()"
                                                   (keydown.enter)="accommodationService.updateAccommodation($event,
                                                                               option,
                                                                               accommodation,
                                                                               updatedVal,
                                                                               additionalFeatures.controls.accommodation)"
                                                   (keydown.space)="$event.stopPropagation()"
                                                   [value]="accommodation.viewValue" />
                                        </div>
                                        <div class="controls" *ngIf="accommodation.value !== 'unbekannt'">
                                            <a class="fas fa-edit icon"
                                               (click)="selectBoxService.activateEditMode($event, option)"
                                               matTooltip="Unterbringung ändern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"
                                            ></a>
                                            <a class="fas fa-check icon d-none"
                                               (click)="accommodationService.updateAccommodation($event,
                                                                               option,
                                                                               accommodation,
                                                                               updatedVal,
                                                                               additionalFeatures.controls.accommodation)"
                                               matTooltip="Änderung speichern"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash icon"
                                               (click)="deleteAccommodation($event,
                                                                            accommodation,
                                                                            selectAccommodation.value)"
                                               matTooltip="Unterbringung löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Unterbringung Beschreibung</mat-label>
                            <input matInput
                                   (keydown.enter)="addOrUpdateAnimal()"
                                   placeholder="Unterbringung Beschreibung"
                                   formControlName="accommodationDescription">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-3 text-left">
                        <mat-checkbox formControlName="hiddenOnWebsite"
                                      (keydown.enter)="addOrUpdateAnimal()">
                            Nicht auf Website anzeigen
                            <i class="fas fa-eye-slash"></i>
                        </mat-checkbox>
                    </div>
                    <div class="col-3 text-left">
                        <mat-checkbox formControlName="premiumAnimal"
                                      (change)="checkBoxChanged($event, 'premiumAnimal')"
                                      (keydown.enter)="addOrUpdateAnimal()">
                            Premiumtier
                            <i class="fas fa-star premium "></i>
                        </mat-checkbox>
                    </div>
                    <div class="col-3 text-left">
                        <mat-checkbox formControlName="sponsoredAnimal"
                                      (keydown.enter)="addOrUpdateAnimal()"
                                      (change)="checkBoxChanged($event, 'sponsoredAnimal')">Sponsortier
                            <i class="fas fa-star sponsored"></i></mat-checkbox>
                    </div>
                </div>

                <div class="row" style="min-height: 85px">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-5 text-left" style="margin-top:8px">
                                <mat-checkbox
                                        (keydown.enter)="addOrUpdateAnimal()"
                                        #noGivingAway
                                        formControlName="noGivingAway">Nicht zur Vergabe <i
                                        class="fas fa-user-slash"></i>
                                </mat-checkbox>
                            </div>
                            <div class="col-md-7 text-left" *ngIf="noGivingAway.checked">
                                <mat-form-field>
                                    <mat-label>Grund</mat-label>
                                    <input matInput
                                           (keydown.enter)="addOrUpdateAnimal()"
                                           placeholder="Grund"
                                           formControlName="noGivingAwayReason">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-3 text-left" style="margin-top:8px">
                                <mat-checkbox
                                        (keydown.enter)="addOrUpdateAnimal()"
                                        formControlName="reserved"
                                        #reserved>
                                    Reserviert
                                </mat-checkbox>
                            </div>
                            <div class="col-md-4 text-left" *ngIf="reserved.checked">
                                <mat-form-field class="mat-datetime-picker">
                                    <input matInput
                                           (keydown.enter)="addOrUpdateAnimal()"
                                           (click)="datePickerReservedFrom.open()"
                                           [ngxMatDatetimePicker]="datePickerReservedFrom"
                                           placeholder="Bis"
                                           formControlName="reservedUntil"
                                           [min]="dateTimeConfig.minDate"
                                           [max]="dateTimeConfig.maxDate">
                                    <mat-datepicker-toggle matSuffix
                                                           [for]="datePickerReservedFrom"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #datePickerReservedFrom
                                                             [stepHour]="dateTimeConfig.stepHour"
                                                             [stepMinute]="dateTimeConfig.stepMinute"
                                                             [hideTime]="true">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-5 text-left" *ngIf="reserved.checked">
                                <mat-form-field>
                                    <mat-label>Für</mat-label>
                                    <input matInput
                                           (keydown.enter)="addOrUpdateAnimal()"
                                           placeholder="Für"
                                           formControlName="reservedFor">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

    <!-- Pflegeplatz-->
    <div class="card" *ngIf="animalEntry.careSpace !== null && animalEntry.careSpace.dateBackToShelter === null">
        <div class="card-header">
            <h4 class="card-title" style="justify-content: normal">
                <i class="fas fa-user-nurse float-left mr-2" style="margin-top:2px">
                </i>Tier hat einen Pflegeplatz
            </h4>
        </div>
        <div class="card-body">
            <div class="row">
                <form [formGroup]="careSpaceForm">
                    <div class="col-md-12">
                        <div class="row mt-2">
                            <div class="col-md-3">
                                <mat-form-field class="text-input">
                                    <mat-label>Name des Pflegers</mat-label>
                                    <input matInput
                                           placeholder="Name des Pflegers"
                                           readonly
                                           formControlName="careTakerName">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="text-input">
                                    <mat-label>Adresse des Pflegers</mat-label>
                                    <input matInput
                                           placeholder="Adresse des Pflegers"
                                           readonly
                                           formControlName="careTakerAddress">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="text-input">
                                    <mat-label>Telefonnummer des Pflegers</mat-label>
                                    <input matInput
                                           placeholder="Telefonnummer des Pflegers"
                                           readonly
                                           formControlName="careTakerPhone">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <button class="float-right mr-3 mt-4"
                                        mat-raised-button color="primary"
                                        (click)="acquireAnimal()">
                                    Zurück ins Tierheim
                                </button>
                                <mat-icon [hidden]="!careSpaceRequest" class="mr-2 float-right"
                                          style="margin-top: 33px">
                                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Sponsoring-->
    <div class="card"
         *ngIf="this.additionalFeatures.get('premiumAnimal').value ||
         this.additionalFeatures.get('sponsoredAnimal').value">
        <div class="card-header">
            <h4 class="card-title">Sponsoring</h4>
        </div>
        <div class="card-body">
            <form class="form"
                  [formGroup]="sponsoringForm"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <div class="row align-items-center">
                    <div class="col-md-3">
                        <mat-form-field class="text-input">
                            <mat-label>Sponsorname</mat-label>
                            <input matInput
                                   placeholder="Sponsorname"
                                   formControlName="sponsorName">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="text-input">
                            <mat-label>Sponsor Website</mat-label>
                            <input matInput
                                   placeholder="Website"
                                   formControlName="sponsorWebsite">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 text-center">
                        <button color="primary" mat-raised-button
                                (click)="changeImages(ImageUploadType.SPONSOR, false)">
                            Logo ändern...
                        </button>
                    </div>
                    <div class="col-md-3 text-right">
                        <ng-container *ngIf="sponsorLogoThumb !== null">
                            <app-media-thumb [media]="sponsorLogoThumb"
                                             [mode]="MediaCentreMode.PREVIEW"
                                             [isAnimalImage]="false"
                                             (thumbRemovedEvent)="removeSponsor()">
                            </app-media-thumb>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Sponsortext</mat-label>
                            <textarea matInput
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="3"
                                      cdkAutosizeMaxRows="5"
                                      formControlName="sponsorText"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Tierattribute-->
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Tierattribute</h4>
        </div>
        <div class="card-body">
            <form class="form"
                  [formGroup]="animalAttributes"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <div class="row">
                    <ng-container *ngFor="let attribute of allAttributes; let i = index;">
                        <div class="clearfix" *ngIf="i % 4 == 0"></div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill">
                                <mat-label>{{attribute.name}}</mat-label>
                                <mat-select [formControlName]="attribute.name"
                                            panelClass="multiSelectAttributes"
                                            multiple>
                                    <mat-option *ngFor="let option of attribute.options"
                                                [attr.selected-id]="option.id"
                                                (onSelectionChange)="attributeChanged(attribute.name, attribute, $event, option.id)"
                                                [value]="option.name">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>

    <!-- Fundtierdatenbank -->
    <div class="card">
        <div class="card-header pb-0">
            <h4 class="card-title">Fundtierdatenbank</h4>
        </div>

        <div class="card-body pt-0">
            <form class="form"
                  [formGroup]="synchronizeWithExternalSystem"
                  [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">
                <div class="row">
                    <div class="col-md-12">
                        <mat-checkbox checked="checked"
                                      formControlName="willSynchronize">
                            Tier mit Fundtierdatenbank synchronisieren
                        </mat-checkbox>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Anmerkung</mat-label>
                            <textarea matInput
                                      formControlName="fundtierDBRemark"
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>Fundtierdatenbank ID:</mat-label>
                            <input matInput
                                   readonly
                                   placeholder="Website"
                                   formControlName="fundtierDBId">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Bildverwaltung -->
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Bilder verwalten</h4>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <button color="primary" mat-raised-button (click)="changeImages(ImageUploadType.ANIMAL_IMAGE)">
                        Bilder ändern...
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 d-flex mt-4 flex-column">
                    <div id="media-container">
                        <ng-container *ngFor="let media of assignedImages">
                            <app-media-thumb [media]="media"
                                             [mode]="MediaCentreMode.PREVIEW"
                                             [avatarId]="avatarId"
                                             (thumbRemovedEvent)="removeImage($event)"
                                             (toggleAvatarEvent)="toggleAvatar($event)">
                            </app-media-thumb>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pb-1">
                <div class="col">
                    <button mat-raised-button color="warn" (click)="cancel()">Abbrechen</button>
                    <button class="float-right"
                            #saveChangesBtn
                            mat-raised-button
                            color="primary"
                            *ngIf="editMode"
                            (click)="addOrUpdateAnimal()">
                        <i class="fas fa-exclamation-circle mr-2"
                           style="color: #b71c1c"
                           *ngIf="displayErroneousInputs">
                        </i>
                        <ng-container>Änderungen speichern</ng-container>
                    </button>
                    <button class="float-right"
                            mat-raised-button
                            color="primary"
                            *ngIf="!editMode"
                            (click)="addOrUpdateAnimal()"
                            aria-label="close-dialog">
                        <i class="fas fa-exclamation-circle mr-2"
                           style="color: #b71c1c"
                           *ngIf="displayErroneousInputs">
                        </i>
                        <ng-container *ngIf="!editMode">Temporär erstellen</ng-container>
                    </button>
                    <button class="float-right mr-3"
                            mat-raised-button color="primary"
                            *ngIf="pensionPeriodExpired"
                            (click)="takeBackToShelter(true)">
                        Tier ins Tierheim übernehmen
                    </button>
                    <button class="float-right mr-3"
                            mat-raised-button color="primary"
                            *ngIf="animalEntry.type !== null && animalEntry.type.name === ActionType.ABNAHME.key"
                            (click)="takeBackToShelter(false)">
                        Tier ins Tierheim übernehmen
                    </button>
                    <mat-icon [hidden]="!(requestSent && basicClaimsForm.valid)" class="mr-2 float-right"
                              style="margin-top: 9px">
                        <mat-spinner color="accent" diameter="20"></mat-spinner>
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>
