import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ArticleListComponent} from '../article-list.component';
import {ArticleEntryModel} from '../../../shared/models/pos/article-entry.model';
import {TupleHelper} from '../../../shared/data-types/tuple';
import {ofType} from '@ngrx/effects';
import {take} from 'rxjs/operators';
import {Order} from '../../../shared/controls/data-table/ordering';
import * as fromArticlesActions from '../../store/articles/articles.actions';

@Component({
    selector: 'app-article-quick-edit',
    templateUrl: './article-quick-edit.component.html',
    styleUrls: ['./article-quick-edit.component.scss']
})
export class ArticleQuickEditComponent extends ArticleListComponent implements OnInit, OnDestroy {
    @Input() public article: ArticleEntryModel;
    @Input() public pageIndex: number;
    @Input() public entriesPerPage: number;
    @Input() public order: Order;
    @Input() public orderedColumn: string;
    public updateRequestSent = false;

    ngOnInit(): void {
        super.initForm(this.article);
        super.initSelectBox();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public updateArticle(): void {
        if (!this.newArticleForm.valid) {
            this.displayErroneousInputs = true;
            return;
        }
        this.updateRequestSent = true;
        this.displayErroneousInputs = false;
        const id = this.article.id;
        const value = this.newArticleForm.value;
        const price = value.newPrice.replace(/,/g, '.');
        const selectedCategory = this.newArticleForm.get('newCategory').value;
        const selectedOption = this.selectSearchPOSCategory.values.find(option => option.value === selectedCategory);
        const updatedArticle: ArticleEntryModel = {
            id,
            name: value.newName,
            category: {
                id: TupleHelper.getValue(selectedOption.meta, 'id'),
                name: selectedCategory
            },
            price
        };

        this.posSystemService.updateArticle(updatedArticle,
            this.entriesPerPage,
            this.pageIndex,
            this.order,
            this.orderedColumn);
        ArticleListComponent.articleIdentifier = value.newName;

        this.actions$.pipe(
            take(1),
            ofType(fromArticlesActions.SET_ARTICLES)
        ).subscribe(() => {
            this.updateRequestSent = false;
        });
    }
}
