import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateTimeConfig} from '../../../shared/controls/date-time-picker/DateTimeConfig';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CUSTOM_DATE_FORMATS} from '../../../shared/controls/date-time-picker/CustomFormat';
import {ZipCity, ZipCitySearchService} from '../../../shared/services/zip-city-search/zip-city-search.service';
import {TupleHelper} from '../../../shared/data-types/tuple';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
    selector: 'app-findling',
    templateUrl: './findling.component.html',
    styleUrls: ['./findling.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
    ]
})
export class FindlingComponent implements OnInit {
    @Input() public acquisitionGiveAwayForm: FormGroup;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public ZipCity = ZipCity;

    constructor(public zipCitySearchService: ZipCitySearchService) {
    }

    ngOnInit(): void {
        this.acquisitionGiveAwayForm.addControl('streetFound', new FormControl(''));
        this.acquisitionGiveAwayForm.addControl('zipFound', new FormControl('', Validators.required));
        this.acquisitionGiveAwayForm.addControl('cityFound', new FormControl('', Validators.required));
        this.acquisitionGiveAwayForm.addControl('howAnimalWasFound', new FormControl(''));
    }

    public zipCityChanged(event: MatAutocompleteSelectedEvent): void {
        const option = event.option.value;
        const city = TupleHelper.getValue(option.meta, 'city');
        this.acquisitionGiveAwayForm.patchValue({
            cityFound: city
        });
    }
}
