import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CustomFormat} from '../../shared/controls/date-time-picker/CustomFormat';
import {DateTimeConfig} from '../../shared/controls/date-time-picker/DateTimeConfig';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActionsService} from '../../actions/actions.service';
import {MatSelect} from '@angular/material/select';
import {SelectSearch} from '../../shared/services/select-box/select-search';
import {Subscription} from 'rxjs';
import {SpeciesService} from '../../animals/store/species/species.service';

@Component({
    selector: 'app-supervision-filter',
    templateUrl: './supervision-filter.component.html',
    styleUrls: ['./supervision-filter.component.scss'],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS, useValue: CustomFormat.Date()}
    ]
})
export class SupervisionFilterComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('datePickerFrom') public datePickerFrom: any;
    @ViewChild('datePickerTo') public datePickerTo: any;
    @ViewChild('singleSelectSpecies') public singleSelectSpecies: MatSelect;
    public dateTimeConfig: DateTimeConfig = new DateTimeConfig();
    public filterForm: FormGroup;
    public selectSearchSpecies = new SelectSearch();

    private selectBoxSub: Subscription;

    constructor(private formBuilder: FormBuilder,
                private speciesService: SpeciesService,
                private actionsService: ActionsService) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initSpeciesDropdown();
    }

    ngAfterViewInit(): void {
        this.selectSearchSpecies.init(this.singleSelectSpecies);
    }

    ngOnDestroy(): void {
        if (this.selectBoxSub) {
            this.selectBoxSub.unsubscribe();
        }
    }

    public filter(): void {
        const value = this.filterForm.value;
        const filterModel = {
            dateFrom: value.from && value.from !== '' ? value.from.format('YYYY-MM-DD') : '',
            dateTo: value.to && value.to !== '' ? value.to.format('YYYY-MM-DD') : '',
            species: value.species ? value.species.viewValue : '',
            onlyUnsupervisedAnimals: value.onlyUnsupervisedAnimals ? value.onlyUnsupervisedAnimals : false
        };

        this.actionsService.filterEvent.next(filterModel);
    }

    public reset(): void {
        this.filterForm.reset({
            from: '',
            to: '',
            species: '',
            onlyUnsupervisedAnimals: [true]
        });
        this.actionsService.filterEvent.next(null);
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            from: '',
            to: '',
            species: '',
            onlyUnsupervisedAnimals: true
        });
    }

    private initSpeciesDropdown(): void {
        this.selectBoxSub = this.speciesService.fetchSpecies()
            .subscribe((species) => {
                this.selectSearchSpecies.setValues(species);
            });
    }
}
