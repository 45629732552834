<div class="title-bar">
    <h3>Tierärztliche Maßnahmen</h3>
</div>

<div class="container-fluid main-card-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Auflistung der Maßnahmen</h4>
                </div>
                <div class="card-body">
                    <div id="top-controls" class="d-flex justify-content-between">
                        <div id="show-entries-container">
                            Zeige
                            <select name="entries"
                                    id="entries"
                                    class="mr-1 ml-1"
                                    (ngModelChange)="changeEntriesPerPage($event)"
                                    [(ngModel)]="entriesPerPage">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            Einträge
                        </div>
                        <div id="add-new-treatment">
                            <a class="d-flex align-items-center no-color"
                               (click)="addTableRow()">
                                Neue Maßnahme erstellen <i
                                    class="add-btn fas fa-plus d-flex justify-content-center align-items-center ml-3"></i>
                            </a>
                        </div>
                    </div>
                    <div style="overflow-x: auto">
                        <table mat-table
                               [dataSource]="treatments$"
                               (matSortChange)="orderData($event)"
                               multiTemplateDataRows
                               matSort
                               class="mat-elevation-z0 extensible">
                            <!-- Position Column -->
                            <ng-container *ngFor="let def of tableDef">
                                <ng-container [matColumnDef]="def.key">
                                    <th [ngClass]="{ 'sortable-header': columnSortable(def.key) }" mat-header-cell
                                        mat-sort-header *matHeaderCellDef> {{def.header}} </th>
                                    <td [ngClass]="def.className"
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngSwitch]="def.key">
                                        <ng-container *ngSwitchCase="'price'">
                                            {{element[def.key] | currency:'EUR':'symbol':'1.2-2'}}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{element[def.key]}}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container [formGroup]="newTreatmentForm">
                                <ng-container matColumnDef="new-id">
                                    <td mat-footer-cell *matFooterCellDef class="pl-0 pr-0">
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-description">
                                    <td mat-footer-cell *matFooterCellDef class="pl-0 pr-2">
                                        <mat-form-field>
                                            <mat-label>Maßnahme</mat-label>
                                            <input matInput
                                                   placeholder="Maßnahme"
                                                   formControlName="newDescription">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-invoice-text">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                        <mat-form-field>
                                            <mat-label>Rechnungstext</mat-label>
                                            <input matInput
                                                   placeholder="Rechnungstext"
                                                   formControlName="newInvoiceText">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-price">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-2">
                                        <mat-form-field>
                                            <mat-label>Kosten</mat-label>
                                            <input matInput
                                                   placeholder="Kosten"
                                                   formControlName="newPrice">
                                            <mat-icon class="error-icon d-none" matSuffix>error_outline</mat-icon>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="new-control">
                                    <td mat-footer-cell *matFooterCellDef class="pl-2 pr-0 text-center">
                                        <a class="ml-2 remove-btn fas fa-minus"
                                           (click)="addNewTreatment = false"
                                           matTooltip="Abbrechen"
                                           matTooltipPosition="right"
                                           matTooltipClass="tooltip-popup"
                                        ></a>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="controls">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="control-wrapper d-flex align-items-center justify-content-center">
                                            <a class="fas fa-edit icon"
                                               matTooltip="Tierdaten bearbeiten"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                            <a class="fas fa-trash-alt icon prevent-opening"
                                               (click)="deleteTreatment(element)"
                                               matTooltip="Maßnahme löschen"
                                               matTooltipPosition="right"
                                               matTooltipClass="tooltip-popup"></a>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
                                    <div class="table-element-detail w-100"
                                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <app-veterinary-treatment-quick-edit class="w-100"
                                                                             [treatment]="element"
                                                                             [pageIndex]="pageIndex"
                                                                             [entriesPerPage]="entriesPerPage"
                                                                             [order]="order"
                                                                             [orderedColumn]="orderedColumn">
                                        </app-veterinary-treatment-quick-edit>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                class="table-row"
                                [class.expanded-row]="expandedElement === element"
                                (click)="expandedElement = canExpand($event, element, expandedElement)">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

                            <tr mat-footer-row
                                [ngStyle]="{'opacity': addNewTreatment ? '1' : '0'}"
                                [ngClass]="{'displayErroneousInputs': displayErroneousInputs}"
                                *matFooterRowDef="displayedColumnNewTreatment"></tr>
                        </table>

                        <div id="bottom-controls"
                             class="controls d-flex justify-content-between align-items-baseline"
                             [ngStyle]="{'bottom': addNewTreatment ? '0' : '94px'}">
                            <div id="total-results">Gesamt: <span>{{totalTreatments}}</span></div>
                            <mat-paginator [length]="totalTreatments"
                                           [pageSize]="entriesPerPage"
                                           (page)="switchPage($event)"
                                           #paginator
                                           showFirstLastButtons
                                           hidePageSize>
                            </mat-paginator>
                        </div>

                        <div class="default-entry mt-2" *ngIf="totalTreatments === 0 && loading === false">
                            Keine Ergebnisse gefunden.
                        </div>

                        <div *ngIf="loading"
                             style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </div>

                    <div class="row" *ngIf="addNewTreatment">
                        <div class="col-md-12">
                            <button class="float-right mt-4 ml-4"
                                    mat-raised-button
                                    color="primary"
                                    [disabled]="addRequestSent && newTreatmentForm.valid"
                                    (click)="saveTreatment()">
                                <i class="fas fa-exclamation-circle mr-2"
                                   style="color: #b71c1c"
                                   *ngIf="displayErroneousInputs">
                                </i>
                                Maßnahme speichern
                            </button>
                            <mat-icon
                                    [hidden]="!(addRequestSent && newTreatmentForm.valid)"
                                    class="float-right" style="margin-top: 30px">
                                <mat-spinner color="accent" diameter="20"></mat-spinner>
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
